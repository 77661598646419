define("client/models/dabkpiaccess", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    role: _emberData.default.belongsTo('role', {
      async: true
    }),
    dabkpi: _emberData.default.belongsTo('dabkpi', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }) // isDelete: DS.attr('boolean')

  });

  _exports.default = _default;
});