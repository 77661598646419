define("client/pods/components/w3office-forms/advanced-components/horizontal-slider/component-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['w-100', 'horizontal-slider'],
    actions: {
      mutateEntryContainer: function mutateEntryContainer(val) {
        this.mutateEntryContainer(val);
      }
    }
  });

  _exports.default = _default;
});