define("client/models/vendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    //attributes
    vendorid: _emberData.default.attr('string'),
    vendorname: _emberData.default.attr('string'),
    vendoraddressnum: _emberData.default.attr('number'),
    contactname: _emberData.default.attr('string'),
    phone1: _emberData.default.attr('string'),
    phone2: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    billtocontactname: _emberData.default.attr('string'),
    billtophone: _emberData.default.attr('string'),
    billtofax: _emberData.default.attr('string'),
    hasbilladdress: _emberData.default.attr('boolean'),
    billtoaddress: _emberData.default.attr('number'),
    isactive: _emberData.default.attr('boolean'),
    ismanufacturer: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    filladdress: _emberData.default.attr('string'),
    fillbilladdress: _emberData.default.attr('string'),
    //relationships
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    vendorparentnum: _emberData.default.belongsTo('vendor', {
      inverse: 'children',
      async: true
    }),
    children: _emberData.default.hasMany('vendor', {
      inverse: 'vendorparentnum',
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});