define("client/pods/components/dashboard-builder/newedit-dabsetting/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isNew: true,
    init: function init() {
      if (this.get("type") === "New") {
        this.set("isNew", true);
      } else if (this.get('type') === 'Edit') {
        this.set("isNew", false);
      }

      return this._super();
    },
    buttonName: Ember.computed('type', function () {
      if (this.get('type') === 'New') {
        return 'Add';
      } else if (this.get('type') === 'Edit') {
        return 'Update';
      }
    }),
    readOnly: Ember.computed('type', function () {
      if (this.get('type') === 'View') {
        return true;
      } else {
        return false;
      }
    }),
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#titleForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#titleForm").removeClass("has-error");
          (0, _jquery.default)("#titleIsEmpty").addClass("hidden");
        }
      });
      (0, _jquery.default)('#descriptionForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#descriptionForm").removeClass("has-error");
          (0, _jquery.default)("#descriptionIsEmpty").addClass("hidden");
        }
      });
    },
    actions: {
      saveDabsetting: function saveDabsetting() {
        var errorCount = 0;
        var newDabsetting = this.get('objDabsetting');

        if (newDabsetting.get('showFirst') === '') {
          (0, _jquery.default)("#showFirstForm").addClass("has-error");
          (0, _jquery.default)("#showFirstIsEmpty").removeClass("hidden");
          errorCount++;
        }

        if (errorCount === 0) {
          this.actionToRoute();
        }
      }
    }
  });

  _exports.default = _default;
});