define("client/models/genreportconfigsort", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    tablename: (0, _attr.default)('string'),
    columnname: (0, _attr.default)('string'),
    sort: (0, _attr.default)('string'),
    ascolumn: (0, _attr.default)('string'),
    isdelete: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    genreportnum: (0, _relationships.belongsTo)('genreport', {
      async: true
    })
  });

  _exports.default = _default;
});