define("client/models/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    item_name: _emberData.default.attr('string'),
    item_desc: _emberData.default.attr('string'),
    item_qty: _emberData.default.attr('number'),
    stock: _emberData.default.attr('number'),
    popularity: _emberData.default.attr('number'),
    selling: _emberData.default.attr('number'),
    item_sku: _emberData.default.attr('string'),
    item_weight: _emberData.default.attr('number'),
    item_uom: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    variant: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isdelete: _emberData.default.attr('boolean'),
    pdf_file: _emberData.default.attr('string'),
    is_unlimited: _emberData.default.attr('boolean'),
    //relation	
    // testimonialnum: DS.hasMany('testimonial', {async: true}),
    // pricenum: DS.hasMany('price', {async: true}),
    // itemimagenum: DS.hasMany('itemimage', {async: true}),
    // itemcategorynum: DS.hasMany('itemcategory', {async: true}),
    uomnum: _emberData.default.belongsTo('uom', {
      async: true
    }),
    // storenum: DS.belongsTo('store', {async: true}),
    categorynum: _emberData.default.belongsTo('category', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});