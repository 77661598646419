define("client/pods/w3office-forms/form/preview/route", ["exports", "ember-inflector", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3office-forms/form-layout-common", "client/mixins/w3office-forms/player-common-setup"], function (_exports, _emberInflector, _checkUserRole, _formLayoutCommon, _playerCommonSetup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _formLayoutCommon.default, _playerCommonSetup.default, {
    environment: 'create',
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    model: function model() {
      return this.modelFor('w3office-forms/form');
    },
    afterModel: function afterModel(model, transition) {
      model.set('viewsCount', model.get('viewsCount') + 1);
      model.save();
    },
    setupController: function setupController(controller, model) {
      controller.set('componentArrayIsPending', true);

      if (!Ember.isEmpty(model)) {
        controller.set('authObject', this.get('authObject'));
        controller.set('model', model);
        controller.set('form', model);

        this._super(controller, model);

        this.initializeFormLayout(controller, {
          entry: undefined
        });
      }
    }
  });

  _exports.default = _default;
});