define("client/pods/users-roles/role-manager/app/app-access/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/role-manager-app", "axios"], function (_exports, _jquery, _checkUserRole, _roleManagerApp, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _roleManagerApp.default, {
    actionList: null,
    beforeModel: function beforeModel() {
      var Variable = Ember.Object.extend({
        action: null,
        varName: null,
        status: false
      });
      var actions = [Variable.create({
        action: 'Create App Access',
        varName: 'canCreateAppAccess',
        status: false
      }), Variable.create({
        action: 'Edit App Access',
        varName: 'canEditAppAccess',
        status: false
      }), Variable.create({
        action: 'Delete App Access',
        varName: 'canDeleteAppAccess',
        status: false
      })];
      this.set('actionList', actions);

      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render({
        outlet: 'app-access'
      });
      var controller = this.get('controller');
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateAppAccess') {
          controller.set('canCreateAppAccess', action.get('status'));
        }

        if (action.get('varName') === 'canEditAppAccess') {
          controller.set('canEditAppAccess', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteAppAccess') {
          controller.set('canDeleteAppAccess', action.get('status'));
        }
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)("#app-access").addClass('active');
        (0, _jquery.default)("#app-access-tab").addClass('active show');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('canCreateAppAccess', false);
      controller.set('canEditAppAccess', false);
      controller.set('canDeleteAppAccess', false);
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateAppAccess') {
          controller.set('canCreateAppAccess', action.get('status'));
        }

        if (action.get('varName') === 'canEditAppAccess') {
          controller.set('canEditAppAccess', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteAppAccess') {
          controller.set('canDeleteAppAccess', action.get('status'));
        }
      });
      controller.set('appaccessId', null);
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)('#access').removeClass('active');
        (0, _jquery.default)('#assign').removeClass('active');
        (0, _jquery.default)('#role').removeClass('active');
        (0, _jquery.default)('#route').removeClass('active');
        (0, _jquery.default)('#access-tab').removeClass('active show');
        (0, _jquery.default)('#assign-tab').removeClass('active show');
        (0, _jquery.default)('#role-tab').removeClass('active show');
        (0, _jquery.default)('#route-tab').removeClass('active show');
      });
    },
    getAppAccessFromSelectedRole: function getAppAccessFromSelectedRole() {
      var controller = this.get('controller');
      var axiosConfig = {
        method: "GET",
        params: {
          appId: this.get('appId'),
          roleId: this.get('roleId'),
          page: controller.get('listPage'),
          limit: controller.get('limit')
        },
        url: this.store.adapterFor('application').get('namespace') + '/appaccesses/getAppAccessFromSelectedRole'
      };
      (0, _axios.default)(axiosConfig).then(function (response) {
        if (response.status === 200) {
          controller.set('appaccesses', response.data.appaccesses);

          if (response.data.exceedTotal) {
            controller.set('listPage', 1);
          }

          controller.set('totalPage', response.data.totalPage);
        }
      }).catch(function (err) {
        console.log("ERROR, something wrong with getAppAccessFromSelectedRole");
        console.log(err);
      });
    },
    actions: {
      setRole: function setRole(params) {
        var controller = this.get('controller');
        controller.set('appaccesses', null);

        if (params !== '') {
          controller.set('roleSelected', true);
          this.set('roleId', params.id);
          controller.set('roleId', params.id);
          controller.set('roleName', params.get('roleName'));
          this.getAppAccessFromSelectedRole();
        } else {
          controller.set('roleSelected', false);
          controller.set('roleName', '');
        }
      },
      changeLimit: function changeLimit() {
        this.getAppAccessFromSelectedRole();
      },
      prevList: function prevList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') - 1);
        this.getAppAccessFromSelectedRole();
      },
      nextList: function nextList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') + 1);
        this.getAppAccessFromSelectedRole();
      },
      storeAppAccess: function storeAppAccess(param) {
        var controller = this.get('controller');
        controller.set('appaccessId', param);
      },
      deleteAppAccess: function deleteAppAccess() {
        var _this = this;

        var controller = this.get('controller');
        this.store.findRecord('appaccess', controller.get('appaccessId')).then(function (appaccess) {
          appaccess.set('isdelete', true);
          appaccess.save().then(function () {
            _this.getAppAccessFromSelectedRole();
          });
        });
      },
      goTo: function goTo(param) {
        this.goTo(param);
      }
    }
  });

  _exports.default = _default;
});