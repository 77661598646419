define("client/pods/components/w3office-forms/value-translator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    tagName: 'span',

    /*
    	Set primitive for primitive collection w/out setter getter
    	Can be overriden if colllection passed is a proper object w/ setter getter
    */
    primitive: true,

    /*
    	Assign the correct label for value on component initiation.
    	Set label to value if collection unavailable
    */
    init: function init() {
      this._super();

      var self = this;

      if (_typeof(this.get('collection')) === 'object') {
        this.get('collection').forEach(function (object) {
          if (self.get('primitive')) {
            if (object[self.get('valueProperty')] === self.get('value')) {
              self.set('label', object[self.get('labelProperty')]);
            }
          } else {
            if (object.get(self.get('valueProperty')) === self.get('value')) {
              self.set('label', object.get(self.get('labelProperty')));
            }
          }
        });
      } else {
        this.set('label', this.get('value'));
      }
    },

    /*
    	Reevaluate when value changed
    */
    didUpdateAttrs: function didUpdateAttrs() {
      var self = this;

      if (_typeof(this.get('collection')) === 'object') {
        this.get('collection').forEach(function (object) {
          if (self.get('primitive')) {
            if (object[self.get('valueProperty')] === self.get('value')) {
              self.set('label', object[self.get('labelProperty')]);
            }
          } else {
            if (object.get(self.get('valueProperty')) === self.get('value')) {
              self.set('label', object.get(self.get('labelProperty')));
            }
          }
        });
      } else {
        this.set('label', this.get('value'));
      }
    }
  });

  _exports.default = _default;
});