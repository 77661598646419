define("client/pods/components/dashboard-v2/chart-component/filter-component/datepicker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    primaryClassName: 'datetime-picker',
    date: null,
    enableTime: true,
    placeholder: null,
    noCalendar: false,
    time_24hr: true,
    actions: {
      executeOnChange: function executeOnChange(selectedDates, dateStr, instance) {
        var fieldName = this.get('fieldName');

        if (fieldName === 'datefrom') {
          this.set('filter.datefrom', dateStr);
        } else {
          this.set('filter.dateto', dateStr);
        }

        this.mutateFilter();
      }
    }
  });

  _exports.default = _default;
});