define("client/pods/components/w3office-forms/form-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('appID', this.get('model').get('appID'));
      this.set('form_id', this.get('model').get('id'));
    },
    actions: {
      appChanged: function appChanged(appId) {
        this.appChanged(appId);
      },
      redirectForm: function redirectForm(formId) {
        //Change form based on id
        if (!Ember.isEmpty(formId)) {
          this.redirectForm(formId);
        }
      }
    }
  });

  _exports.default = _default;
});