define("client/pods/components/dashboard-v2/chart-component/filter-component/datepicker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6r5YHRKn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ember-flatpickr\",null,[[\"date\",\"enableTime\",\"onChange\",\"placeholder\",\"noCalendar\",\"time_24hr\",\"disabled\",\"classNames\",\"dateFormat\",\"disableMobile\"],[[28,\"readonly\",[[24,[\"date\"]]],null],[24,[\"enableTime\"]],[28,\"action\",[[23,0,[]],\"executeOnChange\"],null],[24,[\"placeholder\"]],[24,[\"noCalendar\"]],[24,[\"time_24hr\"]],[24,[\"disabled\"]],\"datetime-picker form-control form-control-sm\",[24,[\"dateFormat\"]],\"true\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dashboard-v2/chart-component/filter-component/datepicker/template.hbs"
    }
  });

  _exports.default = _default;
});