define("client/pods/components/w3oprocess/config-content/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('selectedTab') == 'event') {
        this.get('content').set('contentId', this.get('content').get('eventId'));
        this.set('imgUrl', this.get('content').get('imgUrl'));
      } else if (this.get('selectedTab') == 'activity') {
        this.get('content').set('contentId', this.get('content').get('activityId'));

        if (this.get('content').get('type') == 'task') {
          this.set('imgUrl', "/assets/images/bpmn/task.png");
        } else if (this.get('content').get('type') == 'sub') {
          this.set('imgUrl', "/assets/images/bpmn/sub.png");
        } else if (this.get('content').get('type') == 'call') {
          this.set('imgUrl', "/assets/images/bpmn/call.png");
        }
      } else if (this.get('selectedTab') == 'gateway') {
        this.get('content').set('contentId', this.get('content').get('gatewayId'));
        this.set('imgUrl', "/assets/images/bpmn/gateway.png");
      } else if (this.get('selectedTab') == 'sequence') {
        this.get('content').set('contentId', this.get('content').get('sequenceId'));
        this.set('imgUrl', "/assets/images/bpmn/sequence.png");
      }
    },
    actions: {
      openDeleteModal: function openDeleteModal(contentToBeDeleted) {
        this.openDeleteModal(contentToBeDeleted);
      },
      selectGroupActivityTask: function selectGroupActivityTask(selection) {
        this.selectGroupActivityTask(selection, this.get('index'));
      },
      openActivityEmailModal: function openActivityEmailModal(activity, index) {
        (0, _jquery.default)('#activity-message-modal-' + index).modal('toggle');
      },
      selectGroupActivityEmail: function selectGroupActivityEmail(selection) {
        this.get("content").set("emailGroup", selection);
        this.selectGroupActivityEmail(selection, this.get('index'));
      }
    }
  });

  _exports.default = _default;
});