define("client/pods/table-builder/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    setupController: function setupController(controller) {
      this.controllerFor('application').set('appID', 'Table Builder');
      this.controllerFor('application').set('menus', [{
        'title': 'Table List',
        'glyph': 'fa-copy',
        'link': 'table-builder.table-list',
        'unique': 'table-list',
        'theme': '#ffffff'
      }, {
        'title': 'Server Management',
        'glyph': 'fa-cog',
        'link': 'table-builder.server-management',
        'unique': 'server-management',
        'theme': '#ffffff'
      }]);
    }
  });

  _exports.default = _default;
});