define("client/pods/components/dashboard-v2/preview-table-row/component", ["exports", "client/pods/components/dashboard-v2/preview-table/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    	Set Tagname of Component wrapper as <tr> element
    */
    classNames: ['cursor-pointer'],
    classNameBindings: ['bgSecondary:bg-secondary'],
    tagName: 'tr',
    bgSecondary: false,

    /*
    	Populate cell's column with properties of passed object. Property names are gotten from parent table headers.
    	Provide exception handling when object has setter/getter or not.
    */
    records: Ember.computed('content', 'headers', function () {
      var self = this,
          table = this.get('table'),
          records = Ember.A();

      if (!Ember.isEmpty(this.get('content')) && table.get('headers')) {
        table.get('headers').forEach(function (header) {
          var tempObj;

          if (typeof self.get('content').get === 'function') {
            if (header.relationColumnName) {
              tempObj = {
                id: header.label,
                content: self.get('content')[header.id].get(header.relationColumnName)
              };
            } else {
              tempObj = {
                id: header.label,
                content: self.get('content').get(header.id)
              };
            }
          } else {
            tempObj = {
              id: header.label,
              content: self.get('content')[header.id]
            };
          }

          if (typeof tempObj.content === 'boolean') {
            tempObj.isBoolean = true;
          }

          records.push(tempObj);
        });
      }

      return records;
    }),

    /*
    	Connect cell with parent table
    */
    table: Ember.computed(function () {
      return this.nearestOfType(_component.default);
    }),

    /*
    	Bubble click action to route
    */
    click: function click() {
      this.toggleProperty("bgSecondary");
      this.selectRecord({
        elementId: this.get("elementId"),
        record: this.get("content")
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // console.log("didReceiveAttrs - preview-table-row");
      // console.log(this.get("selectedRecord"));
      if (this.get("elementId") !== this.get("selectedRecord")) {
        this.set("bgSecondary", false);
      }
    }
  });

  _exports.default = _default;
});