define("client/models/currency", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currencyid: _emberData.default.attr('string'),
    currencyname: _emberData.default.attr('string'),
    currencysymbol: _emberData.default.attr('string'),
    currencyremarks: _emberData.default.attr('string'),
    isactive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isdelete: _emberData.default.attr('boolean'),
    //relation	
    companies: _emberData.default.hasMany('company'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});