define("client/components/map-info-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    olMapservice: Ember.inject.service('ol-mapservice'),
    store: Ember.inject.service(),
    tagName: 'div',
    srs_value: 0,
    map_scale: 1000000,
    // need to calculate this
    x_pos: 400000,
    y_pos: 600000,
    fp_status: true,
    guideLink: null,
    srsFieldValue: '5247',
    init: function init() {
      this._super.apply(this, arguments); //this.guideLink = `${this.store.adapterFor('application').get('host')}/pdf/GeoportalUserGuide.pdf`;
      //const olmap2svc = this.get('olmap2svc');
      //olmap2svc.setMousePosition();


      console.log('map-info-bar init getting the srs value: ' + this.get('srsSwitch'));
      this.set('srsFieldValue', this.get('srsSwitch'));
    },
    didInsertElement: function didInsertElement() {
      /* const olmap2svc = this.get('olmap2svc');
      const map = olmap2svc.getOmap();
      const initialExtent = olmap2svc.getOinitialextent(map);    
       const layers = olmap2svc.getOlayers(map);
       this.setProperties({
        map: map,
        initialExtent: initialExtent,
        layers: layers
      }); */
      this.initMap(); // action closure

      console.log('map-info-bar getting the srs value: ' + this.get('srsSwitch'));
      this.set('srsFieldValue', this.get('srsSwitch'));
      console.log('hoichong map-info-bar sendaction done');
    },
    actions: {
      updateMapScale: function updateMapScale() {
        var olMapservice = this.get('olMapservice');
        olMapservice.changeMapScale(olMapservice, this.map_scale);
      },
      selectSRS: function selectSRS(value, event) {
        var olMapservice = this.get('olMapservice');
        console.log('map-info-bar trying to clear layers and search results');
        olMapservice.clearSearchResults();
        this.reloadMapLayers(value);
        olMapservice.searchEPSG(olMapservice, value);
      },
      setInfoMousePointer: function setInfoMousePointer() {
        var olMapservice = this.get('olMapservice');
        console.log('map-info-bar trying to set info mouse pointer');
        var map_panel_arr = document.getElementsByClassName("ol-map-panel");
        map_panel_arr[0].style.cursor = "crosshair";
      },
      toggleFunctionPanel: function toggleFunctionPanel() {
        var fp_status1 = this.get('fp_status');
        var elementA = document.querySelector('#functionPanel21');
        var olMapservice = this.get('olMapservice');
        console.log('hoichong stmap map-info-bar YOU just CLICK on ATOM fp_status1: ' + fp_status1);

        if (Boolean(fp_status1)) {
          console.log('Hide FP..');
          fp_status1 = !fp_status1;
          olMapservice.animateCSS('#functionPanel21', 'bounceOutLeft', function () {
            elementA.style.display = "none"; //fp_status1 = fp_status1 ? false : true;         
          });
        } else {
          console.log('Show FP..');
          fp_status1 = !fp_status1;
          elementA.style.display = "block";
          olMapservice.animateCSS('#functionPanel21', 'bounceInLeft', function () {
            elementA.style.display = "block";
          });
        }

        console.log('final change to ' + fp_status1);
        this.set('fp_status', fp_status1);
      },
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      }
    }
  });

  _exports.default = _default;
});