define("client/pods/report-generator/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/report-generator/report-common"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin, _reportCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _reportCommon.default, {
    currentRoute: '',
    authObject: Ember.inject.service('session'),
    // initialize session
    beforeModel: function beforeModel(transition) {
      this.set('currentRoute', transition.targetName);
    },
    model: function model() {
      return this.store.query('genreport', {
        isdelete: false,
        sort: 'id ASC'
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this;
      controller.set('currentRoute', this.get('currentRoute'));
      controller.set('componentsShown', false);

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var session = Ember.Object.create({
            user: user
          });
          self.controller.set('session', session); //set session
        });
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        //Component Tab Handler on willTransition event
        this.controller.set('currentRoute', transition.targetName);
        this.controller.set('componentsShown', false);
      }
    }
  });

  _exports.default = _default;
});