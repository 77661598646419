define("client/pods/w3office-forms/importexport/key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    form: {
      hasMany: {
        formaccesslimit: {
          formProcess: {
            modelName: 'formprocess',
            isBelongsTo: true
          },
          belongsTo: {
            key: 'form',
            modelName: 'form'
          }
        },
        formpredefvalue: {
          belongsTo: {
            key: 'form',
            modelName: 'form'
          },
          hasMany: {
            formpredefvaluelocalization: {
              belongsTo: {
                key: 'formpredefvalue',
                modelName: 'formpredefvalue'
              }
            }
          }
        },
        formprocess: {
          belongsTo: {
            key: 'form',
            modelName: 'form'
          },
          process: {
            modelName: 'process',
            isBelongsTo: true
          },
          hasMany: {
            fieldtaskconfig: {
              fieldId: {
                modelName: 'field',
                isBelongsTo: false
              },
              belongsTo: {
                key: 'formProcess',
                modelName: 'formprocess'
              }
            }
          }
        },
        formuniquecomposite: {
          belongsTo: {
            key: 'form',
            modelName: 'form'
          },
          hasMany: {
            formuniquecompositecontent: {
              field: {
                modelName: 'field',
                isBelongsTo: true
              },
              predefvalue: {
                modelName: 'formpredefvalue',
                isBelongsTo: true
              },
              belongsTo: {
                key: 'uniqueComposite',
                modelName: 'formuniquecomposite'
              }
            }
          }
        },
        field: {
          relationalField: {
            modelName: 'field',
            isBelongsTo: true
          },
          operationRelationField: {
            modelName: 'field',
            isBelongsTo: true
          },
          monitoredField: {
            modelName: 'field',
            isBelongsTo: true
          },
          firstFieldToCalculate: {
            modelName: 'field',
            isBelongsTo: true
          },
          secondFieldToCalculate: {
            modelName: 'field',
            isBelongsTo: true
          },
          relationImageViewer: {
            modelName: 'field',
            isBelongsTo: true
          },
          belongsTo: {
            key: 'form',
            modelName: 'form'
          },
          hasMany: {
            fieldfilter: {
              filterValue: {
                modelName: 'field',
                isBelongsTo: false
              },
              filterField: {
                modelName: 'field',
                isBelongsTo: true
              },
              belongsTo: {
                key: 'field',
                modelName: 'field'
              }
            },
            fieldgroupcontent: {
              belongsTo: {
                key: 'field',
                modelName: 'field'
              }
            },
            fieldlogicmaster: {
              form: {
                modelName: 'form',
                isBelongsTo: true
              },
              sourceField: {
                modelName: 'field',
                isBelongsTo: true
              },
              belongsTo: {
                key: 'affectedField',
                modelName: 'field'
              },
              hasMany: {
                fieldlogic: {
                  affectedField: {
                    modelName: 'field',
                    isBelongsTo: true
                  },
                  sourceField: {
                    modelName: 'field',
                    isBelongsTo: true
                  },
                  triggerField: {
                    modelName: 'field',
                    isBelongsTo: true
                  },
                  belongsTo: {
                    key: 'fieldLogicMaster',
                    modelName: 'fieldlogicmaster'
                  }
                },
                fieldlogiccontent: {
                  fieldGroupContent: {
                    modelName: 'fieldgroupcontent',
                    isBelongsTo: true
                  },
                  fieldFilter: {
                    modelName: 'fieldfilter',
                    isBelongsTo: true
                  },
                  belongsTo: {
                    key: 'fieldLogicMaster',
                    modelName: 'fieldlogicmaster'
                  }
                }
              }
            },
            fieldonloadconfig: {
              belongsTo: {
                key: 'field',
                modelName: 'field'
              }
            },
            fieldprefix: {
              belongsTo: {
                key: 'field',
                modelName: 'field'
              }
            },
            fieldtrigger: {
              fieldTrigger: {
                modelName: 'field',
                isBelongsTo: false
              },
              belongsTo: {
                key: 'field',
                modelName: 'field'
              }
            },
            fieldlocalization: {
              belongsTo: {
                key: 'field',
                modelName: 'field'
              }
            },
            fieldactionparam: {
              belongsTo: {
                key: 'field',
                modelName: 'field'
              }
            }
          }
        },
        entryfilter: {
          fieldType: {
            modelName: 'field',
            isBelongsTo: true
          },
          hiddenType: {
            modelName: 'formpredefvalue',
            isBelongsTo: true
          },
          entrygroup: {
            modelName: 'entrygroup',
            isBelongsTo: true
          },
          belongsTo: {
            key: 'form',
            modelName: 'form'
          }
        },
        entrygroup: {
          belongsTo: {
            key: 'form',
            modelName: 'form'
          },
          hasMany: {
            entrygrouplocalization: {
              belongsTo: {
                key: 'entrygroup',
                modelName: 'entrygroup'
              }
            }
          }
        },
        entrylistconfiguration: {
          belongsTo: {
            key: 'form',
            modelName: 'form'
          },
          hasMany: {
            entrylistcontent: {
              field: {
                modelName: 'field',
                isBelongsTo: true
              },
              predefvalue: {
                modelName: 'formpredefvalue',
                isBelongsTo: true
              },
              belongsTo: {
                key: 'entryListConfiguration',
                modelName: 'entrylistconfiguration'
              }
            }
          }
        },
        entryconfiguration: {
          entryListConfiguration: {
            modelName: 'entrylistconfiguration',
            isBelongsTo: true
          },
          entryGroup: {
            modelName: 'entrygroup',
            isBelongsTo: true
          },
          mappedForm: {
            modelName: 'form',
            isBelongsTo: true
          },
          belongsTo: {
            key: 'form',
            modelName: 'form'
          }
        },
        formrelation: {
          form2: {
            modelName: 'form',
            isBelongsTo: true
          },
          belongsTo: {
            key: 'form1',
            modelName: 'form'
          }
        },
        formlocalization: {
          belongsTo: {
            key: 'form',
            modelName: 'form'
          }
        }
      }
    }
  };
  _exports.default = _default;
});