define("client/pods/dashboard-builder/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vZFJjEFU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-header row border-top border-bottom mx-0\"],[10,\"style\",\"margin-bottom: 10px;\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"pull-left\"],[8],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[0,\"Dashboard\"],[9],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row mx-0\"],[10,\"style\",\"display: flex; justify-content: flex-end;\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder.new\"]],{\"statements\":[[0,\"    \\t\"],[7,\"div\",true],[10,\"class\",\"btn btn-success my-2\"],[8],[0,\"\\n    \\t\\t\"],[7,\"span\",true],[10,\"class\",\"fa fa-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Create New Dashboard\\n    \\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0 mt-3\"],[8],[0,\"\\n\\t\"],[1,[28,\"component\",[\"dashboard-builder/dashboard-paging\"],[[\"totalPage\",\"listPage\",\"postLimit\",\"firstList\",\"modelConArr\",\"modelCon\",\"modelCount\",\"totalModel\",\"nextList\",\"prevList\",\"deleteDashboard\"],[[24,[\"totalPage\"]],[24,[\"listPage\"]],[24,[\"postLimit\"]],[24,[\"firstList\"]],[24,[\"modelConArr\"]],[24,[\"modelCon\"]],[24,[\"modelCount\"]],[24,[\"totalModel\"]],[28,\"route-action\",[\"nextList\"],null],[28,\"route-action\",[\"prevList\"],null],[28,\"route-action\",[\"deleteDashboard\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/dashboard-builder/index/template.hbs"
    }
  });

  _exports.default = _default;
});