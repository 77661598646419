define("client/pods/components/dashboard/dab-shout/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shout-inline'],
    loadingData: false,
    selectQuery: null,
    selectContents: null,
    paramcollectionQuery: null,
    paramcollectionContents: null,
    selectedParamcollection: null,
    paramContents: null,
    paramLoading: false,
    showButton: false,
    organizationname: 'All Organizations',
    companyname: 'All Companies',
    init: function init() {
      this._super();

      var self = this;

      if (!Ember.isEmpty(self.get('shout').get('organizationnum.id'))) {
        self.set('organizationname', self.get('shout').get('organizationnum.organizationname'));
      }

      if (!Ember.isEmpty(self.get('shout').get('companynum.id'))) {
        self.set('companyname', self.get('shout').get('companynum.companyname'));
      }

      self.set('title', self.get('shout').get('title'));
      self.set('value', self.get('shout').get('value'));
    },
    didInsertElement: function didInsertElement() {
      this.set('loadingData', true);
      this.executeDabShout(this);
    },
    actions: {
      toggleButton: function toggleButton() {
        this.set('showButton', !this.get('showButton'));
      },
      updateChart: function updateChart(id) {
        this.dabShoutUpdateChart(this, id);
      },
      setParamcollection: function setParamcollection(paramcollection) {
        var self = this;

        if (paramcollection) {
          self.set('paramLoading', true);
          self.dabShoutSetParamCollection(self, paramcollection);
        } else {
          self.set('selectedParamcollection', null);
        }
      },
      saveParam: function saveParam() {
        this.dabShoutSaveParam(this);
      },
      showModal: function showModal(param) {
        (0, _jquery.default)('#shoutParameter' + param).modal('toggle');
      }
    }
  });

  _exports.default = _default;
});