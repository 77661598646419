define("client/pods/components/w3o-users-roles/form-user/user-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUTPzIOR",
    "block": "{\"symbols\":[\"xs\",\"group\",\"&default\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"group\"],[8],[0,\"User Group \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"groupIsEmpty\"],[8],[0,\"Please select the user group\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"groupForm\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none dropdown-icon-error\"],[10,\"id\",\"glyphGroup\"],[8],[9],[0,\"\\n\"],[4,\"x-select\",null,[[\"value\",\"on-change\",\"class\"],[[24,[\"groupCon\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"groupCon\"]]],null]],null],\"form-control\"]],{\"statements\":[[0,\"        \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/w3o-users-roles/form-user/user-group/template.hbs' @ L6:C11) \"],null]],[[\"value\"],[\"\"]],{\"statements\":[[0,\"Select Group\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"groupModel\"]]],null,{\"statements\":[[0,\"            \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/w3o-users-roles/form-user/user-group/template.hbs' @ L8:C15) \"],null]],[[\"value\"],[[23,2,[]]]],{\"statements\":[[0,\" \"],[1,[23,2,[\"groupName\"]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/user-group/template.hbs"
    }
  });

  _exports.default = _default;
});