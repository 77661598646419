define("client/pods/components/w3office-forms/draggable-component/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dragStart: function dragStart(event) {
      //Transfer Component ID
      this.set('formContainer.componentHolder', this.get('component'));
      this.set('formContainer.fieldHolder', null); //return event.dataTransfer.setData('text/data', this.get('component').get('path'));
    },
    observeSelection: Ember.observer('component.isSelected', function () {
      //console.log(this.get('component').get('path'));
      //Check if there is selection/unselection of the component
      if (this.get('component').get('isSelected') === true) {
        //console.log(this.get('component').get('path'));
        (0, _jquery.default)('#draggable').css('background-color', '#00ffc5');
      } else {
        (0, _jquery.default)('#draggable').css('background-color', '#ccc');
      }
    }),
    actions: {
      changeSelection: function changeSelection() {
        //Toggle on component selection
        if (!this.get('component').get('isSelected')) {
          console.log(true);
          this.changeSelection(this.get('component'), true);
        } else {
          console.log(false);
          this.changeSelection(this.get('component'), false);
        }
      }
    }
  });

  _exports.default = _default;
});