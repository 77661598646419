define("client/models/genreportcolumnvalue", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    order: (0, _attr.default)('number'),
    value: (0, _attr.default)('string'),
    genreportconfigselectnum: (0, _relationships.belongsTo)('genreportconfigselect')
  });

  _exports.default = _default;
});