define("client/models/dabkpiconfig", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //attributes
    category: (0, _attr.default)("string"),
    fromTable: (0, _attr.default)("string"),
    limit: (0, _attr.default)("string"),
    interval: (0, _attr.default)("string"),
    whatsql: (0, _attr.default)("string"),
    isdelete: (0, _attr.default)('boolean'),
    //relations
    dabkpi: (0, _relationships.belongsTo)("dabkpi", {
      async: true
    }),
    dabkpiconfigconditions: (0, _relationships.hasMany)("dabkpiconfigcondition", {
      async: true
    }),
    dabkpiconfigselections: (0, _relationships.hasMany)("dabkpiconfigselection", {
      async: true
    }),
    dabkpiconfigjoins: (0, _relationships.hasMany)("dabkpiconfigjoin", {
      async: true
    }),
    dabkpiconfiggroupbys: (0, _relationships.hasMany)("dabkpiconfiggroupby", {
      async: true
    })
  });

  _exports.default = _default;
});