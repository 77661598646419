define("client/pods/menu-builder/child/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.findRecord("menu", param.menu_id);
    }
  });

  _exports.default = _default;
});