define("client/pods/dashboard-builder/dabkpi/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t/uQK6zx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-top border-bottom\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n            \"],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder\"]],{\"statements\":[[0,\"Dashboard ( \"],[1,[22,\"dashboardtitle\"],false],[0,\" )\"]],\"parameters\":[]},null],[0,\" - \"],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder.dabkpi\"]],{\"statements\":[[0,\"KPI ( \"],[1,[22,\"dabkpititle\"],false],[0,\" )\"]],\"parameters\":[]},null],[0,\" - Edit\"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0 mt-3\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[\"dashboard-builder/newedit-dabkpi\"],[[\"type\",\"objDabkpi\",\"isLoadingResourceAccess\",\"resourceAccessEnabled\",\"resourceAccessRestricted\",\"resourceAccess\",\"organizationList\",\"companyList\",\"actionToRoute\"],[\"Edit\",[24,[\"model\"]],[24,[\"isLoadingResourceAccess\"]],[24,[\"resourceAccessEnabled\"]],[24,[\"resourceAccessRestricted\"]],[24,[\"resourceAccess\"]],[24,[\"organizationList\"]],[24,[\"companyList\"]],[28,\"route-action\",[\"updateDabkpi\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/dashboard-builder/dabkpi/edit/template.hbs"
    }
  });

  _exports.default = _default;
});