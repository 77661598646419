define("client/models/theme", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    themeName: _emberData.default.attr('string'),
    topNavBarColor: _emberData.default.attr('string'),
    topNavBarFontColor: _emberData.default.attr('string'),
    sideNavBarFontColor: _emberData.default.attr('string'),
    sideNavBarSelectedFontColor: _emberData.default.attr('string'),
    sideNavBarPrimaryColor: _emberData.default.attr('string'),
    sideNavBarSecondaryColor: _emberData.default.attr('string'),
    badgeBackgroundColor: _emberData.default.attr('string'),
    badgeFontColor: _emberData.default.attr('string'),
    menuPosition: _emberData.default.attr('string'),
    buttonColor: _emberData.default.attr('string'),
    buttonTextColor: _emberData.default.attr('string'),
    tableHeaderBackgroundColor: _emberData.default.attr('string'),
    tableHeaderFontColor: _emberData.default.attr('string'),
    tableHeaderFontFamily: _emberData.default.attr('string'),
    tableHeaderFontSize: _emberData.default.attr('string'),
    tableRowBackgroundColor: _emberData.default.attr('string'),
    tableRowFontColor: _emberData.default.attr('string'),
    tableRowFontFamily: _emberData.default.attr('string'),
    tableRowFontSize: _emberData.default.attr('string'),
    labelFontColor: _emberData.default.attr('string'),
    labelFontSize: _emberData.default.attr('string'),
    valueFontColor: _emberData.default.attr('string'),
    valueFontSize: _emberData.default.attr('string'),
    borderStyle: _emberData.default.attr('string'),
    borderColor: _emberData.default.attr('string'),
    backgroundColor: _emberData.default.attr('string'),
    titleFontColor: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});