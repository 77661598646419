define("client/pods/menu-builder/editicon/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    // model createRecord on apllication model
    model: function model(params) {
      return this.store.findRecord('application', params.app_id);
    },
    renderTemplate: function renderTemplate() {
      this._super();
    }
  });

  _exports.default = _default;
});