define("client/pods/components/menu-builder/newedit-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isNew: true,
    isEmergencyFormID: false,
    emergencyValueFormID: null,
    isEmergencyLink: false,
    emergencyValueLink: null,
    init: function init() {
      this._super();

      if (this.get('type') === 'New') {
        this.set('isNew', true);
      } else if (this.get('type') === 'Edit') {
        this.set('isNew', false);
        this.set('isEmergencyFormID', true);
        this.set('emergencyValueFormID', this.get('objMenu').get('formid'));
        this.set('isEmergencyLink', true);
        this.set('emergencyValueLink', this.get('objMenu').get('link'));
      }

      console.log('init newedit-menu component');
      console.log('formid : ' + this.get('objMenu').get('formid'));
      console.log('link : ' + this.get('objMenu').get('link'));
      console.log('end of init newedit-menu component');
    },
    buttonName: Ember.computed('type', function () {
      if (this.get('type') === 'New') {
        return 'Add';
      } else if (this.get('type') === 'Edit') {
        return 'Update';
      }
    }),
    readOnly: Ember.computed('type', function () {
      if (this.get('type') === 'View') {
        return true;
      } else {
        return false;
      }
    }),
    errorMessageMenuTitle: null,
    errorMessageAppID: null,
    errorMessagePriority: null,
    errorMessageLink: null,
    actions: {
      getFormLinks: function getFormLinks(param) {
        this.getFormLinks(param);
      },
      saveMenu: function saveMenu() {
        var errorCount = 0;

        if (Ember.isEmpty(this.get('objMenu').get('title'))) {
          this.set('errorMessageMenuTitle', 'Menu Title must be filled');
          errorCount++;
        } else {
          this.set('errorMessageMenuTitle', null);
        }

        if (Ember.isEmpty(this.get('objMenu').get('appID'))) {
          this.set('errorMessageAppID', 'AppID must be filled');
          errorCount++;
        } else {
          this.set('errorMessageAppID', null);

          if (this.get('objMenu').get('isExternalLink')) {
            if (Ember.isEmpty(this.get('objMenu').get('link'))) {
              this.set('errorMessageLink', 'Menu Link must be filled');
              errorCount++;
            } else {
              this.set('errorMessageLink', null);
              this.get('objMenu').set('formid', null);
            }
          }
        }

        if (!Ember.isEmpty(this.get('objMenu').get('priority')) && isNaN(this.get('objMenu').get('priority'))) {
          this.set('errorMessagePriority', 'Priority must be a number');
          errorCount++;
        } else {
          this.set('errorMessagePriority', null);
        }

        if (errorCount === 0) {
          this.actionToRoute();
        }
      },
      observeFormID: function observeFormID(value) {
        console.log(value);

        if (this.get('isEmergencyFormID') && value === null && this.get('emergencyValueFormID') !== null) {
          console.log('emergency protocol on formID');
          this.get('objMenu').set('formid', this.get('emergencyValueFormID'));
          this.set('isEmergencyFormID', false);
          this.set('emergencyValueFormID', null);
        }
      },
      observeLink: function observeLink(value) {
        if (this.get('isEmergencyLink') && value === null && this.get('emergencyValueLink') !== null) {
          console.log('emergency protocol on Link');
          this.get('objMenu').set('link', this.get('emergencyValueLink'));
          this.set('isEmergencyLink', false);
          this.set('emergencyValueLink', null);
        }
      },
      observeIsExternalLink: function observeIsExternalLink(value, event) {
        this.set('errorMessageLink', null);
      }
    }
  });

  _exports.default = _default;
});