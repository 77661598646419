define("client/pods/dashboard-builder-v2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0yC+Gg4a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col px-1 px-md-4 mt-5 pt-2 pt-md-4\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/dashboard-builder-v2/template.hbs"
    }
  });

  _exports.default = _default;
});