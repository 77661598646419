define("client/pods/users-roles/users/edit/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/create-edit-user"], function (_exports, _checkUserRole, _createEditUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _createEditUser.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super();
    },
    model: function model(params) {
      return this.store.findRecord('user', params.user_id);
    },
    setupController: function setupController(controller, model) {
      this._super();
      /*this.store.queryRecord('userquestion', { user: model.id }).then(function(userquestion){
      	controller.set('question', userquestion.get('secretquestion.id'));
      });*/

    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    actions: {
      getInfo: function getInfo(param) {
        this.getInfo(param);
      },
      //inserting data to user model, and the data is respective to inputted data on template
      editUser: function editUser(model) {
        this.saveUser(model);
      },
      cancel: function cancel() {
        this.transitionTo('users-roles.users');
      }
    }
  });

  _exports.default = _default;
});