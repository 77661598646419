define("client/models/crontask", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    parent: (0, _attr.default)('number'),
    cron: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    apiBaseUrl: (0, _attr.default)('string'),
    apiPath: (0, _attr.default)('string'),
    apiType: (0, _attr.default)('string'),
    isRunning: (0, _attr.default)('boolean'),
    runOnce: (0, _attr.default)('boolean'),
    // currentIteration	: attr('number', { defaultValue: 0 }),
    // finishIteration		: attr('number'),
    startDate: (0, _attr.default)('date'),
    currentDate: (0, _attr.default)('date'),
    nextDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    apiparams: (0, _relationships.hasMany)('apiparam', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});