define("client/pods/dashboard-builder/dabmap/edit/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(param) {
      return this.store.findRecord("dashboardmap", param.dabmap_id);
    },
    setupController: function setupController(controller, model) {
      var dashboardnum = this.modelFor('dashboard-builder.dabmap');
      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set("dabmapid", model.id);
      controller.set("dabmaptitle", dashboardnum.get('mapTitle'));
      controller.set('model', model);
      this.store.query('dashboardmapcolumn', {
        where: {
          dashboardMap: model.id
        }
      }).then(function (dashboardmapcolumns) {
        var objDabmap = Ember.Object.create({
          modelName: model.get('modelName'),
          titleColumn: model.get('titleColumn'),
          associationString: model.get('associationString'),
          longitudeColumn: model.get('longitudeColumn'),
          latitudeColumn: model.get('latitudeColumn'),
          markerColor: model.get('markerColor'),
          markerSymbol: model.get('markerSymbol'),
          dashboard: model.get('dashboard'),
          removedRelationships: Ember.A()
        });
        controller.set('objDashboardmapcolumns', dashboardmapcolumns.toArray());
        controller.set('objDabmap', objDabmap);
      });
    },
    actions: {
      addMoreContent: function addMoreContent() {
        var newContent = this.store.createRecord('dashboardmapcolumn', {
          label: '',
          columnName: '',
          associationString: '',
          dashboardMap: this.get('currentModel')
        });
        this.controller.get('objDashboardmapcolumns').pushObject(newContent);
      },
      deleteContent: function deleteContent(mapcolumn) {
        mapcolumn.destroyRecord();
        this.controller.get('objDashboardmapcolumns').removeObject(mapcolumn);
      },
      updateDabmap: function updateDabmap() {
        var self = this;
        var obj = this.controller.get('objDabmap');
        var up = this.get("currentModel");
        var promises = Ember.A();
        up.set("modelName", obj.get('modelName'));
        up.set("titleColumn", obj.get('titleColumn'));
        up.set("associationString", obj.get("associationString"));
        up.set("longitudeColumn", obj.get("longitudeColumn"));
        up.set("latitudeColumn", obj.get("latitudeColumn"));
        up.set("dashboard", obj.get("dashboard"));
        up.set("markerColor", obj.get("markerColor"));
        up.set("markerSymbol", obj.get("markerSymbol"));
        promises.push(up.save());
        this.controller.get('objDashboardmapcolumns').forEach(function (content) {
          promises.push(content.save());
        });
        obj.get('removedRelationships').forEach(function (removedRelationship) {
          promises.push(removedRelationship.save());
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          return self.transitionTo('dashboard-builder.dabmap');
        });
      }
    }
  });

  _exports.default = _default;
});