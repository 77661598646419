define("client/models/userprocesstask", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    inbox: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    mytask: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    queued: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    involved: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    archived: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    role: (0, _attr.default)('string'),
    user: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    processtask: (0, _relationships.belongsTo)('processtask', {
      async: true
    })
  });

  _exports.default = _default;
});