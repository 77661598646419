define("client/pods/components/w3office-forms/advanced-components/auto-complete/component", ["exports", "client/mixins/w3office-forms/base-component", "jquery"], function (_exports, _baseComponent, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend(_baseComponent.default, {
    /*
    	Set Unique Id for Menu
    */
    _menuId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-menu';
    }),

    /*
    	Determine if auto-complete support multiple selection or not.
    	Currently disabled
    */
    multiple: false,

    /*
    	Label for the auto-complete input text
    	Variable to contain query input
    */
    inputQuery: '',

    /*
    	Container for is-active class
    */
    menuClass: '',

    /*
    	Trigger to enable request query
    */
    startQuery: false,
    didInsertElement: function didInsertElement() {
      var self = this; //Handle behaviour on showing dropdown props

      if (!this.get('preview')) {
        (0, _jquery.default)(document).click(function () {
          if (_typeof(self.get('dropdownCSSProps')) === 'object' && !Ember.isEmpty(self.get('dropdownCSSProps'))) {
            if (self.get('dropdownCSSProps').toString().indexOf('display:block') !== -1) {
              self.set('dropdownCSSProps', Ember.String.htmlSafe("display:none;"));
            }
          } else if (typeof self.get('dropdownCSSProps') === 'string') {
            if (self.get('dropdownCSSProps').indexOf('display:block') !== -1) {
              self.set('dropdownCSSProps', Ember.String.htmlSafe("display:none;"));
            }
          }
        });
        (0, _jquery.default)(".search-field").click(function (e) {
          e.stopPropagation();
          return false;
        });
      }
    },

    /*
    	Set Default value on component initialization
    	Set Field to show component or not based on field config
    */
    init: function init() {
      this._super();

      if (!this.get('authoring') && (this.get('environment') === 'create' || this.get('environment') === 'duplicate')) {
        this.autoCompleteSetup(this);
      }
    },
    queryInputAction: function queryInputAction() {
      this.queryInput(this);
    },
    actions: {
      /*
      	Trigger record fetching when input query is changed/user is typing something
      */
      observeInput: function observeInput() {
        if (!Ember.isEmpty(this.get('inputQuery')) && this.get('startQuery')) {
          if (this.get('inputQuery').length > 2) {
            this.set('isPending', true);
            /*
            	Run Query to API if there is no input activity within 1000ms of last input
            */

            Ember.run.debounce(this, this.queryInputAction, 1000);
          }
        } else {
          this.set('isPending', false);
          this.set('recordList', null);
        }
      },
      addThis: function addThis(selected) {
        var self = this;
        self.set('inputQuery', selected.label);
        self.get('field').set('entryContainer', selected.value);
        self.set('dropdownCSSProps', Ember.String.htmlSafe("display:none;"));
      },
      deleteThis: function deleteThis(selected) {
        this.get('value').removeObject(selected);
        this.set('dropdownCSSProps', Ember.String.htmlSafe("display:none;"));
        var index = this.get('arrayContainer').indexOf(selected.value);
        this.get('arrayContainer').splice(index, 1);
        this.set('arrayUpdated', !this.get('arrayUpdated'));
      },
      focused: function focused() {
        this.set('startQuery', true);
        this.set('isFocused', !this.get('isFocused'));
      },
      unfocused: function unfocused() {
        this.set('startQuery', false);
      }
    }
  });

  _exports.default = _default;
});