define("client/pods/dashboard-builder/dabkpi/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/dashboard-builder/paging-common", "client/mixins/w3office-forms/check-resource-access"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin, _pagingCommon, _checkResourceAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _pagingCommon.default, _checkResourceAccess.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    setupController: function setupController(controller, model) {
      var self = this;
      var modelQuery;
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      var mixinSuper = this._super;
      controller.set('resourceAccessEnabled', dashboardnum.get('resourceAccessEnabled'));

      if (dashboardnum.get('resourceAccessEnabled')) {
        /*
        *   Resource Access Related
        */
        controller.set('resourceAccessRestricted', false);
        controller.set('resourceAccessConditionPromise', Ember.RSVP.defer());
        controller.set('isLoadingResourceAccess', true);
        controller.set('modelName', 'dabkpi');
        controller.set('filterOrganization', '');
        controller.set('filterCompany', '');
        self.populateResourceAccess(); // set organization list

        self.store.query('organization', {
          where: {
            isdelete: false
          },
          sort: 'organizationname ASC'
        }).then(function (organizations) {
          controller.set('organizations', organizations);
        });
        /*this.store.query('section', {departmentnum: controller.get('filterOrganization'), isdelete: false, sort: 'sectionname ASC'}).then(function(sections) {
                     controller.set('sections', sections);
                 });*/

        self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
          controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
          var conditionContainer = {};
          conditionContainer['where'] = {};
          conditionContainer.sort = 'id ASC';
          conditionContainer.where.isdelete = false;
          conditionContainer['where'].dashboard = dashboardnum.id;
          self.checkResourceAccessEntries(conditionContainer.where);
          controller.get('resourceAccessConditionPromise').promise.then(function (finish) {
            modelQuery = self.store.query('dabkpi', conditionContainer);
            mixinSuper(controller, model, modelQuery);
            controller.set("dashboardid", dashboardnum.id);
            controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
          });
        });
      } else {
        modelQuery = self.store.query('dabkpi', {
          where: {
            dashboard: dashboardnum.id,
            isdelete: false
          },
          sort: 'id ASC'
        });
        mixinSuper(controller, model, modelQuery);
        controller.set("dashboardid", dashboardnum.id);
        controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      }
    },
    actions: {
      deleteKpi: function deleteKpi(entry) {
        var self = this;
        this.store.findRecord('dabkpi', entry.id).then(function (container) {
          self.controller.get('modelConArr').removeObject(entry);
          self.controller.get('modelCon').removeObject(entry);
          self.controller.set('modelCount', self.controller.get('modelConArr').get('length'));

          if (self.controller.get('modelCount') === 0) {
            self.controller.set('totalModel', 1);
          } else {
            self.controller.set('totalPage', Math.ceil(self.controller.get('modelCount') / 10));
          }

          if (self.controller.get('listPage') < self.controller.get('totalPage')) {
            var firstCount = self.controller.get('firstList');
            var postCount = self.controller.get('postLimit');
            self.controller.set('modelCon', self.controller.get('modelConArr').slice(firstCount, postCount));
          }

          container.set('isdelete', true);
          container.save();
        });
      },
      filterEntry: function filterEntry() {
        console.log('filterEntry');
        var self = this;
        var controller = this.get('controller'); // reload company list

        if (controller.get('filterOrganization')) {
          this.store.query('company', {
            where: {
              organizationnum: controller.get('filterOrganization'),
              isdelete: false
            },
            sort: 'companyname ASC'
          }).then(function (companies) {
            controller.set('companies', companies);
          });
        } else {
          controller.set('companies', null);
        }

        var model = controller.get('model');
        var modelQuery;
        var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
        var mixinSuper = this._super;
        self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
          controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
          var conditionContainer = {};
          conditionContainer['where'] = {};
          conditionContainer.sort = 'id ASC';
          conditionContainer.where.isdelete = false;
          conditionContainer['where'].dashboard = dashboardnum.id;

          if (controller.get('filterOrganization')) {
            conditionContainer['where'].organizationnum = controller.get('filterOrganization');
          }

          if (controller.get('filterCompany')) {
            conditionContainer['where'].companynum = controller.get('filterCompany');
          }

          self.checkResourceAccessEntries(conditionContainer.where);
          console.log('==conditionContainer==');
          console.log(conditionContainer);
          controller.get('resourceAccessConditionPromise').promise.then(function (finish) {
            modelQuery = self.store.query('dabkpi', conditionContainer);
            controller.set('totalPage', 1);
            controller.set('listPage', 1);
            controller.set('postLimit', 10);
            controller.set('firstList', 0);
            modelQuery.then(function (model) {
              controller.set("modelConArr", model.toArray());
              controller.set('modelCon', controller.get('modelConArr').slice(controller.get('firstList'), controller.get('postLimit')));
              controller.set('modelCount', controller.get('modelConArr').get('length'));

              if (controller.get('modelCount') === 0) {
                controller.set('totalModel', 1);
              } else {
                controller.set('totalPage', Math.ceil(controller.get('modelCount') / 10));
              }
            });
            controller.set("dashboardid", dashboardnum.id);
            controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
          });
        });
      }
    }
  });

  _exports.default = _default;
});