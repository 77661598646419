define("client/pods/components/report-generator/report-form/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      var _this = this;

      this._super();

      var entityQuery = this.store.query('entitymapping', {
        sort: 'id ASC'
      });
      var categoryQuery = this.store.query('genreportcategory', {
        isdelete: false,
        sort: 'id ASC'
      }); // call api

      entityQuery.then(function (entityContents) {
        categoryQuery.then(function (categoryContents) {
          _this.set('categoryContents', categoryContents.toArray());

          var result = Ember.A();
          entityContents.forEach(function (entityContent) {
            var hasCategory = result.findBy('category', entityContent.get('category'));
            var hasTablename = result.findBy('tablename', entityContent.get('tablename'));

            if (!hasCategory || !hasTablename) {
              result.addObject(Ember.Object.create({
                category: entityContent.get('category'),
                tablename: entityContent.get('tablename'),
                modellabel: entityContent.get('modellabel')
              }));
            }
          });

          _this.set('entityContents', result);
        });
      });
    },
    actions: {
      cancelEdit: function cancelEdit() {
        this.cancelEdit();
      },
      generateCompanyList: function generateCompanyList(organizationnum) {
        var _this2 = this;

        this.get('currentReport').set('organizationnum', organizationnum);
        this.set('companyList', Ember.A());

        if (this.get('currentReport').get('organizationnum').get('id')) {
          this.store.query('company', {
            where: {
              organizationnum: this.get('currentReport').get('organizationnum').get('id')
            }
          }).then(function (companies) {
            _this2.set('companyList', companies);
          });
        }
      },
      saveReport: function saveReport() {
        var _this3 = this;

        var self = this;
        var promises = Ember.A();
        var selectPromises = Ember.A();
        var filterQuery = [];
        var selectQuery = [];
        var joinQuery = [];
        var groupbyQuery = [];
        var groupingQuery = [];
        var orderbyQuery = [];
        var where;
        var select;
        var join;
        var groupby;
        var grouping;
        var sort;
        var fromtable;
        var report = this.get('currentReport');
        this.set('disableRun', true);
        this.get('selectContents').forEach(function (content) {
          return selectPromises.push(content.save());
        });
        report.set("genreportcategorynum", this.get('selectedCategory'));
        report.set("genreportsubcategorynum", this.get('selectedSubcategory'));
        Ember.RSVP.Promise.all(selectPromises).then(function (resolvedSelectContents) {
          //push all contents to promise array
          self.get('selectedTableContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('selectedFieldContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('joinContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('summarizeContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('groupbyContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('groupingContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('filterContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('sortContents').forEach(function (content) {
            return promises.push(content.save());
          });
          self.get('expressionContents').forEach(function (content) {
            return promises.push(content.save());
          }); //itterate configurations for whatsql

          self.get('selectedTableContents').forEach(function (table) {
            if (table.get('astable') === report.get('fromtable')) {
              fromtable = [{
                "fromtable": table.get('tablename'),
                'astable': report.get('fromtable'),
                'limit': report.get('limit')
              }];
            }
          });
          self.get('filterContents').forEach(function (filterContent) {
            //ok
            self.get('selectedFieldContents').forEach(function (field) {
              if (field.get('ascolumn') === filterContent.get('ascolumn')) {
                where = {
                  'where': field.get('astable') + '.' + field.get('columnname'),
                  'function': filterContent.get('function'),
                  'value': filterContent.get('value')
                };
              }
            });
            filterQuery.push(where);
          });
          resolvedSelectContents.forEach(function (selectContent) {
            self.get('selectedFieldContents').forEach(function (field) {
              if (field.get('ascolumn') === selectContent.get('ascolumn')) {
                select = {
                  'select': field.get('tablename') + '.' + field.get('columnname'),
                  'function': selectContent.get('method'),
                  'asname': selectContent.get('id'),
                  'astable': field.get('astable')
                };
              }
            });
            selectQuery.push(select);
          });
          self.get('joinContents').forEach(function (joinContent) {
            //ok
            var jointable;
            var fromid;
            var toid;
            self.get('selectedFieldContents').forEach(function (field) {
              if (field.get('ascolumn') === joinContent.get('fromascolumn')) {
                fromid = field.get('astable') + '.' + field.get('columnname');
              }
            });
            self.get('selectedFieldContents').forEach(function (field) {
              if (field.get('ascolumn') === joinContent.get('toascolumn')) {
                toid = field.get('astable') + '.' + field.get('columnname');
              }
            });
            self.get('selectedTableContents').forEach(function (table) {
              if (table.get('astable') === joinContent.get('jointable')) {
                jointable = table.get('tablename');
              }
            });
            join = {
              'type': joinContent.get('type'),
              'jointable': jointable,
              'fromid': fromid,
              'toid': toid,
              'astable': joinContent.get('jointable')
            };
            joinQuery.push(join);
          });
          self.get('groupbyContents').forEach(function (groupbyContent) {
            self.get('selectedFieldContents').forEach(function (field) {
              if (field.get('ascolumn') === groupbyContent.get('ascolumn')) {
                groupby = {
                  'groupby': field.get('astable') + '.' + field.get('columnname'),
                  'function': groupbyContent.get('function')
                };
              }
            });
            groupbyQuery.push(groupby);
          });
          self.get('groupingContents').forEach(function (groupingContent) {
            self.get('selectedFieldContents').forEach(function (field) {
              if (field.get('ascolumn') === groupingContent.get('ascolumn')) {
                grouping = {
                  'groupby': field.get('ascolumn'),
                  'level': groupingContent.get('level')
                };
              }
            });

            if (!Ember.isEmpty(grouping)) {
              groupingQuery.push(grouping);
            }
          });
          self.get('sortContents').forEach(function (sortContent) {
            self.get('selectedFieldContents').forEach(function (field) {
              if (field.get('ascolumn') === sortContent.get('ascolumn')) {
                sort = {
                  'orderby': field.get('astable') + '.' + field.get('columnname'),
                  'function': sortContent.get('sort')
                };
              }
            });
            orderbyQuery.push(sort);
          });
          var whatsql; //set whatsql

          if (self.get('currentReport.reporttype') === 'detailReport') {
            whatsql = {
              'selects': selectQuery,
              'from': fromtable,
              'joins': joinQuery,
              'wheres': filterQuery,
              'groupbys': groupbyQuery,
              'orderbys': orderbyQuery
            };
          } else {
            whatsql = {
              'selects': selectQuery,
              'from': fromtable,
              'joins': joinQuery,
              'wheres': filterQuery,
              'groupbys': groupbyQuery,
              'groupings': groupingQuery,
              'orderbys': orderbyQuery
            };
          }

          report.set("whatsql", JSON.stringify(whatsql));
          promises.push(report.save()); //resolve promises

          Ember.RSVP.Promise.all(promises).then(function () {
            //Fix the bug of blank genreportnum for those sub tables
            self.get('selectContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('selectedTableContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('selectedFieldContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('joinContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('summarizeContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('groupbyContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('groupingContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('filterContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('sortContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            self.get('expressionContents').forEach(function (content) {
              content.set('genreportnum.id', report.id);
              content.save();
            });
            var name = self.get('user.firstName') + '\ ' + self.get('user.lastName');
            var data = {
              "dabKpi": report.id,
              "generatedBy": name
            };
            var url = 'api/v1/genreports/callApi'; //call report-server via custom api in sails controller

            _jquery.default.ajax({
              url: url,
              type: 'POST',
              data: JSON.stringify(data),
              contentType: 'application/json',
              success: function success(result) {
                report.set("reporturl", result);
                report.save().then(function () {
                  self.set('disableRun', false);
                });
              },
              error: function error(xhr, status, _error) {
                console.log(_error);
              }
            });

            _this3.actionAfterSave(report);
          });
        });
      },
      duplicateReport: function duplicateReport() {
        var self = this;
        var selectedTableQuery = null;
        var selectedFieldQuery = null;
        var selectQuery = null;
        var joinQuery = null;
        var summarizeQuery = null;
        var groupbyQuery = null;
        var filterQuery = null;
        var sortQuery = null;
        var expressionQuery = null;
        var selectedTableContents = Ember.A();
        var selectedFieldContents = Ember.A();
        var selectContents = Ember.A();
        var joinContents = Ember.A();
        var summarizeContents = Ember.A();
        var groupbyContents = Ember.A();
        var filterContents = Ember.A();
        var sortContents = Ember.A();
        var expressionContents = Ember.A();
        var newTable;
        var newField;
        var newJoin;
        var newSelect;
        var newGroupby;
        var newFilter;
        var newSort;
        var newExpression;
        var newReport = this.store.createRecord('genreport', {
          reportname: this.get('currentReport').get('reportname') + ' (duplicate)',
          reportheader: this.get('currentReport').get('reportheader'),
          reportfooter: this.get('currentReport').get('reportfooter'),
          reportsummary: this.get('currentReport').get('reportsummary'),
          reporttype: this.get('currentReport').get('reporttype'),
          application: this.get('currentReport').get('application'),
          reportfolder: this.get('currentReport').get('reportfolder'),
          lastimportdate: this.get('currentReport').get('lastimportdate'),
          importedby: this.get('currentReport').get('importedby'),
          qbrcreatedby: this.get('currentReport').get('qbrcreatedby'),
          whatsql: this.get('currentReport').get('whatsql'),
          share: this.get('currentReport').get('share'),
          fromtable: this.get('currentReport').get('fromtable'),
          filetype: this.get('currentReport').get('filetype'),
          companynum: this.get('currentReport').get('companynum')
        });
        newReport.save().then(function (report) {
          selectedTableQuery = self.store.query('genreportconfigtable', {
            where: {
              id: -1
            }
          });
          selectedTableQuery.then(function (queryResult) {
            selectedTableContents = queryResult;
          });
          selectedFieldQuery = self.store.query('genreportconfigfield', {
            where: {
              id: -1
            }
          });
          selectedFieldQuery.then(function (queryResult) {
            selectedFieldContents = queryResult;
          });
          selectQuery = self.store.query('genreportconfigselect', {
            where: {
              id: -1
            }
          });
          selectQuery.then(function (queryResult) {
            selectContents = queryResult;
          });
          joinQuery = self.store.query('genreportconfigjoin', {
            where: {
              id: -1
            }
          });
          joinQuery.then(function (queryResult) {
            joinContents = queryResult;
          });
          summarizeQuery = self.store.query('genreportconfigsummarize', {
            where: {
              id: -1
            }
          });
          summarizeQuery.then(function (queryResult) {
            summarizeContents = queryResult;
          });
          groupbyQuery = self.store.query('genreportconfiggroupby', {
            where: {
              id: -1
            }
          });
          groupbyQuery.then(function (queryResult) {
            groupbyContents = queryResult;
          });
          filterQuery = self.store.query('genreportconfigfilter', {
            where: {
              id: -1
            }
          });
          filterQuery.then(function (queryResult) {
            filterContents = queryResult;
          });
          sortQuery = self.store.query('genreportconfigsort', {
            where: {
              id: -1
            }
          });
          sortQuery.then(function (queryResult) {
            sortContents = queryResult;
          });
          expressionQuery = self.store.query('genreportconfigexpression', {
            where: {
              id: -1
            }
          });
          expressionQuery.then(function (queryResult) {
            expressionContents = queryResult;
          });
          self.get('selectedTableContents').forEach(function (table) {
            newTable = self.store.createRecord('genreportconfigtable', {
              category: table.get('category'),
              tablename: table.get('tablename'),
              modellabel: table.get('modellabel'),
              genreportnum: report
            });
            newTable.save();
          });
          self.controller.get('selectedFieldContents').forEach(function (field) {
            newField = self.store.createRecord('genreportconfigfield', {
              tablename: field.get('tablename'),
              modellabel: field.get('modellabel'),
              columnname: field.get('columnname'),
              columnlabel: field.get('columnlabel'),
              genreportnum: report
            });
            newField.save();
          });
          self.controller.get('joinContents').forEach(function (join) {
            newJoin = self.store.createRecord('genreportconfigjoin', {
              jointable: join.get('jointable'),
              fromtable: join.get('fromtable'),
              fromid: join.get('fromid'),
              totable: join.get('totable'),
              toid: join.get('toid'),
              genreportnum: report
            });
            newJoin.save();
          });
          self.controller.get('selectContents').forEach(function (select) {
            newSelect = self.store.createRecord('genreportconfigselect', {
              tablename: select.get('tablename'),
              columnname: select.get('columnname'),
              label: select.get('label'),
              method: select.get('method'),
              genreportnum: report
            });
            newSelect.save();
          });
          self.controller.get('summarizeContents').forEach(function (summarize) {
            newSummarize = self.store.createRecord('genreportconfigsummarize', {
              function: summarize.get('function'),
              columnname: summarize.get('columnname'),
              genreportnum: report
            });
            newSummarize.save();
          });
          self.controller.get('groupbyContents').forEach(function (groupby) {
            newGroupby = self.store.createRecord('genreportconfiggroupby', {
              tablename: groupby.get('tablename'),
              columnname: groupby.get('columnname'),
              function: groupby.get('function'),
              genreportnum: report
            });
            newGroupby.save();
          });
          self.controller.get('filterContents').forEach(function (filter) {
            newFilter = self.store.createRecord('genreportconfigfilter', {
              tablename: filter.get('tablename'),
              columnname: filter.get('columnname'),
              function: filter.get('function'),
              value: filter.get('value'),
              genreportnum: report
            });
            newFilter.save();
          });
          self.controller.get('sortContents').forEach(function (sort) {
            newSort = self.store.createRecord('genreportconfigsort', {
              tablename: sort.get('tablename'),
              columnname: sort.get('columnname'),
              sort: sort.get('sort'),
              genreportnum: report
            });
            newSort.save();
          });
          self.controller.get('expressionContents').forEach(function (expression) {
            newExpression = self.store.createRecord('genreportconfigexpression', {
              name: expression.get('name'),
              value: expression.get('value'),
              datatype: expression.get('datatype'),
              category: expression.get('category'),
              genreportnum: report
            });
            newExpression.save();
          });
          self.controller.get('reportconfiglist').addObject(report);
        });
      },
      addTable: function addTable() {
        var _this4 = this;

        var self = this;
        this.get('selectedEntityCollection').forEach(function (entity) {
          var astable = entity.get('tablename') + '_' + _this4.get('currentReport.tablecount');

          var newTable = self.store.createRecord('genreportconfigtable', {
            category: entity.get('category'),
            tablename: entity.get('tablename'),
            modellabel: entity.get('modellabel'),
            astable: astable,
            genreportnum: _this4.get('currentReport')
          });

          _this4.set('currentReport.tablecount', _this4.get('currentReport.tablecount') + 1);

          _this4.get('selectedTableContents').addObject(newTable);
        });
        this.set('selectedEntityCollection', Ember.A());
      },
      clearSelectedTable: function clearSelectedTable() {
        this.set('selectedEntityCollection', Ember.A());
      },
      fetchField: function fetchField() {
        var _this5 = this;

        var field;
        this.set('availableFieldContents', Ember.A());
        this.get('selectedTableCollection').forEach(function (table) {
          _this5.store.query('entitymapping', {
            category: table.get('category'),
            tablename: table.get('tablename')
          }).then(function (results) {
            if (!Ember.isEmpty(results)) {
              results.forEach(function (result) {
                field = Ember.Object.create({
                  category: result.get('category'),
                  columnlabel: result.get('columnlabel'),
                  columnname: result.get('columnname'),
                  datatype: result.get('datatype'),
                  modellabel: result.get('modellabel'),
                  tablename: result.get('tablename'),
                  astable: table.get('astable')
                });

                _this5.get('availableFieldContents').pushObject(field);
              });
            }
          });
        });
        this.set('selectedTableCollection', Ember.A());
      },
      removeAllTable: function removeAllTable() {
        var _this6 = this;

        this.get('selectedTableContents').forEach(function (table) {
          try {
            _this6.store.findRecord('genreportconfigtable', table.id).then(function (container) {
              _this6.get('selectedTableContents').removeObject(table);

              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            _this6.get('selectedTableContents').setObjects(Ember.A());
          }
        });
        this.set('selectedTableCollection', Ember.A());
      },
      removeSelectedTable: function removeSelectedTable() {
        var _this7 = this;

        this.get('selectedTableCollection').forEach(function (table) {
          try {
            _this7.store.findRecord('genreportconfigtable', table.id).then(function (container) {
              _this7.get('selectedTableContents').removeObject(table);

              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            _this7.get('selectedTableContents').removeObject(table);
          }
        });
        this.set('selectedTableCollection', Ember.A());
      },
      selectAllTable: function selectAllTable() {
        this.set('selectedTableCollection', this.get('selectedTableContents'));
      },
      uncheckAllTable: function uncheckAllTable() {
        this.set('selectedTableCollection', Ember.A());
      },
      addAllField: function addAllField() {
        var _this8 = this;

        var newField;
        this.get('availableFieldContents').forEach(function (field) {
          newField = _this8.store.createRecord('genreportconfigfield', {
            tablename: field.get('tablename'),
            modellabel: field.get('modellabel'),
            columnname: field.get('columnname'),
            columnlabel: field.get('columnlabel'),
            astable: field.get('astable'),
            ascolumn: field.get('astable') + '.' + field.get('columnname'),
            genreportnum: _this8.get('currentReport')
          });

          if (!Ember.isEmpty(_this8.get('selectedFieldContents').get('firstObject'))) {
            var isExist = false;

            _this8.get('selectedFieldContents').forEach(function (field) {
              if (field.get('tablename') === newField.get('tablename') && field.get('columnname') === newField.get('columnname') && field.get('astable') === newField.get('astable')) {
                isExist = true;
              }
            });

            if (!isExist) {
              _this8.get('selectedFieldContents').addObject(newField);
            }
          } else {
            _this8.get('selectedFieldContents').addObject(newField);
          }
        });
        this.set('availableFieldCollection', Ember.A());
      },
      addSelectedField: function addSelectedField() {
        var _this9 = this;

        this.get('availableFieldCollection').forEach(function (field) {
          var newField = _this9.store.createRecord('genreportconfigfield', {
            tablename: field.get('tablename'),
            modellabel: field.get('modellabel'),
            columnname: field.get('columnname'),
            columnlabel: field.get('columnlabel'),
            astable: field.get('astable'),
            ascolumn: field.get('astable') + '.' + field.get('columnname'),
            genreportnum: _this9.get('currentReport')
          });

          if (!Ember.isEmpty(_this9.get('selectedFieldContents').get('firstObject'))) {
            var isExist = false;

            _this9.get('selectedFieldContents').forEach(function (field) {
              if (field.get('tablename') === newField.get('tablename') && field.get('columnname') === newField.get('columnname') && field.get('astable') === newField.get('astable')) {
                isExist = true;
              }
            });

            if (!isExist) {
              _this9.get('selectedFieldContents').addObject(newField);
            }
          } else {
            _this9.get('selectedFieldContents').addObject(newField);
          }

          _this9.set('availableFieldCollection', Ember.A());
        });
      },
      selectAllField: function selectAllField() {
        this.set('availableFieldCollection', this.get('availableFieldContents'));
      },
      uncheckAllField: function uncheckAllField() {
        this.set('availableFieldCollection', Ember.A());
      },
      removeAllField: function removeAllField() {
        var _this10 = this;

        this.get('selectedFieldContents').forEach(function (field) {
          _this10.get('selectedFieldCollection').addObject(field);
        });
        this.get('selectedFieldCollection').forEach(function (field) {
          try {
            _this10.store.findRecord('genreportconfigfield', field.id).then(function (container) {
              _this10.get('selectedFieldContents').removeObject(field);

              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            _this10.get('selectedFieldContents').removeObject(field);
          }
        });
        this.set('selectedFieldCollection', Ember.A());
      },
      removeSelectedField: function removeSelectedField() {
        var _this11 = this;

        this.get('selectedFieldCollection').forEach(function (field) {
          try {
            _this11.store.findRecord('genreportconfigfield', field.id).then(function (container) {
              _this11.get('selectedFieldContents').removeObject(field);

              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            _this11.get('selectedFieldContents').removeObject(field);
          }
        });
        this.set('selectedFieldCollection', Ember.A());
      },
      selectAllSelectedField: function selectAllSelectedField() {
        this.set('selectedFieldCollection', this.get('selectedFieldContents'));
      },
      uncheckAlSelectedlField: function uncheckAlSelectedlField() {
        this.set('selectedFieldCollection', Ember.A());
      },
      addNewSelect: function addNewSelect() {
        var newSelect = this.store.createRecord('genreportconfigselect', {
          method: 'NA',
          genreportnum: this.get('currentReport')
        });
        this.get('selectContents').addObject(newSelect);
      },
      deleteSelect: function deleteSelect(select) {
        var _this12 = this;

        try {
          this.store.findRecord('genreportconfigselect', select.id).then(function (container) {
            _this12.get('selectContents').removeObject(select);

            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.get('selectContents').removeObject(select);
        }
      },
      addNewJoin: function addNewJoin() {
        var newJoin = this.store.createRecord('genreportconfigjoin', {
          type: 'LEFT_OUTER_JOIN',
          genreportnum: this.get('currentReport')
        });
        this.get('joinContents').addObject(newJoin);
      },
      deleteJoin: function deleteJoin(join) {
        var _this13 = this;

        try {
          this.store.findRecord('genreportconfigjoin', join.id).then(function (container) {
            _this13.get('joinContents').removeObject(join);

            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.get('joinContents').removeObject(join);
        }
      },
      addNewGroupby: function addNewGroupby() {
        var newGroupby = this.store.createRecord('genreportconfiggroupby', {
          function: 'NA',
          genreportnum: this.get('currentReport')
        });
        this.get('groupbyContents').addObject(newGroupby);
      },
      deleteGroupby: function deleteGroupby(groupby) {
        var _this14 = this;

        try {
          this.store.findRecord('genreportconfiggroupby', groupby.id).then(function (container) {
            _this14.get('groupbyContents').removeObject(groupby);

            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.get('groupbyContents').removeObject(groupby);
        }
      },
      addNewGrouping: function addNewGrouping() {
        var newGrouping = this.store.createRecord('genreportconfiggrouping', {
          level: 1,
          genreportnum: this.get('currentReport')
        });
        this.get('groupingContents').addObject(newGrouping);

        if (this.get('groupingContents').get('length') === 3) {
          this.set('groupingLimit', true);
        }
      },
      deleteGrouping: function deleteGrouping(grouping) {
        var _this15 = this;

        try {
          this.store.findRecord('genreportconfiggrouping', grouping.id).then(function (container) {
            _this15.get('groupingContents').removeObject(grouping);

            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.get('groupingContents').removeObject(grouping);
        }

        this.set('groupingLimit', false);
      },
      addSummarizeValue: function addSummarizeValue() {
        var newSummarize = this.store.createRecord('genreportconfigsummarize', {
          function: this.get('summarizeFunction'),
          columnname: this.get('summarizeSelectedField'),
          genreportnum: this.get('currentReport')
        });
        this.get('summarizeContents').addObject(newSummarize);
      },
      clearSummarizeValue: function clearSummarizeValue() {
        this.set('summarizeFunction', 'total');
        this.set('summarizeSelectedField', null);
      },
      removeAllSummarize: function removeAllSummarize() {
        var self = this;
        this.get('summarizeContents').forEach(function (summarize) {
          try {
            self.store.findRecord('genreportconfigsummarize', summarize.id).then(function (container) {
              self.get('summarizeContents').removeObject(summarize);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.get('summarizeContents').removeObject(summarize);
          }
        });
      },
      removeSelectedSummarize: function removeSelectedSummarize() {
        var self = this;
        this.get('summarizeCollection').forEach(function (summarize) {
          try {
            self.store.findRecord('genreportconfigsummarize', summarize.id).then(function (container) {
              self.get('summarizeContents').removeObject(summarize);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.get('summarizeContents').removeObject(summarize);
          }
        });
      },
      addNewFilter: function addNewFilter() {
        var newFilter = this.store.createRecord('genreportconfigfilter', {
          function: 'EQUAL',
          genreportnum: this.get('currentReport')
        });
        this.get('filterContents').addObject(newFilter);
      },
      addNewSort: function addNewSort() {
        var newSort = this.store.createRecord('genreportconfigsort', {
          sort: 'ascending',
          genreportnum: this.get('currentReport')
        });
        this.get('sortContents').addObject(newSort);
      },
      removeAllFilter: function removeAllFilter() {
        var _this16 = this;

        this.get('filterContents').forEach(function (filter) {
          try {
            _this16.store.findRecord('genreportconfigfilter', filter.id).then(function (container) {
              _this16.get('filterContents').removeObject(filter);

              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            _this16.get('filterContents').removeObject(filter);
          }
        });
      },
      removeAllSort: function removeAllSort() {
        var _this17 = this;

        this.get('sortContents').forEach(function (sort) {
          try {
            _this17.store.findRecord('genreportconfigsort', sort.id).then(function (container) {
              _this17.get('sortContents').removeObject(sort);

              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            _this17.get('sortContents').removeObject(sort);
          }
        });
      },
      removeFilter: function removeFilter(filter) {
        var _this18 = this;

        try {
          this.store.findRecord('genreportconfigfilter', filter.id).then(function (container) {
            _this18.get('filterContents').removeObject(filter);

            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.get('filterContents').removeObject(filter);
        }
      },
      removeSort: function removeSort(sort) {
        var _this19 = this;

        try {
          this.store.findRecord('genreportconfigsort', sort.id).then(function (container) {
            _this19.get('sortContents').removeObject(sort);

            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.get('sortContents').removeObject(sort);
        }
      },
      addCalculateSelection: function addCalculateSelection() {
        var self = this;
        this.get('calculateSelectedField').forEach(function (field) {
          self.set('expressionValue', self.get('expressionValue').concat(field.get('tablename') + '.' + field.get('columnname') + ','));
        });
      },
      clearCalculateSelection: function clearCalculateSelection() {
        this.set('calculateSelectedField', Ember.A());
      },
      clearExpression: function clearExpression() {
        this.set('expressionValue', '');
        this.set('expressionName', null);
        this.set('expressionDatatype', 'string');
        this.set('expressionCategory', null);
      },
      addExpression: function addExpression() {
        var newExpression = this.store.createRecord('genreportconfigexpression', {
          value: this.get('expressionValue'),
          name: this.get('expressionName'),
          datatype: this.get('expressionDatatype'),
          category: this.get('expressionCategory'),
          genreportnum: this.get('currentReport')
        });
        this.get('expressionContents').addObject(newExpression);
      },
      removeAllExpression: function removeAllExpression() {
        var self = this;
        this.get('expressionContents').forEach(function (expression) {
          try {
            self.store.findRecord('genreportconfigexpression', expression.id).then(function (container) {
              self.get('expressionContents').removeObject(expression);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.get('expressionContents').removeObject(expression);
          }
        });
      },
      removeSelectedExpression: function removeSelectedExpression() {
        var self = this;
        this.get('expressionCollection').forEach(function (expression) {
          try {
            self.store.findRecord('genreportconfigexpression', expression.id).then(function (container) {
              self.get('expressionContents').removeObject(expression);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.get('expressionContents').removeObject(expression);
          }
        });
      },
      addParamCollection: function addParamCollection() {
        var self = this;
        var newParamcollection = this.store.createRecord('genreportparamcollection', {
          collectionname: 'new param collection',
          genreportnum: this.get('currentReport')
        });
        newParamcollection.save().then(function (paramcollection) {
          self.get('paramcollectionContents').addObject(paramcollection);
          self.set('selectedParamcollection', paramcollection);
          var paramQuery = self.store.query('genreportparam', {
            where: {
              genreportparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.set('paramContents', paramContents.toArray());
          });
        });
      },
      deleteParamCollection: function deleteParamCollection(paramcollection) {
        var self = this;
        this.store.findRecord('genreportparamcollection', paramcollection.id).then(function (container) {
          self.get('paramcollectionContents').removeObject(paramcollection);
          self.set('selectedParamcollection', null);
          container.set('isdelete', true);
          container.save();
        });
      },
      addParam: function addParam(paramcollection) {
        var self = this;
        var newParam = this.store.createRecord('genreportparam', {
          paramtype: "filter",
          filtertype: "EQUAL",
          genreportparamcollectionnum: paramcollection,
          genreportnum: self.get('currentReport')
        });
        self.get('paramContents').addObject(newParam);
      },
      deleteParam: function deleteParam(param) {
        var self = this;

        try {
          this.store.findRecord('genreportparam', param.id).then(function (container) {
            self.get('paramContents').removeObject(param);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.get('paramContents').removeObject(param);
        }
      },
      saveParam: function saveParam(paramcollection) {
        var self = this;
        var whatsql;
        var newParamresult;
        var promises = Ember.A();
        var params = this.get('paramContents');
        this.set('paramLoading', true);
        promises.push(this.get('selectedParamcollection').save());
        this.get('paramContents').forEach(function (content) {
          return promises.push(content.save());
        });

        if (this.get('selectedParamcollection')) {
          whatsql = JSON.parse(this.get('currentReport').get('whatsql'));
          params.forEach(function (param) {
            whatsql['wheres'].push({
              'where': param.get('genreportconfigselect').get('ascolumn'),
              'function': param.get('filtertype'),
              'value': param.get('filtervalue')
            });
          });
          newParamresult = this.store.createRecord('genreportparamresult', {
            whatsql: JSON.stringify(whatsql),
            genreportnum: this.get('currentReport'),
            genreportparamcollectionnum: this.get('selectedParamcollection')
          });
          Ember.RSVP.Promise.all(promises).then(function () {
            newParamresult.save().then(function (paramresult) {
              var name = self.get('user.firstName') + '\ ' + self.get('user.lastName');
              var data = {
                "dabKpi": self.get('currentReport.id'),
                "dabParam": paramresult.get('genreportparamcollectionnum.id'),
                "generatedBy": name,
                "genParamType": "Admin"
              };
              var url = 'api/v1/genreports/callApi';

              _jquery.default.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                success: function success(result) {
                  window.open(result);
                },
                error: function error(xhr, status, _error2) {
                  console.log(_error2);
                }
              });
            });
          });
        } else {}

        this.set('paramLoading', false);
      },
      setParamCollection: function setParamCollection(paramcollection) {
        var self = this;
        this.set('paramLoading', true);

        if (paramcollection) {
          var paramQuery = this.store.query('genreportparam', {
            where: {
              genreportparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.set('paramContents', paramContents.toArray());
            self.set('selectedParamcollection', paramcollection);
            self.set('paramLoading', false);
          });
        } else {
          this.set('selectedParamcollection', null);
          this.set('paramLoading', false);
        }
      },
      setCategory: function setCategory(category) {
        var self = this;

        if (category) {
          var subcategoryQuery = this.store.query('genreportsubcategory', {
            where: {
              genreportcategorynum: category.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          subcategoryQuery.then(function (subcategoryContents) {
            self.set('subcategoryContents', subcategoryContents.toArray());
            self.set('selectedCategory', category);
            self.set('subcategoryLoading', false);
          });
        } else {
          this.set('selectedCategory', null);
          this.set('selectedSubcategory', null);
          this.set('subcategorycontents', Ember.A());
        }
      },
      toggleCategoryInput: function toggleCategoryInput() {
        if (this.get('selectedCategory')) {
          this.set('categoryValue', this.get('selectedCategory').get('category'));
        } else {
          this.set('categoryValue', null);
        }

        this.set('categoryInput', !this.get('categoryInput'));
      },
      addCategory: function addCategory() {
        var self = this;
        var newCategory = this.store.createRecord('genreportcategory', {
          category: this.get('categoryValue'),
          isdelete: false
        });
        newCategory.save().then(function (category) {
          self.get('categoryContents').addObject(category);
          self.set('categoryInput', false);
          self.set('categoryValue', null);
        });
      },
      deleteCategory: function deleteCategory(category) {
        var self = this;
        var promises = Ember.A();
        this.store.query('genreport', {
          genreportcategorynum: category.id
        }).then(function (genreports) {
          genreports.forEach(function (genreport) {
            genreport.set('genreportcategorynum', null);
            genreport.set('genreportsubcategorynum', null);
            promises.push(genreport.save());
          });
          self.store.query('genreportsubcategory', {
            genreportcategorynum: category.id,
            isdelete: false
          }).then(function (genreportsubcategories) {
            genreportsubcategories.forEach(function (genreportsubcategory) {
              genreportsubcategory.set('isdelete', true);
              promises.push(genreportsubcategory.save());
            });
            self.store.findRecord('genreportcategory', category.id).then(function (container) {
              self.get('categoryContents').removeObject(category);
              self.set('selectedCategory', null);
              container.set('isdelete', true);
              promises.push(container.save());
              Ember.RSVP.Promise.all(promises);
            });
          });
        });
      },
      editCategory: function editCategory(category) {
        var self = this;
        this.store.findRecord('genreportcategory', category.id).then(function (container) {
          container.set('category', self.get('categoryValue'));
          container.save().then(function (result) {
            self.set('categoryInput', false);
            self.get('categoryContents').addObject(result);
          });
        });
      },
      setSubcategory: function setSubcategory(subcategory) {
        if (subcategory) {
          this.set('selectedSubcategory', subcategory);
        } else {
          this.set('selectedSubcategory', null);
        }
      },
      toggleSubcategoryInput: function toggleSubcategoryInput() {
        if (this.get('selectedSubcategory')) {
          this.set('subcategoryValue', this.get('selectedSubcategory').get('subcategory'));
        } else {
          this.set('subcategoryValue', null);
        }

        this.set('subcategoryInput', !this.get('subcategoryInput'));
      },
      addSubcategory: function addSubcategory() {
        var self = this;
        var newSubcategory = this.store.createRecord('genreportsubcategory', {
          subcategory: this.get('subcategoryValue'),
          genreportcategorynum: this.get('selectedCategory'),
          isdelete: false
        });
        newSubcategory.save().then(function (subcategory) {
          self.get('subcategoryContents').addObject(subcategory);
          self.set('subcategoryInput', false);
          self.set('subcategoryValue', null);
        });
      },
      editSubcategory: function editSubcategory(subcategory) {
        var self = this;
        this.store.findRecord('genreportsubcategory', subcategory.id).then(function (container) {
          container.set('subcategory', self.get('subcategoryValue'));
          container.save().then(function (result) {
            self.set('subcategoryInput', false);
            self.get('subcategoryContents').addObject(result);
          });
        });
      },
      deleteSubcategory: function deleteSubcategory(subcategory) {
        var self = this;
        var promises = Ember.A();
        this.store.query('genreport', {
          genreportsubcategorynum: subcategory.id
        }).then(function (genreports) {
          genreports.forEach(function (genreport) {
            genreport.set('genreportsubcategorynum', null);
            promises.push(genreport.save());
          });
          self.store.findRecord('genreportsubcategory', subcategory.id).then(function (genreportsubcategory) {
            self.get('subcategoryContents').removeObject(subcategory);
            self.set('selectedSubcategory', null);
            genreportsubcategory.set('isdelete', true);
            promises.push(genreportsubcategory.save());
            Ember.RSVP.Promise.all(promises);
          });
        });
      },
      saveviewersetting: function saveviewersetting() {
        var self = this;
        this.set('viewerissaving', true);
        var promises = Ember.A();
        this.get('viewerparamContents').forEach(function (content) {
          return promises.push(content.save());
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          self.set('viewerissaving', false);
        });
      },
      addviewerparam: function addviewerparam() {
        var self = this;
        var newParam = this.store.createRecord('genreportparamconfiguser', {
          genreportnum: this.get('currentReport')
        });
        newParam.save().then(function (result) {
          return self.get('viewerparamContents').addObject(result);
        });
      },
      deleteviewerparam: function deleteviewerparam(param) {
        var self = this;
        this.store.findRecord('genreportparamconfiguser', param.id).then(function (container) {
          container.set('isdelete', true);
          self.get('viewerparamContents').removeObject(container);
          container.save();
        });
      }
    }
  });

  _exports.default = _default;
});