define("client/mixins/w3office-forms/base-component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isShown: true,
    classNames: ['component-container', 'px-0'],
    classNameBindings: ['borderDanger:borderDanger', 'colmd12:col-md-12'],
    fieldLocalizationType: ['label', 'description'],
    borderDanger: Ember.computed('field.isInvalid', function () {
      return this.get('field').get('isInvalid');
    }),
    colmd12: false,

    /*
    * Set Unique Id for tooltip, value derived from component id
    */
    _tooltipId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-tooltip';
    }),

    /*
    *  Set Unique Id for Uploader
    */
    _uploaderId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-modal';
    }),

    /*
    * Drag Component on UI Builder
    * Required:
    * @param {Object} event - event object
    */
    dragStart: function dragStart(event) {
      if (this.get('authoring')) {
        this.set('formContainer.fieldHolder', this.get('field'));
      }
    },

    /*
    * Set Field to show component or not based on field config
    */
    init: function init() {
      this._super();

      if (this.get('authoring')) {
        this.set('colmd12', false);
      } else {
        this.set('colmd12', true); //call 'Auto Fill on-Load', one-time call on load

        if (this.get('field').get('callAutofillOnLoad')) {
          this.fieldActionGateway(this, {
            actionName: 'populateAutoFill',
            executeAt: 'onLoad'
          });
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // 7 = Datetime Picker
      // 21 = Custom Attribute
      if (this.get('authoring') === false && this.get('field.formcomponent.id') !== 7 && this.get('field.formcomponent.id') !== 21) {
        if (this.get("field.validationType") === 'password' && this.get("environment") === 'view') {
          this.set("containerValue", 'xxxxxxxx');
        } else {
          this.set('containerValue', this.get('field').get('entryContainer'));
        }
      }
    },
    actions: {
      /*
      * Commit field property change on UI Builder
      */
      commitFieldChange: function commitFieldChange() {
        if (this.get("field").get("hasDirtyAttributes")) {
          this.get('field').save();
        }
      },

      /*
      * Delete component from form
      */
      deleteComponent: function deleteComponent() {
        this.toggleDelete(this.get('field'));
      },

      /*
      * Show component move event UI
      */
      moveComponent: function moveComponent() {
        this.set('formContainer.fieldHolder', this.get('field'));
        (0, _jquery.default)('.drop-col-xs').css('height', '60px');
        (0, _jquery.default)('.drop-col-xs').css('border-style', 'dotted');
        (0, _jquery.default)('.orderDrop').css('height', '60px');
        (0, _jquery.default)('.orderDrop').css('border-style', 'dotted');
      },

      /*
      * Persist field label change
      */
      changeLabel: function changeLabel() {
        if (this.get("field").get("hasDirtyAttributes")) {
          this.get('field').save();
        }
      },

      /*
      * Pop up Field Properties Config
      */
      showProperties: function showProperties() {
        this.showProperties(this.get('field'));
      },

      /*
      *	Pop up Localization
      */
      showLocalization: function showLocalization(modelName, types, header, parentModelName, parent, columns) {
        this.showLocalization(modelName, types, header, parentModelName, parent, columns);
      }
    }
  });

  _exports.default = _default;
});