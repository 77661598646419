define("client/pods/components/w3office-forms/scroll-button/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)("html, body").scroll(function () {
        if ((0, _jquery.default)("#form-canvas").offset().top < 0) {
          (0, _jquery.default)('#scrollUpBtn').css('display', 'flex');
        } else {
          (0, _jquery.default)('#scrollUpBtn').css('display', 'none');
        }

        if (_this.get('bottomDivID')) {
          if ((0, _jquery.default)("#".concat(_this.get('bottomDivID'))).offset().top < (0, _jquery.default)(window).height()) {
            (0, _jquery.default)('#scrollDownBtn').css('display', 'none');
          } else {
            (0, _jquery.default)('#scrollDownBtn').css('display', 'flex');
          }
        }
      });
    },
    actions: {
      scrollToTop: function scrollToTop() {
        (0, _jquery.default)("html, body").animate({
          scrollTop: 0
        }, 200);
      },
      scrollToBottomDiv: function scrollToBottomDiv() {
        (0, _jquery.default)("html, body").animate({
          scrollTop: (0, _jquery.default)(document).height()
        }, 200);
      }
    }
  });

  _exports.default = _default;
});