define("client/pods/components/header-profile-picture/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r5m+QI4B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"src\",\"/images/ajax-loader.gif\"],[10,\"style\",\"width: 25px; height: 25px;\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"blobURL\"]]],null,{\"statements\":[[0,\"        \"],[7,\"img\",true],[11,\"id\",[29,[\"photo-\",[22,\"elementId\"]]]],[11,\"src\",[28,\"if\",[[24,[\"blobURL\"]],[24,[\"blobURL\"]],\"/images/blank-profile.png\"],null]],[10,\"alt\",\"profile image\"],[10,\"height\",\"30px\"],[10,\"class\",\"rounded-circle\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"img\",true],[10,\"src\",\"/images/blank-profile.png\"],[10,\"alt\",\"profile image\"],[10,\"height\",\"30px\"],[10,\"class\",\"rounded-circle\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/header-profile-picture/template.hbs"
    }
  });

  _exports.default = _default;
});