define("client/pods/components/menu-builder/menulogo-uploader/component", ["exports", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_authenticatedRouteMixin.default, {
    isUploading: false,
    dataURI: "",
    authObject: Ember.inject.service('session'),
    actions: {
      queuePhoto: function queuePhoto(file) {
        var self = this;
        this.set('isUploading', true);
        this.set('icon', file);
        file.read().then(function setPreviewUrl(dataURI) {
          _jquery.default.ajax({
            url: 'api/v1/applications/' + self.get('app').id,
            type: 'PATCH',
            data: {
              application: {
                appLogo: dataURI
              }
            },
            success: function success(result) {
              self.set('dataURI', dataURI);
            },
            error: function error(xhr, status, _error) {}
          });
        });
      }
    }
  });

  _exports.default = _default;
});