define("client/pods/components/dashboard-v2/chart-component/chartjs-chart/component", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import ChartDetailReport from 'client/mixins/dashboard/chart-detail-report';
  var _default = Ember.Component.extend({
    classNames: ['chartClass'],
    type: 'bar',
    // bar, horizontalBar, pie, doughnut, line, radar, polarArea
    datatitle: 'No Title',
    dynamictitle: 'No Title',
    drilldown: false,
    fill: false,
    beginAtZero: true,
    borderWidth: 1,
    //line can only accept one color
    line_backgroundColor: 'rgba(52,152,219,1)',
    line_borderColor: '#8e5ea2',
    line_fill: true,
    line_beginAtZero: false,
    line_borderWidth: 2,
    radar_polar_backgroundColor: 'rgba(0,0,200,0.2)',
    selected_properties_backgroundColor: null,
    selected_properties_borderColor: null,
    selected_properties_fill: false,
    selected_properties_beginAtZero: false,
    selected_properties_borderWidth: 1,
    isShowingModal: false,
    isShowingModalDetail: 'fade',
    drillable: false,
    hierarchy: 0,
    chartType: [],
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      var display_label_x = Ember.get(this, 'label_x') ? true : false;
      var display_label_y = Ember.get(this, 'label_y') ? true : false;
      Ember.set(this, 'backgroundColor', ['#abb8f1', '#69c2a3', '#24977c', '#8419a9', '#de9e6f', '#193de8', '#ced1fe', '#5ce597', '#5b7ca2', '#48c14c', '#e2f8ca', '#435cda', '#c8f5fd', '#e1512b', '#308e59', '#d87c3a', '#76e05f', '#948da7', '#092a56', '#40e56f', '#5ac24e', '#0a528e', '#f41794', '#c6c2fd', '#5fca74', '#faa85d', '#619f9e', '#ef0464', '#d93883', '#67b269', '#9c3c80', '#c4aa07', '#1588cf', '#72294e', '#75d6bf', '#03213a', '#90f0b2', '#30930d', '#a84450', '#a1c22b', '#63a61d', '#382c78', '#84bc6a', '#8e39c1', '#47244f', '#aa9331', '#c73eea', '#cde94a', '#f6e93e', '#751c9c']);
      Ember.set(this, 'borderColor', ['#abb8f1', '#69c2a3', '#24977c', '#8419a9', '#de9e6f', '#193de8', '#ced1fe', '#5ce597', '#5b7ca2', '#48c14c', '#e2f8ca', '#435cda', '#c8f5fd', '#e1512b', '#308e59', '#d87c3a', '#76e05f', '#948da7', '#092a56', '#40e56f', '#5ac24e', '#0a528e', '#f41794', '#c6c2fd', '#5fca74', '#faa85d', '#619f9e', '#ef0464', '#d93883', '#67b269', '#9c3c80', '#c4aa07', '#1588cf', '#72294e', '#75d6bf', '#03213a', '#90f0b2', '#30930d', '#a84450', '#a1c22b', '#63a61d', '#382c78', '#84bc6a', '#8e39c1', '#47244f', '#aa9331', '#c73eea', '#cde94a', '#f6e93e', '#751c9c']);
      var chartType = [{
        label: 'Bar',
        value: 'bar'
      }, {
        label: 'Bar (Horizontal)',
        value: 'horizontalBar'
      }, {
        label: 'Pie',
        value: 'pie'
      }, {
        label: 'Doughnut',
        value: 'doughnut'
      }, {
        label: 'Line',
        value: 'line'
      }, {
        label: 'Radar',
        value: 'radar'
      }, {
        label: 'Polar Area',
        value: 'polarArea'
      }];
      Ember.set(this, 'chartType', chartType);
      var title = this.title;
      var extratitle = this.extratitle;
      Ember.set(this, 'datatitle', "".concat(title));
      Ember.set(this, 'extratitle', extratitle);
      this.dynamicTitle();
      Ember.set(this, 'dataset', this.dataset);
      Ember.set(this, 'type', this.type); //set(this, 'column', this.column);
      //set(this, 'userinfo', this.userinfo);
      //set(this, 'invoiceParam.column', this.column);
      //console.log(this.type);

      if (this.type === 'line') {
        Ember.set(this, 'selected_properties_backgroundColor', this.line_backgroundColor);
        Ember.set(this, 'selected_properties_borderColor', this.line_borderColor);
        Ember.set(this, 'selected_properties_fill', this.line_fill);
        Ember.set(this, 'selected_properties_beginAtZero', this.line_beginAtZero);
        Ember.set(this, 'selected_properties_borderWidth', this.line_borderWidth);
      } else {
        Ember.set(this, 'selected_properties_backgroundColor', this.backgroundColor);
        Ember.set(this, 'selected_properties_borderColor', this.borderColor);
        Ember.set(this, 'selected_properties_fill', this.fill);
        Ember.set(this, 'selected_properties_beginAtZero', this.beginAtZero);
        Ember.set(this, 'selected_properties_borderWidth', this.borderWidth);
      }

      var datalabel = Ember.get(this, 'dataset').map(function (data) {
        return data.label; // return as ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
      });
      var datavalue = Ember.get(this, 'dataset').map(function (data) {
        return data.value; // return as [12, 19, 3, 5, 2, 3]
      });
      var datacolor = Ember.get(this, 'dataset').map(function (data) {
        return data.color; // return as [12, 19, 3, 5, 2, 3]
      });
      Ember.set(this, "dataset_".concat(Ember.get(this, 'hierarchy'), "_label"), datalabel);
      Ember.set(this, "dataset_".concat(Ember.get(this, 'hierarchy'), "_value"), datavalue);
      Ember.set(this, "dataset_".concat(Ember.get(this, 'hierarchy'), "_dataset"), Ember.get(this, 'dataset'));

      if (Ember.get(this, 'use_risk_color') === true) {
        Ember.set(this, 'selected_properties_backgroundColor', datacolor);
        Ember.set(this, 'selected_properties_borderColor', datacolor);
      }

      if (this.type === 'radar' || this.type === 'polarArea') {
        Ember.set(this, 'selected_properties_fill', true);
        Ember.set(this, 'selected_properties_backgroundColor', Ember.get(this, 'radar_polar_backgroundColor'));
      }

      if (Ember.get(this, 'is_drilldown') === true) {
        Ember.set(this, 'drillable', true);
      }

      var data = {
        labels: Ember.get(this, "dataset_".concat(Ember.get(this, 'hierarchy'), "_label")),
        datasets: [{
          //label: get(this, 'dynamictitle'),
          label: 'Score',
          datatype: Ember.get(this, 'type'),
          data: Ember.get(this, "dataset_".concat(Ember.get(this, 'hierarchy'), "_value")),
          backgroundColor: Ember.get(this, 'selected_properties_backgroundColor'),
          borderColor: Ember.get(this, 'selected_properties_borderColor'),
          borderWidth: Ember.get(this, 'selected_properties_borderWidth'),
          fill: Ember.get(this, 'selected_properties_fill')
        }
        /*{
        	label: 'test 2',
        	datatype: get(this, 'type'),
        	data: get(this, `dataset_${get(this, 'hierarchy')}_value`),
        	backgroundColor: get(this, 'selected_properties_backgroundColor'),
        	borderColor: get(this, 'selected_properties_borderColor'),
        	borderWidth: get(this, 'selected_properties_borderWidth'),
        	fill: get(this, 'selected_properties_fill')
        }*/
        ]
      };
      var scales = {};
      var display_legend = false;

      if (this.type === 'bar' || this.type === 'horizontalBar' || this.type === 'line') {
        display_legend = false;
        scales = {
          xAxes: [{
            barThickness: 'flex',
            ticks: {
              beginAtZero: Ember.get(this, 'selected_properties_beginAtZero')
            },
            scaleLabel: {
              display: display_label_x,
              labelString: Ember.get(this, 'label_x'),
              fontStyle: 'bold'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: display_label_y,
              labelString: Ember.get(this, 'label_y'),
              fontStyle: 'bold'
            }
          }]
        };
      } else {
        display_legend = true;
        scales = {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }]
        };
      }

      var options = {
        legend: {
          display: display_legend
        },
        title: {
          display: false
        },
        scales: scales,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },

        /*  
          tooltips: {
         		callbacks: {
         		    label: function(tooltipItems, data) {
         		        let tooltip_label = data.datasets[tooltipItems.datasetIndex].label,
         		        	tooltip_raw	= data.datasets[tooltipItems.datasetIndex].data[0],
         		        	data_type = data.datasets[tooltipItems.datasetIndex].datatype,
         		        	tooltip_all = ``;
         		        //show percentage for pie and doughnut
         		    	let percdataset = data.datasets[tooltipItems.datasetIndex],
         		    		total = percdataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
        					return previousValue + currentValue;
        				}),
        				currentValue = percdataset.data[tooltipItems.index],
        				percentage = Math.floor(((currentValue/total) * 100)+0.5)+"%";   
          		        if (data_type == 'pie' || data_type == 'doughnut' ){
         		        	tooltip_all = `${tooltip_label}: ${tooltip_raw}, ${percentage}`;
         		        } else {
         		        	tooltip_all = `${tooltip_label}: ${tooltip_raw}`;
         		        }
         		        return tooltip_all;
         		    }
         		}
          },
        */
        responsive: true,
        onClick: function onClick(event, array) {
          //Do nothing if user click on the blank space
          if (Ember.isEmpty(array)) {
            return;
          }

          Ember.set(self, 'clickEvent', event);
          Ember.set(self, 'clickArray', array);
          self.openModal(self); //$('#chartModal').modal('show');
        }
      };
      Ember.set(this, 'data', data);
      Ember.set(this, 'options', options); //console.log('debug this');
      //console.log(this);
    },
    dynamicTitle: function dynamicTitle() {
      if (Ember.get(this, "extratitle.title".concat(Ember.get(this, 'hierarchy') + 1)) !== undefined) {
        if (Ember.get(this, "extratitle.title".concat(Ember.get(this, 'hierarchy') + 1, ".align")) === 'left') {
          Ember.set(this, 'dynamictitle', "".concat(Ember.get(this, "extratitle.title".concat(Ember.get(this, 'hierarchy') + 1, ".label"))).concat(Ember.get(this, 'datatitle')));
        } else {
          Ember.set(this, 'dynamictitle', "".concat(Ember.get(this, 'datatitle')).concat(Ember.get(this, "extratitle.title".concat(Ember.get(this, 'hierarchy') + 1, ".label"))));
        }
      } else {
        Ember.set(this, 'dynamictitle', "".concat(Ember.get(this, 'datatitle')));
      }
    },

    /*
    	drillDown : function() {
    		//let selected_label = this.get('clickArray')[0]._model.label;
    		let selected_index = this.get('clickArray')[0]._index;
    
    		let selected_dataset = this.get(`dataset_${this.get('hierarchy')}_dataset`)[selected_index];
    
    		if (!isEmpty(selected_dataset.series)) {
    			this.set('hierarchy', this.get('hierarchy') + 1);
    			this.set(`dataset_${this.get('hierarchy')}_dataset`, selected_dataset.series);
    
    			this.dynamicTitle();
    
    			let datalabel = this.get(`dataset_${this.get('hierarchy')}_dataset`).map(function(dataset){
    				return dataset.label;	// return as ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
    			});
    
    			let datavalue = this.get(`dataset_${this.get('hierarchy')}_dataset`).map(function(dataset){
    				return dataset.value;	// return as [12, 19, 3, 5, 2, 3]
    			});
    
    			this.set(`dataset_${this.get('hierarchy')}_label`, datalabel);
    			this.set(`dataset_${this.get('hierarchy')}_value`, datavalue);
    
    			let data = {
    				labels: this.get(`dataset_${this.get('hierarchy')}_label`),
    				datasets: [{
    					label: this.get('dynamictitle'),
    					data: this.get(`dataset_${this.get('hierarchy')}_value`),
    					backgroundColor: this.get('selected_properties_backgroundColor'),
    					borderColor: this.get('selected_properties_borderColor'),
    					borderWidth: this.get('selected_properties_borderWidth'),
    					fill: this.get('selected_properties_fill')
    				}]
    			};
    
    			//only need this to refresh the chart data
    			set(this, 'data', data);
    		}
    
    		this.closeModal(this);
    	},
    
    	drillUp : function() {
    
    		if (this.get('hierarchy') > 0) {
    
    			this.set('hierarchy', this.get('hierarchy') - 1);
    
    			this.dynamicTitle();
    
    			let data = {
    				labels: this.get(`dataset_${this.get('hierarchy')}_label`),
    				datasets: [{
    					label: this.get('dynamictitle'),
    					data: this.get(`dataset_${this.get('hierarchy')}_value`),
    					backgroundColor: this.get('selected_properties_backgroundColor'),
    					borderColor: this.get('selected_properties_borderColor'),
    					borderWidth: this.get('selected_properties_borderWidth'),
    					fill: this.get('selected_properties_fill')
    				}]
    			};
    
    			this.set('data', data);
    		}
    
    		this.closeModal(this);
    	},
    
    	cancel : function() {
    		this.closeModal(this);
    	},
    
    	detailReport : function() {
    
    		let column = this.get('column');
    		let searchColumn = column[`column${this.get('hierarchy')+1}`].label;
    
    		let selected_index = this.get('clickArray')[0]._index;
    		let selected_dataset = this.get(`dataset_${this.get('hierarchy')}_dataset`)[selected_index];
    		let searchInput = selected_dataset.label;
    
    		set(this, 'invoiceParam.searchColumn', searchColumn);
    		set(this, 'invoiceParam.searchInput', searchInput);
    
    		this.getInvoice(this);
    		this.closeModal(this);
    		this.openDetail();
    	},
    
    	openModal: function(chart) {
    		set(chart, 'isShowingModal', true);
    	},
    
    	closeModal: function(chart) {
    		set(chart, 'isShowingModal', false);
    	},
    */
    actions: {
      switchChart: function switchChart(id, selectedChart) {
        this.switchChart(id, selectedChart);
      }
    }
  });

  _exports.default = _default;
});