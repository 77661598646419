define("client/models/processevent", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    eventId: (0, _attr.default)('string'),
    label: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    subType: (0, _attr.default)('string'),
    imgUrl: (0, _attr.default)('string'),
    eventIndex: (0, _attr.default)('number'),
    timerDuration: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    emailUser: (0, _attr.default)('number'),
    emailGroup: (0, _attr.default)('number'),
    emailSubject: (0, _attr.default)('string'),
    emailContent: (0, _attr.default)('string'),
    validateSender: (0, _attr.default)('string'),
    validateSubject: (0, _attr.default)('string'),
    validateBody: (0, _attr.default)('string'),
    height: (0, _attr.default)('number'),
    width: (0, _attr.default)('number'),
    posX: (0, _attr.default)('number'),
    posY: (0, _attr.default)('number'),
    processversion: (0, _relationships.belongsTo)('processversion', {
      async: true
    }),
    numericID: Ember.computed('id', function () {
      var id = this.get('id');

      if (id) {
        return +id;
      }
    })
  });

  _exports.default = _default;
});