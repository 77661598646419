define("client/pods/components/general-component/radio-button/component", ["exports", "client/pods/components/general-component/base-toplevel-component", "jquery"], function (_exports, _baseToplevelComponent, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* source:
    https://github.com/stonecircle/ember-radio-buttons
  */
  var _default = _baseToplevelComponent.default.extend({
    tagName: 'input',
    type: 'radio',
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],
    primaryClassName: 'radio-button',
    // used for BaseComponent
    value: null,
    checked: null,
    htmlChecked: Ember.computed('checked', function () {
      return this.get('checked');
    }),
    _setCheckedProp: function _setCheckedProp() {
      if (!(0, _jquery.default)()) {
        return;
      }

      (0, _jquery.default)().prop('checked', this.get('htmlChecked'));
    },
    didInsertElement: function didInsertElement() {
      Ember.run.once(this, '_setCheckedProp');
    }
  });

  _exports.default = _default;
});