define("client/pods/users-roles/role-manager/app/create-app-access/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gadUPwej",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-8 mt-3 px-0\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"Create App Access\"],[9],[0,\"\\n    \"],[7,\"hr\",true],[8],[9],[0,\"\\n    \"],[15,\"users-roles/role-manager/app/form-app-access\",[]],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-9\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"disableSubmit\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"btn btn-success float-right\"],[10,\"disabled\",\"\"],[8],[0,\"\\n                    Submit\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"div\",false],[12,\"class\",\"btn btn-success float-right\"],[3,\"action\",[[23,0,[]],\"createAppAccess\",[24,[\"model\"]]]],[8],[0,\"\\n                    Submit\\n                \"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-secondary float-right mx-2\",\"users-roles.role-manager.app.app-access\"]],{\"statements\":[[0,\"                    Cancel\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "client/pods/users-roles/role-manager/app/create-app-access/template.hbs"
    }
  });

  _exports.default = _default;
});