define("client/pods/dashboard-builder/dabkpi/config/edit/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(param) {
      return this.store.findRecord("dabkpiconfig", param.dabkpiconfig_id);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      var field = {
        entryContainer: ''
      };
      controller.set("field", Ember.Object.create(field));
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      var dabkpinum = this.modelFor('dashboard-builder.dabkpi.config');
      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set("dabkpiid", dabkpinum.id);
      controller.set("dabkpititle", dabkpinum.get('title'));
      controller.set("dabkpiconfigid", model.id);
      controller.set("dabkpiconfigtitle", model.get('xColumnName'));
      controller.set("dabkpitype", dabkpinum.get('type'));
      controller.set('paramContents', null);
      controller.set('selectedParamcollection', null);
      controller.set('paramLoading', false);
      controller.set('paramQuery', this.store.query('dabkpiparam', {
        dabkpinum: -1
      }));
      controller.get('paramQuery').then(function (paramContents) {
        controller.set('paramContents', paramContents.toArray());
      });
      controller.set('paramcollectionQuery', self.store.query('dabkpiparamcollection', {
        where: {
          dabkpinum: model.get('dabkpi.id'),
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.get('paramcollectionQuery').then(function (paramcollectionContents) {
        controller.set('paramcollectionContents', paramcollectionContents.toArray());
      });

      if (controller.get("dabkpitype") === "timechart") {
        controller.set("isTimechart", true);
      } else {
        controller.set("isTimechart", false);
      }

      controller.set('model', model);
      var objDabkpiConfig = Ember.Object.create({
        id: model.id,
        category: model.get('category'),
        fromTable: model.get('fromTable'),
        interval: model.get('interval'),
        dabkpi: model.get('dabkpi'),
        limit: model.get('limit')
      });
      controller.set('objDabkpiConfig', objDabkpiConfig);
      controller.set('conditionQuery', this.store.query('dabkpiconfigcondition', {
        dabkpiconfignum: model.id
      }));
      controller.set('selectionQuery', this.store.query('dabkpiconfigselection', {
        dabkpiconfignum: model.id
      }));
      controller.set('joinQuery', this.store.query('dabkpiconfigjoin', {
        dabkpiconfignum: model.id
      }));
      controller.set('groupbyQuery', this.store.query('dabkpiconfiggroupby', {
        dabkpiconfignum: model.id
      }));
      controller.get('conditionQuery').then(function (conditionContents) {
        controller.set('conditionContents', conditionContents.toArray());
      });
      controller.get('selectionQuery').then(function (selectionContents) {
        controller.set('selectionContents', selectionContents.toArray());
      });
      controller.get('joinQuery').then(function (joinContents) {
        controller.set('joinContents', joinContents.toArray());
      });
      controller.get('groupbyQuery').then(function (groupbyContents) {
        controller.set('groupbyContents', groupbyContents.toArray());
      }); // code for autocomplete table/column/category

      var nameSpace = this.store.adapterFor('application').get('namespace');
      var url = nameSpace;

      _jquery.default.ajax({
        url: url + '/entitymappings/getCategory',
        type: 'POST',
        success: function success(result) {
          controller.set('entitymapQuery', result);
        },
        error: function error(xhr, status, _error) {
          console.log(_error);
        }
      });
    },
    actions: {
      updateDabkpiConfig: function updateDabkpiConfig() {
        var self = this;
        var obj = this.controller.get('objDabkpiConfig');
        var up = this.get("currentModel");
        var promises = Ember.A();
        up.set("category", obj.get("category"));
        up.set("fromTable", obj.get("fromTable"));
        up.set("interval", obj.get("interval"));
        up.set("dabkpi", obj.get("dabkpi"));
        up.set("limit", obj.get("limit"));
        this.controller.get("conditionContents").forEach(function (content) {
          return promises.push(content.save());
        });
        this.controller.get("selectionContents").forEach(function (content) {
          return promises.push(content.save());
        });
        this.controller.get("joinContents").forEach(function (content) {
          return promises.push(content.save());
        });
        this.controller.get("groupbyContents").forEach(function (content) {
          return promises.push(content.save());
        });
        this.controller.get('paramcollectionContents').forEach(function (content) {
          return promises.push(content.save());
        });
        this.controller.get('paramContents').forEach(function (content) {
          return promises.push(content.save());
        });
        var whereQuery = [];
        var selectQuery = [];
        var joinQuery = [];
        var groupbyQuery = [];
        var fromtable = [{
          "fromtable": up.get('fromTable'),
          'astable': up.get('fromTable'),
          'limit': up.get('limit')
        }];
        this.controller.get('conditionContents').forEach(function (conditionContent) {
          var where = {
            'where': conditionContent.get('tableName') + '.' + conditionContent.get('columnName'),
            'function': conditionContent.get('condition'),
            'value': conditionContent.get('value')
          };
          whereQuery.push(where);
        });
        this.controller.get('selectionContents').forEach(function (selectionContent) {
          var select = {
            'select': selectionContent.get('tableName') + '.' + selectionContent.get('xColumnName'),
            'function': selectionContent.get('method'),
            'asname': selectionContent.get('method'),
            'astable': selectionContent.get('tableName')
          };
          selectQuery.push(select);
        });
        this.controller.get('joinContents').forEach(function (joinContent) {
          var join = {
            'type': joinContent.get('type'),
            'jointable': joinContent.get('jointable'),
            'fromid': joinContent.get('fromtable') + '.' + joinContent.get('fromid'),
            'toid': joinContent.get('totable') + '.' + joinContent.get('toid'),
            'astable': joinContent.get('jointable')
          };
          joinQuery.push(join);
        });
        this.controller.get('groupbyContents').forEach(function (groupbyContent) {
          var groupby = {
            'groupby': groupbyContent.get('tableName') + '.' + groupbyContent.get('columnName'),
            'function': groupbyContent.get('function')
          };
          groupbyQuery.push(groupby);
        });
        var whatsql = {
          'selects': selectQuery,
          'from': fromtable,
          'joins': joinQuery,
          'wheres': whereQuery,
          'groupbys': groupbyQuery
        };
        up.set("whatsql", JSON.stringify(whatsql));
        promises.push(up.save());
        this.store.findRecord("dabkpi", up.get('dabkpi.id')).then(function (dabkpi) {
          dabkpi.set('whatsql', JSON.stringify(whatsql));
          promises.push(dabkpi.save());
          Ember.RSVP.Promise.all(promises).then(function () {
            return self.transitionTo('dashboard-builder.dabkpi.config');
          });
        });
      },
      addParamcollection: function addParamcollection() {
        var self = this;
        var newParamcollection = this.store.createRecord('dabkpiparamcollection', {
          collectionname: 'new param collection',
          dabkpinum: self.controller.get('model').get('dabkpi')
        });
        newParamcollection.save().then(function (paramcollection) {
          self.controller.get('paramcollectionContents').addObject(paramcollection);
          self.controller.set('selectedParamcollection', paramcollection);
          var paramQuery = self.store.query('dabkpiparam', {
            where: {
              dabkpiparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.controller.set('paramContents', paramContents.toArray());
          });
        });
      },
      deleteParamcollection: function deleteParamcollection(paramcollection) {
        var self = this;
        self.store.findRecord('dabkpiparamcollection', paramcollection.id).then(function (container) {
          self.controller.get('paramcollectionContents').removeObject(paramcollection);
          self.controller.set('selectedParamcollection', null);
          container.set('isdelete', true);
          container.save();
        });
      },
      addParam: function addParam(paramcollection) {
        var self = this;
        var newParam = this.store.createRecord('dabkpiparam', {
          paramtype: "filter",
          filtertype: "EQUAL",
          dabkpiparamcollectionnum: paramcollection,
          dabkpinum: self.controller.get('model').get('dabkpi')
        });
        self.controller.get('paramContents').addObject(newParam);
      },
      deleteParam: function deleteParam(param) {
        var self = this;

        try {
          self.store.findRecord('dabkpiparam', param.id).then(function (container) {
            self.controller.get('paramContents').removeObject(param);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('paramContents').removeObject(param);
        }
      },
      setParamcollection: function setParamcollection(paramcollection) {
        var self = this;

        if (paramcollection) {
          self.controller.set('paramLoading', true);
          var paramQuery = self.store.query('dabkpiparam', {
            where: {
              dabkpiparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.controller.set('paramContents', paramContents.toArray());
            self.controller.set('selectedParamcollection', paramcollection);
            self.controller.set('paramLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});