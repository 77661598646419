define("client/pods/components/w3office-forms/logic-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.actionList = [{
        name: 'Show',
        value: 'show'
      }, {
        name: 'Disable',
        value: 'disable'
      }, {
        name: 'Copy',
        value: 'copy'
      }];
      this.comparatorList = [{
        name: 'Equal To',
        value: '='
      }, {
        name: 'Not',
        value: '!'
      }, {
        name: 'Greater Than',
        value: '>'
      }, {
        name: 'Less Than',
        value: '<'
      }];
    },
    actions: {
      saveProperties: function saveProperties() {
        var _this = this;

        this.get('logic').save().then(function () {
          _this.reloadList();

          (0, _jquery.default)('#logic-props').modal('hide');
        });
      },
      discardChanges: function discardChanges() {
        if (Ember.isEmpty(this.get('logic').id)) {
          //unload if data is uncommited
          this.get('logic').unloadRecord();
        } else {
          //rollback if data is persisted
          this.get('logic').rollbackAttributes();
        }

        (0, _jquery.default)('#logic-props').modal('hide');
      }
    }
  });

  _exports.default = _default;
});