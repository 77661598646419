define("client/pods/components/report-generator/viewer-setting/value-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEdit: false,
    paramvalue: '',
    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      this.store.query('genreportparamconfiguservalue', {
        genreportparamconfigusernum: this.get('param.id'),
        isdelete: false,
        sort: 'id ASC'
      }).then(function (results) {
        self.set('valueContents', results.toArray());
      });
    },
    actions: {
      toggleInput: function toggleInput() {
        if (this.get('isEdit') === false) {
          if (this.get('param.value')) {
            this.set('paramvalue', this.get('param.value.value'));
          } else {
            this.set('paramvalue', null);
          }
        } else {
          this.set('paramvalue', null);
        }

        this.set('isEdit', !this.get('isEdit'));
      },
      addValue: function addValue() {
        var self = this;
        var newValue = this.store.createRecord('genreportparamconfiguservalue', {
          value: this.get('paramvalue'),
          genreportparamconfigusernum: this.get('param'),
          genreportconfigselectnum: this.get('param.genreportconfigselectnum'),
          genreportnum: this.get('param.genreportnum')
        });
        newValue.save().then(function (result) {
          self.get('valueContents').addObject(result);
          self.set('isEdit', !self.get('isEdit'));
        });
      },
      saveValue: function saveValue(value) {
        var self = this;
        this.store.findRecord('genreportparamconfiguservalue', value.id).then(function (container) {
          container.set('value', self.get('paramvalue'));
          container.save().then(function (result) {
            self.get('valueContents').addObject(result);
            self.set('isEdit', !self.get('isEdit'));
          });
        });
      },
      removeValue: function removeValue(value) {
        var self = this;
        this.store.findRecord('genreportparamconfiguservalue', value.id).then(function (container) {
          container.set('isdelete', true);
          container.save().then(function (result) {
            self.get('valueContents').removeObject(result);
            self.set('param.value', null);
          });
        });
      }
    }
  });

  _exports.default = _default;
});