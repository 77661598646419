define("client/models/ol-source-vector", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    ready: function ready() {
      var _this = this;

      // console.debug('ol-source-vector:ready:call');
      this.__updating = false;
      var source = this.get('source');

      if (!source) {
        return;
      }

      source.getFeatures().forEach(function (f) {
        return _this.addFeature(f);
      });
      source.on('addfeature', function (e) {
        if (!_this.__updating) {
          var feature = e.feature;

          _this.addFeature(feature);
        }
      });
      source.on('removefeature', function (e) {
        if (!_this.__updating) {
          var feature = e.feature;

          _this.get('features').removeObject(feature._emberRecord);
        }
      });
      this.get('features').addArrayObserver({
        arrayWillChange: function arrayWillChange(observedObj, start, removeCount) {
          if (removeCount === 1) {
            _this.__updating = true;

            var feature = _this.get('features').objectAt(start).get('feature');

            if (source.getFeatures().indexOf(feature) !== -1) {
              source.removeFeature(feature);
            }

            _this.__updating = false;
          }
        },
        arrayDidChange: function arrayDidChange(observedObj, start, removeCount, addCount) {
          if (addCount === 1) {
            _this.__updating = true;

            var feature = _this.get('features').objectAt(start).get('feature');

            if (source.getFeatures().indexOf(feature) === -1) {
              source.addFeature(feature);
            }

            _this.__updating = false;
          }
        }
      }); // console.debug('ol-source-vector:ready:return');
    },
    addFeature: function addFeature(feature) {
      // console.debug('ol-source-vector:addFeature:call');
      var r = this.store.createRecord('ol-feature', {
        feature: feature,
        geometry: feature.getGeometry()
      });
      feature._emberRecord = r;
      this.get('features').pushObject(r); // f.on('ready', () => this.get('features').pushObject(f))
      // console.debug('ol-source-vector:addFeature:return');

      return r;
    },
    features: hasMany('ol-feature')
  });

  _exports.default = _default;
});