define("client/models/fieldfilter", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    filterColumn: (0, _attr.default)('string'),
    filterValue: (0, _attr.default)('string'),
    filterType: (0, _attr.default)('string'),
    filterField: (0, _relationships.belongsTo)('field', {
      async: true
    }),
    field: (0, _relationships.belongsTo)('field', {
      async: true,
      inverse: 'fieldfilters'
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});