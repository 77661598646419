define("client/pods/components/dashboard-v2/chart-component/shout-line/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backgroundColor: "rgba(52,152,219,1)",
    label: [1, 2, 3, 4, 5, 6, 7],
    bg_color: Ember.computed('selected', function () {
      var bg_color = this.selected === true ? '#cacfd2' : 'transparent';
      return bg_color;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      console.log('this.dataset');
      console.log(this.dataset);
      var datalabel = this.dataset.map(function (data) {
        return data.label; // return as ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
      });
      var datavalue = this.dataset.map(function (data) {
        return data.value; // return as [12, 19, 3, 5, 2, 3]
      });
      var data = {
        labels: datalabel,
        datasets: [{
          fill: true,
          backgroundColor: this.backgroundColor,
          data: datavalue
        }]
      }; //default options for line chart

      var options = {
        legend: {
          display: false
        }
      };
      this.set('type', 'line');
      this.set('data', data);
      this.set('options', options);
    },
    actions: {
      changeSelected: function changeSelected(chart) {
        this.changeSelected(chart.id);
      }
    }
  });

  _exports.default = _default;
});