define("client/mixins/serializablejson", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serialize: function serialize() {
      var result = {};

      for (var key in $.extend(true, {}, this)) {
        // Skip these
        if (key === 'isInstance' || key === 'isDestroyed' || key === 'isDestroying' || key === 'concatenatedProperties' || typeof this[key] === 'function') {
          continue;
        }

        result[key] = this[key];
      }

      return result;
    }
  });

  _exports.default = _default;
});