define("client/pods/components/dashboard-v2/chart-component/shout-line/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tpotZnxV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"style\",[29,[\"background-color: \",[22,\"bg_color\"]]]],[3,\"action\",[[23,0,[]],\"changeSelected\",[23,0,[]]]],[8],[0,\"\\n\"],[1,[22,\"title\"],false],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[1,[22,\"value\"],false],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[1,[28,\"ember-chart\",null,[[\"type\",\"data\",\"options\"],[[24,[\"type\"]],[24,[\"data\"]],[24,[\"options\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dashboard-v2/chart-component/shout-line/template.hbs"
    }
  });

  _exports.default = _default;
});