define("client/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    locationid: _emberData.default.attr("string"),
    locationtype: _emberData.default.attr("string"),
    locationname: _emberData.default.attr("string"),
    isactive: _emberData.default.attr("boolean"),
    isdelete: _emberData.default.attr("boolean"),
    hasaddress: _emberData.default.attr("boolean"),
    locationimagepath: _emberData.default.attr('string'),
    locationimagetype: _emberData.default.attr('string'),
    filladdress: _emberData.default.attr("string"),
    //relationships	
    sitenum: _emberData.default.belongsTo("site", {
      async: true
    }),
    locationaddressnum: _emberData.default.belongsTo("address", {
      async: true
    }),
    locationparentnum: _emberData.default.belongsTo("location", {
      inverse: "children",
      async: true
    }),
    children: _emberData.default.hasMany("location", {
      inverse: "locationparentnum",
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    locationimage: _emberData.default.belongsTo('locationimage'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});