define("client/pods/w3office-forms/form/logic/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    model: function model() {
      return this.modelFor('w3office-forms/form');
    },
    setupController: function setupController(controller, model) {
      controller.set("currentUser", this.store.findRecord("user", this.get("authObject.data.authenticated.user.id"))); //Set controller properties

      controller.set('flag', true);
      controller.set('showEntriesLogic', true);
      controller.set('logicList', null);
      controller.set('logicListIsLoading', true);
      /*set TRUE when querying fieldlogic, set FALSE after it done*/

      controller.set('showEntriesLogicContent', true);
      controller.set('logicContentList', []);
      controller.set('logicContentListIsLoading', true);
      controller.set('fieldGroupContentContainer', []);
      controller.set('fieldFilterContainer', []);
      controller.set('form', model);
      controller.set('fieldContainer', Ember.A());
      controller.set('restrictAction', true);
      controller.set('fieldLogicMasterSortColumn', 'affectedField');
      controller.set('fieldLogicMasterSortOrder', 'ASC');
      controller.set('logicMaster', null);
      controller.set('logicMasterQuery', this.store.query('fieldlogicmaster', {
        where: {
          form: model.get('id')
        }
      })).then(function (TempFieldlogicmasters) {
        var sortedFieldLogicMaster = TempFieldlogicmasters.toArray().sort(function (a, b) {
          if (a.affectedField > b.affectedField) {
            return 1;
          } else {
            return -1;
          }
        });
        controller.set('logicMasterList', sortedFieldLogicMaster);
        controller.set('restrictAction', false);
      });
      controller.set('actionList', [{
        name: 'Change Field Property',
        value: 'changefieldproperty'
      }, {
        name: 'Copy',
        value: 'copy'
      }, {
        name: 'Disable',
        value: 'disable'
      }, {
        name: 'Show',
        value: 'show'
      }]);
      controller.set('runAtList', [{
        name: 'On Load',
        value: 'onLoad'
      }, {
        name: 'On Change',
        value: 'onChange'
      }]);
      controller.set('propertyNameList', [{
        name: 'API URL',
        value: 'apiURL'
      }, {
        name: 'Field Group Content',
        value: 'fieldgroupcontent'
      }, {
        name: 'On Load API',
        value: 'onLoadAPI'
      }, {
        name: 'On Load & Real-Time API',
        value: 'onLoadRealTimeAPI'
      }, {
        name: 'Real-Time API',
        value: 'realTimeAPI'
      }, {
        name: 'Required',
        value: 'required'
      }]);
      controller.set('logicRuleList', [{
        name: 'AND',
        value: 'AND'
      }, {
        name: 'API',
        value: 'API'
      }, {
        name: 'OR',
        value: 'OR'
      }, {
        name: 'XOR',
        value: 'XOR'
      }]);
      controller.set('triggerList', [{
        name: 'Field',
        value: 'field'
      }, {
        name: 'Form Button',
        value: 'form-button'
      }, {
        name: 'Form Property',
        value: 'form-property'
      }, {
        name: 'Initializer',
        value: 'initializer'
      }, {
        name: 'Parent Property',
        value: 'parent-property'
      }, {
        name: 'Query Params',
        value: 'query-params'
      }]);
      this.store.query('field', {
        where: {
          form: controller.get('form').get('id')
        },
        sort: [{
          label: 'ASC'
        }]
      }).then(function (fields) {
        controller.set('fieldContainer', fields);
        controller.set('buttonContainer', fields.filter(function (field) {
          return field.get('formcomponent.id') === '16';
        }));
      });
    },
    actions: {
      createLogicMaster: function createLogicMaster() {
        this.controller.set('logicMaster', this.store.createRecord('fieldlogicmaster', {
          logicRule: 'AND',
          action: 'show',
          runAt: 'onLoad',
          form: this.get('currentModel'),
          createdBy: this.controller.get("currentUser")
        }));
        this.controller.set('logicList', []);
        this.controller.set('logicContentList', []);
        this.controller.set('logicListIsLoading', false);
        this.controller.set('fieldGroupContentContainer', []);
        this.controller.set('fieldFilterContainer', []);
        this.controller.set('showEntriesLogicContent', true);
        (0, _jquery.default)('#logic-master-props').modal({
          backdrop: 'static',
          keyboard: false
        });
      },
      editLogicMaster: function editLogicMaster(logic) {
        this.controller.set('logicMaster', logic);
        this.controller.set('flag', !this.controller.get('flag'));
        this.controller.set('logicListIsLoading', true);
        this.controller.set('showEntriesLogic', true);
        this.controller.set('showEntriesLogicContent', true);

        if (logic.get('affectedField').get('id')) {
          this.send("populateFieldGroupContent", logic.get('affectedField').get('id'));
        }

        (0, _jquery.default)('#logic-master-props').modal({
          backdrop: 'static',
          keyboard: false
        });
      },
      deleteLogicMaster: function deleteLogicMaster(logicMaster) {
        // need to enhance this with delete fieldlogic & fieldlogiccontent
        var self = this;
        this.controller.set('restrictAction', true);

        if (logicMaster.currentState.stateName === 'root.loaded.created.uncommitted') {
          //unload if data is uncommitted
          logicMaster.unloadRecord();
        } else {
          var logicMasterID = logicMaster.get('id');
          this.controller.get('logicMasterList').removeObject(logicMaster); //delete if data is persisted

          logicMaster.deleteRecord();
          logicMaster.save().then(function () {
            self.store.query('fieldlogic', {
              fieldLogicMaster: logicMasterID
            }).then(function (fieldlogics) {
              fieldlogics.forEach(function (fieldlogic) {
                fieldlogic.deleteRecord();
                fieldlogic.save();
              });
            });
            self.store.query('fieldlogiccontent', {
              fieldLogicMaster: logicMasterID
            }).then(function (fieldlogiccontents) {
              fieldlogiccontents.forEach(function (fieldlogiccontent) {
                fieldlogiccontent.deleteRecord();
                fieldlogiccontent.save();
              });
            });
          });
        }

        this.controller.set('restrictAction', false);
      },
      persistLogicMaster: function persistLogicMaster(logicMaster) {
        var controller = this.controller;

        if (logicMaster.get('logicRule') !== 'API') {
          logicMaster.set('logicRuleAPI', null);
        }

        if (logicMaster.get('action') !== 'changefieldproperty') {
          logicMaster.set('propertyName', null);

          if (logicMaster.get('propertyName') !== 'apiURL' || logicMaster.get('propertyName') !== 'onLoadAPI' || logicMaster.get('propertyName') !== 'realTimeAPI') {
            logicMaster.set('additionalValue', null);
          }
        }

        logicMaster.set("updatedBy", controller.get("currentUser"));

        if (logicMaster.currentState.stateName === 'root.loaded.created.uncommitted') {
          logicMaster.save().then(function (newLogicMaster) {
            controller.get('logicMasterList').addObject(newLogicMaster);
            controller.get('logicList').forEach(function (logic) {
              logic.set('fieldLogicMaster', newLogicMaster);
              logic.set("updatedBy", controller.get("currentUser"));
              logic.save();
            });

            if (newLogicMaster.get('propertyName') === 'fieldgroupcontent' || logicMaster.get('propertyName') === 'apiURL') {
              controller.get('logicContentList').forEach(function (logicContent) {
                logicContent.set('fieldLogicMaster', newLogicMaster);
                logicContent.set("updatedBy", controller.get("currentUser"));
                logicContent.save();
              });
            }

            (0, _jquery.default)('#logic-master-props').modal('hide');
          });
        } else {
          var arrPromise = [];
          logicMaster.save();
          controller.get('logicList').forEach(function (logic) {
            logic.set('fieldLogicMaster', logicMaster);
            logic.set("updatedBy", controller.get("currentUser"));
            arrPromise.push(logic.save());
          });

          if (logicMaster.get('propertyName') === 'fieldgroupcontent' || logicMaster.get('propertyName') === 'apiURL') {
            controller.get('logicContentList').forEach(function (logicContent) {
              logicContent.set('fieldLogicMaster', logicMaster);
              logicContent.set("updatedBy", controller.get("currentUser"));
              arrPromise.push(logicContent.save());
            });
          }

          Ember.RSVP.all(arrPromise).then(function () {
            (0, _jquery.default)('#logic-master-props').modal('hide');
          });
        }
      },
      populateFieldLogic: function populateFieldLogic(logicMasterID) {
        var controller = this.controller;
        this.store.query('fieldlogic', {
          fieldLogicMaster: logicMasterID
        }).then(function (resFieldLogic) {
          controller.set('logicList', resFieldLogic.toArray());
          controller.set('logicListIsLoading', false);
        });
      },
      createLogic: function createLogic(component) {
        var newLogic = this.store.createRecord('fieldlogic', {
          createdBy: this.controller.get("currentUser")
        });
        this.controller.get('logicList').addObject(newLogic);
        component.set('logic', newLogic);
      },
      populateFieldLogicContent: function populateFieldLogicContent(logicMasterID) {
        var controller = this.controller;
        this.store.query('fieldlogiccontent', {
          fieldLogicMaster: logicMasterID
        }).then(function (resFieldLogicContent) {
          controller.set('logicContentList', resFieldLogicContent.toArray());
          controller.set('logicContentListIsLoading', false);
        });
      },
      populateFieldGroupContent: function populateFieldGroupContent(fieldID) {
        var controller = this.controller;

        if (this.controller.get('logicMaster').get('propertyName') === 'fieldgroupcontent') {
          this.store.query('fieldgroupcontent', {
            field: fieldID
          }).then(function (resFieldGroupContent) {
            controller.set('fieldGroupContentContainer', resFieldGroupContent.toArray());
          });
        } else if (this.controller.get('logicMaster').get('propertyName') === 'apiURL') {
          this.store.query('fieldfilter', {
            field: fieldID
          }).then(function (resFieldFilter) {
            controller.set('fieldFilterContainer', resFieldFilter.toArray());
          });
        }
      },
      createLogicContent: function createLogicContent(component) {
        var newLogicContent = this.store.createRecord('fieldlogiccontent', {
          createdBy: this.controller.get("currentUser")
        });
        this.controller.get('logicContentList').addObject(newLogicContent);
        component.set('logicContent', newLogicContent);
      },
      sortFieldLogicMaster: function sortFieldLogicMaster(column) {
        var controller = this.get('controller');

        if (controller.get('fieldLogicMasterSortColumn') == column) {
          if (controller.get('fieldLogicMasterSortOrder') == 'ASC') {
            controller.set('fieldLogicMasterSortOrder', 'DESC');
          } else {
            controller.set('fieldLogicMasterSortOrder', 'ASC');
          }
        } else {
          controller.set('fieldLogicMasterSortColumn', column);
          controller.set('fieldLogicMasterSortOrder', 'ASC');
        }

        var sortedFieldLogicMaster = controller.get('logicMasterList').sort(function (a, b) {
          if (a[controller.get('fieldLogicMasterSortColumn')] > b[controller.get('fieldLogicMasterSortColumn')]) {
            if (controller.get('fieldLogicMasterSortOrder') == 'ASC') {
              return 1;
            } else {
              return -1;
            }
          } else if (a[controller.get('fieldLogicMasterSortColumn')] == b[controller.get('fieldLogicMasterSortColumn')]) {
            return 0;
          } else {
            if (controller.get('fieldLogicMasterSortOrder') == 'ASC') {
              return -1;
            } else {
              return 1;
            }
          }
        });
        controller.set('logicMasterList', '');
        controller.set('logicMasterList', sortedFieldLogicMaster);
      }
    }
  });

  _exports.default = _default;
});