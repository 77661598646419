define("client/pods/components/w3o-users-roles/form-user/base-form-user", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        regexEmail: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
        regexUsername: /^[a-zA-Z0-9_.]{1,15}$/,

        /*
            Regex for password
            at least 8 characters
            at least contains 1 uppercase, 1 lowercase, 1 number, and 1 special character( !"#$%&'()*+,-.:;<=>/?@\^[]_`{}|~ no space currently) 
        */
        regexPass: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~])[a-zA-Z\d!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~]{8,}$/
      });
    },
    validateFirstName: function validateFirstName(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#firstNameForm").addClass("has-error");
        (0, _jquery.default)("#glyphFirstName").removeClass("d-none");
        (0, _jquery.default)("#firstNameIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#firstNameForm").removeClass('has-error');
        (0, _jquery.default)('#glyphFirstName').addClass('d-none');
        (0, _jquery.default)('#firstNameIsEmpty').addClass('d-none');
      }

      return 0;
    },
    validateLastName: function validateLastName(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#lastNameForm").addClass("has-error");
        (0, _jquery.default)("#glyphLastName").removeClass("d-none");
        (0, _jquery.default)("#lastNameIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#lastNameForm").removeClass('has-error');
        (0, _jquery.default)('#glyphLastName').addClass('d-none');
        (0, _jquery.default)('#lastNameIsEmpty').addClass('d-none');
      }

      return 0;
    },
    validateEmail: function validateEmail(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#emailForm").addClass("has-error");
        (0, _jquery.default)("#glyphEmail").removeClass("d-none");
        (0, _jquery.default)("#emailIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#emailForm").removeClass('has-error');
        (0, _jquery.default)('#glyphEmail').addClass('d-none');
        (0, _jquery.default)('#emailIsEmpty').addClass('d-none');

        if (!this.get("regexEmail").test(param)) {
          (0, _jquery.default)("#emailForm").addClass("has-error");
          (0, _jquery.default)("#glyphEmail").removeClass("d-none");
          (0, _jquery.default)("#emailRegexError").removeClass("d-none");
          return 1;
        } else {
          (0, _jquery.default)("#emailForm").removeClass('has-error');
          (0, _jquery.default)('#glyphEmail').addClass('d-none');
          (0, _jquery.default)("#emailRegexError").addClass("d-none");
        }

        return 0;
      }
    },
    validateUsername: function validateUsername(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#usernameForm").addClass("has-error");
        (0, _jquery.default)("#glyphUsername").removeClass("d-none");
        (0, _jquery.default)("#usernameIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#usernameForm").removeClass('has-error');
        (0, _jquery.default)('#glyphUsername').addClass('d-none');
        (0, _jquery.default)('#usernameIsEmpty').addClass('d-none');

        if (!this.get("regexUsername").test(param)) {
          (0, _jquery.default)("#usernameForm").addClass("has-error");
          (0, _jquery.default)("#glyphUsername").removeClass("d-none");
          (0, _jquery.default)("#usernameRegexError").removeClass("d-none");
        } else {
          (0, _jquery.default)("#usernameForm").removeClass('has-error');
          (0, _jquery.default)('#glyphUsername').addClass('d-none');
          (0, _jquery.default)("#usernameRegexError").addClass("d-none");
        }
      }
    },
    validateCountry: function validateCountry(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#countryForm").addClass("has-error");
        (0, _jquery.default)("#glyphCountry").removeClass("d-none");
        (0, _jquery.default)("#countryIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#countryForm").removeClass('has-error');
        (0, _jquery.default)('#glyphCountry').addClass("d-none");
        (0, _jquery.default)('#countryIsEmpty').addClass("d-none");
        return 0;
      }
    },
    validatePhone: function validatePhone(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#phoneForm").addClass("has-error");
        (0, _jquery.default)("#glyphPhone").removeClass("d-none");
        (0, _jquery.default)("#phoneIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#phoneForm").removeClass('has-error');
        (0, _jquery.default)('#glyphPhone').addClass('d-none');
        (0, _jquery.default)('#phoneIsEmpty').addClass('d-none');
        return 0;
      }
    },
    validatePostalCode: function validatePostalCode(param) {
      var value = param.value,
          postalcodeformat = param.postalcodeformat,
          regexPostalCode = param.regexPostalCode;

      if (Ember.isEmpty(value)) {
        (0, _jquery.default)("#postalCodeForm").addClass("has-error");
        (0, _jquery.default)("#glyphPostalCode").removeClass("d-none");
        (0, _jquery.default)("#postalCodeIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#postalCodeForm").removeClass('has-error');
        (0, _jquery.default)('#glyphPostalCode').addClass("d-none");
        (0, _jquery.default)('#postalCodeIsEmpty').addClass("d-none");

        if (postalcodeformat && !regexPostalCode.test(value)) {
          (0, _jquery.default)("#postalCodeForm").addClass("has-error");
          (0, _jquery.default)("#glyphPostalCode").removeClass("d-none");
          (0, _jquery.default)("#postalCodeRegexError").removeClass("d-none");
          return 1;
        } else {
          (0, _jquery.default)("#postalCodeRegexError").addClass("d-none");
          (0, _jquery.default)('#glyphPostalCode').addClass("d-none");
          (0, _jquery.default)("#postalCodeForm").removeClass('has-error');
        }

        return 0;
      }
    },
    validateAddress: function validateAddress(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#addressForm").addClass("has-error");
        (0, _jquery.default)("#glyphAddress").removeClass("d-none");
        (0, _jquery.default)("#addressIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#addressForm").removeClass('has-error');
        (0, _jquery.default)('#glyphAddress').addClass("d-none");
        (0, _jquery.default)('#addressIsEmpty').addClass("d-none");
        return 0;
      }
    },
    validateAddress1: function validateAddress1(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#address1Form").addClass("has-error");
        (0, _jquery.default)("#glyphAddress1").removeClass("d-none");
        (0, _jquery.default)("#address1IsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#address1Form").removeClass('has-error');
        (0, _jquery.default)('#glyphAddress1').addClass("d-none");
        (0, _jquery.default)('#address1IsEmpty').addClass("d-none");
        return 0;
      }
    },
    validateCity: function validateCity(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#cityForm").addClass("has-error");
        (0, _jquery.default)("#glyphCity").removeClass("d-none");
        (0, _jquery.default)("#cityIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#cityForm").removeClass('has-error');
        (0, _jquery.default)('#glyphCity').addClass("d-none");
        (0, _jquery.default)('#cityIsEmpty').addClass("d-none");
        return 0;
      }
    },
    validateState: function validateState(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#stateForm").addClass("has-error");
        (0, _jquery.default)("#glyphState").removeClass("d-none");
        (0, _jquery.default)("#stateIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#stateForm").removeClass('has-error');
        (0, _jquery.default)('#glyphState').addClass("d-none");
        (0, _jquery.default)('#stateIsEmpty').addClass("d-none");
        return 0;
      }
    },
    validatePassword: function validatePassword(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#passwordForm").addClass("has-error");
        (0, _jquery.default)("#glyphPassword").removeClass("d-none");
        (0, _jquery.default)("#passwordIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#passwordForm").removeClass('has-error');
        (0, _jquery.default)('#glyphPassword').addClass("d-none");
        (0, _jquery.default)('#passwordIsEmpty').addClass("d-none");

        if (!this.get("regexPass").test(param)) {
          (0, _jquery.default)("#passwordForm").addClass('has-error');
          (0, _jquery.default)('#glyphPassword').removeClass("d-none");
          (0, _jquery.default)('#passwordValidation').removeClass("d-none");
          return 1;
        } else {
          (0, _jquery.default)("#passwordForm").removeClass('has-error');
          (0, _jquery.default)('#glyphPassword').addClass("d-none");
          (0, _jquery.default)('#passwordValidation').addClass("d-none");
        }

        return 0;
      }
    },
    validatePasswordRepeat: function validatePasswordRepeat(param) {
      var value = param.value,
          password = param.password;

      if (Ember.isEmpty(value)) {
        (0, _jquery.default)("#passwordRepeatForm").addClass("has-error");
        (0, _jquery.default)("#glyphPasswordRepeat").removeClass("d-none");
        (0, _jquery.default)("#passwordRepeatIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#passwordRepeatForm").removeClass('has-error');
        (0, _jquery.default)('#glyphPasswordRepeat').addClass('d-none');
        (0, _jquery.default)('#passwordRepeatIsEmpty').addClass('d-none');

        if (value !== password) {
          (0, _jquery.default)("#passwordRepeatForm").addClass("has-error");
          (0, _jquery.default)("#glyphPasswordRepeat").removeClass("d-none");
          (0, _jquery.default)('#passwordIsNotSame').removeClass('d-none');
          return 1;
        } else {
          (0, _jquery.default)("#passwordRepeatForm").removeClass("has-error");
          (0, _jquery.default)("#glyphPasswordRepeat").addClass("d-none");
          (0, _jquery.default)('#passwordIsNotSame').addClass('d-none');
        }

        return 0;
      }
    },
    validateSecretQuestion: function validateSecretQuestion(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#questionForm").addClass("has-error");
        (0, _jquery.default)("#glyphQuestion").removeClass("d-none");
        (0, _jquery.default)("#questionIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#questionForm").removeClass('has-error');
        (0, _jquery.default)('#glyphQuestion').addClass('d-none');
        (0, _jquery.default)('#questionIsEmpty').addClass('d-none');
        return 0;
      }
    },
    validateAnswer: function validateAnswer(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#answerForm").addClass("has-error");
        (0, _jquery.default)("#glyphAnswer").removeClass("d-none");
        (0, _jquery.default)("#answerIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#answerForm").removeClass('has-error');
        (0, _jquery.default)('#glyphAnswer').addClass('d-none');
        (0, _jquery.default)('#answerIsEmpty').addClass('d-none');
        return 0;
      }
    },
    validateAnswerRepeat: function validateAnswerRepeat(param) {
      var value = param.value,
          answer = param.answer;

      if (Ember.isEmpty(value)) {
        (0, _jquery.default)("#answerRepeatForm").addClass("has-error");
        (0, _jquery.default)("#glyphAnswerRepeat").removeClass("d-none");
        (0, _jquery.default)("#answerRepeatIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#answerRepeatForm").removeClass('has-error');
        (0, _jquery.default)('#glyphAnswerRepeat').addClass('d-none');
        (0, _jquery.default)('#answerRepeatIsEmpty').addClass('d-none');

        if (value !== answer) {
          (0, _jquery.default)("#answerRepeatForm").addClass("has-error");
          (0, _jquery.default)("#glyphAnswerRepeat").removeClass("d-none");
          (0, _jquery.default)('#answerIsNotSame').removeClass('d-none');
          return 1;
        } else {
          (0, _jquery.default)("#answerRepeatForm").removeClass('has-error');
          (0, _jquery.default)('#glyphAnswerRepeat').addClass('d-none');
          (0, _jquery.default)('#answerIsNotSame').addClass('d-none');
        }

        return 0;
      }
    },
    validateGroup: function validateGroup(param) {
      if (Ember.isEmpty(param)) {
        (0, _jquery.default)("#groupForm").addClass("has-error");
        (0, _jquery.default)("#glyphGroup").removeClass("d-none");
        (0, _jquery.default)("#groupIsEmpty").removeClass("d-none");
        return 1;
      } else {
        (0, _jquery.default)("#groupForm").removeClass('has-error');
        (0, _jquery.default)('#glyphGroup').addClass('d-none');
        (0, _jquery.default)('#groupIsEmpty').addClass('d-none');
        return 0;
      }
    }
  });

  _exports.default = _default;
});