define("client/pods/components/report-generator/content-tab/report-groupby/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deleteGroupby: function deleteGroupby(groupby) {
        this.deleteGroupby(groupby);
      }
    }
  });

  _exports.default = _default;
});