define("client/pods/users-roles/role-manager/app/assign/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/role-manager-app", "axios"], function (_exports, _jquery, _checkUserRole, _roleManagerApp, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _roleManagerApp.default, {
    appId: '',
    roleId: '',
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render({
        outlet: 'assign'
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)("#assign").addClass('active');
        (0, _jquery.default)("#assign-tab").addClass('active show');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('limitModal', 10);
      controller.set('sortBy', '"groupName"');
      controller.set('sortOrder', 'ASC');
      controller.set('sortByModal', '"groupName"');
      controller.set('sortOrderModal', 'ASC');
      controller.set('searchInput', '');
      controller.set('searchColumn', '"groupName"');
      controller.set('searchInputModal', '');
      controller.set('searchColumnModal', '"groupName"');
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)('#access').removeClass('active');
        (0, _jquery.default)('#role').removeClass('active');
        (0, _jquery.default)('#route').removeClass('active');
        (0, _jquery.default)('#app-access').removeClass('active');
        (0, _jquery.default)('#access-tab').removeClass('active show');
        (0, _jquery.default)('#role-tab').removeClass('active show');
        (0, _jquery.default)('#route-tab').removeClass('active show');
        (0, _jquery.default)('#app-access-tab').removeClass('active show');
      });
    },
    getGroupFromSelectedRole: function getGroupFromSelectedRole() {
      var self = this;
      var controller = this.get('controller');
      console.log("getGroupFromSelectedRole");
      console.log({
        appId: self.get('appId'),
        roleId: self.get('roleId'),
        page: controller.get('listPage'),
        limit: controller.get('limit'),
        sortBy: controller.get('sortBy'),
        sortOrder: controller.get('sortOrder'),
        searchInput: controller.get('searchInput'),
        searchColumn: controller.get('searchColumn')
      });
      controller.set("showLoadingAssign", true);
      var axiosConfig = {
        method: "GET",
        params: {
          appId: self.get('appId'),
          roleId: self.get('roleId'),
          page: controller.get('listPage'),
          limit: controller.get('limit'),
          sortBy: controller.get('sortBy'),
          sortOrder: controller.get('sortOrder'),
          searchInput: controller.get('searchInput'),
          searchColumn: controller.get('searchColumn')
        },
        url: self.store.adapterFor('application').get('namespace') + '/rolegroups/getGroupFromSelectedRole'
      };
      (0, _axios.default)(axiosConfig).then(function (response) {
        if (response.status === 200) {
          var temp = Ember.A();

          if (response.data.assignedGroup) {
            response.data.assignedGroup.forEach(function (group) {
              self.store.findRecord('group', group.id).then(function (newGroup) {
                temp.pushObject(newGroup);
              });
            });
            controller.set('groupRoleModel', temp);
          } else {
            controller.set('groupRoleModel', temp);
          }

          if (response.data.exceedTotal) {
            controller.set('listPage', 1);
          }

          controller.set('totalPage', response.data.totalPage);

          if (response.data.totalPage != 1) {
            controller.set('totalItemInPage', response.data.assignedGroup.length);
          } else {
            controller.set('totalItemInPage', 0);
          }

          controller.set("showLoadingAssign", false);
        }
      }).catch(function (err) {
        console.log("ERROR, something wrong with getGroupFromSelectedRole");
        console.log(err);
        controller.set("showLoadingAssign", false);
      });
    },
    actions: {
      setRole: function setRole(params) {
        var controller = this.get('controller');
        controller.set('groupSelected', false);
        controller.set('groupRoleModel', '');
        controller.set('unassignedGroupModel', '');

        if (params !== '') {
          controller.set('roleSelected', true);
          this.set('roleId', params.id);
          controller.set('createRole', false);
          controller.set('roleId', params.id);
          controller.set('roleName', params.get('roleName'));
          this.getGroupFromSelectedRole();
        } else {
          controller.set('roleSelected', false);
          controller.set('createRole', false);
          controller.set('roleName', '');
        }
      },
      doSearch: function doSearch() {
        /*if(!this.get('mobile')){
            var controller = this.get('controller');
             controller.set('processList', Ember.A());
        }*/
        this.getGroupFromSelectedRole();
      },
      doSort: function doSort(param) {
        var controller = this.get('controller');

        if (controller.get('sortBy') !== param) {
          controller.set('sortBy', param);
          controller.set('sortOrder', 'ASC');
        } else {
          if (controller.get('sortOrder') === 'DESC') {
            controller.set('sortOrder', 'ASC');
          } else {
            controller.set('sortOrder', 'DESC');
          }
        }

        this.getGroupFromSelectedRole();
      },
      changeColumn: function changeColumn(param) {
        console.log("change Column boss");
        console.log(param);
        var controller = this.get('controller');
        controller.set('searchColumn', param);
      },
      changeLimit: function changeLimit(param) {
        this.controller.set("limit", param);
        this.getGroupFromSelectedRole();
      },
      prevList: function prevList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') - 1);
        this.getGroupFromSelectedRole();
      },
      nextList: function nextList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') + 1);
        this.getGroupFromSelectedRole();
      },
      doSortModal: function doSortModal(param) {
        var controller = this.get('controller');

        if (controller.get('sortByModal') !== param) {
          controller.set('sortByModal', param);
          controller.set('sortOrderModal', 'ASC');
        } else {
          if (controller.get('sortOrderModal') === 'DESC') {
            controller.set('sortOrderModal', 'ASC');
          } else {
            controller.set('sortOrderModal', 'DESC');
          }
        }

        this.send('updateUnassignedGroupModel');
      },
      changeColumnModal: function changeColumnModal(param) {
        var controller = this.get('controller');
        controller.set('searchColumnModal', param);
      },
      doSearchModal: function doSearchModal() {
        this.send('updateUnassignedGroupModel');
      },
      changeLimitModal: function changeLimitModal(param) {
        this.controller.set("limitModal", param);
        this.send('updateUnassignedGroupModel');
      },
      prevListModal: function prevListModal() {
        var controller = this.get('controller');
        controller.set('listPageModal', controller.get('listPageModal') - 1);
        this.send('updateUnassignedGroupModel');
      },
      nextListModal: function nextListModal() {
        var controller = this.get('controller');
        controller.set('listPageModal', controller.get('listPageModal') + 1);
        this.send('updateUnassignedGroupModel');
      },
      updateUnassignedGroupModel: function updateUnassignedGroupModel() {
        var self = this;
        var controller = this.get('controller');
        controller.set("showLoadingModal", true);

        _jquery.default.ajax({
          type: "POST",
          data: {
            appId: self.get('appId'),
            roleId: self.get('roleId'),
            page: controller.get('listPageModal'),
            limit: controller.get('limitModal'),
            sortBy: controller.get('sortByModal'),
            sortOrder: controller.get('sortOrderModal'),
            searchInput: controller.get('searchInputModal'),
            searchColumn: controller.get('searchColumnModal')
          },
          url: self.store.adapterFor('application').get('namespace') + '/rolegroups/updateUnassignedGroupModel',
          success: function success(response) {
            controller.set('unassignedGroupModel', response.unassignedGroup);

            if (response.exceedTotal) {
              controller.set('listPageModal', 1);
            }

            controller.set('totalPageModal', response.totalPage);

            if (response.totalPage != 1) {
              controller.set('totalItemInPageModal', response.unassignedGroup.length);
            } else {
              controller.set('totalItemInPageModal', 0);
            }

            controller.set("showLoadingModal", false);
          },
          error: function error(xhr, status, _error) {
            console.log('Error ' + _error);
            controller.set("showLoadingModal", false);
          }
        });
      },
      unassignGroup: function unassignGroup(param) {
        var self = this;

        _jquery.default.ajax({
          type: "POST",
          data: {
            appId: self.get('appId'),
            roleId: self.get('roleId'),
            groupId: param
          },
          url: self.store.adapterFor('application').get('namespace') + '/rolegroups/unassignGroup',
          success: function success(response) {
            self.getGroupFromSelectedRole();
          },
          error: function error(xhr, status, _error2) {
            console.log('Error ' + _error2);
          }
        });
      },
      assignGroup: function assignGroup(param) {
        var self = this;
        self.controller.set('triggered', false);
        this.store.findRecord('role', self.get('roleId')).then(function (role) {
          self.store.findRecord('group', param.id).then(function (group) {
            var rolegroup = self.store.createRecord('rolegroup', {
              role: role,
              group: group
            });
            rolegroup.save().then(function (done) {
              self.getGroupFromSelectedRole();
              self.send('updateUnassignedGroupModel');
            });
          });
        });
      },
      goTo: function goTo(param) {
        this.goTo(param);
      }
    }
  });

  _exports.default = _default;
});