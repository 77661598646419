define("client/pods/components/report-generator/format-tab/sorting-parameter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addNewSort: function addNewSort() {
        this.addNewSort();
      },
      removeSort: function removeSort(sort) {
        this.removeSort(sort);
      },
      removeAllSort: function removeAllSort() {
        this.removeAllSort();
      }
    }
  });

  _exports.default = _default;
});