define("client/mixins/w3office-forms/field-props-crud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      persistRecord: function persistRecord(component, propertyName) {
        component.get(propertyName).set("updatedBy", component.get("currentUser"));
        component.get(propertyName).save().then(function (content) {
          component.set(propertyName, null); //update fieldgroupcontent

          component.store.query('fieldgroupcontent', {
            where: {
              field: component.get('focusedField').get('id')
            }
          }).then(function (fieldgroupcontent) {
            component.get('focusedField').set('fieldgroupcontent', fieldgroupcontent);
          });
        });
      },
      addRecord: function addRecord(component, propertyName, modelName, objRecord) {
        if (objRecord) {
          objRecord['field'] = component.get('focusedField');
        } else {
          objRecord = {
            field: component.get('focusedField')
          };
        }

        objRecord.createdBy = component.get("currentUser");
        component.set(propertyName, this.store.createRecord(modelName, objRecord));
      },
      deleteRecord: function deleteRecord(component, propertyName) {
        if (Ember.isEmpty(component.get(propertyName).id)) {
          //unload if data is uncommited
          component.get(propertyName).unloadRecord();
        } else {
          //destroy if data is persisted
          component.get(propertyName).destroyRecord();
        }

        component.set(propertyName, null);
      }
    }
  });

  _exports.default = _default;
});