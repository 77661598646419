define("client/models/processtask", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    entryId: (0, _attr.default)('string'),
    activityId: (0, _attr.default)('string'),
    url: (0, _attr.default)('array'),
    dueDate: (0, _attr.default)('date'),
    hasTimer: (0, _attr.default)('boolean'),
    finished: (0, _attr.default)('boolean'),
    sendEmail: (0, _attr.default)('boolean'),
    continueProcess: (0, _attr.default)('boolean'),
    description: (0, _attr.default)('string'),
    priority: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    createdAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    claimBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    stopBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    completeBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    reSupervisorBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    reAssigneeBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    processinstance: (0, _relationships.belongsTo)('processinstance', {
      async: true
    }),
    userprocesstasks: (0, _relationships.hasMany)('userprocesstask', {
      async: true
    }),
    processmemos: (0, _relationships.hasMany)('processmemo', {
      async: true
    })
  });

  _exports.default = _default;
});