define("client/models/menu", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    unique: (0, _attr.default)('string'),
    glyph: (0, _attr.default)('string'),
    icon: (0, _attr.default)('string'),
    link: (0, _attr.default)('string'),
    formid: (0, _attr.default)('string'),
    theme: (0, _attr.default)('string'),
    priority: (0, _attr.default)('number'),
    appID: (0, _attr.default)('string'),
    isExternalLink: (0, _attr.default)('boolean'),
    menuparentnum: (0, _relationships.belongsTo)('menu', {
      inverse: 'children',
      async: true
    }),
    children: (0, _relationships.hasMany)('menu', {
      inverse: 'menuparentnum',
      async: true
    })
  });

  _exports.default = _default;
});