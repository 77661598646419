define("client/models/processversion", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    version: (0, _attr.default)('number'),
    eventCount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    activityCount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    gatewayCount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    sequenceCount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    latest: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    process: (0, _relationships.belongsTo)('process', {
      async: true
    }),
    processinstances: (0, _relationships.hasMany)('processinstance', {
      async: true
    }),
    processactivities: (0, _relationships.hasMany)('processactivity', {
      async: true
    }),
    processevents: (0, _relationships.hasMany)('processevent', {
      async: true
    }),
    processgateways: (0, _relationships.hasMany)('processgateway', {
      async: true
    }),
    processpools: (0, _relationships.hasMany)('processpool', {
      async: true
    }),
    processsequences: (0, _relationships.hasMany)('processsequence', {
      async: true
    })
  });

  _exports.default = _default;
});