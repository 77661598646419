define("client/pods/menu-builder/child/subchild/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JU63LOTf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row mx-1\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-12 padding-0 border-top border-bottom\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page-header mx-3\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"pull-left my-2\"],[8],[7,\"h2\",true],[10,\"class\",\"m-0\"],[8],[4,\"link-to\",null,[[\"route\"],[\"menu-builder\"]],{\"statements\":[[0,\"Menu ( \"],[1,[22,\"menutitle\"],false],[0,\" )\"]],\"parameters\":[]},null],[0,\" - \"],[4,\"link-to\",null,[[\"route\"],[\"menu-builder.child\"]],{\"statements\":[[0,\"Child ( \"],[1,[22,\"childtitle\"],false],[0,\" )\"]],\"parameters\":[]},null],[0,\" - Sub Child\"],[9],[9],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"pull-right my-2\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"menu-builder.child.subchild.new\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-success\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"fa fa-fw fa-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Create New Menu\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-12 padding-0\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"component\",[\"menu-builder/menu-paging\"],[[\"postModel\",\"appFilter\",\"appList\",\"environment\",\"deleteMenu\"],[[24,[\"menuList\"]],[24,[\"appFilter\"]],[24,[\"appList\"]],\"subchild\",[28,\"route-action\",[\"deleteMenu\"],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/menu-builder/child/subchild/index/template.hbs"
    }
  });

  _exports.default = _default;
});