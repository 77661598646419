define("client/pods/menu-builder/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Go5GiCnY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row mx-1\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-12 padding-0 border-top border-bottom\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page-header mx-3\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"pull-left my-2\"],[8],[7,\"h2\",true],[10,\"class\",\"m-0\"],[8],[4,\"link-to\",null,[[\"route\"],[\"menu-builder\"]],{\"statements\":[[0,\"Menu\"]],\"parameters\":[]},null],[0,\" - Edit\"],[9],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-12 padding-0\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"component\",[\"menu-builder/newedit-menu\"],[[\"type\",\"objMenu\",\"appIds\",\"formIds\",\"menuLinks\",\"menuList\",\"getFormLinks\",\"actionToRoute\"],[\"Edit\",[24,[\"model\"]],[24,[\"appIds\"]],[24,[\"formIds\"]],[24,[\"menuLinks\"]],[24,[\"menuList\"]],[28,\"route-action\",[\"getFormLinks\"],null],[28,\"route-action\",[\"saveMenu\"],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/menu-builder/edit/template.hbs"
    }
  });

  _exports.default = _default;
});