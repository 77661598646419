define("client/pods/components/w3office-forms/hidden-values-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.valueTypeList = [{
        name: 'Inputted Value',
        value: 'inputted-value'
      }, {
        name: 'Initializer',
        value: 'initializer'
      }, {
        name: 'Parent Property',
        value: 'parent-property'
      }, {
        name: 'Form Property',
        value: 'form-property'
      }, {
        name: 'Current Date',
        value: 'current-date'
      }];
    },
    actions: {
      saveConfiguration: function saveConfiguration() {
        /*
        	Persisting record via mixins/w3office-forms/form-setting-crud.js
        	persistRecord: function(value, modalName, controllerList)
        */
        this.persistRecord(this.get('prevalue'), 'hidden-value', 'predefValuesList');
      },
      discardChanges: function discardChanges() {
        if (Ember.isEmpty(this.get('prevalue').id)) {
          //unload if data is uncommited
          this.get('prevalue').unloadRecord();
        } else {
          //rollback if data is persisted
          this.get('prevalue').rollbackAttributes();
        }

        (0, _jquery.default)('#hidden-value-props').modal('hide');
      }
    }
  });

  _exports.default = _default;
});