define("client/pods/components/w3office-forms/standard-components/paragraph-text/component-field/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isString', false);
      this.set('isArray', false);
      this.set('isRichText', false); // check whether the validation type is array

      if (this.get('field').get('fieldFormat') == 'array') {
        //set containerValue to array format
        this.set('containerValueArray', Ember.A());
        this.set('isArray', true);

        if (this.get('containerValue')) {
          this.set('containerValueArray', this.get('containerValue').split(','));
        }
      } else if (this.get('field').get('fieldFormat') == 'rich-text') {
        this.set('isRichText', true);
      } else {
        this.set('isString', true);
      }
    },
    didRender: function didRender() {
      if (this.get('currentTheme')) {
        (0, _jquery.default)("#paragraph-".concat(this.get('field').id)).css({
          "color": this.get('currentTheme').valueFontColor,
          "font-size": this.get('currentTheme').valueFontSize
        });
      }
    },
    actions: {
      mutateEntry: function mutateEntry() {
        this.mutateEntry();
      },
      addRecord: function addRecord() {
        if (this.get('containerValueTemp') && !this.get('containerValueArray').includes(this.get('containerValueTemp'))) {
          this.get('containerValueArray').pushObject(this.get('containerValueTemp'));
          this.set('containerValue', this.get('containerValueArray').toString());
          this.set('containerValueTemp', null);
          this.send('mutateEntry');
        }
      },
      removeRecord: function removeRecord(index) {
        var _this = this;

        this.get('containerValueArray').splice(index, 1);
        this.set('containerValue', this.get('containerValueArray').toString());
        this.send('mutateEntry');
        this.set('containerValueArray', Ember.A());

        if (this.get('containerValue')) {
          this.get('containerValue').split(',').forEach(function (value) {
            _this.get('containerValueArray').pushObject(value);
          });
        }
      }
    }
  });

  _exports.default = _default;
});