define("client/models/dabkpiconfiggroupby", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //attributes
    category: (0, _attr.default)("string"),
    tableName: (0, _attr.default)("string"),
    columnName: (0, _attr.default)("string"),
    function: (0, _attr.default)("string"),
    isdelete: (0, _attr.default)('boolean'),
    //relations
    dabkpiconfignum: (0, _relationships.belongsTo)("dabkpiconfig", {
      async: true
    })
  });

  _exports.default = _default;
});