define("client/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    addressid: _emberData.default.attr('string'),
    addressdescription1: _emberData.default.attr('string'),
    addressdescription2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    maplongitude: _emberData.default.attr('number'),
    maplatitude: _emberData.default.attr('number'),
    mapzoom: _emberData.default.attr('number'),
    isdelete: _emberData.default.attr('boolean'),
    //relation
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true,
      inverse: 'addresses'
    }),
    vendornum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});