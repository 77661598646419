define("client/pods/components/w3o-users-roles/form-user/last-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V9u8LSJR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"lastName\"],[8],[0,\"Last Name \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"lastNameIsEmpty\"],[8],[0,\"Last name cannot be empty\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"lastNameForm\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphLastName\"],[8],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"value\"],[\"lastName\",\"Enter Last Name\",\"form-control\",[24,[\"model\",\"lastName\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/last-name/template.hbs"
    }
  });

  _exports.default = _default;
});