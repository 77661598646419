define("client/pods/dashboard/overview/route", ["exports", "axios", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/dashboard-v2/dashboard-route"], function (_exports, _axios, _checkUserRole, _dashboardRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _dashboardRoute.default, {
    authObject: Ember.inject.service('session'),
    selected_shout: 2,
    shout_line: [{
      shoutid: 2,
      lineid: 7
    }, {
      shoutid: 3,
      lineid: 8
    }, {
      shoutid: 4,
      lineid: 9
    }, {
      shoutid: 5,
      lineid: 10
    }, {
      shoutid: 6,
      lineid: 11
    }],
    chartids: [1, 2, 3, 4, 5, 6, 12],
    listingids: [1],
    actions: {}
  });

  _exports.default = _default;
});