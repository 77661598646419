define("client/pods/components/w3office-forms/advanced-components/google-recaptcha/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['g-recaptcha'],
    // attributeBindings: ['siteKey:data-sitekey', 'data-theme', 'data-size', 'data-callback', 'data-expired-callback', 'data-tabindex'],
    //localhost
    siteKey: '6LeYnQ4TAAAAAPptd5yx7WN5lzw5iu-JEwd4Pebx',
    secretKey: '6LeYnQ4TAAAAAHXBNaUyzvardW3qmNYxHirkvlF-',
    //192.168.1.12
    //siteKey: '6LeNDRETAAAAAMVK99xx0MNMTjRdnLHlXVQYjZIa',
    //secretKey: '6LeNDRETAAAAACn1GGCP_rZiZlgXwklNOFwqhDCO',
    lang: 'en',
    resetTrigger: false,
    _isSetup: false,
    _attempts: 0,
    // dragStart(event) {
    //     //Transfer Field Properties
    //     this.set('formContainer.fieldHolder', this.get('field'));
    // },
    _maxAttempts: computed(function () {
      return 20;
    }).readOnly(),
    _interval: computed(function () {
      return 100; // Time between polls (in ms)
    }).readOnly(),
    // verifyCallback: function(data){
    //     console.log(this);
    //     console.log(this.get('resetTrigger'));
    //     $.ajaxPrefilter(function(options, oriOpt, jqXHR) {
    //         jqXHR.setRequestHeader("X-Recaptcha-Token", data);
    //     });
    //     // let requestHeaders = {
    //     //     "X-Recaptcha-Token": response
    //     // };
    //     //var url = this.store.adapterFor('application').get('namespace')+ '/fields/verifyRecaptcha';
    //     var url = "http://localhost:4200/api/v1/fields/verifyRecaptcha";
    //     $.ajax({
    //         type: "POST",
    //         url: url,
    //         data: { 
    //             secret: '6LeYnQ4TAAAAAHXBNaUyzvardW3qmNYxHirkvlF-',
    //             response: data,
    //             //path: '/recaptcha/api/siteverify?secret=6LeYnQ4TAAAAAHXBNaUyzvardW3qmNYxHirkvlF-&response='+data                
    //             'g-recaptcha-response': data
    //         },
    //         success: function(response){
    //             console.log(response);
    //             console.log(response.success);
    //             self.set('resetTrigger', true);
    //         }
    //     });
    // },
    setupGrecaptcha: function setupGrecaptcha() {
      var self = this;
      var siteKey = this.get('siteKey');
      var secretKey = this.get('secretKey'); //render captcha

      grecaptcha.render('recapt', {
        'sitekey': siteKey,
        //'callback' : this.verifyCallback
        'callback': function callback(data) {
          _jquery.default.ajaxPrefilter(function (options, oriOpt, jqXHR) {
            jqXHR.setRequestHeader("X-Recaptcha-Token", data);
          }); // let requestHeaders = {
          //     "X-Recaptcha-Token": response
          // };


          var url = self.store.adapterFor('application').get('namespace') + '/fields/verifyRecaptcha';
          self.recaptchaWait(); //verify captcha response

          _jquery.default.ajax({
            type: "POST",
            url: url,
            data: {
              'secret-key': secretKey,
              'site-key': siteKey,
              'g-recaptcha-response': data
            },
            success: function success(response) {
              //self.set('resetTrigger', true);
              self.pipeRecaptchaResponse(response.success);
            }
          });
        }
      });
      this.set('_isSetup', true);
    },
    resetGrecaptcha: Ember.observer('resetTrigger', function () {
      if (this.get('_isSetup') === true && this.get('resetTrigger') === true) {
        grecaptcha.reset('recapt');
        this.set('resetTrigger', false);
      }
    }),
    pollForObject: function pollForObject() {
      Ember.Logger.debug("Polling for grecaptcha");

      if (window.grecaptcha !== undefined) {
        this.setupGrecaptcha();
      } else if (this.get('_attempts') < this.get('_maxAttempts')) {
        this.set('_attempts', this.get('_attempts') + 1);
        Ember.run.later(this, function () {
          this.pollForObject();
        }, this.get('_interval'));
      } else {
        Ember.Logger.error("Failed to get grecapthca script");
      }
    },
    init: function init() {
      this._super();

      var siteKey = this.get('siteKey');
      var secretKey = this.get('secretKey');

      if (!Ember.isEmpty(siteKey) && !Ember.isEmpty(secretKey) && !this.get('authoring')) {
        var self = this;

        _jquery.default.getScript("https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=" + self.get('lang'), function (data, textStatus, jqxhr) {
          self.pollForObject();
        });
      }
    },
    actions: {// deleteComponent: function(){
      //     //Delete Component
      //     this.sendAction('deleteComponent', this.get('field'));
      // },
      // moveComponent: function(){
      //     //Delete Component
      //     this.set('formContainer.fieldHolder', this.get('field'));
      //     $('.orderDrop').css('height', '60px');
      //     $('.orderDrop').css('border-style', 'dotted');
      // },
      // commitFieldChange: function(){
      //     this.get('field').save();
      // },
      // showProperties: function(){
      //     this.sendAction('showProperties', this.get('field'));
      // }
    }
  });

  _exports.default = _default;
});