define("client/pods/w3office-forms/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['currentPage', 'appID', 'filter'],
    currentPage: '1',
    appID: '',
    filter: ''
  });

  _exports.default = _default;
});