define("client/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    refreshAccessTokens: true,
    serverTokenEndpoint: '/api/v1/auths/login',
    // serverTokenRevocationEndpoint: '/revoke'
    routing: Ember.inject.service('-routing'),
    authObject: Ember.inject.service('session'),
    authenticate: function authenticate(identification, password, authentication, scope) {
      var _this = this;

      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = {
          'grant_type': 'password',
          username: identification,
          password: password,
          authentication: authentication
        };

        var serverTokenEndpoint = _this.get('serverTokenEndpoint');

        var scopesString = Ember.makeArray(scope).join(' ');

        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }

        _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
          Ember.run(function () {
            var expiresAt = _this._absolutizeExpirationTime(response['expires_in']);

            _this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);

            if (!Ember.isEmpty(expiresAt)) {
              response = Ember.assign(response, {
                'expires_at': expiresAt
              });
            } // if(response.user.authEnable){
            //   self.get('routing').transitionTo('authenticate');
            // }


            resolve(response);
          });
        }, function (xhr) {
          Ember.run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },
    _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
      var _this2 = this;

      var self = this;
      var data = {
        'grant_type': 'refresh_token',
        'refresh_token': refreshToken
      };
      var serverTokenEndpoint = this.get('serverTokenEndpoint');

      function deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // Call Login API
        _this2.makeRequest(serverTokenEndpoint, data).then(function (response) {
          Ember.run(function () {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;

            var expiresAt = _this2._absolutizeExpirationTime(expiresIn);

            var data = Ember.assign(response, {
              'expires_in': expiresIn,
              'expires_at': expiresAt,
              'refresh_token': refreshToken
            });

            _this2._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);

            _this2.trigger('sessionDataUpdated', data);

            resolve(data);
          });
        }, function (xhr, status, error) {
          Ember.Logger.warn("Access token could not be refreshed - server responded with ".concat(error, "."));
          deleteAllCookies();

          if (Object.keys(self.get('authObject.data.authenticated')).length > 0) {
            /*self.get('authObject').set('data.locale', null);
            self.get('authObject').set('data.firstName', null);
            self.get('authObject').set('data.lastName', null);
            self.get('authObject').set('data.profilePicture', null);*/
            self.get('authObject').set('data.user', null);
            self.get('authObject').set('data.lastActivity', null);

            if (self.get('authObject.data.blobURL')) {
              window.URL = window.URL || window.webkitURL;
              window.URL.revokeObjectURL(self.get('authObject.data.blobURL'));
              self.get('authObject').set('data.blobURL', null);
            }

            self.get('authObject').invalidate();
          }

          reject();
        });
      });
    }
  });

  _exports.default = _default;
});