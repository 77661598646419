define("client/pods/components/w3office-forms/droppable-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I7wNBuBL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-none d-sm-block grow col-md-12\"],[11,\"id\",[22,\"_droppableRowId\"]],[8],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"class\",\"d-block d-sm-none orderDrop grow col-12\"],[12,\"id\",[22,\"_droppableRowMobileId\"]],[12,\"style\",\"color: transparent; display: flex; align-items: center;\"],[3,\"action\",[[23,0,[]],\"pushComponent\"]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"style\",\"position: absolute; top: 50%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%);\"],[8],[0,\"\\n\\t\\t\"],[7,\"strong\",true],[8],[0,\"Full width\"],[9],[0,\" on desktop resolution\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/droppable-row/template.hbs"
    }
  });

  _exports.default = _default;
});