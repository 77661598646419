define("client/pods/components/w3oprocess/header-nav/component", ["exports", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin", "axios"], function (_exports, _jquery, _authenticatedRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    container: '',
    newNotif: false,
    imageLoaded: false,
    init: function init() {
      this._super(); // menu is array which contains navigation for template


      this.menu = [{
        'title': 'Approval Manager',
        'glyph': 'fa-list-alt',
        'theme': '#e64759',
        'color': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('color: #e64759');
        }),
        'borderColor': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('border-color: #e64759');
        }),
        'unique': 'management',
        'subunique': 'submanagement',
        'show': false,
        'submenu': Ember.A([Ember.Object.create({
          'title': 'Inbox',
          'link': 'process-manager.inbox.index',
          'badge': true,
          'badgeCount': 0,
          'show': false
        }), Ember.Object.create({
          'title': 'My Task',
          'link': 'process-manager.my-task.index',
          'badge': true,
          'badgeCount': 0,
          'show': false
        }), Ember.Object.create({
          'title': 'Queued',
          'link': 'process-manager.queued.index',
          'badge': true,
          'badgeCount': 0,
          'show': false
        }), Ember.Object.create({
          'title': 'Involved',
          'link': 'process-manager.involved.index',
          'badge': true,
          'badgeCount': 0,
          'show': false
        }), Ember.Object.create({
          'title': 'Archived',
          'link': 'process-manager.archived.index',
          'badge': true,
          'badgeCount': 0,
          'show': false
        })])
      }, {
        'title': 'Process Builder',
        'glyph': 'fa-calculator',
        'theme': '#f39c12',
        'color': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('color: #f39c12');
        }),
        'borderColor': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('border-color: #f39c12');
        }),
        'unique': 'builder',
        'subunique': 'subbuilder',
        'show': false,
        'submenu': Ember.A([Ember.Object.create({
          'title': 'Process List',
          'link': 'process-builder.index',
          'show': false
        }), Ember.Object.create({
          'title': 'Create Process',
          'link': 'process-builder.create',
          'show': false
        })])
      }];
    },
    didInsertElement: function didInsertElement() {
      this.checkBadgeInbox();
      this.checkBadgeMyTask();
      this.checkBadgeQueued();
      this.checkBadgeInvolved();
      this.checkBadgeArchived();
      var el = document.querySelector('.customScrollbar');
      Ps.initialize(el);
    },

    /*
    * Wait for session data to be processed, so need to use didReceiveAttrs
    * Get Company Logo if there is association to euser
    */
    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;

      if (this.get('shownMenu')) {
        this.get('menu').forEach(function (header) {
          if (header.submenu) {
            header.submenu.forEach(function (submenu) {
              if (submenu.link && self.get('shownMenu').indexOf(submenu.link) > -1) {
                Ember.set(submenu, 'show', true);
              }
            });
          }

          if (header.title === 'Process Builder' && (self.get('shownMenu').indexOf('process-builder.index') > -1 || self.get('shownMenu').indexOf('process-builder.create') > -1)) {
            Ember.set(header, 'show', true);
          }

          if (header.title === 'Approval Manager' && (self.get('shownMenu').indexOf('process-manager.inbox.index') > -1 || self.get('shownMenu').indexOf('process-manager.my-task.index') > -1 || self.get('shownMenu').indexOf('process-manager.queued.index') > -1 || self.get('shownMenu').indexOf('process-manager.involved.index') > -1 || self.get('shownMenu').indexOf('process-manager.archived.index') > -1)) {
            Ember.set(header, 'show', true);
            (0, _jquery.default)('#dmanagement').addClass('in');
          }
        });
      }

      if (!Ember.isEmpty(this.get('session'))) {
        if (!Ember.isEmpty(this.get('session.user.euser.content')) && this.get('imageLoaded') === false) {
          var companynum;
          this.set('imageLoaded', true);

          if (this.get('session.user.euser.companynum')) {
            companynum = this.get('session.user.euser.companynum');
          } else {
            companynum = this.get('session.user.euser.optmm_company');
          }

          companynum.then(function (company) {
            if (company) {
              var ajaxCall = function ajaxCall(url) {
                (0, _axios.default)({
                  method: 'POST',
                  url: url,
                  responseType: 'arraybuffer'
                }).then(function (e) {
                  if (e.status == 200) {
                    window.URL = window.URL || window.webkitURL;
                    var blob = new Blob([e.data], {
                      type: 'image/jpeg'
                    });
                    var reader = new window.FileReader();
                    reader.readAsDataURL(blob);

                    reader.onloadend = function () {
                      self.set('appLogo', reader.result);
                    };
                  }
                });
              };

              var url;

              if (uuid.test(company.get('companylogopath'))) {
                self.store.query('formuploadedfile', {
                  where: {
                    UUID: company.get('companylogopath')
                  }
                }).then(function (resFormuploadedfile) {
                  url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + encodeURIComponent(resFormuploadedfile.get('firstObject').get('filePath'));
                  ajaxCall(url);
                });
              } else if (company.get('companylogopath')) {
                url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + encodeURIComponent(company.get('companylogopath'));
                ajaxCall(url);
              }
            }
          });
        }
      }
    },
    checkBadgeInbox: Ember.observer('model.content.userprocesstasks.@each.inbox', function () {
      var self = this;
      var inbox = 0;

      if (this.get('model.content')) {
        this.get('model.content.userprocesstasks').forEach(function (userprocesstask) {
          if (userprocesstask.get('inbox')) {
            inbox++;
          }
        });
        self.get('menu').forEach(function (_menu) {
          if (_menu.title === 'Approval Manager') {
            _menu.submenu.forEach(function (_submenu) {
              if (_submenu.title === 'Inbox') {
                _submenu.set('badgeCount', inbox);
              }
            });
          }
        });
      }
    }),
    checkBadgeMyTask: Ember.observer('model.content.userprocesstasks.@each.mytask', function () {
      var self = this;
      var mytask = 0;

      if (this.get('model.content')) {
        this.get('model.content.userprocesstasks').forEach(function (userprocesstask) {
          if (userprocesstask.get('mytask')) {
            mytask++;
          }
        });
        self.get('menu').forEach(function (_menu) {
          if (_menu.title === 'Approval Manager') {
            _menu.submenu.forEach(function (_submenu) {
              if (_submenu.title === 'My Task') {
                _submenu.set('badgeCount', mytask);
              }
            });
          }
        });
      }
    }),
    checkBadgeQueued: Ember.observer('model.content.userprocesstasks.@each.queued', function () {
      var self = this;
      var queued = 0;

      if (this.get('model.content')) {
        this.get('model.content.userprocesstasks').forEach(function (userprocesstask) {
          if (userprocesstask.get('queued')) {
            queued++;
          }
        });
        self.get('menu').forEach(function (_menu) {
          if (_menu.title === 'Approval Manager') {
            _menu.submenu.forEach(function (_submenu) {
              if (_submenu.title === 'Queued') {
                _submenu.set('badgeCount', queued);
              }
            });
          }
        });
      }
    }),
    checkBadgeInvolved: Ember.observer('model.content.userprocesstasks.@each.involved', function () {
      var self = this;
      var involved = 0;

      if (this.get('model.content')) {
        this.get('model.content.userprocesstasks').forEach(function (userprocesstask) {
          if (userprocesstask.get('involved')) {
            involved++;
          }
        });
        self.get('menu').forEach(function (_menu) {
          if (_menu.title === 'Approval Manager') {
            _menu.submenu.forEach(function (_submenu) {
              if (_submenu.title === 'Involved') {
                _submenu.set('badgeCount', involved);
              }
            });
          }
        });
      }
    }),
    checkBadgeArchived: Ember.observer('model.content.userprocesstasks.@each.archived', function () {
      var self = this;
      var archived = 0;

      if (this.get('model.content')) {
        this.get('model.content.userprocesstasks').forEach(function (userprocesstask) {
          if (userprocesstask.get('archived')) {
            archived++;
          }
        });
        self.get('menu').forEach(function (_menu) {
          if (_menu.title === 'Approval Manager') {
            _menu.submenu.forEach(function (_submenu) {
              if (_submenu.title === 'Archived') {
                _submenu.set('badgeCount', archived);
              }
            });
          }
        });
      }
    }),
    model: Ember.computed(function () {
      var userId = this.get('authObject.data.user').id;
      this.store.findAll('userprocesstask');
      return this.store.findRecord('user', userId);
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      },
      showHideSidebar: function showHideSidebar() {
        // check sidebar shown/hidden
        var flag = (0, _jquery.default)('.show-sidebar');

        if (flag.length === 0) {
          (0, _jquery.default)('#headernavdesktop-accordion').addClass('show-sidebar');
          (0, _jquery.default)('#headernavdesktop-accordion').removeClass('hide-sidebar');
          (0, _jquery.default)('.sidebar-mask').show();
        } else {
          (0, _jquery.default)('.show-sidebar').addClass('hide-sidebar');
          (0, _jquery.default)('.show-sidebar').removeClass('show-sidebar');
          (0, _jquery.default)('.sidebar-mask').hide();
        }
      },
      hideSidebarOnClick: function hideSidebarOnClick() {
        // hide sidebar on click
        (0, _jquery.default)('.show-sidebar').addClass('hide-sidebar');
        (0, _jquery.default)('.show-sidebar').removeClass('show-sidebar');
        (0, _jquery.default)('.sidebar-mask').hide();
      }
    }
  });

  _exports.default = _default;
});