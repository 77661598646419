define("client/mixins/w3office-forms/check-resource-access", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /*
    * Call this function to populate Resource Access variable into Initializer
    */
    populateResourceAccess: function populateResourceAccess() {
      var _this = this;

      var nameSpace = this.store.adapterFor('application').get('namespace'),
          url = nameSpace + '/forms/getResourceAccessDetail';

      _jquery.default.ajax({
        url: url,
        type: 'POST',
        data: {
          user: this.get('authObject.data.user').id
        },
        success: function success(result) {
          console.log(result);

          if (result.accessType === 'organizational') {
            _this.set('formContainer.accessType', result.accessType);

            _this.set('formContainer.resourceAccess', result.resourceAccess);

            _this.set('formContainer.organization', result.organization);

            _this.set('formContainer.company', result.company);
          } else if (result.accessType === 'personal') {
            _this.set('formContainer.accessType', result.accessType);

            _this.set('formContainer.user', result.user);
          }

          _this.get('formContainer.resourceValidationFinished').resolve(true);
        },
        error: function error(xhr, status, _error) {
          console.log(_error);
        }
      });
    },

    /*
    * checkResourceAccess - checkResourceAccessOnly()
    *	Call this function to check the Resource Access Variable only
    */
    checkResourceAccessOnly: function checkResourceAccessOnly() {
      var checkRecord;

      if (this.controller.get('form')) {
        if (this.controller.get('form').get('useExternalAPI')) {
          if (this.controller.get('form').get('formid').toLowerCase() === "organization") {
            checkRecord = {
              isExternalAPI: true,
              isOrganization: true,
              isCompany: false,
              hasOrganization: false,
              hasCompany: false
            };
          } else if (this.controller.get('form').get('formid').toLowerCase() === "company") {
            checkRecord = {
              isExternalAPI: true,
              isOrganization: false,
              isCompany: true,
              hasOrganization: true,
              hasCompany: false
            };
          } else {
            checkRecord = {
              isExternalAPI: true,
              hasOrganization: true,
              hasCompany: true
            };
          }
        } else {
          checkRecord = this.getCheckRecord(this.controller.get('modelName'));
        }
      } else {
        checkRecord = this.getCheckRecord(this.controller.get('modelName'));
      }

      if (this.get('formContainer.accessType') === 'organizational' && (checkRecord.isExternalAPI || checkRecord.hasOrganization || checkRecord.hasCompany)) {
        if (this.get('formContainer.resourceAccess') === '/') {
          this.finalizeResourceAccess(false, true);
        } else if (this.get('formContainer.resourceAccess') === '/org') {
          if (checkRecord.isOrganization) {
            this.finalizeResourceAccess(true, true);
          } else {
            this.finalizeResourceAccess(false, true);
          }
        } else if (this.get('formContainer.resourceAccess') === '/org/company') {
          if (checkRecord.isOrganization || checkRecord.isCompany) {
            this.finalizeResourceAccess(true, true);
          } else {
            this.finalizeResourceAccess(false, true);
          }
        }
      } else if (this.get('formContainer.accessType') === 'personal' && checkRecord.hasUser) {
        this.finalizeResourceAccess(false, true);
      } else {
        this.finalizeResourceAccess(true, false);

        if (!checkRecord.hasCompany) {
          console.log('Your model doesn\'t has column/property named \'companynum\' ');
        }

        if (!checkRecord.hasOrganization) {
          console.log('Your model doesn\'t has column/property named \'organizationnum\' ');
        }

        if (!checkRecord.hasUser) {
          console.log('Your model doesn\'t has column/property named \'user\' ');
        }
      }
    },

    /*
    * Check organizationColumn and companyColumn
    */
    checkOrgCompColumn: function checkOrgCompColumn() {
      if (Ember.isEmpty(this.controller.get('form').get('organizationColumn')) || Ember.isEmpty(this.controller.get('form').get('companyColumn'))) {
        alert('Please contact your Web Administrator. Error type #1');
      }
    },

    /*
    *	Populate Resource Access Value into an Object
    *	Required:
    *	@param {Object} conditionContainerWhere - usually the 'where' property from conditionContainer which used to query via this.store.query(modelName, conditionContainer)
    */
    populateResourceAccessFilter: function populateResourceAccessFilter(conditionContainerWhere) {
      var organizationColumn;
      var companyColumn;

      if (this.controller.get('form')) {
        organizationColumn = this.controller.get('form').get('organizationColumn');
        companyColumn = this.controller.get('form').get('companyColumn');
        this.checkOrgCompColumn();
      } else {
        organizationColumn = 'organizationnum';
        companyColumn = 'companynum';
      } // this can be used on dashboard player which only need to populate the conditionContainer WITHOUT resourceAccessRestricted & resourceAccessConditionPromise


      if (this.get('formContainer.resourceAccess') === '/') {//no filter organizationnum & companynum
      } else if (this.get('formContainer.resourceAccess') === '/org') {
        if (!Ember.isEmpty(this.get('formContainer.organization'))) {
          conditionContainerWhere[organizationColumn] = this.get('formContainer.organization');
        }
        /*Delete companyColumn property if exist*/


        if (conditionContainerWhere[companyColumn]) {
          delete conditionContainerWhere[companyColumn];
        }
      } else if (this.get('formContainer.resourceAccess') === '/org/company') {
        if (!Ember.isEmpty(this.get('formContainer.organization'))) {
          conditionContainerWhere[organizationColumn] = this.get('formContainer.organization');
        }

        if (!Ember.isEmpty(this.get('formContainer.company'))) {
          conditionContainerWhere[companyColumn] = this.get('formContainer.company');
        }
      }

      return conditionContainerWhere;
    },

    /*
    * checkResourceAccess - checkResourceAccessEntry
    *	Call this function to limit an entry cannot be viewed if Resource Access value not match with entry value
    *		Required:
    * @param {Object} entry 	- must be an Ember object. Also can a result from Ember model
    */
    checkResourceAccessEntry: function checkResourceAccessEntry(entry) {
      // check Entry, a Record
      // at the end, must set the resourceAccessRestricted & resourceAccessConditionPromise
      var organizationColumn;
      var companyColumn;

      if (this.controller.get('form')) {
        organizationColumn = this.controller.get('form').get('organizationColumn');
        companyColumn = this.controller.get('form').get('companyColumn');
        this.checkOrgCompColumn();
      } else {
        organizationColumn = 'organizationnum';
        companyColumn = 'companynum';
      }

      if (this.get('formContainer.accessType') === 'organizational') {
        if (!Ember.isEmpty(this.get('formContainer.resourceAccess'))) {
          if (this.get('formContainer.resourceAccess') === '/') {
            this.finalizeResourceAccess(false, true);
          } else if (this.get('formContainer.resourceAccess') === '/org') {
            if (entry.get(organizationColumn).isOrganization) {
              this.finalizeResourceAccess(true, true);
            } else {
              if (this.get('formContainer.organization') === parseInt(entry.get(organizationColumn + '.id'))) {
                this.finalizeResourceAccess(false, true);
              } else {
                this.finalizeResourceAccess(true, true);
              }
            }
          } else if (this.get('formContainer.resourceAccess') === '/org/company') {
            if (entry.get(organizationColumn).isOrganization || entry.get(companyColumn).isCompany) {
              this.finalizeResourceAccess(true, true);
            } else {
              if (this.get('formContainer.organization') === parseInt(entry.get(organizationColumn + '.id')) && this.get('formContainer.company') === parseInt(entry.get(companyColumn + '.id'))) {
                this.finalizeResourceAccess(false, true);
              } else {
                this.finalizeResourceAccess(true, true);
              }
            }
          }
        } else {
          this.finalizeResourceAccess(true, true);
        }
      } else if (this.get('formContainer.accessType') === 'personal') {
        if (parseInt(this.get('formContainer.user')) === parseInt(entry.get('user.id'))) {
          this.finalizeResourceAccess(false, true);
        } else {
          console.log('entry does not have user');
          this.finalizeResourceAccess(true, true);
        }
      }
    },

    /*
    * checkResourceAccess - checkResourceAccessEntries
    *	Call this function to limit the entries cannot be viewed if Resource Access value not match Ember model value and filter the entries with Resource Access Value
    *		Required:
    * @param {Object} entry 	- must be an JavaScript object. the 'where' object from query hash object that will be passed into store.query method.
    */
    checkResourceAccessEntries: function checkResourceAccessEntries(conditionContainerWhere) {
      // check Entries, Records 
      // at the end, must set the resourceAccessRestricted & resourceAccessConditionPromise
      var checkRecord, companyColumn, organizationColumn;

      if (this.controller.get('form')) {
        this.checkOrgCompColumn();
        organizationColumn = this.controller.get('form').get('organizationColumn');
        companyColumn = this.controller.get('form').get('companyColumn');

        if (this.controller.get('form').get('useExternalAPI')) {
          checkRecord = 'externalAPI';
        } else {
          checkRecord = this.getCheckRecord(this.controller.get('modelName'));
        }
      } else {
        organizationColumn = 'organizationnum';
        companyColumn = 'companynum';
        checkRecord = this.getCheckRecord(this.controller.get('modelName'));
      }

      if (this.get('formContainer.accessType') === 'organizational') {
        if (checkRecord === 'externalAPI') {
          if (this.get('formContainer.resourceAccess') === '/') {
            this.finalizeResourceAccess(false, true);
          } else if (this.get('formContainer.resourceAccess') === '/org') {
            if (!Ember.isEmpty(this.get('formContainer.organization'))) {
              conditionContainerWhere[organizationColumn] = this.get('formContainer.organization');
              this.finalizeResourceAccess(false, true);
            } else {
              this.finalizeResourceAccess(true, true);
            }
          } else if (this.get('formContainer.resourceAccess') === '/org/company') {
            if (!Ember.isEmpty(this.get('formContainer.organization')) && !Ember.isEmpty(this.get('formContainer.company'))) {
              conditionContainerWhere[organizationColumn] = this.get('formContainer.organization');
              conditionContainerWhere[companyColumn] = this.get('formContainer.company');
              this.finalizeResourceAccess(false, true);
            } else {
              this.finalizeResourceAccess(true, true);
            }
          }
        } else {
          if (!Ember.isEmpty(this.get('formContainer.resourceAccess')) && (checkRecord.hasOrganization || checkRecord.hasCompany)) {
            if (this.get('formContainer.resourceAccess') === '/') {
              this.finalizeResourceAccess(false, true);
            } else if (this.get('formContainer.resourceAccess') === '/org') {
              /* '/org' can't access Organization */
              if (checkRecord.isOrganization) {
                this.finalizeResourceAccess(true, true);
              } else {
                if (!Ember.isEmpty(this.get('formContainer.organization'))) {
                  conditionContainerWhere[organizationColumn] = this.get('formContainer.organization');
                  this.finalizeResourceAccess(false, true);
                } else {
                  this.finalizeResourceAccess(true, true);
                }
              }
            } else if (this.get('formContainer.resourceAccess') === '/org/company') {
              /*Alert if checkRecord doesn't have organizationColumn*/
              if (!checkRecord.hasOrganization) {
                alert('Model on this Form does not have \'organizationnum\' property.');
              }
              /* '/org/company' can't access Organization and Company */


              if (checkRecord.isOrganization || checkRecord.isCompany) {
                this.finalizeResourceAccess(true, true);
              } else {
                if (!Ember.isEmpty(this.get('formContainer.organization')) && !Ember.isEmpty(this.get('formContainer.company'))) {
                  conditionContainerWhere[organizationColumn] = this.get('formContainer.organization');
                  conditionContainerWhere[companyColumn] = this.get('formContainer.company');
                  this.finalizeResourceAccess(false, true);
                } else {
                  this.finalizeResourceAccess(true, true);
                }
              }
            }
          } else {
            this.finalizeResourceAccess(true, true);

            if (!checkRecord.hasCompany) {
              console.log('Your model doesn\'t has column/property named \'companynum\' ');
            }

            if (!checkRecord.hasOrganization) {
              console.log('Your model doesn\'t has column/property named \'organizationnum\' ');
            }
          }
        }
      } else if (this.get('formContainer.accessType') === 'personal') {
        if (!Ember.isEmpty(this.get('formContainer.user')) && checkRecord.hasUser) {
          conditionContainerWhere['user'] = this.get('formContainer.user');
          this.finalizeResourceAccess(false, true);
        } else {
          this.finalizeResourceAccess(true, true);

          if (!checkRecord.hasUser) {
            console.log('Your model doesn\'t has column/property named \'user\' ');
          }
        }
      }
    },
    finalizeResourceAccess: function finalizeResourceAccess(resAccRestricted, resAccConditionPromise) {
      if (resAccRestricted === true) {
        this.controller.set('formExceptionMessage', 'You don\'t have enough Access to access this form');
      }

      this.controller.set('resourceAccessRestricted', resAccRestricted);

      if (resAccConditionPromise) {
        this.controller.get('resourceAccessConditionPromise').resolve(true);
      }

      this.controller.set('isLoadingResourceAccess', false);
    },
    initializeOrgCompField: function initializeOrgCompField() {
      var showOrg, showComp;

      if (this.controller.get('form').get('useExternalAPI')) {
        if (this.get('formContainer.resourceAccess') === '/') {
          if (this.controller.get('form').get('formid').toLowerCase() === "organization") {
            showOrg = showComp = false;
          } else if (this.controller.get('form').get('formid').toLowerCase() === "company") {
            showOrg = true;
            showComp = false;
          } else {
            showOrg = true;
            showComp = true;
          }
        } else if (this.get('formContainer.resourceAccess') === '/org') {
          if (this.controller.get('form').get('formid').toLowerCase() === "organization") {
            showOrg = showComp = false;
          } else if (this.controller.get('form').get('formid').toLowerCase() === "company") {
            showOrg = showComp = false;
          } else {
            showOrg = false;
            showComp = true;
          }
        } else if (this.get('formContainer.resourceAccess') === '/org/company') {
          showOrg = false;
          showComp = false;
        } else {
          showOrg = showComp = false;
        }
      } else {
        var modelName = this.controller.get('modelName') ? this.controller.get('modelName') : this.controller.get('form').get('modelName');
        var checkRecord = this.getCheckRecord(modelName);

        if (this.get('formContainer.resourceAccess') === '/') {
          if (checkRecord.isOrganization) {
            showOrg = false;
            showComp = false;
          } else if (checkRecord.isCompany) {
            showOrg = true;
            showComp = false;
          } else {
            showOrg = true;
            showComp = true;
          }
        } else if (this.get('formContainer.resourceAccess') === '/org') {
          if (checkRecord.isCompany) {
            showOrg = false;
            showComp = false;
          } else {
            showOrg = false;
            showComp = true;
          }
        } else {
          showOrg = showComp = false;
        }
      }

      return {
        showOrg: showOrg,
        showComp: showComp
      };
    },

    /**
     * Description:
     * This is literally PRIVATE function. Shouldn't be called from other routes.
     * @param {string} modelName 
     */
    getCheckRecord: function getCheckRecord(modelName) {
      if (Ember.isEmpty(modelName)) {
        alert("Please set \"modelName\" in your Controller");
      }

      var checkRecord = this.store.createRecord(modelName),
          returnObj = {};
      var organizationColumn;
      var companyColumn;

      if (this.controller.get('form')) {
        organizationColumn = this.controller.get('form').get('organizationColumn');
        companyColumn = this.controller.get('form').get('companyColumn');
        this.checkOrgCompColumn();
      } else {
        organizationColumn = 'organizationnum';
        companyColumn = 'companynum';
      }

      returnObj.hasOrganization = Ember.isEmpty(checkRecord.get(organizationColumn)) ? false : true;
      returnObj.isOrganization = checkRecord.get(organizationColumn).isOrganization ? true : false;
      returnObj.hasCompany = Ember.isEmpty(checkRecord.get(companyColumn)) ? false : true;

      if (returnObj.hasCompany) {
        returnObj.isCompany = checkRecord.get(companyColumn).isCompany ? true : false;
      } else {
        returnObj.isCompany = false;
      }

      returnObj.hasUser = Ember.isEmpty(checkRecord.get('user')) ? false : true;
      checkRecord.destroyRecord();
      return returnObj;
    }
  });

  _exports.default = _default;
});