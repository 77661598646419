define("client/pods/users-roles/session-expired/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YGYJ46kh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loading-pane\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"loading-message panel panel-default\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[8],[0,\"Your session already expired, please click below link to go to the Login page to login again.\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\"],[7,\"a\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"goToStart\"]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h4\",true],[8],[0,\"Click Here\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/users-roles/session-expired/template.hbs"
    }
  });

  _exports.default = _default;
});