define("client/pods/components/w3o-users-roles/user-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authObject: Ember.inject.service('session'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("activeFilterColumn", "email");
      this.set("activeFilterLabel", "Email");
      this.set("filters", [{
        column: "email",
        label: "Email"
      }, {
        column: "username",
        label: "Username"
      }, {
        column: "\"fullName\"",
        label: "Name"
      }]);
      this.set("showPages", 5);
      this.set("currentPageInside", Number(this.get("listPage")));
      this.generatePagination();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set("currentPageInside", Number(this.get("listPage")));
      this.generatePagination();
    },
    generatePagination: function generatePagination() {
      var lastPage = parseInt(this.get("totalPage"), 10);
      var currentPage = parseInt(this.get("listPage"), 10);
      var showPages = this.get("showPages");
      var pageStart;

      if (currentPage <= showPages) {
        pageStart = 0;
      } else if (currentPage % showPages === 0) {
        pageStart = (Math.floor(currentPage / showPages) - 1) * showPages;
      } else {
        pageStart = Math.floor(currentPage / showPages) * showPages;
      }

      var arrPages = [];

      for (var i = 1; i <= showPages; i++) {
        if (pageStart + i <= lastPage) {
          arrPages.push(pageStart + i);
        }
      }

      this.set("pages", arrPages);
      this.set("currentPageSet", Math.ceil(currentPage / showPages));
      this.set("lastPageSet", Math.ceil(lastPage / showPages));

      if (this.get("currentPageSet") === 1) {
        this.set("showPrevFirstPage", false);
      } else {
        this.set("showPrevFirstPage", true);
      }

      if (this.get("currentPageSet") === this.get("lastPageSet")) {
        this.set("showNextLastPage", false);
      } else {
        this.set("showNextLastPage", true);
      }
    },
    actions: {
      clearSearchQuery: function clearSearchQuery() {
        this.set("searchInput", null);
        this.set("activeFilterColumn", "email");
        this.set("activeFilterLabel", "Email");
        this.changeColumn("email");
        this.doSearch();
      },
      doSort: function doSort(param) {
        this.doSort(param);
      },
      doSearch: function doSearch() {
        this.doSearch();
      },
      changeColumn: function changeColumn(param) {
        this.set("activeFilterColumn", param.column);
        this.set("activeFilterLabel", param.label);
        this.changeColumn(param.column);
      },
      changeLimit: function changeLimit(limit) {
        this.set('limit', limit);
        this.changeLimit();
      },
      prevList: function prevList() {
        this.prevList();
      },
      nextList: function nextList() {
        this.nextList();
      },
      moveList: function moveList(direction, page) {
        this.moveList(direction, page);
      },
      deleteUser: function deleteUser() {
        this.deleteUser();
      },
      storeId: function storeId(param) {
        this.set("selectedUserFullName", param.fullName);
        this.storeId(param.id);
      },
      downloadList: function downloadList() {
        this.downloadList();
      },
      viewUserDetail: function viewUserDetail(user) {
        this.viewUserDetail(user);
      }
    }
  });

  _exports.default = _default;
});