define("client/models/form", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    submissionRecording: (0, _attr.default)('string', {
      defaultValue: 'internal-record'
    }),
    usingAPI: (0, _attr.default)('boolean'),
    apiURL: (0, _attr.default)('string'),
    modelName: (0, _attr.default)('string'),
    destinationEmail: (0, _attr.default)('string'),
    hasLimit: (0, _attr.default)('boolean'),
    hasReset: (0, _attr.default)('boolean'),
    entryLimit: (0, _attr.default)('number'),
    hasRecaptcha: (0, _attr.default)('boolean'),
    recaptchaSiteKey: (0, _attr.default)('string'),
    recaptchaSecretKey: (0, _attr.default)('string'),
    submitButtonName: (0, _attr.default)('string', {
      defaultValue: 'Submit'
    }),
    createButtonName: (0, _attr.default)('string', {
      defaultValue: 'Create New'
    }),
    duplicateButtonName: (0, _attr.default)('string', {
      defaultValue: 'Duplicate'
    }),
    editButtonName: (0, _attr.default)('string', {
      defaultValue: 'Edit'
    }),
    deleteButtonName: (0, _attr.default)('string', {
      defaultValue: 'Delete'
    }),
    pdfButtonName: (0, _attr.default)('string', {
      defaultValue: 'PDF'
    }),
    subForProcessButtonName: (0, _attr.default)('string', {
      defaultValue: 'Submit for Processing'
    }),
    viewsCount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    isDeployed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    canAdd: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    canView: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    canEdit: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    canDelete: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    canDuplicate: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    canPDF: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    canCSV: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    showEntryDetail: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    hasOutline: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    outlineColor: (0, _attr.default)('string'),
    backgroundPath: (0, _attr.default)('string'),
    backgroundFileName: (0, _attr.default)('string'),
    fontColor: (0, _attr.default)('string'),
    formid: (0, _attr.default)("string"),
    checkParentAvailability: (0, _attr.default)('boolean'),
    isDisabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    appID: (0, _attr.default)('string'),
    enableDirectAccess: (0, _attr.default)('boolean'),
    resourceAccessEnabled: (0, _attr.default)('boolean'),
    deactivateRecords: (0, _attr.default)('boolean'),
    afterDeleteAPI: (0, _attr.default)('string'),
    deleteColumnName: (0, _attr.default)('string'),
    deepDelete: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    deepDuplicate: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    showOrganization: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    showCompany: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    organizationColumn: (0, _attr.default)('string', {
      defaultValue: 'organizationnum'
    }),
    companyColumn: (0, _attr.default)('string', {
      defaultValue: 'companynum'
    }),
    userRoleRouteAccessLimit: (0, _attr.default)('string'),
    useExternalAPI: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    externalAPIConfig: (0, _attr.default)('string'),
    enableRecordsTicking: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    recordsTickingApiURL: (0, _attr.default)('string'),
    recordsTickingCustomComponent: (0, _attr.default)('string'),
    recordsTickingCustomProperties: (0, _attr.default)('string'),
    recordsTickingLimit: (0, _attr.default)('number'),
    childFormSortingType: (0, _attr.default)('string'),
    useTableBuilder: (0, _attr.default)('boolean'),
    formTable: (0, _relationships.belongsTo)('formtable'),
    crudOnEntries: (0, _attr.default)('string'),
    // author: belongsTo('formuser', {async: true}),
    // formfield: belongsTo('formfield', {async: true}),
    formnotifications: (0, _relationships.hasMany)('formnotification', {
      async: true
    }),
    formconfirmations: (0, _relationships.hasMany)('formconfirmation', {
      async: true
    }),
    fields: (0, _relationships.hasMany)('field', {
      async: true
    }),
    entryConfigurations: (0, _relationships.hasMany)('entryconfiguration', {
      inverse: "form",
      async: true
    }),
    entryListConfigurations: (0, _relationships.hasMany)('entrylistconfiguration', {
      async: true
    }),
    //entries: hasMany('entry', {async: true}),
    formpredefvalues: (0, _relationships.hasMany)('formpredefvalue', {
      async: true
    }),
    entryfilters: (0, _relationships.hasMany)('entryfilter', {
      inverse: "form",
      async: true
    }),
    entrygroups: (0, _relationships.hasMany)('entrygroup', {
      async: true
    }),
    formaccesslimits: (0, _relationships.hasMany)('formaccesslimit', {
      async: true
    }),
    formProcesses: (0, _relationships.hasMany)('formprocess', {
      async: true
    }),
    formUniqueComposites: (0, _relationships.hasMany)('formuniquecomposite', {
      async: true
    }),
    fieldLogicMasters: (0, _relationships.hasMany)('fieldlogicmaster', {
      async: true
    }),
    entriesCount: Ember.computed('entries', function () {
      return this.get('entries.length');
    }),
    conversionRate: Ember.computed('viewsCount', function () {
      var cRate = parseFloat(this.get('entriesCount') / this.get('viewsCount')).toFixed(2);

      if (this.get('viewsCount') === null || this.get('entriesCount') === 0) {
        cRate = '';
      }

      return cRate;
    }),
    // for embed custom UI
    isEmbedCustomUI: (0, _attr.default)('boolean'),
    componentName: (0, _attr.default)('string'),
    createdBy: (0, _relationships.belongsTo)("user", {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)("user", {
      async: true
    })
  });

  _exports.default = _default;
});