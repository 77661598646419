define("client/pods/components/w3o-users-roles/form-user/repeat-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/rI6kzX4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"passwordRepeat\"],[8],[0,\"Repeat Password \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"passwordRepeatIsEmpty\"],[8],[0,\"Repeat password cannot be empty\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"passwordIsNotSame\"],[8],[0,\"Password must be same with repeat password\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"passwordRepeatForm\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphPasswordRepeat\"],[8],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"type\",\"value\"],[\"passwordRepeat\",\"Repeat password\",\"form-control\",\"password\",[24,[\"model\",\"passwordRepeat\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/repeat-password/template.hbs"
    }
  });

  _exports.default = _default;
});