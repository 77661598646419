define("client/models/processinstance", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    instruction: (0, _attr.default)('string'),
    currentNode: (0, _attr.default)('string'),
    currentEntry: (0, _attr.default)('string'),
    currentEntryName: (0, _attr.default)('string'),
    latestTask: (0, _attr.default)('string'),
    submitterId: (0, _attr.default)('number'),
    finished: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    organizationnum: (0, _attr.default)('number'),
    companynum: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    supervisor: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    processversion: (0, _relationships.belongsTo)('processversion', {
      async: true
    }),
    processtasks: (0, _relationships.hasMany)('processtask', {
      async: true
    }),
    processmemos: (0, _relationships.hasMany)('processmemo', {
      async: true
    }),
    processinstancetracks: (0, _relationships.hasMany)('processinstancetrack', {
      async: true
    })
  });

  _exports.default = _default;
});