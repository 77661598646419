define("client/pods/users-roles/role-manager/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MmBIdATo",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"loading-pane\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading-message\"],[8],[0,\"\\n      Loading...\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/users-roles/role-manager/loading/template.hbs"
    }
  });

  _exports.default = _default;
});