define("client/pods/ui/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3office-forms/create-common"], function (_exports, _checkUserRole, _createCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _createCommon.default, {
    environment: 'create',
    // also can 'duplicate'
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      this.simpleAuthSetup(transition);

      this._super.apply(this, arguments);
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    model: function model(params) {
      if (this.modelFor('ui')) {
        return this.modelFor('ui');
      } else {
        return null;
      }
    },
    afterModel: function afterModel(model, transition) {// Create New don't need to be counted into ViewsCount
      // if(!isEmpty(model)) {
      // 	this.incrementView(model);
      // 	this._super();
      // }
    },
    setupController: function setupController(controller, model) {
      if (!Ember.isEmpty(model)) {
        controller.set('authObject', this.get('authObject'));

        this._super(controller, model);
      }
    }
  });

  _exports.default = _default;
});