define("client/pods/components/w3office-forms/standard-components/radio-button/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    /*
    * Array for storing radio options
    */
    fieldContents: Ember.computed('field.fieldContents', function () {
      return this.get('field').get('fieldContents');
    }),

    /*
    * Set Unique Name for radio group
    */
    _radioName: Ember.computed('elementId', function () {
      return this.get('elementId') + '-radio';
    }),
    isLoadFieldFilter: Ember.computed('field.loadFieldFilter', function () {
      return this.get('field').get('loadFieldFilter');
    }),
    isLoadFieldContents: Ember.computed('field.loadFieldContents', function () {
      return this.get('field').get('loadFieldContents');
    }),
    isSkeleton: Ember.computed('isLoadFieldContents', 'isLoadFieldFilter', function () {
      if (this.get('isLoadFieldContents') || this.get('isLoadFieldFilter')) {
        return true;
      } else {
        return false;
      }
    }),

    /*
    * Populate fieldcontents with value from fieldgroupcontent
    * Determine which value are chosen by comparing value from fieldgroupcontents with entryContainer
    */
    init: function init() {
      this._super();

      if (this.get("authoring")) {
        this.set("exampleFieldContents", [{
          name: 'Option 1'
        }, {
          name: 'Option 2'
        }, {
          name: 'Option 3'
        }, {
          name: 'Option 4'
        }]);
      } else {
        this.get('field').setProperties({
          loadFieldFilter: true,
          loadFieldContents: true
        });

        if (!Ember.isEmpty(this.get('field'))) {
          this.fieldActionGateway(this, {
            actionName: 'getFieldGroupContent'
          });
        }
      }
    },

    /*
    * Repopulate entrycontainer when radio button value is changed
    */
    didRender: function didRender() {
      this._super();

      var self = this;
    },
    didInsertElement: function didInsertElement() {
      var el = document.querySelector('.customScrollbar');
      Ps.initialize(el);
    },
    actions: {
      mutateEntry: function mutateEntry(val) {
        this.set('containerValue', val.value);
        this.setEntryContainer(this.get('field'), this.get('containerValue'));
      }
    }
  });

  _exports.default = _default;
});