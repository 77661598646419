define("client/models/formaccesslimit", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    limitType: (0, _attr.default)('string'),
    propertyType: (0, _attr.default)('string'),
    propertyName: (0, _attr.default)('string'),
    conditionComparator: (0, _attr.default)('string'),
    conditionValue: (0, _attr.default)('string'),
    customMessage: (0, _attr.default)('string'),
    form: (0, _relationships.belongsTo)('form'),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    formProcess: (0, _relationships.belongsTo)('formprocess', {
      async: true
    })
  });

  _exports.default = _default;
});