define("client/pods/components/w3office-forms/standard-components/html-content/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {});

  _exports.default = _default;
});