define("client/pods/dashboard-builder/dabkpi/edit/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3office-forms/check-resource-access"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin, _checkResourceAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _checkResourceAccess.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(param) {
      return this.store.findRecord("dabkpi", param.dabkpi_id);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set("dabkpiid", model.id);
      controller.set("dabkpititle", model.get('title'));
      controller.set('model', model);
      controller.set('resourceAccessEnabled', dashboardnum.get('resourceAccessEnabled'));

      if (dashboardnum.get('resourceAccessEnabled')) {
        controller.set('resourceAccessRestricted', false);
        controller.set('resourceAccessConditionPromise', Ember.RSVP.defer());
        controller.set('isLoadingResourceAccess', true);
        controller.set('modelName', 'dabkpi');
        self.populateResourceAccess();
        self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
          controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
          self.checkResourceAccessOnly();

          if (self.get('formContainer.resourceAccess') === '/') {
            self.store.query('organization', {
              isdelete: false
            }).then(function (organizations) {
              self.controller.set('organizationList', organizations);

              if (!Ember.isEmpty(model.get('organizationnum').get('id'))) {
                self.store.query('company', {
                  where: {
                    organizationnum: model.get('organizationnum').get('id'),
                    isdelete: false
                  }
                }).then(function (companies) {
                  self.controller.set('companyList', companies);
                });
              }
            });
          } else if (self.get('formContainer.resourceAccess') === '/org') {
            controller.get('model').set('organizationnum', self.get('optmmSetting.companynum.organizationnum'));
            self.store.query('company', {
              where: {
                organizationnum: model.get('organizationnum').get('id'),
                isdelete: false
              }
            }).then(function (companies) {
              self.controller.set('companyList', companies);
            });
          }

          controller.get('model').addObserver('organizationnum', function (currentmodel) {
            if (currentmodel.get('organizationnum')) {
              self.store.query('company', {
                where: {
                  organizationnum: currentmodel.get('organizationnum').get('id'),
                  isdelete: false
                }
              }).then(function (companies) {
                self.controller.set('companyList', companies);
              });
            }
          });
        });
      }
    },
    actions: {
      updateDabkpi: function updateDabkpi() {
        var self = this;
        var up = this.get("currentModel");
        up.save().then(function () {
          self.transitionTo('dashboard-builder.dabkpi');
        });
      }
    }
  });

  _exports.default = _default;
});