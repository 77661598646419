define("client/pods/users-roles/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a3XaI+0v",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row no-gutters\"],[8],[0,\"\\n\\t\\t\"],[7,\"form\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"form-group col-md-2 col-xs-4\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"user\",\"profilePicture\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[24,[\"session\",\"user\",\"profilePicture\"]]],[10,\"alt\",\"profile image\"],[10,\"width\",\"100%\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/images/blank-profile.png\"],[10,\"alt\",\"profile image\"],[10,\"width\",\"100%\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"form-group col-md-10 col-xs-8\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"h3\",true],[8],[0,\"Welcome, \"],[1,[24,[\"session\",\"user\",\"firstName\"]],false],[0,\" \"],[1,[24,[\"session\",\"user\",\"lastName\"]],false],[0,\"!\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\t\\t\\t            \\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row no-gutters\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"users-roles.users.user\",[24,[\"session\",\"user\",\"id\"]]]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-info\"],[8],[0,\"\\n\\t\\t\\t\\tGo To Profile\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/users-roles/index/template.hbs"
    }
  });

  _exports.default = _default;
});