define("client/pods/process-manager/my-task/detail/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3oprocess/process-manager-detail"], function (_exports, _checkUserRole, _processManagerDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _processManagerDetail.default, {
    environment: 'my-task',
    beforeModel: function beforeModel() {
      this._super();
    },
    model: function model(param) {
      return this.store.findRecord('userprocesstask', param.task_id); // return this.store.findRecord('userprocesstask', param.task_id).then(function(model){
      // 	if(!model.get('mytask')){
      // 		if(model.get('inbox')){
      // 			self.transitionTo('process-manager.inbox.detail', model.get('id'));
      // 		}else if(model.get('queued')){
      // 			self.transitionTo('process-manager.queued.detail', model.get('id'));
      // 		}else if(model.get('involved')){
      // 			self.transitionTo('process-manager.involved.detail', model.get('id'));
      // 		}else if(model.get('archived')){
      // 			self.transitionTo('process-manager.archived.detail', model.get('id'));
      // 		}
      // 	}else{
      // 		return model;
      // 	}
      // });
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.controllerFor("process-manager.my-task").set("showListingIcon", true);
    },
    setupController: function setupController(controller, model) {
      this._super();
    },
    actions: {
      gotoList: function gotoList() {
        this.gotoList();
      },
      stopProcess: function stopProcess() {
        this.stopProcess();
      },
      selectUserTask: function selectUserTask(user) {
        this.selectUserTask(user);
      },
      reassignUser: function reassignUser() {
        this.reassignUser();
      }
    }
  });

  _exports.default = _default;
});