define("client/pods/components/w3o-scheduler/scheduler-param-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EEkCoD3B",
    "block": "{\"symbols\":[\"xs\",\"pName\"],\"statements\":[[7,\"label\",true],[8],[0,\"Parameter Name\"],[9],[0,\"\\n\"],[4,\"x-select\",null,[[\"class\",\"value\",\"on-change\"],[\"form-control\",[24,[\"parameterName\"]],[28,\"action\",[[23,0,[]],\"parameterNameChanged\"],null]]],{\"statements\":[[4,\"each\",[[24,[\"parameterNameList\"]]],null,{\"statements\":[[0,\"        \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/w3o-scheduler/scheduler-param-name/template.hbs' @ L4:C11) \"],null]],[[\"value\"],[[23,2,[]]]],{\"statements\":[[0,\" \"],[1,[23,2,[]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/w3o-scheduler/scheduler-param-name/template.hbs' @ L6:C7) \"],null]],[[\"value\"],[\"others_param_name\"]],{\"statements\":[[0,\" others_param_name \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"param\",\"isOther\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[24,[\"param\",\"name\"]],\"Parameter Name\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-scheduler/scheduler-param-name/template.hbs"
    }
  });

  _exports.default = _default;
});