define("client/pods/components/header-profile-picture/component", ["exports", "jquery", "axios"], function (_exports, _jquery, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentProfilePicture', this.get('filePath'));

      if (!this.get('blobURL')) {
        this.loadProfilePicture();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('filePath') !== this.get('currentProfilePicture')) {
        this.set('currentProfilePicture', this.get('filePath'));
        this.loadProfilePicture();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)('#photo-' + this.get('elementId')).on('error', function (ev) {
        if (_this.get('filePath') && _this.get('elementId')) {
          _this.loadProfilePicture();
        }
      });
    },
    loadProfilePicture: function loadProfilePicture() {
      var _this2 = this;

      if (this.get('filePath')) {
        this.set('isLoading', true);
        var url = this.store.adapterFor('application').get('namespace') + "/formuploads/downloadFormAttachment?fd=" + encodeURIComponent(this.get('filePath'));
        (0, _axios.default)({
          method: 'POST',
          url: url,
          responseType: 'arraybuffer'
        }).catch(function (err) {
          console.log('error');
          console.log(err);

          _this2.set('blobURL', '/images/blank-profile.png');

          _this2.set('isLoading', false);
        }).then(function (response) {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: _this2.get('fileType')
            });
            window.URL = window.URL || window.webkitURL;

            _this2.set('blobURL', window.URL.createObjectURL(blob));

            _this2.set('isLoading', false);
          } else {
            console.log(response);

            _this2.set('blobURL', '/images/blank-profile.png');

            _this2.set('isLoading', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});