define("client/pods/report-generator/report/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    authObject: Ember.inject.service('session'),
    //initialize session
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(params) {
      // return params.report_id;
      var self = this;
      var conditionContainer = {};
      conditionContainer['where'] = {
        'id': params.report_id
      };
      conditionContainer.sort = 'id ASC';
      conditionContainer.where.isdelete = false;
      return self.store.query('genreport', conditionContainer);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set('model', model.get('firstObject'));
      model = controller.get('model');
      controller.set('selectedParamcollection', null);
      controller.set('paramContents', Ember.A());
      controller.set('availableFieldContents', Ember.A());
      controller.set('selection', Ember.Object.create({
        category: '',
        tablename: ''
      }));
      controller.set('loadingData', true);
      controller.set('currentReport', model);

      if (model.get('genreportcategorynum')) {
        controller.set('selectedCategory', model.get('genreportcategorynum'));
        var subcategoryQuery = self.store.query('genreportsubcategory', {
          where: {
            isdelete: false,
            genreportcategorynum: model.get('genreportcategorynum.id')
          },
          sort: 'id ASC'
        });
        subcategoryQuery.then(function (subcategoryContents) {
          controller.set('subcategoryContents', subcategoryContents.toArray());
        });
      } else {
        controller.set('selectedCategory', null);
      }

      if (model.get('genreportsubcategorynum')) {
        controller.set('selectedSubcategory', model.get('genreportsubcategorynum'));
      } else {
        controller.set('selectedSubcategory', null);
      }

      if (self.get('formContainer.resourceAccess') === '/') {
        self.store.findAll('organization').then(function (organizations) {
          controller.set('organizationList', organizations);

          if (!Ember.isEmpty(model.get('organizationnum').get('id'))) {
            self.store.query('company', {
              where: {
                organizationnum: model.get('organizationnum').get('id')
              }
            }).then(function (companies) {
              controller.set('companyList', companies);
            });
          }
        });
      } else if (self.get('formContainer.resourceAccess') === '/org') {
        self.store.query('company', {
          where: {
            organizationnum: model.get('organizationnum').get('id')
          }
        }).then(function (companies) {
          controller.set('companyList', companies);
        });
      }

      controller.set('isEdit', false);
      controller.set('selectedTableCollection', Ember.A());
      controller.set('availableFieldCollection', Ember.A());
      controller.set('selectedEntityCollection', Ember.A());
      controller.set('selectedFieldCollection', Ember.A()); //set query

      controller.set('selectedTableQuery', self.store.query('genreportconfigtable', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('selectedFieldQuery', self.store.query('genreportconfigfield', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('selectQuery', self.store.query('genreportconfigselect', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('joinQuery', self.store.query('genreportconfigjoin', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('summarizeQuery', self.store.query('genreportconfigsummarize', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('groupbyQuery', self.store.query('genreportconfiggroupby', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('groupingQuery', self.store.query('genreportconfiggrouping', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('filterQuery', self.store.query('genreportconfigfilter', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('sortQuery', self.store.query('genreportconfigsort', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('expressionQuery', self.store.query('genreportconfigexpression', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('paramcollectionQuery', self.store.query('genreportparamcollection', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      controller.set('viewerparamQuery', self.store.query('genreportparamconfiguser', {
        where: {
          genreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      })); //call api

      controller.get('selectedTableQuery').then(function (selectedTableContents) {
        controller.get('selectedFieldQuery').then(function (selectedFieldContents) {
          controller.get('selectQuery').then(function (selectContents) {
            controller.get('joinQuery').then(function (joinContents) {
              controller.get('summarizeQuery').then(function (summarizeContents) {
                controller.get('groupbyQuery').then(function (groupbyContents) {
                  controller.get('groupingQuery').then(function (groupingContents) {
                    controller.get('filterQuery').then(function (filterContents) {
                      controller.get('sortQuery').then(function (sortContents) {
                        controller.get('expressionQuery').then(function (expressionContents) {
                          controller.get('paramcollectionQuery').then(function (paramcollectionContents) {
                            controller.get('viewerparamQuery').then(function (viewerparamContents) {
                              //add result to variable
                              controller.set('paramcollectionContents', paramcollectionContents.toArray());
                              controller.set('viewerparamContents', viewerparamContents.toArray());
                              controller.set('expressionContents', expressionContents.toArray());
                              controller.set('sortContents', sortContents.toArray());
                              controller.set('filterContents', filterContents.toArray());
                              controller.set('groupbyContents', groupbyContents.toArray());
                              controller.set('groupingContents', groupingContents.toArray());
                              console.log(groupingContents.get('length'));

                              if (groupingContents.get('length') === 3) {
                                controller.set('groupingLimit', true);
                              }

                              controller.set('summarizeContents', summarizeContents.toArray());
                              controller.set('joinContents', joinContents.toArray());
                              controller.set('selectContents', selectContents.toArray());
                              controller.set('selectedFieldContents', selectedFieldContents.toArray());
                              controller.set('selectedTableContents', selectedTableContents.toArray());
                              controller.set('loadingData', false);
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    },
    actions: {
      editReport: function editReport() {
        this.get('controller').set('isEdit', true);
      },
      cancelEdit: function cancelEdit() {
        this.get('controller').set('isEdit', false);
      },
      deleteReport: function deleteReport(report) {
        var self = this;
        self.store.findRecord('genreport', report.id).then(function (container) {
          self.controllerFor('report-generator').get('reportconfiglist').removeObject(report);
          var AdHocReportsChildren = self.controllerFor('application').get('menus').find(function (menu) {
            return menu.title == 'Ad Hoc Reports';
          })['children'];
          AdHocReportsChildren.forEach(function (childMenu) {
            console.log(childMenu.param);

            if (childMenu.param == report.id) {
              AdHocReportsChildren.removeObject(childMenu);
            }
          });
          container.set('isdelete', true);
          container.save();
          self.transitionTo('report-generator');
        });
      },
      actionAfterSave: function actionAfterSave() {
        this.get('controller').set('isEdit', false);
      },
      createReport: function createReport() {
        var self = this;
        self.controller.set('selectedParamcollection', null);
        self.controller.set('paramContents', Ember.A());
        self.controller.set('availableFieldContents', Ember.A());
        self.controller.set('selection', Ember.Object.create({
          category: '',
          tablename: ''
        })); // Resource Access

        self.controller.set('companyList', Ember.A());
        self.controller.set('organizationList', Ember.A());
        var currentCompany = null;
        var currentOrganization = null;

        if (self.get('formContainer.resourceAccess') === '/') {
          self.store.findAll('organization').then(function (organizations) {
            self.controller.set('organizationList', organizations);
          });
        } else if (self.get('formContainer.resourceAccess') === '/org') {
          currentOrganization = self.get('optmmSetting.companynum.organizationnum');
          self.store.query('company', {
            where: {
              organizationnum: self.get('optmmSetting.companynum.organizationnum.id')
            }
          }).then(function (companies) {
            self.controller.set('companyList', companies);
          });
        } else {
          currentCompany = self.get('optmmSetting.companynum');
          currentOrganization = self.get('optmmSetting.companynum.organizationnum');
        }

        var newReport = self.store.createRecord('genreport', {
          reportname: "",
          reportheader: "",
          reportfooter: "",
          reportsummary: "",
          reporttype: "",
          application: "",
          reportfolder: "",
          importedby: "",
          qbrcreatedby: self.controller.get('user.firstName'),
          whatsql: "",
          share: false,
          filetype: "EXCEL",
          limit: "NA",
          tablecount: 0,
          isdelete: false,
          companynum: currentCompany,
          organizationnum: currentOrganization,
          createdBy: self.controller.get('user')
        });
        self.controller.get('reportconfiglist').addObject(newReport);
        self.controller.set('currentReport', newReport);
        self.controller.set('selectedTableQuery', self.store.query('genreportconfigtable', {
          where: {
            id: -1
          }
        }));
        self.controller.get('selectedTableQuery').then(function (selectedTableContents) {
          self.controller.set('selectedTableContents', selectedTableContents.toArray());
        });
        self.controller.set('selectedFieldQuery', self.store.query('genreportconfigfield', {
          where: {
            id: -1
          }
        }));
        self.controller.get('selectedFieldQuery').then(function (selectedFieldContents) {
          self.controller.set('selectedFieldContents', selectedFieldContents.toArray());
        });
        self.controller.set('selectQuery', self.store.query('genreportconfigselect', {
          where: {
            id: -1
          }
        }));
        self.controller.get('selectQuery').then(function (selectContents) {
          self.controller.set('selectContents', selectContents.toArray());
        });
        self.controller.set('joinQuery', self.store.query('genreportconfigjoin', {
          where: {
            id: -1
          }
        }));
        self.controller.get('joinQuery').then(function (joinContents) {
          self.controller.set('joinContents', joinContents.toArray());
        });
        self.controller.set('summarizeQuery', self.store.query('genreportconfigsummarize', {
          where: {
            id: -1
          }
        }));
        self.controller.get('summarizeQuery').then(function (summarizeContents) {
          self.controller.set('summarizeContents', summarizeContents.toArray());
        });
        self.controller.set('groupbyQuery', self.store.query('genreportconfiggroupby', {
          where: {
            id: -1
          }
        }));
        self.controller.get('groupbyQuery').then(function (groupbyContents) {
          self.controller.set('groupbyContents', groupbyContents.toArray());
        });
        self.controller.set('groupingQuery', self.store.query('genreportconfiggrouping', {
          where: {
            id: -1
          }
        }));
        self.controller.get('groupingQuery').then(function (groupingContents) {
          self.controller.set('groupingContents', groupingContents.toArray());
        });
        self.controller.set('filterQuery', self.store.query('genreportconfigfilter', {
          where: {
            id: -1
          }
        }));
        self.controller.get('filterQuery').then(function (filterContents) {
          self.controller.set('filterContents', filterContents.toArray());
        });
        self.controller.set('sortQuery', self.store.query('genreportconfigsort', {
          where: {
            id: -1
          }
        }));
        self.controller.get('sortQuery').then(function (sortContents) {
          self.controller.set('sortContents', sortContents.toArray());
        });
        self.controller.set('expressionQuery', self.store.query('genreportconfigexpression', {
          where: {
            id: -1
          }
        }));
        self.controller.get('expressionQuery').then(function (expressionContents) {
          self.controller.set('expressionContents', expressionContents.toArray());
        });
        self.controller.get('currentReport').addObserver('organizationnum', function (currentreport) {
          self.controller.set('companyList', Ember.A());

          if (currentreport.get('organizationnum').get('id')) {
            self.store.query('company', {
              where: {
                organizationnum: currentreport.get('organizationnum').get('id')
              }
            }).then(function (companies) {
              self.controller.set('companyList', companies);
            });
          }
        });
      },
      duplicateReport: function duplicateReport() {
        var self = this;
        var selectedTableQuery = null;
        var selectedFieldQuery = null;
        var selectQuery = null;
        var joinQuery = null;
        var summarizeQuery = null;
        var groupbyQuery = null;
        var filterQuery = null;
        var sortQuery = null;
        var expressionQuery = null;
        var selectedTableContents = Ember.A();
        var selectedFieldContents = Ember.A();
        var selectContents = Ember.A();
        var joinContents = Ember.A();
        var summarizeContents = Ember.A();
        var groupbyContents = Ember.A();
        var filterContents = Ember.A();
        var sortContents = Ember.A();
        var expressionContents = Ember.A();
        var newTable;
        var newField;
        var newJoin;
        var newSelect;
        var newGroupby;
        var newFilter;
        var newSort;
        var newExpression;
        var newReport = this.store.createRecord('genreport', {
          reportname: this.controller.get('currentReport').get('reportname') + ' (duplicate)',
          reportheader: this.controller.get('currentReport').get('reportheader'),
          reportfooter: this.controller.get('currentReport').get('reportfooter'),
          reportsummary: this.controller.get('currentReport').get('reportsummary'),
          reporttype: this.controller.get('currentReport').get('reporttype'),
          application: this.controller.get('currentReport').get('application'),
          reportfolder: this.controller.get('currentReport').get('reportfolder'),
          lastimportdate: this.controller.get('currentReport').get('lastimportdate'),
          importedby: this.controller.get('currentReport').get('importedby'),
          qbrcreatedby: this.controller.get('currentReport').get('qbrcreatedby'),
          whatsql: this.controller.get('currentReport').get('whatsql'),
          share: this.controller.get('currentReport').get('share'),
          fromtable: this.controller.get('currentReport').get('fromtable'),
          filetype: this.controller.get('currentReport').get('filetype'),
          companynum: this.controller.get('currentReport').get('companynum')
        });
        newReport.save().then(function (report) {
          selectedTableQuery = self.store.query('genreportconfigtable', {
            where: {
              id: -1
            }
          });
          selectedTableQuery.then(function (queryResult) {
            selectedTableContents = queryResult;
          });
          selectedFieldQuery = self.store.query('genreportconfigfield', {
            where: {
              id: -1
            }
          });
          selectedFieldQuery.then(function (queryResult) {
            selectedFieldContents = queryResult;
          });
          selectQuery = self.store.query('genreportconfigselect', {
            where: {
              id: -1
            }
          });
          selectQuery.then(function (queryResult) {
            selectContents = queryResult;
          });
          joinQuery = self.store.query('genreportconfigjoin', {
            where: {
              id: -1
            }
          });
          joinQuery.then(function (queryResult) {
            joinContents = queryResult;
          });
          summarizeQuery = self.store.query('genreportconfigsummarize', {
            where: {
              id: -1
            }
          });
          summarizeQuery.then(function (queryResult) {
            summarizeContents = queryResult;
          });
          groupbyQuery = self.store.query('genreportconfiggroupby', {
            where: {
              id: -1
            }
          });
          groupbyQuery.then(function (queryResult) {
            groupbyContents = queryResult;
          });
          filterQuery = self.store.query('genreportconfigfilter', {
            where: {
              id: -1
            }
          });
          filterQuery.then(function (queryResult) {
            filterContents = queryResult;
          });
          sortQuery = self.store.query('genreportconfigsort', {
            where: {
              id: -1
            }
          });
          sortQuery.then(function (queryResult) {
            sortContents = queryResult;
          });
          expressionQuery = self.store.query('genreportconfigexpression', {
            where: {
              id: -1
            }
          });
          expressionQuery.then(function (queryResult) {
            expressionContents = queryResult;
          });
          self.controller.get('selectedTableContents').forEach(function (table) {
            newTable = self.store.createRecord('genreportconfigtable', {
              category: table.get('category'),
              tablename: table.get('tablename'),
              modellabel: table.get('modellabel'),
              genreportnum: report
            });
            newTable.save();
          });
          self.controller.get('selectedFieldContents').forEach(function (field) {
            newField = self.store.createRecord('genreportconfigfield', {
              tablename: field.get('tablename'),
              modellabel: field.get('modellabel'),
              columnname: field.get('columnname'),
              columnlabel: field.get('columnlabel'),
              genreportnum: report
            });
            newField.save();
          });
          self.controller.get('joinContents').forEach(function (join) {
            newJoin = self.store.createRecord('genreportconfigjoin', {
              jointable: join.get('jointable'),
              fromtable: join.get('fromtable'),
              fromid: join.get('fromid'),
              totable: join.get('totable'),
              toid: join.get('toid'),
              genreportnum: report
            });
            newJoin.save();
          });
          self.controller.get('selectContents').forEach(function (select) {
            newSelect = self.store.createRecord('genreportconfigselect', {
              tablename: select.get('tablename'),
              columnname: select.get('columnname'),
              label: select.get('label'),
              method: select.get('method'),
              genreportnum: report
            });
            newSelect.save();
          });
          self.controller.get('summarizeContents').forEach(function (summarize) {
            newSummarize = self.store.createRecord('genreportconfigsummarize', {
              function: summarize.get('function'),
              columnname: summarize.get('columnname'),
              genreportnum: report
            });
            newSummarize.save();
          });
          self.controller.get('groupbyContents').forEach(function (groupby) {
            newGroupby = self.store.createRecord('genreportconfiggroupby', {
              tablename: groupby.get('tablename'),
              columnname: groupby.get('columnname'),
              function: groupby.get('function'),
              genreportnum: report
            });
            newGroupby.save();
          });
          self.controller.get('filterContents').forEach(function (filter) {
            newFilter = self.store.createRecord('genreportconfigfilter', {
              tablename: filter.get('tablename'),
              columnname: filter.get('columnname'),
              function: filter.get('function'),
              value: filter.get('value'),
              genreportnum: report
            });
            newFilter.save();
          });
          self.controller.get('sortContents').forEach(function (sort) {
            newSort = self.store.createRecord('genreportconfigsort', {
              tablename: sort.get('tablename'),
              columnname: sort.get('columnname'),
              sort: sort.get('sort'),
              genreportnum: report
            });
            newSort.save();
          });
          self.controller.get('expressionContents').forEach(function (expression) {
            newExpression = self.store.createRecord('genreportconfigexpression', {
              name: expression.get('name'),
              value: expression.get('value'),
              datatype: expression.get('datatype'),
              category: expression.get('category'),
              genreportnum: report
            });
            newExpression.save();
          });
          self.controller.get('reportconfiglist').addObject(report);
        });
      },
      addTable: function addTable() {
        var self = this;
        this.controller.get('selectedEntityCollection').forEach(function (entity) {
          var astable = entity.get('tablename') + '_' + self.controller.get('currentReport.tablecount');
          var newTable = self.store.createRecord('genreportconfigtable', {
            category: entity.get('category'),
            tablename: entity.get('tablename'),
            modellabel: entity.get('modellabel'),
            astable: astable,
            genreportnum: self.controller.get('currentReport')
          });
          self.controller.set('currentReport.tablecount', self.controller.get('currentReport.tablecount') + 1);
          self.controller.get('selectedTableContents').addObject(newTable);
        });
        this.controller.set('selectedEntityCollection', Ember.A());
      },
      clearSelectedTable: function clearSelectedTable() {
        this.controller.set('selectedEntityCollection', Ember.A());
      },
      fetchField: function fetchField() {
        var self = this;
        var field;
        this.controller.set('availableFieldContents', Ember.A());
        this.controller.get('selectedTableCollection').forEach(function (table) {
          self.store.query('entitymapping', {
            category: table.get('category'),
            tablename: table.get('tablename')
          }).then(function (results) {
            if (!Ember.isEmpty(results)) {
              results.forEach(function (result) {
                field = Ember.Object.create({
                  category: result.get('category'),
                  columnlabel: result.get('columnlabel'),
                  columnname: result.get('columnname'),
                  datatype: result.get('datatype'),
                  modellabel: result.get('modellabel'),
                  tablename: result.get('tablename'),
                  astable: table.get('astable')
                });
                self.controller.get('availableFieldContents').pushObject(field);
              });
            }
          });
        });
        this.controller.set('selectedTableCollection', Ember.A());
      },
      removeAllTable: function removeAllTable() {
        var self = this;
        this.controller.get('selectedTableContents').forEach(function (table) {
          try {
            self.store.findRecord('genreportconfigtable', table.id).then(function (container) {
              self.controller.get('selectedTableContents').removeObject(table);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedTableContents').removeObject(table);
          }
        });
        this.controller.set('selectedTableCollection', Ember.A());
      },
      removeSelectedTable: function removeSelectedTable() {
        var self = this;
        this.controller.get('selectedTableCollection').forEach(function (table) {
          try {
            self.store.findRecord('genreportconfigtable', table.id).then(function (container) {
              self.controller.get('selectedTableContents').removeObject(table);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedTableContents').removeObject(table);
          }
        });
        this.controller.set('selectedTableCollection', Ember.A());
      },
      selectAllTable: function selectAllTable() {
        this.controller.set('selectedTableCollection', this.controller.get('selectedTableContents'));
      },
      uncheckAllTable: function uncheckAllTable() {
        this.controller.set('selectedTableCollection', Ember.A());
      },
      addAllField: function addAllField() {
        var self = this;
        var newField;
        this.controller.get('availableFieldContents').forEach(function (field) {
          newField = self.store.createRecord('genreportconfigfield', {
            tablename: field.get('tablename'),
            modellabel: field.get('modellabel'),
            columnname: field.get('columnname'),
            columnlabel: field.get('columnlabel'),
            astable: field.get('astable'),
            ascolumn: field.get('astable') + '.' + field.get('columnname'),
            genreportnum: self.controller.get('currentReport')
          });

          if (!Ember.isEmpty(self.controller.get('selectedFieldContents').get('firstObject'))) {
            var isExist = false;
            self.controller.get('selectedFieldContents').forEach(function (field) {
              if (field.get('tablename') === newField.get('tablename') && field.get('columnname') === newField.get('columnname') && field.get('astable') === newField.get('astable')) {
                isExist = true;
              }
            });

            if (!isExist) {
              self.controller.get('selectedFieldContents').addObject(newField);
            }
          } else {
            self.controller.get('selectedFieldContents').addObject(newField);
          }
        });
        this.controller.set('availableFieldCollection', Ember.A());
      },
      addSelectedField: function addSelectedField() {
        var self = this;
        this.controller.get('availableFieldCollection').forEach(function (field) {
          var newField = self.store.createRecord('genreportconfigfield', {
            tablename: field.get('tablename'),
            modellabel: field.get('modellabel'),
            columnname: field.get('columnname'),
            columnlabel: field.get('columnlabel'),
            astable: field.get('astable'),
            ascolumn: field.get('astable') + '.' + field.get('columnname'),
            genreportnum: self.controller.get('currentReport')
          });

          if (!Ember.isEmpty(self.controller.get('selectedFieldContents').get('firstObject'))) {
            var isExist = false;
            self.controller.get('selectedFieldContents').forEach(function (field) {
              if (field.get('tablename') === newField.get('tablename') && field.get('columnname') === newField.get('columnname') && field.get('astable') === newField.get('astable')) {
                isExist = true;
              }
            });

            if (!isExist) {
              self.controller.get('selectedFieldContents').addObject(newField);
            }
          } else {
            self.controller.get('selectedFieldContents').addObject(newField);
          }

          self.controller.set('availableFieldCollection', Ember.A());
        });
      },
      selectAllField: function selectAllField() {
        this.controller.set('availableFieldCollection', this.controller.get('availableFieldContents'));
      },
      uncheckAllField: function uncheckAllField() {
        this.controller.set('availableFieldCollection', Ember.A());
      },
      removeAllField: function removeAllField() {
        var self = this;
        this.controller.get('selectedFieldContents').forEach(function (field) {
          self.controller.get('selectedFieldCollection').addObject(field);
        });
        this.controller.get('selectedFieldCollection').forEach(function (field) {
          try {
            self.store.findRecord('genreportconfigfield', field.id).then(function (container) {
              self.controller.get('selectedFieldContents').removeObject(field);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedFieldContents').removeObject(field);
          }
        });
        this.controller.set('selectedFieldCollection', Ember.A());
      },
      removeSelectedField: function removeSelectedField() {
        var self = this;
        this.controller.get('selectedFieldCollection').forEach(function (field) {
          try {
            self.store.findRecord('genreportconfigfield', field.id).then(function (container) {
              self.controller.get('selectedFieldContents').removeObject(field);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedFieldContents').removeObject(field);
          }
        });
        this.controller.set('selectedFieldCollection', Ember.A());
      },
      selectAllSelectedField: function selectAllSelectedField() {
        this.controller.set('selectedFieldCollection', this.controller.get('selectedFieldContents'));
      },
      uncheckAlSelectedlField: function uncheckAlSelectedlField() {
        this.controller.set('selectedFieldCollection', Ember.A());
      },
      addNewSelect: function addNewSelect() {
        var newSelect = this.store.createRecord('genreportconfigselect', {
          method: 'NA',
          genreportnum: this.controller.get('currentReport')
        });
        this.controller.get('selectContents').addObject(newSelect);
      },
      deleteSelect: function deleteSelect(select) {
        var self = this;

        try {
          this.store.findRecord('genreportconfigselect', select.id).then(function (container) {
            self.controller.get('selectContents').removeObject(select);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.controller.get('selectContents').removeObject(select);
        }
      },
      addNewJoin: function addNewJoin() {
        var newJoin = this.store.createRecord('genreportconfigjoin', {
          type: 'LEFT_OUTER_JOIN',
          genreportnum: this.controller.get('currentReport')
        });
        this.controller.get('joinContents').addObject(newJoin);
      },
      deleteJoin: function deleteJoin(join) {
        var self = this;

        try {
          this.store.findRecord('genreportconfigjoin', join.id).then(function (container) {
            self.controller.get('joinContents').removeObject(join);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.controller.get('joinContents').removeObject(join);
        }
      },
      addNewGroupby: function addNewGroupby() {
        var newGroupby = this.store.createRecord('genreportconfiggroupby', {
          function: 'NA',
          genreportnum: this.controller.get('currentReport')
        });
        this.controller.get('groupbyContents').addObject(newGroupby);
      },
      deleteGroupby: function deleteGroupby(groupby) {
        var self = this;

        try {
          this.store.findRecord('genreportconfiggroupby', groupby.id).then(function (container) {
            self.controller.get('groupbyContents').removeObject(groupby);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.controller.get('groupbyContents').removeObject(groupby);
        }
      },
      addNewGrouping: function addNewGrouping() {
        var self = this;
        var newGrouping = this.store.createRecord('genreportconfiggrouping', {
          level: 1,
          genreportnum: self.controller.get('currentReport')
        });
        this.controller.get('groupingContents').addObject(newGrouping);

        if (this.controller.get('groupingContents').get('length') === 3) {
          this.controller.set('groupingLimit', true);
        }
      },
      deleteGrouping: function deleteGrouping(grouping) {
        var self = this;

        try {
          this.store.findRecord('genreportconfiggrouping', grouping.id).then(function (container) {
            self.controller.get('groupingContents').removeObject(grouping);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.controller.get('groupingContents').removeObject(grouping);
        }

        this.controller.set('groupingLimit', false);
      },
      addSummarizeValue: function addSummarizeValue() {
        var newSummarize = this.store.createRecord('genreportconfigsummarize', {
          function: this.controller.get('summarizeFunction'),
          columnname: this.controller.get('summarizeSelectedField'),
          genreportnum: this.controller.get('currentReport')
        });
        this.controller.get('summarizeContents').addObject(newSummarize);
      },
      clearSummarizeValue: function clearSummarizeValue() {
        this.controller.set('summarizeFunction', 'total');
        this.controller.set('summarizeSelectedField', null);
      },
      removeAllSummarize: function removeAllSummarize() {
        var self = this;
        this.controller.get('summarizeContents').forEach(function (summarize) {
          try {
            self.store.findRecord('genreportconfigsummarize', summarize.id).then(function (container) {
              self.controller.get('summarizeContents').removeObject(summarize);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('summarizeContents').removeObject(summarize);
          }
        });
      },
      removeSelectedSummarize: function removeSelectedSummarize() {
        var self = this;
        this.controller.get('summarizeCollection').forEach(function (summarize) {
          try {
            self.store.findRecord('genreportconfigsummarize', summarize.id).then(function (container) {
              self.controller.get('summarizeContents').removeObject(summarize);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('summarizeContents').removeObject(summarize);
          }
        });
      },
      addNewFilter: function addNewFilter() {
        var newFilter = this.store.createRecord('genreportconfigfilter', {
          function: 'EQUAL',
          genreportnum: this.controller.get('currentReport')
        });
        this.controller.get('filterContents').addObject(newFilter);
      },
      addNewSort: function addNewSort() {
        var newSort = this.store.createRecord('genreportconfigsort', {
          sort: 'ascending',
          genreportnum: this.controller.get('currentReport')
        });
        this.controller.get('sortContents').addObject(newSort);
      },
      removeAllFilter: function removeAllFilter() {
        var self = this;
        this.controller.get('filterContents').forEach(function (filter) {
          try {
            self.store.findRecord('genreportconfigfilter', filter.id).then(function (container) {
              self.controller.get('filterContents').removeObject(filter);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('filterContents').removeObject(filter);
          }
        });
      },
      removeAllSort: function removeAllSort() {
        var self = this;
        this.controller.get('sortContents').forEach(function (sort) {
          try {
            self.store.findRecord('genreportconfigsort', sort.id).then(function (container) {
              self.controller.get('sortContents').removeObject(sort);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('sortContents').removeObject(sort);
          }
        });
      },
      removeFilter: function removeFilter(filter) {
        var self = this;

        try {
          this.store.findRecord('genreportconfigfilter', filter.id).then(function (container) {
            self.controller.get('filterContents').removeObject(filter);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('filterContents').removeObject(filter);
        }
      },
      removeSort: function removeSort(sort) {
        var self = this;

        try {
          this.store.findRecord('genreportconfigsort', sort.id).then(function (container) {
            self.controller.get('sortContents').removeObject(sort);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.controller.get('sortContents').removeObject(sort);
        }
      },
      addCalculateSelection: function addCalculateSelection() {
        var self = this;
        this.controller.get('calculateSelectedField').forEach(function (field) {
          self.controller.set('expressionValue', self.controller.get('expressionValue').concat(field.get('tablename') + '.' + field.get('columnname') + ','));
        });
      },
      clearCalculateSelection: function clearCalculateSelection() {
        this.controller.set('calculateSelectedField', Ember.A());
      },
      clearExpression: function clearExpression() {
        this.controller.set('expressionValue', '');
        this.controller.set('expressionName', null);
        this.controller.set('expressionDatatype', 'string');
        this.controller.set('expressionCategory', null);
      },
      addExpression: function addExpression() {
        var newExpression = this.store.createRecord('genreportconfigexpression', {
          value: this.controller.get('expressionValue'),
          name: this.controller.get('expressionName'),
          datatype: this.controller.get('expressionDatatype'),
          category: this.controller.get('expressionCategory'),
          genreportnum: this.controller.get('currentReport')
        });
        this.controller.get('expressionContents').addObject(newExpression);
      },
      removeAllExpression: function removeAllExpression() {
        var self = this;
        this.controller.get('expressionContents').forEach(function (expression) {
          try {
            self.store.findRecord('genreportconfigexpression', expression.id).then(function (container) {
              self.controller.get('expressionContents').removeObject(expression);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('expressionContents').removeObject(expression);
          }
        });
      },
      removeSelectedExpression: function removeSelectedExpression() {
        var self = this;
        this.controller.get('expressionCollection').forEach(function (expression) {
          try {
            self.store.findRecord('genreportconfigexpression', expression.id).then(function (container) {
              self.controller.get('expressionContents').removeObject(expression);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('expressionContents').removeObject(expression);
          }
        });
      },
      addParamCollection: function addParamCollection() {
        var self = this;
        var newParamcollection = this.store.createRecord('genreportparamcollection', {
          collectionname: 'new param collection',
          genreportnum: this.controller.get('currentReport')
        });
        newParamcollection.save().then(function (paramcollection) {
          self.controller.get('paramcollectionContents').addObject(paramcollection);
          self.controller.set('selectedParamcollection', paramcollection);
          var paramQuery = self.store.query('genreportparam', {
            where: {
              genreportparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.controller.set('paramContents', paramContents.toArray());
          });
        });
      },
      deleteParamCollection: function deleteParamCollection(paramcollection) {
        var self = this;
        this.store.findRecord('genreportparamcollection', paramcollection.id).then(function (container) {
          self.controller.get('paramcollectionContents').removeObject(paramcollection);
          self.controller.set('selectedParamcollection', null);
          container.set('isdelete', true);
          container.save();
        });
      },
      addParam: function addParam(paramcollection) {
        var self = this;
        var newParam = this.store.createRecord('genreportparam', {
          paramtype: "filter",
          filtertype: "EQUAL",
          genreportparamcollectionnum: paramcollection,
          genreportnum: self.controller.get('currentReport')
        });
        self.controller.get('paramContents').addObject(newParam);
      },
      deleteParam: function deleteParam(param) {
        var self = this;

        try {
          this.store.findRecord('genreportparam', param.id).then(function (container) {
            self.controller.get('paramContents').removeObject(param);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          this.controller.get('paramContents').removeObject(param);
        }
      },
      saveParam: function saveParam(paramcollection) {
        var self = this;
        var whatsql;
        var newParamresult;
        var promises = Ember.A();
        var params = this.controller.get('paramContents');
        this.controller.set('paramLoading', true);
        promises.push(this.controller.get('selectedParamcollection').save());
        this.controller.get('paramContents').forEach(function (content) {
          return promises.push(content.save());
        });

        if (this.controller.get('selectedParamcollection')) {
          whatsql = JSON.parse(this.controller.get('currentReport').get('whatsql'));
          params.forEach(function (param) {
            whatsql['wheres'].push({
              'where': param.get('genreportconfigselect').get('ascolumn'),
              'function': param.get('filtertype'),
              'value': param.get('filtervalue')
            });
          });
          newParamresult = this.store.createRecord('genreportparamresult', {
            whatsql: JSON.stringify(whatsql),
            genreportnum: this.controller.get('currentReport'),
            genreportparamcollectionnum: this.controller.get('selectedParamcollection')
          });
          Ember.RSVP.Promise.all(promises).then(function () {
            newParamresult.save().then(function (paramresult) {
              var name = self.controller.get('user.firstName') + '\ ' + self.controller.get('user.lastName');
              var data = {
                "dabKpi": self.controller.get('currentReport.id'),
                "dabParam": paramresult.get('genreportparamcollectionnum.id'),
                "generatedBy": name,
                "genParamType": "Admin"
              };
              var url = "".concat(this.store.adapterFor('application').get('serverHost'), "/api/v1/genreports/callApi");

              _jquery.default.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                success: function success(result) {
                  window.open(result);
                },
                error: function error(xhr, status, _error) {
                  console.log(_error);
                }
              });
            });
          });
        } else {}

        this.controller.set('paramLoading', false);
      },
      setParamCollection: function setParamCollection(paramcollection) {
        var self = this;
        this.controller.set('paramLoading', true);

        if (paramcollection) {
          var paramQuery = this.store.query('genreportparam', {
            where: {
              genreportparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.controller.set('paramContents', paramContents.toArray());
            self.controller.set('selectedParamcollection', paramcollection);
            self.controller.set('paramLoading', false);
          });
        } else {
          this.controller.set('selectedParamcollection', null);
          this.controller.set('paramLoading', false);
        }
      },
      setCategory: function setCategory(category) {
        var self = this;

        if (category) {
          var subcategoryQuery = this.store.query('genreportsubcategory', {
            where: {
              genreportcategorynum: category.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          subcategoryQuery.then(function (subcategoryContents) {
            self.controller.set('subcategoryContents', subcategoryContents.toArray());
            self.controller.set('selectedCategory', category);
            self.controller.set('subcategoryLoading', false);
          });
        } else {
          this.controller.set('selectedCategory', null);
          this.controller.set('selectedSubcategory', null);
          this.controller.set('subcategorycontents', Ember.A());
        }
      },
      toggleCategoryInput: function toggleCategoryInput() {
        if (this.controller.get('selectedCategory')) {
          this.controller.set('categoryValue', this.controller.get('selectedCategory').get('category'));
        } else {
          this.controller.set('categoryValue', null);
        }

        this.controller.set('categoryInput', !this.controller.get('categoryInput'));
      },
      addCategory: function addCategory() {
        var self = this;
        var newCategory = this.store.createRecord('genreportcategory', {
          category: this.controller.get('categoryValue'),
          isdelete: false
        });
        newCategory.save().then(function (category) {
          self.controller.get('categoryContents').addObject(category);
          self.controller.set('categoryInput', false);
          self.controller.set('categoryValue', null);
        });
      },
      deleteCategory: function deleteCategory(category) {
        var self = this;
        var promises = Ember.A();
        this.store.query('genreport', {
          genreportcategorynum: category.id
        }).then(function (genreports) {
          genreports.forEach(function (genreport) {
            genreport.set('genreportcategorynum', null);
            genreport.set('genreportsubcategorynum', null);
            promises.push(genreport.save());
          });
          self.store.query('genreportsubcategory', {
            genreportcategorynum: category.id,
            isdelete: false
          }).then(function (genreportsubcategories) {
            genreportsubcategories.forEach(function (genreportsubcategory) {
              genreportsubcategory.set('isdelete', true);
              promises.push(genreportsubcategory.save());
            });
            self.store.findRecord('genreportcategory', category.id).then(function (container) {
              self.controller.get('categoryContents').removeObject(category);
              self.controller.set('selectedCategory', null);
              container.set('isdelete', true);
              promises.push(container.save());
              Ember.RSVP.Promise.all(promises);
            });
          });
        });
      },
      editCategory: function editCategory(category) {
        var self = this;
        this.store.findRecord('genreportcategory', category.id).then(function (container) {
          container.set('category', self.controller.get('categoryValue'));
          container.save().then(function (result) {
            self.controller.set('categoryInput', false);
            self.controller.get('categoryContents').addObject(result);
          });
        });
      },
      setSubcategory: function setSubcategory(subcategory) {
        if (subcategory) {
          this.controller.set('selectedSubcategory', subcategory);
        } else {
          this.controller.set('selectedSubcategory', null);
        }
      },
      toggleSubcategoryInput: function toggleSubcategoryInput() {
        if (this.controller.get('selectedSubcategory')) {
          this.controller.set('subcategoryValue', this.controller.get('selectedSubcategory').get('subcategory'));
        } else {
          this.controller.set('subcategoryValue', null);
        }

        this.controller.set('subcategoryInput', !this.controller.get('subcategoryInput'));
      },
      addSubcategory: function addSubcategory() {
        var self = this;
        var newSubcategory = this.store.createRecord('genreportsubcategory', {
          subcategory: this.controller.get('subcategoryValue'),
          genreportcategorynum: this.controller.get('selectedCategory'),
          isdelete: false
        });
        newSubcategory.save().then(function (subcategory) {
          self.controller.get('subcategoryContents').addObject(subcategory);
          self.controller.set('subcategoryInput', false);
          self.controller.set('subcategoryValue', null);
        });
      },
      editSubcategory: function editSubcategory(subcategory) {
        var self = this;
        this.store.findRecord('genreportsubcategory', subcategory.id).then(function (container) {
          container.set('subcategory', self.controller.get('subcategoryValue'));
          container.save().then(function (result) {
            self.controller.set('subcategoryInput', false);
            self.controller.get('subcategoryContents').addObject(result);
          });
        });
      },
      deleteSubcategory: function deleteSubcategory(subcategory) {
        var self = this;
        var promises = Ember.A();
        this.store.query('genreport', {
          genreportsubcategorynum: subcategory.id
        }).then(function (genreports) {
          genreports.forEach(function (genreport) {
            genreport.set('genreportsubcategorynum', null);
            promises.push(genreport.save());
          });
          self.store.findRecord('genreportsubcategory', subcategory.id).then(function (genreportsubcategory) {
            self.controller.get('subcategoryContents').removeObject(subcategory);
            self.controller.set('selectedSubcategory', null);
            genreportsubcategory.set('isdelete', true);
            promises.push(genreportsubcategory.save());
            Ember.RSVP.Promise.all(promises);
          });
        });
      },
      saveviewersetting: function saveviewersetting() {
        var self = this;
        this.controller.set('viewerissaving', true);
        var promises = Ember.A();
        this.controller.get('viewerparamContents').forEach(function (content) {
          return promises.push(content.save());
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          self.controller.set('viewerissaving', false);
        });
      },
      addviewerparam: function addviewerparam() {
        var self = this;
        var newParam = this.store.createRecord('genreportparamconfiguser', {
          genreportnum: this.controller.get('currentReport')
        });
        newParam.save().then(function (result) {
          return self.controller.get('viewerparamContents').addObject(result);
        });
      },
      deleteviewerparam: function deleteviewerparam(param) {
        var self = this;
        this.store.findRecord('genreportparamconfiguser', param.id).then(function (container) {
          container.set('isdelete', true);
          self.controller.get('viewerparamContents').removeObject(container);
          container.save();
        });
      },
      willTransition: function willTransition(transition) {
        //Component Tab Handler on willTransition event
        this.controller.set('currentRoute', transition.targetName);
        this.controller.set('componentsShown', false);
      },
      createCustomDate: function createCustomDate() {},
      changeValueformat: function changeValueformat() {}
    }
  });

  _exports.default = _default;
});