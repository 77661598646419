define("client/pods/components/w3office-forms/advanced-components/google-recaptcha/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PP/4TzN+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"authoring\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"row componentContainer col-md-12 col-12\"],[10,\"draggable\",\"true\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row col-md-12 col-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\" This is a Google reCaptcha field. reCaptcha is not shown on authoring page\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field-button-group\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",false],[12,\"class\",\"iconButton btn-info\"],[3,\"action\",[[23,0,[]],\"deleteComponent\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"span\",false],[12,\"class\",\"d-block d-sm-none iconButton btn-info\"],[3,\"action\",[[23,0,[]],\"moveComponent\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-arrows-alt\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"span\",false],[12,\"class\",\"iconButton btn-info\"],[3,\"action\",[[23,0,[]],\"showProperties\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-cog\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field-mark border-top border-left px-1 py-1\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"strong\",true],[8],[1,[24,[\"field\",\"formcomponent\",\"label\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"component\",[\"w3office-forms/component-message\"],[[\"properties\",\"submissionRecordingType\",\"columnName\"],[[24,[\"field\",\"properties\"]],[24,[\"submissionRecordingType\"]],[24,[\"field\",\"columnName\"]]]]],false],[0,\"\\n\\n\"],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"id\",\"recapt\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/advanced-components/google-recaptcha/template.hbs"
    }
  });

  _exports.default = _default;
});