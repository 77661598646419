define("client/pods/dashboard-builder/dabkpi/access/new/route", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _applicationRouteMixin, _authenticatedRouteMixin, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, {
    dabkpi_id: null,
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      this._super();

      this.set("dabkpi_id", transition.params["dashboard-builder.dabkpi.access"].dabkpi_id);
    },
    model: function model(param) {
      // console.log("NEW ACCESS");
      // console.log(this.get("dabkpi_id"));
      return this.store.createRecord("dabkpiaccess");
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set('model', model);
      this.store.findRecord('dabkpi', this.get("dabkpi_id")).then(function (dabkpi) {
        controller.set('model.dabkpi', dabkpi);
      });
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      var dabkpinum = this.modelFor('dashboard-builder.dabkpi.access');
      controller.set('dabkpititle', dabkpinum.get('title'));
      controller.set("roles", null);
      this.store.findRecord('user', this.get('authObject.data.user').id).then(function (user) {
        controller.set('currentUser', user);
      });
      this.store.queryRecord('application', {
        appId: controller.get("dashboardtitle")
      }).then(function (app) {
        _jquery.default.ajax({
          type: "POST",
          data: {
            dabkpi: self.get("dabkpi_id"),
            app: app.id
          },
          url: self.store.adapterFor('application').get('namespace') + '/dabkpiaccesses/getRoles',
          success: function success(response) {
            if (response.roles.length && response.allRoles === false) {
              self.store.query('role', {
                id: response.roles
              }).then(function (roles) {
                controller.set('roles', roles);
              });
            }
          },
          error: function error(xhr, status, _error) {
            console.log('Error ' + _error);
          }
        });
      });
    },
    actions: {
      submit: function submit(model) {
        var self = this;
        var controller = this.get("controller");
        model.set('createdBy', controller.get("currentUser"));
        model.set('updatedBy', controller.get('currentUser'));
        model.save().then(function () {
          self.transitionTo('dashboard-builder.dabkpi.access.index');
        });
      }
    }
  });

  _exports.default = _default;
});