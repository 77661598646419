define("client/pods/dashboard-builder/dabkpi/access/index/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/dashboard-builder/paging-common", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin, _pagingCommon, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _pagingCommon.default, _checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    model: function model(param) {
      return this.store.queryRecord('dabkpiaccess', {
        dabkpi: param.dabkpi_id
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller, model) {
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      controller.set('dashboardtitle', dashboardnum.get('dashboardId'));
      var dabkpinum = this.modelFor('dashboard-builder.dabkpi.access');
      controller.set('dabkpititle', dabkpinum.get('title'));
      var modelQuery = this.store.query('dabkpiaccess', {
        where: {
          dabkpi: dabkpinum.id
        },
        sort: 'id ASC'
      });

      this._super(controller, model, modelQuery);
    },
    actions: {
      deleteAccess: function deleteAccess(entry) {
        var self = this;
        this.store.findRecord('dabkpiaccess', entry.id).then(function (container) {
          self.controller.get('modelConArr').removeObject(entry);
          self.controller.get('modelCon').removeObject(entry);
          self.controller.set('modelCount', self.controller.get('modelConArr').get('length'));

          if (self.controller.get('modelCount') === 0) {
            self.controller.set('totalModel', 1);
          } else {
            self.controller.set('totalPage', Math.ceil(self.controller.get('modelCount') / 10));
          }

          if (self.controller.get('listPage') < self.controller.get('totalPage')) {
            var firstCount = self.controller.get('firstList');
            var postCount = self.controller.get('postLimit');
            self.controller.set('modelCon', self.controller.get('modelConArr').slice(firstCount, postCount));
          } // container.set('isDelete', true);


          container.destroyRecord();
        });
      }
    }
  });

  _exports.default = _default;
});