define("client/pods/process-builder/unauthorized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/BeLpK9",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col px-1 px-md-4 mt-5 pt-2 pt-md-4\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"loading-pane\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"loading-message card pt-3\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h3\",true],[8],[0,\"You are not authorised to access this \\\"\"],[1,[22,\"pageName\"],false],[0,\"\\\" page.\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\tPlease contact your application administrator to resolve this issue.\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/process-builder/unauthorized/template.hbs"
    }
  });

  _exports.default = _default;
});