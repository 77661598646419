define("client/pods/components/w3office-forms/collection-components/email-confirmation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    originalEmail: "",
    confirmEmail: "",
    dragStart: function dragStart(event) {
      //Transfer Field Properties
      this.set('formContainer.fieldHolder', this.get('field'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      //automatically set field validationType to email
      if (!Ember.isEmpty(this.get('field'))) {
        this.get('field').set('validationType', 'email');
      }
    },
    actions: {
      deleteComponent: function deleteComponent() {
        //Delete Component
        this.deleteComponent(this.get('field'));
      },
      observeRequired: function observeRequired() {
        this.get('field').save();
      },
      observeEmail: function observeEmail() {
        if (this.get('field').get('required') && !Ember.isEmpty(this.get('originalEmail')) && !Ember.isEmpty(this.get('confirmEmail')) || !this.get('field').get('required') && !(Ember.isEmpty(this.get('originalEmail')) ^ Ember.isEmpty(this.get('confirmEmail')))) {
          if (this.get('originalEmail') === this.get('confirmEmail')) {
            this.get('field').set('isInvalid', false);
            this.get('field').set('entryContainer', this.get('originalEmail'));
          } else {
            this.get('field').set('isInvalid', true);
            this.get('field').set('entryContainer', "");
          }
        } else {
          this.get('field').set('entryContainer', "");
        }
      }
    }
  });

  _exports.default = _default;
});