define("client/pods/users-roles/users/index/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "papaparse", "axios"], function (_exports, _jquery, _checkUserRole, _papaparse, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    userId: '',
    groupCon: '',
    groupName: '',
    actionList: null,
    beforeModel: function beforeModel() {
      // Setup available actions to be send in the check-user-role mixin
      var Variable = Ember.Object.extend({
        action: null,
        varName: null,
        status: false
      });
      var actions = [Variable.create({
        action: 'Create User',
        varName: 'canCreateUser',
        status: false
      }), Variable.create({
        action: 'Edit User',
        varName: 'canEditUser',
        status: false
      }), Variable.create({
        action: 'Delete User',
        varName: 'canDeleteUser',
        status: false
      }), Variable.create({
        action: 'Assign Group to User',
        varName: 'canAssign',
        status: false
      }), Variable.create({
        action: 'Create Group',
        varName: 'canCreateGroup',
        status: false
      }), Variable.create({
        action: 'Edit Group',
        varName: 'canEditGroup',
        status: false
      }), Variable.create({
        action: 'Delete Group',
        varName: 'canDeleteGroup',
        status: false
      })];
      this.set('actionList', actions);

      this._super();
    },
    setupController: function setupController(controller, model) {
      controller.set('hasModel', false);
      controller.set('loading', true);
      controller.set('listPage', 1);
      controller.set('totalPage', 1);
      controller.set('limit', 10);
      controller.set('sortBy', '"createdAt"');
      controller.set('sortOrder', 'DESC');
      controller.set('searchInput', '');
      controller.set('searchColumn', 'email'); // Initialized the variables for the actions

      controller.set('canCreateUser', false);
      controller.set('canEditUser', false);
      controller.set('canDeleteUser', false);
      controller.set('canAssign', false);
      controller.set('canCreateGroup', false);
      controller.set('canEditGroup', false);
      controller.set('canDeleteGroup', false);
      this.getUsers(); // Re-set the action value to the value obtained from the check-user-role mixin

      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateUser') {
          controller.set('canCreateUser', action.get('status'));
        }

        if (action.get('varName') === 'canEditUser') {
          controller.set('canEditUser', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteUser') {
          controller.set('canDeleteUser', action.get('status'));
        }

        if (action.get('varName') === 'canAssign') {
          controller.set('canAssign', action.get('status'));
        }

        if (action.get('varName') === 'canCreateGroup') {
          controller.set('canCreateGroup', action.get('status'));
        }

        if (action.get('varName') === 'canEditGroup') {
          controller.set('canEditGroup', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteGroup') {
          controller.set('canDeleteGroup', action.get('status'));
        }
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();

      var controller = this.get('controller');
      /* 
          Re-set the action value to the value obtained from the check-user-role mixin. 
          This will guarantee that the value is already final from the mixin
      */

      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateUser') {
          controller.set('canCreateUser', action.get('status'));
        }

        if (action.get('varName') === 'canEditUser') {
          controller.set('canEditUser', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteUser') {
          controller.set('canDeleteUser', action.get('status'));
        }

        if (action.get('varName') === 'canAssign') {
          controller.set('canAssign', action.get('status'));
        }

        if (action.get('varName') === 'canCreateGroup') {
          controller.set('canCreateGroup', action.get('status'));
        }

        if (action.get('varName') === 'canEditGroup') {
          controller.set('canEditGroup', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteGroup') {
          controller.set('canDeleteGroup', action.get('status'));
        }
      });
    },
    getUsers: function getUsers() {
      var controller = this.get('controller');
      var self = this;
      controller.set("showLoadingUser", true);

      _jquery.default.ajax({
        type: "POST",
        data: {
          page: controller.get('listPage'),
          limit: controller.get('limit'),
          sortBy: controller.get('sortBy'),
          sortOrder: controller.get('sortOrder'),
          searchInput: controller.get('searchInput'),
          searchColumn: controller.get('searchColumn')
        },
        url: self.store.adapterFor('application').get('namespace') + '/users/getUsers',
        success: function success(response) {
          controller.set('model', response.user);
          controller.set('loading', false);

          if (response.exceedTotal) {
            controller.set('listPage', 1);
          }

          controller.set('totalPage', response.totalPage);
          controller.set("totalRecords", response.totalRecords);

          if (response.user.length) {
            controller.set('hasModel', true);
          } else {
            controller.set('hasModel', false);
            controller.set('loading', false);
          }

          controller.set("showLoadingUser", false);
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },
    actions: {
      changeLimit: function changeLimit() {
        this.getUsers();
      },
      downloadList: function downloadList() {
        (0, _jquery.default)("#exportProgressModal").modal({
          backdrop: 'static',
          keyboard: false
        });
        (0, _axios.default)({
          method: 'POST',
          url: this.store.adapterFor('application').get('namespace') + '/users/exportUser'
        }).then(function (result) {
          var csv = _papaparse.default.unparse(result, {
            quotes: true,
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ","
          });

          var csvData = new Blob([csv], {
            type: 'text/csv;charset=utf-8;'
          });
          var csvURL = window.URL.createObjectURL(csvData);
          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', 'user-list.csv');
          tempLink.click();
          setTimeout(function () {
            (0, _jquery.default)("#exportProgressModal").modal("hide");
          }, 300);
        });
      },
      prevList: function prevList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') - 1);
        this.getUsers();
      },
      nextList: function nextList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') + 1);
        this.getUsers();
      },
      moveList: function moveList(direction, page) {
        var controller = this.get("controller");

        if (direction === "page" && page) {
          controller.set("listPage", page);
        }

        this.getUsers();
      },
      doSort: function doSort(param) {
        var controller = this.get('controller');

        if (controller.get('sortBy') !== param) {
          controller.set('sortBy', param);
          controller.set('sortOrder', 'ASC');
        } else {
          if (controller.get('sortOrder') === 'DESC') {
            controller.set('sortOrder', 'ASC');
          } else {
            controller.set('sortOrder', 'DESC');
          }
        }

        this.getUsers();
      },
      changeColumn: function changeColumn(param) {
        var controller = this.get('controller');
        controller.set('searchColumn', param);
      },
      doSearch: function doSearch() {
        this.getUsers();
      },
      storeId: function storeId(param) {
        this.set('userId', param);
      },
      deleteUser: function deleteUser() {
        var _this = this;

        var axiosConfig = {
          method: "POST",
          data: {
            userId: this.get('userId')
          },
          url: this.store.adapterFor('application').get('namespace') + '/users/deleteUser'
        };
        this.controller.set("showLoadingUser", true);
        (0, _axios.default)(axiosConfig).then(function (response) {
          if (response.status && response.status === 200) {
            _this.getUsers();
          }
        });
      },
      viewUserDetail: function viewUserDetail(user) {
        this.transitionTo("users-roles.users.detail", user.id);
      }
    }
  });

  _exports.default = _default;
});