define("client/pods/components/w3office-forms/advanced-components/custom-attribute/component-field/component", ["exports", "client/mixins/w3office-forms/base-component", "axios"], function (_exports, _baseComponent, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend(_baseComponent.default, {
    init: function init() {
      var _this = this;

      this._super();

      this.set('suggestions', Ember.A([]));
      this.set('showSuggestion', false);
      this.set('allowmultiple', false);
      this.set('CustomAttributes', Ember.A([]));

      if (this.get('containerValue')) {
        var keys = Object.keys(this.get('containerValue'));
        keys.forEach(function (key) {
          var details = Ember.A([]);

          _this.get('containerValue')[key].split(',').forEach(function (value) {
            details.pushObject(value);
          });

          _this.get('CustomAttributes').pushObject(Ember.Object.create({
            name: key,
            details: details
          }));
        });
      }

      console.log(this.get('containerValue'));
      this.set('detailDisabled', true);
    },
    actions: {
      changeDetailSuggestion: function changeDetailSuggestion(name) {
        this.set('selectedName', name);
        this.set('detailDisabled', false);
        this.set('suggestions', name.details);
        this.set('selectedAttributeValue', null);
        this.set('allowmultiple', name.allowmultiple);
      },
      changeDetail: function changeDetail(label) {
        this.set('selectedAttributeValue', Ember.Object.create({
          name: this.get('selectedName.name'),
          label: label
        }));
      },
      mutateSelectedAttribute: function mutateSelectedAttribute(name, label) {
        this.set('selectedAttribute', label);
        this.set('selectedAttributeValue', Ember.Object.create({
          name: name,
          label: label
        }));
        this.set('showSuggestion', false);
      },
      updateSuggestion: function updateSuggestion() {
        var _this2 = this;

        if (this.get('selectedAttribute')) {
          this.set('suggestions', Ember.A([]));
          this.get('customAttributesList').forEach(function (customAttribute) {
            var details = Ember.A([]);
            customAttribute.details.forEach(function (detail) {
              if (detail.label.includes(_this2.get('selectedAttribute'))) {
                details.pushObject(detail);
              }
            });

            if (details.length > 0) {
              _this2.get('suggestions').pushObject({
                name: customAttribute.name,
                details: details
              });
            }
          });
          this.set('showSuggestion', true);
        } else {
          this.set('showSuggestion', false);
        }
      },
      addAttribute: function addAttribute() {
        var _this3 = this;

        console.log(_typeof(this.get('containerValue')));

        if (this.get('selectedAttributeValue')) {
          //Update CustomAttributes to update UI
          var updatedCustomAttribute = this.get('CustomAttributes').filter(function (customAttribute) {
            return customAttribute.name == _this3.get('selectedAttributeValue').get('name');
          });

          if (updatedCustomAttribute.length > 0) {
            if (!updatedCustomAttribute.objectAt(0).get('details').includes(this.get('selectedAttributeValue').get('label'))) {
              if (this.get('allowmultiple')) {
                updatedCustomAttribute.objectAt(0).get('details').pushObject(this.get('selectedAttributeValue').get('label'));
              } else {
                return alert('This Custom Attribute only allow single value');
              }
            }
          } else {
            var name = this.get('selectedAttributeValue').get('name');
            var details = Ember.A([]);
            details.pushObject(this.get('selectedAttributeValue').get('label'));
            this.get('CustomAttributes').pushObject(Ember.Object.create({
              name: name,
              details: details
            }));
          } //Update containerValue


          if (!this.get('containerValue')) {
            this.set('containerValue', {});
          }

          var currentDetails = this.get('containerValue')[this.get('selectedAttributeValue').get('name')] ? this.get('containerValue')[this.get('selectedAttributeValue').get('name')] : null;
          var newDetails;

          if (currentDetails) {
            if (currentDetails.includes(this.get('selectedAttributeValue').get('label'))) {
              newDetails = currentDetails;
            } else {
              newDetails = "".concat(currentDetails, ",").concat(this.get('selectedAttributeValue').get('label'));
            }
          } else {
            newDetails = "".concat(this.get('selectedAttributeValue').get('label'));
          }

          this.get('containerValue')[this.get('selectedAttributeValue').get('name')] = newDetails;
          this.setEntryContainer(this.get('field'), JSON.stringify(this.get('containerValue')));
          this.set('selectedAttribute', null);
          this.set('selectedAttributeValue', null);
          this.set('showSuggestion', false);
        }
      },
      removeAttribute: function removeAttribute(name, detail, indexCA, indexDetail) {
        //Update CustomAttributes to update UI
        this.get('CustomAttributes').objectAt(indexCA).get('details').splice(indexDetail, 1);
        var tempArrayDetail = this.get('CustomAttributes').objectAt(indexCA).get('details');
        this.get('CustomAttributes').objectAt(indexCA).set('details', Ember.A());
        this.get('CustomAttributes').objectAt(indexCA).set('details', Ember.A(tempArrayDetail));

        if (tempArrayDetail == 0) {
          this.get('CustomAttributes').splice(indexCA, 1);
          var tempArrayCA = this.get('CustomAttributes');
          this.set('CustomAttributes', Ember.A());
          this.set('CustomAttributes', Ember.A(tempArrayCA));
        } //Update containerValue


        var currentDetails = this.get('containerValue')[name];
        var currentDetailsArray = this.get('containerValue')[name].split(',');
        console.log(currentDetailsArray);
        var newDetails;

        if (currentDetailsArray.length > 1) {
          if (indexDetail < currentDetailsArray.length - 1) {
            newDetails = currentDetails.replace("".concat(detail, ","), "");
          } else if (indexDetail = currentDetailsArray.length - 1) {
            newDetails = currentDetails.replace(",".concat(detail), "");
          }
        } else {
          newDetails = currentDetails.replace(detail, "");
        }

        if (!newDetails) {
          delete this.get('containerValue')[name];
        } else {
          this.get('containerValue')[name] = newDetails;
        }

        this.setEntryContainer(this.get('field'), JSON.stringify(this.get('containerValue')));
      }
    }
  });

  _exports.default = _default;
});