define("client/models/dashboard", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    dashboardId: (0, _attr.default)('string'),
    mapTitle: (0, _attr.default)('string'),
    kpiTitle: (0, _attr.default)("string"),
    resourceAccessEnabled: (0, _attr.default)('boolean'),
    isdelete: (0, _attr.default)('boolean'),
    // companynum: belongsTo('company', {async: true}),
    dashboardMaps: (0, _relationships.hasMany)('dashboardmap', {
      async: true
    }),
    dashboardReports: (0, _relationships.hasMany)('dashboardreport', {
      async: true
    }),
    dabkpis: (0, _relationships.hasMany)("dabkpi", {
      async: true
    })
  });

  _exports.default = _default;
});