define("client/pods/components/w3office-forms/form-paging/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('filterValue', this.get('filter'));
      this.set('appFilter', this.get('appID'));
    },
    actions: {
      filterForm: function filterForm() {
        if (!Ember.isEmpty(this.get('filterValue')) || !Ember.isEmpty(this.get('appFilter'))) {
          this.filterForm(this.get('filterValue'), this.get('appFilter'));
        }
      },
      nextList: function nextList() {
        // GO TO NEXT POST LIST -----------------------------------------------
        this.nextList();
      },
      prevList: function prevList() {
        // GO TO PREVIOUS POST LIST ---------------------------------------------
        this.prevList();
      },
      deleteForm: function deleteForm(formID) {
        this.deleteForm(formID);
      }
    }
  });

  _exports.default = _default;
});