define("client/pods/dashboard-builder/dabkpi/config/new/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model() {
      var dabkpinum = this.modelFor('dashboard-builder.dabkpi.config');
      return this.store.createRecord('dabkpiconfig', {
        xColumnName: '',
        xColumnDate: '',
        condition: 'EQUAL',
        yQuery: '',
        tablename: '',
        interval: '',
        method: 'COUNT',
        dabkpi: dabkpinum
      });
    },
    setupController: function setupController(controller, model) {
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      var dabkpinum = this.modelFor('dashboard-builder.dabkpi.config');
      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set("dabkpiid", dabkpinum.id);
      controller.set("dabkpititle", dabkpinum.get('title'));
      controller.set("dabkpitype", dabkpinum.get('type'));

      if (controller.get("dabkpitype") === "timechart") {
        controller.set("isTimechart", true);
      } else {
        controller.set("isTimechart", false);
      }

      controller.set('model', model);
    },
    actions: {
      saveDabkpiConfig: function saveDabkpiConfig() {
        var self = this;
        var newRec = this.get('currentModel');
        newRec.save().then(function () {
          var whatsql = {
            'selects': [{
              'select': newRec.get('tablename') + '.' + newRec.get('xColumnName'),
              'function': 'NA',
              'asname': 'ANY'
            }, {
              'select': newRec.get('tablename') + '.' + newRec.get('xColumnName'),
              'function': newRec.get('method'),
              'asname': newRec.get('method')
            }],
            'from': newRec.get('tablename')
          };
          self.store.findRecord("dabkpi", newRec.get('dabkpi.id')).then(function (dabkpi) {
            dabkpi.set('whatsql', JSON.stringify(whatsql));
            dabkpi.save().then(function () {
              self.transitionTo('dashboard-builder.dabkpi.config');
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});