define("client/pods/components/dashboard-v2/chart-component/list-table/component", ["exports", "jquery", "papaparse"], function (_exports, _jquery, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = Ember.Component.extend({
    authObject: Ember.inject.service('session'),
    moreinfo: false,
    //currentfeature : [],
    //feature : [],
    thumbloading: true,
    moreCommentActionBtn: Ember.computed('comment.count', function () {
      return this.get('comment.count') > 18;
    }),

    /*
    	moreCommentActionBtn: function() {
    		return this.get('comment.count') > 18;
    	}.property('comment.count'),
    */
    featureAction: Ember.computed('morefeature', function () {
      if (this.morefeature) {
        return "less feature";
      } else {
        return "more feature";
      }
    }),
    commentAction: Ember.computed('morecomment', function () {
      if (this.morecomment) {
        return "Less Reasons";
      } else {
        return "More Reasons";
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      switch (this.get('thumb.user_choice')) {
        case 'up':
          this.set('choice_up', true);
          this.set('choice_down', false);
          break;

        case 'down':
          this.set('choice_up', false);
          this.set('choice_down', true);
          break;

        default:
          break;
      }

      this.set('canViewThumbDetail', this.get('canViewThumbDetail'));
    },
    actions: {
      doSort: function doSort(param) {
        for (var key in this.ListHeader) {
          var value = this.ListHeader[key];

          if (value != param) {
            var tempId = '#sort-icon-' + value;

            if ((0, _jquery.default)(tempId)) {
              var tempList = (0, _jquery.default)(tempId).attr('class');

              if (tempList.includes('fa-sort-asc')) {
                (0, _jquery.default)(tempId).removeClass('fa-sort-asc');
                (0, _jquery.default)(tempId).addClass('fa-sort');
              }

              if (tempList.includes('fa-sort-desc')) {
                (0, _jquery.default)(tempId).removeClass('fa-sort-desc');
                (0, _jquery.default)(tempId).addClass('fa-sort');
              }
            }
          }
        }

        this.set('sortBy', param);
        var sortIconId = '#sort-icon-' + param;

        if ((0, _jquery.default)(sortIconId)) {
          var classList = (0, _jquery.default)(sortIconId).attr('class');

          if (classList.includes('fa-sort')) {
            this.set('sortOrder', 'ASC');
            (0, _jquery.default)(sortIconId).removeClass('fa-sort');
            (0, _jquery.default)(sortIconId).addClass('fa-sort-asc');
          }

          if (classList.includes('fa-sort-asc')) {
            this.set('sortOrder', 'DESC');
            (0, _jquery.default)(sortIconId).removeClass('fa-sort-asc');
            (0, _jquery.default)(sortIconId).addClass('fa-sort-desc');
          }

          if (classList.includes('fa-sort-desc')) {
            this.set('sortOrder', 'ASC');
            (0, _jquery.default)(sortIconId).removeClass('fa-sort-desc');
            (0, _jquery.default)(sortIconId).addClass('fa-sort-asc');
          }
        }

        this.doSort(this);
      },
      doSearch: function doSearch(param, param2) {
        this.doSearch(param, param2);
      },
      changeColumn: function changeColumn(param) {
        this.changeColumn(param);
      },
      changeLimit: function changeLimit(param) {
        this.changeLimit(param, this);
      },
      prevList: function prevList(table_chart) {
        this.prevList(table_chart);
      },
      nextList: function nextList(table_chart) {
        this.nextList(table_chart);
      },

      /*
      deleteAction: function(){
      this.deleteAction();
      },
      storeId: function(param){
      this.storeId(param);
      },*/
      moreFeature: function moreFeature() {
        this.moreFeature();
      },
      moreComment: function moreComment() {
        this.moreComment();
      },
      addComment: function addComment(comment, invoice_key, invoice_line, invoicefilter) {
        if (this.get('newComment')) this.addComment(comment, invoice_key, invoice_line, invoicefilter);else {//console.log('empty boi')
          //add handle
        }
      },
      giveThumb: function giveThumb(thumb, invoice_key, invoice_line, invoicefilter) {
        //console.log({thumb,invoice_key,invoice_line,invoicefilter});
        this.giveThumb(thumb, invoice_key, invoice_line, invoicefilter);
      },
      getThumbDatas: function getThumbDatas(invoice_key, invoice_line, invoicefilter) {
        //console.log('getdata')
        //console.log(param)
        var self = this;

        if ((0, _jquery.default)('#thumbdataarea').hasClass('show')) {//console.log('ada')
        } else {
          //console.log('tidak')
          _jquery.default.ajax({
            type: "POST",
            data: {
              invoice_key: invoice_key,
              invoice_line: invoice_line,
              invoicefilter: invoicefilter
            },
            url: '/api/v1/userthumbs/getThumbDetails',
            success: function success(response) {
              // console.log(response.data)
              if (response.data.length > 0) self.set('allthumbdata', response.data); // console.log(response.thumb_up)

              if (response.thumb_up.length > 0) self.set('thumbdatas_up', response.thumb_up); // console.log(response.thumb_down)

              if (response.thumb_down.length > 0) self.set('thumbdatas_down', response.thumb_down);
              self.set('thumbloading', false);
            },
            error: function error(xhr, status, _error) {
              console.log('Error ' + _error);
            }
          });
        }
      },
      exportCSV: function exportCSV() {
        var csvget = [];

        _jquery.default.ajax({
          type: "POST",
          data: {
            page: this.get('listPage'),
            limit: this.get('limit'),
            sortBy: this.get('sortBy'),
            sortOrder: this.get('sortOrder'),
            searchInput: this.get('searchInput'),
            searchColumn: this.get('searchColumn'),
            userinfo: this.get('userinfo'),
            exportcsv: true,
            invoicefilter: this.get('invoicefilter')
          },
          url: '/api/v1/charts/getInvoice',
          success: function success(response) {
            csvget = response; //maybe need some indicator to let the user know its processing?

            var csvdata = csvget.map(function (_ref) {
              var item = _extends({}, _ref);

              return item;
            }); //HUMAN READABLE HEADER

            var humanheader = ["Invoice Key", "Invoice ID", "Risk Score", "Invoice Date", "Invoice Line", "Sub BU.", "", "Dept.", "Vendor ID", "Vendor Class", "Vendor Name", "PO ID", "Header Desc.", "Currency", "Header Line Amt. (Excl. GST)", "Detail Line Desc.", "Line Amt. (Incl. GST)", "Useful to Investigate Further", "Reason for Yes / No"];

            var csvArray = humanheader + "\r" + _papaparse.default.unparse(csvdata, {
              header: false
            });

            var _window = window,
                document = _window.document,
                URL = _window.URL;
            var anchor = document.createElement('a');
            anchor.download = "export.csv";
            anchor.href = URL.createObjectURL(new Blob([csvArray], {
              type: 'text/csv'
            }));
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
          },
          error: function error(xhr, status, _error2) {
            console.log('Error ' + _error2);
          }
        }); //*/

      }
    }
  });

  _exports.default = _default;
});