define("client/pods/dashboard-builder/dabmap/new/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model() {
      var dashboardnum = this.modelFor('dashboard-builder.dabmap');
      return Ember.Object.create({
        modelName: '',
        titleColumn: '',
        associationString: '',
        longitudeColumn: '',
        latitiudeColumn: '',
        markerColor: '',
        markerSymbol: '',
        dashboard: dashboardnum,
        removedRelationships: Ember.A()
      });
    },
    setupController: function setupController(controller, model) {
      var dashboardnum = this.modelFor('dashboard-builder.dabmap');
      controller.set("dashboardid", dashboardnum.id);
      controller.set('objDashboardmapcolumns', Ember.A());
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set('model', model);
    },
    actions: {
      addMoreContent: function addMoreContent() {
        var newContent = this.store.createRecord('dashboardmapcolumn', {
          label: '',
          columnName: '',
          associationString: ''
        });
        this.controller.get('objDashboardmapcolumns').pushObject(newContent);
      },
      deleteContent: function deleteContent(mapcolumn) {
        this.controller.get('objDashboardmapcolumns').removeObject(mapcolumn);
      },
      saveDabmap: function saveDabmap() {
        var self = this;
        var promises = Ember.A();
        var newRec = this.get('currentModel');
        var newDabmap = self.store.createRecord('dashboardmap', {
          modelName: newRec.get('modelName'),
          titleColumn: newRec.get('titleColumn'),
          associationString: newRec.get('associationString'),
          longitudeColumn: newRec.get('longitudeColumn'),
          latitudeColumn: newRec.get('latitudeColumn'),
          dashboard: newRec.get('dashboard'),
          markerColor: newRec.get('markerColor'),
          markerSymbol: newRec.get('markerSymbol')
        });
        newDabmap.save().then(function (dabmap) {
          self.controller.get('objDashboardmapcolumns').forEach(function (content) {
            content.set('dashboardMap', dabmap);
            promises.push(content.save());
          });
          Ember.RSVP.Promise.all(promises).then(function () {
            return self.transitionTo('dashboard-builder.dabmap');
          });
        });
      }
    }
  });

  _exports.default = _default;
});