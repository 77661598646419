define("client/models/formpredefvalue", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    toREST: (0, _attr.default)('boolean'),
    type: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    showOnForm: (0, _attr.default)('boolean'),
    showOnEntry: (0, _attr.default)('boolean'),
    executeOnNew: (0, _attr.default)('boolean'),
    executeOnDuplicate: (0, _attr.default)('boolean'),
    executeOnEdit: (0, _attr.default)('boolean'),
    executeOnTask: (0, _attr.default)('boolean'),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    entryListContents: (0, _relationships.hasMany)('entrylistcontent', {
      async: true
    })
  });

  _exports.default = _default;
});