define("client/pods/components/report-viewer/dab-user-param-collection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addParamcollection: function addParamcollection() {
        this.addParamcollection();
      },
      deleteParamcollection: function deleteParamcollection(paramcollection) {
        this.deleteParamcollection(paramcollection);
      },
      setParamcollection: function setParamcollection(paramcollection) {
        this.setParamcollection(paramcollection);
      },
      addParam: function addParam() {
        this.addParam();
      },
      deleteParam: function deleteParam(param) {
        this.deleteParam(param);
      },
      saveParam: function saveParam() {
        this.saveParam();
      }
    }
  });

  _exports.default = _default;
});