define("client/pods/components/w3office-forms/collection-components/full-name/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    firstName: "",
    lastName: "",
    didReceiveAttrs: function didReceiveAttrs() {
      //Automatically set validation type to letter only
      if (!Ember.isEmpty(this.get('field'))) {
        this.get('field').set('validationType', 'letter');
      }
    },
    requiredObserver: Ember.observer('field.required', function () {
      this.get('field').save();
    }),
    entryContainerObserver: Ember.observer('field.entryContainer', function () {
      //automatically set firstName and lastName field to empty when entrycontainer empty
      if (Ember.isEmpty(this.get('field').get('entryContainer'))) {
        this.set('firstName', "");
        this.set('lastName', "");
      }
    }),
    fullNameObserver: Ember.observer('firstName', 'lastName', function () {
      //Append first name and last name
      if (!Ember.isEmpty(this.get('firstName')) && !Ember.isEmpty(this.get('lastName')) || !this.get('field').get('required')) {
        this.get('field').set('entryContainer', this.get('firstName') + " " + this.get('lastName'));
      } else {
        this.get('field').set('entryContainer', "");
      }
    }),
    actions: {}
  });

  _exports.default = _default;
});