define("client/models/formrelation", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    relationType: (0, _attr.default)('string'),
    form1Key: (0, _attr.default)('string'),
    form2Key: (0, _attr.default)('string'),
    priority: (0, _attr.default)('number'),
    form1: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    form2: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});