define("client/pods/components/dashboard-builder/autocomplete-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qfiF4fMh",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bs-select dropdown\"],[8],[0,\"\\n\\t\"],[1,[28,\"input\",null,[[\"autocomplete\",\"class\",\"type\",\"placeholder\",\"value\",\"focus-in\",\"focus-out\",\"key-up\"],[\"off\",\"form-control search-field\",\"text\",\"Enter Value\",[24,[\"inputQuery\"]],[28,\"action\",[[23,0,[]],\"focused\"],null],[28,\"action\",[[23,0,[]],\"unfocused\"],null],[28,\"action\",[[23,0,[]],\"observeInput\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"isPending\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"dropdown-menu\"],[11,\"style\",[22,\"dropdownCSSProps\"]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"role\",\"button\"],[10,\"class\",\"autocomplete-choice\"],[8],[0,\"Loading...\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"dropdown-menu\"],[11,\"style\",[22,\"dropdownCSSProps\"]],[8],[0,\"\\t\\t\\n\"],[4,\"each\",[[24,[\"recordList\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"autocomplete-choice\"],[3,\"action\",[[23,0,[]],\"addThis\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"text\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"columnname\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"i\",true],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dashboard-builder/autocomplete-input/template.hbs"
    }
  });

  _exports.default = _default;
});