define("client/pods/components/w3office-forms/component-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F5DiAmjf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"message\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"flash-messsage unsuccessful field-message\"],[10,\"style\",\"position: absolute; bottom: 5px;\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"message\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/component-message/template.hbs"
    }
  });

  _exports.default = _default;
});