define("client/mixins/menu-builder/menu-crud", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    getAppIds: function getAppIds() {
      var self = this;

      _jquery.default.ajax({
        type: "GET",
        url: self.store.adapterFor('application').get('namespace') + '/menus/getAppIds',
        success: function success(response) {
          self.controller.set('appIds', response);
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },
    getFormLinks: function getFormLinks(param) {
      var controller = this.get('controller');
      controller.get('model').set('appID', param);

      _jquery.default.ajax({
        type: "POST",
        data: {
          appID: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/menus/getFormIds',
        success: function success(response) {
          controller.set('formIds', response);
        },
        error: function error(xhr, status, _error2) {
          console.log('Error ' + _error2);
        }
      });

      _jquery.default.ajax({
        type: "POST",
        data: {
          appID: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/menus/getMenuLinks',
        success: function success(response) {
          var menu_link = [{
            routeName: 'd'
          }].concat(_toConsumableArray(response));
          controller.set('menuLinks', menu_link);
        },
        error: function error(xhr, status, _error3) {
          console.log('Error ' + _error3);
        }
      });
    },
    actions: {
      getFormLinks: function getFormLinks(param) {
        this.getFormLinks(param);
      },
      saveMenu: function saveMenu() {
        console.log('saveMenu in Mixin called');
        var self = this;
        this.get('currentModel').save().then(function (model) {
          //nothing here
          self.transitionTo(self.controller.get('currentRoute'));
        }, function (error) {
          self.transitionTo(self.controller.get('currentRoute'));
        });
      },
      deleteMenu: function deleteMenu(id, environment) {
        var self = this;

        _jquery.default.ajax({
          type: "POST",
          data: {
            id: id,
            environment: environment
          },
          url: this.store.adapterFor('application').get('namespace') + '/menus/deleteMenu',
          success: function success(response) {
            if (environment === 'parent') {
              window.location.reload(true);
            } else {
              self.transitionTo('menu-builder');
            }
          },
          error: function error(xhr, status, _error4) {}
        });
      }
    }
  });

  _exports.default = _default;
});