define("client/models/fieldlogicmaster", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    logicRule: (0, _attr.default)('string'),
    logicRuleAPI: (0, _attr.default)('string'),
    action: (0, _attr.default)('string'),
    runAt: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    propertyName: (0, _attr.default)('string'),
    additionalValue: (0, _attr.default)('string'),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    affectedField: (0, _relationships.belongsTo)('field', {
      async: true
    }),
    sourceField: (0, _relationships.belongsTo)('field', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    fieldLogics: (0, _relationships.hasMany)('fieldlogic', {
      async: true
    }),
    fieldLogicContents: (0, _relationships.hasMany)('fieldlogiccontent', {
      async: true
    })
  });

  _exports.default = _default;
});