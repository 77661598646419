define("client/models/role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // relationships
    rolegroups: _emberData.default.hasMany('rolegroup', {
      async: true
    }),
    roleroutes: _emberData.default.hasMany('roleroute', {
      async: true
    }),
    dabkpiaccesses: _emberData.default.hasMany('dabkpiaccess', {
      async: true
    }),
    dashboardreportaccesses: _emberData.default.hasMany('dashboardreportaccess', {
      async: true
    }),
    dabtabaccesses: _emberData.default.hasMany('dabtabaccess', {
      async: true
    }),
    application: _emberData.default.belongsTo('application', {
      async: true
    }),
    // attributes
    roleName: _emberData.default.attr('string')
  });

  _exports.default = _default;
});