define("client/pods/components/w3office-forms/component-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.set('message', '');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('submissionRecordingType') == 'rest-endpoint' && Ember.isEmpty(this.get('columnName')) && this.get('properties').get('hasColumnName')) {
        this.set('message', 'Column Name is not filled yet.');
      } else {
        this.set('message', '');
      }
    }
  });

  _exports.default = _default;
});