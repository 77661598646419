define("client/routes/stmap", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-users-roles/check-user-role", "ol", "ol/layer/Tile", "ol/source/OSM"], function (_exports, _authenticatedRouteMixin, _checkUserRole, _ol, _Tile, _OSM) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* import 'ol/ol.css'; */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _checkUserRole.default, {
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});