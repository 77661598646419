define("client/router", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("users-roles", {
      path: "users-roles"
    }, function () {
      this.route("users", function () {
        this.route("user", {
          path: "/profile"
        });
        this.route("detail", {
          path: ":user_id/detail"
        });
        this.route("create", {
          path: "create"
        });
        this.route('loading');
        this.route('edit', {
          path: ":user_id/edit"
        });
      });
      this.route("theme", function () {
        this.route("list");
        this.route("create");
        this.route("edit", {
          path: '/edit/:id'
        });
      });
      this.route('role-manager', function () {
        this.route('loading');
        this.route('create');
        this.route('app', {
          path: '/app/:id'
        }, function () {
          this.route('create', {
            path: '/route/create'
          });
          this.route('edit', {
            path: '/route/:route/edit'
          });
          this.route('access');
          this.route('route');
          this.route('assign');
          this.route('role');
          this.route('app-access');
          this.route('create-app-access', {
            path: "/app-access/create"
          });
          this.route('edit-app-access', {
            path: "/app-access/:appaccess_id/edit"
          });
        });
        this.route('edit', {
          path: ':id/edit'
        });
      });
      this.route('config');
      this.route('loading');
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
      this.route('groups', function () {
        this.route('create');
      });
      this.route('session-expired');
      this.route('exportimport');
      this.route('config');
    });
    this.route("login");
    this.route("register");
    this.route('reset-password');
    this.route('reset', {
      path: '/reset/:key'
    });
    this.route('authenticate', {});
    this.route('temp-trans', {});
    this.route('start', {});
    this.route('change-password');
    this.route('d', {
      path: "/d/:dashboard_id"
    }); //Form Builder

    this.route('w3office-forms', function () {
      this.route('form', {
        path: "/form/:form_id"
      }, function () {
        this.route('edit');
        this.route('settings', function () {
          this.route('notification', function () {
            this.route('edit', {
              path: "/edit/:note_id"
            });
            this.route('new');
          });
          this.route('confirmation', function () {
            this.route('new');
            this.route('edit', {
              path: "/edit/:confirmation_id"
            });
          });
          this.route('action', function () {
            this.route('new');
            this.route('edit', {
              path: "/edit/:action_id"
            });
          });
          this.route('actionvalidation', function () {
            this.route('new');
            this.route('edit', {
              path: "/edit/:actionvalidation_id"
            });
          });
        });
        this.route('preview');
        this.route('logic');
        this.route('process');
      });
      this.route('importexport', {});
      this.route('external-api', {});
      this.route('language');
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    }); //Table Manager

    this.route('table-builder', function () {
      this.route('table-list');
      this.route('create-table');
      this.route('edit-table', {
        path: '/edit-table/:dynamic_value'
      });
      this.route('server-management');
    }); //Form Player

    this.route('ui', {
      path: "/ui/:app_id/:form_id"
    }, function () {
      this.route('entries');
      this.route('entry', {
        path: "/entry/:entry_id"
      }, function () {
        this.route('edit');
        this.route('pdf');
        this.route('task');
        this.route('delete');
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    }); //Proccess Builder

    this.route('process-builder', function () {
      this.route('create');
      this.route('config', {
        path: '/:process_id/config'
      });
      this.route('instances', {
        path: '/:process_id/instances'
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      }); // this.route('task');
      // this.route('manage');
      // this.route('report');
    });
    this.route('process-manager', function () {
      this.route('inbox', function () {
        this.route('detail', {
          path: '/:task_id/detail'
        });
      });
      this.route('my-task', function () {
        this.route('detail', {
          path: '/:task_id/detail'
        });
      });
      this.route('queued', function () {
        this.route('detail', {
          path: '/:task_id/detail'
        });
      });
      this.route('involved', function () {
        this.route('detail', {
          path: '/:task_id/detail'
        });
      });
      this.route('archived', function () {
        this.route('detail', {
          path: '/:task_id/detail'
        });
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    }); //Menu Builder

    this.route('menu-builder', function () {
      this.route('new');
      this.route('edit', {
        path: "/:menu_id/edit"
      });
      this.route('editicon', {
        path: "/:app_id/editicon"
      });
      this.route('view', {
        path: "/:menu_id/view"
      });
      this.route('child', {
        path: "/:menu_id/child"
      }, function () {
        this.route('new');
        this.route('edit', {
          path: "/:child_id/edit"
        });
        this.route('view', {
          path: "/:child_id/view"
        });
        this.route('subchild', {
          path: "/:child_id/subchild"
        }, function () {
          this.route('new');
          this.route('edit', {
            path: "/:subchild_id/edit"
          });
          this.route('view', {
            path: "/:subchild_id/view"
          });
        });
      });
      this.route('theme');
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    });
    this.route('dashboard-builder', function () {
      this.route('new');
      this.route('edit', {
        path: "/:dashboard_id/edit"
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
      this.route('dabmap', {
        path: "/:dashboard_id/dabmap"
      }, function () {
        this.route('new');
        this.route('edit', {
          path: "/dabmap/:dabmap_id/edit"
        });
      });
      this.route('dabreport', {
        path: "/:dashboard_id/dabreport"
      }, function () {
        this.route('new');
        this.route('edit', {
          path: "/dabreport/:dabreport_id/edit"
        });
        this.route('access', {
          path: "/dabreport/access"
        }, function () {
          this.route('index', {
            path: "/:dabreport_id"
          });
          this.route('new', {
            path: "/:dabreport_id/new"
          });
          this.route('edit', {
            path: "/:dabreportaccess_id/edit"
          });
        });
        this.route('config', {
          path: "/dabreport/:dabreport_id/config"
        });
      });
      this.route('dabkpi', {
        path: "/:dashboard_id/dabkpi"
      }, function () {
        this.route('new');
        this.route('edit', {
          path: "/dabkpi/:dabkpi_id/edit"
        });
        this.route('access', {
          path: "/dabkpi/:dabkpi_id/access"
        }, function () {
          this.route('new');
          this.route('edit', {
            path: "/:dabkpiaccess_id/edit"
          });
        });
        this.route('config', {
          path: "/:dabkpi_id/config"
        }, function () {
          this.route('new');
          this.route('edit', {
            path: "/config/:dabkpiconfig_id/edit"
          });
        });
      });
      this.route('dabsetting', {
        path: "/:dashboard_id/dabsetting"
      }, function () {
        this.route('new');
        this.route('edit', {
          path: "/dabsetting/:dabsetting_id/edit"
        });
      });
      this.route('dabtabaccess', {
        path: "/:dashboard_id/dabtabaccess"
      }, function () {
        this.route('new');
        this.route('edit', {
          path: "/dabtabaccess/:dabtabaccess_id/edit"
        });
      });
    });
    this.route('dashboard', function () {
      this.route('overview');
      this.route('program-value');
      this.route('activity');
      this.route('assignment');
    });
    this.route('scheduler', function () {
      this.route('create');
      this.route('edit', {
        path: '/:schedule_id/edit'
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    });
    this.route('report-generator', function () {
      this.route('report', {
        path: "report/:report_id"
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    });
    this.route('report-viewer', function () {
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    });
    this.route('dashboard-builder-v2', function () {
      this.route('dashboard-creator', function () {
        this.route('tabs', {
          path: "/:dashboard_id/tabs"
        }, function () {
          this.route('index', {
            path: ''
          });
          this.route('charts', {
            path: "/:tab_id/charts"
          });
          this.route('filter', {
            path: "/:tab_id/filter"
          });
        });
      });
      this.route('chart-builder-v2', function () {
        this.route('chart-type');
        this.route('chart');
        this.route('dimension-measure', {
          path: "/:chart_id/dimension-measure"
        });
      });
      this.route('datasource', function () {
        this.route('edit', {
          path: "/:datasource_id/edit"
        });
        this.route('hierarchy', {
          path: "/:datasource_id/hierarchy"
        }, function () {
          this.route('index', {
            path: ''
          });
          this.route('edit', {
            path: "/:hierarchy_id/edit/"
          });
        });
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
    });
    this.route('dash', {
      path: "/dash/:dashboard_id"
    });
  });
  var _default = Router;
  _exports.default = _default;
});