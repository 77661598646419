define("client/models/dabkpi", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //attributes
    title: (0, _attr.default)("string"),
    description: (0, _attr.default)("string"),
    type: (0, _attr.default)("string"),
    order: (0, _attr.default)("number"),
    isActive: (0, _attr.default)("boolean"),
    axisFormat: (0, _attr.default)("string"),
    height: (0, _attr.default)("number"),
    width: (0, _attr.default)("number"),
    titleGlyph: (0, _attr.default)("string"),
    titleIcon: (0, _attr.default)('string'),
    shown: (0, _attr.default)("boolean"),
    whatsql: (0, _attr.default)("string"),
    isdelete: (0, _attr.default)('boolean'),
    //relations
    companynum: (0, _relationships.belongsTo)("company", {
      async: true
    }),
    organizationnum: (0, _relationships.belongsTo)("organization", {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    dashboard: (0, _relationships.belongsTo)("dashboard", {
      async: true
    }),
    dabkpirecords: (0, _relationships.hasMany)("dabkpirecord", {
      async: true
    }),
    dabkpiconfigs: (0, _relationships.hasMany)("dabkpiconfig", {
      async: true
    }),
    dabkpilimits: (0, _relationships.hasMany)("dabkpilimit", {
      async: true
    }),
    dabkpihistories: (0, _relationships.hasMany)("dabkpihistory", {
      async: true
    }),
    dabkpiaccesses: (0, _relationships.hasMany)("dabkpiaccess", {
      async: true
    })
  });

  _exports.default = _default;
});