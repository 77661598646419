define("client/pods/components/table-builder/table-description/component", ["exports", "axios", "jquery"], function (_exports, _axios, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      var _this = this;

      this._super();

      if (this.get('create')) {
        this.set('tooltip', "Check this if the table already exist.\nIf checked, Table Builder will only create Model, Controller, and Route files.\nIf unchecked, Table Builder will also create the table in the database.");
      } else {
        if (this.get('model').get('existingTable')) {
          this.set('tooltip', "This table uses table that already exist.\nOn update, Table Builder will only update Model, Controller, and Route files.");
        } else {
          this.set('tooltip', "This table does not use table that already exist.\nOn update, Table Builder will update the table in database, Model, Controller, and Route files.");
        }
      }

      if (!this.get('create') && this.get('model.tableServer.id')) {
        //Edit table with new format
        this.set('disableServerSelection', true);
        this.set('serverList', []);
        this.get('serverList').pushObject(this.get('model.tableServer'));
        this.set('selectedTableServer', this.get('serverList').objectAt(0));
        (0, _axios.default)({
          method: 'GET',
          url: "".concat(this.get('model.tableServer.domain')).concat(this.get('model.tableServer.namespace'), "/Formtables/getServerDatabase")
        }).then(function (serverDatabase) {
          (0, _jquery.default)('#serverDatabase').removeClass('skeleton');

          _this.get('model').set('serverDatabase', serverDatabase.data);
        });
      } else {
        //Create new table or edit table with old format
        this.set('isLoadingServer', true);
        this.set('serverList', this.store.query('tableserver', {
          where: {
            isdelete: false
          }
        })).then(function () {
          (0, _jquery.default)("#serverListDDL").removeClass("skeleton");

          _this.set('isLoadingServer', false);

          _this.set('disableServerSelection', false);
        });
      }
    },
    actions: {
      tableNameUpdated: function tableNameUpdated() {
        var regex = new RegExp("[^A-Za-z_\d]");

        if (regex.test(this.get('model').get('tableName'))) {
          this.get('model').set('tableName', this.get('model').get('tableName').replace(/[^A-Za-z_]/g, ''));
        }

        this.tableNameUpdated();
      },
      updateTableServer: function updateTableServer(tableserver) {
        this.updateTableServer(tableserver);
      }
    }
  });

  _exports.default = _default;
});