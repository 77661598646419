define("client/pods/reset/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/unauthenticated-route-mixin", "axios"], function (_exports, _applicationRouteMixin, _unauthenticatedRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _applicationRouteMixin.default, {
    key: null,
    beforeModel: function beforeModel(transition) {
      console.log(transition.params);
      var self = this;

      this._super.apply(this, arguments);

      this.store.queryRecord('resetpass', {
        key: transition.params
      }).then(function (param) {
        if (param) {
          return;
        } else {
          self.transitionTo('start');
        }
      });
    },
    model: function model(params) {
      this.set("key", params.key);
      return this.store.queryRecord('resetpass', {
        key: params.key
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('errorMessage', null);
      controller.set('successMessage', null);
      controller.set("showLoading", false);
      this.store.queryRecord('user', {
        email: model.get('email')
      }).then(function (user) {
        // controller.set('question', user.get('secretquestion.content.question'));
        controller.set("user", user);
      });
    },
    actions: {
      reset: function reset() {
        var _this = this;

        var flag = 0;
        var controller = this.get('controller');

        if (Ember.isEmpty(controller.get('password'))) {
          controller.set('message', 'Please input password!');
          /*setTimeout(function(){
              controller.set('message', null);
          }, 2000);*/

          flag++;
          return;
        }
        /*
            Regex for password
            at least 8 characters
            at least contains 1 uppercase, 1 lowercase, 1 number, and 1 special character( !"#$%&'()*+,-.:;<=>/?@\^[]_`{}|~ no space currently) 
        */


        var regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~])[a-zA-Z\d!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~]{8,}$/;

        if (!regexPass.test(controller.get('password'))) {
          controller.set('errorMessage', 'Password must be at least 8 characters and contains 1 uppercase, 1 lowercase, 1 number, and 1 special character!');
          flag++;
          return;
        }

        if (controller.get('password') !== controller.get('passwordRepeat')) {
          controller.set('errorMessage', 'Repeat Password must be same!');
          flag++;
          return;
        }

        if (!controller.get('model')) {
          controller.set('errorMessage', 'Key is invalid!');
          flag++;
          return;
        }

        if (flag === 0) {
          controller.set("showLoading", true);
          controller.set('errorMessage', null);
          controller.set("successMessage", null);
          var axiosConfig = {
            method: "POST",
            data: {
              email: controller.get('model').get('email'),
              key: this.get("key"),
              password: controller.get('password')
            },
            url: "".concat(this.store.adapterFor("application").get("host"), "/api/v1/users/resetPassword")
          };
          (0, _axios.default)(axiosConfig).catch(function (err) {
            console.log("catch(err)");
            console.log(err);

            if (err && err.response) {
              controller.set("errorMessage", err.response.data.message);
            } else {
              controller.set("errorMessage", "Server is down");
            }

            controller.set("showLoading", false);
          }).then(function (response) {
            console.log("then(response)");
            console.log(response);

            if (response && response.status == 200) {
              controller.set("successMessage", response.data.message);
              setTimeout(function () {
                _this.transitionTo("start");
              }, 3000);
            }
          });
        } else {
          controller.set("showLoading", false);
        }
      }
    }
  });

  _exports.default = _default;
});