define("client/pods/menu-builder/theme/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        $.ajax({
          type: "POST",
          url: _this.store.adapterFor('application').get('namespace') + '/applications/getTheme',
          success: function success(response) {
            resolve(response);
          },
          error: function error(err) {
            reject(err);
          }
        });
      });
    },
    setupController: function setupController(controller, model) {
      console.log(model);
      controller.set('model', model);
    },
    actions: {
      saveTheme: function saveTheme() {
        $.ajax({
          type: "POST",
          url: this.store.adapterFor('application').get('namespace') + '/applications/setTheme',
          data: this.get('controller').get('model'),
          success: function success(response) {
            console.log(response);
            window.location.reload();
          },
          error: function error(err) {
            console.log(err);
          }
        });
      }
    }
  });

  _exports.default = _default;
});