define("client/pods/components/dashboard/dab-user-param-collection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filter_param: {
      filter_header: null,
      filter_value: null,
      filter_type: null
    },
    filterType: [{
      label: 'Equal',
      value: 'EQUAL'
    }, {
      label: 'Greater or Equal',
      value: 'GREATEROREQUAL'
    }, {
      label: 'Greater Than',
      value: 'GREATERTHAN'
    }, {
      label: 'Less or Equal',
      value: 'LESSOREQUAL'
    }, {
      label: 'Less Than',
      value: 'LESSTHAN'
    }, {
      label: 'Like',
      value: 'LIKE'
    }, {
      label: 'Not Equal',
      value: 'NOTEQUAL'
    }, {
      label: 'Starts With',
      value: 'STARTSWITH'
    }, {
      label: 'Ends With',
      value: 'ENDSWITH'
    }],
    // init: function(){
    // 	this._super();
    // 	let self = this;
    // },
    actions: {
      setFilterHeader: function setFilterHeader(value) {
        this.set('filter_param.filter_header', value);
      },
      setFilterType: function setFilterType(value) {
        this.set('filter_param.filter_type', value);
      },
      saveParam: function saveParam() {
        this.saveParam(this.get('filter_param'));
      }
    }
  });

  _exports.default = _default;
});