define("client/models/entry", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    user: (0, _relationships.belongsTo)('formuser', {
      async: true
    }),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    entrycontents: (0, _relationships.hasMany)('entrycontent', {
      async: true
    }),
    createdAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    numericID: Ember.computed('entries', function () {
      return parseInt(this.get('id'));
    })
  });

  _exports.default = _default;
});