define("client/models/dabkpiparam", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    paramtype: (0, _attr.default)('string'),
    filtertype: (0, _attr.default)('string'),
    filtervalue: (0, _attr.default)('string'),
    ordertype: (0, _attr.default)('string'),
    orderseq: (0, _attr.default)('number'),
    isdelete: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    dabkpiconfigselectionnum: (0, _relationships.belongsTo)('dabkpiconfigselection', {
      async: true
    }),
    dabkpiparamcollectionnum: (0, _relationships.belongsTo)('dabkpiparamcollection', {
      async: true
    }),
    dabkpinum: (0, _relationships.belongsTo)('dabkpi', {
      async: true
    }) // companynum: belongsTo('company', {async: true})

  });

  _exports.default = _default;
});