define("client/pods/dashboard-builder-v2/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      this.set('currentRoute', transition.targetName);
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('application').set('appID', 'Dashboard Builder V2');
      this.controllerFor('application').set('menus', [{
        'title': 'Data Source',
        'glyph': 'fa-database',
        'link': 'dashboard-builder-v2.datasource',
        'unique': 'dbv2-datasource',
        'theme': '#ffffff'
      }, {
        'title': 'Chart Builder',
        'glyph': 'fa-chart-bar',
        'unique': 'dbv2-chart-bar',
        'subunique': 'dbv2-chart-bar-sub',
        'theme': '#ffffff',
        'children': [{
          'title': 'Chart Type',
          'link': 'dashboard-builder-v2.chart-builder-v2.chart-type'
        }, {
          'title': 'Chart',
          'link': 'dashboard-builder-v2.chart-builder-v2.chart'
        }]
      }, {
        'title': 'Dashboard Creator',
        'glyph': 'fa-columns',
        'link': 'dashboard-builder-v2.dashboard-creator',
        'unique': 'language',
        'theme': '#ffffff'
      }]);
    }
  });

  _exports.default = _default;
});