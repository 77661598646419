define("client/pods/users-roles/role-manager/app/create/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/create-edit-route"], function (_exports, _checkUserRole, _createEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _createEditRoute.default, {
    appId: '',
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      this._super();

      this.set('appId', this.controllerFor("users-roles.role-manager.app").get("model.id"));
    },
    // model createRecord on allowedroute model
    model: function model() {
      return this.store.createRecord('allowedroute');
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render({
        outlet: 'create'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('typeSelected', false);
      controller.set('type', null);
      controller.set('parent', null);
      controller.set('isCreate', true);
      controller.set('disableSubmit', false);

      this._super();
    },
    actions: {
      selectType: function selectType(param) {
        this.selectType(param);
      },
      changeParent: function changeParent(param) {
        this.changeParent(param);
      },
      createModule: function createModule(model) {
        this.saveRoute(model);
      },
      goTo: function goTo(param) {
        this.goTo(param);
      }
    }
  });

  _exports.default = _default;
});