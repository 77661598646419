define("client/pods/components/w3office-forms/bootstrap-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X8eNva7H",
    "block": "{\"symbols\":[\"record\"],\"statements\":[[4,\"each\",[[24,[\"records\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"p\",true],[10,\"class\",\"font-weight-bold text-uppercase px-3 mb-0\"],[10,\"style\",\"font-size: 14px;\"],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"font-weight-light px-3\"],[8],[1,[23,1,[\"content\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/bootstrap-card/template.hbs"
    }
  });

  _exports.default = _default;
});