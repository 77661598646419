define("client/pods/components/w3office-forms/advanced-components/auto-increment/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    /*
    	Initialize Auto Increment
    	Set Field to show component or not based on field config
    */
    init: function init() {
      this._super();

      if (!this.get('authoring') && (this.get('environment') === 'create' || this.get('environment') === 'duplicate')) {
        this.fieldActionGateway(this, {
          actionName: 'autoIncrementSetup'
        });
      } //Notes: later, someone can implement isSkeleton here

    }
  });

  _exports.default = _default;
});