define("client/pods/users-roles/theme/create/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    model: function model() {
      return this.store.createRecord('theme', {
        menuPosition: 'SIDE'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('showEntries', true);
    },
    actions: {
      togglePreview: function togglePreview() {
        var controller = this.get('controller');
        controller.set('showEntries', !controller.get('showEntries'));
      },
      saveTheme: function saveTheme() {
        var _this = this;

        this.get('controller').get('model').save().then(function () {
          _this.transitionTo('users-roles.theme.list');
        });
      }
    }
  });

  _exports.default = _default;
});