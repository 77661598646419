define("client/pods/process-manager/involved/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/yVYPEqz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-none d-sm-none d-md-block card mb-3 pm-thin-top-bar\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"py-2 px-2\"],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"color:\",[24,[\"currentTheme\",\"titleFontColor\"]],\";\"],null]],null]],[8],[0,\"\\n        Involved\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showListingIcon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"ul\",true],[10,\"class\",\"nav nav-tabs tabs-children\"],[10,\"role\",\"tablist\"],[8],[0,\"\\n            \"],[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[0,\"\\n                \"],[5,\"link-to\",[[12,\"class\",\"nav-link\"]],[[\"@route\"],[\"process-manager.involved\"]],{\"statements\":[[0,\"\\n                    \"],[7,\"i\",true],[10,\"class\",\"fa fa-table\"],[8],[9],[0,\"\\n                \"]],\"parameters\":[]}],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/process-manager/involved/template.hbs"
    }
  });

  _exports.default = _default;
});