define("client/pods/components/w3office-forms/entryfilter-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    invalidFilter: false,
    alert: false,
    invalidMessage: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.valueTypeList = [{
        name: 'Inputted Value',
        value: 'inputted-value'
      }, {
        name: 'Initializer',
        value: 'initializer'
      }, {
        name: 'Parent Property',
        value: 'parent-property'
      }, {
        name: 'Form Property',
        value: 'form-property'
      }, {
        name: 'Current Date',
        value: 'current-date'
      }];
    },
    actions: {
      saveConfiguration: function saveConfiguration() {
        this.set('invalidFilter', false);

        if (this.get('entryfilter').get('isHiddenValue') && Ember.isEmpty(this.get('entryfilter').get('hiddenType').content)) {
          this.set('invalidFilter', true);
          this.set('invalidMessage', 'Please Fill the Hidden Value Dependency');
        } else if (!this.get('entryfilter').get('isHiddenValue') && Ember.isEmpty(this.get('entryfilter').get('fieldType').content)) {
          this.set('invalidFilter', true);
          this.set('invalidMessage', 'Please Fill the Field Dependency');
        } else {
          this.set('invalidFilter', false);
          this.set('invalidMessage', '');
        }

        if (!this.get('invalidFilter')) {
          /*
          	Persisting record via mixins/w3office-forms/form-setting-crud.js
          	persistRecord: function(value, modalName, controllerList)
          */
          this.persistRecord(this.get('entryfilter'), 'entryfilter', 'entryfilterList');
        } else {
          (0, _jquery.default)(".flash-messsage").show();
          Ember.run.later(this, function () {
            (0, _jquery.default)(".flash-messsage").fadeOut(1000);
          }, 3500);
        }
      },
      discardChanges: function discardChanges() {
        if (Ember.isEmpty(this.get('entryfilter').id)) {
          //unload if data is uncommited
          this.get('entryfilter').unloadRecord();
        } else {
          //rollback if data is persisted
          this.get('entryfilter').rollbackAttributes();
        }

        (0, _jquery.default)('#entryfilter-props').modal('hide');
      }
    }
  });

  _exports.default = _default;
});