define("client/pods/users-roles/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super();
    },
    setupController: function setupController(controller) {
      controller.set('session', this.get('authObject.session'));
    },
    renderTemplate: function renderTemplate() {
      this._super();
    }
  });

  _exports.default = _default;
});