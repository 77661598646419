define("client/pods/scheduler/edit/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-scheduler/scheduler-detail"], function (_exports, _checkUserRole, _schedulerDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _schedulerDetail.default, {
    authObject: Ember.inject.service('session'),
    environment: 'edit',
    beforeModel: function beforeModel() {
      this._super();
    },
    model: function model(param) {
      return this.store.findRecord('crontask', param.schedule_id);
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller, model) {
      this._super();
    },
    actions: {
      edit: function edit(model) {
        var self = this;
        var controller = this.get('controller');
        var second, minute, hour, month, DoM, DoW; // Set Second Value

        second = this.generateSecond(); // Set Minute Value

        minute = this.generateMinute(); // Set Hour Value

        hour = this.generateHour(); // Set Day of Month Value

        DoM = this.generateDoM(); // Set Month Value

        month = this.generateMonth(); // Set Day of Week Value

        DoW = this.generateDoW(); // Combine value to Cron

        model.set('cron', second + ' ' + minute + ' ' + hour + ' ' + DoM + ' ' + month + ' ' + DoW); //set Date if any

        if (model.get('startDate')) {
          model.set('startDate', new Date(model.get('startDate')));
        }

        if (model.get('endDate')) {
          model.set('endDate', new Date(model.get('endDate')));
        }

        this.store.findRecord('user', this.get('authObject.data.user').id).then(function (user) {
          model.set('updatedBy', user);
          model.save().then(function (_model) {
            var apiparams = Ember.A();
            model.get('apiparams').forEach(function (apiparam) {
              apiparam.set('crontask', _model);
              apiparams.push(apiparam.save());
            });
            Ember.RSVP.Promise.all(apiparams).then(function () {
              var deleted = Ember.A();
              controller.get('deletedCon').forEach(function (del) {
                deleted.push(del.destroyRecord());
              });
              Ember.RSVP.Promise.all(deleted).then(function () {
                var url = self.store.adapterFor('application').get('namespace') + '/crontasks/editCron';
                /*
                *	runCron defined on Mixin
                */

                self.runCron(_model, url);
              });
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});