define("client/pods/components/general-component/datetime-picker/component", ["exports", "client/pods/components/general-component/base-toplevel-component"], function (_exports, _baseToplevelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // source:
  // - https://momentjs.com/docs/#/parsing/string-format/
  // - https://flatpickr.js.org/formatting/
  var momentToFlatPickrFormat = function momentToFlatPickrFormat(checkChunk) {
    if (checkChunk.includes('D')) {
      if (checkChunk === "Do") {
        return 'J';
      } else if (checkChunk === "DD") {
        return 'd';
      } else {
        return 'j';
      }
    } else if (checkChunk.includes('M')) {
      if (checkChunk === "MMMM") {
        return 'F';
      } else if (checkChunk === "MMM") {
        return 'M';
      } else if (checkChunk === "MM") {
        return 'm';
      } else {
        return 'n';
      }
    } else if (checkChunk.includes('Y')) {
      if (checkChunk === "YYYY") {
        return 'Y';
      } else {
        return 'y';
      }
    } else if (checkChunk.includes('H') || checkChunk.includes('k')) {
      return 'H';
    } else if (checkChunk.includes('h')) {
      return 'h';
    } else if (checkChunk.includes('m')) {
      return 'i';
    } else if (checkChunk.includes('s')) {
      return 'S';
    } else {
      return checkChunk;
    }
  };

  var _default = _baseToplevelComponent.default.extend({
    primaryClassName: 'datetime-picker',
    date: null,
    enableTime: true,
    placeholder: null,
    noCalendar: false,
    time_24hr: true,
    mode: 'single',
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.isEmpty(this.get("disable"))) {
        this.set("disable", []);
      }

      if (Ember.isEmpty(this.get("allowInput"))) {
        this.set("allowInput", false);
      }

      if (this.get("dateFormat")) {
        var dateformat = this.get("dateFormat");
        var dateformatFlatpickr = "";
        var checkChunk = "";
        var checkCalendar = 0;
        var checkTime = 0;

        var countCheckCalendarTime = function countCheckCalendarTime(checkChunk) {
          if (checkChunk.includes("D") || checkChunk.includes("M")) {
            checkCalendar++;
          }

          if (checkChunk.includes("H") || checkChunk.includes("h") || checkChunk.includes("m")) {
            checkTime++;
          }
        };

        for (var i = 0; i < dateformat.length; i++) {
          if (checkChunk[0] !== dateformat.charAt(i)) {
            if (i !== 0 || i === dateformat.length - 1) {
              countCheckCalendarTime(checkChunk);
              dateformatFlatpickr += momentToFlatPickrFormat(checkChunk);
            }

            checkChunk = dateformat.charAt(i);
          } else {
            checkChunk += dateformat.charAt(i);
          }

          if (i === dateformat.length - 1) {
            countCheckCalendarTime(checkChunk);
            dateformatFlatpickr += momentToFlatPickrFormat(checkChunk);
          }
        }

        this.set("dateFormatValue", dateformatFlatpickr);
        this.set("enableTime", checkTime >= 2);
        this.set("noCalendar", !(checkCalendar >= 2));
      } else {
        this.set("dateFormatValue", "d/m/Y H:i K");
        this.set("enableTime", true);
        this.set("noCalendar", false);
      }
    },
    actions: {
      executeOnChange: function executeOnChange(selectedDates, dateStr, instance) {
        if (this.onChange instanceof Function) {
          this.onChange(selectedDates, dateStr, instance);
        }
      }
    }
  });

  _exports.default = _default;
});