define("client/pods/components/report-generator/subreport-tab/filtering-parameter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OLoCOJVQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"style\",\"float: left;\"],[8],[7,\"h4\",true],[8],[0,\"Parameter\"],[9],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"style\",\"float: right;\"],[8],[7,\"button\",true],[10,\"class\",\"btn btn-danger\"],[8],[0,\"Remove All\"],[9],[9],[0,\"\\n\\t\"],[7,\"br\",true],[8],[9],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[0,\"Name\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"d-none d-sm-block col-md-4\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"placeholder\",\"class\",\"value\"],[\"text\",\"Enter Jobplan ID\",\"form-control\",\"paramJobplanID\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"d-none d-sm-block col-md-7\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"d-none d-sm-block col-md-1\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-danger\"],[10,\"style\",\"min-width: 46px;\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[10,\"style\",\"margin-top: 15px;\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[8],[0,\"Add New\"],[9],[9],[0,\"\\n\\t\"],[7,\"br\",true],[8],[9],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/report-generator/subreport-tab/filtering-parameter/template.hbs"
    }
  });

  _exports.default = _default;
});