define("client/pods/components/dashboard-v2/table-sub/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    test: Ember.computed('tables.data.[]', function () {
      var test = this.tables.data;
      console.log(test);
      return test;
    }),
    init: function init() {
      this._super();

      console.log('init'); //console.log(this.get(tables));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      console.log('update'); //console.log(this.get(tables));
    }
  });

  _exports.default = _default;
});