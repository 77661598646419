define("client/models/organization", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    organizationid: _emberData.default.attr('string'),
    organizationname: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),

    /*Computed Property*/
    organizationnum: Ember.computed('id', function () {
      return {
        id: this.get('id'),
        isOrganization: true
      };
    }),
    //relation
    companies: _emberData.default.hasMany('company', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});