define("client/pods/components/chart-builder-v2/chart-shout/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var _chart;

      if (this.chartarray) {
        _chart = this.chartarray.find(function (chart) {
          return chart.chart_info.id == _this.chartnum;
        });
      }

      console.log("_chart (Shout!)");
      console.log(_chart);
      var shoutValue = _chart.chart_data[0].label;

      if (_chart.chart_info.data_prefix) {
        shoutValue = "".concat(_chart.chart_info.data_prefix, " ").concat(shoutValue);
      }

      if (_chart.chart_info.data_suffix) {
        shoutValue = "".concat(shoutValue, " ").concat(_chart.chart_info.data_suffix);
      }

      this.set("dynamictitle", _chart.chart_info.name);
      this.set("shoutValue", shoutValue);
      this.set("shoutId", "shout-".concat(this.get("elementId")));
      this.set("shoutIdValue", "shout-value-".concat(this.get("elementId")));
      this.set("alignment", _chart.chart_info.alignment);
      this.set("fontSize", _chart.chart_info.font_size);

      if (this.get("chart_title_color")) {
        this.set("chartTitleColor", this.get("chart_title_color"));
      } else {
        this.set("chartTitleColor", "#3399FF");
      }
    },
    didInsertElement: function didInsertElement() {
      // modify the 'alignment' and 'font-size'
      var alignment = this.get("alignment");

      if (alignment === "center") {
        (0, _jquery.default)("#".concat(this.get("shoutId"))).addClass("text-center");
      } else if (alignment === "right") {
        (0, _jquery.default)("#".concat(this.get("shoutId"))).addClass("text-right");
      }

      if (this.get("fontSize")) {
        (0, _jquery.default)("#".concat(this.get("shoutIdValue"))).css("font-size", "".concat(this.get("fontSize")));
      }
    }
  });

  _exports.default = _default;
});