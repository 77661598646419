define("client/pods/dashboard-builder/dabkpi/config/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/dashboard-builder/paging-common"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin, _pagingCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _pagingCommon.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    model: function model() {
      var dabkpinum = this.modelFor('dashboard-builder.dabkpi.config');
      return this.store.query('dabkpiconfig', {
        dabkpi: dabkpinum.id
      });
    },
    setupController: function setupController(controller, model) {
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      var dabkpinum = this.modelFor('dashboard-builder.dabkpi.config');
      var modelQuery = this.store.query('dabkpiconfig', {
        where: {
          dabkpi: dabkpinum.id
        },
        sort: 'id ASC'
      });

      this._super(controller, model, modelQuery);

      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set("dabkpiid", dabkpinum.id);
      controller.set("dabkpititle", dabkpinum.get('title'));
      controller.set("dabkpitype", dabkpinum.get('type'));

      if (controller.get("dabkpitype") === "timechart") {
        controller.set("isTimechart", true);
      } else {
        controller.set("isTimechart", false);
      }
    },
    actions: {
      createConfig: function createConfig() {
        var self = this;
        var dabkpinum = this.modelFor('dashboard-builder.dabkpi.config');
        var newConfig = self.store.createRecord('dabkpiconfig', {
          interval: '',
          method: 'COUNT',
          dabkpi: dabkpinum
        });
        newConfig.save().then(function (dabkpiconfig) {
          self.transitionTo('dashboard-builder.dabkpi.config.edit', dabkpiconfig);
        });
      }
    }
  });

  _exports.default = _default;
});