define("client/pods/components/dashboard/dab-tabular-report/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentPage: 1,
    prevDisabled: true,
    nextDisabled: true,
    lastSortParameter: 'id',
    sortedDescending: true,
    loadingData: false,
    selectQuery: null,
    selectContents: null,
    paramcollectionQuery: null,
    paramcollectionContents: null,
    selectedParamcollection: null,
    paramContents: null,
    paramLoading: false,
    getRowDataset: null,
    organizationname: 'All Organizations',
    companyname: 'All Companies',
    init: function init() {
      this._super();

      var self = this;

      if (!Ember.isEmpty(self.get('report').get('organizationnum.id'))) {
        self.set('organizationname', self.get('report').get('organizationnum.organizationname'));
        self.set('organizationnum', self.get('report').get('organizationnum.id'));
      }

      if (!Ember.isEmpty(self.get('report').get('companynum.id'))) {
        self.set('companyname', self.get('report').get('companynum.companyname'));
        self.set('companynum', self.get('report').get('companynum.id'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.executeDabTabularReport(this);
    },
    actions: {
      saveParam: function saveParam(filter_param) {
        this.dabTabularReportSaveParam(this, filter_param);
      },
      setParamcollection: function setParamcollection(paramcollection) {
        this.dabTabularReportSetParamCollection(this, paramcollection);
      },
      moveList: function moveList(direction) {
        this.dabTabularReportMoveList(this, direction);

        if (this.get('currentPage') === this.get('totalPage')) {
          this.set('nextDisabled', true);
        } else {
          this.set('nextDisabled', false);
        }

        if (this.get('currentPage') === 1) {
          this.set('prevDisabled', true);
        } else {
          this.set('prevDisabled', false);
        }
      },
      refreshList: function refreshList() {
        this.dabTabularReportRefreshList(this);
      }
    }
  });

  _exports.default = _default;
});