define("client/pods/components/w3o-users-roles/form-user/repeat-answer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SEXbgOXV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"answerRepeat\"],[8],[0,\"\\n    Repeat Answer \"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\" \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"answerRepeatIsEmpty\"],[8],[0,\"Repeat answer cannot be empty\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"answerIsNotSame\"],[8],[0,\"Answer must be same with repeat answer\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"answerRepeatForm\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphAnswerRepeat\"],[8],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"type\",\"value\"],[\"answerRepeat\",\"Repeat Answer\",\"form-control\",\"password\",[24,[\"model\",\"answerRepeat\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/repeat-answer/template.hbs"
    }
  });

  _exports.default = _default;
});