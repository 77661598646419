define("client/pods/ui/entry/route", ["exports", "client/mixins/w3office-forms/entry-root-common"], function (_exports, _entryRootCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_entryRootCommon.default, {});

  _exports.default = _default;
});