define("client/templates/components/loading-cylon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YgzepGdu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 32 32\"],[11,\"width\",[22,\"loadingSvgSize\"]],[11,\"height\",[22,\"loadingSvgSize\"]],[11,\"fill\",[22,\"loadingSvgColor\"]],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"transform\",\"translate(0 0)\"],[10,\"d\",\"M0 12 V20 H4 V12z\"],[8],[0,\"\\n    \"],[7,\"animateTransform\",true],[10,\"attributeName\",\"transform\"],[10,\"values\",\"0 0; 28 0; 0 0; 0 0\"],[10,\"dur\",\"1.5s\"],[10,\"begin\",\"0\"],[10,\"repeatCount\",\"indefinite\"],[10,\"keytimes\",\"0;0.3;0.6;1\"],[10,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8\"],[10,\"calcMode\",\"spline\"],[10,\"type\",\"translate\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"path\",true],[10,\"opacity\",\"0.5\"],[10,\"transform\",\"translate(0 0)\"],[10,\"d\",\"M0 12 V20 H4 V12z\"],[8],[0,\"\\n    \"],[7,\"animateTransform\",true],[10,\"attributeName\",\"transform\"],[10,\"values\",\"0 0; 28 0; 0 0; 0 0\"],[10,\"dur\",\"1.5s\"],[10,\"begin\",\"0.1s\"],[10,\"repeatCount\",\"indefinite\"],[10,\"keytimes\",\"0;0.3;0.6;1\"],[10,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8\"],[10,\"calcMode\",\"spline\"],[10,\"type\",\"translate\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"path\",true],[10,\"opacity\",\"0.25\"],[10,\"transform\",\"translate(0 0)\"],[10,\"d\",\"M0 12 V20 H4 V12z\"],[8],[0,\"\\n    \"],[7,\"animateTransform\",true],[10,\"attributeName\",\"transform\"],[10,\"values\",\"0 0; 28 0; 0 0; 0 0\"],[10,\"dur\",\"1.5s\"],[10,\"begin\",\"0.2s\"],[10,\"repeatCount\",\"indefinite\"],[10,\"keytimes\",\"0;0.3;0.6;1\"],[10,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8\"],[10,\"calcMode\",\"spline\"],[10,\"type\",\"translate\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/loading-cylon.hbs"
    }
  });

  _exports.default = _default;
});