define("client/pods/components/w3office-forms/scroll-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+eI5eqHp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"d-flex w-100\"],[10,\"style\",\"position:fixed; bottom: 2%; justify-content: center; pointer-events: none;\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"id\",\"scrollUpBtn\"],[12,\"class\",\"btn btn-primary shadow-lg\"],[12,\"style\",\"border-radius: 50%; width: 40px; height: 40px; display: none; pointer-events: auto; justify-content: center; box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24);\"],[3,\"action\",[[23,0,[]],\"scrollToTop\"]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-fw fa-chevron-up my-auto\"],[10,\"style\",\"font-size: 20px;\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t \\n\\t\"],[7,\"button\",false],[12,\"id\",\"scrollDownBtn\"],[12,\"class\",\"btn btn-primary shadow-lg\"],[12,\"style\",\"border-radius: 50%; width: 40px; height: 40px; display: flex; pointer-events: auto; justify-content: center; box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24);\"],[3,\"action\",[[23,0,[]],\"scrollToBottomDiv\"]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-fw fa-chevron-down my-auto\"],[10,\"style\",\"font-size: 20px;\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/scroll-button/template.hbs"
    }
  });

  _exports.default = _default;
});