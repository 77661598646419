define("client/pods/components/w3o-users-roles/google-recaptcha/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o3w0TCbx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"recapt\"],[10,\"class\",\"g-recaptcha d-flex justify-content-center\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/google-recaptcha/template.hbs"
    }
  });

  _exports.default = _default;
});