define("client/pods/components/w3office-forms/droppable-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mL5Q6Axz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-none d-sm-block drop-field col-md-12\"],[10,\"style\",\"height: 60%; min-height: 229px; background-color: #eee;\"],[10,\"id\",\"dropZone\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[10,\"style\",\"padding-top: 5rem; padding-bottom: 5rem;\"],[8],[0,\" Drop Component Here \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"class\",\"d-block d-sm-none drop-field col-12\"],[12,\"style\",\"height: 200px;\"],[12,\"id\",\"dropZone\"],[3,\"action\",[[23,0,[]],\"pushComponent\"]],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[8],[0,\" Drop Component Here \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/droppable-field/template.hbs"
    }
  });

  _exports.default = _default;
});