define("client/pods/components/chart-builder-v2/chart-shout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bs4uJ/vR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"id\",[22,\"shoutId\"]],[10,\"class\",\"m-3\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"chartTitle mb-0\"],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"color: \",[24,[\"chartTitleColor\"]],\";\"],null]],null]],[8],[0,\" \"],[1,[22,\"dynamictitle\"],false],[0,\" \"],[9],[0,\"\\n    \"],[7,\"p\",true],[11,\"id\",[22,\"shoutIdValue\"]],[8],[1,[22,\"shoutValue\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/chart-builder-v2/chart-shout/template.hbs"
    }
  });

  _exports.default = _default;
});