define("client/pods/components/w3o-users-roles/form-user/postal-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JNnE1KGx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"postalCode\"],[8],[0,\"Postal Code \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"postalCodeIsEmpty\"],[8],[0,\"Postal Code cannot be empty\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"postalCodeRegexError\"],[8],[0,\"Invalid Postal Code format\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"postalCodeForm\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphPostalCode\"],[8],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"placeholder\",\"class\",\"value\",\"focus-out\"],[\"postalCode\",\"text\",\"Enter Postal Code\",\"form-control\",[24,[\"model\",\"postalCode\"]],[28,\"action\",[[23,0,[]],[24,[\"getInfo\"]],[24,[\"model\",\"postalCode\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/postal-code/template.hbs"
    }
  });

  _exports.default = _default;
});