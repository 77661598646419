define("client/pods/ui/unauthorized/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      if (window.location.href.indexOf('unauthorized') === -1) {
        return this.store.queryRecord('allowedroute', {
          routeName: param.route,
          dynamicValue: param.dynamic_value
        });
      } else {
        window.history.back();
      }
    },
    setupController: function setupController(controller, model) {
      if (model) {
        controller.set('pageName', model.get('pageName'));
      } else {
        controller.set('pageName', window.location.href);
      }
    }
  });

  _exports.default = _default;
});