define("client/pods/components/general-component/ember-quill/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.options = {
        theme: "snow",
        modules: {
          toolbar: [[{
            header: [2, 3, 4, false]
          }], ["bold", "italic", "underline", "strike"], [{
            "color": []
          }], [{
            "list": "ordered"
          }, {
            "list": "bullet"
          }], [{
            "indent": "-1"
          }, {
            "indent": "+1"
          }], [{
            "align": []
          }], ['image'], ["clean"]]
        },
        placeholder: this.get('placeholder'),
        readOnly: this.get('disabled')
      };
    },
    actions: {
      updateText: function updateText(editor) {
        console.log(editor.root.innerHTML);
        this.set('value', editor.root.innerHTML);
        this.mutateEntry();
      }
    }
  });

  _exports.default = _default;
});