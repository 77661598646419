define("client/pods/menu-builder/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kYxg9qpr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-header row border-top border-bottom mx-0\"],[10,\"style\",\"margin-bottom: 10px;\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"pull-left\"],[8],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[0,\"Menu\"],[9],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row mx-0\"],[10,\"style\",\"display: flex; justify-content: flex-end;\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"menu-builder.new\"]],{\"statements\":[[0,\"    \\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-success\"],[8],[0,\"\\n    \\t\\t\"],[7,\"span\",true],[10,\"class\",\"fa fa-fw fa-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Create New Menu\\n    \\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 padding-0\"],[8],[0,\"\\n\\t\"],[1,[28,\"component\",[\"menu-builder/menu-paging\"],[[\"appFilter\",\"appList\",\"changeAppFilter\",\"environment\",\"postModel\",\"deleteMenu\"],[[24,[\"appFilter\"]],[24,[\"appList\"]],[28,\"route-action\",[\"changeAppFilter\"],null],\"parent\",[24,[\"menuList\"]],[28,\"route-action\",[\"deleteMenu\"],null]]]],false],[0,\"\\n\\n\\t\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/menu-builder/index/template.hbs"
    }
  });

  _exports.default = _default;
});