define("client/pods/users-roles/groups/create/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('groupName', null);
      controller.set("isLoading", false);
    },
    actions: {
      cancelCreateGroup: function cancelCreateGroup() {
        this.set('controller.groupName', null);
        this.transitionTo("users-roles.groups");
      },
      createGroup: function createGroup(param) {
        var _this = this;

        if (Ember.isEmpty(param)) {
          (0, _jquery.default)("#groupNameIsEmpty").removeClass("d-none");
          return;
        }

        var controller = this.controller;
        (0, _jquery.default)("#groupNameIsEmpty").addClass("d-none");
        controller.set("isLoading", true);
        this.store.query("group", {
          where: {
            groupName: param
          }
        }).then(function (resultGroups) {
          console.log(resultGroups);

          if (resultGroups.get("length") === 0) {
            // allow Create
            var group = _this.store.createRecord('group', {
              groupName: param
            });

            group.save().then(function () {
              _this.transitionTo('users-roles.groups');
            });
          } else {
            (0, _jquery.default)("#groupNameIsExist").removeClass("d-none");
            controller.set("isLoading", false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});