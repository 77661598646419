define("client/pods/components/dashboard-builder/newedit-dabkpiconfig/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isNew: true,
    buttonName: Ember.computed('type', function () {
      if (this.get('type') === 'New') {
        return 'Add';
      } else if (this.get('type') === 'Edit') {
        return 'Update';
      }
    }),
    readOnly: Ember.computed('type', function () {
      if (this.get('type') === 'View') {
        return true;
      } else {
        return false;
      }
    }),
    init: function init() {
      this._super();

      if (this.get("type") === "New") {
        this.set("isNew", true);
      } else if (this.get('type') === 'Edit') {
        this.set("isNew", false);
      }

      this.methodSelects = [{
        value: "NA",
        text: "None"
      }, {
        value: "COUNT",
        text: "Count"
      }, {
        value: "SUM",
        text: "Sum"
      }, {
        value: "YEARMONTH",
        text: "Extract Calendar Month"
      }, {
        value: "EXTRACTDATE",
        text: "Extract Date"
      }, {
        value: "EXTRACTDAYOFWEEK",
        text: "Extract Day of Week"
      }, {
        value: "EXTRACTHOUR",
        text: "Extract Hour"
      }, {
        value: "MIN",
        text: "Min"
      }, {
        value: "MAX",
        text: "Max"
      }];
      this.conditionSelects = [{
        value: "EQUAL",
        text: "Equal"
      }, {
        value: "EQUALIGNORECASE",
        text: "Equal Ignore Case"
      }, {
        value: "GREATEROREQUAL",
        text: "Greater or Equal"
      }, {
        value: "GREATERTHAN",
        text: "Greater Than"
      }, {
        value: "LESSOREQUAL",
        text: "Less or Equal"
      }, {
        value: "LESSTHAN",
        text: "Less Than"
      }, {
        value: "LIKE",
        text: "Like"
      }, {
        value: "LIKEIGNORECASE",
        text: "Like Ignore Case"
      }, {
        value: "NOTEQUAL",
        text: "Not Equal"
      }, {
        value: "NOTLIKE",
        text: "Not Like"
      }, {
        value: "NOTLIKEIGNORECASE",
        text: "Not Like Ignore Case"
      }, {
        value: "STARTSWITH",
        text: "Starts With"
      }, {
        value: "ENDSWITH",
        text: "Ends With"
      }, {
        value: "ISNULL",
        text: "Is Null"
      }, {
        value: "ISNOTNULL",
        text: "Is not Null"
      }, {
        value: "ISTRUE",
        text: "Is True"
      }, {
        value: "ISFALSE",
        text: "Is False"
      }];
      this.joinTypes = [{
        value: "LEFT_OUTER_JOIN",
        text: "Left Outer Join"
      }, {
        value: "RIGHT_OUTER_JOIN",
        text: "Right Outer Join"
      }, {
        value: "CROSS_JOIN",
        text: "Cross Join"
      }, {
        value: "CROSS_APPLY",
        text: "Cross Apply"
      }, {
        value: "FULL_OUTER_JOIN",
        text: "Full Outer Join"
      }, {
        value: "JOIN",
        text: "Join"
      }, {
        value: "NATURAL_JOIN",
        text: "Natural Join"
      }, {
        value: "NATURAL_LEFT_OUTER_JOIN",
        text: "Natural Left Outer Join"
      }, {
        value: "NATURAL_RIGHT_OUTER_JOIN",
        text: "Natural Right Outer Join"
      }, {
        value: "OUTER_APPLY",
        text: "Outer Apply"
      }];
      this.groupbyFunctionSelects = [{
        value: "NA",
        text: "N/A"
      }, {
        value: "YEARMONTH",
        text: "Extract Calendar Month"
      }, {
        value: "EXTRACTDATE",
        text: "Extract Date"
      }, {
        value: "EXTRACTDAYOFWEEK",
        text: "Extract Day of Week"
      }, {
        value: "EXTRACTHOUR",
        text: "Extract Hour"
      }];
      return this._super();
    },
    tablenameQuery: null,
    columnNameQuery: null,
    actions: {
      saveDabkpiConfig: function saveDabkpiConfig() {
        var checkIsEmpty = function checkIsEmpty(obj, arrayProperties) {
          var objIsEmpty = false;

          for (var i = 0; i < arrayProperties.length; i++) {
            if (Ember.isEmpty(obj.get(arrayProperties[i]))) {
              objIsEmpty = true; // exit the for-loop

              i = arrayProperties.length;
            }
          }

          return objIsEmpty;
        }; // =============
        // SELECT CLAUSE
        // =============


        var selectionContents = this.get("selectionContents");
        var selectProperties = ["category", "tableName", "xColumnName"];
        var selectIsEmpty = false;

        for (var i = 0; i < selectionContents.length; i++) {
          if (checkIsEmpty(selectionContents[i], selectProperties)) {
            selectIsEmpty = true; // exit the for-loop

            i = selectionContents.length;
          }
        }

        this.set("errorSelect", selectIsEmpty);

        if (selectIsEmpty) {
          // $('html, body').animate({
          //     scrollTop: $("#kpi-config-selection-list").offset().top
          // }, 1000);
          return;
        } // ===========
        // FROM CLAUSE
        // ===========


        var fromProperties = ["category", "fromTable"];
        var fromIsEmpty = false;

        if (this.get("isTimechart")) {
          fromProperties.push("interval");
        }

        if (checkIsEmpty(this.get("objDabkpiConfig"), fromProperties)) {
          fromIsEmpty = true;
        }

        this.set("errorFrom", fromIsEmpty);

        if (fromIsEmpty) {
          // $('html, body').animate({
          //     scrollTop: -($("#kpi-config-from-clause").offset().top) - (window.innerHeight - $("#kpi-config-submit").offset().top)
          // }, 1000);
          return;
        } // ============
        // JOIN CLAUSE
        // ============


        var joinContents = this.get("joinContents");
        var joinProperties = ["joincategory", "jointable", "fromcategory", "fromtable", "fromid", "tocategory", "totable", "toid"];
        var joinIsEmpty = false;

        for (var _i = 0; _i < joinContents.length; _i++) {
          if (checkIsEmpty(joinContents[_i], joinProperties)) {
            joinIsEmpty = true; // exit the for-loop

            _i = joinContents.length;
          }
        }

        this.set("errorJoin", joinIsEmpty);

        if (joinIsEmpty) {
          // $('html, body').animate({
          //     scrollTop: -($("#kpi-config-join-list").offset().top) - (window.innerHeight - $("#kpi-config-submit").offset().top)
          // }, 1000);
          return;
        } // ============
        // WHERE CLAUSE
        // ============


        var conditionContents = this.get("conditionContents");
        var conditionProperties = ["category", "tableName", "columnName", "value"];
        var conditionIsEmpty = false;

        for (var _i2 = 0; _i2 < conditionContents.length; _i2++) {
          if (checkIsEmpty(conditionContents[_i2], conditionProperties)) {
            conditionIsEmpty = true; // exit the for-loop

            _i2 = conditionContents.length;
          }
        }

        this.set("errorWhere", conditionIsEmpty);

        if (conditionIsEmpty) {
          // $('html, body').animate({
          //     scrollTop: -($("#kpi-config-condition-list").offset().top) - (window.innerHeight - $("#kpi-config-submit").offset().top)
          // }, 1000);
          return;
        } // ==============
        // GROUPBY CLAUSE
        // ==============


        var groupbyContents = this.get("groupbyContents");
        var groupbyProperties = ["category", "tableName", "columnName"];
        var groupbyIsEmpty = false;

        for (var _i3 = 0; _i3 < groupbyContents.length; _i3++) {
          if (checkIsEmpty(groupbyContents[_i3], groupbyProperties)) {
            groupbyIsEmpty = true; // exit the for-loop

            _i3 = groupbyContents.length;
          }
        }

        this.set("errorGroupBy", groupbyIsEmpty);

        if (groupbyIsEmpty) {
          // $('html, body').animate({
          //     scrollTop: -($("#kpi-config-groupby-list").offset().top) - (window.innerHeight - $("#kpi-config-submit").offset().top)
          // }, 1000);
          return;
        }

        this.actionToRoute();
      },
      addContent: function addContent() {
        //Add Content to Group Content
        var self = this;
        var newContent = this.store.createRecord('dabkpiconfigcondition', {
          condition: 'EQUAL',
          operator: 'AND',
          dabkpiconfignum: self.get('model')
        });
        newContent.save().then(function (content) {
          self.get('conditionContents').addObject(content);
        });
      },
      addSelectionContent: function addSelectionContent() {
        //Add Content to Group Content
        var self = this;
        var newContent = this.store.createRecord('dabkpiconfigselection', {
          method: 'NA',
          dabkpinum: self.get('model').get('dabkpi'),
          dabkpiconfignum: self.get('model')
        });
        newContent.save().then(function (content) {
          self.get('selectionContents').addObject(content);
        });
      },
      addJoinContent: function addJoinContent() {
        //Add Content to Group Content
        var self = this;
        var newContent = this.store.createRecord('dabkpiconfigjoin', {
          type: 'LEFT_OUTER_JOIN',
          dabkpiconfignum: self.get('model')
        });
        newContent.save().then(function (content) {
          self.get('joinContents').addObject(content);
        });
      },
      addGroupbyContent: function addGroupbyContent() {
        //Add Content to Group Content
        var self = this;
        var newContent = this.store.createRecord('dabkpiconfiggroupby', {
          function: 'NA',
          dabkpiconfignum: self.get('model')
        });
        newContent.save().then(function (content) {
          self.get('groupbyContents').addObject(content);
        });
      },
      deleteContent: function deleteContent(condition) {
        //Delete Content from Group Content
        this.get('conditionContents').removeObject(condition);
        condition.destroyRecord();
      },
      deleteSelectionContent: function deleteSelectionContent(selection) {
        //Delete Content from Group Content
        this.get('selectionContents').removeObject(selection);
        selection.destroyRecord();
      },
      deleteJoinContent: function deleteJoinContent(join) {
        //Delete Content from Group Content
        this.get('joinContents').removeObject(join);
        join.destroyRecord();
      },
      deleteGroupbyContent: function deleteGroupbyContent(groupby) {
        //Delete Content from Group Content
        this.get('groupbyContents').removeObject(groupby);
        groupby.destroyRecord();
      },
      setParamcollection: function setParamcollection(paramcollection) {
        this.setParamcollection(paramcollection);
      },
      addParamcollection: function addParamcollection() {
        this.addParamcollection();
      },
      deleteParamcollection: function deleteParamcollection(paramcollection) {
        this.deleteParamcollection(paramcollection);
      },
      addParam: function addParam(selectedParamcollection) {
        this.addParam(selectedParamcollection);
      },
      deleteParam: function deleteParam(param) {
        this.deleteParam(param);
      }
    }
  });

  _exports.default = _default;
});