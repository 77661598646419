define("client/pods/components/dashboard-v2/chart-component/chart-bubble/component", ["exports", "client/mixins/dashboard/chart-detail-report"], function (_exports, _chartDetailReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartDetailReport.default, {
    selectedIndex: 0,
    selectedValue: null,
    primaryKey: null,
    isShowingModal: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'is_drilldown') === true) {
        Ember.set(this, 'drillable', true);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var self = this;
      var display_label_x = Ember.get(this, 'label_x') ? true : false;
      var display_label_y = Ember.get(this, 'label_y') ? true : false;
      var display_label_r = Ember.get(this, 'label_r') ? true : false;
      var label_x = Ember.get(this, 'label_x');
      var label_y = Ember.get(this, 'label_y');
      var label_r = Ember.get(this, 'label_r');
      Ember.set(this, 'primaryKey', this.column.label);
      var dataset = this.dataset;
      var title = this.title;
      var backgroundColor = ['#00876c', '#3a 966b', '#5da46a', '#7eb269', '#a1bf6a', '#c4ca6d', '#e8d575', '#eabe61', '#eaa653', '#e98d4b', '#e57449', '#de594c', '#d43d51', '#00876c', '#3a966b', '#5da46a', '#7eb269', '#a1bf6a', '#c4ca6d', '#e8d575', '#eabe61', '#eaa653', '#e98d4b', '#e57449', '#de594c', '#d43d51', '#00876c', '#3a966b', '#5da46a', '#7eb269', '#a1bf6a', '#c4ca6d', '#e8d575', '#eabe61', '#eaa653', '#e98d4b', '#e57449', '#de594c', '#d43d51', '#00876c', '#3a966b', '#5da46a', '#7eb269', '#a1bf6a', '#c4ca6d', '#e8d575', '#eabe61', '#eaa653', '#e98d4b', '#e57449', '#de594c', '#d43d51', '#00876c', '#3a966b', '#5da46a', '#7eb269', '#a1bf6a', '#c4ca6d', '#e8d575', '#eabe61', '#eaa653', '#e98d4b', '#e57449', '#de594c', '#d43d51']; //to scale r by percentage %, because bubble chart value r use pixel for its radius, so the r value can't be too large

      var total_r = 0;
      var risk_color = [];
      dataset.forEach(function (data, i) {
        total_r += data.data[0].r;
        risk_color.push(data.data[0].color);
      });

      if (Ember.get(this, 'use_risk_color') === true) {
        backgroundColor = risk_color;
      }

      var newdataset = [];
      dataset.forEach(function (data, i) {
        var scaled_r = data.data[0].r / total_r * 100;
        var scaled_data = {
          x: data.data[0].x,
          y: data.data[0].y,
          r: scaled_r
        };
        var dataset_obj = {
          label: [data.label],
          data: [scaled_data],
          backgroundColor: backgroundColor[i],
          borderColor: backgroundColor[i]
        };
        newdataset.push(dataset_obj);
      });
      var data = {
        labels: "Bubble",
        datasets: newdataset
        /*
                datasets : [
                  {
                    label: ["High Risk"],
                    backgroundColor: "#139af9",
                    borderColor: "#139af9",
                    data: [{
                      x: 0.1,
                      y: 1441853682.2500112,
                      r: 7276
                    }]
                  }, {
                    label: ["Medium Risk"],
                    backgroundColor: "rgba(192,80,70,1)",
                    borderColor: "rgba(192,80,70,1)",
                    data: [{
                      x: 0.5,
                      y: 1073395603.360004,
                      r: 4858
                    }]
                  }, {
                    label: ["Low Risk"],
                    backgroundColor: "rgba(155,187,89,1)",
                    borderColor: "rgba(155,187,89,1)",
                    data: [{
                      x: 0.3,
                      y: 1455910841.790023,
                      r: 7173
                    }]
                  }
                ]
        */

      };
      var options = {
        /*
        title: {
          display: true,
          text: title
        },
        */
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 20
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: display_label_y,
              labelString: label_y,
              fontStyle: 'bold'
            },
            ticks: {
              //max: 5000,
              beginAtZero: true
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: display_label_x,
              labelString: label_x,
              fontStyle: 'bold'
            },
            ticks: {
              max: 1,
              beginAtZero: true
            }
          }]
        },
        legend: {
          display: true,
          position: 'right'
        },
        tooltips: {
          callbacks: {
            label: function label(tooltipItems, data) {
              //console.log(tooltipItems.datasetIndex);
              //console.log(data);
              var tooltip_label = data.datasets[tooltipItems.datasetIndex].label;
              var tooltip_x_raw = data.datasets[tooltipItems.datasetIndex].data[0].x;
              var tooltip_y_raw = data.datasets[tooltipItems.datasetIndex].data[0].y;
              var tooltip_r_raw = data.datasets[tooltipItems.datasetIndex].data[0].r;
              var tooltip_x = tooltip_x_raw.toFixed(4) + '(' + label_x + ')';
              var tooltip_y = tooltip_y_raw.toFixed(4) + '(' + label_y + ')';
              var tooltip_r = tooltip_r_raw.toFixed(4) + '(' + label_r + ')';
              var tooltip_all = "".concat(tooltip_label, ": ").concat(tooltip_x, " , ").concat(tooltip_y, " , ").concat(tooltip_r);
              return tooltip_all;
            }
          }
        },
        onClick: function onClick(event, array) {
          //Do nothing if user click on the blank space
          if (Ember.isEmpty(array)) {
            return;
          }

          var selectedIndex = array[0]._datasetIndex;
          Ember.set(self, 'selectedIndex', selectedIndex);
          Ember.set(self, 'selectedValue', newdataset[selectedIndex].label[0]);
          self.openModal(self);
        }
      };
      this.set('data', data);
      this.set('options', options);
    },
    openModal: function openModal(chart) {
      Ember.set(chart, 'isShowingModal', true);
    },
    closeModal: function closeModal(chart) {
      Ember.set(chart, 'isShowingModal', false);
    },
    drillDown: function drillDown() {},
    drillUp: function drillUp() {},
    detailReport: function detailReport() {
      var searchColumn = this.primaryKey;
      var searchInput = this.selectedValue;
      Ember.set(this, 'invoiceParam.searchColumn', searchColumn);
      Ember.set(this, 'invoiceParam.searchInput', searchInput);
      this.getInvoice(this);
      this.closeModal(this);
      this.openDetail();
    },
    cancel: function cancel() {
      this.closeModal(this);
    }
  });

  _exports.default = _default;
});