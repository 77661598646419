define("client/models/dabkpihistoryparam", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //attributes
    xDateValue: (0, _attr.default)('date'),
    xValue: (0, _attr.default)("string"),
    yValue: (0, _attr.default)("number"),
    //relations
    dabkpinum: (0, _relationships.belongsTo)("dabkpi", {
      async: true
    }),
    dabkpiparamresultnum: (0, _relationships.belongsTo)("dabkpiparamresult", {
      async: true
    })
  });

  _exports.default = _default;
});