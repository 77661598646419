define("client/pods/dashboard-builder/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/menu-builder/serializablejson", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/dashboard-builder/paging-common"], function (_exports, _checkUserRole, _serializablejson, _applicationRouteMixin, _authenticatedRouteMixin, _pagingCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _serializablejson.default, _pagingCommon.default, {
    // beforeModel: function(){
    // 	this._super(); //add this at the very first of beforeModel if there are any beforeModel
    // },
    // renderTemplate: function(){
    // //	this._super(); //add this at the very first of renderTemplate if there are any renderTemplate
    // },
    model: function model() {
      return this.store.findAll('dashboard');
    },
    setupController: function setupController(controller, model) {
      var modelQuery = this.store.query('dashboard', {
        where: {
          isdelete: false
        },
        sort: 'id ASC'
      });

      this._super(controller, model, modelQuery);
    },
    actions: {
      deleteDashboard: function deleteDashboard(dashboard) {
        var self = this;
        this.store.findRecord('dashboard', dashboard.id).then(function (container) {
          self.controller.get('modelConArr').removeObject(dashboard);
          self.controller.get('modelCon').removeObject(dashboard);
          self.controller.set('modelCount', self.controller.get('modelConArr').get('length'));

          if (self.controller.get('modelCount') === 0) {
            self.controller.set('totalModel', 1);
          } else {
            self.controller.set('totalPage', Math.ceil(self.controller.get('modelCount') / 10));
          }

          if (self.controller.get('listPage') < self.controller.get('totalPage')) {
            var firstCount = self.controller.get('firstList');
            var postCount = self.controller.get('postLimit');
            self.controller.set('modelCon', self.controller.get('modelConArr').slice(firstCount, postCount));
          }

          container.set('isdelete', true);
          container.save();
        });
      }
    }
  });

  _exports.default = _default;
});