define("client/pods/menu-builder/child/view/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(param) {
      return this.store.findRecord("menu", param.child_id);
    },
    setupController: function setupController(controller, model) {
      var menunum = this.modelFor('menu-builder.child');
      controller.set("menuid", menunum.id);
      controller.set("menutitle", menunum.get('title'));
      controller.set("childid", model.id);
      controller.set("childtitle", model.get('title'));
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});