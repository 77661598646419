define("client/models/entrycontent", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    metaValue: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    fileType: (0, _attr.default)('string'),
    field: (0, _relationships.belongsTo)('field', {
      async: true
    }),
    entry: (0, _relationships.belongsTo)('entry', {
      async: true
    }),
    downloadable: Ember.computed('field', function () {
      if (this.get('field').get('fieldType') === 'w3office-forms/advanced-components/form-upload' && !Ember.isEmpty(this.get('metaValue'))) {
        return true;
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});