define("client/mixins/w3oprocess/process-builder-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /*
    	Inject session service as authObject
    */
    authObject: Ember.inject.service('session'),

    /*
    Initialize controller variables and headers for table
    */
    setupController: function setupController() {
      var controller = this.get('controller');
      var model = this.get('currentModel'); // this.store.findAll('processinstance');

      controller.set('approver', '');
      controller.set('difficulty', '');
      controller.set('instruction', '');
      controller.set('loading', true);
      controller.set('currentProcess', model.get('content').objectAt(0));
      this.store.findRecord('user', this.get('authObject.data.user').id).then(function (user) {
        controller.set('currentUser', user);
      });
      controller.set('listPage', 1);
      controller.set('totalPage', 1);
      controller.set('limit', 10);
      controller.set('sortBy', 'processId');
      controller.set('sortOrder', 'ASC');
      this.getProcesses();
    },
    getProcesses: function getProcesses() {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Paska
      // Edit date : 1 November 2016
      // Note : Refactor to Ember 2.8
      var controller = this.get('controller');
      var self = this;
      var temp = Ember.A();
      return _jquery.default.ajax({
        type: "POST",
        data: {
          page: controller.get('listPage'),
          limit: controller.get('limit'),
          sortBy: controller.get('sortBy'),
          sortOrder: controller.get('sortOrder')
        },
        url: self.store.adapterFor('application').get('namespace') + '/processes/getProcesses',
        success: function success(response) {
          var Variable = Ember.Object.extend({
            id: null,
            app: null,
            drafted: false,
            description: null,
            processId: null,
            // processversions: null,
            supervisor: null,
            title: null,
            userId: null,
            version: null,
            instanceRunning: null
          });
          response.process.forEach(function (process) {
            // self.store.query('processversion', { process: process.id }).then(function(processversions){
            temp.pushObject(Variable.create({
              id: process.id,
              app: process.app,
              drafted: process.drafted,
              description: process.description,
              processId: process.processId,
              // processversions: self.store.query('processversion', { process: process.id }),
              supervisor: process.supervisor,
              title: process.title,
              userId: process.userId,
              version: process.version,
              instanceRunning: process.instanceRunning
            }));
            controller.set('model', temp);
            controller.set('loading', false); // });
          });

          if (!response.process.length) {
            controller.set('model', null);
            controller.set('loading', false);
          }

          if (response.exceedTotal) {
            controller.set('listPage', 1);
          }

          controller.set('totalPage', response.totalPage);
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },
    doSort: function doSort(param) {
      var controller = this.get('controller');

      if (controller.get('sortBy') !== param) {
        controller.set('sortBy', param);
        controller.set('sortOrder', 'ASC');
      } else {
        if (controller.get('sortOrder') === 'DESC') {
          controller.set('sortOrder', 'ASC');
        } else {
          controller.set('sortOrder', 'DESC');
        }
      }

      this.getProcesses();
    },
    changeLimit: function changeLimit() {
      this.getProcesses();
    },
    prevList: function prevList() {
      var controller = this.get('controller');
      controller.set('listPage', controller.get('listPage') - 1);
      this.getProcesses();
    },
    nextList: function nextList() {
      var controller = this.get('controller');
      controller.set('listPage', controller.get('listPage') + 1);
      this.getProcesses();
    },
    gotoInstances: function gotoInstances(id) {
      console.log(id);
      this.transitionTo('process-builder.instances', id);
    },
    startProcess: function startProcess(process) {
      var self = this;
      var controller = this.get('controller');
      var url = self.store.adapterFor('application').get('namespace') + '/processactivities/startProcess';

      _jquery.default.ajax({
        type: "POST",
        url: url,
        data: {
          processID: controller.get('currentProcess.processId'),
          approverID: this.get('authObject.data.user').id,
          difficulty: controller.get('difficulty'),
          instruction: controller.get('instruction'),
          from: 'process'
        },
        success: function success(res) {
          console.log(res);
          (0, _jquery.default)('#new-process-modal').modal('toggle');
          self.transitionTo('process-manager.inbox');
        }
      });
    },
    storeProcess: function storeProcess(process, index) {
      var controller = this.get('controller');
      (0, _jquery.default)('.list-group-item').removeClass('active');
      (0, _jquery.default)('#process-' + index).addClass('active');
      controller.set('currentProcess', process);
    },
    selectThisUser: function selectThisUser() {
      var controller = this.get('controller');
      controller.set('approver', controller.get('currentUser'));
    },
    updateDate: function updateDate(mut, date) {},
    moveTo: function moveTo(param) {
      this.transitionTo(param);
    }
  });

  _exports.default = _default;
});