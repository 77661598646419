define("client/pods/components/w3office-forms/logic-master-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.comparatorList = [{
        name: 'Equal To',
        value: '='
      }, {
        name: 'Not',
        value: '!'
      }, {
        name: 'Greater Than',
        value: '>'
      }, {
        name: 'Less Than',
        value: '<'
      }];
      this.triggerOnChangeList = [{
        name: 'Field',
        value: 'field'
      }, {
        name: 'Form Property',
        value: 'form-property'
      }, {
        name: 'Initializer',
        value: 'initializer'
      }, {
        name: 'Parent Property',
        value: 'parent-property'
      }, {
        name: 'Query Params',
        value: 'query-params'
      }];
      this.errorMessage = '';
      this.logic = null;
      this.logicContent = null;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      /*This hook only called on re-render*/
      this._super();

      if (this.get('logicMaster')) {
        if (this.get('logicMaster').id && this.get('logicListIsLoading')) {
          this.populateFieldLogic(this.get('logicMaster').get('id'));

          if (this.get('logicMaster').get('propertyName') === 'fieldgroupcontent' || this.get('logicMaster').get('propertyName') === 'apiURL') {
            this.populateFieldLogicContent(this.get('logicMaster').get('id'));
          }
        }
      }
    },
    actions: {
      saveLogicMaster: function saveLogicMaster() {
        this.set("showEntriesLogic", true);
        this.persistLogicMaster(this.get('logicMaster'));
      },
      discardChanges: function discardChanges() {
        if (Ember.isEmpty(this.get('logicMaster').id)) {
          //unload if data is uncommited
          this.get('logicMaster').unloadRecord();
        } else {
          //rollback if data is persisted
          this.get('logicMaster').rollbackAttributes();
        }

        (0, _jquery.default)('#logic-master-props').modal('hide');
        this.set('showEntriesLogic', true);
      },
      createLogic: function createLogic() {
        this.set('showEntriesLogic', false);
        this.createLogic(this);
      },
      editLogic: function editLogic(logic) {
        this.set('showEntriesLogic', false);
        this.set('logic', logic);
      },
      deleteLogic: function deleteLogic(logic) {
        this.get('logicList').removeObject(logic);

        if (logic.currentState.stateName === 'root.loaded.created.uncommitted') {
          logic.unloadRecord();
        } else {
          logic.deleteRecord();
          logic.save();
        }
      },
      cancelEdit: function cancelEdit() {
        this.set('showEntriesLogic', true);
        this.set('logic', null);
      },
      createLogicContent: function createLogicContent() {
        this.set('showEntriesLogicContent', false);
        this.createLogicContent(this);
      },
      editLogicContent: function editLogicContent(logicContent) {
        this.set('showEntriesLogicContent', false);
        this.set('logicContent', logicContent);
      },
      deleteLogicContent: function deleteLogicContent(logicContent) {
        this.get('logicContentList').removeObject(logicContent);

        if (logicContent.currentState.stateName === 'root.loaded.created.uncommitted') {
          logicContent.unloadRecord();
        } else {
          logicContent.deleteRecord();
          logicContent.save();
        }
      },
      cancelEditContent: function cancelEditContent() {
        this.set('showEntriesLogicContent', true);
        this.set('logicContent', null);
      },
      observeAffectedField: function observeAffectedField() {
        if (this.get('logicMaster')) {
          if (this.get('logicMaster').get('affectedField').get('id')) {
            this.populateFieldGroupContent(this.get('logicMaster').get('affectedField').get('id'));
          }
        }
      }
    }
  });

  _exports.default = _default;
});