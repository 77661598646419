define("client/pods/components/report-generator/content-tab/select-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lastlabel: null,
    didRender: function didRender() {
      var _this = this;

      var columnlabel = null;
      var self = this;

      if (!Ember.isEmpty(this.get('ascolumn'))) {
        this.get('selectedFieldContents').forEach(function (field) {
          if (field.get('ascolumn') === self.get('ascolumn')) {
            columnlabel = field.get('columnlabel');
          }
        });

        if (Ember.isEmpty(this.get('value'))) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            return _this.set('value', columnlabel);
          });
        } else {
          if (this.get('value') === this.get('lastlabel')) {
            Ember.run.scheduleOnce('afterRender', this, function () {
              return _this.set('value', columnlabel);
            });
          }
        }

        this.set('lastlabel', columnlabel);
      }
    }
  });

  _exports.default = _default;
});