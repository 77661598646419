define("client/pods/components/dashboard-v2/preview-table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mMHFtmVT",
    "block": "{\"symbols\":[\"record\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"enableRecordsTicking\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",false],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"actionTickRecord\"]],[24,[\"content\"]]],null]],[[\"bubbles\"],[false]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"content\",\"isRecordChecked\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"input\",true],[11,\"checked\",[24,[\"content\",\"isRecordChecked\"]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"input\",true],[11,\"checked\",[24,[\"content\",\"isRecordChecked\"]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"records\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"td\",true],[10,\"role\",\"button\"],[10,\"class\",\"ellipsisTableColumn\"],[11,\"data-title\",[23,1,[\"id\"]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"id\"]],\"Action\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"isBoolean\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"content\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"disabled\"],[\"checkbox\",true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[23,1,[\"content\"]],true],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dashboard-v2/preview-table-row/template.hbs"
    }
  });

  _exports.default = _default;
});