define("client/pods/table-builder/table-list/route", ["exports", "axios", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _axios, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    model: function model() {
      return this.store.findAll('formtable');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },
    actions: {
      searchTable: function searchTable() {
        var _this = this;

        this.get('controller').set('loading', true);
        this.store.query('formtable', {
          where: {
            isdelete: false,
            tableName: {
              contains: this.get('controller').get('searchInput')
            }
          }
        }).then(function (result) {
          _this.get('controller').set('model', result);

          _this.get('controller').set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});