define("client/models/kpi", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    kpiid: _emberData.default.attr('string'),
    cautionmax: _emberData.default.attr('number'),
    cautionmin: _emberData.default.attr('number'),
    target: _emberData.default.attr('number'),
    kpivalue: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    selectstatement: _emberData.default.attr('string'),
    clause: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    createdby: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    modifiedby: _emberData.default.attr('string'),
    modifieddate: _emberData.default.attr('date'),
    isactive: _emberData.default.attr('boolean'),
    ispublic: _emberData.default.attr('boolean'),
    valueupdatedate: _emberData.default.attr('date'),
    valueupdateby: _emberData.default.attr('string'),
    calctype: _emberData.default.attr('string'),
    //relation
    companynum: _emberData.default.belongsTo("company", {
      async: true
    })
  });

  _exports.default = _default;
});