define("client/pods/users-roles/users/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yh7cKMYc",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 col-sm-12 padding-0\"],[8],[0,\"\\n\\t\"],[4,\"link-to\",null,[[\"route\"],[\"users-roles.users\"]],{\"statements\":[[7,\"u\",true],[8],[0,\"Back to User List\"],[9]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[28,\"component\",[\"w3o-users-roles/user-profile\"],[[\"model\",\"user\",\"countryList\",\"userDetail\",\"setStatus\",\"disabled\",\"isUploading\",\"profilepic\",\"dataURI\",\"canReset\",\"isEditing\",\"disabledSaveButton\",\"stateToken\",\"hasStripeAccount\",\"stripeClientID\",\"upload\",\"saveEdit\",\"getInfo\",\"changeStatus\",\"changePassword\",\"changeQuestion\",\"unblockUser\",\"invalidateSession\"],[[24,[\"model\"]],[24,[\"model\"]],[24,[\"countryList\"]],[24,[\"userDetail\"]],[24,[\"setStatus\"]],[24,[\"disabled\"]],[24,[\"isUploading\"]],[24,[\"profilepic\"]],[24,[\"dataURI\"]],[24,[\"canReset\"]],[24,[\"isEditing\"]],[24,[\"disabledSaveButton\"]],[24,[\"stateToken\"]],[24,[\"hasStripeAccount\"]],[24,[\"stripeClientID\"]],\"ppupload\",[28,\"route-action\",[\"saveEdit\"],null],[28,\"route-action\",[\"getInfo\"],null],[28,\"route-action\",[\"changeStatus\"],null],[28,\"route-action\",[\"changePassword\"],null],[28,\"route-action\",[\"changeQuestion\"],null],[28,\"route-action\",[\"unblockUser\"],null],[28,\"route-action\",[\"invalidateSession\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 col-sm-12 padding-0\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"triggered\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"component\",[\"payment/flash-message\"],[[\"status\",\"succeed\",\"message\"],[[24,[\"triggered\"]],[24,[\"succeed\"]],[24,[\"message\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/users-roles/users/detail/template.hbs"
    }
  });

  _exports.default = _default;
});