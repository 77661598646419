define("client/pods/process-builder/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KPpBSeGv",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"pull-left\"],[8],[7,\"h2\",true],[8],[0,\"Create Process\"],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"card col-md-12\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"component\",[\"general-component/loading-cover\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[15,\"process-builder/form\",[]],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"style\",\"padding-bottom: 50px\"],[8],[0,\"\\n\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row float-right mx-0\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-danger\",\"process-builder.index\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\tCancel\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-success\"],[3,\"action\",[[23,0,[]],\"create\",[24,[\"model\"]]]],[8],[0,\"\\n\\t\\t\\t\\t\\tSave Process\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "client/pods/process-builder/create/template.hbs"
    }
  });

  _exports.default = _default;
});