define("client/pods/users-roles/role-manager/index/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    appCon: '',
    actionList: null,
    init: function init() {
      this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel() {
      var Variable = Ember.Object.extend({
        action: null,
        varName: null,
        status: false
      });
      var actions = [Variable.create({
        action: 'Create App',
        varName: 'canCreateApp',
        status: false
      }), Variable.create({
        action: 'Edit App',
        varName: 'canEditApp',
        status: false
      }), Variable.create({
        action: 'Delete App',
        varName: 'canDeleteApp',
        status: false
      })];
      this.set('actionList', actions);

      this._super();
    },
    model: function model() {
      return this.store.findAll('application');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('show', false);
      controller.set('canCreateApp', false);
      controller.set('canEditApp', false);
      controller.set('canDeleteApp', false);
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateApp') {
          controller.set('canCreateApp', action.get('status'));
        }

        if (action.get('varName') === 'canEditApp') {
          controller.set('canEditApp', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteApp') {
          controller.set('canDeleteApp', action.get('status'));
        }
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();

      var controller = this.get('controller');
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateApp') {
          controller.set('canCreateApp', action.get('status'));
        }

        if (action.get('varName') === 'canEditApp') {
          controller.set('canEditApp', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteApp') {
          controller.set('canDeleteApp', action.get('status'));
        }
      });
    },
    actions: {
      storeApp: function storeApp(params) {
        this.set('appCon', params);
      },
      deleteApp: function deleteApp() {
        var self = this;

        _jquery.default.ajax({
          type: "POST",
          data: {
            app: self.get('appCon.id')
          },
          url: self.store.adapterFor('application').get('namespace') + '/applications/deleteApp',
          success: function success(response) {
            self.get('appCon').destroyRecord();
          },
          error: function error(xhr, status, _error) {
            console.log('Error ' + _error);
          }
        });
      }
    }
  });

  _exports.default = _default;
});