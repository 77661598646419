define("client/models/formuploadedfile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    UUID: _emberData.default.attr('string'),
    sourceNum: _emberData.default.attr('number'),
    sourceName: _emberData.default.attr('string'),
    filePath: _emberData.default.attr('string'),
    fileType: _emberData.default.attr('string'),
    name: _emberData.default.attr("string"),
    size: _emberData.default.attr("number"),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });

  _exports.default = _default;
});