define("client/components/ol-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Checkbox.extend({
    instrumentDisplay: '{{input type="radio"}}',
    classNames: ['ol-radio special_check_box'],
    classNameBindings: ['isChecked:right-icon:right-icon-inactive'],
    type: 'radio'
  });

  _exports.default = _default;
});