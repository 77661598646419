define("client/pods/components/report-generator/content-tab/report-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deleteSelect: function deleteSelect(select) {
        this.deleteSelect(select);
      }
    }
  });

  _exports.default = _default;
});