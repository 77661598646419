define("client/pods/w3office-forms/index/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "axios"], function (_exports, _jquery, _checkUserRole, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller, model) {
      controller.set("currentUser", this.store.findRecord("user", this.get("authObject.data.authenticated.user.id"))); //Set Controller Properties

      var self = this;
      controller.set('conditionContainer', {
        skip: 0,
        limit: 10,
        sort: 'id ASC'
      });
      controller.set('disabledPrevNavigation', true);
      controller.set('disabledNextNavigation', true);
      controller.set('initialLoadForm', true);
      controller.set('formContainer', []);
      controller.set('currentDelete', 0);
      this.controller.set('conditionContainer', {
        skip: (parseInt(controller.get('currentPage')) - 1) * 10,
        limit: 10,
        sort: 'id ASC',
        where: {
          name: {
            contains: controller.get('filter')
          },
          appID: controller.get('appID')
        }
      });
      this.populateForms();
      /*Populate appList*/

      _jquery.default.ajax({
        type: "POST",
        url: self.store.adapterFor('application').get('namespace') + '/forms/getAvailableAppId',
        success: function success(response) {
          controller.set('appList', response.appIds);
        }
      });
      /*End of Populate appList*/

    },
    populateForms: function populateForms() {
      var self = this;
      var controller = this.controller;
      controller.set('initialLoadForm', true);
      var axiosConfig = {
        method: "GET",
        params: self.controller.get('conditionContainer'),
        url: self.store.adapterFor('application').get('namespace') + '/forms/getForms'
      };
      (0, _axios.default)(axiosConfig).then(function (response) {
        if (response.status === 200) {
          controller.set('totalPage', Math.ceil(response.data.total / 10 === 0 ? 1 : Math.ceil(response.data.total / 10)));
          controller.set('initialLoadForm', false);
          response.data.results.forEach(function (form) {
            if (response.data.usersObject[form.createdBy]) {
              form.createdBy = response.data.usersObject[form.createdBy];
            }

            if (response.data.usersObject[form.updatedBy]) {
              form.updatedBy = response.data.usersObject[form.updatedBy];
            }
          });
          controller.set('formContainer', response.data.results);

          if (controller.get('totalPage') === controller.get('currentPage')) {
            controller.set('disabledNextNavigation', true);
          } else {
            controller.set('disabledNextNavigation', false);
          }

          if (controller.get('currentPage') === 1) {
            controller.set('disabledPrevNavigation', true);
          } else {
            controller.set('disabledPrevNavigation', false);
          }
        }
      }).catch(function (err) {
        console.log("ERROR: something wrong with getForms");
        console.log(err);
      });
    },
    actions: {
      newFormTransition: function newFormTransition() {
        var _this = this;

        // this.get("authObject.data.authenticated.user.id")
        var newForm = this.store.createRecord('form', {
          name: 'New Form',
          description: '',
          submissionRecording: 'internal-record',
          author: this.get('formContainer.sessionHolder'),
          enableDirectAccess: true,
          createdBy: this.controller.get("currentUser"),
          updatedBy: this.controller.get("currentUser")
        });
        newForm.save().then(function (form) {
          _this.transitionTo('w3office-forms.form.edit', form);
        });
      },
      filterForm: function filterForm(filterValue, appFilter) {
        var tempWhere = {};
        tempWhere.skip = 0;
        tempWhere.limit = 0;
        tempWhere.sort = this.controller.get('conditionContainer').sort;
        this.controller.set('appID', appFilter);
        this.controller.set('filter', filterValue);

        if (filterValue || appFilter) {
          tempWhere.where = {};

          if (filterValue) {
            tempWhere.where.name = {
              contains: filterValue
            };
          }

          if (appFilter) {
            tempWhere.where.appID = appFilter;
          }
        }

        this.controller.set('currentPage', 1);
        this.controller.set('conditionContainer', {
          skip: 0,
          limit: 10,
          sort: 'id ASC',
          where: {
            name: {
              contains: filterValue
            },
            appID: appFilter
          }
        });
        this.populateForms();
      },
      nextList: function nextList() {
        if (this.controller.get('currentPage') < this.controller.get('totalPage')) {
          this.controller.set('disabledPrevNavigation', true);
          this.controller.set('disabledNextNavigation', true);
          this.controller.set('currentPage', parseInt(this.controller.get('currentPage')) + 1);
          this.controller.get('conditionContainer').skip += this.controller.get('conditionContainer').limit;
          this.populateForms();
        }
      },
      prevList: function prevList() {
        if (this.controller.get('currentPage') > 1) {
          this.controller.set('disabledPrevNavigation', true);
          this.controller.set('disabledNextNavigation', true);
          this.controller.set('currentPage', parseInt(this.controller.get('currentPage')) - 1);
          this.controller.get('conditionContainer').skip -= this.controller.get('conditionContainer').limit;
          this.populateForms();
        }
      },
      deleteForm: function deleteForm(formID) {
        var self = this;
        var url = this.store.adapterFor('application').get('namespace') + '/forms/deleteForm';

        _jquery.default.ajax({
          type: "POST",
          url: url,
          data: {
            formID: formID
          },
          success: function success(response) {
            self.populateForms();
          }
        });
      },
      toggleDeleteForm: function toggleDeleteForm(formID) {
        if (this.controller.get('currentDelete') !== 0) {
          (0, _jquery.default)(".form-" + this.controller.get('currentDelete')).css({
            "display": ""
          });
          (0, _jquery.default)(".delete-" + this.controller.get('currentDelete')).css({
            "display": "none"
          });
        }

        this.controller.set('currentDelete', formID);
        (0, _jquery.default)(".form-" + formID).css({
          "display": "none"
        });
        (0, _jquery.default)(".delete-" + formID).css({
          "display": ""
        });
      },
      cancelDeleteForm: function cancelDeleteForm(formID) {
        this.controller.set('currentDelete', 0);
        (0, _jquery.default)(".form-" + formID).css({
          "display": ""
        });
        (0, _jquery.default)(".delete-" + formID).css({
          "display": "none"
        });
      }
    }
  });

  _exports.default = _default;
});