define("client/pods/components/w3office-forms/standard-components/check-box/component-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8eBreb/y",
    "block": "{\"symbols\":[\"content\",\"index\",\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"isSkeleton\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"fieldContents\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"field\",\"displayContentInline\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"label\",true],[10,\"class\",\"form-check-inline my-0\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"input\",null,[[\"id\",\"type\",\"checked\",\"disabled\",\"change\"],[[28,\"concat\",[\"checkbox-\",[24,[\"field\",\"id\"]]],null],\"checkbox\",[23,1,[\"value\"]],[24,[\"field\",\"isDisabled\"]],[28,\"action\",[[23,0,[]],\"mutateEntry\",[23,1,[\"name\"]]],null]]]],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"px-2\"],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"color:\",[24,[\"currentTheme\",\"valueFontColor\"]],\"; font-size:\",[24,[\"currentTheme\",\"valueFontSize\"]],\";\"],null]],null]],[8],[0,\" \"],[1,[23,1,[\"name\"]],false],[0,\" \"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"label\",true],[10,\"class\",\"my-0\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"input\",null,[[\"id\",\"type\",\"checked\",\"disabled\",\"change\"],[[28,\"concat\",[\"checkbox-\",[24,[\"field\",\"id\"]]],null],\"checkbox\",[23,1,[\"value\"]],[24,[\"field\",\"isDisabled\"]],[28,\"action\",[[23,0,[]],\"mutateEntry\",[23,1,[\"name\"]]],null]]]],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"px-2\"],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"color:\",[24,[\"currentTheme\",\"valueFontColor\"]],\"; font-size:\",[24,[\"currentTheme\",\"valueFontSize\"]],\";\"],null]],null]],[8],[0,\" \"],[1,[23,1,[\"name\"]],false],[0,\" \"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/standard-components/check-box/component-field/template.hbs"
    }
  });

  _exports.default = _default;
});