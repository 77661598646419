define("client/pods/components/w3office-forms/advanced-components/auto-fill/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    // need to add isSkeleton here
    isSkeleton: Ember.computed('field.isLoading', function () {
      return this.get('field').get('isLoading');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('field').get('entryContainer')) {
        this.get('field').set('containerLabel', this.get('field').get('entryContainer'));
      } else {
        this.get('field').set('containerLabel', null);
      }
    },
    actions: {
      // when auto-fill is editable, then changes on containerLabel will mutate the entryContainer
      mutateEntryContainer: function mutateEntryContainer() {
        this.setEntryContainer(this.get('field'), this.get('field').get('containerLabel'));
      }
    }
  });

  _exports.default = _default;
});