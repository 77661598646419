define("client/models/entryconfiguration", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    configurationName: (0, _attr.default)('string'),
    listingType: (0, _attr.default)('string'),
    startDateColumn: (0, _attr.default)('string'),
    endDateColumn: (0, _attr.default)('string'),
    associationString: (0, _attr.default)('string'),
    latitudeColumn: (0, _attr.default)('string'),
    longitudeColumn: (0, _attr.default)('string'),
    sortBy: (0, _attr.default)('string'),
    isAscending: (0, _attr.default)('boolean'),
    entryListConfiguration: (0, _relationships.belongsTo)('entrylistconfiguration', {
      async: true
    }),
    entryGroup: (0, _relationships.belongsTo)('entrygroup', {
      async: true
    }),
    mappedFrom: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});