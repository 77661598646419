define("client/pods/components/w3office-forms/bootstrap-table-row-audit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "24Ww/P/H",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[7,\"td\",true],[8],[1,[28,\"moment-format\",[[24,[\"record\",\"updatedAt\"]],\"DD-MM-YYYY HH:mm:ss\"],null],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[28,\"get\",[[24,[\"record\",\"updatedBy\"]],\"firstName\"],null],false],[0,\" \"],[1,[28,\"get\",[[24,[\"record\",\"updatedBy\"]],\"lastName\"],null],false],[0,\" ( \"],[1,[28,\"get\",[[24,[\"record\",\"updatedBy\"]],\"email\"],null],false],[0,\" ) \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"isChanged\"]]],null,{\"statements\":[[0,\"        \"],[7,\"td\",true],[10,\"style\",\"background-color: #ff6961;\"],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"td\",true],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/bootstrap-table-row-audit/template.hbs"
    }
  });

  _exports.default = _default;
});