define("client/templates/components/loading-bubbles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CwREVswY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 32 32\"],[11,\"width\",[22,\"loadingSvgSize\"]],[11,\"height\",[22,\"loadingSvgSize\"]],[11,\"fill\",[22,\"loadingSvgColor\"]],[8],[0,\"\\n  \"],[7,\"circle\",true],[10,\"transform\",\"translate(8 0)\"],[10,\"cx\",\"0\"],[10,\"cy\",\"16\"],[10,\"r\",\"0\"],[8],[0,\" \\n    \"],[7,\"animate\",true],[10,\"attributeName\",\"r\"],[10,\"values\",\"0; 4; 0; 0\"],[10,\"dur\",\"1.2s\"],[10,\"repeatCount\",\"indefinite\"],[10,\"begin\",\"0\"],[10,\"keytimes\",\"0;0.2;0.7;1\"],[10,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8\"],[10,\"calcMode\",\"spline\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"circle\",true],[10,\"transform\",\"translate(16 0)\"],[10,\"cx\",\"0\"],[10,\"cy\",\"16\"],[10,\"r\",\"0\"],[8],[0,\" \\n    \"],[7,\"animate\",true],[10,\"attributeName\",\"r\"],[10,\"values\",\"0; 4; 0; 0\"],[10,\"dur\",\"1.2s\"],[10,\"repeatCount\",\"indefinite\"],[10,\"begin\",\"0.3\"],[10,\"keytimes\",\"0;0.2;0.7;1\"],[10,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8\"],[10,\"calcMode\",\"spline\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"circle\",true],[10,\"transform\",\"translate(24 0)\"],[10,\"cx\",\"0\"],[10,\"cy\",\"16\"],[10,\"r\",\"0\"],[8],[0,\" \\n    \"],[7,\"animate\",true],[10,\"attributeName\",\"r\"],[10,\"values\",\"0; 4; 0; 0\"],[10,\"dur\",\"1.2s\"],[10,\"repeatCount\",\"indefinite\"],[10,\"begin\",\"0.6\"],[10,\"keytimes\",\"0;0.2;0.7;1\"],[10,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8\"],[10,\"calcMode\",\"spline\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/loading-bubbles.hbs"
    }
  });

  _exports.default = _default;
});