define("client/models/geoportaluserrole", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // attributes
    // userid: DS.attr('number'),
    // geo_roleid: DS.attr('number'),
    isfoc: _emberData.default.attr('boolean'),
    userid: _emberData.default.belongsTo('user', {
      async: true
    }),
    geo_roleid: _emberData.default.belongsTo('geoportalrole', {
      async: true
    })
  });

  _exports.default = _default;
});