define("client/pods/components/w3office-forms/player-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'mx-0', 'col-12'],
    actions: {
      autoCompleteSetup: function autoCompleteSetup(component) {
        //called from: advanced-components/auto-complete
        this.autoCompleteSetup(component);
      },
      queryInput: function queryInput() {
        //called from: advanced-components/auto-complete
        this.queryInput();
      },
      setEntryContainer: function setEntryContainer(field, val) {
        this.setEntryContainer(field, val);
      },
      fieldActionGateway: function fieldActionGateway(component, dynamicParams) {
        return this.fieldActionGateway(component, dynamicParams);
      }
    }
  });

  _exports.default = _default;
});