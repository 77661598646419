define("client/pods/table-builder/create-table/route", ["exports", "axios", "jquery", "client/mixins/w3o-users-roles/check-user-role", "ember-inflector"], function (_exports, _axios, _jquery, _checkUserRole, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    model: function model() {
      return this.store.createRecord('formtable', {});
    },
    setupController: function setupController(controller, model) {
      this._super();

      controller.set('model', model);
      this.store.findRecord('user', this.get('authObject.data.user').id).then(function (user) {
        controller.set('currentUser', user);
        model.setProperties({
          createdBy: user,
          updatedBy: user
        });
      });
      controller.set('disableCreateButton', false); //Fetch list of formtable to provide selection for relation

      controller.set('formtables', this.store.findAll('formtable'));
      console.log(controller.get('formtables'));
    },
    resetController: function resetController(controller, isExiting, transition) {
      console.log(transition);

      if (isExiting) {
        controller.get('model').rollbackAttributes();
      }
    },
    normalizeSnakeCase: function normalizeSnakeCase(input) {
      input = input.split('_').join('');
      input = input.split('-').join('');
      input = input.split(' ').join('');
      return input.toLowerCase();
    },
    actions: {
      tableNameUpdated: function tableNameUpdated() {
        console.log(this.get('controller').get('model').get('tableName'));
        var normalizedTableName = this.normalizeSnakeCase(this.get('controller').get('model').get('tableName'));
        this.get('controller').get('model').set('modelName', normalizedTableName.charAt(0).toUpperCase() + normalizedTableName.slice(1));
      },
      addNewColumn: function addNewColumn() {
        this.get('controller').get('newColumns').pushObject(Ember.Object.create({
          column_name: null,
          data_type: 'varchar',
          max_length: null,
          remark: 'New'
        }));
      },
      toggleShapeColumn: function toggleShapeColumn() {
        if (this.get('controller').get('createShape')) {
          //create SHAPE column
          this.get('controller').get('newColumns').pushObject(Ember.Object.create({
            column_name: 'SHAPE',
            data_type: '"MDSYS"."SDO_GEOMETRY"',
            max_length: null,
            remark: 'Default'
          }));
        } else {
          //find the index of genereated SHAPE column
          var ShapeColumnIndex = this.get('controller').get('newColumns').findIndex(function (newColumn) {
            return newColumn.column_name === 'SHAPE' && newColumn.remark === 'Default';
          }); //delete the SHAPE column

          this.get('controller').get('newColumns').removeAt(ShapeColumnIndex);
        }
      },
      deleteNewColumn: function deleteNewColumn(index) {
        this.get('controller').get('newColumns').removeAt(index);
      },
      commitChanges: function commitChanges() {
        var _this = this;

        this.get('controller').set('disableCreateButton', true);
        var controller = this.get('controller');
        var model = controller.get('model');
        (0, _axios.default)({
          method: 'POST',
          url: "".concat(model.get('tableServer.domain')).concat(model.get('tableServer.namespace'), "/Formtables/updateTable"),
          data: {
            newTable: this.get('controller').get('creatingTable'),
            tableName: this.get('controller').get('model').get('tableName'),
            newColumns: this.get('controller').get('newColumns'),
            modelName: this.get('controller').get('model').get('modelName'),
            existingTable: this.get('controller').get('model').get('existingTable')
          }
        }).then(function (result) {
          console.log(result);

          _this.get('controller').get('model').save();

          _this.get('controller').set('triggered', 'succeed');

          _this.get('controller').set('succeed', true);

          _this.get('controller').set('message', 'Changes successfully commited');

          Ember.run.later(_this, function () {
            this.get('controller').set('triggered', null);
            this.get('controller').set('message', null);
            this.transitionTo('table-builder.table-list');
          }, 2000);
        }).catch(function (err) {
          console.log(err);

          _this.get('controller').set('disableCreateButton', false);

          _this.get('controller').set('triggered', 'error');

          _this.get('controller').set('succeed', false);

          _this.get('controller').set('message', "Error while commiting changes.");

          Ember.run.later(_this, function () {
            this.get('controller').set('triggered', null);
            this.get('controller').set('message', null);
          }, 5000);
        });
      },
      updateTableServer: function updateTableServer(tableserver) {
        var controller = this.get('controller');
        (0, _jquery.default)('#serverDatabase').addClass('skeleton');
        controller.set('selectedTableServer', tableserver);
        controller.get('model').set('tableServer', tableserver);
        controller.get('model').set('serverDatabase', null);
        (0, _axios.default)({
          method: 'GET',
          url: "".concat(tableserver.domain).concat(tableserver.namespace, "/Formtables/getServerDatabase")
        }).then(function (serverDatabase) {
          (0, _jquery.default)('#serverDatabase').removeClass('skeleton');
          controller.get('model').set('serverDatabase', serverDatabase.data);
          controller.set('newColumns', Ember.A()); //Set default columns

          if (controller.get('model').get('serverDatabase') != "oracle") {
            controller.get('newColumns').pushObject(Ember.Object.create({
              column_name: 'id',
              data_type: 'sequence',
              max_length: null,
              primaryKey: true,
              remark: 'Default'
            }));
          } else {
            controller.get('newColumns').pushObject(Ember.Object.create({
              column_name: 'id',
              data_type: 'varchar',
              max_length: null,
              primaryKey: true,
              remark: 'Default'
            }));
            controller.get('newColumns').pushObject(Ember.Object.create({
              column_name: 'seq_num',
              data_type: 'sequence',
              max_length: null,
              remark: 'Default'
            }));
          }

          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: 'isdelete',
            data_type: 'bit',
            max_length: null,
            remark: 'Default'
          }));
          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: 'createdAt',
            data_type: 'datetime',
            max_length: null,
            remark: 'Default'
          }));
          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: 'updatedAt',
            data_type: 'datetime',
            max_length: null,
            remark: 'Default'
          }));
          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: 'createdBy',
            data_type: 'int',
            max_length: null,
            remark: 'Default'
          }));
          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: 'updatedBy',
            data_type: 'int',
            max_length: null,
            remark: 'Default'
          }));
          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: 'organizationnum',
            data_type: 'int',
            max_length: null,
            remark: 'Default'
          }));
          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: 'companynum',
            data_type: 'int',
            max_length: null,
            remark: 'Default'
          }));
          controller.get('newColumns').pushObject(Ember.Object.create({
            column_name: null,
            data_type: 'varchar',
            max_length: null,
            remark: 'New'
          }));
          controller.set('creatingTable', true);
        }).catch(function (err) {
          console.log(err);
          (0, _jquery.default)('#serverDatabase').removeClass('skeleton');

          if (tableserver) {
            alert("Cannot determine the database from this server");
          }
        });
      }
    }
  });

  _exports.default = _default;
});