define("client/pods/components/w3o-users-roles/modal-dialog/component", ["exports", "client/mixins/w3o-users-roles/focus-for-keypress", "jquery"], function (_exports, _focusForKeypress, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //importing 3rd party library mixins/focus-for-keypress
  var _default = Ember.Component.extend(_focusForKeypress.default, {
    // if ESCAPE key is pressed, close the modal dialog
    action: 'closeModal',
    keyDown: function keyDown(e) {
      if (e.keyCode === 27) {// this.sendAction();
      }
    },
    //show the modal dialog
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = (0, _jquery.default)('#modalDialog').modal({
        backdrop: 'static',
        keyboard: false,
        'show': true
      }); // TODO: Check to see if we need to cleanup this on event upon destroy

      modal.on('hidden.bs.modal', function () {// this.sendAction();
      });
    },
    //hide the modal dialog
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('#modalDialog').modal('hide');
    }
  });

  _exports.default = _default;
});