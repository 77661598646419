define("client/pods/components/w3office-forms/standard-components/input-text/component-field/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isSkeleton:skeleton'],
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('field').get('validationType') === 'password') {
        this.set('type', 'password');
      } else if (this.get('field').get('validationType') === 'number') {
        this.set('type', 'number');
      } else {
        this.set('type', 'text');
      }
    },
    didRender: function didRender() {
      if (this.get('currentTheme')) {
        (0, _jquery.default)("#input-".concat(this.get('field').id)).css({
          "color": this.get('currentTheme').valueFontColor,
          "font-size": this.get('currentTheme').valueFontSize
        });

        if (this.get('currentTheme').borderStyle === "ALL") {
          (0, _jquery.default)("#input-".concat(this.get('field').id)).removeClass("simpleInputText");
        }

        if (this.get("type") === 'password') {
          (0, _jquery.default)("#retype-input-".concat(this.get('field').id)).css({
            "color": this.get('currentTheme').valueFontColor,
            "font-size": this.get('currentTheme').valueFontSize
          });

          if (this.get('currentTheme').borderStyle === "ALL") {
            (0, _jquery.default)("#retype-input-".concat(this.get('field').id)).removeClass("simpleInputText");
          }
        }
      }
    },
    actions: {
      mutateEntry: function mutateEntry() {
        this.mutateEntry();
      }
    }
  });

  _exports.default = _default;
});