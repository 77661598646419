define("client/models/processsequence", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    sequenceId: (0, _attr.default)('string'),
    source: (0, _attr.default)('string'),
    //activity, event, or gateway
    target: (0, _attr.default)('string'),
    //activity, event, or gateway
    targetType: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    sequenceIndex: (0, _attr.default)('number'),
    startX: (0, _attr.default)('number'),
    startY: (0, _attr.default)('number'),
    finX: (0, _attr.default)('number'),
    finY: (0, _attr.default)('number'),
    processversion: (0, _relationships.belongsTo)('processversion', {
      async: true
    }),
    numericID: Ember.computed('id', function () {
      var id = this.get('id');

      if (id) {
        return +id;
      }
    })
  });

  _exports.default = _default;
});