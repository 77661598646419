define("client/pods/components/w3o-users-roles/form-user/component", ["exports", "client/pods/components/w3o-users-roles/form-user/base-form-user", "jquery"], function (_exports, _baseFormUser, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseFormUser.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var model = this.get("model");
      (0, _jquery.default)('#firstNameForm input').blur(function () {
        _this.validateFirstName(this.value);
      });
      (0, _jquery.default)('#lastNameForm input').blur(function () {
        _this.validateLastName(this.value);
      });
      (0, _jquery.default)('#emailForm input').blur(function () {
        _this.validateEmail(this.value);
      });
      (0, _jquery.default)('#usernameForm input').blur(function () {
        _this.validateUsername(this.value);
      });
      (0, _jquery.default)('#countryForm select').change(function () {
        _this.validateCountry(this.value);
      });
      (0, _jquery.default)('#phoneForm input').blur(function () {
        _this.validatePhone(this.value);
      });
      (0, _jquery.default)('#postalCodeForm input').blur(function () {
        var postalcodeformat = model.get("country").get("postalcodeformat");
        var regexPostalCode = new RegExp(postalcodeformat);

        _this.validatePostalCode({
          value: this.value,
          postalcodeformat: postalcodeformat,
          regexPostalCode: regexPostalCode
        });
      });

      if ((0, _jquery.default)('#addressForm input').length > 0) {
        (0, _jquery.default)('#addressForm input').blur(function () {
          _this.validateAddress(this.value);
        });
      }

      if ((0, _jquery.default)('#addressForm textarea').length > 0) {
        (0, _jquery.default)('#addressForm textarea').blur(function () {
          _this.validateAddress(this.value);
        });
      }

      (0, _jquery.default)('#address1Form input').blur(function () {
        _this.validateAddress1(this.value);
      });
      (0, _jquery.default)('#cityForm input').blur(function () {
        _this.validateCity(this.value);
      });
      (0, _jquery.default)('#stateForm input').blur(function () {
        _this.validateState(this.value);
      });
      (0, _jquery.default)('#passwordForm input').blur(function () {
        _this.validatePassword(this.value);
      });
      (0, _jquery.default)('#passwordRepeatForm input').blur(function () {
        _this.validatePasswordRepeat({
          value: this.value,
          password: model.get("password")
        });
      });
      (0, _jquery.default)('#questionForm select').change(function () {
        _this.validateSecretQuestion(this.value);
      });
      (0, _jquery.default)('#answerForm input').blur(function () {
        _this.validateAnswer(this.value);
      });
      (0, _jquery.default)('#answerRepeatForm input').blur(function () {
        _this.validateAnswerRepeat({
          value: this.value,
          answer: model.get("answer")
        });
      });
      (0, _jquery.default)('#groupForm select').change(function () {
        _this.validateGroup(this.value);
      });
    },
    scrollTo: function scrollTo(divId) {
      (0, _jquery.default)('html, body').animate({
        scrollTop: (0, _jquery.default)("#".concat(divId)).offset().top - ((0, _jquery.default)("#".concat(divId)).height() + 80)
      }, 1000);
    },
    actions: {
      submit: function submit(model) {
        var flag = 0,
            scrolled = false;
        var postalcodeformat = model.get("country").get("postalcodeformat");
        var regexPostalCode = new RegExp(postalcodeformat);
        var checkFirstName = this.validateFirstName(model.get("firstName"));

        if (checkFirstName === 1 && !scrolled) {
          flag = flag + checkFirstName;
          scrolled = true;
          this.scrollTo("firstNameForm");
        }

        var checkLastName = this.validateLastName(model.get("lastName"));

        if (checkLastName === 1 && !scrolled) {
          flag = flag + checkLastName;
          scrolled = true;
          this.scrollTo("lastNameForm");
        }

        var checkEmail = this.validateEmail(model.get("email"));

        if (checkEmail === 1 && !scrolled) {
          flag = flag + checkEmail;
          scrolled = true;
          this.scrollTo("emailForm");
        }

        var checkUsername = this.validateUsername(model.get("username"));

        if (checkUsername === 1 && !scrolled) {
          flag = flag + checkUsername;
          scrolled = true;
          this.scrollTo("usernameForm");
        }

        var checkCountry = this.validateCountry(model.get("country.content"));

        if (checkCountry === 1 && !scrolled) {
          flag = flag + checkCountry;
          scrolled = true;
          this.scrollTo("countryForm");
        }

        var checkPhone = this.validatePhone(model.get("phone"));

        if (checkPhone === 1 && !scrolled) {
          flag = flag + checkPhone;
          scrolled = true;
          this.scrollTo("phoneForm");
        }

        var checkPostalCode = this.validatePostalCode({
          value: model.get("postalCode"),
          postalcodeformat: postalcodeformat,
          regexPostalCode: regexPostalCode
        });

        if (checkPostalCode === 1 && !scrolled) {
          flag = flag + checkPostalCode;
          scrolled = true;
          this.scrollTo("postalCodeForm");
        }

        var checkAddress = this.validateAddress(model.get("address"));

        if (checkAddress === 1 && !scrolled) {
          flag = flag + checkAddress;
          scrolled = true;
          this.scrollTo("addressForm");
        }

        var checkAddress1 = this.validateAddress1(model.get("address1"));

        if (checkAddress1 === 1 && !scrolled) {
          flag = flag + checkAddress1;
          scrolled = true;
          this.scrollTo("address1Form");
        }

        var checkCity = this.validateCity(model.get("city"));

        if (checkCity === 1 && !scrolled) {
          flag = flag + checkCity;
          scrolled = true;
          this.scrollTo("cityForm");
        }

        var checkState = this.validateState(model.get("state"));

        if (checkState === 1 && !scrolled) {
          flag = flag + checkState;
          scrolled = true;
          this.scrollTo("stateForm");
        }

        if (this.get("isCreate")) {
          var checkPassword = this.validatePassword(model.get("password"));

          if (checkPassword === 1 && !scrolled) {
            flag = flag + checkPassword;
            scrolled = true;
            this.scrollTo("passwordForm");
          }

          var checkPasswordRepeat = this.validatePasswordRepeat({
            value: model.get("passwordRepeat"),
            password: model.get("password")
          });

          if (checkPasswordRepeat === 1 && !scrolled) {
            flag = flag + checkPasswordRepeat;
            scrolled = true;
            this.scrollTo("passwordRepeatForm");
          }

          var checkSecretQuestion = this.validateSecretQuestion(model.get('secretquestion.content'));

          if (checkSecretQuestion === 1 && !scrolled) {
            flag = flag + checkSecretQuestion;
            scrolled = true;
            this.scrollTo("questionForm");
          }

          var checkAnswer = this.validateAnswer(model.get("answer"));

          if (checkAnswer === 1 && !scrolled) {
            flag = flag + checkAnswer;
            scrolled = true;
            this.scrollTo("answerForm");
          }

          var checkAnswerRepeat = this.validateAnswerRepeat({
            value: model.get("answerRepeat"),
            answer: model.get("answer")
          });

          if (checkAnswerRepeat === 1 && !scrolled) {
            flag = flag + checkAnswerRepeat;
            scrolled = true;
            this.scrollTo("answerRepeatForm");
          }

          var checkGroup = this.validateGroup(this.get("groupCon"));

          if (checkGroup === 1 && !scrolled) {
            flag = flag + checkGroup;
            scrolled = true;
            this.scrollTo("groupForm");
          }
        }

        if (flag === 0) {
          this.submit(model);
        }
      },
      cancel: function cancel() {
        this.cancel();
      },
      getInfo: function getInfo(param) {
        if (this.get('model').get('country').get('id') == 192) {
          this.getInfo(param);
        }
      }
    }
  });

  _exports.default = _default;
});