define("client/pods/dashboard-builder/dabreport/config/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(param) {
      return this.store.findRecord("dashboardreport", param.dabreport_id);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      var dashboardnum = this.modelFor('dashboard-builder.dabreport');
      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set("dabreportid", model.id);
      controller.set("dabreporttitle", model.get('reportName'));
      var methodSelects = [{
        value: "NA",
        text: "None"
      }, {
        value: "COUNT",
        text: "Count"
      }, {
        value: "SUM",
        text: "Sum"
      }, {
        value: "EXTRACTDATE",
        text: "Extract Date"
      }, {
        value: "MIN",
        text: "Min"
      }];
      var conditionSelects = [{
        value: "EQUAL",
        text: "Equal"
      }, {
        value: "EQUALIGNORECASE",
        text: "Equal Ignore Case"
      }, {
        value: "GREATEROREQUAL",
        text: "Greater or Equal"
      }, {
        value: "GREATERTHAN",
        text: "Greater Than"
      }, {
        value: "LESSOREQUAL",
        text: "Less or Equal"
      }, {
        value: "LESSTHAN",
        text: "Less Than"
      }, {
        value: "LIKE",
        text: "Like"
      }, {
        value: "LIKEIGNORECASE",
        text: "Like Ignore Case"
      }, {
        value: "NOTEQUAL",
        text: "Not Equal"
      }, {
        value: "NOTLIKE",
        text: "Not Like"
      }, {
        value: "NOTLIKEIGNORECASE",
        text: "Not Like Ignore Case"
      }, {
        value: "STARTSWITH",
        text: "Starts With"
      }, {
        value: "ENDSWITH",
        text: "Ends With"
      }, {
        value: "ISNULL",
        text: "Is Null"
      }, {
        value: "ISNOTNULL",
        text: "Is not Null"
      }, {
        value: "ISTRUE",
        text: "Is True"
      }, {
        value: "ISFALSE",
        text: "Is False"
      }];
      var joinTypes = [{
        value: "LEFT_OUTER_JOIN",
        text: "Left Outer Join"
      }, {
        value: "RIGHT_OUTER_JOIN",
        text: "Right Outer Join"
      }, {
        value: "CROSS_JOIN",
        text: "Cross Join"
      }, {
        value: "CROSS_APPLY",
        text: "Cross Apply"
      }, {
        value: "FULL_OUTER_JOIN",
        text: "Full Outer Join"
      }, {
        value: "JOIN",
        text: "Join"
      }, {
        value: "NATURAL_JOIN",
        text: "Natural Join"
      }, {
        value: "NATURAL_LEFT_OUTER_JOIN",
        text: "Natural Left Outer Join"
      }, {
        value: "NATURAL_RIGHT_OUTER_JOIN",
        text: "Natural Right Outer Join"
      }, {
        value: "OUTER_APPLY",
        text: "Outer Apply"
      }];
      var groupbyFunctionSelects = [{
        value: "NA",
        text: "N/A"
      }, {
        value: "EXTRACTDATE",
        text: "Extract Date"
      }];
      controller.set('methodSelects', methodSelects);
      controller.set('conditionSelects', conditionSelects);
      controller.set('joinTypes', joinTypes);
      controller.set('groupbyFunctionSelects', groupbyFunctionSelects); //Define controller properties

      controller.set('model', model);
      controller.set('userfirstname', this.get('session.user.firstName')); //controller.set('companynum', this.get('optmmSetting.companynum'));

      controller.set('currentRoute', this.get('currentRoute'));
      controller.set('componentsShown', false); //content tab

      controller.set('availableFieldContents', Ember.A());
      controller.set('selectedTableCollection', Ember.A());
      controller.set('availableFieldCollection', Ember.A());
      controller.set('selectedFieldCollection', Ember.A());
      controller.set('paramContents', null);
      controller.set('selectedParamcollection', null);
      controller.set('paramLoading', false);
      controller.set('selection', Ember.Object.create({
        category: '',
        tablename: ''
      }));
      this.controller.set('currentReport', model);
      this.controller.set('selectedTableQuery', this.store.query('dashboardreportconfigtable', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      this.controller.get('selectedTableQuery').then(function (selectedTableContents) {
        self.controller.set('selectedTableContents', selectedTableContents.toArray());
      });
      this.controller.set('selectedFieldQuery', this.store.query('dashboardreportconfigfield', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      this.controller.get('selectedFieldQuery').then(function (selectedFieldContents) {
        self.controller.set('selectedFieldContents', selectedFieldContents.toArray());
      });
      this.controller.set('selectQuery', this.store.query('dashboardreportconfigselect', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      this.controller.get('selectQuery').then(function (selectContents) {
        self.controller.set('selectContents', selectContents.toArray());
      });
      this.controller.set('joinQuery', this.store.query('dashboardreportconfigjoin', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      this.controller.get('joinQuery').then(function (joinContents) {
        self.controller.set('joinContents', joinContents.toArray());
      });
      this.controller.set('groupbyQuery', this.store.query('dashboardreportconfiggroupby', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      this.controller.get('groupbyQuery').then(function (groupbyContents) {
        self.controller.set('groupbyContents', groupbyContents.toArray());
      });
      this.controller.set('filterQuery', this.store.query('dashboardreportconfigfilter', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      this.controller.get('filterQuery').then(function (filterContents) {
        self.controller.set('filterContents', filterContents.toArray());
      });
      this.controller.set('sortQuery', this.store.query('dashboardreportconfigsort', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      this.controller.get('sortQuery').then(function (sortContents) {
        self.controller.set('sortContents', sortContents.toArray());
      });
      self.controller.set('paramcollectionQuery', self.store.query('dashboardreportparamcollection', {
        where: {
          dashboardreportnum: model.id,
          isdelete: false
        },
        sort: 'id ASC'
      }));
      self.controller.get('paramcollectionQuery').then(function (paramcollectionContents) {
        self.controller.set('paramcollectionContents', paramcollectionContents.toArray());
      });
    },
    actions: {
      saveReport: function saveReport() {
        var self = this;
        var promises = Ember.A();
        var filterQuery = [];
        var selectQuery = [];
        var joinQuery = [];
        var groupbyQuery = [];
        var orderbyQuery = [];
        var where;
        var select;
        var join;
        var groupby;
        var sort;
        var fromtable;
        var report = this.controller.get('currentReport');

        if (this.controller.get('selectedTableContents')) {
          this.controller.get('selectedTableContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('selectedFieldContents')) {
          this.controller.get('selectedFieldContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('selectContents')) {
          this.controller.get('selectContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('joinContents')) {
          this.controller.get('joinContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('groupbyContents')) {
          this.controller.get('groupbyContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('filterContents')) {
          this.controller.get('filterContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('sortContents')) {
          this.controller.get('sortContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('paramcollectionContents')) {
          this.controller.get('paramcollectionContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }

        if (this.controller.get('paramContents')) {
          this.controller.get('paramContents').forEach(function (content) {
            return promises.push(content.save());
          });
        }
        /*
        	To solve the bug of whatsql cannot get the latest generated id 
        	as its name from dabreportconfigselect
        */


        Ember.RSVP.Promise.all(promises).then(function () {
          return promiseSaveAll;
        });
        var promiseSaveAll = new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.later(function () {
            self.controller.set('selectQuery', self.store.query('dashboardreportconfigselect', {
              where: {
                dashboardreportnum: self.controller.get('model.id'),
                isdelete: false
              },
              sort: 'id ASC'
            }));
            self.controller.get('selectQuery').then(function (selectContents) {
              self.controller.set('selectContents', selectContents.toArray());
              self.controller.get('selectedTableContents').forEach(function (table) {
                if (table.get('astable') === report.get('fromtable')) {
                  fromtable = [{
                    "fromtable": table.get('tablename'),
                    'astable': report.get('fromtable'),
                    'limit': report.get('limit')
                  }];
                }
              });
              self.controller.get('filterContents').forEach(function (filterContent) {
                //ok
                self.controller.get('selectedFieldContents').forEach(function (field) {
                  if (field.get('ascolumn') === filterContent.get('ascolumn')) {
                    where = {
                      'where': field.get('astable') + '.' + field.get('columnname'),
                      'function': filterContent.get('function'),
                      'value': filterContent.get('value')
                    };
                  }
                });
                filterQuery.push(where);
              });
              self.controller.get('selectContents').forEach(function (selectContent) {
                self.controller.get('selectedFieldContents').forEach(function (field) {
                  if (field.get('ascolumn') === selectContent.get('ascolumn')) {
                    select = {
                      'select': field.get('tablename') + '.' + field.get('columnname'),
                      'function': selectContent.get('method'),
                      'asname': selectContent.get('id'),
                      'astable': field.get('astable')
                    };
                  }
                });
                selectQuery.push(select);
              });
              self.controller.get('joinContents').forEach(function (joinContent) {
                //ok
                var jointable;
                var fromid;
                var toid;
                self.controller.get('selectedFieldContents').forEach(function (field) {
                  if (field.get('ascolumn') === joinContent.get('fromascolumn')) {
                    fromid = field.get('astable') + '.' + field.get('columnname');
                  }
                });
                self.controller.get('selectedFieldContents').forEach(function (field) {
                  if (field.get('ascolumn') === joinContent.get('toascolumn')) {
                    toid = field.get('astable') + '.' + field.get('columnname');
                  }
                });
                self.controller.get('selectedTableContents').forEach(function (table) {
                  if (table.get('astable') === joinContent.get('jointable')) {
                    jointable = table.get('tablename');
                  }
                });
                join = {
                  'type': joinContent.get('type'),
                  'jointable': jointable,
                  'fromid': fromid,
                  'toid': toid,
                  'astable': joinContent.get('jointable')
                };
                joinQuery.push(join);
              });
              self.controller.get('groupbyContents').forEach(function (groupbyContent) {
                self.controller.get('selectedFieldContents').forEach(function (field) {
                  if (field.get('ascolumn') === groupbyContent.get('ascolumn')) {
                    groupby = {
                      'groupby': field.get('astable') + '.' + field.get('columnname'),
                      'function': groupbyContent.get('function')
                    };
                  }
                });
                groupbyQuery.push(groupby);
              });
              self.controller.get('sortContents').forEach(function (sortContent) {
                self.controller.get('selectedFieldContents').forEach(function (field) {
                  if (field.get('ascolumn') === sortContent.get('ascolumn')) {
                    sort = {
                      'orderby': field.get('astable') + '.' + field.get('columnname'),
                      'function': sortContent.get('sort')
                    };
                  }
                });
                orderbyQuery.push(sort);
              });
              var whatsql = {
                'selects': selectQuery,
                'from': fromtable,
                'joins': joinQuery,
                'wheres': filterQuery,
                'groupbys': groupbyQuery,
                'orderbys': orderbyQuery
              };
              report.set("whatsql", JSON.stringify(whatsql));
              promises.push(report.save());
              alert('Your report configuration is created successfully');
              resolve();
            });
          }, 2000);
        }); //EmberPromise.all(promises).then(() => self.transitionTo('dashboard-builder.dabreport'));
      },
      addTable: function addTable() {
        var self = this;
        var category = this.controller.get('selection').get('category');
        var tablename = this.controller.get('selection').get('tablename');
        this.store.query('entitymapping', {
          category: category,
          tablename: tablename
        }).then(function (results) {
          var astable;
          var firstObject = results.get('firstObject');
          astable = firstObject.get('tablename') + '_' + self.controller.get('currentReport.tablecount');
          var newTable = self.store.createRecord('dashboardreportconfigtable', {
            category: firstObject.get('category'),
            tablename: firstObject.get('tablename'),
            modellabel: firstObject.get('modellabel'),
            astable: astable,
            dashboardreportnum: self.controller.get('currentReport')
          });
          self.controller.set('currentReport.tablecount', self.controller.get('currentReport.tablecount') + 1);
          self.controller.get('selectedTableContents').addObject(newTable);
        });
      },
      fetchField: function fetchField() {
        var self = this;
        var field;
        self.controller.set('availableFieldContents', Ember.A());
        this.controller.get('selectedTableCollection').forEach(function (table) {
          self.store.query('entitymapping', {
            category: table.get('category'),
            tablename: table.get('tablename')
          }).then(function (results) {
            if (!Ember.isEmpty(results)) {
              results.forEach(function (result) {
                field = Ember.Object.create({
                  category: result.get('category'),
                  columnlabel: result.get('columnlabel'),
                  columnname: result.get('columnname'),
                  datatype: result.get('datatype'),
                  modellabel: result.get('modellabel'),
                  tablename: result.get('tablename'),
                  astable: table.get('astable')
                });
                self.controller.get('availableFieldContents').pushObject(field);
              });
            }
          });
        });
        self.controller.set('selectedTableCollection', Ember.A());
      },
      removeAllTable: function removeAllTable() {
        var self = this;
        this.controller.get('selectedTableContents').forEach(function (table) {
          try {
            self.store.findRecord('dashboardreportconfigtable', table.id).then(function (container) {
              self.controller.get('selectedTableContents').removeObject(table);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedTableContents').removeObject(table);
          }
        });
        this.controller.set('selectedTableCollection', Ember.A());
      },
      removeSelectedTable: function removeSelectedTable() {
        var self = this;
        this.controller.get('selectedTableCollection').forEach(function (table) {
          try {
            self.store.findRecord('dashboardreportconfigtable', table.id).then(function (container) {
              self.controller.get('selectedTableContents').removeObject(table);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedTableContents').removeObject(table);
          }
        });
        this.controller.set('selectedTableCollection', Ember.A());
      },
      selectAllTable: function selectAllTable() {
        self.controller.set('selectedTableCollection', self.controller.get('selectedTableContents'));
      },
      uncheckAllTable: function uncheckAllTable() {
        self.controller.set('selectedTableCollection', Ember.A());
      },
      addAllField: function addAllField() {
        var self = this;
        var newField;
        self.controller.get('availableFieldContents').forEach(function (field) {
          newField = self.store.createRecord('dashboardreportconfigfield', {
            tablename: field.get('tablename'),
            modellabel: field.get('modellabel'),
            columnname: field.get('columnname'),
            columnlabel: field.get('columnlabel'),
            astable: field.get('astable'),
            ascolumn: field.get('astable') + '.' + field.get('columnname'),
            dashboardreportnum: self.controller.get('currentReport')
          });

          if (!Ember.isEmpty(self.controller.get('selectedFieldContents').get('firstObject'))) {
            var isExist = false;
            self.controller.get('selectedFieldContents').forEach(function (field) {
              if (field.get('tablename') === newField.get('tablename') && field.get('columnname') === newField.get('columnname') && field.get('astable') === newField.get('astable')) {
                isExist = true;
              }
            });

            if (!isExist) {
              self.controller.get('selectedFieldContents').addObject(newField);
            }
          } else {
            self.controller.get('selectedFieldContents').addObject(newField);
          }
        });
        self.controller.set('availableFieldCollection', Ember.A());
      },
      addSelectedField: function addSelectedField() {
        var self = this;
        self.controller.get('availableFieldCollection').forEach(function (field) {
          var newField = self.store.createRecord('dashboardreportconfigfield', {
            tablename: field.get('tablename'),
            modellabel: field.get('modellabel'),
            columnname: field.get('columnname'),
            columnlabel: field.get('columnlabel'),
            astable: field.get('astable'),
            ascolumn: field.get('astable') + '.' + field.get('columnname'),
            dashboardreportnum: self.controller.get('currentReport')
          });

          if (!Ember.isEmpty(self.controller.get('selectedFieldContents').get('firstObject'))) {
            var isExist = false;
            self.controller.get('selectedFieldContents').forEach(function (field) {
              if (field.get('tablename') === newField.get('tablename') && field.get('columnname') === newField.get('columnname') && field.get('astable') === newField.get('astable')) {
                isExist = true;
              }
            });

            if (!isExist) {
              self.controller.get('selectedFieldContents').addObject(newField);
            }
          } else {
            self.controller.get('selectedFieldContents').addObject(newField);
          }

          self.controller.set('availableFieldCollection', Ember.A());
        });
      },
      selectAllField: function selectAllField() {
        self.controller.set('availableFieldCollection', self.controller.get('availableFieldContents'));
      },
      uncheckAllField: function uncheckAllField() {
        self.controller.set('availableFieldCollection', Ember.A());
      },
      removeAllField: function removeAllField() {
        var self = this;
        this.controller.get('selectedFieldContents').forEach(function (field) {
          self.controller.get('selectedFieldCollection').addObject(field);
        });
        this.controller.get('selectedFieldCollection').forEach(function (field) {
          try {
            self.store.findRecord('dashboardreportconfigfield', field.id).then(function (container) {
              self.controller.get('selectedFieldContents').removeObject(field);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedFieldContents').removeObject(field);
          }
        });
        this.controller.set('selectedFieldCollection', Ember.A());
      },
      removeSelectedField: function removeSelectedField() {
        var self = this;
        this.controller.get('selectedFieldCollection').forEach(function (field) {
          try {
            self.store.findRecord('dashboardreportconfigfield', field.id).then(function (container) {
              self.controller.get('selectedFieldContents').removeObject(field);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('selectedFieldContents').removeObject(field);
          }
        });
        this.controller.set('selectedFieldCollection', Ember.A());
      },
      selectAllSelectedField: function selectAllSelectedField() {
        self.controller.set('selectedFieldCollection', self.controller.get('selectedFieldContents'));
      },
      uncheckAlSelectedlField: function uncheckAlSelectedlField() {
        self.controller.set('selectedFieldCollection', Ember.A());
      },
      addNewSelect: function addNewSelect() {
        var self = this;
        var newSelect = this.store.createRecord('dashboardreportconfigselect', {
          method: 'NA',
          dashboardreportnum: self.controller.get('currentReport')
        });
        self.controller.get('selectContents').addObject(newSelect);
      },
      deleteSelect: function deleteSelect(select) {
        var self = this;

        try {
          this.store.findRecord('dashboardreportconfigselect', select.id).then(function (container) {
            self.controller.get('selectContents').removeObject(select);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('selectContents').removeObject(select);
        }
      },
      addNewJoin: function addNewJoin() {
        var self = this;
        var newJoin = this.store.createRecord('dashboardreportconfigjoin', {
          type: 'LEFT_OUTER_JOIN',
          dashboardreportnum: self.controller.get('currentReport')
        });
        self.controller.get('joinContents').addObject(newJoin);
      },
      deleteJoin: function deleteJoin(join) {
        var self = this;

        try {
          this.store.findRecord('dashboardreportconfigjoin', join.id).then(function (container) {
            self.controller.get('joinContents').removeObject(join);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('joinContents').removeObject(join);
        }
      },
      addNewGroupby: function addNewGroupby() {
        var self = this;
        var newGroupby = this.store.createRecord('dashboardreportconfiggroupby', {
          function: 'NA',
          dashboardreportnum: self.controller.get('currentReport')
        });
        self.controller.get('groupbyContents').addObject(newGroupby);
      },
      deleteGroupby: function deleteGroupby(groupby) {
        var self = this;

        try {
          this.store.findRecord('dashboardreportconfiggroupby', groupby.id).then(function (container) {
            self.controller.get('groupbyContents').removeObject(groupby);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('groupbyContents').removeObject(groupby);
        }
      },
      addSummarizeValue: function addSummarizeValue() {
        var self = this;
        var newSummarize = this.store.createRecord('dashboardreportconfigsummarize', {
          function: self.controller.get('summarizeFunction'),
          columnname: self.controller.get('summarizeSelectedField'),
          dashboardreportnum: self.controller.get('currentReport')
        });
        self.controller.get('summarizeContents').addObject(newSummarize);
      },
      clearSummarizeValue: function clearSummarizeValue() {
        this.controller.set('summarizeFunction', 'total');
        this.controller.set('summarizeSelectedField', null);
      },
      removeAllSummarize: function removeAllSummarize() {
        var self = this;
        this.controller.get('summarizeContents').forEach(function (summarize) {
          try {
            self.store.findRecord('dashboardreportconfigsummarize', summarize.id).then(function (container) {
              self.controller.get('summarizeContents').removeObject(summarize);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('summarizeContents').removeObject(summarize);
          }
        });
      },
      removeSelectedSummarize: function removeSelectedSummarize() {
        var self = this;
        this.controller.get('summarizeCollection').forEach(function (summarize) {
          try {
            self.store.findRecord('dashboardreportconfigsummarize', summarize.id).then(function (container) {
              self.controller.get('summarizeContents').removeObject(summarize);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('summarizeContents').removeObject(summarize);
          }
        });
      },
      addNewFilter: function addNewFilter() {
        var self = this;
        var newFilter = this.store.createRecord('dashboardreportconfigfilter', {
          function: 'EQUAL',
          dashboardreportnum: self.controller.get('currentReport')
        });
        self.controller.get('filterContents').addObject(newFilter);
      },
      addNewSort: function addNewSort() {
        var self = this;
        var newSort = this.store.createRecord('dashboardreportconfigsort', {
          sort: 'ascending',
          dashboardreportnum: self.controller.get('currentReport')
        });
        self.controller.get('sortContents').addObject(newSort);
      },
      removeAllFilter: function removeAllFilter() {
        var self = this;
        this.controller.get('filterContents').forEach(function (filter) {
          try {
            self.store.findRecord('dashboardreportconfigfilter', filter.id).then(function (container) {
              self.controller.get('filterContents').removeObject(filter);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('filterContents').removeObject(filter);
          }
        });
      },
      removeAllSort: function removeAllSort() {
        var self = this;
        this.controller.get('sortContents').forEach(function (sort) {
          try {
            self.store.findRecord('dashboardreportconfigsort', sort.id).then(function (container) {
              self.controller.get('sortContents').removeObject(sort);
              container.set('isdelete', true);
              container.save();
            });
          } catch (e) {
            self.controller.get('sortContents').removeObject(sort);
          }
        });
      },
      removeFilter: function removeFilter(filter) {
        var self = this;

        try {
          this.store.findRecord('dashboardreportconfigfilter', filter.id).then(function (container) {
            self.controller.get('filterContents').removeObject(filter);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('filterContents').removeObject(filter);
        }
      },
      removeSort: function removeSort(sort) {
        var self = this;

        try {
          this.store.findRecord('dashboardreportconfigsort', sort.id).then(function (container) {
            self.controller.get('sortContents').removeObject(sort);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('sortContents').removeObject(sort);
        }
      },
      addParamcollection: function addParamcollection() {
        var self = this;
        var newParamcollection = this.store.createRecord('dashboardreportparamcollection', {
          collectionname: 'new param collection',
          dashboardreportnum: self.controller.get('model')
        });
        newParamcollection.save().then(function (paramcollection) {
          self.controller.get('paramcollectionContents').addObject(paramcollection);
          self.controller.set('selectedParamcollection', paramcollection);
          var paramQuery = self.store.query('dashboardreportparam', {
            where: {
              dashboardreportparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.controller.set('paramContents', paramContents.toArray());
          });
        });
      },
      deleteParamcollection: function deleteParamcollection(paramcollection) {
        var self = this;
        self.store.findRecord('dashboardreportparamcollection', paramcollection.id).then(function (container) {
          self.controller.get('paramcollectionContents').removeObject(paramcollection);
          self.controller.set('selectedParamcollection', null);
          container.set('isdelete', true);
          container.save();
        });
      },
      addParam: function addParam(paramcollection) {
        var self = this;
        var newParam = this.store.createRecord('dashboardreportparam', {
          paramtype: "filter",
          filtertype: "EQUAL",
          dashboardreportparamcollectionnum: paramcollection,
          dashboardreportnum: self.controller.get('model')
        });
        self.controller.get('paramContents').addObject(newParam);
      },
      deleteParam: function deleteParam(param) {
        var self = this;

        try {
          self.store.findRecord('dashboardreportparam', param.id).then(function (container) {
            self.controller.get('paramContents').removeObject(param);
            container.set('isdelete', true);
            container.save();
          });
        } catch (e) {
          self.controller.get('paramContents').removeObject(param);
        }
      },
      setParamcollection: function setParamcollection(paramcollection) {
        var self = this;

        if (!Ember.isEmpty(paramcollection)) {
          self.controller.set('paramLoading', true);
          var paramQuery = self.store.query('dashboardreportparam', {
            where: {
              dashboardreportparamcollectionnum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.controller.set('paramContents', paramContents.toArray());
            self.controller.set('selectedParamcollection', paramcollection);
            self.controller.set('paramLoading', false);
          });
        }
      },
      willTransition: function willTransition(transition) {
        //Component Tab Handler on willTransition event
        this.controller.set('currentRoute', transition.targetName);
        this.controller.set('componentsShown', false);
      }
    }
  });

  _exports.default = _default;
});