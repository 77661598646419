define("client/pods/components/dashboard-v2/table-sub/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "piKVtb/P",
    "block": "{\"symbols\":[\"table\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"collapse border-0 px-2 input-connection-name\"],[11,\"id\",[29,[\"collapseTables-\",[22,\"connectionid\"]]]],[8],[0,\"\\n    test\\n\"],[4,\"each\",[[24,[\"tables\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,1,[\"tablename\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dashboard-v2/table-sub/template.hbs"
    }
  });

  _exports.default = _default;
});