define("client/pods/d/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-dashboard/dashboard-player", "client/config/environment"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin, _dashboardPlayer, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _dashboardPlayer.default, {
    olmap2svc: Ember.inject.service('ol-mapservice'),
    environment: 'desktop',
    model: function model(params) {
      return this.store.queryRecord('dashboard', {
        dashboardId: params.dashboard_id,
        isdelete: false
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("mapTitle", model.get("mapTitle"));
      controller.set("kpiTitle", model.get("kpiTitle")); //Set variables for nav bar

      if (this.controllerFor('application').get('appID') !== model.dashboardId) {
        this.controllerFor('application').set('appID', model.dashboardId);
        this.controllerFor('application').set('menus', controller.get('menus'));
      }

      if (_environment.default.W3O_openlayers.useOpenLayer) {
        controller.set("useOpenLayer", true);
        var olmap2svc = this.get('olmap2svc');
        /* const map = olmap2svc.getOmap();
        const initialExtent = olmap2svc.getOinitialextent(map);    
        			const layers = olmap2svc.getOlayers(map);
        			controller.setProperties({
        map: map,
        initialExtent: initialExtent,
        layers: layers
        }); 
        
        console.log('hoichong d route map initialextent layers done...'); */
      } else {
        controller.set("useOpenLayer", false);
      }

      controller.set("showAddFilterD2", false);
    },
    actions: {
      willTransition: function willTransition() {
        this.willTransition();
      },
      closeNotification: function closeNotification(notification) {
        this.closeNotification(notification);
      },

      /*Map Action*/
      displayMap: function displayMap() {
        this.displayMap();
      },
      openPopup: function openPopup(marker) {
        this.openPopup(marker);
      },
      closePopup: function closePopup(marker) {
        this.closePopup(marker);
      },

      /*End of Map Action*/
      displayD2: function displayD2() {
        this.displayD2();
      },
      displayKpi: function displayKpi() {
        this.displayKpi();
      },
      displayReport: function displayReport() {
        this.displayReport();
      },
      displayNotification: function displayNotification() {
        this.displayNotification();
      },

      /*Notification Action*/
      changeNotification: function changeNotification(selection) {
        this.changeNotification(selection);
      },

      /*End of Notification Action*/
      back: function back() {
        this.back();
      },
      forward: function forward() {
        this.forward();
      },

      /*
      *   Function Handled from Components
      */
      executeDabChart: function executeDabChart(component) {
        this.executeDabChart(component);
      },
      dabChartUpdateChart: function dabChartUpdateChart(component, id) {
        this.dabChartUpdateChart(component, id);
      },
      dabChartSetParamCollection: function dabChartSetParamCollection(component, paramcollection) {
        this.dabChartSetParamCollection(component, paramcollection);
      },
      dabChartSaveParam: function dabChartSaveParam(component) {
        this.dabChartSaveParam(component);
      },
      executeDabShout: function executeDabShout(component) {
        this.executeDabShout(component);
      },
      dabShoutUpdateChart: function dabShoutUpdateChart(component, id) {
        this.dabShoutUpdateChart(component, id);
      },
      dabShoutSetParamCollection: function dabShoutSetParamCollection(component, paramcollection) {
        this.dabShoutSetParamCollection(component, paramcollection);
      },
      dabShoutSaveParam: function dabShoutSaveParam(component) {
        this.dabShoutSaveParam(component);
      },
      executeDabTimeChart: function executeDabTimeChart(component) {
        this.executeDabTimeChart(component);
      },
      dabTimeChartSetRange: function dabTimeChartSetRange(component, id, range) {
        this.dabTimeChartSetRange(component, id, range);
      },
      dabTimeChartGenerateChart: function dabTimeChartGenerateChart(component, id) {
        this.dabTimeChartGenerateChart(component, id);
      },
      dabTimeChartSetParamCollection: function dabTimeChartSetParamCollection(component, paramcollection) {
        this.dabTimeChartSetParamCollection(component, paramcollection);
      },
      dabTimeChartSaveParam: function dabTimeChartSaveParam(component) {
        this.dabTimeChartSaveParam(component);
      },
      executeDabTabularReport: function executeDabTabularReport(component) {
        this.executeDabTabularReport(component);
      },
      dabTabularReportSaveParam: function dabTabularReportSaveParam(component, filter_param) {
        this.dabTabularReportSaveParam(component, filter_param);
      },
      dabTabularReportSetParamCollection: function dabTabularReportSetParamCollection(component, paramcollection) {
        this.dabTabularReportSetParamCollection(component, paramcollection);
      },
      dabTabularReportMoveList: function dabTabularReportMoveList(component, direction) {
        this.dabTabularReportMoveList(component, direction);
      },
      dabTabularReportRefreshList: function dabTabularReportRefreshList(component) {
        this.dabTabularReportRefreshList(component);
      },
      configAutoRefresh: function configAutoRefresh() {
        this.configAutoRefresh();
      },
      filterKpiReport: function filterKpiReport() {
        this.filterKpiReport();
      },
      filterKpiReport2: function filterKpiReport2() {
        this.filterKpiReport2();
      }
    }
  });

  _exports.default = _default;
});