define("client/pods/components/w3office-forms/advanced-components/table-input/component", ["exports", "jquery", "client/mixins/w3office-forms/base-component"], function (_exports, _jquery, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    requiredObserver: Ember.observer('field.required', function () {
      this.get('field').save();
    }),
    didInsertElement: function didInsertElement() {
      var el = document.querySelector('.customScrollbar');
      Ps.initialize(el);
    },
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      var columnLength = 0;
      var rowLength = 0;
      var arrayTable = [];
      var arrayInput = [];
      this.fieldContents = [];
      this.tableContent = [];
      this.input = [];
      this.set('fieldContents', Ember.A());

      if (!Ember.isEmpty(this.get('field').get('id'))) {
        //Set table
        this.store.query('fieldgroupcontent', {
          where: {
            field: this.get('field').get('id')
          }
        }).then(function (contents) {
          var sortProperties = ["id"];
          self.set('fieldContents', Ember.ArrayProxy.extend(Ember.SortableMixin).create(contents).set('sortProperties', sortProperties));
          self.get('fieldContents').forEach(function (rowcontent) {
            if (!arrayTable[rowLength]) {
              arrayTable[rowLength] = [];
              arrayInput[rowLength] = [];
            }

            if (!rowcontent.get('parentCol.id')) {
              columnLength = 0;
              self.get('fieldContents').forEach(function (colcontent) {
                if (colcontent.get('parentCol.id') === rowcontent.id || colcontent.id === rowcontent.id) {
                  if (colcontent.get('addOther')) {
                    if (self.get('field').get('entryContainer') && self.get('field').get('entryContainer')[rowLength]) {
                      colcontent.set('value', self.get('field').get('entryContainer')[rowLength][columnLength]);
                    }

                    arrayTable[rowLength][columnLength] = colcontent;
                    arrayInput[rowLength][columnLength] = colcontent.get('value');
                  } else {
                    arrayTable[rowLength][columnLength] = colcontent;
                    arrayInput[rowLength][columnLength] = colcontent.get('name');
                  }

                  columnLength++;
                }
              });
              rowLength++;
            }
          });
          arrayTable.pop();
          arrayInput.pop();
          self.set('tableContent', arrayTable);
          self.set('input', arrayInput); //Run jQuery onChange

          Ember.run.scheduleOnce('afterRender', self, self.tableObserver);
        });
      }
    },
    entryContainerObserver: Ember.observer('field.entryContainer', function () {
      //uncheck all radio button on empty entrycontainer
      if (Ember.isEmpty(this.get('field').get('entryContainer'))) {
        this.set('input', []);
      }
    }),
    tableObserver: function tableObserver() {
      var self = this;
      this.get('tableContent').forEach(function (row, rowLength) {
        row.forEach(function (col, columnLength) {
          (0, _jquery.default)('input[type=text][id=' + col.id + ']').change(function () {
            self.get('input')[rowLength][columnLength] = this.value;
            self.get('field').set('entryContainer', self.get('input'));
          });
        });
      });
    },
    actions: {
      addTableRow: function addTableRow() {
        console.log(this.get('field'));
      }
    }
  });

  _exports.default = _default;
});