define("client/pods/dashboard-builder/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    currentRoute: '',
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      // this.store.query('formuser', 1).then(function(user){
      // 	self.set('formContainer.sessionHolder', user);
      // });
      this.set('currentRoute', transition.targetName);
    },
    // model: function(){
    // 	return this.store.query('form');
    // },
    setupController: function setupController(controller, model) {
      //Define controller properties
      //controller.set('model', model);
      //Set variables for nav bar
      if (this.controllerFor('application').get('appID') !== 'Dashboard') {
        this.controllerFor('application').set('appID', 'Dashboard');
        this.controllerFor('application').set('menus', [{
          'title': 'Dashboard Builder',
          'glyph': 'fa-copy',
          'unique': 'form',
          'subunique': 'subform',
          'theme': '#ffffff',
          'children': [{
            'title': 'Dashboard List',
            'link': 'dashboard-builder'
          }]
        }]);
      }

      controller.set('currentRoute', this.get('currentRoute'));
      controller.set('componentsShown', false);

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var session = Ember.Object.create({
            user: user
          });
          controller.set('session', session); //set session
        });
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        //Component Tab Handler on willTransition event
        this.controller.set('currentRoute', transition.targetName);
        this.controller.set('componentsShown', false);
      }
    }
  });

  _exports.default = _default;
});