define("client/models/dabkpiconfigjoin", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //attributes
    type: (0, _attr.default)("string"),
    joincategory: (0, _attr.default)("string"),
    jointable: (0, _attr.default)("string"),
    fromcategory: (0, _attr.default)("string"),
    fromtable: (0, _attr.default)("string"),
    fromid: (0, _attr.default)("string"),
    tocategory: (0, _attr.default)("string"),
    totable: (0, _attr.default)("string"),
    toid: (0, _attr.default)("string"),
    isdelete: (0, _attr.default)('boolean'),
    //relations
    dabkpiconfignum: (0, _relationships.belongsTo)("dabkpiconfig", {
      async: true
    })
  });

  _exports.default = _default;
});