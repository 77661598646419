define("client/pods/w3office-forms/external-api/route", ["exports", "axios", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _axios, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    model: function model() {
      return this.fetchAppIDs();
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        formList: null,
        isLoading: false,
        message: null,
        model: model,
        selectedApp: null
      });
    },
    fetchAppIDs: function fetchAppIDs() {
      var url = "".concat(this.store.adapterFor('application').get('host')).concat(this.store.adapterFor('application').get('namespace'), "/forms/getAvailableAppId");
      return (0, _axios.default)({
        method: "POST",
        url: url
      }).catch(function (err) {
        console.log("ERROR: Fetch Record Information of Uploaded File. Error: ".concat(err));
        return [];
      }).then(function (response) {
        return response.data.appIds;
      });
    },
    actions: {
      fetchForms: function fetchForms(appID) {
        var _this = this;

        this.controller.set("isLoading", true);
        this.store.query('form', {
          where: {
            appID: appID,
            useExternalAPI: true
          }
        }).then(function (resForms) {
          resForms.forEach(function (form) {
            var JSONExternalAPIConfig, externalAPIDomain;
            JSONExternalAPIConfig = JSON.parse(form.get('externalAPIConfig'));
            externalAPIDomain = JSONExternalAPIConfig.externalAPIDomain;
            form.set('externalAPIDomain', externalAPIDomain);
          });

          _this.controller.setProperties({
            formList: resForms,
            isLoading: false
          });
        });
      },
      updateExternalAPIDomain: function updateExternalAPIDomain(newDomain) {
        var model = this.get('controller').get('formList');
        model.forEach(function (form) {
          form.set('externalAPIDomain', newDomain);
        });
      },
      save: function save() {
        var _this2 = this;

        var controller = this.get('controller');
        var formList = controller.get('formList');
        controller.set("isLoading", true);
        formList.forEach(function (form) {
          var JSONExternalAPIConfig;
          JSONExternalAPIConfig = JSON.parse(form.get('externalAPIConfig'));
          JSONExternalAPIConfig.externalAPIDomain = form.get('externalAPIDomain');
          form.set('externalAPIConfig', JSON.stringify(JSONExternalAPIConfig));
        });
        formList.save().then(function (success) {
          controller.setProperties({
            isLoading: false,
            message: 'Saved Successfully',
            newDomain: null,
            succeed: true
          });
          Ember.run.later(_this2, function () {
            controller.set('message', null);
          }, 3000);
        }).catch(function (err) {
          controller.setProperties({
            message: 'Failed to save',
            succeed: false
          });
          Ember.run.later(_this2, function () {
            controller.set('message', null);
          }, 3000);
        });
      }
    }
  });

  _exports.default = _default;
});