define("client/helpers/convert-operator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertOperator = convertOperator;
  _exports.default = void 0;

  function convertOperator(params
  /*, hash*/
  ) {
    var stringOperator = params[0];

    if (params[0] === ">") {
      stringOperator = "Greater than";
    } else if (params[0] === ">=") {
      stringOperator = "Greater than or Equal to";
    } else if (params[0] === "<") {
      stringOperator = "Less than";
    } else if (params[0] === "<=") {
      stringOperator = "Less than or Equal to";
    } else if (params[0] === "=") {
      stringOperator = "Equal to";
    } else if (params[0] === "><") {
      stringOperator = "Between";
    } else {
      stringOperator = params[0];
    }

    return stringOperator;
  }

  var _default = Ember.Helper.helper(convertOperator);

  _exports.default = _default;
});