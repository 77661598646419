define("client/pods/components/w3o-users-roles/form-user/address1/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eMh3u4YW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"loadingPostalcode\",\"address1\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"skeleton\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"address1IsEmpty\"],[8],[0,\"Address cannot be empty\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"address1Form\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphAddress1\"],[8],[9],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"value\"],[\"address1\",\"Enter Address\",\"form-control\",[24,[\"model\",\"address1\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/address1/template.hbs"
    }
  });

  _exports.default = _default;
});