define("client/mixins/w3oprocess/process-manager-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    mobile: false,

    /*
    	Inject session service as authObject
    */
    authObject: Ember.inject.service('session'),

    /*
      Initialize controller variables and headers for table
    */
    setupController: function setupController() {
      var userId = this.get('authObject.data.user.id');
      var controller = this.get('controller');
      controller.set('hasModel', false);
      controller.set('loading', true);
      controller.set('processList', Ember.A());
      controller.set('listPage', 1);
      controller.set('totalPage', 1);

      if (this.get('mobile')) {
        controller.set('limit', 5);
      } else {
        controller.set('limit', 10);
      }

      controller.set('sortBy', 'processTaskDueDate');
      controller.set('sortOrder', 'DESC');
      this.store.findRecord('user', userId).then(function (user) {
        controller.set('currentUser', user);
      });
      controller.set('searchInput', '');
      controller.set('searchColumn', 'Task');
      controller.set('entryHeaders', Ember.A());
      controller.get('entryHeaders').addObject({
        id: 'processtask.id',
        label: 'Task ID',
        value: 'processTaskId'
      });
      controller.get('entryHeaders').addObject({
        id: 'processtask._internalModel._relationships.initializedRelationships.processinstance.canonicalState._relationships.initializedRelationships.processversion.canonicalState._relationships.initializedRelationships.process.canonicalState._data.title',
        label: 'Process',
        value: 'processTitle'
      });
      controller.get('entryHeaders').addObject({
        id: 'processtask._internalModel._data.title',
        label: 'Task',
        value: 'processTaskTitle'
      });
      controller.get('entryHeaders').addObject({
        id: 'processtask._internalModel._data.description',
        label: 'Description'
      });
      controller.get('entryHeaders').addObject({
        id: 'priorityType',
        label: 'Priority',
        value: 'processTaskPriority'
      });
      controller.get('entryHeaders').addObject({
        id: 'processtask._internalModel._data.dueDate',
        label: 'Due Date',
        value: 'processTaskDueDate'
      });
      controller.get('entryHeaders').addObject({
        id: 'Action',
        label: 'Action'
      });
      controller.set('entryTypeContainer', [{
        label: 'Task',
        value: 'Task'
      }, {
        label: 'Description',
        value: 'Description'
      }]);
      controller.set('sortOrderContainer', [{
        label: 'Ascending',
        value: 'ASC'
      }, {
        label: 'Descending',
        value: 'DESC'
      }]);
      this.getTasks();
    },

    /*
      Get userprocesstask lists
    */
    getTasks: function getTasks() {
      var userId = this.get('authObject.data.user.id');
      var self = this;
      var controller = this.get('controller');

      var _column;

      if (controller.get('searchColumn') === 'Task') {
        _column = 'title';
      } else if (controller.get('searchColumn') === 'Description') {
        _column = 'description';
      }

      _jquery.default.ajax({
        type: "POST",
        data: {
          user: userId,
          page: controller.get('listPage'),
          limit: controller.get('limit'),
          sortBy: controller.get('sortBy'),
          sortOrder: controller.get('sortOrder'),
          type: self.get('environment'),
          searchInput: controller.get('searchInput'),
          searchColumn: _column
        },
        url: this.store.adapterFor('application').get('namespace') + '/userprocesstasks/getTasks',
        success: function success(response) {
          var temp = Ember.A();
          var Variable = Ember.Object.extend({
            id: null,
            user: null,
            processtask: null,
            inbox: null,
            mytask: null,
            queued: null,
            involved: null,
            archived: null,
            role: null
          });

          if (!Ember.isEmpty(response.task)) {
            response.task.forEach(function (process) {
              self.store.findRecord('processtask', process.processtask.id).then(function (processtask) {
                if (self.get('mobile')) {
                  controller.get('processList').pushObject(Variable.create({
                    id: process.id,
                    user: process.user,
                    processtask: processtask,
                    inbox: process.inbox,
                    mytask: process.mytask,
                    queued: process.queued,
                    involved: process.involved,
                    archived: process.archived,
                    role: process.role
                  }));
                } else {
                  temp[process.arrayOrder] = Variable.create({
                    id: process.id,
                    user: process.user,
                    processtask: processtask,
                    inbox: process.inbox,
                    mytask: process.mytask,
                    queued: process.queued,
                    involved: process.involved,
                    archived: process.archived,
                    role: process.role
                  });
                  controller.set('processList', temp);
                }

                controller.set('loading', false);
              });
            });
          }

          if (response.exceedTotal) {
            controller.set('listPage', 1);
          }

          controller.set('totalPage', response.totalPage);
          console.log('MIXIN TEST');
          console.log(response);

          if (response.task.length) {
            controller.set('hasModel', true);
          } else {
            controller.set('hasModel', false);
            controller.set('loading', false);
          }
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },

    /*
      Show more records
    */
    loadMore: function loadMore() {
      var controller = this.get('controller');
      controller.set('listPage', controller.get('listPage') + 1);
      controller.set("loading", true);
      this.getTasks();
    },

    /*
      Apply sorting and reset records
    */
    sortRecords: function sortRecords() {
      var controller = this.get('controller');
      controller.set('processList', Ember.A());
      controller.set("loading", true);
      this.getTasks();
    },
    doSort: function doSort(param) {
      var controller = this.get('controller');

      if (controller.get('sortBy') !== param) {
        controller.set('sortBy', param);
        controller.set('sortOrder', 'ASC');
      } else {
        if (controller.get('sortOrder') === 'DESC') {
          controller.set('sortOrder', 'ASC');
        } else {
          controller.set('sortOrder', 'DESC');
        }
      }

      controller.set("loading", true);
      this.getTasks();
    },

    /*
      Search records
    */
    doSearch: function doSearch() {
      if (!this.get('mobile')) {
        var controller = this.get('controller');
        controller.set('processList', Ember.A());
      }

      controller.set("loading", true);
      this.getTasks();
    },

    /*
      Remove search filter
    */
    resetFilter: function resetFilter() {
      var controller = this.get('controller');
      controller.set('searchColumn', 'Task');
      controller.set('searchInput', null);
      controller.set('processList', Ember.A());
      controller.set("loading", true);
      this.getTasks();
    },
    changeColumn: function changeColumn(param) {
      var controller = this.get('controller');
      controller.set("loading", true);
      controller.set('searchColumn', param);
    },
    changeLimit: function changeLimit(selectedLimit) {
      this.controller.set("loading", true);
      this.controller.set("limit", selectedLimit);
      this.getTasks();
    },
    prevList: function prevList() {
      var controller = this.get('controller');
      controller.set("loading", true);
      controller.set('listPage', controller.get('listPage') - 1);
      this.getTasks();
    },
    nextList: function nextList() {
      var controller = this.get('controller');
      controller.set("loading", true);
      controller.set('listPage', controller.get('listPage') + 1);
      this.getTasks();
    },

    /*
      Claim selected task
    */
    claimTask: function claimTask(userTask) {
      var self = this;
      var userId = this.get('authObject.data.user.id');

      _jquery.default.ajax({
        type: "POST",
        data: {
          id: userTask.id,
          user: userId,
          processtask: parseInt(userTask.processtask.id)
        },
        url: this.store.adapterFor('application').get('namespace') + '/userprocesstasks/claimTask',
        success: function success(response) {
          self.transitionTo('process-manager.inbox.index');
        },
        error: function error(xhr, status, _error2) {
          console.log('Error ' + _error2);
        }
      });
    },

    /*
      Continue selected process
    */
    continueProcess: function continueProcess(userTask) {
      var self = this;
      var controller = this.get('controller');
      var url = self.store.adapterFor('application').get('namespace') + '/processactivities/continueProcess';
      var accessToken = this.get('authObject.data.authenticated.access_token');

      _jquery.default.ajax({
        type: "POST",
        url: url,
        data: {
          taskID: userTask.get('processtask.id'),
          userID: controller.get('currentUser.id'),
          accessToken: accessToken,
          url: self.store.adapterFor('application').get('mainHost')
        },
        success: function success(res) {
          console.log(res);
          self.transitionTo('process-manager.archived');
        }
      });
    },

    /*
      Stop Selected Process
    */
    stopProcess: function stopProcess(userTask, memoText) {
      var self = this;
      var controller = this.get('controller');
      var systemMemo = '';
      var url = self.store.adapterFor('application').get('namespace') + '/processactivities/endProcess';

      _jquery.default.ajax({
        type: "POST",
        url: url,
        data: {
          processId: userTask.get('processtask.processinstance.processversion.process.id'),
          instanceId: userTask.get('processtask.processinstance.id')
        },
        success: function success(res) {
          console.log(res);
          systemMemo += 'Process stopped by ';
          systemMemo += controller.get('currentUser.firstName') + ' ' + controller.get('currentUser.lastName');
          var memo = self.store.createRecord('processmemo', {
            title: 'Stop Process',
            type: 'danger',
            processType: 'processinstance',
            memo: memoText,
            systemMemo: systemMemo,
            user: controller.get('currentUser'),
            processinstance: userTask.get('processtask.processinstance')
          });
          memo.save().then(function () {
            self.transitionTo('process-manager.archived');
          });
        }
      });
    },

    /*
      Go to task detail
    */
    gotoDetail: function gotoDetail(id) {
      this.transitionTo('process-manager.' + this.get('environment') + '.detail', id);
    },

    /*
      Delete selected task
    */
    deleteTask: function deleteTask(id) {
      var self = this;
      var controller = this.get('controller');
      this.store.findRecord('userprocesstask', id).then(function (userTask) {
        userTask.set('archived', false);
        userTask.save().then(function (userTask) {
          if (!this.get('mobile')) {
            (0, _jquery.default)('#delete-task-modal').modal('toggle');
          }

          controller.set('processList', Ember.A());
          controller.set('loading', true);
          controller.set('hasModel', false);
          self.getTasks();
        });
      });
    },

    /*
      Delete all tasks
    */
    deleteAllTask: function deleteAllTask() {
      var controller = this.get('controller');

      _jquery.default.ajax({
        type: "POST",
        data: {
          userId: this.get('authObject.data.user.id')
        },
        url: this.store.adapterFor('application').get('namespace') + '/userprocesstasks/deleteAllTasks',
        success: function success(response) {
          if (!this.get('mobile')) {
            (0, _jquery.default)('#delete-all-task-modal').modal('toggle');
          }

          controller.set('hasModel', false);
        },
        error: function error(xhr, status, _error3) {
          console.log('Error ' + _error3);
        }
      });
    }
  });

  _exports.default = _default;
});