define("client/models/processgateway", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    gatewayId: (0, _attr.default)('string'),
    label: (0, _attr.default)('string'),
    gatewayIndex: (0, _attr.default)('number'),
    acceptSequence: (0, _attr.default)('string'),
    rejectSequence: (0, _attr.default)('string'),
    acceptLabel: (0, _attr.default)('string'),
    rejectLabel: (0, _attr.default)('string'),
    columnName: (0, _attr.default)('string'),
    expectedValue: (0, _attr.default)('string'),
    height: (0, _attr.default)('number'),
    width: (0, _attr.default)('number'),
    posX: (0, _attr.default)('number'),
    posY: (0, _attr.default)('number'),
    processversion: (0, _relationships.belongsTo)('processversion', {
      async: true
    }),
    numericID: Ember.computed('id', function () {
      var id = this.get('id');

      if (id) {
        return +id;
      }
    })
  });

  _exports.default = _default;
});