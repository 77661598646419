define("client/pods/components/report-generator/summarize-tab/summarize-fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterModel: null,
    filterHeader: 'function',
    actions: {
      removeAllSummarize: function removeAllSummarize() {
        this.removeAllSummarize();
      },
      removeSelectedSummarize: function removeSelectedSummarize() {
        this.removeSelectedSummarize();
      },
      filterData: function filterData() {
        var self = this;
        var tempModel = Ember.A();
        this.get('models').forEach(function (model) {
          if (model.get(self.get('filterHeader')).includes(self.get('filterValue'))) {
            tempModel.push(model);
          }
        });
        this.set('filterModel', tempModel);
      }
    }
  });

  _exports.default = _default;
});