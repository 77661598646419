define("client/models/dashboardmap", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    modelName: (0, _attr.default)('string'),
    associationString: (0, _attr.default)('string'),
    latitudeColumn: (0, _attr.default)('string'),
    longitudeColumn: (0, _attr.default)('string'),
    markerColor: (0, _attr.default)('string'),
    markerSymbol: (0, _attr.default)('string'),
    isdelete: (0, _attr.default)('boolean'),
    dashboard: (0, _relationships.belongsTo)('dashboard', {
      async: true
    }),
    dashboardFilters: (0, _relationships.hasMany)('dashboardfilter', {
      async: true
    }),
    dashboardMapColumns: (0, _relationships.hasMany)('dashboardmapcolumn', {
      async: true
    })
  });

  _exports.default = _default;
});