define("client/templates/components/ol-layer-tree-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/uVAsVib",
    "block": "{\"symbols\":[\"layer\"],\"statements\":[[4,\"if\",[[24,[\"isInitLayerGroup\"]]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h5\",true],[10,\"class\",\"m-3 mx-4 mt-4\"],[8],[0,\"LAYERS\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"each\",[[24,[\"layers\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ol-layer-tree-item\",null,[[\"layer\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/ol-layer-tree-group.hbs"
    }
  });

  _exports.default = _default;
});