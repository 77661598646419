define("client/pods/process-manager/route", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    setupController: function setupController(controller) {
      var _this = this;

      //Set variables for nav bar
      if (this.controllerFor('application').get('appID') !== 'Process') {
        this.controllerFor('application').set('appID', 'Process');
        this.controllerFor('application').set('menus', []);
        this.set('defaultMenus', [{
          'title': 'Approval Manager',
          'glyph': 'fa-list-alt',
          'theme': '#e64759',
          'color': '#e64759',
          'borderColor': '#e64759',
          'unique': 'management',
          'subunique': 'submanagement',
          'defaultSubMenus': [{
            'title': 'Inbox',
            'link': 'process-manager.inbox.index',
            'badge': true,
            'badgeCount': 0
          }, {
            'title': 'My Task',
            'link': 'process-manager.my-task.index',
            'badge': true,
            'badgeCount': 0
          }, {
            'title': 'Queued',
            'link': 'process-manager.queued.index',
            'badge': true,
            'badgeCount': 0
          }, {
            'title': 'Involved',
            'link': 'process-manager.involved.index',
            'badge': true,
            'badgeCount': 0
          }, {
            'title': 'Archived',
            'link': 'process-manager.archived.index',
            'badge': true,
            'badgeCount': 0
          }],
          'children': []
        }]);
        var menuList = "('process-manager.archived.index', 'process-manager.inbox.index', 'process-manager.involved.index', 'process-manager.my-task.index', 'process-manager.queued.index', 'process-builder.index', 'process-builder.create')";

        _jquery.default.ajax({
          url: '/api/v1/allowedroutes/checkMenu',
          type: 'POST',
          data: {
            userId: this.get('authObject.data.user.id'),
            menuList: menuList
          }
        }).then(function (response) {
          _this.set('shownMenu', response.menus);

          if (_this.get('shownMenu')) {
            _this.get('defaultMenus').forEach(function (header, index) {
              if (header.defaultSubMenus) {
                header.defaultSubMenus.forEach(function (defaultSubMenus) {
                  if (defaultSubMenus.link && _this.get('shownMenu').indexOf(defaultSubMenus.link) > -1) {
                    _this.set('childMenu', Ember.Object.create(defaultSubMenus));

                    header.children.pushObject(_this.get('childMenu'));
                  }
                });
              }

              if (header.title === 'Process Builder' && (_this.get('shownMenu').indexOf('process-builder.index') > -1 || _this.get('shownMenu').indexOf('process-builder.create') > -1)) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'Approval Manager' && (_this.get('shownMenu').indexOf('process-manager.inbox.index') > -1 || _this.get('shownMenu').indexOf('process-manager.my-task.index') > -1 || _this.get('shownMenu').indexOf('process-manager.queued.index') > -1 || _this.get('shownMenu').indexOf('process-manager.involved.index') > -1 || _this.get('shownMenu').indexOf('process-manager.archived.index') > -1)) {
                (0, _jquery.default)('#dmanagement').addClass('in');

                _this.controllerFor('application').get('menus').pushObject(header);

                _this.calculateBadgeCount();
              }
            });
          }
        });
      }

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var session = Ember.Object.create({
            user: user
          });
          controller.set('session', session); //set session
        });
      }
    },
    calculateBadgeCount: function calculateBadgeCount() {
      var _this2 = this;

      var inboxCount = 0;
      var taskCount = 0;
      var queuedCount = 0;
      var involvedCount = 0;
      var archivedCount = 0;
      this.store.query('userprocesstask', {
        user: this.get('authObject.data.user.id')
      }).then(function (userprocesstasks) {
        userprocesstasks.forEach(function (userprocesstask) {
          console.log(userprocesstask.get('archived'));

          if (userprocesstask.get('inbox')) {
            inboxCount++;
          }

          if (userprocesstask.get('mytask')) {
            taskCount++;
          }

          if (userprocesstask.get('queued')) {
            queuedCount++;
          }

          if (userprocesstask.get('involved')) {
            involvedCount++;
          }

          if (userprocesstask.get('archived')) {
            archivedCount++;
          }
        });

        var approvalManagerChildren = _this2.controllerFor('application').get('menus').find(function (menu) {
          return menu.title == 'Approval Manager';
        })['children'];

        approvalManagerChildren.forEach(function (childMenu) {
          if (childMenu.title == 'Inbox') {
            childMenu.set('badgeCount', inboxCount);
          } else if (childMenu.title == 'Queued') {
            childMenu.set('badgeCount', queuedCount);
          } else if (childMenu.title == 'Involved') {
            childMenu.set('badgeCount', involvedCount);
          } else if (childMenu.title == 'Archived') {
            childMenu.set('badgeCount', archivedCount);
          }
        });
      });
    },
    actions: {}
  });

  _exports.default = _default;
});