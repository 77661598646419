define("client/pods/components/w3oprocess/process-paging/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      doSort: function doSort(param) {
        this.doSort(param);
      },
      changeLimit: function changeLimit(limit) {
        this.set('limit', limit);
        this.changeLimit();
      },
      prevList: function prevList() {
        this.prevList();
      },
      nextList: function nextList() {
        this.nextList();
      },
      gotoInstances: function gotoInstances(id) {
        this.gotoInstances(id);
      }
    }
  });

  _exports.default = _default;
});