define("client/pods/process-builder/route", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    setupController: function setupController(controller) {
      var _this = this;

      //Set variables for nav bar
      if (this.controllerFor('application').get('appID') !== 'Process') {
        this.controllerFor('application').set('appID', 'Process');
        this.controllerFor('application').set('menus', []);
        this.set('defaultMenus', [{
          'title': 'Process Builder',
          'glyph': 'fa-calculator',
          'theme': '#f39c12',
          'color': '#f39c12',
          'borderColor': '#f39c12',
          'unique': 'builder',
          'subunique': 'subbuilder',
          'defaultSubMenus': [{
            'title': 'Process List',
            'link': 'process-builder.index'
          }, {
            'title': 'Create Process',
            'link': 'process-builder.create'
          }],
          'children': []
        }]);
        var menuList = "('process-manager.archived.index', 'process-manager.inbox.index', 'process-manager.involved.index', 'process-manager.my-task.index', 'process-manager.queued.index', 'process-builder.index', 'process-builder.create')";

        _jquery.default.ajax({
          url: '/api/v1/allowedroutes/checkMenu',
          type: 'POST',
          data: {
            userId: this.get('authObject.data.user.id'),
            menuList: menuList
          }
        }).then(function (response) {
          _this.set('shownMenu', response.menus);

          if (_this.get('shownMenu')) {
            _this.get('defaultMenus').forEach(function (header, index) {
              if (header.defaultSubMenus) {
                header.defaultSubMenus.forEach(function (defaultSubMenus) {
                  if (defaultSubMenus.link && _this.get('shownMenu').indexOf(defaultSubMenus.link) > -1) {
                    header.children.pushObject(defaultSubMenus);
                  }
                });
              }

              if (header.title === 'Process Builder' && (_this.get('shownMenu').indexOf('process-builder.index') > -1 || _this.get('shownMenu').indexOf('process-builder.create') > -1)) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'Approval Manager' && (_this.get('shownMenu').indexOf('process-manager.inbox.index') > -1 || _this.get('shownMenu').indexOf('process-manager.my-task.index') > -1 || _this.get('shownMenu').indexOf('process-manager.queued.index') > -1 || _this.get('shownMenu').indexOf('process-manager.involved.index') > -1 || _this.get('shownMenu').indexOf('process-manager.archived.index') > -1)) {
                (0, _jquery.default)('#dmanagement').addClass('in');

                _this.controllerFor('application').get('menus').pushObject(header);
              }
            });
          }
        });
      }

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var session = Ember.Object.create({
            user: user
          });
          controller.set('session', session); //set session
        });
      }
    },
    actions: {}
  });

  _exports.default = _default;
});