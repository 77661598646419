define("client/pods/components/w3office-forms/droppable-row/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['draggableDropzone'],
    classNameBindings: ['dragClass'],
    dragClass: 'deactivated',
    _droppableRowId: Ember.computed('elementId', function () {
      return "droppableRow-".concat(this.get('elementId'));
    }),
    _droppableRowMobileId: Ember.computed('elementId', function () {
      return "droppableRowMobile-".concat(this.get('elementId'));
    }),
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('height', '20px');
      (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('border-style', 'none');
      (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('margin-top', '0px');
      (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('margin-bottom', '0px');
      this.set('dragClass', 'deactivated');
    },
    dragOver: function dragOver(event) {
      if (!this.get('isDropping')) {
        event.preventDefault();
        (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('height', '60px');
        (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('border-style', 'dotted');
        (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('border-color', '#5e5e5c');
        (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('margin-top', '5px');
        (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('margin-bottom', '5px');
        this.set('dragClass', 'activated');
      }
    },
    drop: function drop(event) {
      if (!this.get('isDropping')) {
        this.putFieldOnRow(this.get('currentField'), this.get('lastRow'));
        (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('height', '20px');
        (0, _jquery.default)("#".concat(this.get('_droppableRowId'))).css('border-style', 'none');
        this.set('dragClass', 'deactivated');
      }
    } //,
    // actions: {
    // 	pushComponent: function(){
    // 		var self = this;
    // 		var movedField; // moved Field Data      
    // 		var currentField = this.get('currentField'); // dropzone Field Data
    // 		var latestRow = this.get('latestRow'); //Get the value of latest row
    // 		var rowHolder;
    // 		var columnHolder;
    // 		var lowerRowHolder; //Hold lower limit of the ordering
    // 		var upperRowHolder; //Hold upper limit of the ordering
    // 		var isNew = false; //Determine is dropped component already persisted on Database or not
    // 		var newField;
    // 		var customLabel = '';
    // 		var data;
    // 		if(!Ember.isEmpty(this.get('selectedComponent')))
    // 		{
    // 			data = this.get('selectedComponent');
    // 		}
    // 		else if(!Ember.isEmpty(this.get('formContainer.fieldHolder')))
    // 		{
    // 			data = this.get('formContainer.fieldHolder');
    // 		}
    // 		if(!Ember.isEmpty(this.get('formContainer.fieldHolder')) || !Ember.isEmpty(this.get('selectedComponent')))
    // 		{
    // 			$(`#${this.get('_droppableRowId')}`).css('height', '20px');
    // 			$(`#${this.get('_droppableRowId')}`).css('border-style', 'none');
    // 			$('.drop-col-xs').css('height', '20px');        
    // 			$('.drop-col-xs').css('border-style', 'none');
    // 			$('.orderDrop').css('height', '20px');        
    // 			$('.orderDrop').css('border-style', 'none');
    // 			this.sendAction('resetSelection');
    // 			if(data.get('isForm'))
    // 			{
    // 				var fieldPromises = Ember.A();
    // 				var fieldRowContainer;
    // 				var totalRow;
    // 				if(!Ember.isEmpty(this.get('latestRow')) && this.get('lastRow'))
    // 				{
    // 					data.get('form').get('fields').forEach(function(formfield){
    // 						if(formfield.get('formcomponent').get('path').indexOf('collection-components') !== -1)
    // 						{
    // 							if(formfield.get('formcomponent').get('path').indexOf('full-name') !== -1)
    // 							{
    // 								customLabel = 'Full Name';
    // 							}
    // 							else if(formfield.get('formcomponent').get('path').indexOf('full-address') !== -1)
    // 							{
    // 								customLabel = 'Full Address';
    // 							}
    // 						}
    // 						totalRow = totalRow < formfield.get('fieldRow') ? formfield.get('fieldRow') : totalRow;
    // 						fieldRowContainer = self.get('latestRow') + formfield.get('fieldRow');
    // 						//Create new Component on form
    // 						newField = self.store.createRecord('field', {
    // 							label: formfield.get('label'),
    // 							description: formfield.get('description'),
    // 							fieldFormat: formfield.get('fieldFormat'),
    // 							columnName: formfield.get('columnName'),
    // 							secondaryColumnName: formfield.get('secondaryColumnName'),
    // 							defaultValue: formfield.get('defaultValue'),
    // 							minValue: formfield.get('minValue'),
    // 							maxValue: formfield.get('maxValue'),
    // 							valueStep: formfield.get('valueStep'),
    // 							placeholder: formfield.get('placeholder'),
    // 							fieldRow: fieldRowContainer,
    // 							fieldColumn: formfield.get('fieldColumn'),
    // 							fieldLength: formfield.get('fieldLength'),
    // 							required: formfield.get('required'),
    // 							validationType: formfield.get('validationType'),
    // 							validationOperator: formfield.get('validationOperator'),
    // 							validationValue: formfield.get('validationValue'),
    // 							fromAPI: formfield.get('fromAPI'),
    // 							modelName: formfield.get('modelName'),
    // 							valueColumn: formfield.get('valueColumn'),
    // 							labelColumn: formfield.get('labelColumn'),                    
    // 							form: self.get('form'),
    // 							formcomponent: formfield.get('formcomponent')
    // 						});
    // 						fieldPromises.addObject(newField.save());
    // 					});
    // 					//self.set('latestRow', self.get('latestRow') + totalRow + 1);
    // 					Ember.RSVP.Promise.all(fieldPromises).then(function(){
    // 						console.log('saved');
    // 						self.sendAction('sortGrid');
    // 					});
    // 				}
    // 				else if(!Ember.isEmpty('currentField'))
    // 				{
    // 					lowerRowHolder = currentField.get('fieldRow');
    // 					self.store.query('field', {where: {form: currentField.get('form').get('id'), fieldRow: {'>=': lowerRowHolder}}}).then(function(fields){
    // 						console.log('2');
    // 						fields.forEach(function(field){
    // 							field.set('fieldRow', field.get('fieldRow') + data.get('form').get('fields').sortBy('fieldRow').get('lastObject').get('fieldRow') + 1);
    // 						});
    // 						fields.save().then(function(){
    // 							data.get('form').get('fields').forEach(function(formfield){
    // 								if(formfield.get('formcomponent').get('path').indexOf('collection-components') !== -1)
    // 								{
    // 									if(formfield.get('formcomponent').get('path').indexOf('full-name') !== -1)
    // 									{
    // 										customLabel = 'Full Name';
    // 									}
    // 									else if(formfield.get('formcomponent').get('path').indexOf('full-address') !== -1)
    // 									{
    // 										customLabel = 'Full Address';
    // 									}
    // 								}
    // 								totalRow = totalRow < formfield.get('fieldRow') ? formfield.get('fieldRow') : totalRow;
    // 								fieldRowContainer = lowerRowHolder + formfield.get('fieldRow');
    // 								//Create new Component on form
    // 								newField = self.store.createRecord('field', {
    // 									label: formfield.get('label'),
    // 									description: formfield.get('description'),
    // 									fieldFormat: formfield.get('fieldFormat'),
    // 									columnName: formfield.get('columnName'),
    // 									secondaryColumnName: formfield.get('secondaryColumnName'),
    // 									defaultValue: formfield.get('defaultValue'),
    // 									minValue: formfield.get('minValue'),
    // 									maxValue: formfield.get('maxValue'),
    // 									valueStep: formfield.get('valueStep'),
    // 									placeholder: formfield.get('placeholder'),
    // 									fieldRow: fieldRowContainer,
    // 									fieldColumn: formfield.get('fieldColumn'),
    // 									fieldLength: formfield.get('fieldLength'),
    // 									required: formfield.get('required'),
    // 									validationType: formfield.get('validationType'),
    // 									validationOperator: formfield.get('validationOperator'),
    // 									validationValue: formfield.get('validationValue'),
    // 									fromAPI: formfield.get('fromAPI'),
    // 									modelName: formfield.get('modelName'),
    // 									valueColumn: formfield.get('valueColumn'),
    // 									labelColumn: formfield.get('labelColumn'),                    
    // 									form: self.get('form'),
    // 									formcomponent: formfield.get('formcomponent')
    // 								});
    // 								fieldPromises.addObject(newField.save());
    // 							});
    // 							//self.set('latestRow', self.get('latestRow') + totalRow + 1);
    // 							Ember.RSVP.Promise.all(fieldPromises).then(function(){
    // 								console.log('saved');
    // 								self.sendAction('sortGrid');
    // 							});
    // 						});
    // 					});
    // 				}                
    // 			}
    // 			else
    // 			{
    // 				if(!Ember.isEmpty(this.get('formContainer.fieldHolder')))
    // 				{
    // 					movedField = this.get('formContainer.fieldHolder');
    // 				}
    // 				else
    // 				{
    // 					if(data.get('path').indexOf('collection-components') !== -1)
    // 					{
    // 						if(data.get('path').indexOf('full-name') !== -1)
    // 						{
    // 							customLabel = 'Full Name';
    // 						}
    // 						else if(data.get('path').indexOf('full-address') !== -1)
    // 						{
    // 							customLabel = 'Full Address';
    // 						}
    // 					}
    // 					//Create new Component on form
    // 					newField = this.store.createRecord('field', {
    // 						formcomponent: data,
    // 						label: customLabel,
    // 						form: this.get('form'),
    // 						fieldRow: this.get('latestRow'),
    // 						fieldColumn: 0,
    // 						fieldLength: 1
    // 					});  
    // 					//Add component to be displayed on template
    // 					this.get('componentArray').addObject(newField._internalModel);
    // 					movedField = newField;
    // 					//Increment Latest Row
    // 					this.set('latestRow', this.get('latestRow')+1);
    // 					latestRow = this.get('latestRow');
    // 					isNew = true;
    // 				}
    // 				if(isNew)
    // 				{
    // 					//Set as latest component
    // 					if(!Ember.isEmpty('latestRow') && this.get('lastRow'))
    // 					{
    // 						movedField.save().then(function(){
    // 							self.sendAction('sortGrid');
    // 						});
    // 					}
    // 					//Set component based on droppable-ordering field
    // 					else if(!Ember.isEmpty(currentField) && movedField.get('fieldRow') > currentField.get('fieldRow'))
    // 					{
    // 						lowerRowHolder = currentField.get('fieldRow');
    // 						upperRowHolder = movedField.get('fieldRow');
    // 						movedField.set('fieldRow', currentField.get('fieldRow'));
    // 						movedField.save().then(function(){
    // 							self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: currentField.get('form').get('id'), fieldRow: {'>=': lowerRowHolder, '<=': upperRowHolder}}}).then(function(fields){
    // 								fields.forEach(function(field){
    // 									field.set('fieldRow', field.get('fieldRow')+1);
    // 								});
    // 								fields.save().then(function(){
    // 									self.sendAction('sortGrid');
    // 								});
    // 							});
    // 						});
    // 					}
    // 				}
    // 				else
    // 				{
    // 					if(parseInt(movedField.get('fieldLength')) === 1)
    // 					{
    // 						//Move order to the latest
    // 						if(!Ember.isEmpty('latestRow') && movedField.get('fieldRow')<latestRow-1 && this.get('lastRow'))
    // 						{
    // 							lowerRowHolder = movedField.get('fieldRow')+1;
    // 							movedField.set('fieldRow', latestRow-1);
    // 							movedField.save().then(function(){
    // 								self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: movedField.get('form').get('id'), fieldRow: {'<=': latestRow-1, '>=':lowerRowHolder}}}).then(function(fields){
    // 									fields.forEach(function(field){
    // 										field.set('fieldRow', field.get('fieldRow')-1);
    // 									});
    // 									fields.save().then(function(){
    // 										self.sendAction('sortGrid');
    // 									});
    // 								}); 
    // 							});            
    // 						}
    // 						//Move order backward
    // 						else if(!Ember.isEmpty(currentField) && movedField.get('fieldRow') > currentField.get('fieldRow'))
    // 						{
    // 							lowerRowHolder = currentField.get('fieldRow');
    // 							upperRowHolder = movedField.get('fieldRow');
    // 							movedField.set('fieldRow', currentField.get('fieldRow'));
    // 							movedField.save().then(function(){
    // 								self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: currentField.get('form').get('id'), fieldRow: {'>=': lowerRowHolder, '<=': upperRowHolder}}}).then(function(fields){
    // 									fields.forEach(function(field){
    // 										field.set('fieldRow', field.get('fieldRow')+1);
    // 									});
    // 									fields.save().then(function(){
    // 										self.sendAction('sortGrid');
    // 									});                            
    // 								});
    // 							});            
    // 						}
    // 						//Move order forward
    // 						else if(!Ember.isEmpty(currentField) && movedField.get('fieldRow')+1 < currentField.get('fieldRow'))
    // 						{
    // 							lowerRowHolder = movedField.get('fieldRow')+1;
    // 							movedField.set('fieldRow', currentField.get('fieldRow')-1);
    // 							movedField.save().then(function(){
    // 								self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: currentField.get('form').get('id'), fieldRow: {'<=': movedField.get('fieldRow'), '>=':lowerRowHolder}}}).then(function(fields){
    // 									fields.forEach(function(field){
    // 										field.set('fieldRow', field.get('fieldRow')-1);
    // 									});
    // 									fields.save().then(function(){
    // 										self.sendAction('sortGrid');
    // 									});
    // 								});     
    // 							});                    
    // 						}
    // 					}
    // 					else
    // 					{
    // 						//Move order to the latest
    // 						if(!Ember.isEmpty('latestRow') && movedField.get('fieldRow')<latestRow && this.get('lastRow'))
    // 						{
    // 							rowHolder = movedField.get('fieldRow');
    // 							columnHolder = movedField.get('fieldColumn');
    // 							movedField.set('fieldRow', latestRow);
    // 							movedField.set('fieldColumn', 0);                    
    // 							movedField.set('fieldLength', 1);
    // 							this.set('latestRow', this.get('latestRow')+1);
    // 							movedField.save().then(function(){
    // 								self.store.query('field', {where: {'form': movedField.get('form').get('id'), 'fieldRow': rowHolder}}).then(function(fields){
    // 									fields.forEach(function(fieldValue){
    // 										fieldValue.set('fieldLength', fieldValue.get('fieldLength') - 1);
    // 									});
    // 									var updatedFieldHolder = fields.filter(function(fieldValue){
    // 										return fieldValue.get('fieldColumn') >= columnHolder;
    // 									});
    // 									updatedFieldHolder.forEach(function(fieldValue){
    // 										fieldValue.set('fieldColumn', fieldValue.get('fieldColumn') - 1);
    // 									});
    // 									fields.save().then(function(){
    // 										self.sendAction('sortGrid');
    // 									});
    // 								});
    // 							});            
    // 						}                
    // 						//Move order forward
    // 						else if(!Ember.isEmpty(currentField))
    // 						{
    // 							columnHolder = movedField.get('fieldColumn');
    // 							rowHolder = movedField.get('fieldRow');
    // 							lowerRowHolder = currentField.get('fieldRow');
    // 							movedField.set('fieldRow', currentField.get('fieldRow'));
    // 							movedField.set('fieldColumn', 0);
    // 							movedField.set('fieldLength', 1);
    // 							this.set('latestRow', this.get('latestRow')+1);
    // 							movedField.save().then(function(){
    // 								self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: currentField.get('form').get('id')}}).then(function(originField){
    // 									var firstFilter = originField.filter(function(fieldValue){
    // 										return parseInt(fieldValue.get('fieldRow')) === parseInt(rowHolder);
    // 									});
    // 									firstFilter.forEach(function(fieldValue){
    // 										fieldValue.set('fieldLength', fieldValue.get('fieldLength') - 1);
    // 									});
    // 									var secondFilter = firstFilter.filter(function(fieldValue){
    // 										return parseInt(fieldValue.get('fieldColumn')) >= parseInt(columnHolder);
    // 									});
    // 									secondFilter.forEach(function(fieldValue){
    // 										fieldValue.set('fieldColumn', fieldValue.get('fieldColumn') - 1);
    // 									});
    // 									var thirdFilter = originField.filter(function(fieldValue){
    // 										return parseInt(fieldValue.get('fieldRow')) >= parseInt(lowerRowHolder);
    // 									});
    // 									thirdFilter.forEach(function(fieldValue){
    // 										fieldValue.set('fieldRow', fieldValue.get('fieldRow') + 1);
    // 									});
    // 									originField.save().then(function(){
    // 										self.sendAction('sortGrid');
    // 									});                            
    // 								});
    // 							});                    
    // 						}
    // 					}
    // 				}
    // 			}
    // 		}               
    // 	}
    // }

  });

  _exports.default = _default;
});