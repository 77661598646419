define("client/pods/components/report-generator/content-tab/add-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    categoryValue: '',
    tablenameValue: '',
    isFiltered: false,
    filterModel: null,
    actions: {
      filterData: function filterData() {
        var self = this;
        var tempModel = Ember.A();

        if (self.get('categoryValue') || self.get('tablenameValue') || self.get('columnValue')) {
          self.set('isFiltered', true);
        } else {
          self.set('isFiltered', false);
        }

        this.get('models').forEach(function (model) {
          if (model.get('category').includes(self.get('categoryValue')) && model.get('modellabel').includes(self.get('tablenameValue'))) {
            tempModel.push(model);
          }
        });
        this.set('filterModel', tempModel);
      }
    }
  });

  _exports.default = _default;
});