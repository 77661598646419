define("client/pods/components/w3o-users-roles/form-user/address/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zs4xgv8i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"loadingPostalcode\",\"address\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"skeleton\"],[8],[0,\" \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"skeleton\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"for\",\"address\"],[8],[0,\"Address \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"addressIsEmpty\"],[8],[0,\"Address cannot be empty\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"addressForm\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphAddress\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"inputType\"]],\"text\"],null]],null,{\"statements\":[[0,\"            \"],[1,[28,\"input\",null,[[\"type\",\"id\",\"placeholder\",\"class\",\"value\"],[\"textarea\",\"address\",\"Enter Address\",\"form-control\",[24,[\"model\",\"address\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"inputType\"]],\"textarea\"],null]],null,{\"statements\":[[0,\"                \"],[1,[28,\"textarea\",null,[[\"class\",\"id\",\"placeholder\",\"value\",\"cols\",\"rows\"],[\"form-control\",\"address\",\"Enter Address\",[24,[\"model\",\"address\"]],\"80\",\"4\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/address/template.hbs"
    }
  });

  _exports.default = _default;
});