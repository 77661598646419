define("client/pods/components/report-generator/content-tab/maintable-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      fetchField: function fetchField() {
        this.fetchField();
      },
      removeAllTable: function removeAllTable() {
        this.removeAllTable();
      },
      removeSelectedTable: function removeSelectedTable() {
        this.removeSelectedTable();
      },
      addAllField: function addAllField() {
        this.addAllField();
      },
      addSelectedField: function addSelectedField() {
        this.addSelectedField();
      },
      removeAllField: function removeAllField() {
        this.removeAllField();
      },
      removeSelectedField: function removeSelectedField() {
        this.removeSelectedField();
      }
    }
  });

  _exports.default = _default;
});