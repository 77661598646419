define("client/mixins/dashboard-builder/paging-common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController: function setupController(controller, model, modelQuery) {
      controller.set('totalPage', 1);
      controller.set('listPage', 1);
      controller.set('postLimit', 10);
      controller.set('firstList', 0);
      modelQuery.then(function (model) {
        controller.set("modelConArr", model.toArray());
        controller.set('modelCon', controller.get('modelConArr').slice(controller.get('firstList'), controller.get('postLimit')));
        controller.set('modelCount', controller.get('modelConArr').get('length'));

        if (controller.get('modelCount') === 0) {
          controller.set('totalModel', 1);
        } else {
          controller.set('totalPage', Math.ceil(controller.get('modelCount') / 10));
        }
      });
    },
    actions: {
      nextList: function nextList() {
        if (this.controller.get('listPage') < this.controller.get('totalPage')) {
          var pageCount = this.controller.get('listPage');
          var firstCount = this.controller.get('firstList');
          var postCount = this.controller.get('postLimit');
          this.controller.set('listPage', pageCount + 1);
          this.controller.set('firstList', firstCount + 10);
          this.controller.set('postLimit', postCount + 10);
          this.controller.set('modelCon', this.controller.get('modelConArr').slice(firstCount + 10, postCount + 10));
        }
      },
      prevList: function prevList() {
        // GO TO PREVIOUS POST LIST ---------------------------------------------
        if (this.controller.get('listPage') > 1) {
          var pageCount = this.controller.get('listPage');
          var firstCount = this.controller.get('firstList');
          var postCount = this.controller.get('postLimit');
          this.controller.set('listPage', pageCount - 1);
          this.controller.set('firstList', firstCount - 10);
          this.controller.set('postLimit', postCount - 10);
          this.controller.set('modelCon', this.controller.get('modelConArr').slice(firstCount - 10, postCount - 10));
        }
      }
    }
  });

  _exports.default = _default;
});