define("client/pods/dashboard-builder/dabmap/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+IeICfWQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-top border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n    \\t\\t\"],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder\"]],{\"statements\":[[0,\"Dashboard ( \"],[1,[22,\"dashboardtitle\"],false],[0,\" )\"]],\"parameters\":[]},null],[0,\" - \"],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder.dabmap\"]],{\"statements\":[[0,\"Dashboard Map ( \"],[1,[22,\"dabmaptitle\"],false],[0,\" )\"]],\"parameters\":[]},null],[0,\" - Edit\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0 mt-3\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[\"dashboard-builder/newedit-dabmap\"],[[\"type\",\"objDabmap\",\"objDashboardmapcolumns\",\"dabmap\",\"actionToRoute\",\"addMoreContent\",\"deleteContent\"],[\"Edit\",[24,[\"objDabmap\"]],[24,[\"objDashboardmapcolumns\"]],[24,[\"model\"]],[28,\"route-action\",[\"updateDabmap\"],null],[28,\"route-action\",[\"addMoreContent\"],null],[28,\"route-action\",[\"deleteContent\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/dashboard-builder/dabmap/edit/template.hbs"
    }
  });

  _exports.default = _default;
});