define("client/pods/components/w3o-users-roles/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nLEeGmxA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"id\",\"class\"],[\"text\",[24,[\"filter\"]],\" Search\",\"search\",\"form-control\"]]],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"red\"],[8],[1,[22,\"noResult\"],false],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/search-bar/template.hbs"
    }
  });

  _exports.default = _default;
});