define("client/pods/components/w3office-forms/confirmation-settings-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.confirmationTypeContainer = [{
        name: 'Confirmation Message',
        value: 'Confirmation Message'
      }, {
        name: 'Page Redirect',
        value: 'Page Redirect'
      }];
    },
    actions: {
      saveConfiguration: function saveConfiguration() {
        /*
        	Persisting record via mixins/w3office-forms/form-setting-crud.js
        	persistRecord: function(value, modalName, controllerList)
        */
        this.persistRecord(this.get('confirmation'), 'confirmation', 'confirmationList');
      },
      discardChanges: function discardChanges() {
        if (Ember.isEmpty(this.get('confirmation').id)) {
          //unload if data is uncommited
          this.get('confirmation').unloadRecord();
        } else {
          //rollback if data is persisted
          this.get('confirmation').rollbackAttributes();
        }

        (0, _jquery.default)('#confirmation-props').modal('hide');
      },
      observeConfirmationType: function observeConfirmationType() {
        if (this.get('confirmation').get('confirmationType') === 'Page Redirect') {
          this.set('isMessage', false);
        } else {
          this.set('isMessage', true);
        }
      }
    }
  });

  _exports.default = _default;
});