define("client/pods/components/w3office-forms/standard-components/boolean-check/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    classNames: ["boolean-padding"],
    init: function init() {
      this._super();

      if (this.get('environment') === 'create') {
        //set containerValue based on 'defaultValue' than call setEntryContainer() at didInsertElement()
        this.set('containerValue', this.get('field').get('defaultValue') === 'true' ? true : false);
      } else {
        this.set('containerValue', this.get('field').get('entryContainer'));
      }
    },
    didInsertElement: function didInsertElement() {
      // can setEntryContainer() on 'create' page when have 'defaultValue'
      if (!this.get('authoring') && this.get('environment') === 'create') {
        this.setEntryContainer(this.get('field'), this.get('containerValue'));
      }
    },
    actions: {
      mutateEntry: function mutateEntry(val) {
        this.set('containerValue', val);
        this.setEntryContainer(this.get('field'), this.get('containerValue'));
      }
    }
  });

  _exports.default = _default;
});