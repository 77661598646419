define("client/pods/menu-builder/child/subchild/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/menu-builder/menu-crud"], function (_exports, _checkUserRole, _menuCrud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _menuCrud.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model() {
      var self = this;
      var parentnum = this.modelFor('menu-builder.child.subchild');
      return new Promise(function (resolve, reject) {
        self.store.query("menu", {
          where: {
            menuparentnum: parentnum.get('id')
          },
          sort: "priority ASC"
        }).then(function (menuChildren) {
          resolve(menuChildren);
        });
      });
    },
    setupController: function setupController(controller, model) {
      if (this.controllerFor('menu-builder').get('indexSet')) {
        controller.set("appFilter", this.controllerFor('menu-builder.index').get('appFilter'));
        controller.set("appList", this.controllerFor('menu-builder.index').get('appList'));
        var menunum = this.modelFor('menu-builder.child');
        var childnum = this.modelFor('menu-builder.child.subchild');
        controller.set("menuList", model);
        controller.set("menuid", menunum.id);
        controller.set("menutitle", menunum.get('title'));
        controller.set("childid", childnum.id);
        controller.set("childtitle", childnum.get('title'));
      } else {
        this.transitionTo('menu-builder');
      }
    }
  });

  _exports.default = _default;
});