define("client/pods/components/w3office-forms/standard-components/datetime-picker/component-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('disable', [function (date) {
        // https://flatpickr.js.org/examples/#disabling-dates-by-a-function
        // 'date' is the date options to be displayed
        // return true to disable
        if (_this.get('field').get('validationOperator') === '>') {
          return new Date(moment.utc().subtract('1', 'day').startOf('day').toISOString()) > date;
        } else if (_this.get('field').get('validationOperator') === '<') {
          return new Date() < date;
        } else {
          return false;
        }
      }]);
    },
    actions: {
      onChange: function onChange(selectedDates) {
        this.mutateEntry(selectedDates[0]);
      }
    }
  });

  _exports.default = _default;
});