define("client/routes/map", ["exports", "ol", "ol/layer/Tile", "ol/source/OSM"], function (_exports, _ol, _Tile, _OSM) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* import 'ol/ol.css'; */
  var _default = Ember.Route.extend({
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});