define("client/mixins/menu-player", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /*
    * Populate menu for header-navui component. populate the controller variable named 'menus'
    *	Required:
    * @param {String} application ID
    */
    populateMenu: function populateMenu(appID) {
      var _this = this;

      var controller = this.controller;

      if (this.get('authObject') === undefined) {
        alert("Please add authObject: Ember.inject.service('session') in the route.js");
      } else {
        // 'menus' must be an Array, so can 'pass by reference' inside application controller
        controller.set('menus', Ember.A());

        _jquery.default.ajax({
          type: "POST",
          url: this.store.adapterFor('application').get('namespace') + '/menus/getMenuFilterByRole',
          data: {
            user: this.get('authObject.data.user').id,
            appid: appID
          },
          success: function success(response) {
            var objMenus = {};
            objMenus['root'] = Ember.A();
            response.menus.forEach(function (menu) {
              if (_this.controller.get('isMobile') && menu.appID === 'HRMS' && (menu.title === 'Assign Usergroups' || menu.id === 10036)) {
                console.log(menu.appID + ' - ' + menu.title + ' menu not displayed on Mobile');
              } else {
                if (menu.menuparentnum === null) {
                  objMenus[menu.id] = objMenus[menu.id] === undefined ? Ember.A() : objMenus[menu.id];
                  menu.children = objMenus[menu.id];
                  objMenus['root'].pushObject(menu);
                } else {
                  objMenus[menu.menuparentnum] = objMenus[menu.menuparentnum] === undefined ? Ember.A() : objMenus[menu.menuparentnum];
                  objMenus[menu.id] = objMenus[menu.id] === undefined ? Ember.A() : objMenus[menu.id];
                  menu.children = objMenus[menu.id];
                  objMenus[menu.menuparentnum].pushObject(menu);
                }
              }
            });
            objMenus.root.forEach(function (menuRoot) {
              if (Ember.isEmpty(menuRoot.link)) {
                if (menuRoot.children.length > 0) {
                  var deletedMenuRootChild = [];
                  menuRoot.children.forEach(function (menuRootChild) {
                    if (Ember.isEmpty(menuRootChild.link) && menuRootChild.children.length === 0) {
                      deletedMenuRootChild.push(menuRootChild);
                    }
                  });
                  deletedMenuRootChild.forEach(function (deleted) {
                    menuRoot.children.removeObject(deleted);
                  });

                  if (menuRoot.children.length > 0) {
                    controller.get('menus').pushObject(menuRoot);
                  }
                }
              } else {
                controller.get('menus').pushObject(menuRoot);
              }
            });
          },
          error: function error(xhr, status, _error) {
            if (xhr.responseText) {
              console.log('Error ' + xhr.responseText);
            } else {
              console.log('Error ' + _error);
            }
          }
        });
      }
    },
    actions: {
      changeLanguage: function changeLanguage() {// Temporary function
        // the reason need this because in smartbiz project,
        // when initiating the headernav-ui,
        // also include the changeLanguage=(route-action 'changeLanguage')
        // so, please keep this function for a while. thanks
      }
    }
  });

  _exports.default = _default;
});