define("client/pods/users-roles/role-manager/app/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    appId: '',
    // actionList: '',
    beforeModel: function beforeModel(transition) {
      this._super();
    },
    model: function model(param) {
      this.set('appId', param.id);
      return this.store.findRecord('application', param.id);
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      this.set('appSetting.appId', model.id);
      this.set('appSetting.appName', model.get('appName'));
      this.set('appSetting.resourceAccessEnabled', model.get('resourceAccessEnabled'));
      console.log(this.controllerFor('application').get('menus').length > 0);

      if (this.controllerFor('application').get('menus').length > 0) {
        var roleManagementChildren = this.controllerFor('application').get('menus').find(function (menu) {
          return menu.title == 'Role Management';
        })['children'];
        var roleManagementChildrenLength = roleManagementChildren.length;
        roleManagementChildren.forEach(function (roleManagementChild, index) {
          roleManagementChildren.pushObject({
            'title': roleManagementChild.title,
            'link': roleManagementChild.link,
            'param': model.id
          });
        });
        roleManagementChildren.splice(0, roleManagementChildrenLength);
      }
    },
    actions: {
      goTo: function goTo(param) {
        this.transitionTo('users-roles.role-manager.app.' + param, this.get('appId'));
      }
    }
  });

  _exports.default = _default;
});