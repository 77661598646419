define("client/models/genreport", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    reportname: (0, _attr.default)('string'),
    reportheader: (0, _attr.default)('string'),
    reportfooter: (0, _attr.default)('string'),
    reportsummary: (0, _attr.default)('string'),
    reporttype: (0, _attr.default)('string'),
    application: (0, _attr.default)('string'),
    reportfolder: (0, _attr.default)('string'),
    lastimportdate: (0, _attr.default)('date'),
    importedby: (0, _attr.default)('string'),
    qbrcreatedby: (0, _attr.default)('string'),
    whatsql: (0, _attr.default)('string'),
    reporturl: (0, _attr.default)('string'),
    share: (0, _attr.default)('boolean'),
    fromtable: (0, _attr.default)('string'),
    limit: (0, _attr.default)('string'),
    generatestatus: (0, _attr.default)('string'),
    filetype: (0, _attr.default)('string'),
    tablecount: (0, _attr.default)('number'),
    isdelete: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    issubreport: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    companynum: (0, _relationships.belongsTo)("company", {
      async: true
    }),
    organizationnum: (0, _relationships.belongsTo)("organization", {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    genreportcategorynum: (0, _relationships.belongsTo)('genreportcategory', {
      async: true
    }),
    genreportsubcategorynum: (0, _relationships.belongsTo)('genreportsubcategory', {
      async: true
    }),
    paramcollectionnum: (0, _relationships.belongsTo)('genreportparamcollection', {
      async: true
    }),
    subreportto: (0, _attr.default)('number')
  });

  _exports.default = _default;
});