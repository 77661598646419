define("client/models/process", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    processId: (0, _attr.default)('string'),
    drafted: (0, _attr.default)('boolean'),
    app: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    formIdName: (0, _attr.default)('string'),
    supervisorGroup: (0, _relationships.belongsTo)('group', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    processversions: (0, _relationships.hasMany)('processversion', {
      async: true
    }),
    formProcesses: (0, _relationships.hasMany)('formprocess', {
      async: true
    })
  });

  _exports.default = _default;
});