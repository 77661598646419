define("client/models/section", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sectionid: _emberData.default.attr('string'),
    sectionname: _emberData.default.attr('string'),
    //relations	
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    departmentnum: _emberData.default.belongsTo("department", {
      async: true
    }),
    headofsection: _emberData.default.belongsTo('employee', {
      async: true
    }),
    isdelete: _emberData.default.attr('boolean'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});