define("client/pods/components/dashboard-builder/dabtabaccess-paging/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      nextList: function nextList() {
        this.nextList();
      },
      prevList: function prevList() {
        this.prevList();
      },
      deleteTabaccess: function deleteTabaccess(entry) {
        this.deleteTabaccess(entry);
      }
    }
  });

  _exports.default = _default;
});