define("client/models/layersetup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // attributes
    title: _emberData.default.attr('string'),
    font_char: _emberData.default.attr('string'),
    font_color: _emberData.default.attr('string'),
    font_family: _emberData.default.attr('string'),
    isexclusive: _emberData.default.attr('boolean'),
    isvisible: _emberData.default.attr('boolean'),
    zindex: _emberData.default.attr('number'),
    wms_url: _emberData.default.attr('string'),
    target_layer: _emberData.default.attr('string'),
    wms_styles: _emberData.default.attr('string'),
    wms_version: _emberData.default.attr('string'),
    publish_status: _emberData.default.attr('string'),
    access_by: _emberData.default.attr('number'),
    layer_info: _emberData.default.attr('string'),
    layer_type: _emberData.default.attr('string'),
    reprojectionerrorthreshold: _emberData.default.attr('number'),
    opaque: _emberData.default.attr('boolean'),
    minscale: _emberData.default.attr('number'),
    maxscale: _emberData.default.attr('number'),
    ratio: _emberData.default.attr('number'),
    load_order: _emberData.default.attr('number')
  });

  _exports.default = _default;
});