define("client/pods/components/w3o-users-roles/search-bar/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterCon: Ember.A(),
    noResult: '',
    basedOn: '',
    checkSearch: Ember.observer('list.@each', 'filter', function () {
      var list = this.get('postModel'),
          filter = this.get('filter');
      var self = this;

      if (!filter) {
        return list;
      }

      self.set('filterCon', Ember.A());
      this.set('basedOn', self.get('filterBy'));
      list.filter(function (item) {
        if (item.get(self.get('basedOn')).toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          self.get('filterCon').pushObject(item);
          self.set('noResult', '');
        }
      });

      for (var i = 0; i < list.content.length; i++) {
        if (list.content[i].get(self.get('basedOn')).toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          self.set('noResult', '');
          break;
        } else {
          self.set('noResult', 'No Result Found');
        }
      }

      (0, _jquery.default)("#search").keyup(function (e) {
        var keyCode = e.keyCode ? e.keyCode : e.which;

        if (keyCode === 8 && document.getElementById('search').value.length === 0) {
          self.set('filterCon', Ember.A());
          self.set('noResult', '');
          self.filterResult(self.get('filterCon'));
        } else {
          self.filterResult(self.get('filterCon'));
        }
      });
    })
  });

  _exports.default = _default;
});