define("client/pods/w3office-forms/form/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    authObject: Ember.inject.service('session'),
    currentPath: '',
    model: function model(params) {
      return this.store.findRecord('form', params.form_id);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      this.controller.set('filteringAppID', false);
      this.controller.set('formPickerPlaceholder', 'Switch Form');
      /*Need Rollback because of Localization*/

      if (model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }

      controller.set('model', model);
      controller.set('currentPath', this.get('currentPath'));
      this.addObserver('currentPath', function () {
        controller.set('currentPath', self.get('currentPath'));
      });
      var url = this.store.adapterFor('application').get('namespace') + '/forms/getAvailableAppId';

      _jquery.default.ajax({
        type: "POST",
        url: url,
        success: function success(response) {
          controller.set('appList', response.appIds);
        }
      });

      this.store.query('form', {
        sort: 'name ASC'
      }).then(function (forms) {
        controller.set('forms', forms);
      });
      controller.set('isEmbedCustomUI', controller.get('model.isEmbedCustomUI'));
    },
    afterModel: function afterModel(model, transition) {
      this.set('currentPath', transition.targetName);
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.set('currentPath', transition.targetName);
      },
      appChanged: function appChanged(appId) {
        var self = this;
        self.controller.set('filteringAppID', true);

        if (!Ember.isEmpty(appId)) {
          this.store.query('form', {
            where: {
              appID: appId
            },
            sort: 'name ASC'
          }).then(function (forms) {
            self.controller.set('forms', forms);
            self.controller.set('filteringAppID', false);
          });
        } else {
          this.store.query('form', {
            sort: 'name ASC'
          }).then(function (forms) {
            self.controller.set('forms', forms);
            self.controller.set('filteringAppID', false);
          });
        }
      },
      redirectForm: function redirectForm(formId) {
        //Check if path have second key or not. If there is second key, return to parent page
        if (this.controllerFor("application").get("currentPath").indexOf('w3office-forms.form.settings.notification') !== -1 || this.controllerFor("application").get("currentPath").indexOf('w3office-forms.form.settings.confirmation') !== -1) {
          this.transitionTo('w3office-forms.form.settings.index', formId);
        } else {
          this.transitionTo(this.controllerFor("application").get("currentPath"), formId);
        }
      }
    }
  });

  _exports.default = _default;
});