define("client/models/dashboardfilter", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    filteredColumn: (0, _attr.default)('string'),
    variableContainer: (0, _attr.default)('string'),
    nameValue: (0, _attr.default)('string'),
    operator: (0, _attr.default)('string'),
    isdelete: (0, _attr.default)('boolean'),
    dashboardMap: (0, _relationships.belongsTo)('dashboardmap', {
      async: true
    }) //dashboardReport: belongsTo('dashboardreport', {async: true})

  });

  _exports.default = _default;
});