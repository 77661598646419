define("client/models/employee", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employeeid: _emberData.default.attr('string'),
    employeename: _emberData.default.attr('string'),
    employeedescription1: _emberData.default.attr('string'),
    employeedescription2: _emberData.default.attr('string'),
    occupation: _emberData.default.attr('string'),
    resourceaccess: _emberData.default.attr('string'),
    //relations
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company'),

    /*parent: DS.belongsTo('employee', {
    		inverse: 'children'
    }),*/
    //if have parent, it means the employee has supervisor
    supervisornum: _emberData.default.belongsTo('employee', {
      inverse: 'children',
      async: true
    }),
    children: _emberData.default.hasMany('employee', {
      inverse: 'supervisornum',
      async: true
    }),

    /*occupationnum: DS.belongsTo('occupation'),*/
    divisionnum: _emberData.default.belongsTo('division', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    eusernum: _emberData.default.belongsTo('euser', {
      async: true
    }),
    isdelete: _emberData.default.attr('boolean'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});