define("client/pods/components/w3office-forms/advanced-components/action-button/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    actions: {
      /*
      	Bubble Action to Wrapper for executing API
      */
      executeAPICall: function executeAPICall() {
        this.get('field').set('isDisabled', true);
        this.fieldActionGateway(this, {
          actionName: 'executeCall'
        });
        /*
        	Perform Additional Logic from FieldLogic if trigger is Form Button. Currently only set to copy action only.
        */

        if (this.get('field').get('haveAdditionalLogic')) {
          this.get('field').get('issuedLogic').then(function (logics) {
            logics.forEach(function (logic) {
              if (logic.get('action') === 'copy') {
                logic.get('sourceField').then(function (sourceCopy) {
                  logic.get('affectedField').then(function (destinationCopy) {
                    destinationCopy.set('entryContainer', sourceCopy.get('entryContainer'));
                  });
                  self.get('field').set('isDisabled', false);
                });
              }
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});