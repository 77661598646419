define("client/pods/w3office-forms/server-management/route", ["exports", "axios", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _axios, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    setupController: function setupController(controller, model) {
      (0, _axios.default)({
        method: 'GET',
        url: this.store.adapterFor('application').get('serverBackEnd') + '/Formtables/getServerDetail'
      }).then(function (server) {
        controller.set('app_name', server.data);
      });
    },
    actions: {
      restartServer: function restartServer() {
        (0, _axios.default)({
          method: 'POST',
          url: this.store.adapterFor('application').get('serverBackEnd') + '/Formtables/restartServer'
        });
        (0, _jquery.default)("#restart-confirmation").modal("hide");
      },
      restartConfirmation: function restartConfirmation() {
        (0, _jquery.default)("#restart-confirmation").modal("show");
      }
    }
  });

  _exports.default = _default;
});