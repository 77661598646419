define("client/pods/components/w3office-forms/confirmation-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bu5g59qj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"confirmation\"],[10,\"class\",\"modal fade col-md-8 offset-md-2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n                \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n                \"],[7,\"button\",true],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"modal\"],[10,\"aria-hidden\",\"true\"],[10,\"type\",\"button\"],[8],[0,\"×\"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n                \"],[14,1],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"redirect\"]]],null,{\"statements\":[[0,\"                   \"],[4,\"link-to\",null,[[\"route\"],[[24,[\"route\"]]]],{\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-cancel\"],[10,\"data-dismiss\",\"modal\"],[8],[0,\"Close\"],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[7,\"button\",true],[10,\"class\",\"btn btn-cancel\"],[10,\"data-dismiss\",\"modal\"],[8],[0,\"Close\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/confirmation-modal/template.hbs"
    }
  });

  _exports.default = _default;
});