define("client/pods/w3office-forms/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hG8D5etL",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"page-header row border-top border-bottom mx-0\"],[10,\"style\",\"margin-bottom: 10px;\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"pull-left\"],[8],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[0,\"Form List\"],[9],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row mx-0\"],[10,\"style\",\"display: flex; justify-content: flex-end;\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"btn btn-success bump\"],[3,\"action\",[[23,0,[]],\"newFormTransition\"]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"fa fa-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Create New Form\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[\"w3office-forms/form-paging\"],[[\"appID\",\"appList\",\"conditionContainer\",\"currentPage\",\"disabledPrevNavigation\",\"disabledNextNavigation\",\"filter\",\"formContainer\",\"initialLoadForm\",\"totalPage\",\"deleteForm\",\"filterForm\",\"nextList\",\"prevList\",\"toggleDeleteForm\"],[[24,[\"appID\"]],[24,[\"appList\"]],[24,[\"conditionContainer\"]],[24,[\"currentPage\"]],[24,[\"disabledPrevNavigation\"]],[24,[\"disabledNextNavigation\"]],[24,[\"filter\"]],[24,[\"formContainer\"]],[24,[\"initialLoadForm\"]],[24,[\"totalPage\"]],[28,\"route-action\",[\"deleteForm\"],null],[28,\"route-action\",[\"filterForm\"],null],[28,\"route-action\",[\"nextList\"],null],[28,\"route-action\",[\"prevList\"],null],[28,\"route-action\",[\"toggleDeleteForm\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/w3office-forms/index/template.hbs"
    }
  });

  _exports.default = _default;
});