define("client/pods/components/w3office-forms/bootstrap-table-action-control/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    	This Component Used by components/w3office-forms/form-paging and pods/w3office-forms/form/settings/index/template.hbs
    */
    tagName: 'tr',
    showPrompt: false,
    actions: {
      toggleDelete: function toggleDelete() {
        if (this.get('showPrompt')) {
          this.set('showPrompt', false);
        } else {
          this.set('showPrompt', true);
        }
      },
      doDelete: function doDelete(param, controllerList) {
        this.deleteFunction(param, controllerList);
      },
      doUpdate: function doUpdate(param, controllerProp, modalName) {
        this.updateFunction(param, controllerProp, modalName);
      },
      showLocalization: function showLocalization(modelName, types, header, parentModelName, parent, columns) {
        this.showLocalization(modelName, types, header, parentModelName, parent, columns);
      }
    }
  });

  _exports.default = _default;
});