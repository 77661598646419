define("client/pods/process-builder/instances/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Dio
      // Edit date : 28 March 2016
      // Note : 
      this._super();
    },
    model: function model(param) {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Dio
      // Edit date : 28 March 2016
      // Note : 
      return this.store.findRecord('process', param.process_id);
    },
    renderTemplate: function renderTemplate() {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Dio
      // Edit date : 28 March 2016
      // Note : 
      this._super();
    },
    setupController: function setupController(controller, model) {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Paska
      // Edit date : 1 November 2016
      // Note : Refactor to Ember 2.8
      var self = this;
      var versionCount = 1;
      controller.set('loading', true);
      controller.set('model', model);
      controller.set('versions', model.get('processversions'));
      controller.get('versions').forEach(function (version) {
        self.store.query('processinstance', {
          processversion: version.get('id')
        }).then(function () {
          if (Ember.isEmpty(version.get('processinstances'))) {
            controller.set('loading', false);
          } else {
            version.get('processinstances').forEach(function (instance) {
              self.store.findRecord('user', instance.get('submitterId')).then(function (user) {
                instance.setProperties({
                  firstName: user.get('firstName'),
                  lastName: user.get('lastName')
                });
                self.store.query('processactivity', {
                  processversion: version.get('id'),
                  activityId: instance.get('currentNode')
                }).then(function (activities) {
                  if (!instance.get('finished')) {
                    instance.setProperties({
                      activityName: activities.objectAt(0).get('label'),
                      activityDesc: activities.objectAt(0).get('description')
                    });
                  }

                  if (versionCount <= controller.get('versions.length')) {
                    controller.set('loading', false);
                  } else {
                    versionCount++;
                  }
                });
              });
            });
          }
        });
      });
    }
  });

  _exports.default = _default;
});