define("client/pods/components/w3o-users-roles/user-profile-photo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tks58096",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"style\",[22,\"loadingStyle\"]],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/images/ajax-loader.gif\"],[10,\"style\",\"width: 25px; height: 25px;\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"profilePicture\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"profPic2 row justify-content-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-8 col-lg-12 col-md-12 text-center\"],[8],[0,\"\\n        \"],[7,\"img\",true],[11,\"id\",[29,[\"photo-\",[22,\"elementId\"]]]],[11,\"src\",[28,\"if\",[[24,[\"filePath\"]],[24,[\"filePath\"]],\"/images/blank-profile.png\"],null]],[10,\"alt\",\"profile image\"],[11,\"width\",[22,\"imgWidth\"]],[11,\"height\",[22,\"imgHeight\"]],[10,\"class\",\"img-fluid rounded-circle\"],[11,\"style\",[22,\"displayInline\"]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"profPic2 row justify-content-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-8 col-lg-12 col-md-12 text-center\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/images/blank-profile.png\"],[10,\"alt\",\"profile image\"],[11,\"width\",[22,\"imgWidth\"]],[11,\"height\",[22,\"imgHeight\"]],[10,\"class\",\"img-fluid rounded-circle\"],[11,\"style\",[22,\"displayInline\"]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/user-profile-photo/template.hbs"
    }
  });

  _exports.default = _default;
});