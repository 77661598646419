define("client/pods/components/dashboard-v2/preview-table/component", ["exports", "axios", "jquery"], function (_exports, _axios, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.set('editColumnName', false);
      this.set('isShowing', true);

      if (this.get('currentPage') === this.get('totalPage')) {
        this.set('nextDisabled', true);
      } else {
        this.set('nextDisabled', false);
      }

      if (this.get('currentPage') === 1) {
        this.set('prevDisabled', true);
      } else {
        this.set('prevDisabled', false);
      }

      console.log('entryList');
      console.log(this);
      console.log(Ember.get(this, 'entryList'));
      console.log(Ember.get(this, 'headers'));
      console.log(Ember.get(this, 'currentPage'));

      if (this.get("isTabular")) {
        this.set("tabularClass", Ember.String.htmlSafe("chart-tabular-container tabularChart-".concat(this.get("elementId"))));
        this.set("tableClass", Ember.String.htmlSafe("table table-sm table-hover chart-tabular-table"));
        this.set("tabularClassString", "tabularChart-".concat(this.get("elementId")));
        this.set("tableHeaderStyle", Ember.String.htmlSafe(""));
      } else {
        this.set("tabularClass", Ember.String.htmlSafe("table-responsive padding-0"));
        this.set("tableClass", Ember.String.htmlSafe("table table-hover"));
        this.set("tableHeaderStyle", Ember.String.htmlSafe("min-width: 100px; max-width: 500px; white-space: nowrap;"));
      }
    },
    didRender: function didRender() {
      if (this.get("isTabular")) {
        (0, _jquery.default)(".".concat(this.get("tabularClassString"))).css("height", this.get("tabularChartHeight"));
      }
    },
    observePage: Ember.observer('currentPage', 'totalPage', function () {
      if (this.get('currentPage') === this.get('totalPage')) {
        this.set('nextDisabled', true);
      } else {
        this.set('nextDisabled', false);
      }

      if (this.get('currentPage') === 1) {
        this.set('prevDisabled', true);
      } else {
        this.set('prevDisabled', false);
      }
    }),
    actions: {
      editEntry: function editEntry(index) {
        this.set('columnName', '');
        var headers = this.get('headers');
        console.log(headers);
        headers.forEach(function (element) {
          if (element.index == index) {
            Ember.set(element, 'edit', true);
          }
        });
        this.set('headers', headers);
      },
      saveEditEntry: function saveEditEntry(index, id, name) {
        var _this = this;

        console.log(index, id, name);
        var headers = this.get('headers');
        headers.forEach(function (element) {
          if (element.index == index) {
            Ember.set(element, 'edit', false);
          }
        });
        var url = "".concat(this.store.adapterFor('application').get('namespace'), "/connections/editReadableName");

        if (name != null && name != '') {
          (0, _axios.default)({
            method: 'POST',
            data: {
              finaltablecolumnnum: id,
              readablename: name
            },
            url: url
          }).then(function (response) {
            if (response.status == 200) {
              console.log(response);

              _this.saveEditEntry();
            } else {}
          });
        }
      },
      cancelEditEntry: function cancelEditEntry(index) {
        var headers = this.get('headers');
        headers.forEach(function (element) {
          if (element.index == index) {
            Ember.set(element, 'edit', false);
          }
        });
      },
      moveEntries: function moveEntries(direction, id) {
        this.set('direction', direction);
        this.set('tabularNum', id);
        this.set('isShowing', false);
        this.getTabularSet({
          direction: direction
        });
      },
      sortEntries: function sortEntries(id, column) {
        var sortby = [];

        if (this.get('sortColumn') != column) {
          this.set('orderBy') == null;
        }

        this.set('tabularNum', id);
        this.set('sortColumn', column);

        if (this.get('sortOrder') == null) {
          this.set('sortOrder', 'ASC');
        } else if (this.get('sortOrder') == 'ASC') {
          this.set('sortOrder', 'DESC');
        } else {
          this.set('sortOrder', 'ASC');
        }

        var orderBy = this.get('sortOrder');
        sortby.push("order by ".concat(column, " ").concat(orderBy));
        console.log(sortby);
        this.set('direction', null);
        this.set('sortBy', sortby);
        this.set('isShowing', false);
        this.getTabularSet({
          sortBy: sortby
        });
      },
      changeEntryLimit: function changeEntryLimit(id, limit) {
        this.set('direction', null);
        this.set('currentLimit', limit);
        this.set('tabularNum', id);
        this.set('isShowing', false);
        this.getTabularSet({});
      },
      sortAction: function sortAction() {},
      moveAction: function moveAction(param) {
        console.log(param);
      }
    }
  });

  _exports.default = _default;
});