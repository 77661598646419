define("client/pods/dashboard-builder/dabkpi/new/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3office-forms/check-resource-access"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin, _checkResourceAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _checkResourceAccess.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model() {
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      return this.store.createRecord('dabkpi', {
        title: null,
        description: null,
        type: 'shout',
        order: '',
        isActive: false,
        axisFormat: '%Y-%m-%d',
        height: null,
        width: null,
        shown: true,
        titleGlyph: null,
        dashboard: dashboardnum
      });
    },
    setupController: function setupController(controller, model) {
      var self = this;
      var dashboardnum = this.modelFor('dashboard-builder.dabkpi');
      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set('model', model);
      controller.set('resourceAccessEnabled', dashboardnum.get('resourceAccessEnabled'));

      if (dashboardnum.get('resourceAccessEnabled')) {
        controller.set('resourceAccessRestricted', false);
        controller.set('resourceAccessConditionPromise', Ember.RSVP.defer());
        controller.set('isLoadingResourceAccess', true);
        controller.set('modelName', 'dabkpi');
        self.populateResourceAccess();
        self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
          controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
          self.checkResourceAccessOnly();

          if (self.get('formContainer.resourceAccess') === '/') {
            self.store.query('organization', {
              isdelete: false
            }).then(function (organizations) {
              self.controller.set('organizationList', organizations);
            });
          } else if (self.get('formContainer.resourceAccess') === '/org') {
            controller.get('model').set('organizationnum', self.get('optmmSetting.companynum.organizationnum'));
            self.store.query('company', {
              where: {
                organizationnum: self.get('optmmSetting.companynum.organizationnum.id'),
                isdelete: false
              }
            }).then(function (companies) {
              self.controller.set('companyList', companies);
            });
          } else {
            controller.get('model').set('organizationnum', self.get('optmmSetting.companynum.organizationnum'));
            controller.get('model').set('companynum', self.get('optmmSetting.companynum'));
          }

          controller.get('model').addObserver('organizationnum', function (currentmodel) {
            self.controller.set('companyList', Ember.A());

            if (currentmodel.get('organizationnum')) {
              self.store.query('company', {
                where: {
                  organizationnum: currentmodel.get('organizationnum').get('id'),
                  isdelete: false
                }
              }).then(function (companies) {
                self.controller.set('companyList', companies);
              });
            }
          });
        });
      }
    },
    actions: {
      saveDabkpi: function saveDabkpi() {
        var self = this;
        var newRec = this.get('currentModel');
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          newRec.set('createdBy', user);
          newRec.save().then(function () {
            self.transitionTo('dashboard-builder.dabkpi');
          });
        });
      }
    }
  });

  _exports.default = _default;
});