define("client/pods/components/w3office-forms/bootstrap-table-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    	Set Tagname of Component wrapper as <tr> element
    */
    classNames: ['cursor-pointer'],
    tagName: 'tr',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.setProperties({
        disableField: false,
        showEdit: this.get("enableMultipleEdit") ? true : false
      });
      var headers = this.get("headers"),
          columns = Ember.A();

      if (!Ember.isEmpty(this.get('content')) && headers) {
        headers.forEach(function (header) {
          // console.log("header");
          // console.log(header);
          var columnObject = Ember.Object.create({
            id: null,
            content: null,
            columnName: null,
            falseValue: false,
            isBoolean: false,
            isDate: header.isDate,
            trueValue: true
          });

          if (typeof _this.get('content').get === 'function' && header.relationColumnName) {
            columnObject.setProperties({
              id: header.label,
              content: _this.get('content')[header.id] ? _this.get('content')[header.id][header.relationColumnName] : null,
              relationColumnName: header.relationColumnName
            });
          } else {
            // particular case for isCalculatedField
            if (header.isCalculatedFieldAlias) {
              columnObject.setProperties({
                id: header.label,
                content: _this.get('content')[header.isCalculatedFieldAlias]
              });
            } else {
              columnObject.setProperties({
                id: header.label,
                content: _this.get('content')[header.id],
                columnName: header.id
              });
            }
          }

          if (typeof columnObject.content === 'boolean') {
            columnObject.set("isBoolean", true);
          }

          if (header.isDate) {
            columnObject.fieldFormat = header.fieldFormat;
            columnObject.disableDate = []; // later need enhancement for this so can disable some date

            if (_this.get("content").get("".concat(header.id, "-rawDateValue"))) {
              columnObject.set("rawDateValue", _this.get("content").get("".concat(header.id, "-rawDateValue")));
            } else {
              columnObject.set("rawDateValue", null);
            }
          }

          if (_this.get("fieldsDDLoptions")) {
            if (_this.get("fieldsDDLoptions")[header.id]) {
              columnObject.set("options", _this.get("fieldsDDLoptions")[header.id]);
            }
          } // console.log("final columnObject");
          // console.log(columnObject);


          columns.push(columnObject);
        });
      }

      this.set("columns", columns);

      if (Ember.isEmpty(this.get("content.id"))) {
        console.log("hehe");
        this.set("showEdit", true);
      }
    },
    resetColumns: function resetColumns() {
      var _this2 = this;

      try {
        this.get("columns").forEach(function (col) {
          if (col.relationColumnName) {
            col.set("content", _this2.get('content')[col.id][col.relationColumnName]);
          } else {
            col.set("content", _this2.get("content")[col.columnName]);
          }

          col.set("errorMessage", null);
        });
      } catch (err) {
        console.log(err);
      }
    },
    actions: {
      columnAction: function columnAction() {
        if (!this.get('viewLimited') && !this.get("showEdit")) {
          this.actionTransitionToView(this.get('content'));
        }
      },
      dateChanged: function dateChanged(column, selectedDates) {
        // console.log("dateChanged");
        // console.log(column);
        // console.log(selectedDates);
        column.set("content", selectedDates[0]);
      },
      deleteRecord: function deleteRecord() {
        var _this3 = this;

        var confirm = window.confirm("Are you sure you want to delete this record?");

        if (confirm) {
          this.deleteRecord({
            columns: this.get("columns"),
            content: this.get("content")
          }, function (error) {
            if (error) {}

            _this3.set("showEdit", false);
          });
        }
      },
      rowChanged: function rowChanged() {
        console.log("rowChanged called");

        if (this.get("enableMultipleEdit")) {
          console.log({
            columns: this.get("columns"),
            content: this.get("content")
          });
          this.rowChanged({
            columns: this.get("columns"),
            content: this.get("content")
          }); // Logic
          // 1. SEND the updated row to ui.entries
          // 2. COLLECT the update row on ui.entries. DIFFERENTIATE by id
          // 3. LOOP the updated row and DO XHR to back-end
          // UI/IX
          // Need to show how many updated record
          // Must disable the input-text
          // Disable Field should be a Computed Property (updateOnProgress, enableMultipleEdit)
        }
      },
      toggleShowEdit: function toggleShowEdit() {
        if (this.get("showEdit")) this.resetColumns();
        this.setProperties({
          disableField: false,
          showEdit: !this.get("showEdit")
        });
      },
      updateRecord: function updateRecord() {
        var _this4 = this;

        this.set("disableField", true);
        this.updateRecord({
          columns: this.get("columns"),
          content: this.get("content")
        }, function (error) {
          if (error) {
            if (error.type === "validation") {
              _this4.get("columns").forEach(function (col) {
                col.set("errorMessage", error.message[col.columnName]);
              });

              _this4.set("disableField", false);
            } else if (error.type === "xhr-failed") {
              _this4.resetColumns();

              _this4.set("showEdit", false);
            }
          } else {
            _this4.set("showEdit", false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});