define("client/pods/components/dashboard-v2/sidebar-nav/component", ["exports", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin", "axios"], function (_exports, _jquery, _authenticatedRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_authenticatedRouteMixin.default, {
    // menu is array which contains navigation for template
    authObject: Ember.inject.service('session'),
    init: function init() {
      this._super();

      this.menu = [{
        'title': 'Datasource',
        //'link': 'dashboard-builder',
        'unique': 'form',
        'subunique': 'subform',
        'theme': '#ffffff',
        'submenu': [{
          'title': this.get('model.name') //'link': 'dashboard-builder'

        }]
      }, {
        'title': 'Connection',
        //'link': 'dashboard-builder',
        'unique': 'form',
        'subunique': 'subform',
        'theme': '#ffffff'
        /*'submenu': [{
            'title': 'Tax and Bills',
            'link': 'dashboard-builder'
        }]*/

      }, {
        'title': 'Static File',
        //'link': 'dashboard-builder',
        'unique': 'form',
        'subunique': 'subform',
        'theme': '#ffffff',
        'submenu': [{
          'upload': true,
          'type': 'excel',
          'title': 'Microsoft Excel',
          'ext': 'xls xlsx' //'link': 'dashboard-builder'

        }, {
          'upload': true,
          'type': 'csv',
          'title': 'CSV File',
          'ext': 'csv'
        }]
      }, {
        'title': 'API',
        //'link': 'dashboard-builder',
        'unique': 'form',
        'subunique': 'subform',
        'theme': '#ffffff',
        'submenu': [{
          'title': 'Data API' //'link': 'dashboard-builder'

        }]
      }];
      this.set('dbServer', {});
    },
    container: '',
    newNotif: false,
    imageLoaded: false,
    connectionnum: null,
    connecting: false,
    connectionFailed: false,
    errMessage: 'Connection Failed.',

    /*
    * Wait for session data to be processed, so need to use didReceiveAttrs
    * Get Company Logo if there is association to euser
    */
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.isEmpty(this.get('session'))) {
        var self = this;

        if (!Ember.isEmpty(this.get('session.user.euser.content')) && this.get('imageLoaded') === false) {
          var companynum;
          this.set('imageLoaded', true);

          if (this.get('session.user.euser.companynum')) {
            companynum = this.get('session.user.euser.companynum');
          } else {
            companynum = this.get('session.user.euser.optmm_company');
          }

          companynum.then(function (company) {
            if (company) {
              var ajaxCall = function ajaxCall(url) {
                (0, _axios.default)({
                  method: 'POST',
                  url: url,
                  responseType: 'arraybuffer'
                }).then(function (e) {
                  if (e.status == 200) {
                    window.URL = window.URL || window.webkitURL;
                    var blob = new Blob([e.data], {
                      type: 'image/jpeg'
                    });
                    var reader = new window.FileReader();
                    reader.readAsDataURL(blob);

                    reader.onloadend = function () {
                      self.set('appLogo', reader.result);
                    };
                  }
                });
              };

              var url;

              if (uuid.test(company.get('companylogopath'))) {
                self.store.query('formuploadedfile', {
                  where: {
                    UUID: company.get('companylogopath')
                  }
                }).then(function (resFormuploadedfile) {
                  url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + encodeURIComponent(resFormuploadedfile.get('firstObject').get('filePath'));
                  ajaxCall(url);
                });
              } else if (company.get('companylogopath')) {
                url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + encodeURIComponent(company.get('companylogopath'));
                ajaxCall(url);
              }
            }
          });
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      (0, _jquery.default)(document).ready(function () {
        (0, _jquery.default)('.link-click').click(function () {
          (0, _jquery.default)('.collapse.in').collapse('hide');
        });
        (0, _jquery.default)('#connectionModal').on('hidden.bs.modal', function (e) {
          // do something...
          self.set('connectionFailed', false);
          self.set('connecting', false);
        });
      });
      var el = document.querySelector('.customScrollbar');
      Ps.initialize(el);
    },
    model: Ember.computed(function () {
      var userId = this.session.get('user.id'); // return this.store.query('page', { sort: 'order asc', limit: 999 });

      return this.store.findRecord('user', userId);
    }),
    uploadFile: function uploadFile(file, url, connectionnum) {
      var _this = this;

      var headers = {
        Authorization: "Bearer ".concat(this.get('authObject.data.authenticated.access_token'))
      };
      file.upload(url, {
        headers: headers,
        data: {
          datasourcenum: this.get('model.id'),
          organizationnum: this.get('organizationnum'),
          companynum: this.get('companynum'),
          userid: this.get('authObject.data.user.id'),
          connectionnum: connectionnum // if there is connectionnum, it indicates edit

        }
      }).then(function (response) {
        _this.setProperties({
          isUploading: false,
          alertSuccess: true,
          alertMessage: 'Upload Success!',
          showAlert: true
        });

        _this.getConnection();
      }, function (err) {
        _this.setProperties({
          isUploading: false,
          alertSuccess: false,
          alertMessage: 'Upload Failed!',
          showAlert: true
        });
      });
    },
    refreshPreviewData: function refreshPreviewData() {
      var _this2 = this;

      if (this.get('currentPage') == null && this.get('currentLimit')) {
        this.set('currentPage', 1);
        this.set('currentLimit', 5);
      }

      var param = {
        tablename: this.get('tablename'),
        rowperpage: this.get('currentLimit'),
        currpage: this.get('currentPage')
      };
      var url = "".concat(this.store.adapterFor('application').get('namespace'), "/connections/viewData");
      (0, _axios.default)({
        method: 'POST',
        data: param,
        url: url
      }).then(function (response) {
        if (response.status == 200) {
          // console.log('response');
          // console.log(response);
          if ("preview_data" in response.data) {
            _this2.setProperties({
              showPreview: true,
              entryHeader: response.data.preview_data.entryHeader,
              entryList: response.data.preview_data.entryList,
              // currentPage: 1,
              currentLimit: _this2.get('currentLimit'),
              totalPage: response.data.preview_data.totalPage,
              sortOrder: 'ASC',
              sortColumn: null,
              isEmpty: true,
              enableRecordsTicking: false,
              numOfTickedRecords: 0,
              showPaging: true,
              currentPageAllTicked: false
            });
          }
        } else {}
      });
    },
    // actions will sendAction to respective routes on template where this component is called
    actions: {
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      },
      showHideSidebar: function showHideSidebar() {
        // check sidebar shown/hidden
        var flag = (0, _jquery.default)('.show-sidebar');

        if (flag.length === 0) {
          (0, _jquery.default)('#headernavdesktop-accordion').addClass('show-sidebar');
          (0, _jquery.default)('#headernavdesktop-accordion').removeClass('hide-sidebar');
          (0, _jquery.default)('.sidebar-mask').show();
        } else {
          (0, _jquery.default)('.show-sidebar').addClass('hide-sidebar');
          (0, _jquery.default)('.show-sidebar').removeClass('show-sidebar');
          (0, _jquery.default)('.sidebar-mask').hide();
        }
      },
      hideSidebarOnClick: function hideSidebarOnClick() {
        // hide sidebar on click
        (0, _jquery.default)('.show-sidebar').addClass('hide-sidebar');
        (0, _jquery.default)('.show-sidebar').removeClass('show-sidebar');
        (0, _jquery.default)('.sidebar-mask').hide();
      },
      storeConnectionnum: function storeConnectionnum(param) {
        this.set('connectionnum', param);
      },
      queueFile: function queueFile(file) {
        var url = '/api/v1/connections/uploadFile';
        this.set('isUploading', true);
        this.uploadFile(file, url);
      },
      editConnection: function editConnection(file) {
        var url = '/api/v1/connections/uploadFile';
        this.set('isUploading', true);

        if (file.error) {
          this.set('isErrorUpload', true);
        } else {
          this.set('isErrorUpload', false);
        }

        this.uploadFile(file, url, this.get('connectionnum'));
      },
      renameConnection: function renameConnection(connectionnum) {
        var inputId = 'connection-name-' + connectionnum;
        document.getElementById(inputId).disabled = false;
        document.getElementById(inputId).select();
      },
      saveRenameConnection: function saveRenameConnection(connection) {
        var _this3 = this;

        var url = '/api/v1/connections/renameConnection';
        var inputId = 'connection-name-' + connection.id;
        document.getElementById(inputId).disabled = true;
        (0, _axios.default)({
          method: 'POST',
          data: {
            name: connection.name,
            userId: this.get('authObject.data.user.id'),
            connectionnum: connection.id
          },
          url: url
        }).then(function (response) {
          if (response.status == 200) {
            _this3.set('alertSuccess', true);

            _this3.set('alertMessage', 'Rename Success!');
          } else {
            _this3.set('alertSuccess', false);

            _this3.set('alertMessage', 'Rename Failed!');
          }

          _this3.set('showAlert', true);
        });
      },
      newServerConnection: function newServerConnection(type) {
        //console.log(type);
        this.set('showEdit', false);
        this.set('dbServer.type', type);
      },
      createDatabase: function createDatabase() {
        var _this4 = this;

        var dbServer = {};
        var portNum;
        this.set('connecting', true);
        this.set('connectionFailed', false);

        if (this.get('dbServer.portNo') == '') {
          portNum = null;
        } else {
          portNum = this.get('dbServer.portNo');
        }

        dbServer = {
          category: 'server',
          type: this.get('dbServer.type'),
          serverName: this.get('dbServer.serverName'),
          hostIp: this.get('dbServer.hostIp'),
          portNo: portNum,
          dbName: this.get('dbServer.dbName'),
          username: this.get('dbServer.username'),
          password: this.get('dbServer.password'),
          organizationnum: this.get('organizationnum'),
          companynum: this.get('companynum')
        };
        var url = '/api/v1/connections/createDatabase';
        (0, _axios.default)({
          method: 'POST',
          data: {
            datasourcenum: this.model.id,
            dbServer: dbServer,
            userId: this.get('authObject.data.user.id')
          },
          url: url
        }).then(function (response) {
          if (response.status == 200) {
            _this4.set('dbServer', {});

            _this4.get('connections').pushObject(response.data.connection_data);

            (0, _jquery.default)('#connectionModal').modal('hide');
          } else {
            _this4.set('dbServer', {});
          }
        }).catch(function (err) {
          _this4.set('errMessage', err.response.data.message);

          _this4.set('connecting', false);

          _this4.set('connectionFailed', true);
        });
      },
      editServerConnection: function editServerConnection(connectionnum) {
        var self = this;
        self.set('showEdit', true);
        var url = '/api/v1/connections/getServerConnection'; //let inputId = 'connection-name-' + connection.id;
        //document.getElementById(inputId).disabled = true;

        (0, _axios.default)({
          method: 'POST',
          data: {
            id: connectionnum
          },
          url: url
        }).then(function (response) {
          if (response.status == 200) {
            //this.set('alertSuccess', true);
            //this.set('alertMessage', 'Rename Success!');
            console.log(response.data.message);
            console.log(response.data.data);
            self.set('dbServer', {
              id: connectionnum,
              category: response.data.data[0].category,
              type: response.data.data[0].type,
              serverName: response.data.data[0].name,
              hostIp: response.data.data[0].host,
              portNo: response.data.data[0].port,
              dbName: response.data.data[0].database,
              username: response.data.data[0].username,
              password: response.data.data[0].password,
              organizationnum: response.data.data[0].organizationnum,
              companynum: response.data.data[0].companynum
            });
            console.log(self.get('dbServer'));
          } else {//this.set('alertSuccess', false);
            //this.set('alertMessage', 'Rename Failed!');
          } //this.set('showAlert', true);

        });
      },
      showTables: function showTables(connectionnum) {
        var self = this;
        var url = '/api/v1/connections/getServerTables';
        (0, _axios.default)({
          method: 'POST',
          data: {
            id: connectionnum
          },
          url: url
        }).then(function (response) {
          if (response.status == 200) {
            console.log(response.data.message);
            console.log(response.data.returnData.sql_data);
            self.set("tables", response.data.returnData.sql_data); //console.log(self.get(`tables`));

            /*
            let connections = self.get('connections');
            connections.forEach(conn => {
              if (conn.id === connectionid){
                conn.tables = response.data.data;
              }
            });
            console.log(connections);
            self.set('connections', connections);
            */
          } else {}
        });
      },
      // Rename datasource
      editDatasource: function editDatasource(saveData, editData, inputData) {
        this.toggleProperty('isShowing');
        var buttonId = "saveData";
        var buttonId2 = "editData"; // let inputId = "inputData";
        // document.getElementById(buttonId).disabled = false;
        // document.getElementById(buttonId2).disabled = true;
        // document.getElementById(inputId).disabled = false;
      },
      saveDatasource: function saveDatasource(param) {
        var datasourceName = this.model.name;
        var datasourceId = this.model.id;
        var url = '/api/v1/datasources/renameDatasource';
        console.log(this.model.id);
        console.log(param);
        this.toggleProperty('isShowing');
        (0, _axios.default)({
          method: 'POST',
          data: {
            name: datasourceName,
            userId: this.get('authObject.data.user.id'),
            dataId: datasourceId
          },
          url: url
        }); // let buttonId = "editData";
        // let buttonId2 = "saveData";
        // document.getElementById(buttonId).disabled = false;
        // document.getElementById(buttonId2).disabled = true;
      },
      cancelDatasource: function cancelDatasource() {
        this.toggleProperty('isShowing');
      },
      closeModal: function closeModal() {
        this.toggleProperty('showEdit');
      },
      saveEditedConnection: function saveEditedConnection(dbServer) {
        var _this5 = this;

        this.set('connecting', true);
        this.set('connectionFailed', false);
        var url = '/api/v1/connections/saveConnection';
        var self = this; // let connectionId = this.dbServer.id;

        var connectionName = dbServer.serverName;
        var hostName = dbServer.hostIp;
        var portNumber = dbServer.portNo;
        var databaseName = dbServer.dbName;
        var usernameSave = dbServer.username;
        var passwordSave = dbServer.password; //console.log(connectionnum, param1, param2, param3, param4, param5, param6);
        // console.log(connectionnum);

        if (portNumber == '') {
          portNumber = null;
        }

        (0, _axios.default)({
          method: 'POST',
          data: {
            userId: this.get('authObject.data.user.id'),
            connId: dbServer.id,
            name: connectionName,
            host: hostName,
            port: portNumber,
            database: databaseName,
            username: usernameSave,
            password: passwordSave
          },
          url: url
        }).then(function (response) {
          if (response.status == 200) {
            //this.set('alertSuccess', true);
            //this.set('alertMessage', 'Rename Success!');
            console.log(response.data.message);
            (0, _jquery.default)('#connectionModal').modal('hide');
          } else {//this.set('alertSuccess', false);
            //this.set('alertMessage', 'Rename Failed!');
          } //this.set('showAlert', true);

        }).catch(function (err) {
          _this5.set('errMessage', err.response.data.message);

          _this5.set('connecting', false);

          _this5.set('connectionFailed', true);
        });
        ;
      },
      viewData: function viewData(table) {
        var _this6 = this;

        this.setProperties({
          showPreview: false,
          entryHeader: [],
          entryList: [],
          isEmpty: false
        });
        this.set('tablename', table.tablename);
        this.set('currentPage', 1);
        this.set('currentLimit', 5);
        var param = {
          tablename: table.tablename,
          rowperpage: this.get('currentLimit'),
          currpage: this.get('currentPage')
        };
        var url = "".concat(this.store.adapterFor('application').get('namespace'), "/connections/viewData");
        (0, _axios.default)({
          method: 'POST',
          data: param,
          url: url
        }).then(function (response) {
          if (response.status == 200) {
            if ("preview_data" in response.data) {
              _this6.setProperties({
                showPreview: true,
                entryHeader: response.data.preview_data.entryHeader,
                entryList: response.data.preview_data.entryList,
                currentLimit: _this6.get('currentLimit'),
                totalPage: response.data.preview_data.totalPage,
                sortOrder: 'ASC',
                sortColumn: null,
                isEmpty: true,
                enableRecordsTicking: false,
                numOfTickedRecords: 0,
                showPaging: true,
                currentPageAllTicked: false
              });
            }
          } else {}
        });
      },
      movePreviewData: function movePreviewData(direction) {
        if (direction == 'prev') this.set('currentPage', this.get('currentPage') - 1);else this.set('currentPage', this.get('currentPage') + 1);
        this.refreshPreviewData();
      },
      limitPreviewData: function limitPreviewData(limit) {
        this.set('currentLimit', limit);
        this.refreshPreviewData();
      }
    }
  });

  _exports.default = _default;
});