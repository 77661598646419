define("client/pods/components/w3oprocess/process-canvastoolbar/component", ["exports", "jquery", "jsplumb"], function (_exports, _jquery, _jsplumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    nodeID: '',
    eventLabel: '',
    activityLabel: '',
    activityAsignee: '',
    gatewayLabel: '',
    nextAction: '',
    type: '',
    autoNode: '',
    actions: {
      addNode: function addNode(nextAction, type) {
        this.set('autoNode', 'node-' + this.get('nodeIdent'));

        if (nextAction === 'addEvent') {
          (0, _jquery.default)('#create-event-modal').modal({
            backdrop: 'static',
            keyboard: false
          });
        } else if (nextAction === 'addActivity') {
          (0, _jquery.default)('#create-activity-modal').modal({
            backdrop: 'static',
            keyboard: false
          });
        } else if (nextAction === 'addGateway') {
          (0, _jquery.default)('#create-gateway-modal').modal({
            backdrop: 'static',
            keyboard: false
          });
        }

        this.set('nextAction', nextAction);
        this.set('type', type);
      },
      changeAutoNode: function changeAutoNode(nodeID) {
        this.set('nodeID', nodeID);
        this.send(this.get('nextAction'), this.get('type'));
        var nodeIdent = this.get('nodeIdent');
        nodeIdent++;
        this.set('nodeIdent', nodeIdent);
      },
      addEvent: function addEvent(type) {
        var self = this;
        this.createEvent(type, this.get('nodeID'), this.get('eventLabel'));
        Ember.run.later(function () {
          var count = self.get('model.eventCount');
          count++;
          self.set('model.eventCount', count);
          var container = (0, _jquery.default)('.event-item-' + self.get('model.eventCount'));
          var newState = (0, _jquery.default)('.event-item-' + self.get('model.eventCount') + ' .node-event');
          var connector = (0, _jquery.default)('.event-item-' + self.get('model.eventCount') + ' .node-connect');
          container.css({
            'top': (0, _jquery.default)('#work-canvas').scrollTop() + 20,
            'left': (0, _jquery.default)('#work-canvas').scrollLeft() + 20
          });
          connector.css({
            'width': newState[0].width + 10,
            'height': newState[0].height + 10
          });
          (0, _jquery.default)(container).draggable({
            drag: function drag() {
              _jsplumb.jsPlumb.repaintEverything();
            },
            stop: function stop() {
              var position = (0, _jquery.default)(this).position();

              if (position.top < 0) {
                (0, _jquery.default)(container).css({
                  top: 20
                });
              }

              if (position.left < 0) {
                (0, _jquery.default)(container).css({
                  left: 20
                });
              }
            }
          });

          _jsplumb.jsPlumb.makeSource(connector, {
            isSource: true,
            deleteEndpointsOnDetach: true
          });

          _jsplumb.jsPlumb.makeTarget(connector, {
            isTarget: true,
            uniqueEndpoint: true,
            deleteEndpointsOnDetach: true,
            connectionsDetachable: true
          }); // newState.dblclick(function(e) {
          // 	jsPlumb.detachAllConnections($(connector));
          // 	container.remove();
          // 	e.stopPropagation();
          // });


          self.set('eventLabel', '');
        }, 100);
      },
      addActivity: function addActivity(type) {
        var self = this;
        this.createActivity(type, this.get('nodeID'), this.get('activityLabel'), this.get('activityAsignee'));
        Ember.run.later(function () {
          var count = self.get('model.activityCount');
          count++;
          self.set('model.activityCount', count);
          var container = (0, _jquery.default)('.activity-item-' + self.get('model.activityCount'));
          var newState = (0, _jquery.default)('.activity-item-' + self.get('model.activityCount') + ' .node-activity');
          var connector = (0, _jquery.default)('.activity-item-' + self.get('model.activityCount') + ' .node-connect');
          container.css({
            'top': (0, _jquery.default)('#work-canvas').scrollTop() + 20,
            'left': (0, _jquery.default)('#work-canvas').scrollLeft() + 20
          });

          if (type === 'task') {
            connector.css({
              'width': newState[0].scrollWidth + 14,
              'height': newState[0].scrollHeight + 14
            });
          } else if (type === 'sub') {
            connector.css({
              'width': newState[0].scrollWidth + 14,
              'height': newState[0].scrollHeight + 38
            });
          } else if (type === 'call') {
            connector.css({
              'width': newState[0].scrollWidth + 18,
              'height': newState[0].scrollHeight + 18
            });
          }

          (0, _jquery.default)(container).draggable({
            drag: function drag() {
              _jsplumb.jsPlumb.repaintEverything();
            },
            stop: function stop() {
              var position = (0, _jquery.default)(this).position();

              if (position.top < 0) {
                (0, _jquery.default)(container).css({
                  top: 20
                });
              }

              if (position.left < 0) {
                (0, _jquery.default)(container).css({
                  left: 20
                });
              }
            }
          });

          _jsplumb.jsPlumb.makeSource(connector, {
            isSource: true,
            deleteEndpointsOnDetach: true
          });

          _jsplumb.jsPlumb.makeTarget(connector, {
            isTarget: true,
            uniqueEndpoint: true,
            deleteEndpointsOnDetach: true,
            connectionsDetachable: true
          }); // newState.dblclick(function(e) {
          // 	jsPlumb.detachAllConnections($(connector));
          // 	container.remove();
          // 	e.stopPropagation();
          // });


          self.set('activityLabel', '');
          self.set('activityAsignee', '');
        }, 100);
      },
      addGateway: function addGateway() {
        var self = this;
        this.createGateway(this.get('nodeID'), this.get('gatewayLabel'));
        Ember.run.later(function () {
          var count = self.get('model.gatewayCount');
          count++;
          self.set('model.gatewayCount', count);
          var container = (0, _jquery.default)('.gateway-item-' + self.get('model.gatewayCount'));
          var newState = (0, _jquery.default)('.gateway-item-' + self.get('model.gatewayCount') + ' .node-gateway');
          var connector = (0, _jquery.default)('.gateway-item-' + self.get('model.gatewayCount') + ' .node-connect');
          container.css({
            'top': (0, _jquery.default)('#work-canvas').scrollTop() + 20,
            'left': (0, _jquery.default)('#work-canvas').scrollLeft() + 20
          });
          connector.css({
            'width': newState[0].scrollWidth + 10,
            'height': newState[0].scrollHeight + 10
          });
          (0, _jquery.default)(container).draggable({
            drag: function drag() {
              _jsplumb.jsPlumb.repaintEverything();
            },
            stop: function stop() {
              var position = (0, _jquery.default)(this).position();

              if (position.top < 0) {
                (0, _jquery.default)(container).css({
                  top: 20
                });
              }

              if (position.left < 0) {
                (0, _jquery.default)(container).css({
                  left: 20
                });
              }
            }
          });

          _jsplumb.jsPlumb.makeSource(connector, {
            isSource: true,
            deleteEndpointsOnDetach: true,
            connectorOverlays: [["Label", {
              label: "condition"
            }]],
            maxConnections: 2,
            onMaxConnections: function onMaxConnections() {
              alert('This gateway already reach maximum connections');
            }
          });

          _jsplumb.jsPlumb.makeTarget(connector, {
            isTarget: true,
            uniqueEndpoint: true,
            deleteEndpointsOnDetach: true,
            connectionsDetachable: true
          }); // newState.dblclick(function(e) {
          // 	jsPlumb.detachAllConnections(connector);
          // 	container.remove();
          // 	e.stopPropagation();
          // });


          self.set('gatewayLabel', '');
        }, 100);
      }
    }
  });

  _exports.default = _default;
});