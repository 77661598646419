define("client/models/dabkpiparamvalue", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    order: (0, _attr.default)('number'),
    value: (0, _attr.default)('string'),
    isdelete: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    dabkpinum: (0, _relationships.belongsTo)('dabkpi', {
      async: true
    }),
    dabkpiconfigselectionnum: (0, _relationships.belongsTo)('dabkpiconfigselection', {
      async: true
    })
  });

  _exports.default = _default;
});