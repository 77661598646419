define("client/mixins/w3o-users-roles/create-edit-user", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authObject: Ember.inject.service('session'),
    create: false,
    setupController: function setupController() {
      var controller = this.get('controller');
      var model = this.get('currentModel');
      controller.set('model', model);
      controller.set('groupModel', this.store.findAll('group'));
      controller.set('applicationModel', this.store.findAll('application'));
      controller.set('secretQuestionModel', this.store.findAll('secretquestion'));
      controller.set('hasSelect', false);
      controller.set('loadingPostalcode', Ember.Object.create({
        address: false,
        address1: false,
        city: false
      }));
      controller.set('countryList', this.store.query('country', {
        sort: 'nicename ASC'
      }));
    },
    getInfo: function getInfo(param) {
      var self = this;
      var controller = this.get('controller');
      controller.get('loadingPostalcode').setProperties({
        address: true,
        address1: true,
        city: true,
        state: true
      });
      controller.set('model.state', '-');
      controller.set("loadingPostalcode.state", false);
      (0, _jquery.default)("#stateForm").removeClass('has-error');
      (0, _jquery.default)('#glyphState').addClass("d-none");
      (0, _jquery.default)('#stateIsEmpty').addClass("d-none");

      _jquery.default.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressByPostalCodeFromGoThere',
        success: function success(response) {
          controller.set('model.address', response.value);
          controller.set('loadingPostalcode.address', false);

          if (response.value) {
            (0, _jquery.default)("#addressForm").removeClass('has-error');
            (0, _jquery.default)('#glyphAddress').addClass("d-none");
            (0, _jquery.default)('#addressIsEmpty').addClass("d-none");
          }
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });

      _jquery.default.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressLineByPostalCodeFromGoThere',
        success: function success(response) {
          controller.set('model.address1', response.value);
          controller.set('loadingPostalcode.address1', false);

          if (response.value) {
            (0, _jquery.default)("#address1Form").removeClass('has-error');
            (0, _jquery.default)('#glyphAddress1').addClass("d-none");
            (0, _jquery.default)('#address1IsEmpty').addClass("d-none");
          }
        },
        error: function error(xhr, status, _error2) {
          console.log('Error ' + _error2);
        }
      });

      _jquery.default.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameByPostalCodeFromGoThere',
        success: function success(response) {
          controller.set('model.city', response.value);
          controller.set('loadingPostalcode.city', false);

          if (response.value) {
            (0, _jquery.default)("#cityForm").removeClass('has-error');
            (0, _jquery.default)('#glyphCity').addClass("d-none");
            (0, _jquery.default)('#cityIsEmpty').addClass("d-none");
          }
        },
        error: function error(xhr, status, _error3) {
          console.log('Error ' + _error3);
        }
      }); // $.ajax({
      //     type: "POST",
      //     data:{
      //         sourceId: param
      //     },
      //     url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameCodeByPostalCodeFromGoThere',
      //     success: function(response){
      //         self.store.queryRecord('country', { iso: response.value }).then(function(country){
      //             controller.set('model.country', country);
      //             if (response.value) {
      //                 $("#countryForm").removeClass('has-error');
      //                 $('#glyphCountry').addClass("d-none");
      //                 $('#countryIsEmpty').addClass("d-none");
      //             }
      //         });
      //     },
      //     error: function(xhr, status, error){
      //         console.log('Error ' + error);
      //     }
      // });

    },
    saveUser: function saveUser(model) {
      var self = this;
      var controller = this.get('controller');

      if (this.get('create')) {
        _jquery.default.ajax({
          url: '/api/v1/users',
          type: 'POST',
          data: JSON.stringify({
            user: {
              password: model.get('password'),
              firstName: model.get('firstName'),
              lastName: model.get('lastName'),
              username: model.get('username'),
              tagline: model.get('tagline'),
              email: model.get('email').toLowerCase(),
              website: model.get('website'),
              postalCode: model.get('postalCode'),
              address: model.get('address'),
              address1: model.get('address1'),
              city: model.get('city'),
              state: model.get('state'),
              country: model.get('country.content.id'),
              phone: model.get('phone'),
              answer: model.get('answer'),
              secretquestion: model.get('secretquestion.content.id'),
              authEnable: model.get('authEnable'),
              status: 'Active'
            }
          }),
          contentType: 'application/json'
        }).then(function (response) {
          self.store.findRecord('user', response.user.id).then(function (user) {
            var usergroup = self.store.createRecord('usergroup', {
              user: user,
              group: controller.get('groupCon')
            });
            usergroup.save().then(function (usergroup) {
              self.transitionTo('users-roles.users');
            });
          }); // _this.get('session').authenticate('authenticator:signup', response);
        }, function (xhr, status, error) {
          alert("Error: ".concat(xhr.responseText));
        });
      } else {
        model.save(); // .then(function(user){                    
        //     $.ajax({
        //         url: '/api/v1/users/reset/',
        //         type: 'POST',
        //         data: {
        //             id: model.id,
        //             siteAdmin: true, 
        //             email: model.get('email').toLowerCase(),
        //             sendPassToEmail: true,
        //             password: model.get('password'),
        //         },
        //         dataType:    'json',
        //         contentType: 'application/x-www-form-urlencoded',
        //     }).then((response) => {
        //         $.ajax({
        //             url: '/api/v1/users/resetans',
        //             type: 'POST',
        //             headers: requestHeaders,
        //             data: {
        //                 id: model.id,
        //                 siteAdmin: true, 
        //                 email: model.get('email').toLowerCase(),
        //                 sendAnswerToEmail: true,
        //                 newanswer: model.get('answer')
        //             },
        //             dataType:    'json',
        //             contentType: 'application/x-www-form-urlencoded',
        //         }).then((response) => {
        //             $.ajax({
        //                 url: '/api/v1/users/changequestion',
        //                 type: 'POST',
        //                 headers: requestHeaders,
        //                 data: {
        //                     id: model.id,
        //                     secretquestion: model.get('secretquestion.content.id')
        //                 },
        //                 dataType:    'json',
        //                 contentType: 'application/x-www-form-urlencoded',
        //             }).then((response) => {
        //                 self.transitionTo('users-roles.users');
        //             },(xhr, status, error) => {
        //             });
        //         });
        //     },(xhr, status, error) => {
        //         alert(status);
        //         alert(error);
        //         alert(`Error: ${xhr.responseText}`);
        //     });
        // });

        self.transitionTo('users-roles.users');
      }
    }
  });

  _exports.default = _default;
});