define("client/models/site", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    siteid: _emberData.default.attr('string'),
    sitename: _emberData.default.attr('string'),
    sitedescription: _emberData.default.attr('string'),
    isactive: _emberData.default.attr('boolean'),
    billtocontactname: _emberData.default.attr('string'),
    contactname: _emberData.default.attr('string'),
    shiptocontactname: _emberData.default.attr('string'),
    sitephone1: _emberData.default.attr('string'),
    sitephone2: _emberData.default.attr('string'),
    sitefax: _emberData.default.attr('string'),
    sitetype: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    hasbilladdress: _emberData.default.attr('boolean'),
    hasshipaddress: _emberData.default.attr('boolean'),
    filladdress: _emberData.default.attr('string'),
    fillbilladdress: _emberData.default.attr('string'),
    fillshipaddress: _emberData.default.attr('string'),
    //relations	
    addressnum: _emberData.default.belongsTo('address', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    billtoaddressnum: _emberData.default.belongsTo('address', {
      async: true
    }),
    shiptoaddressnum: _emberData.default.belongsTo('address', {
      async: true
    }),
    locations: _emberData.default.hasMany("location", {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});