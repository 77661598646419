define("client/pods/reset-password/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/unauthenticated-route-mixin", "axios"], function (_exports, _applicationRouteMixin, _unauthenticatedRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
      Accessible only when user is not logged in
  */
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _applicationRouteMixin.default, {
    /*
        Create model 'resetpass'
    */
    model: function model() {
      return this.store.createRecord('resetpass');
    },

    /*
        Initialize values
    */
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('sendEmailSuccess', null);
      controller.set('errorMessage', null);
      controller.set("successMessage", null);
      controller.set('reCaptchaFlag', null);
      controller.set('recaptchaResult', null);
      controller.set("showLoading", false);
    },
    actions: {
      recaptchaWait: function recaptchaWait() {
        this.controller.set('reCaptchaFlag', false);
      },
      pipeRecaptchaResponse: function pipeRecaptchaResponse(success) {
        //pipe recaptcha result from component to route
        this.controller.set('reCaptchaFlag', true);
        this.controller.set('recaptchaResult', success);
      },
      resetPassword: function resetPassword() {
        var _this = this;

        var controller = this.get('controller');
        var email = controller.get('model').get('email');
        var flag = 0;

        if (Ember.isEmpty(email)) {
          controller.set('errorMessage', 'Please input your email!');
          /*setTimeout(function(){
              controller.set('errorMessage', null);
          }, 2000);*/

          flag++;
          return;
        }

        if (Ember.isEmpty(controller.get('reCaptchaFlag')) && Ember.isEmpty(controller.get('recaptchaResult'))) {
          controller.set('errorMessage', 'Please fufill the reCaptcha');
          /*setTimeout(function(){
              controller.set('errorMessage', null);
          }, 2000);*/

          flag++;
          return;
        } else {
          if (controller.get('reCaptchaFlag')) {
            if (!controller.get('recaptchaResult')) {
              controller.set('errorMessage', 'Invalid Recaptcha Verification');
              /*setTimeout(function(){
                  controller.set('errorMessage', null);
              }, 2000);*/

              flag++;
              return;
            }
          } else {
            controller.set('errorMessage', 'Please wait until reCaptcha is verified');
            /*setTimeout(function(){
                controller.set('errorMessage', null);
            }, 2000);*/

            flag++;
            return;
          }
        }

        if (flag === 0) {
          controller.set("errorMessage", null);
          controller.set("showLoading", true);
          email = email.toLowerCase().trim();
          var axiosConfig = {
            method: "POST",
            data: {
              email: email
            },
            url: "".concat(this.store.adapterFor("application").get("host"), "/api/v1/resetpasses/requestResetPassword")
          };
          (0, _axios.default)(axiosConfig).catch(function (err) {
            console.log("catch(err)");
            console.log(err);

            if (err && err.response) {
              controller.set("errorMessage", err.response.data.message);
            } else {
              controller.set("errorMessage", "Server is down");
            }

            controller.set("showLoading", false);
          }).then(function (response) {
            console.log("then(response)");
            console.log(response);

            if (response && response.status == 200) {
              controller.set("successMessage", response.data.message);
              setTimeout(function () {
                _this.transitionTo("start");
              }, 3000);
            }
          });
        } else {
          controller.set("showLoading", false);
        }
      }
    }
  });

  _exports.default = _default;
});