define("client/pods/components/w3o-users-roles/form-user/city/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1MDbJCcz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"loadingPostalcode\",\"city\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"skeleton\"],[8],[0,\" \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"skeleton\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"for\",\"city\"],[8],[0,\"City \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"cityIsEmpty\"],[8],[0,\"City cannot be empty\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"cityForm\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphCity\"],[8],[9],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"value\"],[\"city\",\"Enter City\",\"form-control\",[24,[\"model\",\"city\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/city/template.hbs"
    }
  });

  _exports.default = _default;
});