define("client/pods/components/w3office-forms/component-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QYMM43xP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"isSkeleton\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[24,[\"field\",\"label\"]],false],[0,\" \\n\"],[4,\"if\",[[24,[\"field\",\"required\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"requiredMessage\"],[8],[0,\"*\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"field\",\"description\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[11,\"data-tip\",[24,[\"field\",\"description\"]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-question-circle\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/component-label/template.hbs"
    }
  });

  _exports.default = _default;
});