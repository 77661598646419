define("client/pods/components/w3o-users-roles/login-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6FpLZ4+D",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"form\",false],[12,\"class\",\"form-inline\"],[3,\"action\",[[23,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group form-group-sm\"],[8],[0,\"\\n\\t    \"],[7,\"label\",true],[10,\"class\",\"sr-only\"],[10,\"for\",\"identification\"],[8],[0,\"Email address\"],[9],[0,\"\\n\\t    \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"id\",\"placeholder\",\"value\"],[\"email\",\"form-control\",\"identification\",\"Email\",[24,[\"identification\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group form-group-sm\"],[8],[0,\"\\n\\t    \"],[7,\"label\",true],[10,\"class\",\"sr-only\"],[10,\"for\",\"password\"],[8],[0,\"Password\"],[9],[0,\"\\n\\t    \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"id\",\"placeholder\",\"value\"],[\"password\",\"form-control\",\"password\",\"Password\",[24,[\"password\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-secondary btn-sm\"],[10,\"title\",\"Log In\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t  \\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-arrow-right\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"register\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-secondary btn-sm\"],[10,\"title\",\"Sign Up\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t  \\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-arrow-up\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/login-bar/template.hbs"
    }
  });

  _exports.default = _default;
});