define("client/pods/components/w3office-forms/standard-components/dropdown-list/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    isLoadFieldFilter: Ember.computed('field.loadFieldFilter', function () {
      return this.get('field').get('loadFieldFilter');
    }),
    isLoadFieldContents: Ember.computed('field.loadFieldContents', function () {
      return this.get('field').get('loadFieldContents');
    }),
    isSkeleton: Ember.computed('isLoadFieldContents', 'isLoadFieldFilter', function () {
      if (this.get('isLoadFieldContents') || this.get('isLoadFieldFilter')) {
        return true;
      } else {
        return false;
      }
    }),
    containerLabel: Ember.computed('field.containerLabel', function () {
      return this.get('field').get('containerLabel');
    }),

    /*
    	Populate and filter dropdown on Initialization
    	Set Field to show component or not based on field config
    */
    init: function init() {
      this._super();

      this.get('field').setProperties({
        loadFieldFilter: true,
        loadFieldContents: true
      });

      if (!this.get('authoring')) {
        if (this.get('field').get('hasFieldLogicOnLoad')) {//Skip populateFieldFilter()
        } else {
          this.fieldActionGateway(this, {
            actionName: 'getFieldGroupContent'
          });
        }

        this.set('containerValue', this.get('field').get('entryContainer'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (['view', 'delete'].includes(this.get('environment'))) {
        if (!Ember.isEmpty(this.get('field').get('entryContainer'))) {
          this.fieldActionGateway(this, {
            actionName: 'findEntryObject'
          });
        } else {
          this.set('field.containerLabel', null);
        }
      } else {
        this.set('containerValue', this.get('field').get('entryContainer'));
      }
    },
    actions: {
      mutateEntry: function mutateEntry(val) {
        this.setEntryContainer(this.get('field'), val);
      }
    }
  });

  _exports.default = _default;
});