define("client/pods/users-roles/role-manager/app/access/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/role-manager-app"], function (_exports, _jquery, _checkUserRole, _roleManagerApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _roleManagerApp.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render({
        outlet: 'access'
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)("#access").addClass('active');
        (0, _jquery.default)("#access-tab").addClass('active show');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)('#assign').removeClass('active');
        (0, _jquery.default)('#role').removeClass('active');
        (0, _jquery.default)('#route').removeClass('active');
        (0, _jquery.default)('#app-access').removeClass('active');
        (0, _jquery.default)('#assign-tab').removeClass('active show');
        (0, _jquery.default)('#role-tab').removeClass('active show');
        (0, _jquery.default)('#route-tab').removeClass('active show');
        (0, _jquery.default)('#app-access-tab').removeClass('active show');
      });
    },
    actions: {
      setRole: function setRole(params) {
        this.setRole(params);
      },
      getRoutes: function getRoutes(params) {
        this.getRoutes(params);
      },
      getChildRoutes: function getChildRoutes(params) {
        this.getChildRoutes(params);
      },
      changeLimit: function changeLimit() {
        this.changeLimit();
      },
      prevList: function prevList(type, route) {
        this.prevList(type, route);
      },
      nextList: function nextList(type, route) {
        this.nextList(type, route);
      },
      finalize: function finalize(model) {
        var self = this;
        var controller = this.get('controller');
        var role = this.get('roleId');
        controller.set("grantAccessLoading", true);

        if (!model.get('checked')) {
          _jquery.default.ajax({
            type: "POST",
            data: {
              route: model.id,
              role: role
            },
            url: self.store.adapterFor('application').get('namespace') + '/allowedroutes/grantAccess',
            success: function success(response) {
              model.set('checked', true);
              controller.get('routeChecker').push(model.id.toString());
              controller.set("grantAccessLoading", false);
            },
            error: function error(xhr, status, _error) {
              console.log('Error ' + _error);
            }
          });
        } else {
          _jquery.default.ajax({
            type: "POST",
            data: {
              route: model.id,
              role: role
            },
            url: self.store.adapterFor('application').get('namespace') + '/allowedroutes/revokeAccess',
            success: function success(response) {
              model.set('checked', false);
              controller.get('routeChecker').splice(controller.get('routeChecker').indexOf(model.id.toString()), 1);
              controller.set("grantAccessLoading", false);
            },
            error: function error(xhr, status, _error2) {
              console.log('Error ' + _error2);
            }
          });
        }
      },
      goTo: function goTo(param) {
        this.goTo(param);
      },
      selectAll: function selectAll(type, allowedRoute) {
        this.selectAll(type, allowedRoute);
      },
      deselectAll: function deselectAll(type, allowedRoute) {
        this.deselectAll(type, allowedRoute);
      }
    }
  });

  _exports.default = _default;
});