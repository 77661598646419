define("client/pods/components/w3office-forms/bootstrap-table-row-audit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var columns = [];
      var recordChangedColumns = this.get("auditColumnsChanged")[this.get("index")];
      this.get("auditColumns").forEach(function (column) {
        var tdObject = {
          value: _this.get("record")[column.columnName]
        };

        if (recordChangedColumns && recordChangedColumns.indexOf(column.columnName) > -1) {
          tdObject.isChanged = true;
        }

        columns.push(tdObject);
      });
      this.set("columns", columns);
    }
  });

  _exports.default = _default;
});