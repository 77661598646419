define("client/pods/users-roles/users/user/route", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-users-roles/invalidate-session"], function (_exports, _jquery, _applicationRouteMixin, _authenticatedRouteMixin, _invalidateSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  / Module Name:
  / app/pods/users/user/route
  */
  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _invalidateSession.default, {
    actionList: null,
    setActions: Ember.A(),
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      var Variable = Ember.Object.extend({
        action: null,
        varName: null,
        status: false
      });
      var actions = [Variable.create({
        action: 'Set Status',
        varName: 'setStatus',
        status: false
      }), Variable.create({
        action: 'Reset Password & Question',
        varName: 'canReset',
        status: false
      })];
      this.set('actionList', actions);

      this._super();
    },
    // return model user based on user_id
    model: function model() {
      return this.store.findRecord('user', this.get('authObject.data.user.id'));
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('countryList', this.store.query('country', {
        sort: 'nicename ASC'
      }));
      controller.set('isEditing', false);
      controller.set('disabledSaveButton', false);
      controller.set('secretQuestionModel', this.store.findAll('secretquestion'));
      controller.set('oldquestion', model.get('secretquestion.content.question'));
      controller.set('setStatus', false);
      controller.set('canReset', false);
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'setStatus') {
          controller.set('setStatus', action.get('status'));
        }

        if (action.get('varName') === 'canReset') {
          controller.set('canReset', action.get('status'));
        }
      });
      controller.set('message', null);
      controller.set('disabled', true);

      _jquery.default.ajax({
        url: "/api/v1/users/getUserGroupRoleApp/".concat(model.get("id")),
        type: 'GET'
      }).then(function (response) {
        controller.set('userDetail', response.user);
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();

      var controller = this.get('controller');
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'setStatus') {
          controller.set('setStatus', action.get('status'));
        }

        if (action.get('varName') === 'canReset') {
          controller.set('canReset', action.get('status'));
        }
      });
    },
    generateRandomString: function generateRandomString() {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 25; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    },
    actions: {
      /*
          Save the status of the user
      */
      changeStatus: function changeStatus(model) {
        var controller = this.get('controller');
        controller.set('message', null);
        model.save().then(function () {
          controller.set('message', 'Status Changed!');
        });
      },

      /*
          Update address info when the postal code is filled
      */
      getInfo: function getInfo(param) {
        var self = this;
        var controller = this.get('controller');
        controller.set('model.state', '-');

        _jquery.default.ajax({
          type: "POST",
          data: {
            sourceId: param
          },
          url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressByPostalCodeFromGoThere',
          success: function success(response) {
            controller.set('model.address', response.value);
          },
          error: function error(xhr, status, _error) {
            console.log('Error ' + _error);
          }
        });

        _jquery.default.ajax({
          type: "POST",
          data: {
            sourceId: param
          },
          url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressLineByPostalCodeFromGoThere',
          success: function success(response) {
            controller.set('model.address1', response.value);
          },
          error: function error(xhr, status, _error2) {
            console.log('Error ' + _error2);
          }
        });

        _jquery.default.ajax({
          type: "POST",
          data: {
            sourceId: param
          },
          url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameByPostalCodeFromGoThere',
          success: function success(response) {
            controller.set('model.city', response.value);
          },
          error: function error(xhr, status, _error3) {
            console.log('Error ' + _error3);
          }
        });

        _jquery.default.ajax({
          type: "POST",
          data: {
            sourceId: param
          },
          url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameCodeByPostalCodeFromGoThere',
          success: function success(response) {
            self.store.queryRecord('country', {
              iso: response.value
            }).then(function (country) {
              controller.set('model.country', country);
            });
          },
          error: function error(xhr, status, _error4) {
            console.log('Error ' + _error4);
          }
        });
      },

      /*
          Save user data
      */
      editUser: function editUser(user) {
        var controller = this.get('controller');
        var self = this;
        user.set('email', user.get('email').toLowerCase());
        user.set('password', '');
        controller.set('message', null);
        user.save().then(function () {
          controller.set('isEditing', false);
          controller.set('disabledSaveButton', false);
          controller.set('disabled', true);
          controller.set('isUploading', false);
          controller.set('profilepic', '');
          controller.set('dataURI', '');
          controller.set('message', 'Successfully edit profile!');
          self.get('authObject').set('data.user.firstName', user.get('firstName'));
          self.get('authObject').set('data.user.lastName', user.get('lastName'));
          self.get('authObject').set('data.user.profilePicture', user.get('profilePicture'));
        });
      },

      /*
          Upload Image to server then save user data
      */
      uploadImage: function uploadImage(user) {
        var self = this;
        var controller = this.get('controller');
        var file = controller.get('profilepic');
        var objData = {
          email: user.get('email')
        };

        if (Ember.isEmpty(user.get('profilePicture')) === false) {
          objData.oldProfilePicture = user.get('profilePicture');
        }

        var headers = {};
        headers['Authorization'] = "Bearer ".concat(this.get('authObject.data.authenticated.access_token'));
        file.upload('/api/v1/uploads/profilepicMobile', {
          headers: headers,
          data: objData
        }).then(function (response) {
          user.set('profilePicture', response.body.path);
          self.send('editUser', user);
        });
      },

      /*
          Acts as validator for save edit
          If user upload profile pic, execute uploadImage action
          else execute editUser action
      */
      saveEdit: function saveEdit(user) {
        var controller = this.get('controller');
        controller.set('disabledSaveButton', true);

        if (controller.get('isUploading')) {
          this.send('uploadImage', user);
        } else {
          user.set('password', '');
          this.send('editUser', user);
        }
      },

      /*
          Save password using api call to server
      */
      changePassword: function changePassword(model) {
        var self = this;
        var controller = this.get('controller');
        controller.set('message', null);

        _jquery.default.ajax({
          url: '/api/v1/users/reset',
          type: 'POST',
          data: {
            id: model.id,
            siteAdmin: controller.get('canReset'),
            email: model.get('email').toLowerCase(),
            sendPassToEmail: model.get('sendPassToEmail'),
            oldpassword: model.get('oldpassword'),
            password: model.get('newpassword')
          },
          dataType: 'json',
          contentType: 'application/x-www-form-urlencoded'
        }).then(function (response) {
          model.set('oldpassword', null);
          model.set('newpassword', null);
          model.set('passwordRepeat', null);
          (0, _jquery.default)('#successNotifPassword').removeClass('d-none');
          controller.set('message', 'Password successfully changed!');
          setTimeout(function () {
            (0, _jquery.default)('#changePasswordModal').modal('hide');
            (0, _jquery.default)('#successNotifPassword').addClass('d-none');
            model.set('incorrectPassword', '');
            model.set('sendPassToEmail', false);

            if (self.get('authObject.data.user.id') == self.get('model.id')) {
              self.invalidateSession();
            }
          }, 1000);
        }, function (xhr, status, error) {
          (0, _jquery.default)("#oldForm").addClass("has-error");
          (0, _jquery.default)('#glyphOld').removeClass('d-none');
          (0, _jquery.default)('#oldError2').removeClass('d-none');
          (0, _jquery.default)('#oldError').addClass('d-none');
          controller.set('message', 'Current Password is incorrect!');
          model.set('incorrectPassword', model.get('oldpassword'));
          model.set('oldpassword', null);
        });
      },
      changeQuestion: function changeQuestion(model) {
        var self = this;
        var controller = this.get('controller');
        controller.set('message', null);

        if (controller.get('canReset')) {
          _jquery.default.ajax({
            url: '/api/v1/users/resetans',
            type: 'POST',
            data: {
              id: model.id,
              siteAdmin: controller.get('canReset'),
              email: model.get('email').toLowerCase(),
              sendAnswerToEmail: model.get('sendAnswerToEmail'),
              newanswer: model.get('newAnswer')
            },
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded'
          }).then(function (response) {
            _jquery.default.ajax({
              url: '/api/v1/users/changequestion',
              type: 'POST',
              data: {
                id: model.id,
                secretquestion: model.get('newQuestion.id')
              },
              dataType: 'json',
              contentType: 'application/x-www-form-urlencoded'
            }).then(function (response) {
              model.set('newQuestion', null);
              model.set('newAnswer', null);
              model.set('answerRepeat', null);
              model.set('sendAnswerToEmail', false);
              controller.set('message', 'Security Question successfully changed!');
              (0, _jquery.default)('#successNotifQuestion').removeClass('d-none');
              setTimeout(function () {
                (0, _jquery.default)('#changePasswordModal').modal('hide');
                (0, _jquery.default)('#successNotifQuestion').addClass('d-none');
                self.set('incorrectAnswer', '');
                model.set('sendAnswerToEmail', false);
              }, 1000);
            }, function (xhr, status, error) {});
          }, function (xhr, status, error) {
            (0, _jquery.default)("#oldanswerForm").addClass("has-error");
            (0, _jquery.default)('#glyphOldanswer').removeClass('d-none');
            (0, _jquery.default)('#oldanswerError2').removeClass('d-none');
            (0, _jquery.default)('#oldanswerError').addClass('d-none');
            self.set('incorrectAnswer', model.get('oldanswer'));
            model.set('oldanswer', null);
          });
        } else {
          _jquery.default.ajax({
            url: '/api/v1/users/checkpass',
            type: 'POST',
            data: {
              id: model.id,
              password: model.get('currentPassword')
            },
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded'
          }).then(function (response) {
            console.log('password OK');

            _jquery.default.ajax({
              url: '/api/v1/users/resetans',
              type: 'POST',
              data: {
                id: model.id,
                siteAdmin: controller.get('canReset'),
                email: model.get('email').toLowerCase(),
                sendAnswerToEmail: model.get('sendAnswerToEmail'),
                newanswer: model.get('newAnswer')
              },
              dataType: 'json',
              contentType: 'application/x-www-form-urlencoded'
            }).then(function (response) {
              _jquery.default.ajax({
                url: '/api/v1/users/changequestion',
                type: 'POST',
                data: {
                  id: model.id,
                  secretquestion: model.get('newQuestion.id')
                },
                dataType: 'json',
                contentType: 'application/x-www-form-urlencoded'
              }).then(function (response) {
                model.set('currentPassword', null);
                model.set('newQuestion', null);
                model.set('newAnswer', null);
                model.set('answerRepeat', null);
                model.set('sendAnswerToEmail', false);
                controller.set('message', 'Security Question successfully changed!');
                (0, _jquery.default)('#successNotifQuestion').removeClass('d-none');
                setTimeout(function () {
                  (0, _jquery.default)('#changePasswordModal').modal('hide');
                  (0, _jquery.default)('#successNotifQuestion').addClass('d-none');
                  self.set('incorrectAnswer', '');
                  model.set('sendAnswerToEmail', false);
                }, 1000);
              }, function (xhr, status, error) {});
            }, function (xhr, status, error) {
              controller.set('message', error);
            });
          }, function (xhr, status, error) {
            console.log(xhr);
            (0, _jquery.default)("#oldanswerForm").addClass("has-error");
            (0, _jquery.default)('#glyphOldanswer').removeClass('d-none');
            (0, _jquery.default)('#oldanswerError2').removeClass('d-none');
            (0, _jquery.default)('#oldanswerError').addClass('d-none');
            self.set('incorrectAnswer', model.get('oldanswer'));
            model.set('oldanswer', null);
          });
        }
      },
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      }
    }
  });

  _exports.default = _default;
});