define("client/pods/components/w3office-forms/advanced-components/color-picker/component-field/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    init: function init() {
      this._super();

      this.set('R', null);
      this.set('G', null);
      this.set('B', null);
    },
    generateRGB: function generateRGB(color) {
      this.set('R', parseInt(color.slice(1, 3), 16));
      this.set('G', parseInt(color.slice(3, 5), 16));
      this.set('B', parseInt(color.slice(5, 7), 16));
    },
    actions: {
      updateHex: function updateHex() {
        var R = this.get('R') ? parseInt(this.get('R')).toString(16) : '00';

        if (R.length < 2) {
          R = "0".concat(R);
        }

        R = R.toUpperCase();
        var G = this.get('G') ? parseInt(this.get('G')).toString(16) : '00';

        if (G.length < 2) {
          G = "0".concat(G);
        }

        G = G.toUpperCase();
        var B = this.get('B') ? parseInt(this.get('B')).toString(16) : '00';

        if (B.length < 2) {
          B = "0".concat(B);
        }

        B = B.toUpperCase();
        this.set('inputHex', "#".concat(R).concat(G).concat(B));
      },
      selectColor: function selectColor(color) {
        this.set('inputHex', color);
        this.generateRGB(color);
      },
      setColor: function setColor() {
        this.set('selectedColor', this.get('inputHex'));
        this.setEntryContainer(this.get('field'), this.get('inputHex'));
      }
    }
  });

  _exports.default = _default;
});