define("client/models/notification", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    notificationHTML: (0, _attr.default)('string'),
    notificationContent: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    shown: (0, _attr.default)('boolean'),
    moduleId: (0, _attr.default)('number'),
    moduleTitle: (0, _attr.default)('string'),
    moduleType: (0, _attr.default)('string'),
    moduleaction: (0, _attr.default)('string'),
    targetUser: (0, _attr.default)('number'),
    targetGroup: (0, _attr.default)('number'),
    user: (0, _attr.default)('number'),
    isNew: (0, _attr.default)('boolean'),

    /*
       *   NotificationR Regarding Email
       */
    subjectEmail: (0, _attr.default)('string'),
    fromEmail: (0, _attr.default)('string'),
    toEmail: (0, _attr.default)('string'),
    ccEmail: (0, _attr.default)('string'),
    bccEmail: (0, _attr.default)('string'),
    replyToEmail: (0, _attr.default)('string'),

    /*
    *   Notification Regarding SMS
    */
    phoneNumber: (0, _attr.default)('string'),

    /*
    *   Notification Type Flag Execution
    */
    isShowDashboard: (0, _attr.default)('boolean'),
    isSendEmail: (0, _attr.default)('boolean'),
    isSendSMS: (0, _attr.default)('boolean'),

    /*
    *   Resource Access Related
    */
    companynum: (0, _relationships.belongsTo)("company", {
      async: true
    }),
    organizationnum: (0, _relationships.belongsTo)("organization", {
      async: true
    })
  });

  _exports.default = _default;
});