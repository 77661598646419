define("client/pods/components/w3o-users-roles/theme-form/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      if (this.get('model').borderStyle === "ALL") {
        (0, _jquery.default)("#input-preview-1").removeClass("simpleInputText");
        (0, _jquery.default)("#input-preview-2").removeClass("simpleInputText");
      }

      (0, _jquery.default)("#input-preview-1").css({
        "color": this.get('model').valueFontColor
      });
      (0, _jquery.default)("#input-preview-2").css({
        "color": this.get('model').valueFontColor
      });
    },
    actions: {
      changeBorderStyle: function changeBorderStyle(borderStyle) {
        this.get('model').set('borderStyle', borderStyle);

        if (this.get('model').borderStyle === "ALL") {
          (0, _jquery.default)("#input-preview-1").removeClass("simpleInputText");
          (0, _jquery.default)("#input-preview-2").removeClass("simpleInputText");
        } else {
          (0, _jquery.default)("#input-preview-1").addClass("simpleInputText");
          (0, _jquery.default)("#input-preview-2").addClass("simpleInputText");
        }
      },
      changeValueFontColor: function changeValueFontColor(valueFontColor) {
        this.get('model').set('valueFontColor', valueFontColor);
        (0, _jquery.default)("#input-preview-1").css({
          "color": this.get('model').valueFontColor
        });
        (0, _jquery.default)("#input-preview-2").css({
          "color": this.get('model').valueFontColor
        });
      },
      changeValueFontSize: function changeValueFontSize(valueFontSize) {
        this.get('model').set('valueFontSize', valueFontSize);
        (0, _jquery.default)("#input-preview-1").css({
          "font-size": this.get('model').valueFontSize
        });
        (0, _jquery.default)("#input-preview-2").css({
          "font-size": this.get('model').valueFontSize
        });
      },
      togglePreview: function togglePreview() {
        this.togglePreview();
      },
      saveTheme: function saveTheme() {
        this.saveTheme();
      }
    }
  });

  _exports.default = _default;
});