define("client/pods/components/report-generator/calculate-tab/expression-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      clearExpression: function clearExpression() {
        this.clearExpression();
      },
      addExpression: function addExpression() {
        this.addExpression();
      }
    }
  });

  _exports.default = _default;
});