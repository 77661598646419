define("client/models/allowedroute", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // relationships
    roleroutes: _emberData.default.hasMany('roleroute', {
      async: true
    }),
    application: _emberData.default.belongsTo('application', {
      async: true
    }),
    parent: _emberData.default.belongsTo('allowedroute', {
      async: true,
      inverse: 'children'
    }),
    children: _emberData.default.hasMany('allowedroute', {
      async: true,
      inverse: 'parent'
    }),
    // attributes
    routeName: _emberData.default.attr('string'),
    pageName: _emberData.default.attr('string'),
    dynamicValue: _emberData.default.attr('string')
  });

  _exports.default = _default;
});