define("client/pods/components/dashboard-builder/dabkpiconfig-paging/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y0216l38",
    "block": "{\"symbols\":[\"entry\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0 card\"],[10,\"style\",\"margin-left:0px;\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-md-12\"],[10,\"style\",\"padding: 15px 15px 0px 15px;\"],[8],[0,\" \\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"table-responsive col-xs-12 col-md-12 px-0\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"table\",true],[10,\"class\",\"table table-hover table-striped\"],[10,\"style\",\"text-align: left;\"],[8],[0,\"\\n\\t\\t\\t  \"],[7,\"thead\",true],[8],[0,\"\\n\\t\\t\\t    \"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t    \\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t    \\t\\tActions\\n\\t\\t\\t    \\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t    \\t\\tQuery\\n\\t\\t\\t    \\t\"],[9],[0,\"\\n\\t\\t\\t    \"],[9],[0,\"\\n\\t\\t\\t  \"],[9],[0,\"\\n\\t\\t\\t  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"modelCon\"]]],null,{\"statements\":[[0,\"\\t\\t\\t    \\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t    \\t \\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t    \\t \\t\\t\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"dashboard-builder.dabkpi.config.edit\",[23,1,[\"id\"]]]],{\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"title\",\"Edit\"],[10,\"style\",\"margin-right: 2px;\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-pencil-alt\"],[8],[9],[9]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t    \\t \\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[23,1,[\"whatsql\"]],false],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t    \\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\\t  \"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dashboard-builder/dabkpiconfig-paging/template.hbs"
    }
  });

  _exports.default = _default;
});