define("client/pods/components/report-generator/content-tab/selected-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    componentmodel: null,
    tablemodel: null,
    filterModel: null,
    filterHeader: 'columnlabel',
    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;

      if (!Ember.isEmpty(this.get('astable'))) {
        var tempModel = Ember.A();
        this.get('models').forEach(function (model) {
          if (model.get('astable') === self.get('astable')) {
            tempModel.push(model);
          }
        });
        this.set('componentmodel', tempModel);
      } else {
        this.set('componentmodel', this.get('models'));
      }
    },
    actions: {
      filterData: function filterData() {
        var self = this;
        var tempModelA = Ember.A();
        var tempModelB = Ember.A();

        if (!Ember.isEmpty(this.get('astable'))) {
          this.get('componentmodel').forEach(function (model) {
            if (model.get('astable').includes(self.get('astable'))) {
              tempModelA.push(model);
            }
          });
          this.set('tablemodel', tempModelA);
          this.get('tablemodel').forEach(function (model) {
            if (model.get(self.get('filterHeader')).includes(self.get('filterValue'))) {
              tempModelB.push(model);
            }
          });
          this.set('filterModel', tempModelB);
        } else {
          this.get('componentmodel').forEach(function (model) {
            if (model.get(self.get('filterHeader')).includes(self.get('filterValue'))) {
              tempModelB.push(model);
            }
          });
          this.set('filterModel', tempModelB);
        }
      }
    }
  });

  _exports.default = _default;
});