define("client/pods/components/dashboard-builder/newedit-dabtabaccess/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isNew: true,
    KPIChecked: false,
    ReportsChecked: false,
    MapChecked: false,
    MessagesChecked: false,
    init: function init() {
      if (this.get("type") === "New") {
        this.set("isNew", true);
      } else if (this.get('type') === 'Edit') {
        this.set("isNew", false);
      }

      if (this.get('tabCon').indexOf('D2') > -1) {
        this.set('D2Checked', true);
      }

      if (this.get('tabCon').indexOf('KPI') > -1) {
        this.set('KPIChecked', true);
      }

      if (this.get('tabCon').indexOf('Reports') > -1) {
        this.set('ReportsChecked', true);
      }

      if (this.get('tabCon').indexOf('Map') > -1) {
        this.set('MapChecked', true);
      }

      if (this.get('tabCon').indexOf('Messages') > -1) {
        this.set('MessagesChecked', true);
      }

      return this._super();
    },
    buttonName: Ember.computed('type', function () {
      if (this.get('type') === 'New') {
        return 'Add';
      } else if (this.get('type') === 'Edit') {
        return 'Update';
      }
    }),
    readOnly: Ember.computed('type', function () {
      if (this.get('type') === 'View') {
        return true;
      } else {
        return false;
      }
    }),
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#titleForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#titleForm").removeClass("has-error");
          (0, _jquery.default)("#titleIsEmpty").addClass("hidden");
        }
      });
      (0, _jquery.default)('#descriptionForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#descriptionForm").removeClass("has-error");
          (0, _jquery.default)("#descriptionIsEmpty").addClass("hidden");
        }
      });
    },
    actions: {
      changeTabValue: function changeTabValue(param) {
        if (this.get('tabCon') && this.get('tabCon').indexOf(param) > -1) {
          this.get('tabCon').splice(this.get('tabCon').indexOf(param), 1);
          this.set(param + 'Checked', false);
        } else {
          this.get('tabCon').push(param);
          this.set(param + 'Checked', true);
        }
      },
      saveDabtabaccess: function saveDabtabaccess() {
        var errorCount = 0;
        var newDabtabaccess = this.get('objDabtabaccess');

        if (newDabtabaccess.get('showFirst') === '') {
          (0, _jquery.default)("#showFirstForm").addClass("has-error");
          (0, _jquery.default)("#showFirstIsEmpty").removeClass("hidden");
          errorCount++;
        }

        if (errorCount === 0) {
          this.actionToRoute();
        }
      }
    }
  });

  _exports.default = _default;
});