define("client/pods/components/w3office-forms/bootstrap-card-container/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();
    },
    actions: {
      changeEntryLimit: function changeEntryLimit(limit) {
        this.limitAction(limit);
      },
      changeGroup: function changeGroup(group) {
        this.changeGroup(group);
      },
      moveEntries: function moveEntries(direction) {
        (0, _jquery.default)("html, body").animate({
          scrollTop: 0
        }, 500); // scroll page to top

        this.moveAction(direction);
      },
      actionToView: function actionToView(identifier) {
        this.actionToView(identifier);
      }
    }
  });

  _exports.default = _default;
});