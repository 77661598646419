define("client/pods/components/w3office-forms/flash-message/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    	Determine if message a success message or an alert
    	By Default is set to true
    */
    succeed: true,

    /*
    	Fadeout after component initialized
    	Can be compounded with a wrapper template validation
    */
    init: function init() {
      this._super();

      console.log(this.get('status'));
      (0, _jquery.default)(".flash-messsage").hide();

      if (this.get('status')) {
        Ember.run.later(this, function () {
          (0, _jquery.default)(".flash-messsage").fadeOut(1000);
        }, 3500);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      console.log(this.get('status'));
      (0, _jquery.default)(".flash-messsage").hide();

      if (this.get('status')) {
        Ember.run.later(this, function () {
          (0, _jquery.default)(".flash-messsage").fadeOut(1000);
        }, 3500);
      }
    }
  });

  _exports.default = _default;
});