define("client/models/fieldgroupcontent", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    check: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    addOther: (0, _attr.default)('boolean'),
    isActive: (0, _attr.default)('boolean'),
    dataType: (0, _attr.default)('string'),
    field: (0, _relationships.belongsTo)('field', {
      async: true
    }),
    parentCol: (0, _relationships.belongsTo)('fieldgroupcontent', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    childrenCol: (0, _relationships.hasMany)('fieldgroupcontent', {
      inverse: 'parentCol',
      async: true
    })
  });

  _exports.default = _default;
});