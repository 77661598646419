define("client/pods/process-manager/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    activate: function activate() {
      this.transitionTo('process-manager.inbox');
    }
  });

  _exports.default = _default;
});