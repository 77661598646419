define("client/pods/menu-builder/child/subchild/edit/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/menu-builder/menu-crud"], function (_exports, _checkUserRole, _menuCrud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _menuCrud.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(param) {
      return this.store.findRecord("menu", param.subchild_id);
    },
    authObject: Ember.inject.service('session'),
    setupController: function setupController(controller, model) {
      var menunum = this.modelFor('menu-builder.child');
      var menuchildnum = this.modelFor('menu-builder.child.subchild');
      controller.set("menuid", menunum.id);
      controller.set("menutitle", menunum.get('title'));
      controller.set("childid", menuchildnum.id);
      controller.set("childtitle", menuchildnum.get('title'));
      controller.set("subchildid", model.id);
      controller.set("subchildtitle", model.get('title'));
      controller.set('model', model);
      controller.set('menuList', this.store.query('menu', {
        appID: model.get('appID')
      }));
      controller.set('currentRoute', 'menu-builder.child.subchild');
      this.getFormLinks(model.get('appID'));
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        this.get('currentModel').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});