define("client/pods/components/report-generator/sidebar-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var el = document.querySelector('.customScrollbar');
      Ps.initialize(el);
    },
    actions: {
      selectReport: function selectReport(report) {
        this.selectReport(report);
      },
      createReport: function createReport() {
        this.createReport();
      }
    }
  });

  _exports.default = _default;
});