define("client/pods/components/w3office-forms/collection-components/full-address/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    sAddress1: "",
    sAddress2: "",
    adCity: "",
    adState: "",
    postalCode: "",
    adCountry: "",
    didReceiveAttrs: function didReceiveAttrs() {
      //Append to error message missing values
      var emptyValues = " ";
      emptyValues = emptyValues.concat(Ember.isEmpty(this.get('sAddress1')) ? 'Street Address 1, ' : " ");
      emptyValues = emptyValues.concat(Ember.isEmpty(this.get('sAddress2')) ? 'Street Address 2, ' : " ");
      emptyValues = emptyValues.concat(Ember.isEmpty(this.get('adCity')) ? 'City, ' : " ");
      emptyValues = emptyValues.concat(Ember.isEmpty(this.get('adState')) ? 'State, ' : " ");
      emptyValues = emptyValues.concat(Ember.isEmpty(this.get('postalCode')) ? 'Postal Code, ' : " ");
      emptyValues = emptyValues.concat(Ember.isEmpty(this.get('adCountry')) ? 'Country, ' : " ");
      this.get('field').set('errorMessage', emptyValues + 'are required');
      this.get('field').set('entryContainer', "");
    },
    requiredObserver: Ember.observer('field.required', function () {
      this.get('field').save();
    }),
    entryContainerObserver: Ember.observer('field.entryContainer', function () {
      //observe changes on entrycontainer
      if (Ember.isEmpty(this.get('field').get('entryContainer')) && !this.get('field').get('isInvalid')) {
        this.set('sAddress1', "");
        this.set('sAddress2', "");
        this.set('adCity', "");
        this.set('adState', "");
        this.set('postalCode', "");
        this.set('adCountry', "");
      }
    }),
    actions: {
      checkAddress: function checkAddress() {
        if (this.get('field').get('required')) {
          this.get('field').set('errorMessage', ""); //Append to error message missing files

          var emptyValues = "";
          emptyValues = emptyValues.concat(Ember.isEmpty(this.get('sAddress1')) ? 'Street Address 1, ' : " ");
          emptyValues = emptyValues.concat(Ember.isEmpty(this.get('sAddress2')) ? 'Street Address 2, ' : " ");
          emptyValues = emptyValues.concat(Ember.isEmpty(this.get('adCity')) ? 'City, ' : " ");
          emptyValues = emptyValues.concat(Ember.isEmpty(this.get('adState')) ? 'State, ' : " ");
          emptyValues = emptyValues.concat(Ember.isEmpty(this.get('postalCode')) ? 'Postal Code, ' : " ");
          emptyValues = emptyValues.concat(Ember.isEmpty(this.get('adCountry')) ? 'Country, ' : " ");
          emptyValues = emptyValues.trim(); //Trigger error message

          if (!Ember.isEmpty(emptyValues)) {
            this.get('field').set('isInvalid', true);
            this.get('field').set('errorMessage', emptyValues + ' are required');
            this.get('field').set('entryContainer', "");
          } else {
            //Append fields as one value
            this.get('field').set('isInvalid', false);
            this.get('field').set('entryContainer', this.get('sAddress1').trim() + " " + this.get('sAddress2').trim() + " " + this.get('adCity').trim() + " " + this.get('adState').trim() + " " + this.get('postalCode').trim() + " " + this.get('adCountry').trim());
          }
        } else {
          //Append fields as one value
          this.get('field').set('entryContainer', this.get('sAddress1').trim() + " " + this.get('sAddress2').trim() + " " + this.get('adCity').trim() + " " + this.get('adState').trim() + " " + this.get('postalCode').trim() + " " + this.get('adCountry').trim());
        }
      }
    }
  });

  _exports.default = _default;
});