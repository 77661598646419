define("client/pods/users-roles/users/create/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/create-edit-user"], function (_exports, _checkUserRole, _createEditUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _createEditUser.default, {
    authObject: Ember.inject.service('session'),
    create: true,
    beforeModel: function beforeModel() {
      this._super();
    },
    model: function model() {
      return this.store.createRecord('user');
    },
    setupController: function setupController(controller, model) {
      this._super();

      controller.set('isCreate', true);
      controller.set('groupCon', null);
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    actions: {
      getInfo: function getInfo(param) {
        this.getInfo(param);
      },
      //inserting data to user model, and the data is respective to inputted data on template
      createUser: function createUser(model) {
        this.saveUser(model);
      },
      cancel: function cancel() {
        this.transitionTo('users-roles.users');
      }
    }
  });

  _exports.default = _default;
});