define("client/mixins/report-generator/report-common", ["exports", "jquery", "client/mixins/w3office-forms/check-resource-access"], function (_exports, _jquery, _checkResourceAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_checkResourceAccess.default, {
    setupController: function setupController(controller, model) {
      var self = this;

      if (self.controllerFor('application').get('appID') !== 'Report Generator' && self.get('routeName').includes('report-generator')) {
        self.controllerFor('application').set('appID', 'Report Generator');
        self.controllerFor('application').set('menus', [{
          'title': 'Create Report',
          'unique': 'createreport',
          'subunique': 'subcreatereport',
          'glyph': 'fa-plus',
          'theme': '#ffffff',
          'color': '#ffffff',
          'borderColor': '#ffffff',
          'link': 'report-generator'
        }, {
          'title': 'Ad Hoc Reports',
          'unique': 'adhocreports',
          'subunique': 'subadhocreports',
          'glyph': 'fa-signal',
          'theme': '#ffffff',
          'color': '#ffffff',
          'borderColor': '#ffffff',
          'children': []
        }]);
      } // var companyExist = !Ember.isEmpty(this.get('optmmSetting.companynum.id'));


      controller.set('companyExist', true);
      var conditionSelects = [{
        value: "EQUAL",
        text: "Equal"
      }, {
        value: "EQUALIGNORECASE",
        text: "Equal Ignore Case"
      }, {
        value: "GREATEROREQUAL",
        text: "Greater or Equal"
      }, {
        value: "GREATERTHAN",
        text: "Greater Than"
      }, {
        value: "LESSOREQUAL",
        text: "Less or Equal"
      }, {
        value: "LESSTHAN",
        text: "Less Than"
      }, {
        value: "LIKE",
        text: "Like"
      }, {
        value: "LIKEIGNORECASE",
        text: "Like Ignore Case"
      }, {
        value: "NOTEQUAL",
        text: "Not Equal"
      }, {
        value: "NOTLIKE",
        text: "Not Like"
      }, {
        value: "NOTLIKEIGNORECASE",
        text: "Not Like Ignore Case"
      }, {
        value: "STARTSWITH",
        text: "Starts With"
      }, {
        value: "ENDSWITH",
        text: "Ends With"
      }, {
        value: "ISNULL",
        text: "Is Null"
      }, {
        value: "ISNOTNULL",
        text: "Is not Null"
      }, {
        value: "ISTRUE",
        text: "Is True"
      }, {
        value: "ISFALSE",
        text: "Is False"
      }];
      controller.set('conditionSelects', conditionSelects);

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          self.controller.set('user', user);
        });
      }

      controller.set('companynum', this.get('optmmSetting.companynum'));
      controller.set('currentReport', null); //Define controller properties

      controller.set('model', model);
      controller.set('currentRoute', this.get('currentRoute'));
      controller.set('componentsShown', false);
      /*
      * 	Resource Access Related
      */

      controller.set('resourceAccessRestricted', false);
      controller.set('resourceAccessConditionPromise', Ember.RSVP.defer());
      controller.set('isLoadingResourceAccess', true);
      controller.set('modelName', 'genreport');
      this.populateResourceAccess();
      self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
        controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
        var conditionContainer = {};
        conditionContainer['where'] = {};
        conditionContainer.sort = 'id ASC';
        conditionContainer.where.isdelete = false;
        self.checkResourceAccessEntries(conditionContainer.where);
        controller.get('resourceAccessConditionPromise').promise.then(function (finish) {
          self.store.query('genreport', conditionContainer).then(function (results) {
            self.controller.set('reportconfiglist', results.toArray()); //Set variables for nav bar

            if (self.controllerFor('application').get('appID') == 'Report Generator') {
              self.controller.get('reportconfiglist').forEach(function (reportconfig) {
                var AdHocReportsChildren = self.controllerFor('application').get('menus').find(function (menu) {
                  return menu.title == 'Ad Hoc Reports';
                })['children'];
                AdHocReportsChildren.pushObject({
                  'title': reportconfig.reportname,
                  'link': 'report-generator.report',
                  'param': reportconfig.id
                });
              });
            }
          });
        });
      });
    },
    actions: {
      runReport: function runReport(report) {
        var self = this;
        var name = this.controller.get('user.firstName') + '\ ' + this.controller.get('user.lastName');
        var data = {
          "dabKpi": report.id,
          "generatedBy": name
        };
        var url = "".concat(this.store.adapterFor('application').get('serverHost'), "/api/v1/genreports/callApi");

        _jquery.default.ajax({
          url: url,
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          success: function success(result) {
            window.open(result);
          },
          error: function error(xhr, status, _error) {
            console.log(_error);
          }
        });
      }
    }
  });

  _exports.default = _default;
});