define("client/pods/dashboard-v2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rIC9dg7a",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[\"dashboard-v2/header-top\"],[[\"session\",\"invalidateSession\"],[[24,[\"session\"]],[28,\"route-action\",[\"invalidateSession\"],null]]]],false],[0,\" \\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/dashboard-v2/template.hbs"
    }
  });

  _exports.default = _default;
});