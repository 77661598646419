define("client/pods/report-viewer/index/route", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/report-generator/report-common"], function (_exports, _jquery, _applicationRouteMixin, _authenticatedRouteMixin, _checkUserRole, _reportCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _reportCommon.default, {
    authObject: Ember.inject.service('session'),
    //initialize session
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this; //Define controller properties

      controller.set('disableRun', false);
      controller.set('loadingData', false);
      controller.set('selectContents', Ember.A());
      controller.set('paramcollectionContents', Ember.A());
      controller.set('paramContents', Ember.A());
      controller.set('selectQuery', null);
      controller.set('paramcollectionQuery', null);
      controller.set('paramQuery', null);
      controller.set('selectedParamcollection', null);
      controller.set('paramLoading', false);
      controller.set('categoryValue', null);
      controller.set('subcategoryValue', null);
      controller.set('subcategoryContents', null);
      controller.set('filterValue', null);
      controller.set('isFiletype', false);
      controller.set('lastCategoryValue', null);
      var categoryQuery = this.store.query('genreportcategory', {
        isdelete: false,
        sort: 'id ASC'
      });
      categoryQuery.then(function (categoryContents) {
        self.controller.set('categoryContents', categoryContents.toArray());
      });
    },
    actions: {
      setFilter: function setFilter() {
        var self = this;
        var query = Ember.Object.create({});
        query.reopen({
          isdelete: false
        });

        if (!Ember.isEmpty(this.controller.get('categoryValue'))) {
          query.reopen({
            genreportcategorynum: this.controller.get('categoryValue.id')
          });

          if (this.controller.get('lastCategoryValue') === this.controller.get('categoryValue')) {
            if (!Ember.isEmpty(this.controller.get('subcategoryValue'))) {
              query.reopen({
                genreportsubcategorynum: this.controller.get('subcategoryValue.id')
              });
            }
          } else {
            this.controller.set('lastCategoryValue', this.controller.get('categoryValue'));
            this.controller.set('subcategoryValue', null);
            var subcategoryQuery = this.store.query('genreportsubcategory', {
              where: {
                genreportcategorynum: this.controller.get('categoryValue.id'),
                isdelete: false
              },
              sort: 'id ASC'
            });
            subcategoryQuery.then(function (subcategoryContents) {
              self.controller.set('subcategoryContents', subcategoryContents.toArray());
            });
          }
        }

        if (!Ember.isEmpty(this.controller.get('filterType'))) {
          if (this.controller.get('filterType') === 'reportname') {
            if (!Ember.isEmpty(this.controller.get('filterValue'))) {
              query.reopen({
                reportname: Ember.Object.create({
                  'like': '%' + this.controller.get('filterValue') + '%'
                })
              });
            }
          } else if (this.controller.get('filterType') === 'filetype') {
            if (!Ember.isEmpty(this.controller.get('filterValue'))) {
              query.reopen({
                filetype: this.controller.get('filterValue')
              });
            }
          }
        }

        var filterQuery = this.store.query('genreport', {
          where: JSON.stringify(query),
          sort: 'id ASC'
        });
        filterQuery.then(function (results) {
          self.controller.set('reportconfiglist', results.toArray());
        });
      },
      checkFilterType: function checkFilterType(value) {
        this.controller.set('filterValue', null);

        if (value === 'filetype') {
          this.controller.set('isFiletype', true);
        } else {
          this.controller.set('isFiletype', false);
        }
      },
      addParamcollection: function addParamcollection() {
        var self = this;
        self.controller.set('paramLoading', true);
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var newparamcollection = self.store.createRecord('genreportparamcollectionuser', {
            genreportnum: self.controller.get('currentReport'),
            createdBy: user,
            collectionname: "New Param Collection"
          });
          newparamcollection.save().then(function (paramcollection) {
            var paramQuery = self.store.query('genreportparamuser', {
              where: {
                genreportparamcollectionusernum: paramcollection.id,
                genreportnum: self.controller.get('currentReport.id'),
                isdelete: false
              },
              sort: 'id ASC'
            });
            paramQuery.then(function (paramContents) {
              self.controller.set('paramContents', paramContents.toArray());
              self.controller.get('paramcollectionContents').addObject(paramcollection);
              self.controller.set('selectedParamcollection', paramcollection);
              self.controller.set('paramLoading', false);
            });
          });
        });
      },
      deleteParamcollection: function deleteParamcollection() {
        var self = this;
        var promises = Ember.A();
        this.store.findRecord('genreportparamcollectionuser', this.controller.get('selectedParamcollection.id')).then(function (container) {
          self.store.query('genreportparamuser', {
            genreportparamcollectionusernum: container.id
          }).then(function (params) {
            params.forEach(function (param) {
              param.set('isdelete', true);
            });
            container.set('isdelete', true);
            params.forEach(function (param) {
              promises.push(param.save());
            });
            promises.push(container.save());
            Ember.RSVP.Promise.all(promises).then(function (result) {
              self.controller.get('paramcollectionContents').removeObject(container);
              self.controller.set('selectedParamcollection', null);
            });
          });
        });
      },
      openParamCollection: function openParamCollection(report) {
        var self = this;
        self.controller.set('currentReport', report);
        self.controller.set('selectQuery', self.store.query('genreportconfigselect', {
          where: {
            genreportnum: report.id,
            isdelete: false
          },
          sort: 'id ASC'
        }));
        self.controller.set('configQuery', self.store.query('genreportparamconfiguser', {
          where: {
            genreportnum: report.id,
            isdelete: false
          },
          sort: 'id ASC'
        }));
        self.controller.set('paramcollectionQuery', self.store.query('genreportparamcollectionuser', {
          where: {
            genreportnum: report.id,
            isdelete: false,
            createdBy: this.get('authObject.data.user.id')
          },
          sort: 'id ASC'
        }));
        self.controller.get('selectQuery').then(function (selectContents) {
          self.controller.get('configQuery').then(function (configContents) {
            self.controller.get('paramcollectionQuery').then(function (paramcollectionContents) {
              var selectArray = Ember.A();
              configContents.forEach(function (config) {
                selectContents.forEach(function (select) {
                  if (config.get('genreportconfigselectnum.id') === select.id) {
                    selectArray.push(select);
                  }
                });
              });
              self.controller.set('selectContents', selectArray);
              self.controller.set('paramcollectionContents', paramcollectionContents.toArray());
              (0, _jquery.default)('#setParameter').modal('toggle');
            });
          });
        });
      },
      setParamcollection: function setParamcollection(paramcollection) {
        var self = this;
        self.controller.set('paramLoading', true);

        if (paramcollection) {
          var paramQuery = self.store.query('genreportparamuser', {
            where: {
              genreportparamcollectionusernum: paramcollection.id,
              isdelete: false
            },
            sort: 'id ASC'
          });
          paramQuery.then(function (paramContents) {
            self.controller.set('paramContents', paramContents.toArray());
            self.controller.set('selectedParamcollection', paramcollection);
            self.controller.set('paramLoading', false);
          });
        } else {
          self.controller.set('paramContents', Ember.A());
          self.controller.set('selectedParamcollection', null);
          self.controller.set('paramLoading', false);
        }
      },
      addParam: function addParam(paramconfig) {
        var self = this;
        var newParam = this.store.createRecord('genreportparamuser', {
          filtertype: "EQUAL",
          genreportparamcollectionusernum: this.controller.get('selectedParamcollection'),
          genreportnum: self.controller.get('currentReport')
        });
        newParam.save().then(function (result) {
          self.controller.get('paramContents').addObject(result);
        });
      },
      deleteParam: function deleteParam(param) {
        var self = this;
        this.store.findRecord('genreportparamuser', param.id).then(function (container) {
          container.set('isdelete', true);
          container.save().then(function () {
            self.controller.get('paramContents').removeObject(container);
          });
        });
      },
      saveParam: function saveParam() {
        var self = this;
        var promises = Ember.A();

        if (self.controller.get('selectedParamcollection')) {
          self.controller.get('paramContents').forEach(function (param) {
            promises.push(param.save());
          });
          self.controller.get('paramcollectionContents').forEach(function (paramcollection) {
            promises.push(paramcollection.save());
          });
          Ember.RSVP.Promise.all(promises).then(function () {
            var name = self.controller.get('user.firstName') + '\ ' + self.controller.get('user.lastName');
            var data = {
              "dabKpi": self.controller.get('currentReport.id'),
              "dabParam": self.controller.get('selectedParamcollection.id'),
              "generatedBy": name,
              "genParamType": "User"
            };
            var url = 'api/v1/genreports/callApi';

            _jquery.default.ajax({
              url: url,
              type: 'POST',
              data: JSON.stringify(data),
              contentType: 'application/json',
              success: function success(result) {
                window.open(result);
              },
              error: function error(xhr, status, _error) {
                console.log(_error);
              }
            });
          });
        } else {}
      },
      viewReport: function viewReport(report) {
        var self = this;

        if (report.get('reporturl')) {
          window.open(report.get('reporturl'));
        } else {
          var name = self.controller.get('user.firstName') + '\ ' + self.controller.get('user.lastName');
          var data = {
            "dabKpi": report.id,
            "generatedBy": name
          };
          var url = 'api/v1/genreports/callApi';

          _jquery.default.ajax({
            url: url,
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json',
            success: function success(result) {
              report.set('reporturl', result);
              report.save().then(function () {
                window.open(result);
              });
            },
            error: function error(xhr, status, _error2) {
              console.log(_error2);
            }
          });
        }
      },
      updateReport: function updateReport(report) {
        var self = this;
        var name = self.controller.get('user.firstName') + '\ ' + self.controller.get('user.lastName');
        var organizationnum = 0;
        var companynum = 0;

        if (!Ember.isEmpty(report.get('organizationnum').get('id'))) {
          organizationnum = report.get('organizationnum').get('id');
        }

        if (!Ember.isEmpty(report.get('companynum').get('id'))) {
          companynum = report.get('companynum').get('id');
        }

        var data = {
          "dabKpi": report.id,
          "generatedBy": name,
          "organizationNum": organizationnum,
          "companyNum": companynum
        };
        var url = "".concat(this.store.adapterFor('application').get('serverHost'), "/api/v1/genreports/callApi");

        _jquery.default.ajax({
          url: url,
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          success: function success(result) {
            report.set('reporturl', result);
            report.save();
          },
          error: function error(xhr, status, _error3) {
            console.log(_error3);
          }
        });
      }
    }
  });

  _exports.default = _default;
});