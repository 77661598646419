define("client/mixins/w3office-forms/parent-child-mechanism", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    resetParentChildMechanism: function resetParentChildMechanism() {
      this.get('formContainer').setProperties({
        parentEntryStack: [],
        parentEntry: null,
        formParent: null,
        childRelation: null,
        formParentID: null,
        fromEntryDetail: false,
        parentIndex: ''
      });
      this.controllerFor('ui').set('parentStack', []);
    },
    parentChildMechanism: function parentChildMechanism(formData, entryData) {
      var substract = 0,
          doSubstract = false,
          doHeadSubstract = false;
      this.get('formContainer.parentEntryStack').forEach(function (parentEntry) {
        if (formData.get('formid') === parentEntry.formData.get('formid') && entryData.get('id') === parentEntry.entryData.get('id')) {
          substract = parentEntry.seq;
          doSubstract = true;

          if (parentEntry.head === true) {
            doHeadSubstract = true;
          }
        }
      });

      if (doSubstract) {
        if (doHeadSubstract) {
          this.resetParentChildMechanism();
        } else {
          this.get('formContainer.parentEntryStack').splice(substract - 1);
          this.get('formContainer').setProperties({
            parentEntryStack: this.get('formContainer.parentEntryStack'),
            parentIndex: this.get('formContainer.parentIndex').substring(0, this.get('formContainer.parentIndex').indexOf(formData.toString()) - 1),
            parentEntry: this.get('formContainer.parentEntryStack')[this.get('formContainer.parentEntryStack').length - 1].entryData,
            formParent: this.get('formContainer.parentEntryStack')[this.get('formContainer.parentEntryStack').length - 1].formData,
            formParentID: this.get('formContainer.formParent').get('id'),
            childRelation: this.get('formContainer.parentEntryStack')[this.get('formContainer.parentEntryStack').length - 1].childRelation,
            fromEntryDetail: this.get('formContainer.parentEntryStack')[this.get('formContainer.parentEntryStack').length - 1].fromEntryDetail
          });
        }

        this.controllerFor('ui').set('parentStack', null);
        this.controllerFor('ui').set('parentStack', this.get('formContainer.parentEntryStack'));
      }

      if (this.get('formContainer.fromEntryDetail') === true) {
        this.set('formContainer.fromEntryDetail', false);
        this.transitionTo('ui.entry.index', formData.get('formid'), entryData.id);
      } else if (this.controllerFor('ui').get('is1to1')) {
        this.controllerFor('ui.entry.index').set('afterEdit', true);
        this.transitionTo('ui.entry.index', formData.get('formid'), entryData.id);
      } else if (this.get('isTask')) {
        this.transitionTo('process-manager.inbox');
      } else {
        this.transitionTo('ui.entry.index', formData.get('formid'), entryData.id);
      }
    },
    actions: {
      backToParent: function backToParent(formData, entryData) {
        this.parentChildMechanism(formData, entryData);
      }
    }
  });

  _exports.default = _default;
});