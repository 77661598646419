define("client/pods/users-roles/exportimport/route", ["exports", "jquery", "client/mixins/serializablejson", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _serializablejson, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var globalGenerateKeyJSON = {
    application: {
      hasMany: {
        role: {
          belongsTo: {
            key: 'application',
            modelName: 'application'
          },
          hasMany: {
            roleroute: {
              belongsTo: {
                key: 'role',
                modelName: 'role'
              },
              allowedroute: {
                modelName: 'allowedroute',
                isBelongsTo: true
              }
            },
            rolegroup: {
              belongsTo: {
                key: 'role',
                modelName: 'role'
              },
              group: {
                modelName: 'group',
                isBelongsTo: true
              }
            }
          }
        },
        allowedroute: {
          belongsTo: {
            key: 'application',
            modelName: 'application'
          },
          parent: {
            modelName: 'allowedroute',
            isBelongsTo: true
          }
        },
        appsession: {
          belongsTo: {
            key: 'application',
            modelName: 'application'
          },
          appsessionparent: {
            modelName: 'appsession',
            isBelongsTo: true
          }
        }
      }
    }
  };

  var _default = Ember.Route.extend(_checkUserRole.default, _serializablejson.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super();
    },
    model: function model() {
      return this.store.findAll('application');
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller, model) {
      console.log('export import!');
      controller.set('model', model);
      controller.set('exportFileName', null);
      controller.set('processedForms', 0);
      controller.set('totalForms', 0);
      controller.set('oldApplication', null);
      controller.set('newApplication', null);
      controller.set('processingExport', false);
      controller.set('processingImport', false);
      controller.set('abortImport', Ember.RSVP.defer());
      this.store.findAll('group').then(function (groups) {
        var tempObj = {};
        groups.forEach(function (group) {
          tempObj[group.id] = group;
        });
        controller.set('group', tempObj);
      }); //import

      controller.set('errorUploadFile', false);
      controller.set('successUploadFile', false);
      controller.set('messageUploadFile', '');
      controller.set('messageImportFile', '');
      controller.set('fileName', '');
      controller.set('jsonIsLoaded', false);
      controller.set('jsonFile', null); //export/download

      controller.set('errorDownloadFile', false);
      controller.set('messageDownloadFile', '');
      controller.addObserver('processedForms', 'totalForms', function () {
        var resultWidth = controller.get('processedForms') / controller.get('totalForms') * 100;
        controller.set('progressbarWidth', Ember.String.htmlSafe("width: " + resultWidth + "%"));
      });
    },
    extractEntity: function extractEntity(mainFormData, resolver, entity, entityObject, entityIDParent) {
      var self = this;
      var whereQuery = {};
      whereQuery.where = {};
      whereQuery.where[entityObject.belongsTo.key] = entityIDParent;
      var arrayIDLevelN = [];
      this.store.query(entity, whereQuery).then(function (resultExtractEntity) {
        var totalLevelN = resultExtractEntity.get('length');
        mainFormData[entity] = {};
        mainFormData[entity]['keyName'] = entity;
        mainFormData[entity]['data'] = [];

        if (totalLevelN > 0) {
          var hasHasMany = false;
          resultExtractEntity.forEach(function (recordLevelN) {
            var serializedObj = recordLevelN.serialize();
            serializedObj['id'] = recordLevelN.get('id');
            /*Here to populate Relation Extended and Check hasMany*/

            Object.keys(entityObject).forEach(function (propertyEntity) {
              if (propertyEntity === 'hasMany') {
                hasHasMany = true;
                arrayIDLevelN.push(recordLevelN.get('id'));
              }
              /*else if(entityObject[propertyEntity].modelName === 'form'){
              serializedObj[propertyEntity] = self.controller.get('arrayObjForm')[serializedObj[propertyEntity]];
              }else if(entityObject.belongsTo.modelName === 'form'){
              serializedObj[entityObject.belongsTo.key] = self.controller.get('arrayObjForm')[serializedObj[entityObject.belongsTo.key]];
              }*/

            });
            mainFormData[entity]['data'].push(serializedObj);
          });

          if (!hasHasMany) {
            resolver(true);
          } else {
            var arrayPromiseLevelNChildren = [];
            Object.keys(entityObject.hasMany).forEach(function (propertyKey) {
              var promiseLevelNChildren = new Promise(function (resolveLevelNChildren, rejectChildren) {
                self.extractEntity(mainFormData, resolveLevelNChildren, propertyKey, entityObject.hasMany[propertyKey], arrayIDLevelN);
              });
              arrayPromiseLevelNChildren.addObject(promiseLevelNChildren);
            });
            Ember.RSVP.Promise.all(arrayPromiseLevelNChildren).then(function (yaaa) {
              resolver(true);
            });
          }
        } else {
          resolver(true);
        }
      });
    },
    actions: {
      uploadFile: function uploadFile(file) {
        if (file['file'].type === 'application/json') {
          this.controller.set('errorUploadFile', false);
          this.controller.set('jsonFile', file);
          this.controller.set('jsonIsLoaded', true);
          this.controller.set('fileName', file.get('name'));
        } else {
          this.controller.set('fileName', file.get('name'));
          this.controller.set('errorUploadFile', true);
          this.controller.set('jsonIsLoaded', false);
          this.controller.set('messageUploadFile', 'Please select file with .json extension');
        }
      },
      importForms: function importForms(file) {
        console.time('timerName');
        var self = this;
        this.controller.set('processedForms', 0);
        this.controller.set('processingImport', true);
        this.controller.set('messageImportFile', 'Processing...');
        var namespace = this.store.adapterFor('application').get('namespace');
        var url = namespace + '/forms/uploadJSON';
        var filepath = '/uploads/jsonfile';
        var headers = {};
        headers['Authorization'] = "Bearer ".concat(this.get('authObject.data.authenticated.access_token'));
        file.upload(url, {
          headers: headers,
          data: {
            path: filepath
          }
        }).then(function (response) {
          var fd = response['body']['files'][0]['fd'];
          var url = self.store.adapterFor('application').get('namespace') + "/forms/downloadJSON?fd=";

          _jquery.default.ajax({
            type: "POST",
            url: url + fd,
            success: function success(response) {
              /*Call Delete JSON here*/
              _jquery.default.ajax({
                type: "POST",
                url: self.store.adapterFor('application').get('namespace') + "/forms/deleteJSON?fd=" + fd,
                success: function success() {
                  console.log('deleteJSON success');
                }
              });

              var result = JSON.parse(response);
              var key = result.key;
              var mainDataIndex = 0;
              var arrayObjHash = {};
              var arrayWaitingEntityRecord = [];
              self.controller.set('totalForms', result.mainData.length);

              var objectToArray = function objectToArray(object, arrayContainer) {
                var arrayNext = [];
                Object.keys(object).forEach(function (entity, index) {
                  arrayContainer.addObject({
                    entity: entity,
                    property: object[entity]
                  });

                  if (object[entity].hasMany) {
                    arrayNext.addObject(object[entity].hasMany);
                  }
                });

                if (arrayNext.length > 0) {
                  arrayNext.forEach(function (nextEntity) {
                    objectToArray(nextEntity, arrayContainer);
                  });
                }
              };

              var localGenerateEntity = function localGenerateEntity(resolveForm, arrayKey, arrayKeyIndex, mainData, mainDataIndex, arrayObjHash, arrayWaitingEntityRecord) {
                if (arrayKeyIndex === arrayKey.length) {
                  resolveForm(true);
                } else {
                  console.log('localGenerateEntity - ' + arrayKey[arrayKeyIndex].entity);

                  var localGenerateEntityRecord = function localGenerateEntityRecord(arrayObjHash, arrayKey, arrayKeyIndex, recordN, recordIndex, totalRecord) {
                    console.log(recordIndex + ' of ' + totalRecord);

                    if (recordIndex === totalRecord) {
                      if (!self.controller.get('processingImport')) {
                        /*Abort Import*/
                        arrayKeyIndex = arrayKey.length;
                      } else {
                        arrayKeyIndex += 1;
                      }

                      localGenerateEntity(resolveForm, arrayKey, arrayKeyIndex, mainData, mainDataIndex, arrayObjHash, arrayWaitingEntityRecord);
                    } else {
                      var arrayWaitingProperty = [];
                      Object.keys(arrayKey[arrayKeyIndex].property).forEach(function (propertyEntity) {
                        if (recordN[propertyEntity] && propertyEntity !== 'hasMany' && propertyEntity !== 'belongsTo') {
                          if (arrayKey[arrayKeyIndex].property[propertyEntity].modelName === 'group') {
                            recordN[propertyEntity] = self.controller.get('group')[recordN[propertyEntity]];
                          } else {
                            /*Emergency Used to be here*/

                            /*Check other relation extension*/
                            if (arrayObjHash[arrayKey[arrayKeyIndex].property[propertyEntity].modelName][recordN[propertyEntity]]) {
                              if (arrayKey[arrayKeyIndex].property[propertyEntity].isBelongsTo && isNaN(recordN[propertyEntity]) === false) {
                                recordN[propertyEntity] = arrayObjHash[arrayKey[arrayKeyIndex].property[propertyEntity].modelName][recordN[propertyEntity]];
                              } else {
                                recordN[propertyEntity] = arrayObjHash[arrayKey[arrayKeyIndex].property[propertyEntity].modelName][recordN[propertyEntity]].id;
                              }
                            } else {
                              arrayWaitingProperty.push({
                                isBelongsTo: arrayKey[arrayKeyIndex].property[propertyEntity].isBelongsTo,
                                entity: arrayKey[arrayKeyIndex].property[propertyEntity].modelName,
                                property: propertyEntity,
                                value: recordN[propertyEntity]
                              });
                            }
                          }
                        }
                      });
                      /*Pair the foreign key*/

                      recordN[arrayKey[arrayKeyIndex].property.belongsTo.key] = arrayObjHash[arrayKey[arrayKeyIndex].property.belongsTo.modelName][recordN[arrayKey[arrayKeyIndex].property.belongsTo.key]];
                      var tempID = recordN.id;
                      delete recordN.id;
                      self.store.createRecord(arrayKey[arrayKeyIndex].entity, recordN).save().then(function (newRecordN) {
                        /*Push new Record to arrayObjHash*/
                        arrayObjHash[arrayKey[arrayKeyIndex].entity][tempID] = newRecordN;
                        /*Push new record to arrayWaiting*/

                        if (arrayWaitingProperty.length > 0) {
                          arrayWaitingEntityRecord.push({
                            waitingProperty: arrayWaitingProperty,
                            record: newRecordN
                          });
                        }

                        recordIndex += 1;
                        /* Abort Import*/

                        if (!self.controller.get('processingImport')) {
                          recordIndex = totalRecord;
                        }

                        localGenerateEntityRecord(arrayObjHash, arrayKey, arrayKeyIndex, mainData[mainDataIndex][arrayKey[arrayKeyIndex].entity].data[recordIndex], recordIndex, totalRecord);
                      });
                    }
                  };

                  if (mainData[mainDataIndex][arrayKey[arrayKeyIndex].entity].data.length > 0) {
                    console.log('first call localGenerateEntityRecord - ' + arrayKey[arrayKeyIndex].entity);
                    arrayObjHash[arrayKey[arrayKeyIndex].entity] = {};
                    var totalRecord = mainData[mainDataIndex][arrayKey[arrayKeyIndex].entity].data.length;
                    localGenerateEntityRecord(arrayObjHash, arrayKey, arrayKeyIndex, mainData[mainDataIndex][arrayKey[arrayKeyIndex].entity].data[0], 0, totalRecord);
                  } else {
                    arrayKeyIndex += 1;
                    localGenerateEntity(resolveForm, arrayKey, arrayKeyIndex, mainData, mainDataIndex, arrayObjHash, arrayWaitingEntityRecord);
                  }
                }
              };

              var simpleLoop = function simpleLoop() {
                if (mainDataIndex === result.mainData.length) {
                  if (self.controller.get('processingImport')) {
                    self.controller.set('messageImportFile', 'Complete');
                    console.timeEnd('timerName');
                    console.log('SUCCESS IMPORT Application');

                    if (self.controller.get('oldApplication')) {
                      _jquery.default.ajax({
                        type: "POST",
                        url: self.store.adapterFor('application').get('namespace') + "/applications/deleteApp",
                        data: {
                          app: self.controller.get('oldApplication').get('id')
                        },
                        success: function success(response) {
                          console.log('delete oldApplication complete');
                          self.controller.get('oldApplication').destroyRecord();
                          self.controller.set('oldApplication', null);
                        }
                      });
                    }

                    self.controller.set('newApplication', null);
                    Ember.run.later(self, function () {
                      self.controller.set('processingImport', false);
                      self.controller.set('fileName', null);
                      self.controller.set('jsonIsLoaded', false);
                      self.transitionTo('users-roles.role-manager');
                    }, 1000);
                  } else {
                    console.log('Import Application Aborted by User!');
                    self.controller.set('oldApplication', null);
                    self.controller.set('fileName', null);
                    self.controller.set('jsonIsLoaded', false);
                    console.timeEnd('timerName');
                    self.controller.get('abortImport').resolve(true);
                  }
                } else {
                  var arrayPromiseForm = [];
                  var arrayKey = [];
                  var promiseForm = new Promise(function (resolveForm, rejectForm) {
                    self.store.query('application', {
                      where: {
                        appId: result.mainData[mainDataIndex].application.appId
                      }
                    }).then(function (application) {
                      if (application.get('length') > 0) {
                        self.controller.set('oldApplication', application.get('firstObject'));
                      }

                      objectToArray(key.application.hasMany, arrayKey);
                      var tempID = result.mainData[mainDataIndex].application.id;
                      delete result.mainData[mainDataIndex].application.id;
                      self.store.createRecord('application', result.mainData[mainDataIndex].application).save().then(function (newApplication) {
                        console.log('new application Created - ' + newApplication.get('appId'));
                        self.controller.set('newApplication', newApplication);

                        if (arrayObjHash['application']) {
                          arrayObjHash['application'][tempID] = newApplication;
                        } else {
                          arrayObjHash['application'] = {};
                          arrayObjHash['application'][tempID] = newApplication;
                        }

                        localGenerateEntity(resolveForm, arrayKey, 0, result.mainData, mainDataIndex, arrayObjHash, arrayWaitingEntityRecord);
                      });
                    });
                  });
                  arrayPromiseForm.push(promiseForm);
                  Ember.RSVP.Promise.all(arrayPromiseForm).then(function () {
                    if (arrayWaitingEntityRecord.length > 0) {
                      console.log('execute waitingRecord'); // 'process waitingProtocol'

                      var arrayWaitingProtocol = [];
                      var arrayRepushRecord = [];
                      arrayWaitingEntityRecord.forEach(function (waitingRecord) {
                        var waitingProtocol = new Promise(function (resolveWP, rejectWP) {
                          var rePush = false;
                          waitingRecord.waitingProperty.forEach(function (wp) {
                            if (arrayObjHash[wp.entity][wp.value]) {
                              if (wp.isBelongsTo) {
                                waitingRecord.record.set(wp.property, arrayObjHash[wp.entity][wp.value]);
                              } else {
                                waitingRecord.record.set(wp.property, arrayObjHash[wp.entity][wp.value].id);
                              }
                            } else {
                              if (!rePush) {
                                rePush = true;
                                arrayRepushRecord.push(waitingRecord);
                              }
                            }
                          });
                          waitingRecord.record.save().then(function () {
                            resolveWP(true);
                          });
                        });
                        arrayWaitingProtocol.push(waitingProtocol);
                      });
                      Ember.RSVP.Promise.all(arrayWaitingProtocol).then(function () {
                        // 'waitingProtocol Complete'
                        if (arrayRepushRecord.length > 0) {
                          arrayWaitingEntityRecord = arrayRepushRecord;
                        } else {
                          arrayWaitingEntityRecord = [];
                        }

                        self.controller.set('processedForms', self.controller.get('processedForms') + 1);
                        mainDataIndex++;
                        simpleLoop();
                      });
                    } else {
                      self.controller.set('processedForms', self.controller.get('processedForms') + 1);
                      mainDataIndex++;
                      simpleLoop();
                    }
                  });
                }
              };

              simpleLoop();
            }
          });
        });
      },
      exportForms: function exportForms() {
        this.controller.set('messageDownloadFile', '');

        if (Ember.isEmpty(this.controller.get('exportFileName')) || this.controller.get('appID') === null) {
          this.controller.set('errorDownloadFile', true);

          if (Ember.isEmpty(this.controller.get('exportFileName'))) {
            this.controller.set('messageDownloadFile', this.controller.get('messageDownloadFile') + 'File Name is Required. ');
          }

          if (this.controller.get('appID') === null) {
            this.controller.set('messageDownloadFile', this.controller.get('messageDownloadFile') + 'Application is Required. ');
          }
        } else {
          console.time('timerName');
          this.controller.set('processingExport', true);
          this.controller.set('errorDownloadFile', false);
          this.controller.set('messageDownloadFile', 'Processing...');
          var self = this;
          var masterJSON = {};
          console.log('Generate JSON');
          masterJSON['key'] = globalGenerateKeyJSON;
          masterJSON['mainData'] = [];
          this.controller.set('isProcessing', true);
          this.controller.set('processedForms', 0);
          this.controller.set('totalForms', 1);
          var incrementLoop = 0;

          var simpleLoop = function simpleLoop() {
            if (incrementLoop === 1) {
              Ember.run.later(self, function () {
                self.controller.set('processingExport', false);
                self.controller.set('exportFileName', null);
                self.controller.set('appID', null);
                self.controller.set('messageDownloadFile', '');
              }, 3000);
              var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(masterJSON));
              var dlAnchorElem = document.getElementById('downloadAnchorElem');
              dlAnchorElem.setAttribute("href", dataStr);
              dlAnchorElem.setAttribute("download", self.controller.get("exportFileName") + ".json");
              dlAnchorElem.click();
              console.timeEnd('timerName');
              self.controller.set('messageDownloadFile', 'Complete');
            } else {
              var arrayPromiseForm = [];
              var mainFormData = {};
              var promiseForm = new Promise(function (resolveForm, rejectForm) {
                self.store.findRecord('application', self.controller.get('appID')).then(function (formValue) {
                  mainFormData['application'] = formValue.serialize();
                  mainFormData['application'].id = formValue.get('id');
                  var arrayPromiseMain = [];
                  Object.keys(globalGenerateKeyJSON.application.hasMany).forEach(function (entity) {
                    /*Loop properties from the Form, like field, entrygroup, entryfilter, etc*/
                    var arrayIDLevel1 = [];
                    var promiseLevel1 = new Promise(function (resolveLevel1, reject) {
                      /*Each Entity processed under promise*/
                      var whereQuery = {};
                      whereQuery.where = {};
                      whereQuery.where[globalGenerateKeyJSON.application.hasMany[entity].belongsTo.key] = formValue.get('id');
                      self.store.query(entity, whereQuery).then(function (resultLevel1) {
                        var totalLevel1 = resultLevel1.get('length');
                        mainFormData[entity] = {};
                        mainFormData[entity]['keyName'] = entity;
                        mainFormData[entity]['data'] = [];

                        if (totalLevel1 > 0) {
                          /*process data of an Entity*/
                          var hasHasMany = false;
                          resultLevel1.forEach(function (recordLevel1) {
                            var serializedObj = recordLevel1.serialize();
                            serializedObj['id'] = recordLevel1.get('id');
                            /*Here to populate Form and Check hasMany*/

                            Object.keys(globalGenerateKeyJSON.application.hasMany[entity]).forEach(function (propertyEntity) {
                              if (propertyEntity === 'hasMany') {
                                hasHasMany = true;
                                arrayIDLevel1.push(recordLevel1.get('id'));
                              }
                              /*else if(globalGenerateKeyJSON.application.hasMany[entity][propertyEntity].modelName === 'application'){
                              serializedObj[propertyEntity] = self.controller.get('arrayObjForm')[serializedObj[propertyEntity]];
                              }else if(globalGenerateKeyJSON.application.hasMany[entity].belongsTo.modelName === 'application'){
                              serializedObj[globalGenerateKeyJSON.application.hasMany[entity].belongsTo.key] = self.controller.get('arrayObjForm')[serializedObj[globalGenerateKeyJSON.application.hasMany[entity].belongsTo.key]];
                              }*/

                            });
                            mainFormData[entity]['data'].push(serializedObj);
                          });

                          if (!hasHasMany) {
                            resolveLevel1(true);
                          } else {
                            /*If an Entity has hasMany, process them. Eg. field has hasMany fieldfilter*/
                            var arrayPromiseMainChildren = [];
                            Object.keys(globalGenerateKeyJSON.application.hasMany[entity].hasMany).forEach(function (propertyKey) {
                              var promiseLevel1Children = new Promise(function (resolveLevel1Children, rejectChildren) {
                                self.extractEntity(mainFormData, resolveLevel1Children, propertyKey, globalGenerateKeyJSON.application.hasMany[entity].hasMany[propertyKey], arrayIDLevel1);
                              });
                              arrayPromiseMainChildren.addObject(promiseLevel1Children);
                            });
                            Ember.RSVP.Promise.all(arrayPromiseMainChildren).then(function (yaaa) {
                              resolveLevel1(true);
                            });
                          }
                        } else {
                          resolveLevel1(true);
                        }
                      });
                    });
                    arrayPromiseMain.push(promiseLevel1);
                  });
                  Ember.RSVP.Promise.all(arrayPromiseMain).then(function () {
                    /*Testing*/
                    // var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(masterJSON));
                    // var dlAnchorElem = document.getElementById('downloadAnchorElem');
                    // // var file_name = formValue.get('name').replace(/ /g,'');//remove any spaces
                    // // file_name = file_name + '_' + formValue.get('id');
                    // dlAnchorElem.setAttribute("href",	 dataStr	 );
                    // dlAnchorElem.setAttribute("download", self.controller.get("exportFileName")+".json");
                    // dlAnchorElem.click();
                    // console.timeEnd('timerName');
                    // self.controller.set('messageDownloadFile','Complete');

                    /*Real*/
                    masterJSON['mainData'].push(mainFormData);
                    resolveForm(true);
                  });
                });
              });
              arrayPromiseForm.push(promiseForm);
              Ember.RSVP.Promise.all(arrayPromiseForm).then(function () {
                self.controller.set('processedForms', self.controller.get('processedForms') + 1);
                incrementLoop++;
                simpleLoop();
              });
            }
          };

          simpleLoop();
        }
      },
      willTransition: function willTransition(transition) {
        var self = this;

        if (this.controller.get('processingImport')) {
          var returnConfirm = confirm('Importing Application still in process! Do you want to abort it?');
          transition.abort();

          if (returnConfirm) {
            this.controller.set('processingImport', false);
            var tempID = this.controller.get('newApplication').get('id');
            this.controller.get('abortImport').promise.then(function () {
              self.controller.get('newApplication').destroyRecord().then(function () {
                _jquery.default.ajax({
                  type: "POST",
                  url: self.store.adapterFor('application').get('namespace') + "/applications/deleteApp",
                  data: {
                    app: tempID
                  },
                  success: function success(response) {
                    self.transitionTo(transition.targetName);
                  }
                });
              });
            });
          }
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});