define("client/pods/components/w3office-forms/bootstrap-table/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.set("showPages", 10);

      if (this.get("resetPagination")) {
        this.set("currentPageInside", Number(this.get("currentPage")));
        this.generatePagination();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get("resetPagination")) {
        this.set("currentPageInside", Number(this.get("currentPage")));
        this.generatePagination();
      }

      this.set("tempCurrentPage", this.get("currentPage"));
    },
    didRender: function didRender() {
      var _this = this;

      console.log("didRender() bootstrap-table");

      if (this.get("currentTheme")) {
        if (this.get("currentTheme.tableHeaderBackgroundColor")) {
          (0, _jquery.default)("#ui-entries-table > thead > tr > th").each(function (index, th) {
            th.style.background = "".concat(_this.get("currentTheme.tableHeaderBackgroundColor")); // First Column doesn't have the 'a' tag

            if (th.children[0]) {
              th.children[0].style.color = "".concat(_this.get("currentTheme.tableHeaderFontColor"));
              th.children[0].style.fontSize = "".concat(_this.get("currentTheme.tableHeaderFontSize"));

              if (_this.get("currentTheme.tableHeaderFontFamily")) {
                th.children[0].style.fontFamily = "".concat(_this.get("currentTheme.tableHeaderFontFamily"));
              }
            }
          });
        }

        if (this.get("currentTheme.tableRowBackgroundColor")) {
          (0, _jquery.default)("#ui-entries-table > tbody > tr").each(function (index, th) {
            th.style.backgroundColor = "".concat(_this.get("currentTheme.tableRowBackgroundColor"));
            th.style.color = "".concat(_this.get("currentTheme.tableRowFontColor"));
            th.style.fontSize = "".concat(_this.get("currentTheme.tableRowFontSize"));

            if (_this.get("currentTheme.tableRowFontFamily")) {
              th.style.fontFamily = "".concat(_this.get("currentTheme.tableRowFontFamily"));
            }
          });
        }
      }

      var $table = (0, _jquery.default)('#ui-entries-table'); // source: https://stackoverflow.com/a/17380697 (Auto Width Columns)

      var actionColumnLength = $table.find('thead tr').children('.actionColumn').length;
      var fieldValueColumnLength = $table.find('thead tr').children('.fieldValueColumn').length;
      var columnWidth;

      if (actionColumnLength === 0) {
        if ([0, 1].includes(fieldValueColumnLength)) {
          columnWidth = 1;
        } else {
          columnWidth = 100;
        }
      } else {
        if ([0, 1, 2, 3].includes(fieldValueColumnLength)) {
          columnWidth = 100 / fieldValueColumnLength;
        } else {
          columnWidth = 50;
        }
      } // Set the width of thead columns


      $table.find('thead tr').children().each(function (i, v) {
        (0, _jquery.default)(v).width("".concat(columnWidth, "%"));
      });
    },
    generatePagination: function generatePagination() {
      var lastPage = parseInt(this.get("totalPage"), 10);
      var currentPage = parseInt(this.get("currentPage"), 10);
      var showPages = this.get("showPages");
      var pageStart;

      if (currentPage <= showPages) {
        pageStart = 0;
      } else if (currentPage % showPages === 0) {
        pageStart = (Math.floor(currentPage / showPages) - 1) * showPages;
      } else {
        pageStart = Math.floor(currentPage / showPages) * showPages;
      }

      var arrPages = [];

      for (var i = 1; i <= showPages; i++) {
        if (pageStart + i <= lastPage) {
          arrPages.push(pageStart + i);
        }
      }

      this.set("pages", arrPages);
      this.set("currentPageSet", Math.ceil(currentPage / showPages));
      this.set("lastPageSet", Math.ceil(lastPage / showPages));

      if (this.get("currentPageSet") === 1) {
        this.set("showPrevFirstPage", false);
      } else {
        this.set("showPrevFirstPage", true);
      }

      if (this.get("currentPageSet") === this.get("lastPageSet")) {
        this.set("showNextLastPage", false);
      } else {
        this.set("showNextLastPage", true);
      }
    },
    actions: {
      changeEntryLimit: function changeEntryLimit(limit) {
        this.set("resetPagination", false);
        this.limitAction(limit);
      },
      changePage: function changePage(page) {
        this.set("resetPagination", false);
        this.moveAction("page", page);
      },
      moveEntries: function moveEntries(direction, page) {
        this.set("resetPagination", false);
        this.moveAction(direction, page);
      },
      nextPageSet: function nextPageSet() {
        if (this.get("currentPageSet") < this.get("lastPageSet")) {
          this.set("resetPagination", false);
          this.moveAction("page", this.get("currentPageSet") * this.get("showPages") + 1);
        }
      },
      pageInputChanged: function pageInputChanged(param) {
        var movePage = parseInt(param, 10);

        if (movePage < 1 || movePage > this.get("totalPage")) {
          return this.set("tempCurrentPage", this.get("currentPage"));
        }

        if (parseInt(this.get("currentPage"), 10) !== movePage) {
          this.moveAction("page", movePage);
        }
      },
      prevPageSet: function prevPageSet() {
        if (this.get("currentPageSet") > 1) {
          this.set("resetPagination", false);
          this.moveAction("page", (this.get("currentPageSet") - 1) * this.get("showPages"));
        }
      },
      sortEntries: function sortEntries(column) {
        this.set("resetPagination", false);
        this.sortAction(column);
      }
    }
  });

  _exports.default = _default;
});