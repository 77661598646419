define("client/pods/w3office-forms/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    currentRoute: '',
    beforeModel: function beforeModel(transition) {
      var self = this;

      if (this.get('authObject.session.isAuthenticated')) {
        if (!Ember.isEmpty(this.get('authObject.data.user.id'))) {
          // this one still caused DEPRECATION: The adapter returned an array for the primary data of a `queryRecord` response. This is deprecated as `queryRecord` should return a single record.
          // changed to store.query
          this.store.query('formuser', {
            where: {
              user: this.get('authObject.data.user.id')
            }
          }).then(function (users) {
            self.set('formContainer.sessionHolder', users.content[0]);
          });
        }
      } else {
        this.transitionTo('start');
      }

      this.set('currentRoute', transition.targetName);
    },
    setupController: function setupController(controller) {
      controller.set('standardComponentList', []);
      controller.set('advancedComponentList', []);
      controller.set('collectionComponentList', []);
      controller.set('formComponentList', []);
      controller.set('noComponents', false);
      controller.set('standardComponents', false);
      controller.set('advancedComponents', false);
      controller.set('collectionComponents', false);
      controller.set('formComponents', false);
      controller.set('currentRoute', this.get('currentRoute'));
      controller.set('componentsShown', false);
      this.controllerFor('application').set('appID', 'W3Office Forms');
      this.controllerFor('application').set('menus', [{
        'title': 'Form Builder',
        'glyph': 'fa-copy',
        'link': 'w3office-forms',
        'unique': 'form',
        'subunique': 'subform',
        'theme': '#ffffff',
        'children': [{
          'title': 'Form List',
          'link': 'w3office-forms'
        }, {
          'title': 'Export / Import',
          'link': 'w3office-forms.importexport'
        }, {
          'title': 'External API Domain Setting',
          'link': 'w3office-forms.external-api'
        }]
      }, {
        'title': 'Languages',
        'glyph': 'fa-language',
        'link': 'w3office-forms.language',
        'unique': 'language',
        'theme': '#ffffff'
      }]);
      console.log('currentRoute route = ' + this.get('currentRoute'));

      if (!Ember.isEmpty(this.get('authObject.data.user.id'))) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          controller.set('userInfo', user);
        });
      } //Populate static component from database


      this.store.findAll('formcomponent').then(function (formcomponents) {
        formcomponents.forEach(function (formcomponent) {
          if (formcomponent.get('path').indexOf('standard-components') !== -1) {
            controller.get('standardComponentList').addObject(formcomponent);
          } else if (formcomponent.get('path').indexOf('advanced-components') !== -1) {
            controller.get('advancedComponentList').addObject(formcomponent);
          } else if (formcomponent.get('path').indexOf('collection-components') !== -1) {
            controller.get('collectionComponentList').addObject(formcomponent);
          }
        });
      }); //Populate form component from database

      this.store.findAll('formfield').then(function (formfields) {
        formfields.forEach(function (formField) {
          controller.get('formComponentList').addObject(formField);
        });
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        //Component Tab Handler on willTransition event
        this.controller.set('currentRoute', transition.targetName);
        this.controller.set('componentsShown', false);
      },
      changeComponentList: function changeComponentList(param) {
        console.log('changeComponentList on Route');

        if (param === 'none') {
          this.controller.set('noComponents', true);
          this.controller.set('standardComponents', false);
          this.controller.set('advancedComponents', false);
          this.controller.set('collectionComponents', false);
          this.controller.set('formComponents', false);
        } else if (param === 'standard') {
          this.controller.set('noComponents', false);
          this.controller.set('standardComponents', true);
          this.controller.set('advancedComponents', false);
          this.controller.set('collectionComponents', false);
          this.controller.set('formComponents', false);
        } else if (param === 'advanced') {
          this.controller.set('noComponents', false);
          this.controller.set('standardComponents', false);
          this.controller.set('advancedComponents', true);
          this.controller.set('collectionComponents', false);
          this.controller.set('formComponents', false);
        } else if (param === 'collection') {
          this.controller.set('noComponents', false);
          this.controller.set('standardComponents', false);
          this.controller.set('advancedComponents', false);
          this.controller.set('collectionComponents', true);
          this.controller.set('formComponents', false);
        } else if (param === 'form') {
          this.controller.set('noComponents', false);
          this.controller.set('standardComponents', false);
          this.controller.set('advancedComponents', false);
          this.controller.set('collectionComponents', false);
          this.controller.set('formComponents', true);
        }
      }
    }
  });

  _exports.default = _default;
});