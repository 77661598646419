define("client/pods/dashboard-builder/dabtabaccess/edit/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3office-forms/check-resource-access"], function (_exports, _jquery, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin, _checkResourceAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _checkResourceAccess.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(param) {
      return this.store.findRecord("dabtabaccess", param.dabtabaccess_id);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      var dashboardnum = this.modelFor('dashboard-builder.dabtabaccess');
      controller.set("dashboardid", dashboardnum.id);
      controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      controller.set("dabtabaccessid", model.id); // controller.set("dabtabaccesstitle", model.get('title'));

      controller.set('model', model);
      controller.set('tabCon', model.get('tab').split(','));
      controller.set('resourceAccessEnabled', dashboardnum.get('resourceAccessEnabled'));
      /*this.store.queryRecord('application', {appId: controller.get("dashboardtitle")}).then(function(app){
      	self.get('authObject').authorize('authorizer:application', (headerName, headerValue) => {
      		const requestHeaders = {};
      		requestHeaders[headerName] = headerValue;
      	
      		$.ajax({
      			type: "POST",
      			headers: requestHeaders,
      			data:{
      				dashboard: dashboardnum.id,
      				app: app.id
      			},
      			url: self.store.adapterFor('application').get('namespace') + '/dabtabaccesses/getRoles',
      			success: function(response){
      				if(response.roles.length && response.allRoles === false){
      					self.store.query('role', { id: response.roles }).then(function(roles){
      						controller.set('roles', roles);
      					});
      				}
      			},
      			error: function(xhr, status, error){
      				console.log('Error ' + error);
      			}
      		});
      	});
      });*/

      if (dashboardnum.get('resourceAccessEnabled')) {
        controller.set('resourceAccessRestricted', false);
        controller.set('resourceAccessConditionPromise', Ember.RSVP.defer());
        controller.set('isLoadingResourceAccess', true);
        controller.set('modelName', 'dabtabaccess');
        self.populateResourceAccess();
        self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
          controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
          self.checkResourceAccessOnly();

          if (self.get('formContainer.resourceAccess') === '/') {
            self.store.query('organization', {
              isdelete: false
            }).then(function (organizations) {
              self.controller.set('organizationList', organizations);

              if (!Ember.isEmpty(model.get('organizationnum').get('id'))) {
                self.store.query('company', {
                  where: {
                    organizationnum: model.get('organizationnum').get('id'),
                    isdelete: false
                  }
                }).then(function (companies) {
                  self.controller.set('companyList', companies);
                });
              }
            });
          } else if (self.get('formContainer.resourceAccess') === '/org') {
            controller.get('model').set('organizationnum', self.get('optmmSetting.companynum.organizationnum'));
            self.store.query('company', {
              where: {
                organizationnum: model.get('organizationnum').get('id'),
                isdelete: false
              }
            }).then(function (companies) {
              self.controller.set('companyList', companies);
            });
          }

          self.getRoles();
          controller.get('model').addObserver('organizationnum', function (currentmodel) {
            if (currentmodel.get('organizationnum')) {
              self.store.query('company', {
                where: {
                  organizationnum: currentmodel.get('organizationnum').get('id'),
                  isdelete: false
                }
              }).then(function (companies) {
                self.controller.set('companyList', companies);
              });
            }
          });
          /*controller.get('model').addObserver('companynum', function(currentmodel) {
          	if (currentmodel.get('companynum')) {
          		self.getRoles();
          	}
          });*/
        });
      }
    },
    getRoles: function getRoles() {
      var self = this;
      var controller = this.get('controller');

      if (!Ember.isEmpty(controller.get('model.organizationnum.content')) && !Ember.isEmpty(controller.get('model.companynum.content'))) {
        this.store.queryRecord('application', {
          appId: controller.get("dashboardtitle")
        }).then(function (app) {
          _jquery.default.ajax({
            type: "POST",
            data: {
              dashboard: controller.get('dashboardid'),
              app: app.id,
              role: controller.get('model.role.content.id'),
              organizationnum: controller.get('model.organizationnum.content.id'),
              companynum: controller.get('model.companynum.content.id')
            },
            url: self.store.adapterFor('application').get('namespace') + '/dabtabaccesses/getRoles',
            success: function success(response) {
              if (response.roles.length) {
                self.store.query('role', {
                  id: response.roles
                }).then(function (roles) {
                  controller.set('roles', roles);
                });
              } else {
                controller.set('roles', null);
              }
            },
            error: function error(xhr, status, _error) {
              console.log('Error ' + _error);
            }
          });
        });
      }
    },
    actions: {
      updateDabtabaccess: function updateDabtabaccess() {
        var self = this;
        var model = this.get("currentModel");
        model.set('tab', this.controller.get('tabCon'));
        this.store.findRecord('user', self.get('authObject.data.user.id')).then(function (user) {
          model.set('updatedBy', user);
          model.save().then(function () {
            self.transitionTo('dashboard-builder.dabtabaccess');
          });
        });
      }
    }
  });

  _exports.default = _default;
});