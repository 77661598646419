define("client/models/dashboardreport", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    reportName: (0, _attr.default)('string'),
    modelName: (0, _attr.default)('string'),
    recordsPerPage: (0, _attr.default)('number'),
    order: (0, _attr.default)('number'),
    size: (0, _attr.default)('string'),
    whatsql: (0, _attr.default)('string'),
    fromtable: (0, _attr.default)('string'),
    limit: (0, _attr.default)('string'),
    tablecount: (0, _attr.default)('number'),
    isdelete: (0, _attr.default)('boolean'),
    dashboard: (0, _relationships.belongsTo)('dashboard', {
      async: true
    }),
    companynum: (0, _relationships.belongsTo)("company", {
      async: true
    }),
    organizationnum: (0, _relationships.belongsTo)("organization", {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    dashboardreportparamcollections: (0, _relationships.hasMany)('dashboardreportparamcollection', {
      async: true
    }),
    dashboardreportconfigselects: (0, _relationships.hasMany)('dashboardreportconfigselect', {
      async: true
    }),
    dashboardreportaccesses: (0, _relationships.hasMany)('dashboardreportaccess', {
      async: true
    })
    /*,
    dashboardFilters: hasMany('dashboardfilter', {async: true})*/

  });

  _exports.default = _default;
});