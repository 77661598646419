define("client/pods/components/w3oprocess/config-tab/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _actions;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('events', this.get('events'));
      this.set('activities', this.get('activities'));
      this.set('gateways', this.get('gateways'));
      this.set('sequences', this.get('sequences'));
    },
    actions: (_actions = {
      openDeleteModal: function openDeleteModal(contentToBeDeleted) {
        this.set('contentToBeDeleted', contentToBeDeleted);
        (0, _jquery.default)('#delete-modal').modal('toggle');
      },
      deleteNode: function deleteNode(node, type) {
        this.deleteNode(node, type);
      },
      selectGroupActivityTask: function selectGroupActivityTask(selection, index) {
        this.selectGroupActivityTask(selection, index);
      }
    }, _defineProperty(_actions, "openDeleteModal", function openDeleteModal(contentToBeDeleted) {
      this.set('contentToBeDeleted', contentToBeDeleted);
      (0, _jquery.default)('#delete-modal').modal('toggle');
    }), _defineProperty(_actions, "selectGroupActivityEmail", function selectGroupActivityEmail(selection, index) {
      this.selectGroupActivityEmail(selection, index);
    }), _actions)
  });

  _exports.default = _default;
});