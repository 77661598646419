define("client/components/ol-layer-tree-group", ["exports", "client/templates/components/ol-layer-tree-group"], function (_exports, _olLayerTreeGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_defineProperty({
    layout: _olLayerTreeGroup.default,
    tagName: 'div',
    canInitLayers: false,
    canPropagateInitLayers: Ember.computed('canInitLayers', function () {
      return this.get('canInitLayers');
    }),
    isInitLayerGroup: '',
    setup: Ember.on('init', function () {
      /* const layers = this.get('layers')
      console.log("layers in ol-layer-tree-group component: "+layers)
      const exclusiveLayers = layers.filter(layer => layer.get('exclusive'))
      console.log("exclusive layer: "+exclusiveLayers)
      const onExlusiveLayerVisibilityChange = layer => {
        const visible = layer.get('visible')
        if (visible) {
          exclusiveLayers.filter(e => layer !== e)
            .forEach(e => e.set('visible', false))
        }
      }
      console.log("Inside ol layer tree group component")
      exclusiveLayers.forEach(layer => layer.addObserver('visible', onExlusiveLayerVisibilityChange)) */
    }),
    initLayers: function initLayers() {
      /* const layers = this.get('layers')
      console.log("layers in ol-layer-tree-group component: "+layers)
      const exclusiveLayers = layers.filter(layer => layer.get('exclusive'))
      console.log("exclusive layer: "+exclusiveLayers)
      const onExlusiveLayerVisibilityChange = layer => {
        const visible = layer.get('visible')
        if (visible) {
          exclusiveLayers.filter(e => layer !== e)
            .forEach(e => e.set('visible', false))
        }
      }
      console.log("Inside ol layer tree group component")
      exclusiveLayers.forEach(layer => layer.addObserver('visible', onExlusiveLayerVisibilityChange)) */
    },
    observer: Ember.observer('canInitLayers', function () {
      if (this.canInitLayers) {
        var layers = this.get('layers');
        console.log("Inside ol-layer-tree-group component layers (obsrv canInitLayers) : " + layers);
        console.log(layers);
        var exclusiveLayers = layers.filter(function (layer) {
          return layer.get('exclusive');
        });
        console.log("exclusive layer: " + exclusiveLayers);

        var onExlusiveLayerVisibilityChange = function onExlusiveLayerVisibilityChange(layer) {
          var visible = layer.get('visible');

          if (visible) {
            exclusiveLayers.filter(function (e) {
              return layer !== e;
            }).forEach(function (e) {
              return e.set('visible', false);
            });
          }
        };

        console.log("Inside ol layer tree group component before iterate exclusiveLayers...");
        exclusiveLayers.forEach(function (layer) {
          return layer.addObserver('visible', onExlusiveLayerVisibilityChange);
        });
      }
    })
  }, "observer", Ember.observer('layers', function () {
    console.log('Inside ol-layer-tree-group component layers (obsrv layers) isInitLayerGroup: ' + this.get('isInitLayerGroup'));

    if (this.canInitLayers) {
      var layers = this.get('layers');
      console.log("Inside ol-layer-tree-group component layers (obsrv layers): ");
      console.log(layers); //need to fix here exclusive layer

      var subExclusiveLayers = null;
      var exclusiveLayers = layers.filter(function (layer) {
        if ('group_array' in layer) {
          // do not process exclusive for layer group, but must process exclusive for layers in the group
          var layers22 = layer.group_array;
          console.log(layers22);
          var exclusiveInnerLayers = layers22.filter(function (layer) {
            return layer.get('exclusive');
          });
          console.log('exclusiveInnerLayers');
          console.log(exclusiveInnerLayers);
          subExclusiveLayers = exclusiveInnerLayers;
          return layer.get('exclusive');
        } else {
          return layer.get('exclusive');
        }
      });
      console.log("exclusive layers are: ");
      console.log(exclusiveLayers);
      console.log(subExclusiveLayers);
      var theExclusiveLayers = exclusiveLayers.concat(subExclusiveLayers);

      var onExclusiveLayerVisibilityChange = function onExclusiveLayerVisibilityChange(layer) {
        console.log("Inside ol layer tree group component handler for onExclusiveLayerVisibilityChange ...");
        var visible = layer.get('visible');
        console.log(layer);

        if (visible) {
          theExclusiveLayers.filter(function (e) {
            return layer !== e;
          }).forEach(function (e) {
            return e.set('visible', false);
          });
        }
      };

      console.log("Inside ol layer tree group component before iterate exclusiveLayers...");
      theExclusiveLayers.forEach(function (layer) {
        return layer.addObserver('visible', onExclusiveLayerVisibilityChange);
      });
    }
  })));

  _exports.default = _default;
});