define("client/pods/components/w3o-users-roles/form-user/phone-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1NCBRRP3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"phone\"],[8],[0,\"Phone Number \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"phoneIsEmpty\"],[8],[0,\"Phone number cannot be empty\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"phoneForm\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphPhone\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-3 pr-1\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"model\",\"country\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"disabled\"],[\"countryCode\",\"text\",\"form-control\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"value\",\"disabled\"],[\"countryCode\",\"text\",\"form-control text-right pr-2\",[28,\"concat\",[\"+\",[24,[\"model\",\"country\",\"phonecode\"]]],null],true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-9 pl-0\"],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"placeholder\",\"class\",\"value\"],[\"phone\",\"number\",\"Enter Phone Number\",\"form-control\",[24,[\"model\",\"phone\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/phone-number/template.hbs"
    }
  });

  _exports.default = _default;
});