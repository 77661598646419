define("client/models/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // relationships
    roles: _emberData.default.hasMany('role', {
      async: true
    }),
    allowedroutes: _emberData.default.hasMany('allowedroute', {
      async: true
    }),
    appsessions: _emberData.default.hasMany('appsession', {
      async: true
    }),
    theme: _emberData.default.belongsTo('theme', {
      async: true
    }),
    // attributes
    appName: _emberData.default.attr('string'),
    routeName: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    appLogo: _emberData.default.attr('string'),
    appId: _emberData.default.attr('string'),
    sortOrder: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    isExternalLink: _emberData.default.attr('boolean'),
    resourceAccessEnabled: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});