define("client/helpers/is-dashboard-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDashboardMenu = isDashboardMenu;
  _exports.default = void 0;

  /**
   * IF the 'link' is 'd' or starts with "d.%", then RETURN true
   * params[0] must be STRING and a 'link' that {{link-to }} compliant
   */
  function isDashboardMenu(params
  /*, hash*/
  ) {
    if (params[0] === 'd' || params[0].substring(0, 2) === 'd.') {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(isDashboardMenu);

  _exports.default = _default;
});