define("client/models/field", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // the value of "columnDataType" could be 'string', 'number', 'boolean', 'date'
  var _default = _model.default.extend({
    label: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    placeholder: (0, _attr.default)('string', {
      defaultValue: ' '
    }),
    defaultValue: (0, _attr.default)('string'),
    fieldFormat: (0, _attr.default)('string', {
      defaultValue: 'DD-MM-YYYY HH:mm:ss'
    }),
    columnDataType: (0, _attr.default)('string'),
    columnName: (0, _attr.default)('string'),
    secondaryColumnName: (0, _attr.default)('string'),
    relationColumnName: (0, _attr.default)('string'),
    columnToShow: (0, _attr.default)('string'),
    fromAPI: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    apiURL: (0, _attr.default)('string'),
    modelName: (0, _attr.default)('string'),
    valueColumn: (0, _attr.default)('string'),
    labelColumn: (0, _attr.default)('string'),
    minValue: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    maxValue: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    valueStep: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    fieldRow: (0, _attr.default)('number'),
    fieldColumn: (0, _attr.default)('number'),
    fieldLength: (0, _attr.default)('number'),
    required: (0, _attr.default)('boolean'),
    noDuplicate: (0, _attr.default)('boolean'),
    validationType: (0, _attr.default)('string'),
    validationOperator: (0, _attr.default)('string'),
    validationValue: (0, _attr.default)('string'),
    showInList: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    showInChildForm: (0, _attr.default)("boolean", {
      defaultValue: false
    }),
    horizontalStyle: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    customFieldSize: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    customLabelWidth: (0, _attr.default)('number'),
    customFieldWidth: (0, _attr.default)('number'),
    isRelationalField: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    relationalField: (0, _relationships.belongsTo)('field', {
      inverse: 'childrenRelationalField'
    }),
    relationalPropertyName: (0, _attr.default)('string'),
    isOperationRelationField: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    operationRelationType: (0, _attr.default)('string', {
      defaultValue: '>'
    }),
    operationRelationField: (0, _relationships.belongsTo)('field', {
      inverse: 'childrenOperationRelationField'
    }),
    incrementNumber: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    incrementStep: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    incrementStartingNum: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    addRow: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    oldEntryContainer: (0, _attr.default)('string'),
    entryContainer: (0, _attr.default)('string'),
    secondaryEntryContainer: (0, _attr.default)('string'),
    isEmpty: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isInvalid: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    errorMessage: (0, _attr.default)('string'),
    isShown: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isDisabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    showOnCreate: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    showOnView: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    showOnEdit: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    showOnTask: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    enableInTask: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    useValidationAPI: (0, _attr.default)('boolean'),
    validationAPIPath: (0, _attr.default)('string'),
    onLoad: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    onLoadAPI: (0, _attr.default)('string'),
    executeEdit: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    executeTask: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    executeDuplicate: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    realTime: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    realTimeAPI: (0, _attr.default)('string'),
    isEditable: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    afterAction: (0, _attr.default)('string'),
    haveAdditionalLogic: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    fieldContents: (0, _attr.default)('array'),
    conditionContainer: (0, _attr.default)(),
    resourceAccessEnabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    customMessage: (0, _attr.default)('string'),
    //Prefilled Value
    isPrefilled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnLoad: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnChange: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    onLoadSource: (0, _attr.default)('string'),
    onLoadSourceValue: (0, _attr.default)('string'),
    onChangeSource: (0, _attr.default)('string'),
    onChangeSourceValue: (0, _attr.default)('string'),
    fieldToggleDelete: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isRedirect: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    redirectHostName: (0, _attr.default)('string'),
    redirectPath: (0, _attr.default)('string'),
    isOpenOnNewTab: (0, _attr.default)('boolean'),
    booleanContent: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    displayContentInline: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isMultipleFilesUpload: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isRenameFilename: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isBoolean: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    // for calculate 2 fields
    isCalculatedField: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    firstFieldToCalculate: (0, _relationships.belongsTo)('field', {
      async: true,
      inverse: null
    }),
    secondFieldToCalculate: (0, _relationships.belongsTo)('field', {
      async: true,
      inverse: null
    }),
    operatorToCalculateField: (0, _attr.default)('string'),
    monitoredField: (0, _relationships.belongsTo)('field', {
      async: true,
      inverse: 'changedField'
    }),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    formcomponent: (0, _relationships.belongsTo)('formcomponent', {
      async: true
    }),
    changedField: (0, _relationships.hasMany)('field', {
      async: true,
      inverse: 'monitoredField'
    }),
    fieldPrefixes: (0, _relationships.hasMany)('fieldprefix', {
      async: true
    }),
    fieldfilters: (0, _relationships.hasMany)('fieldfilter', {
      async: true,
      inverse: 'field'
    }),
    fieldgroupcontents: (0, _relationships.hasMany)('fieldgroupcontent', {
      async: true
    }),
    fieldtriggers: (0, _relationships.hasMany)('fieldtrigger', {
      async: true,
      inverse: 'field'
    }),
    fieldactionparams: (0, _relationships.hasMany)('fieldactionparam', {
      async: true,
      inverse: 'field'
    }),
    entryListContents: (0, _relationships.hasMany)('entrylistcontent', {
      async: true
    }),
    copyLogic: (0, _relationships.hasMany)('fieldlogic', {
      async: true,
      inverse: 'sourceField'
    }),
    issuedLogic: (0, _relationships.hasMany)('fieldlogic', {
      async: true,
      inverse: 'triggerField'
    }),
    boundLogic: (0, _relationships.hasMany)('fieldlogic', {
      async: true,
      inverse: 'affectedField'
    }),
    childrenRelationalField: (0, _relationships.hasMany)('field', {
      inverse: 'relationalField'
    }),
    childrenOperationRelationField: (0, _relationships.hasMany)('field', {
      inverse: 'operationRelationField'
    }),
    fieldonloadconfigs: (0, _relationships.hasMany)('fieldonloadconfig', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});