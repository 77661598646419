define("client/pods/components/report-generator/user-param/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('fulldate', moment().format('DD-MM-YYYY'));
      this.set('dateYear', this.get('fulldate').split('-')[2]);
      this.set('dateMonth', this.get('fulldate').split('-')[1]);
      console.log(this.get('selectContents'));
    },
    actions: {
      createCustomDate: function createCustomDate(param) {
        var date = moment(param._d).format('DD-MM-YYYY');
        this.set('fulldate', date);
        this.set('dateYear', date.split('-')[2]);
        this.set('dateMonth', date.split('-')[1]);
      },
      changeValueformat: function changeValueformat(param) {
        this.set('fulldate', this.get('fulldate').split('-')[0] + '-' + this.get('dateMonth') + '-' + this.get('dateYear'));

        if (param.get('valueformat') === 'year') {
          param.set('filtervalue', this.get('dateYear'));
        } else if (param.get('valueformat') === 'month') {
          param.set('filtervalue', this.get('dateMonth') + '-' + this.get('dateYear'));
        } else if (param.get('valueformat') === 'date') {
          param.set('filtervalue', this.get('fulldate'));
        }
      },
      deleteParam: function deleteParam(param) {
        this.deleteParam(param);
      },
      addParam: function addParam(param) {
        this.addParam(param);
      },
      saveParam: function saveParam() {
        this.saveParam();
      }
    }
  });

  _exports.default = _default;
});