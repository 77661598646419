define("client/pods/menu-builder/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "client/mixins/w3o-users-roles/modal-actions", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _modalActions, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _applicationRouteMixin.default, _modalActions.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get('authObject.session.isAuthenticated') === false) {
        this.transitionTo('start');
      }
    },
    setupController: function setupController(controller) {
      if (!Ember.isEmpty(this.get('authObject.data.user.id'))) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          controller.set('userInfo', user);
        });
      } //Set variables for nav bar


      if (this.controllerFor('application').get('appID') !== 'Menu') {
        this.controllerFor('application').set('appID', 'Menu');
        this.controllerFor('application').set('menus', [{
          'title': 'Menu Builder',
          'glyph': 'fa-th-list',
          'unique': 'form',
          'subunique': 'subform',
          'theme': '#ffffff',
          'children': [{
            'title': 'Menu List',
            'link': 'menu-builder.index'
          }, {
            'title': 'Theme',
            'link': 'menu-builder.theme'
          }]
        }]);
      }

      controller.set('menu', [{
        'title': 'Menu Builder',
        'glyph': 'fa-th-list',
        'link': 'menu-builder',
        'unique': 'form',
        'subunique': 'subform',
        'theme': '#ffffff'
      }]);
    },
    actions: {}
  });

  _exports.default = _default;
});