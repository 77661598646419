define("client/pods/components/w3office-forms/standard-components/datetime-picker/component-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lrTJUnBA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"component\",[\"general-component/datetime-picker\"],[[\"date\",\"disabled\",\"dateFormat\",\"onChange\",\"disable\"],[[24,[\"containerValue\"]],[24,[\"field\",\"isDisabled\"]],[24,[\"field\",\"fieldFormat\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"disable\"]]]]],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/standard-components/datetime-picker/component-field/template.hbs"
    }
  });

  _exports.default = _default;
});