define("client/pods/users-roles/role-manager/app/role/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/role-manager-app", "axios"], function (_exports, _jquery, _checkUserRole, _roleManagerApp, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _roleManagerApp.default, {
    roleName: '',
    appId: '',
    actionList: null,
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      var Variable = Ember.Object.extend({
        action: null,
        varName: null,
        status: false
      });
      var actions = [Variable.create({
        action: 'Create Role',
        varName: 'canCreateRole',
        status: false
      }), Variable.create({
        action: 'Edit Role',
        varName: 'canEditRole',
        status: false
      }), Variable.create({
        action: 'Delete Role',
        varName: 'canDeleteRole',
        status: false
      })]; // if using dynamic param as route, use below to pass dynamic param
      // this.set('dynamicParam', transition.params);
      // this.set('dynamicKey', '_id');

      this.set('actionList', actions);

      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render({
        outlet: 'role'
      });
      var controller = this.get('controller');
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateRole') {
          controller.set('canCreateRole', action.get('status'));
        }

        if (action.get('varName') === 'canEditRole') {
          controller.set('canEditRole', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteRole') {
          controller.set('canDeleteRole', action.get('status'));
        }
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)("#role").addClass('active');
        (0, _jquery.default)("#role-tab").addClass('active show');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('loading', true);
      controller.set('editRole', false);
      controller.set('createRole', false);
      this.getRoles();
      controller.set('canCreateRole', false);
      controller.set('canEditRole', false);
      controller.set('canDeleteRole', false);
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateRole') {
          controller.set('canCreateRole', action.get('status'));
        }

        if (action.get('varName') === 'canEditRole') {
          controller.set('canEditRole', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteRole') {
          controller.set('canDeleteRole', action.get('status'));
        }
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)('#access').removeClass('active');
        (0, _jquery.default)('#assign').removeClass('active');
        (0, _jquery.default)('#route').removeClass('active');
        (0, _jquery.default)('#app-access').removeClass('active');
        (0, _jquery.default)('#access-tab').removeClass('active show');
        (0, _jquery.default)('#assign-tab').removeClass('active show');
        (0, _jquery.default)('#route-tab').removeClass('active show');
        (0, _jquery.default)('#app-access-tab').removeClass('active show');
      });
    },
    getRoles: function getRoles() {
      var controller = this.get('controller');
      var axiosConfig = {
        method: "GET",
        params: {
          appId: controller.get('appId'),
          page: controller.get('listPage'),
          limit: controller.get('limit')
        },
        url: this.store.adapterFor('application').get('namespace') + '/roles/getRoles'
      };
      (0, _axios.default)(axiosConfig).then(function (response) {
        if (response.status === 200) {
          controller.set('model', response.data.role);
          controller.set('totalPage', response.data.totalPage);
          controller.set('loading', false);

          if (response.data.exceedTotal) {
            controller.set('listPage', 1);
          }

          if (response.data.role.length) {
            controller.set('hasModel', true);
          } else {
            controller.set('hasModel', false);
            controller.set('loading', false);
          }
        }
      }).catch(function (err) {
        console.log("ERROR, while getRoles");
        console.log(err);
      });
    },
    actions: {
      doCreate: function doCreate() {
        var controller = this.get('controller');
        controller.set('createRole', true);
      },
      cancelCreateRole: function cancelCreateRole() {
        var controller = this.get('controller');
        controller.set('createRole', false);
      },
      createRole: function createRole(param) {
        var self = this;
        var controller = this.get('controller');

        if (Ember.isEmpty(param)) {
          controller.set('errorNotif', true);
          document.getElementById("pageError").classList.remove("d-none");
          document.getElementById("glyphPage").classList.remove("d-none");
          setTimeout(function () {
            controller.set('errorNotif', false);
            document.getElementById("pageError").classList.add("d-none");
            document.getElementById("glyphPage").classList.add("d-none");
          }, 2000);
        } else {
          this.store.findRecord('application', this.get('appId')).then(function (application) {
            var role = self.store.createRecord('role', {
              roleName: param,
              application: application
            });
            role.save().then(function (result) {
              controller.set('createRole', false);
              self.getRoles();
            });
          });
        }
      },
      doEdit: function doEdit() {
        var controller = this.get('controller');
        controller.set('editRole', true);
      },
      editRoleName: function editRoleName(role) {
        var controller = this.get('controller');
        this.store.findRecord('role', role.id).then(function (roleupdate) {
          roleupdate.set('roleName', role.roleName);
          roleupdate.save().then(function () {
            controller.set('editRole', false);
          });
        });
      },
      cancelEditRole: function cancelEditRole() {
        var controller = this.get('controller');
        controller.set('editRole', false);
      },
      deleteRole: function deleteRole(role) {
        var self = this;

        _jquery.default.ajax({
          type: "POST",
          data: {
            role: role.id,
            app: this.get('appId')
          },
          url: self.store.adapterFor('application').get('namespace') + '/roles/deleteRole',
          success: function success(response) {
            self.set('roleId', '');
            self.set('controller.role', '');
            self.set('controller.roleName', '');
            self.set('loading', true);
            self.getRoles();
          },
          error: function error(xhr, status, _error) {
            console.log('Error ' + _error);
          }
        });
      },
      changeLimit: function changeLimit() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.getRoles();
      },
      prevList: function prevList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') - 1);
        this.getRoles();
      },
      nextList: function nextList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') + 1);
        this.getRoles();
      },
      goTo: function goTo(param) {
        // this.transitionTo('users-roles.role-manager.app.' + param, this.get('appId'));
        this.goTo(param);
      }
    }
  });

  _exports.default = _default;
});