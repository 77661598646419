define("client/pods/menu-builder/new/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/menu-builder/menu-crud"], function (_exports, _checkUserRole, _menuCrud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _menuCrud.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    authObject: Ember.inject.service('session'),
    model: function model() {
      return this.store.createRecord('menu');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('currentRoute', 'menu-builder');
      this.getAppIds();
    }
  });

  _exports.default = _default;
});