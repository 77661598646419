define("client/pods/process-builder/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3oprocess/process-builder-list"], function (_exports, _checkUserRole, _processBuilderList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _processBuilderList.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Dio
      // Edit date : 28 March 2016
      // Note : 
      this._super();
    },
    model: function model() {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Paska
      // Edit date : 1 November 2016
      // Note : Refactor to Ember 2.8
      return this.store.findAll('process');
    },
    renderTemplate: function renderTemplate() {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Dio
      // Edit date : 28 March 2016
      // Note : 
      this._super();
    },
    setupController: function setupController(controller, model) {
      // Created by : Dio
      // Created date : 28 March 2016
      // Current Programmer : Michael
      // Edit date : 28 March 2016
      // Note : 
      this._super();
    },
    actions: {
      doSort: function doSort(param) {
        // Created by : Michael
        // Created date : 13 July 2016
        // Current Programmer : Michael
        // Edit date : 13 July 2016
        // Note : 
        this.doSort(param);
      },
      changeLimit: function changeLimit() {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Michael
        // Edit date : 28 March 2016
        // Note : 
        this.getProcesses();
      },
      prevList: function prevList() {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Michael
        // Edit date : 28 March 2016
        // Note : 
        this.prevList();
      },
      nextList: function nextList() {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Michael
        // Edit date : 28 March 2016
        // Note : 
        this.nextList();
      },
      gotoInstances: function gotoInstances(id) {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Dio
        // Edit date : 28 March 2016
        // Note :
        this.gotoInstances(id);
      },
      startProcess: function startProcess(process) {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Paska
        // Edit date : 1 November 2016
        // Note : 
        this.startProcess(process);
      },
      storeProcess: function storeProcess(process, index) {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Dio
        // Edit date : 28 March 2016
        // Note : 
        this.storeProcess(process, index);
      },
      selectThisUser: function selectThisUser() {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Dio
        // Edit date : 28 March 2016
        // Note : 
        this.selectThisUser();
      },
      updateDate: function updateDate(mut, date) {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Dio
        // Edit date : 28 March 2016
        // Note : 
        // console.log(mut);
        // console.log(date);
        // console.log('asdasd');
        this.updateDate(mut, date);
      },
      moveTo: function moveTo(param) {
        // Created by : Dio
        // Created date : 28 March 2016
        // Current Programmer : Dio
        // Edit date : 28 March 2016
        // Note : 
        this.moveTo(param);
      }
    }
  });

  _exports.default = _default;
});