define("client/pods/components/w3office-forms/draggable-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKonN6hm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"data-toggle\",\"tooltip\"],[10,\"data-placement\",\"top\"],[10,\"title\",\"Drag and Drop\"],[10,\"class\",\"d-none d-sm-block btn btn-sm btn-primary w-100\"],[10,\"draggable\",\"true\"],[10,\"align\",\"center\"],[10,\"style\",\"margin: 5px 0;\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"span\",false],[12,\"id\",\"draggable\"],[12,\"class\",\"d-block d-sm-none btn btn-primary bump col-12 draggableComponent\"],[12,\"draggable\",\"true\"],[12,\"align\",\"center\"],[3,\"action\",[[23,0,[]],\"changeSelection\"]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/draggable-component/template.hbs"
    }
  });

  _exports.default = _default;
});