define("client/mixins/w3office-forms/form-setting-crud", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      createRecord: function createRecord(controllerProp, modelName, obj, modalName) {
        var tempRecord;

        if (obj) {
          tempRecord = this.store.createRecord(modelName, obj);
        } else {
          tempRecord = this.store.createRecord(modelName);
        }

        if (modelName === "entrygroup") {
          var entrygroupList = this.controller.get("entrygroupList");
          var entrygroupListLength = entrygroupList.length;
          var priorityList = Ember.A();
          var i = 0;

          for (i = 1; i <= entrygroupListLength + 1; i++) {
            priorityList.push(i);
          }

          this.controller.set('priorityList', priorityList);
        }

        tempRecord.set("createdBy", this.controller.get("currentUser"));
        this.controller.set(controllerProp, tempRecord);
        (0, _jquery.default)('#' + modalName + '-props').modal('show');
      },
      updateRecord: function updateRecord(value, controllerProp, modalName) {
        this.controller.set(controllerProp, value);

        if (modalName === "entrygroup") {
          var entrygroupList = this.controller.get("entrygroupList");
          var entrygroupListLength = entrygroupList.length;
          var priorityList = Ember.A();
          var i = 0;

          for (i = 1; i <= entrygroupListLength; i++) {
            priorityList.push(i);
          }

          this.controller.set('priorityList', priorityList);
        }

        (0, _jquery.default)('#' + modalName + '-props').modal('show');
      },
      deleteRecord: function deleteRecord(value, controllerList) {
        this.controller.get(controllerList).removeObject(value);

        if (value.currentState.stateName === 'root.loaded.created.uncommitted') {
          //unload if data is uncommited
          value.unloadRecord();
        } else {
          //delete if data is persisted
          value.deleteRecord();
          value.save();
        }
      },
      persistRecord: function persistRecord(value, modalName, controllerList) {
        value.set("updatedBy", this.controller.get("currentUser"));
        value.save().then(function () {
          (0, _jquery.default)('#' + modalName + '-props').modal('hide');
        });

        if (value.get('isNew')) {
          this.controller.get(controllerList).addObject(value);
        }
      }
    }
  });

  _exports.default = _default;
});