define("client/templates/components/loading-spin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mgzbL023",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 32 32\"],[11,\"width\",[22,\"loadingSvgSize\"]],[11,\"height\",[22,\"loadingSvgSize\"]],[11,\"fill\",[22,\"loadingSvgColor\"]],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"opacity\",\".25\"],[10,\"d\",\"M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4\"],[8],[9],[0,\"\\n  \"],[7,\"path\",true],[10,\"d\",\"M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z\"],[8],[0,\"\\n    \"],[7,\"animateTransform\",true],[10,\"attributeName\",\"transform\"],[10,\"from\",\"0 16 16\"],[10,\"to\",\"360 16 16\"],[10,\"dur\",\"0.8s\"],[10,\"repeatCount\",\"indefinite\"],[10,\"type\",\"rotate\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/loading-spin.hbs"
    }
  });

  _exports.default = _default;
});