define("client/pods/process-manager/my-task/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3oprocess/process-manager-list"], function (_exports, _checkUserRole, _processManagerList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _processManagerList.default, {
    environment: 'my-task',
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController() {
      this._super();

      this.controllerFor("process-manager.my-task").set("showListingIcon", false);
    },
    actions: {
      doSort: function doSort(param) {
        this.doSort(param);
      },
      doSearch: function doSearch() {
        this.doSearch();
      },
      resetFilter: function resetFilter() {
        this.resetFilter();
      },
      changeColumn: function changeColumn(param) {
        this.changeColumn(param);
      },
      changeLimit: function changeLimit(selectedLimit) {
        this.changeLimit(selectedLimit);
      },
      prevList: function prevList() {
        this.prevList();
      },
      nextList: function nextList() {
        this.nextList();
      },
      stopProcess: function stopProcess(userTask, memoText) {
        this.stopProcess(userTask, memoText);
      },
      gotoDetail: function gotoDetail(id) {
        this.gotoDetail(id);
      },
      deleteTask: function deleteTask(mytask) {
        this.deleteTask(mytask);
      },
      deleteAllTask: function deleteAllTask() {
        this.deleteAllTask();
      }
    }
  });

  _exports.default = _default;
});