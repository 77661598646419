define("client/pods/components/menu-builder/menu-paging/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    postLimit: '',
    // LIMIT THE POST TO BE SHOWN 			[PAGINATION]
    currentPage: '',
    // CURRENT PAGE 						[PAGINATION]
    firstList: '',
    // INDEX OF THE FIRST POST IN THE LIST 	[PAGINATION]
    totalPage: '',
    // TOTAL PAGE 							[PAGINATION]
    init: function init() {
      this._super();

      this.modelCon = [];
      var totalModel = this.get('postModel').get('length');
      this.set('currentPage', 1);
      this.set('postLimit', 10);
      this.set('firstList', 0);
      this.set('modelCon', this.get('postModel').slice(this.get('firstList'), this.get('postLimit')));

      if (totalModel === 0) {
        this.set('totalPage', 1);
      } else {
        this.set('totalPage', Math.ceil(totalModel / 10));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('modelCon', []);
      var totalModel = this.get('postModel').get('length');
      this.set('currentPage', 1);
      this.set('postLimit', 10);
      this.set('firstList', 0);
      this.set('modelCon', this.get('postModel').slice(this.get('firstList'), this.get('postLimit')));

      if (totalModel === 0) {
        this.set('totalPage', 1);
      } else {
        this.set('totalPage', Math.ceil(totalModel / 10));
      }
    },
    actions: {
      nextList: function nextList() {
        // GO TO NEXT POST LIST -------------------------------------------------
        if (this.get('currentPage') < this.get('totalPage')) {
          var pageCount = this.get('currentPage');
          var firstCount = this.get('firstList');
          var postCount = this.get('postLimit');
          this.set('currentPage', pageCount + 1);
          this.set('firstList', firstCount + 10);
          this.set('postLimit', postCount + 10);
          this.set('modelCon', this.get('postModel').slice(firstCount + 10, postCount + 10));
        }
      },
      prevList: function prevList() {
        // GO TO PREVIOUS POST LIST ---------------------------------------------
        if (this.get('currentPage') > 1) {
          var pageCount = this.get('currentPage');
          var firstCount = this.get('firstList');
          var postCount = this.get('postLimit');
          this.set('currentPage', pageCount - 1);
          this.set('firstList', firstCount - 10);
          this.set('postLimit', postCount - 10);
          this.set('modelCon', this.get('postModel').slice(firstCount - 10, postCount - 10));
        }
      },
      deleteMenu: function deleteMenu(id, environment) {
        this.deleteMenu(id, environment);
      },
      changeAppFilter: function changeAppFilter(value) {
        this.changeAppFilter(value);
      }
    }
  });

  _exports.default = _default;
});