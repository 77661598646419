define("client/models/formnotification", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    destinationEmail: (0, _attr.default)('string'),
    notificationType: (0, _attr.default)('string'),
    originName: (0, _attr.default)('string'),
    originEmail: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    trigger: (0, _attr.default)('string'),
    recipientType: (0, _attr.default)('string'),
    recipient: (0, _attr.default)('string'),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});