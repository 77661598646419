define("client/pods/components/dashboard-v2/chart-component/chart-gauge/component", ["exports", "jquery", "client/mixins/dashboard/chart-detail-report"], function (_exports, _jquery, _chartDetailReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartDetailReport.default, {
    click_label: null,
    click_value: null,
    drillable: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'is_drilldown') === true) {
        Ember.set(this, 'drillable', true);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var self = this; //console.log('debug gauge');
      //console.log(this.title);
      //console.log(this.type);
      //console.log(this.dataset);
      //console.log(this.column);
      //console.log(this.userinfo);

      var total = this.dataset.filter(function (data) {
        return data.label === 'total';
      });
      var data_total = this.dataset.filter(function (data) {
        return data.label !== 'total';
      });
      var total_value = total[0].value;
      var data_total_value = data_total[0].value;
      var data_total_label = data_total[0].label; //console.log(data_total_value);
      //console.log(data_total_label);

      Ember.set(this, 'click_label', this.column.column1.label);
      Ember.set(this, 'click_value', data_total_label); //console.log(click_label);
      //console.log(click_value);

      var data_total_percent = (data_total_value / total_value * 100).toFixed(2);
      var other_total_percent = (100 - data_total_percent).toFixed(2); //console.log(data_total_percent);
      //console.log(other_total_percent);

      Chart.defaults.doughnutLabels = Chart.helpers.clone(Chart.defaults.doughnut);
      var helpers = Chart.helpers;
      var defaults = Chart.defaults;
      Chart.controllers.doughnutLabels = Chart.controllers.doughnut.extend({
        updateElement: function updateElement(arc, index, reset) {
          var _this = this;

          var chart = _this.chart,
              chartArea = chart.chartArea,
              opts = chart.options,
              animationOpts = opts.animation,
              arcOpts = opts.elements.arc,
              centerX = (chartArea.left + chartArea.right) / 2,
              centerY = (chartArea.top + chartArea.bottom) / 2,
              startAngle = opts.rotation,
              // non reset case handled later
          endAngle = opts.rotation,
              // non reset case handled later
          dataset = _this.getDataset(),
              circumference = reset && animationOpts.animateRotate ? 0 : arc.hidden ? 0 : _this.calculateCircumference(dataset.data[index]) * (opts.circumference / (2.0 * Math.PI)),
              innerRadius = reset && animationOpts.animateScale ? 0 : _this.innerRadius,
              outerRadius = reset && animationOpts.animateScale ? 0 : _this.outerRadius,
              custom = arc.custom || {},
              valueAtIndexOrDefault = helpers.getValueAtIndexOrDefault;

          helpers.extend(arc, {
            // Utility
            _datasetIndex: _this.index,
            _index: index,
            // Desired view properties
            _model: {
              x: centerX + chart.offsetX,
              y: centerY + chart.offsetY,
              startAngle: startAngle,
              endAngle: endAngle,
              circumference: circumference,
              outerRadius: outerRadius,
              innerRadius: innerRadius,
              label: valueAtIndexOrDefault(dataset.label, index, chart.data.labels[index])
            },
            draw: function draw() {
              var ctx = this._chart.ctx,
                  vm = this._view,
                  sA = vm.startAngle,
                  eA = vm.endAngle,
                  opts = this._chart.config.options;
              var labelPos = this.tooltipPosition();
              var segmentLabel = vm.circumference / opts.circumference * 100;
              ctx.beginPath();
              ctx.arc(vm.x, vm.y, vm.outerRadius, sA, eA);
              ctx.arc(vm.x, vm.y, vm.innerRadius, eA, sA, true);
              ctx.closePath();
              ctx.strokeStyle = vm.borderColor;
              ctx.lineWidth = vm.borderWidth;
              ctx.fillStyle = vm.backgroundColor;
              ctx.fill();
              ctx.lineJoin = 'bevel';

              if (vm.borderWidth) {
                ctx.stroke();
              }

              if (vm.circumference > 0.0015) {
                // Trying to hide label when it doesn't fit in segment
                ctx.beginPath();
                ctx.font = helpers.fontString(opts.defaultFontSize, opts.defaultFontStyle, opts.defaultFontFamily);
                ctx.fillStyle = "#190707";
                ctx.textBaseline = "top";
                ctx.textAlign = "center"; // Round percentage in a way that it always adds up to 100%
                //    ctx.fillText(segmentLabel.toFixed(2) + "%", labelPos.x, labelPos.y);
              } //display in the center the total sum of all segments


              var total = dataset.data.reduce(function (sum, val) {
                return sum + val;
              }, 0);
              ctx.fillText('0%', vm.x - vm.x / 2, vm.y + 5);
              ctx.fillText('100%', vm.x + vm.x / 2, vm.y + 5);
              ctx.font = helpers.fontString(42, opts.defaultFontStyle, opts.defaultFontFamily);
              ctx.fillText("".concat(data_total_percent, "%"), vm.x, vm.y - vm.y / 8, 200);
            }
          });
          var model = arc._model;
          model.backgroundColor = custom.backgroundColor ? custom.backgroundColor : valueAtIndexOrDefault(dataset.backgroundColor, index, arcOpts.backgroundColor);
          model.hoverBackgroundColor = custom.hoverBackgroundColor ? custom.hoverBackgroundColor : valueAtIndexOrDefault(dataset.hoverBackgroundColor, index, arcOpts.hoverBackgroundColor);
          model.borderWidth = custom.borderWidth ? custom.borderWidth : valueAtIndexOrDefault(dataset.borderWidth, index, arcOpts.borderWidth);
          model.borderColor = custom.borderColor ? custom.borderColor : valueAtIndexOrDefault(dataset.borderColor, index, arcOpts.borderColor); // Set correct angles if not resetting

          if (!reset || !animationOpts.animateRotate) {
            if (index === 0) {
              model.startAngle = opts.rotation;
            } else {
              model.startAngle = _this.getMeta().data[index - 1]._model.endAngle;
            }

            model.endAngle = model.startAngle + model.circumference;
          }

          arc.pivot();
        }
      });
      var config = {
        type: 'doughnutLabels',
        data: {
          datasets: [{
            data: [data_total_percent, other_total_percent],
            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
            borderColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
            borderWidth: 1
          }],
          labels: [data_total_label, "Empty"]
        },
        options: {
          circumference: Math.PI,
          rotation: 1.0 * Math.PI,
          responsive: true,
          legend: {
            display: true,
            labels: {
              filter: function filter(item, chart) {
                return item.text == null || !item.text.includes('Empty');
              }
            },
            position: 'bottom'
          },
          title: {
            display: true,
            text: this.title
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            enabled: false // callbacks: {
            //     label: function(tooltipItem, data) {
            //     var dataset = data.datasets[tooltipItem.datasetIndex];
            //     var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
            //         return previousValue + currentValue;
            //     });
            //     var currentValue = dataset.data[tooltipItem.index];
            //     //var precentage = Math.floor(((currentValue / total) * 100) + 0.5);
            //     return window.upDownChart.data.labels[tooltipItem.index] + " " + currentValue + "%";
            //     }
            // }

          },
          hover: {
            mode: null
          },
          onClick: function onClick(event, array) {
            //console.log('gauge debug');
            self.openModal(self);
          }
        }
      };
      var ctx = (0, _jquery.default)("#myChartGauge"); //var ctx = document.getElementById('myChart');

      window.upDownChart = new Chart(ctx, config);
    },
    drillDown: function drillDown() {},
    drillUp: function drillUp() {},
    detailReport: function detailReport() {
      var searchColumn = Ember.get(this, 'click_label');
      var searchInput = Ember.get(this, 'click_value'); //console.log(searchColumn);
      //console.log(searchInput);

      Ember.set(this, 'invoiceParam.searchColumn', searchColumn);
      Ember.set(this, 'invoiceParam.searchInput', searchInput);
      this.getInvoice(this);
      this.closeModal(this);
      this.openDetail();
    },
    cancel: function cancel() {
      this.closeModal(this);
    },
    openModal: function openModal(chart) {
      Ember.set(chart, 'isShowingModal', true);
    },
    closeModal: function closeModal(chart) {
      Ember.set(chart, 'isShowingModal', false);
    }
  });

  _exports.default = _default;
});