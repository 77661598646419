define("client/pods/components/w3office-forms/droppable-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['draggableDropzone'],
    classNameBindings: ['dragClass'],
    dragClass: 'deactivated',
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      this.set('dragClass', 'deactivated');
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
      this.set('dragClass', 'activated');
    },
    drop: function drop(event) {
      this.putFirstField();
      this.set('dragClass', 'deactivated');
    } //,
    // actions: {
    // 	pushComponent: function(){
    // 		var self = this;
    // 		var data = this.get('selectedComponent');
    // 		if(!Ember.isEmpty(data))
    // 		{
    // 			var newField;
    // 			var customLabel = '';
    // 			this.sendAction('resetSelection');
    // 			if(!data.get('isForm'))
    // 			{
    // 				if(data.get('path').indexOf('collection-components') !== -1)
    // 				{
    // 					if(data.get('path').indexOf('full-name') !== -1)
    // 					{
    // 						customLabel = 'Full Name';
    // 					}
    // 					else if(data.get('path').indexOf('full-address') !== -1)
    // 					{
    // 						customLabel = 'Full Address';
    // 					}
    // 				}
    // 				//Create new Component on form
    // 				newField = this.store.createRecord('field', {
    // 					formcomponent: data,
    // 					label: customLabel,
    // 					form: this.get('form'),
    // 					fieldRow: this.get('latestRow'),
    // 					fieldColumn: 0,
    // 					fieldLength: 1
    // 				});
    // 				//Add component to be displayed on template
    // 				this.get('componentArray').addObject(newField);
    // 				//Increment Latest Row
    // 				this.set('latestRow', this.get('latestRow')+1);
    // 				this.set('dragClass', 'deactivated');
    // 				newField.save();
    // 			}
    // 			else
    // 			{
    // 				var fieldPromises = Ember.A();
    // 				var lastRow = 0;
    // 				data.get('form').get('fields').forEach(function(formfield){
    // 					if(formfield.get('formcomponent').get('path').indexOf('collection-components') !== -1)
    // 					{
    // 						if(formfield.get('formcomponent').get('path').indexOf('full-name') !== -1)
    // 						{
    // 							customLabel = 'Full Name';
    // 						}
    // 						else if(formfield.get('formcomponent').get('path').indexOf('full-address') !== -1)
    // 						{
    // 							customLabel = 'Full Address';
    // 						}
    // 					}
    // 					lastRow = lastRow < formfield.get('fieldRow') ? formfield.get('fieldRow') : lastRow;
    // 					//Create new Component on form
    // 					newField = self.store.createRecord('field', {
    // 						label: formfield.get('label'),
    // 						description: formfield.get('description'),
    // 						fieldFormat: formfield.get('fieldFormat'),
    // 						columnName: formfield.get('columnName'),
    // 						secondaryColumnName: formfield.get('secondaryColumnName'),
    // 						defaultValue: formfield.get('defaultValue'),
    // 						minValue: formfield.get('minValue'),
    // 						maxValue: formfield.get('maxValue'),
    // 						valueStep: formfield.get('valueStep'),
    // 						placeholder: formfield.get('placeholder'),
    // 						fieldRow: formfield.get('fieldRow'),
    // 						fieldColumn: formfield.get('fieldColumn'),
    // 						fieldLength: formfield.get('fieldLength'),
    // 						required: formfield.get('required'),
    // 						validationType: formfield.get('validationType'),
    // 						validationOperator: formfield.get('validationOperator'),
    // 						validationValue: formfield.get('validationValue'),
    // 						fromAPI: formfield.get('fromAPI'),
    // 						modelName: formfield.get('modelName'),
    // 						valueColumn: formfield.get('valueColumn'),
    // 						labelColumn: formfield.get('labelColumn'),
    // 						form: self.get('form'),
    // 						formcomponent: formfield.get('formcomponent')
    // 					});
    // 					fieldPromises.addObject(newField.save());
    // 				});
    // 				self.set('latestRow', lastRow + 1);
    // 				Ember.RSVP.Promise.all(fieldPromises).then(function(){
    // 					console.log('saved');
    // 					self.sendAction('sortGrid');
    // 				});
    // 			}
    // 		}
    // 	}
    // }

  });

  _exports.default = _default;
});