define("client/models/relationjoin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    equalsymbol: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    //relation
    tablerelationnum: _emberData.default.belongsTo("table", {
      async: true
    }),
    fromtablecolumnnum: _emberData.default.belongsTo("table", {
      async: true
    }),
    totablecolumnnum: _emberData.default.belongsTo("table", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});