define("client/pods/components/w3office-forms/advanced-components/horizontal-slider/component-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b4IvY3Dw",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"x-range-input\",null,[[\"min\",\"max\",\"step\",\"value\",\"action\",\"disabled\"],[[24,[\"field\",\"minValue\"]],[24,[\"field\",\"maxValue\"]],[24,[\"field\",\"valueStep\"]],[24,[\"containerValue\"]],[28,\"action\",[[23,0,[]],\"mutateEntryContainer\"],null],[24,[\"field\",\"isDisabled\"]]]]],false],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[1,[24,[\"field\",\"minValue\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n    \"],[1,[24,[\"field\",\"maxValue\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[22,\"containerValue\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/advanced-components/horizontal-slider/component-field/template.hbs"
    }
  });

  _exports.default = _default;
});