define("client/pods/components/w3o-scheduler/scheduler-param-name/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFINED_PARAMETER_NAME = ["dabKpi"];

  var _default = Ember.Component.extend({
    classNames: ['col-md-6', 'form-group'],
    init: function init() {
      this._super.apply(this, arguments);

      this.set("parameterNameList", DEFINED_PARAMETER_NAME);

      if (DEFINED_PARAMETER_NAME.includes(this.get("param.name"))) {
        this.get("param").set("isOther", false);
        this.set("parameterName", this.get("param.name"));
      } else {
        if (this.get("param.id")) {
          this.get("param").set("isOther", true);
          this.set("parameterName", "others_param_name");
        } else {
          this.get("param").set("isOther", false);
          this.set("param.name", DEFINED_PARAMETER_NAME[0]);
          this.set("parameterName", DEFINED_PARAMETER_NAME[0]);
        }
      }
    },
    actions: {
      parameterNameChanged: function parameterNameChanged(selected) {
        this.set("parameterName", selected);

        if (selected === "others_param_name") {
          this.get("param").setProperties({
            "isOther": true,
            "name": null
          });
        } else {
          this.get("param").setProperties({
            "isOther": false,
            "name": selected
          });
        }
      }
    }
  });

  _exports.default = _default;
});