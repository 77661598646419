define("client/models/formcomponent", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    label: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    minParse: (0, _attr.default)('number'),
    isSelected: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isForm: (0, _attr.default)('boolean', {
      defaultValue: false
    })
    /*,
    field: hasMany('field', {async: true})  */

  });

  _exports.default = _default;
});