define("client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/value-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0mEEmgXm",
    "block": "{\"symbols\":[\"xs\",\"valueContent\"],\"statements\":[[4,\"x-select\",null,[[\"class\",\"value\",\"on-change\"],[\"form-control\",[24,[\"param\",\"filtervalue\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"param\",\"filtervalue\"]]],null]],null]]],{\"statements\":[[0,\"\\t\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/value-select/template.hbs' @ L2:C4) \"],null]],null,{\"statements\":[[0,\"Nothing Selected\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[28,\"log\",[[24,[\"valueContents\"]]],null],false],[0,\"\\n\"],[4,\"each\",[[24,[\"valueContents\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/value-select/template.hbs' @ L5:C5) \"],null]],[[\"value\"],[[23,2,[\"value\"]]]],{\"statements\":[[1,[23,2,[\"value\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/value-select/template.hbs"
    }
  });

  _exports.default = _default;
});