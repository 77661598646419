define("client/pods/users-roles/users/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mHtHm8kE",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"page-header row border-top border-bottom mx-0\"],[10,\"style\",\"margin-bottom: 10px;\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"pull-left\"],[8],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[0,\"Create New User\"],[9],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"component\",[\"w3o-users-roles/form-user\"],[[\"model\",\"secretQuestionModel\",\"countryList\",\"groupModel\",\"isCreate\",\"groupCon\",\"loadingPostalcode\",\"submit\",\"cancel\",\"getInfo\"],[[24,[\"model\"]],[24,[\"secretQuestionModel\"]],[24,[\"countryList\"]],[24,[\"groupModel\"]],[24,[\"isCreate\"]],[24,[\"groupCon\"]],[24,[\"loadingPostalcode\"]],[28,\"route-action\",[\"createUser\"],null],[28,\"route-action\",[\"cancel\"],null],[28,\"route-action\",[\"getInfo\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/users-roles/users/create/template.hbs"
    }
  });

  _exports.default = _default;
});