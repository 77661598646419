define("client/pods/components/w3office-forms/localization-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('lang', null);
      this.set('errorMessage', null);
    },
    actions: {
      saveData: function saveData() {
        var flag = 0;
        this.get('columns').forEach(function (column) {
          if (column.isRequired && Ember.isEmpty(column.value)) {
            flag++;
          }
        });

        if (flag !== 0) {
          this.set('errorMessage', 'Translated column cannot be empty');
          setTimeout(function () {
            this.set('errorMessage', null);
          }, 3000);
        } else {
          this.saveData(this, this.get('modelName'), this.get('parent').get('id'), this.get('parentModelName'), this.get('lang'), this.get('columns'), this.get('id'));
        }
      },
      clearData: function clearData() {
        this.get('columns').forEach(function (column) {
          column.set('value', null);
        });
      },
      fetchLocalizationData: function fetchLocalizationData(param) {
        this.set('lang', param);
        this.set('id', null);
        this.set('langIsLoading', true);
        this.get('columns').forEach(function (column) {
          column.set('value', null);
        });
        this.fetchLocalizationData(this);
      }
    }
  });

  _exports.default = _default;
});