define("client/models/ol-layer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    ready: function ready() {
      var _this = this;

      if (!this.get('id')) {
        this.set('id', Ember.guidFor(this));
      }

      var layer = this.get('layer');
      console.log("hoichong ol-layer model layer is...");
      console.log(layer);
      layer._modelRecord = this;
      this.set('title', layer.get('title'));
      this.set('font_char', layer.get('font_char'));
      this.set('font_color', layer.get('font_color'));
      this.set('minScale', layer.get('minScale'));
      this.set('maxScale', layer.get('maxScale'));
      this.set('visiType', layer.get('visiType'));
      this.set('visible', layer.get('visible'));
      this.set('exclusive', layer.get('exclusive'));
      this.addObserver('visible', function () {
        console.log('CHANGE0 LAYER VISIBILITY');
        layer.set('visible', this.get('visible'));
      });
      layer.on('change:visible', function () {
        console.log('CHANGE LAYER VISIBILITY');

        _this.set('visible', layer.get('visible'));
      });

      if (layer.getLayers) {
        console.log('hoichong ol-layer model looks like LAYERGROUP...'); //console.log(layer.getLayers())

        this.set('isGroup', true);
        var layersArray = layer.getLayers().getArray(); //console.log(layersArray)
        //FIX here

        /*const layers = layersArray.map(layer => { 
          console.log('Trying to process layers in group layers')
          console.log(layer)
          return this.store.createRecord('ol-layer', { layer }) 
        }) */
        //this.set('layers', layers)
      }
    },
    isGroup: attr('boolean', {
      defaultValue: false
    }),
    title: attr('string'),
    font_char: attr('string'),
    font_color: attr('string'),
    minScale: attr('number'),
    maxScale: attr('number'),
    visiType: attr('string'),
    visible: attr('boolean'),
    exclusive: attr('exclusive'),
    layers: hasMany('ol-layer', {
      inverse: null
    })
  });

  _exports.default = _default;
});