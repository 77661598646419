define("client/pods/dashboard-builder/dabtabaccess/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/dashboard-builder/paging-common", "client/mixins/w3office-forms/check-resource-access"], function (_exports, _checkUserRole, _applicationRouteMixin, _authenticatedRouteMixin, _pagingCommon, _checkResourceAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, _pagingCommon.default, _checkResourceAccess.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    setupController: function setupController(controller, model) {
      var self = this;
      var modelQuery;
      var dashboardnum = this.modelFor('dashboard-builder.dabtabaccess');
      var mixinSuper = this._super;
      controller.set('resourceAccessEnabled', dashboardnum.get('resourceAccessEnabled'));

      if (dashboardnum.get('resourceAccessEnabled')) {
        /*
        * 	Resource Access Related
        */
        controller.set('resourceAccessRestricted', false);
        controller.set('resourceAccessConditionPromise', Ember.RSVP.defer());
        controller.set('isLoadingResourceAccess', true);
        controller.set('modelName', 'dabtabaccess');
        self.populateResourceAccess();
        self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
          controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
          var conditionContainer = {};
          conditionContainer['where'] = {};
          conditionContainer.sort = 'id ASC';
          conditionContainer.where.isdelete = false;
          conditionContainer['where'].dashboard = dashboardnum.id;
          self.checkResourceAccessEntries(conditionContainer.where);
          controller.get('resourceAccessConditionPromise').promise.then(function (finish) {
            modelQuery = self.store.query('dabtabaccess', conditionContainer);
            mixinSuper(controller, model, modelQuery);
            controller.set("dashboardid", dashboardnum.id);
            controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
          });
        });
      } else {
        modelQuery = self.store.query('dabtabaccess', {
          where: {
            dashboard: dashboardnum.id,
            isdelete: false
          },
          sort: 'id ASC'
        });
        mixinSuper(controller, model, modelQuery);
        controller.set("dashboardid", dashboardnum.id);
        controller.set("dashboardtitle", dashboardnum.get('dashboardId'));
      }
    },
    actions: {
      deleteTabaccess: function deleteTabaccess(entry) {
        var self = this;
        this.store.findRecord('dabtabaccess', entry.id).then(function (container) {
          self.controller.get('modelConArr').removeObject(entry);
          self.controller.get('modelCon').removeObject(entry);
          self.controller.set('modelCount', self.controller.get('modelConArr').get('length'));

          if (self.controller.get('modelCount') === 0) {
            self.controller.set('totalModel', 1);
          } else {
            self.controller.set('totalPage', Math.ceil(self.controller.get('modelCount') / 10));
          }

          if (self.controller.get('listPage') < self.controller.get('totalPage')) {
            var firstCount = self.controller.get('firstList');
            var postCount = self.controller.get('postLimit');
            self.controller.set('modelCon', self.controller.get('modelConArr').slice(firstCount, postCount));
          }

          container.set('isdelete', true);
          container.save();
        });
      }
    }
  });

  _exports.default = _default;
});