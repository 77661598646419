define("client/models/appaccess", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //attributes
    isdelete: (0, _attr.default)('boolean'),
    //relations
    companynum: (0, _relationships.belongsTo)("company", {
      async: true
    }),
    organizationnum: (0, _relationships.belongsTo)("organization", {
      async: true
    }),
    role: (0, _relationships.belongsTo)('role', {
      async: true
    }),
    application: (0, _relationships.belongsTo)("application", {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});