define("client/mixins/w3o-scheduler/scheduler-detail", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var sortNumber = function sortNumber(a, b) {
    return a - b;
  };

  var _default = Ember.Mixin.create({
    /*
    	Inject session service as authObject
    */
    authObject: Ember.inject.service('session'),

    /*
    Initialize controller variables
    */
    setupController: function setupController() {
      var model = this.get('currentModel');
      var controller = this.get('controller');
      var cron;
      var DoMCon = [];
      var monthCon = [];
      controller.set('model', model);

      if (this.get('environment') === 'create') {
        controller.set('model.isRunning', true);
        controller.set('model.runOnce', false);
        controller.set('model.type', 'schedule');
        controller.set('model.apiType', 'JSON');
        controller.set('startLater', true);
        controller.set('continuous', true);
        controller.set('secondType', 'everySecond');
        controller.set('second', '*');
        controller.set('secondStep', 1);
        controller.set('minuteType', 'everyMinute');
        controller.set('minute', '*');
        controller.set('minuteStep', 1);
        controller.set('hourType', 'everyHour');
        controller.set('hour', '*');
        controller.set('hourStep', 1);
        controller.set('DoMType', 'everyDoM');
        controller.set('DoM', '*');
        controller.set('DoMStep', 1);
        controller.set('monthType', 'everyMonth');
        controller.set('month', '*');
        controller.set('monthStep', 1);
        controller.set('DoWType', 'everyDoW');
        controller.set('DoW', '*');
      } else if (this.get('environment') === 'edit') {
        if (model.get('controller') === 'processtask' && model.get('task') === 'checkTask') {
          controller.set('jobTask', 'taskScheduling');
        }

        controller.set('deletedCon', Ember.A());

        if (model.get('startDate')) {
          controller.set('startLater', false);
        } else {
          controller.set('startLater', true);
        }

        if (model.get('endDate')) {
          controller.set('continuous', false);
        } else {
          controller.set('continuous', true);
        }

        cron = model.get('cron').split(' '); // second

        if (cron[0].indexOf('/') > -1) {
          var second = cron[0].split('/');
          controller.set('secondStep', second[1]);

          if (second[0] === '*') {
            controller.set('second', second[0]);
            controller.set('secondType', 'everyNSecond');
          } else {
            controller.set('second', second[0].split(','));
            controller.set('secondType', 'eachSelectedSecond');
          }
        } else {
          controller.set('secondStep', 1);

          if (cron[0] === '*') {
            controller.set('second', cron[0]);
            controller.set('secondType', 'everySecond');
          } else {
            controller.set('second', cron[0].split(','));
            controller.set('secondType', 'eachSelectedSecond');
          }
        } // minute


        if (cron[1].indexOf('/') > -1) {
          var minute = cron[1].split('/');
          controller.set('minuteStep', minute[1]);

          if (minute[0] === '*') {
            controller.set('minute', minute[0]);
            controller.set('minuteType', 'everyNMinute');
          } else {
            controller.set('minute', minute[0].split(','));
            controller.set('minuteType', 'eachSelectedMinute');
          }
        } else {
          controller.set('minuteStep', 1);

          if (cron[1] === '*') {
            controller.set('minute', cron[1]);
            controller.set('minuteType', 'everyMinute');
          } else {
            controller.set('minute', cron[1].split(','));
            controller.set('minuteType', 'eachSelectedMinute');
          }
        } // hour


        if (cron[2].indexOf('/') > -1) {
          var hour = cron[2].split('/');
          controller.set('hourStep', hour[1]);

          if (hour[0] === '*') {
            controller.set('hour', hour[0]);
            controller.set('hourType', 'everyNHour');
          } else {
            controller.set('hour', hour[0].split(','));
            controller.set('hourType', 'eachSelectedHour');
          }
        } else {
          controller.set('hourStep', 1);

          if (cron[2] === '*') {
            controller.set('hour', cron[2]);
            controller.set('hourType', 'everyHour');
          } else {
            controller.set('hour', cron[2].split(','));
            controller.set('hourType', 'eachSelectedHour');
          }
        } // DoM - Day of Month


        if (cron[3].indexOf('/') > -1) {
          var DoM = cron[3].split('/');
          controller.set('DoMStep', DoM[1]);

          if (DoM[0] === '*') {
            controller.set('DoM', DoM[0]);
            controller.set('DoMType', 'everyNDoM');
          } else {
            DoM[0].split(',').forEach(function (DoMVal) {
              DoMCon.push((parseInt(DoMVal) - 1).toString());
            });
            controller.set('DoM', DoMCon);
            controller.set('DoMType', 'eachSelectedDoM');
          }
        } else {
          controller.set('DoMStep', 1);

          if (cron[3] === '*') {
            controller.set('DoM', cron[3]);
            controller.set('DoMType', 'everyDoM');
          } else {
            cron[3].split(',').forEach(function (DoMVal) {
              DoMCon.push((parseInt(DoMVal) - 1).toString());
            });
            controller.set('DoM', DoMCon);
            controller.set('DoMType', 'eachSelectedDoM');
          }
        } // month


        if (cron[4].indexOf('/') > -1) {
          var month = cron[4].split('/');
          controller.set('monthStep', month[1]);

          if (month[0] === '*') {
            controller.set('month', month[0]);
            controller.set('monthType', 'everyNMonth');
          } else {
            month[0].split(',').forEach(function (monthVal) {
              monthCon.push((parseInt(monthVal) - 1).toString());
            });
            controller.set('month', monthCon);
            controller.set('monthType', 'eachSelectedMonth');
          }
        } else {
          controller.set('monthStep', 1);

          if (cron[4] === '*') {
            controller.set('month', cron[4]);
            controller.set('monthType', 'everyMonth');
          } else {
            cron[4].split(',').forEach(function (monthVal) {
              monthCon.push(parseInt(monthVal).toString());
            });
            controller.set('month', monthCon);
            controller.set('monthType', 'eachSelectedMonth');
          }
        } // DoW - Day of Week


        if (cron[5].indexOf('/') > -1) {
          var DoW = cron[5].split('/');
          controller.set('DoWStep', DoW[1]);

          if (DoW[0] === '*') {
            controller.set('DoW', DoW[0]);
            controller.set('DoWType', 'everyNDoW');
          } else {
            controller.set('DoW', DoW[0].split(','));
            controller.set('DoWType', 'eachSelectedDoW');
          }
        } else {
          controller.set('DoWStep', 1);

          if (cron[5] === '*') {
            controller.set('DoW', cron[5]);
            controller.set('DoWType', 'everyDoW');
          } else {
            controller.set('DoW', cron[5].split(','));
            controller.set('DoWType', 'eachSelectedDoW');
          }
        }
      }

      controller.set('secondList', ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']);
      controller.set('minuteList', ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']);
      controller.set('hourList', ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']);
      controller.set('DoMList', ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']);
      controller.set('monthList', ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      controller.set('DoWList', ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    },

    /**
     * Geneate CRON value
     */
    generateSecond: function generateSecond() {
      var controller = this.controller,
          second;

      if (controller.get("secondType") === "everySecond") {
        second = '*';
      } else if (controller.get("secondType") === "everyNSecond") {
        second = "*/".concat(controller.get('secondStep'));
      } else if (controller.get("secondType") === "eachSelectedSecond") {
        if (controller.get("second").length === 0) {
          second = '*';
        } else {
          controller.get("second").sort(sortNumber);
          second = controller.get("second").toString();
        }
      }

      return second;
    },
    generateMinute: function generateMinute() {
      var controller = this.controller,
          minute;

      if (controller.get("minuteType") === "everyMinute") {
        minute = '*';
      } else if (controller.get("minuteType") === "everyNMinute") {
        minute = "*/".concat(controller.get('minuteStep'));
      } else if (controller.get("minuteType") === "eachSelectedMinute") {
        if (controller.get("minute").length === 0) {
          minute = "*";
        } else {
          controller.get("minute").sort(sortNumber);
          minute = controller.get("minute").toString();
        }
      }

      return minute;
    },
    generateHour: function generateHour() {
      var controller = this.controller,
          hour;

      if (controller.get("hourType") === "everyHour") {
        hour = '*';
      } else if (controller.get("hourType") === "everyNHour") {
        hour = "*/".concat(controller.get('hourStep'));
      } else if (controller.get("hourType") === "eachSelectedHour") {
        if (controller.get("hour").length === 0) {
          hour = '*';
        } else {
          controller.get("hour").sort(sortNumber);
          hour = controller.get("hour").toString();
        }
      }

      return hour;
    },
    generateDoM: function generateDoM() {
      var controller = this.controller,
          DoM;

      if (controller.get("DoMType") === "everyDoM") {
        DoM = '*';
      } else if (controller.get("DoMType") === "everyNDoM") {
        DoM = "*/".concat(controller.get('DoMStep'));
      } else if (controller.get("DoMType") === "eachSelectedDoM") {
        if (controller.get("DoM").length === 0) {
          DoM = "*";
        } else {
          controller.get("DoM").sort(sortNumber);
          DoM = controller.get("DoM").toString();
        }
      }

      return DoM;
    },
    generateMonth: function generateMonth() {
      var controller = this.controller,
          month;

      if (controller.get("monthType") === "everyMonth") {
        month = '*';
      } else if (controller.get("monthType") === "everyNMonth") {
        month = "*/".concat(controller.get('monthStep'));
      } else if (controller.get("monthType") === "eachSelectedMonth") {
        if (controller.get("month").length === 0) {
          month = "*";
        } else {
          controller.get("month").sort(sortNumber);
          month = controller.get("month").toString();
        }
      }

      return month;
    },
    generateDoW: function generateDoW() {
      var controller = this.controller,
          DoW;

      if (controller.get("DoWType") === "everyDoW") {
        DoW = '*';
      } else if (controller.get("DoWType") === "eachSelectedDoW") {
        if (controller.get("DoW").length === 0) {
          DoW = "*";
        } else {
          controller.get("DoW").sort(sortNumber);
          DoW = controller.get("DoW").toString();
        }
      }

      return DoW;
    },
    setType: function setType() {
      // Created by : Michael
      // Created date : 21 April 2016
      // Current Programmer : Michael
      // Edit date : 21 April 2016
      // Note : 
      var controller = this.get('controller');
      controller.set('model.moduleId', null);
      controller.set('jobTask', null);
    },
    addParam: function addParam() {
      var controller = this.get('controller');
      var param = this.store.createRecord('apiparam', {
        name: null,
        value: null
      });
      controller.get('model.apiparams').pushObject(param);
    },
    removeParam: function removeParam(param) {
      var controller = this.get('controller');
      controller.get('model.apiparams').removeObject(param);

      if (this.get('environment') === 'edit') {
        controller.get('deletedCon').push(param);
      }
    },
    setBehavior: function setBehavior(param) {
      var controller = this.get('controller');
      controller.set('model.isRunning', param);

      if (param) {
        controller.set('startLater', true);
        controller.set('model.startDate', null);
      }
    },

    /*
    *   Explanation : Set cron to be scheduled later based on specified date
    */
    setStart: function setStart() {
      var controller = this.get('controller');

      if (controller.get('startLater')) {
        controller.set('startLater', false);
      } else {
        controller.set('startLater', true);
        controller.set('model.startDate', null);
      }
    },
    setEnd: function setEnd() {
      var controller = this.get('controller');

      if (controller.get('continuous')) {
        controller.set('continuous', false);
      } else {
        controller.set('continuous', true);
        controller.set('model.endDate', null);
      }
    },

    /*
    *   Explanation : Set type between 3 type of each frequency, everySecond everyNSecond and eachSelectedSecond
    *   Parameter   : param
    */
    setSecondType: function setSecondType(param) {
      var controller = this.get('controller');

      if (param === 'everySecond') {
        controller.set('secondType', 'everySecond');
        controller.set('second', '*');
      } else if (param === 'everyNSecond') {
        controller.set('secondType', 'everyNSecond');
      } else if (param === 'eachSelectedSecond') {
        controller.set('secondType', 'eachSelectedSecond');

        if (controller.get('second') === '*') {
          controller.set('second', []);
        }
      }
    },
    setMinuteType: function setMinuteType(param) {
      var controller = this.get('controller');

      if (param === 'everyMinute') {
        controller.set('minuteType', 'everyMinute');
        controller.set('minute', '*');
      } else if (param === 'everyNMinute') {
        controller.set('minuteType', 'everyNMinute'); // controller.set('minute', '5');
      } else if (param === 'eachSelectedMinute') {
        controller.set('minuteType', 'eachSelectedMinute');

        if (controller.get('minute') === '*') {
          controller.set('minute', []);
        }
      }
    },
    setHourType: function setHourType(param) {
      var controller = this.get('controller');

      if (param === 'everyHour') {
        controller.set('hourType', 'everyHour');
        controller.set('hour', '*');
      } else if (param === 'everyNHour') {
        controller.set('hourType', 'everyNHour'); // controller.set('hour', '5');
      } else if (param === 'eachSelectedHour') {
        controller.set('hourType', 'eachSelectedHour');

        if (controller.get('hour') === '*') {
          controller.set('hour', []);
        }
      }
    },
    setDoMType: function setDoMType(param) {
      var controller = this.get('controller');

      if (param === 'everyDoM') {
        controller.set('DoMType', 'everyDoM');
        controller.set('DoM', '*');
      } else if (param === 'everyNDoM') {
        controller.set('DoMType', 'everyNDoM'); // controller.set('DoM', '5');
      } else if (param === 'eachSelectedDoM') {
        controller.set('DoMType', 'eachSelectedDoM');

        if (controller.get('DoM') === '*') {
          controller.set('DoM', []);
        }
      }
    },
    setMonthType: function setMonthType(param) {
      var controller = this.get('controller');

      if (param === 'everyMonth') {
        controller.set('monthType', 'everyMonth');
        controller.set('month', '*');
      } else if (param === 'everyNMonth') {
        controller.set('monthType', 'everyNMonth');
      } else if (param === 'eachSelectedMonth') {
        controller.set('monthType', 'eachSelectedMonth');

        if (controller.get('month') === '*') {
          controller.set('month', []);
        }
      }
    },
    setDoWType: function setDoWType(param) {
      // Created by : Michael
      // Created date : 14 April 2016
      // Current Programmer : Michael
      // Edit date : 21 April 2016
      // Note : 
      var controller = this.get('controller');

      if (param === 'everyDoW') {
        controller.set('DoWType', 'everyDoW');
        controller.set('DoW', '*');
      } else if (param === 'eachSelectedDoW') {
        controller.set('DoWType', 'eachSelectedDoW');

        if (controller.get('DoW') === '*') {
          controller.set('DoW', []);
        }
      }
    },

    /*
    *   Explanation : Toggling Second Minute on and off of the chosen integer
    *   Parameter   : param 
    */
    toggleSecond: function toggleSecond(param) {
      var controller = this.get('controller');
      var _param = param;

      if (this.get('environment') === 'edit') {
        _param = param.toString();
      }

      if (controller.get('second').indexOf(_param) > -1) {
        controller.get('second').splice(controller.get('second').indexOf(_param), 1);
      } else {
        controller.get('second').push(_param);
      }
    },
    toggleMinute: function toggleMinute(param) {
      var controller = this.get('controller');
      var _param = param;

      if (this.get('environment') === 'edit') {
        _param = param.toString();
      }

      if (controller.get('minute').indexOf(_param) > -1) {
        controller.get('minute').splice(controller.get('minute').indexOf(_param), 1);
      } else {
        controller.get('minute').push(_param);
      }
    },
    toggleHour: function toggleHour(param) {
      var controller = this.get('controller');
      var _param = param;

      if (this.get('environment') === 'edit') {
        _param = param.toString();
      }

      if (controller.get('hour').indexOf(_param) > -1) {
        controller.get('hour').splice(controller.get('hour').indexOf(_param), 1);
      } else {
        controller.get('hour').push(_param);
      }
    },
    toggleDoM: function toggleDoM(param) {
      var controller = this.get('controller');
      var _param = param;

      if (this.get('environment') === 'edit') {
        _param = param.toString();
      }

      if (controller.get('DoM').indexOf(_param) > -1) {
        controller.get('DoM').splice(controller.get('DoM').indexOf(_param), 1);
      } else {
        controller.get('DoM').push(_param);
      }
    },
    toggleMonth: function toggleMonth(param) {
      var controller = this.get('controller');
      var _param = param;

      if (this.get('environment') === 'edit') {
        _param = param.toString();
      }

      if (controller.get('month').indexOf(_param) > -1) {
        controller.get('month').splice(controller.get('month').indexOf(_param), 1);
      } else {
        controller.get('month').push(_param);
      }
    },
    toggleDoW: function toggleDoW(param) {
      var controller = this.get('controller');
      var _param = param;

      if (this.get('environment') === 'edit') {
        _param = param.toString();
      }

      if (controller.get('DoW').indexOf(_param) > -1) {
        controller.get('DoW').splice(controller.get('DoW').indexOf(_param), 1);
      } else {
        controller.get('DoW').push(_param);
      }
    },

    /*
    *   Explanation : Run Cron after saving model on create and edit schedule
    *   Parameter   : _model, url
    *   Used on     : create, edit
    */
    runCron: function runCron(_model, url) {
      var self = this;

      var _this$get = this.get('authObject.data.authenticated'),
          access_token = _this$get.access_token;

      var accessToken = access_token.replace('Bearer ', '');

      _jquery.default.ajax({
        type: "POST",
        url: url,
        data: {
          id: _model.id,
          apiBaseUrl: _model.get('apiBaseUrl'),
          apiPath: _model.get('apiPath'),
          accessToken: accessToken
        },
        success: function success(res) {
          self.transitionTo('scheduler.index');
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },
    updateSecond: function updateSecond(value) {
      this.controller.set('secondStep', value);
    },
    updateMinute: function updateMinute(value) {
      this.controller.set('minuteStep', value);
    },
    updateHour: function updateHour(value) {
      this.controller.set('hourStep', value);
    },
    updateDoM: function updateDoM(value) {
      this.controller.set('DoMStep', value);
    },
    updateMonth: function updateMonth(value) {
      this.controller.set('monthStep', value);
    },
    actions: {
      setType: function setType() {
        this.setType();
      },
      addParam: function addParam() {
        this.addParam();
      },
      removeParam: function removeParam(param) {
        this.removeParam(param);
      },
      setBehavior: function setBehavior(param) {
        this.setBehavior(param);
      },
      setStart: function setStart() {
        this.setStart();
      },
      setEnd: function setEnd() {
        this.setEnd();
      },
      setSecondType: function setSecondType(param) {
        this.setSecondType(param);
      },
      setMinuteType: function setMinuteType(param) {
        this.setMinuteType(param);
      },
      setHourType: function setHourType(param) {
        this.setHourType(param);
      },
      setDoMType: function setDoMType(param) {
        this.setDoMType(param);
      },
      setMonthType: function setMonthType(param) {
        this.setMonthType(param);
      },
      setDoWType: function setDoWType(param) {
        this.setDoWType(param);
      },
      toggleSecond: function toggleSecond(param) {
        this.toggleSecond(param);
      },
      toggleMinute: function toggleMinute(param) {
        this.toggleMinute(param);
      },
      toggleHour: function toggleHour(param) {
        this.toggleHour(param);
      },
      toggleDoM: function toggleDoM(param) {
        this.toggleDoM(param);
      },
      toggleMonth: function toggleMonth(param) {
        this.toggleMonth(param);
      },
      toggleDoW: function toggleDoW(param) {
        this.toggleDoW(param);
      },
      updateSecond: function updateSecond(value) {
        this.updateSecond(value);
      },
      updateMinute: function updateMinute(value) {
        this.updateMinute(value);
      },
      updateHour: function updateHour(value) {
        this.updateHour(value);
      },
      updateDoM: function updateDoM(value) {
        this.updateDoM(value);
      },
      updateMonth: function updateMonth(value) {
        this.updateMonth(value);
      }
    }
  });

  _exports.default = _default;
});