define("client/pods/dashboard-builder/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EulA0Gly",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-top border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder\"]],{\"statements\":[[0,\"Dashboard\"]],\"parameters\":[]},null],[0,\" - New\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0 mt-3\"],[8],[0,\"\\n\\t\"],[1,[28,\"component\",[\"dashboard-builder/newedit-dashboard\"],[[\"applications\",\"objDashboard\",\"type\",\"actionToRoute\"],[[24,[\"applications\"]],[24,[\"model\"]],\"New\",[28,\"route-action\",[\"saveDashboard\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/dashboard-builder/new/template.hbs"
    }
  });

  _exports.default = _default;
});