define("client/pods/components/w3office-forms/standard-components/datetime-picker/component", ["exports", "jquery", "client/mixins/w3office-forms/base-component"], function (_exports, _jquery, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    /*
    * Normalize date in case it's not stored on Date format
    */
    init: function init() {
      this._super.apply(this, arguments);

      var currentDate = new Date();

      if (this.get("authoring")) {
        this.set("field.currentDate", moment(currentDate).format(this.get("field.fieldFormat")));
      } else {
        if (!Ember.isEmpty(this.get('field').get('entryContainer'))) {
          // if ( this.get('field').get('fieldFormat') === 'DD-MM-YYYY' ) {
          // 	let checkHour = -(this.get('field').get('entryContainer').getTimezoneOffset()/60) + this.get('field').get('entryContainer').getHours();
          // 	let tempEntryContainer;
          // 	if (checkHour > 0 && checkHour < 24) {
          // 		tempEntryContainer = moment(this.get('field').get('entryContainer')).utc().add(-this.get('field').get('entryContainer').getTimezoneOffset(), 'm');
          // 	}
          // 	else {
          // 		tempEntryContainer = this.get('field').get('entryContainer');
          // 	}
          // }
          this.set('containerValue', new Date(this.get('field').get('entryContainer')));
        } else {
          console.log("ini defaultValue");
          console.log(this.get("field.defaultValue"));

          if (this.get("field.defaultValue") && this.get("field.defaultValue") === "true") {
            console.log("hi!");
            this.set("containerValue", currentDate);
            this.send("mutateEntry", currentDate);
          } else {
            this.set('containerValue', null);
          }
        }
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.get('field').get('isDisabled')) {
        (0, _jquery.default)('#dpContainer_' + this.get('field.id')).children().css("width", '100%');
      } else {
        /*Add the field.fieldFormat as placeholder*/
        (0, _jquery.default)('#dpContainer_' + this.get('field.id')).children().children().attr("placeholder", this.get('field').get('fieldFormat'));
      }
    },
    actions: {
      mutateEntry: function mutateEntry(val) {
        this.set('containerValue', val);
        this.setEntryContainer(this.get('field'), this.get('containerValue'));
      }
    }
  });

  _exports.default = _default;
});