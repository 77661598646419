define("client/pods/components/general-component/sidebar-nav/component", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authObject: Ember.inject.service('session'),
    sidebarIsMinimized: false,
    hoverCounter: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        'topNavBarColor': '#2B2B2B',
        'topNavBarFontColor': '#ebebeb',
        'sideNavBarFontColor': '#ebebeb',
        'sideNavBarSelectedFontColor': 'grey',
        'sideNavBarPrimaryColor': '#2B2B2B',
        'sideNavBarSecondaryColor': '#505050',
        'badgeBackgroundColor': '#fff',
        'badgeFontColor': '#777',
        'backgroundColor': '#f5f6f8'
      });
    },
    didRender: function didRender() {
      this.setProperties(this.get("currentTheme"));

      if (this.get("showSideMenubar")) {
        // theme.menuPosition = LEFT SIDE
        if ($('#headernavdesktop-accordion')[0]) {
          if (this.get('appID')) {
            $('#headernavdesktop-accordion').removeClass('d-md-none');
            $("#side-menubar-spacer").removeClass("d-md-none");
            $("#side-menubar-spacer").addClass("d-md-block");
            $("#toggle-menu-button").removeClass("d-md-none");
          } else {
            $('#headernavdesktop-accordion')[0].scrollTop = 0;
            $('#headernavdesktop-accordion').addClass('d-md-none');
            $("#side-menubar-spacer").addClass("d-md-none");
            $("#side-menubar-spacer").removeClass("d-md-block");
            $("#core-main-container").removeClass("main-container-expanded");
            $("#toggle-menu-button").addClass("d-md-none");
          }

          $(".link-click").css({
            "color": this.get('sideNavBarFontColor')
          });
          $(".link-click.active").css({
            "color": this.get('sideNavBarSelectedFontColor')
          });
        }
      } else {
        $('#headernavdesktop-accordion')[0].scrollTop = 0;
        $('#headernavdesktop-accordion').addClass('d-md-none');
        $("#side-menubar-spacer").addClass("d-md-none");
        $("#core-main-container").removeClass("col-md-10");
      }
    },
    mouseEnter: function mouseEnter() {
      if (_environment.default.W3O_collapsible_menu.hoverToExpand === false) {
        return;
      }

      this.set("hoverCounter", this.get("hoverCounter") + 1);
      console.log("innnnn " + this.get("hoverCounter"));
      var sidebarMenu = document.getElementById("headernavdesktop-accordion");

      if (sidebarMenu.classList.contains("sidebar-minimized")) {
        this.set("sidebarIsMinimized", true);
        $("#headernavdesktop-accordion").removeClass("sidebar-minimized");
        $("#side-menubar-spacer").removeClass("sidebar-minimized");
        $("#core-main-container").removeClass("main-container-expanded");
        $("#toggle-menu-button").removeClass("d-md-block");
      } else {
        this.set("sidebarIsMinimized", false);
      }
    },
    mouseLeave: function mouseLeave() {
      if (_environment.default.W3O_collapsible_menu.hoverToExpand === false) {
        return;
      }

      console.log("outttt " + this.get("hoverCounter"));

      if (this.get("sidebarIsMinimized")) {
        $("#headernavdesktop-accordion").addClass("sidebar-minimized");
        $("#side-menubar-spacer").addClass("sidebar-minimized");
        $("#core-main-container").addClass("main-container-expanded");
        $("#toggle-menu-button").addClass("d-md-block");
        this.set("sidebarIsMinimized", false);
      }
    },
    actions: {
      collapseMenu: function collapseMenu(id) {
        var arrow = $("#arrow-" + id)[0];

        if (arrow.classList.contains('rotate-down')) {
          arrow.classList.add('rotate-up');
          arrow.classList.remove('rotate-down');
        } else {
          arrow.classList.add('rotate-down');
          arrow.classList.remove('rotate-up');
        }
      },
      expandSidebarMenu: function expandSidebarMenu() {
        this.expandSidebarMenu();
      },
      hideSidebarOnClick: function hideSidebarOnClick() {
        /**
         * hide-sidebar-mobile = the copied from general-component/header-nav/component.js-showHideSidebar()
        */
        $('#headernavdesktop-accordion').removeClass('show-sidebar');
        $('#headernavdesktop-accordion').addClass('hide-sidebar');
        $('.sidebar-mask').hide();
        $("#side-menubar-spacer").addClass("d-none");
      },
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      }
    }
  });

  _exports.default = _default;
});