define("client/pods/users-roles/role-manager/app/create-app-access/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/create-edit-appaccess", "client/mixins/w3office-forms/check-resource-access"], function (_exports, _checkUserRole, _createEditAppaccess, _checkResourceAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _createEditAppaccess.default, _checkResourceAccess.default, {
    appId: '',
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      this._super();

      this.set('appId', this.controllerFor("users-roles.role-manager.app").get("model.id"));
    },
    // model createRecord on appaccess model
    model: function model() {
      return this.store.createRecord('appaccess');
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render({
        outlet: 'create-app-access'
      });
    },
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set('isCreate', true);
      controller.set('disableSubmit', false);

      this._super();

      this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
        model.set('createdBy', user);
      });
      controller.set('resourceAccessRestricted', false);
      controller.set('resourceAccessConditionPromise', Ember.RSVP.defer());
      controller.set('isLoadingResourceAccess', true);
      controller.set('modelName', 'appaccess');
      self.populateResourceAccess();
      self.get('formContainer.resourceValidationFinished').promise.then(function (finished) {
        controller.set('resourceAccess', self.get('formContainer.resourceAccess'));
        self.checkResourceAccessOnly();

        if (self.get('formContainer.resourceAccess') === '/') {
          self.store.query('organization', {
            isdelete: false
          }).then(function (organizations) {
            self.controller.set('organizationList', organizations);
          });
        } else if (self.get('formContainer.resourceAccess') === '/org') {
          controller.get('model').set('organizationnum', self.get('optmmSetting.companynum.organizationnum'));
          self.store.query('company', {
            where: {
              organizationnum: self.get('optmmSetting.companynum.organizationnum.id'),
              isdelete: false
            }
          }).then(function (companies) {
            self.controller.set('companyList', companies);
          });
        } else {
          controller.get('model').set('organizationnum', self.get('optmmSetting.companynum.organizationnum'));
          controller.get('model').set('companynum', self.get('optmmSetting.companynum'));
        }

        controller.get('model').addObserver('organizationnum', function (currentmodel) {
          self.controller.set('companyList', Ember.A());

          if (currentmodel.get('organizationnum')) {
            self.store.query('company', {
              where: {
                organizationnum: currentmodel.get('organizationnum').get('id'),
                isdelete: false
              }
            }).then(function (companies) {
              self.controller.set('companyList', companies);
            });
          }
        });
      });
    },
    actions: {
      createAppAccess: function createAppAccess(model) {
        this.saveAppAccess(model);
      },
      goTo: function goTo(param) {
        this.goTo(param);
      }
    }
  });

  _exports.default = _default;
});