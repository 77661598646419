define("client/mixins/w3o-users-roles/focus-for-keypress", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    focusForKeypress: Ember.on('didInsertElement', function () {
      //fix for catching key events
      (0, _jquery.default)().attr('tabindex', 0);
      (0, _jquery.default)().focus();
    })
  });

  _exports.default = _default;
});