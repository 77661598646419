define("client/pods/register/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    question: '',
    //this provides a space for new model
    model: function model() {
      return this.store.createRecord('user');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('secretQuestionModel', this.store.findAll('secretquestion'));
    },
    actions: {
      setQuestion: function setQuestion(param) {
        this.set('question', param);
      },
      createUser: function createUser(model) {
        var self = this; //console.log('your data is: ' + model.get('firstName') + model.get('lastName') + model.get('email') + model.get('password'));
        //ajax input the data from template into model user

        _jquery.default.ajax({
          url: '/api/v1/users',
          type: 'POST',
          data: JSON.stringify({
            user: {
              password: model.get('password'),
              email: model.get('email'),
              firstName: model.get('firstName'),
              lastName: model.get('lastName'),
              countryCode: model.get('countryCode'),
              phone: model.get('phone'),
              answer: model.get('answer'),
              userquestion: this.get('question'),
              status: 'Active'
            }
          }),
          contentType: 'application/json'
        }).then(function (response) {
          alert('Register success!');
          self.transitionTo('login'); // _this.get('session').authenticate('authenticator:signup', response);
        }, function (xhr, status, error) {
          // this.set('errorMessage', error);
          // alert('Register failed due to ' + error);
          if (!/\S/.test(model.get('email'))) {
            alert('You must enter email!');
            model.set('email', '');
          }

          if (!/\S/.test(model.get('password'))) {
            alert('You must enter password!');
            model.set('password', '');
          }
        }); // model.save().then(user => this.transitionTo('users.user', user));

      }
    }
  });

  _exports.default = _default;
});