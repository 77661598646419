define("client/transforms/ol-geometry", ["exports", "ember-data", "ol/format"], function (_exports, _emberData, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var format = new _format.GeoJSON();

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return format.readGeometry(serialized);
    },
    serialize: function serialize(deserialized) {
      return format.writeGeometry(deserialized);
    }
  });

  _exports.default = _default;
});