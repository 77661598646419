define("client/pods/users-roles/theme/list/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    model: function model() {
      return this.store.findAll('theme');
    },
    actions: {
      showDuplicateModal: function showDuplicateModal(theme) {
        (0, _jquery.default)("#duplicateModal").modal('show');
        var controller = this.get('controller');
        controller.set('selectedTheme', theme);
      },
      duplicateTheme: function duplicateTheme() {
        var controller = this.get('controller');
        var newTheme = controller.get('selectedTheme').toJSON();
        delete newTheme.id;
        newTheme.themeName = controller.get('newThemeName');
        this.store.createRecord('theme', newTheme).save();
      },
      showDeleteModal: function showDeleteModal(theme) {
        (0, _jquery.default)("#deleteModal").modal('show');
        var controller = this.get('controller');
        controller.set('selectedTheme', theme);
      },
      deleteTheme: function deleteTheme() {
        var controller = this.get('controller');
        controller.get('selectedTheme').destroyRecord();
      }
    }
  });

  _exports.default = _default;
});