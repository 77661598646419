define("client/components/ol-map-panel", ["exports", "client/templates/components/ol-map-panel"], function (_exports, _olMapPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    olmap2svc: Ember.inject.service('ol-mapservice'),
    data_coordinates: null,
    layout: _olMapPanel.default,
    isInitialized: false,
    classNames: ['ol-map-panel'],
    completeMapSetup: function completeMapSetup() {
      var olmap2svc = this.get('olmap2svc');
      var marker = this.get('marker');
      var map = this.get('map');
      console.log("Inside ol map panel component, completeMapSetup map is: " + map);
      console.log("Inside ol map panel component, marker is: " + marker);
      map.setTarget(this.element);
      var initialExtent = this.get('initialExtent');
      if (initialExtent) map.getView().fit(initialExtent, map.getSize()); //if (marker) olmap2svc.plotOnePoint(map, marker)
    },
    didInsertElement: function didInsertElement() {
      //this.get('registerComponent')(this);
      //this.get('finishMapSetup')(this.element);
      var olmap2svc = this.get('olmap2svc');
      var marker = this.get('marker');
      var map = this.get('map'); //const map = olmap2svc.get('map')

      console.log("Inside ol map panel component, didInsertElement map is: " + map);
      console.log('Model providing map marker coordinates...');
      console.log(this.get('positionList'));
      console.log(marker);
      var markerCent = this.get('markerCenter');
      console.log(markerCent);

      if (map !== undefined && markerCent !== undefined) {
        console.log('Zoom to Marker Center');
        var yx = markerCent.map(function (ele) {
          return parseFloat(ele);
        });

        if (isNaN(yx[0]) || isNaN(yx[1])) {
          console.log('NaN doublecheck again...');
        } else {
          var yx1 = yx.slice();
          olmap2svc.plotOnePoint(map, yx);
          olmap2svc.zoomToCenter(yx1);
        }
      }
    },
    observer: Ember.observer('isInitialized', function () {
      var olmap2svc = this.get('olmap2svc');
      var marker = this.get('marker');
      var map = this.get('map');

      if (this.isInitialized) {
        this.get('registerComponent')(this);
        this.get('finishMapSetup')(this.element);
        console.log("Inside ol map panel component, observer isInitialized map is: " + map);
        console.log('MarkerCenter coordinates...');
        var markerCent = this.get('markerCenter');
        console.log(markerCent);

        if (map !== undefined && markerCent !== undefined) {
          console.log('Zoom to Marker Center');
          var yx = markerCent.map(function (ele) {
            return parseFloat(ele);
          });

          if (isNaN(yx[0]) || isNaN(yx[1])) {
            console.log('NaN doublecheck again...');
          } else {
            var yx1 = yx.slice();
            olmap2svc.plotOnePoint(map, yx);
            olmap2svc.zoomToCenter(yx1);
          }
        }
      }
    }),
    willDestroyElement: function willDestroyElement() {
      this.get('deregisterComponent')(); // this._super(...argument);    //remove because error when click home button
    }
  });

  _exports.default = _default;
});