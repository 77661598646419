define("client/mixins/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  	Utilities that may be used in general applications
  */
  var _default = Ember.Mixin.create({
    /*
    * Function to convert base64 to Blob
    * Source -> https://gist.github.com/poeticninja/0e4352bc80bc34fad6f7
    *	Required:
    *	@param string	dataURI 		- dataURI string to be converted
    */
    dataURItoBlob: function dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;

      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      } // separate out the mime component


      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // write the bytes of the string to a typed array

      var ia = new Uint8Array(byteString.length);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString
      });
    },

    /**
     * Convert array of native object into array of Ember Object.
     * Only shallow convert.
     * @param {Array} arr 
     */
    toEmberObject: function toEmberObject(arr) {
      var newArr = [];
      arr.forEach(function (each) {
        newArr.push(Ember.Object.create(each));
      });
      return newArr;
    }
  });

  _exports.default = _default;
});