define("client/pods/components/w3office-forms/access-limit-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.valueTypeList = [{
        name: 'Inputted Value',
        value: 'inputted-value'
      }, {
        name: 'Initializer',
        value: 'initializer'
      }, {
        name: 'Parent Property',
        value: 'parent-property'
      }, {
        name: 'Form Property',
        value: 'form-property'
      }, {
        name: 'Current Date',
        value: 'current-date'
      }, {
        name: 'API Validation',
        value: 'api-validation'
      }];
      this.comparatorList = [{
        name: 'Equal To',
        value: '='
      }, {
        name: 'Not',
        value: '!'
      }, {
        name: 'Greater Than',
        value: '>'
      }, {
        name: 'Less Than',
        value: '<'
      }, {
        name: 'Contains',
        value: '()'
      }, {
        name: 'Does Not Contains',
        value: '!()'
      }, {
        name: 'Is Empty',
        value: '[]'
      }, {
        name: 'Is Not Empty',
        value: '![]'
      }];
    },
    actions: {
      saveConfiguration: function saveConfiguration() {
        /*
        	Persisting record via mixins/w3office-forms/form-setting-crud.js
        	persistRecord: function(value, modalName, controllerList)
        */
        this.persistRecord(this.get('accesslimit'), 'access-limit', 'accesslimitList');
      },
      discardChanges: function discardChanges() {
        if (Ember.isEmpty(this.get('accesslimit').id)) {
          //unload if data is uncommited
          this.get('accesslimit').unloadRecord();
        } else {
          //rollback if data is persisted
          this.get('accesslimit').rollbackAttributes();
        }

        (0, _jquery.default)('#access-limit-props').modal('hide');
      }
    }
  });

  _exports.default = _default;
});