define("client/pods/components/w3oprocess/process-canvas/component", ["exports", "jquery", "jsplumb"], function (_exports, _jquery, _jsplumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    gatewaySequence: false,
    activitySequence: false,
    gatewaySeqLab: '',
    sequenceID: '',
    sequenceInfo: '',
    targetType: '',
    gatewaySeqVal: null,
    activitySeqVal: null,
    loading: true,
    selectedNode: null,
    selectedType: 'event_start',
    didInsertElement: function didInsertElement() {
      var self = this;
      var mostTop = 0,
          mostLeft = 0;
      var top = 0,
          left = 0; // Ember.run.later((function() {

      this.get('initializeConfig').promise.then(function () {
        if (self.get('model.processevents')) {
          self.get('model.processevents').forEach(function (event) {
            var width = event.get('width');
            var height = event.get('height');

            if (event.get('posY') < mostTop) {
              mostTop = event.get('posY');
            }

            if (event.get('posX') < mostLeft) {
              mostLeft = event.get('posX');
            }

            var container = (0, _jquery.default)('.event-item-' + event.get('eventIndex'));
            (0, _jquery.default)(container).draggable({
              drag: function drag() {
                _jsplumb.jsPlumb.repaintEverything();
              },
              stop: function stop() {
                var position = (0, _jquery.default)(this).position();

                if (position.top < 0) {
                  (0, _jquery.default)(container).css({
                    top: 20
                  });
                }

                if (position.left < 0) {
                  (0, _jquery.default)(container).css({
                    left: 20
                  });
                }
              }
            });

            _jsplumb.jsPlumb.ready(function () {
              _jsplumb.jsPlumb.setDraggable(container, false);
            });

            width += 10;
            height += 10;
            event.setProperties({
              borderWidth: width,
              borderHeight: height
            });
          });
        }

        if (self.get('model.processactivities')) {
          self.get('model.processactivities').forEach(function (activity) {
            var width = activity.get('width');
            var height = activity.get('height');

            if (activity.get('posY') < mostTop) {
              mostTop = activity.get('posY');
            }

            if (activity.get('posX') < mostLeft) {
              mostLeft = activity.get('posX');
            }

            var container = (0, _jquery.default)('.activity-item-' + activity.get('activityIndex'));
            (0, _jquery.default)(container).draggable({
              drag: function drag() {
                _jsplumb.jsPlumb.repaintEverything();
              },
              stop: function stop() {
                var position = (0, _jquery.default)(this).position();

                if (position.top < 0) {
                  (0, _jquery.default)(container).css({
                    top: 20
                  });
                }

                if (position.left < 0) {
                  (0, _jquery.default)(container).css({
                    left: 20
                  });
                }
              }
            });

            _jsplumb.jsPlumb.ready(function () {
              _jsplumb.jsPlumb.setDraggable(container, false);
            });

            if (activity.get('type') === 'task') {
              width += 19;
              height += 46;
            } else if (activity.get('type') === 'sub') {
              width += 19;
              height += 70;
            } else if (activity.get('type') === 'call') {
              width += 23;
              height += 50;
            }

            activity.setProperties({
              borderWidth: width,
              borderHeight: height
            });
          });
        }

        if (self.get('model.processgateways')) {
          self.get('model.processgateways').forEach(function (gateway) {
            var width = gateway.get('width');
            var height = gateway.get('height');

            if (gateway.get('posY') < mostTop) {
              mostTop = gateway.get('posY');
            }

            if (gateway.get('posX') < mostLeft) {
              mostLeft = gateway.get('posX');
            }

            var container = (0, _jquery.default)('.gateway-item-' + gateway.get('gatewayIndex'));
            (0, _jquery.default)(container).draggable({
              drag: function drag() {
                _jsplumb.jsPlumb.repaintEverything();
              },
              stop: function stop() {
                var position = (0, _jquery.default)(this).position();

                if (position.top < 0) {
                  (0, _jquery.default)(container).css({
                    top: 20
                  });
                }

                if (position.left < 0) {
                  (0, _jquery.default)(container).css({
                    left: 20
                  });
                }
              }
            });

            _jsplumb.jsPlumb.ready(function () {
              _jsplumb.jsPlumb.setDraggable(container, false);
            });

            width += 10;
            height += 10;
            gateway.setProperties({
              borderWidth: width,
              borderHeight: height
            });
          });
        }

        if (mostTop < 0) {
          mostTop = Math.abs(mostTop) + 20;
        }

        if (mostLeft < 0) {
          mostLeft = Math.abs(mostLeft) + 20;
        }

        console.log('MAX Y: ' + mostTop);
        console.log('MAX X: ' + mostLeft);

        if (self.get('model.processevents')) {
          self.get('model.processevents').forEach(function (event) {
            top = event.get('posY');
            top += mostTop;
            event.set('posY', top);
            left = event.get('posX');
            left += mostLeft;
            event.set('posX', left);
          });
        }

        if (self.get('model.processactivities')) {
          self.get('model.processactivities').forEach(function (activity) {
            top = activity.get('posY');
            top += mostTop;
            activity.set('posY', top);
            left = activity.get('posX');
            left += mostLeft;
            activity.set('posX', left);
          });
        }

        if (self.get('model.processgateways')) {
          self.get('model.processgateways').forEach(function (gateway) {
            top = gateway.get('posY');
            top += mostTop;
            gateway.set('posY', top);
            left = gateway.get('posX');
            left += mostLeft;
            gateway.set('posX', left);
          });
        }

        _jsplumb.jsPlumb.ready(function () {
          _jsplumb.jsPlumb.importDefaults({
            Anchor: 'Continuous',
            ConnectionOverlays: [["Arrow", {
              width: 15,
              length: 15,
              location: 1,
              foldback: 0.8,
              id: "arrow"
            }]],
            Connector: 'Flowchart',
            Endpoint: ["Dot", {
              radius: 5,
              hoverClass: 'endpoint-hover'
            }]
          });

          _jsplumb.jsPlumb.setContainer((0, _jquery.default)('#work-canvas'));

          Ember.run.later(function () {
            if (self.get('model.processsequences')) {
              if (self.get('model.processsequences').get('length') > 0) {
                self.get('model.processsequences').forEach(function (sequence) {
                  var connection = _jsplumb.jsPlumb.connect({
                    source: sequence.get('source'),
                    target: sequence.get('target'),
                    detachable: false
                  });

                  connection.id = sequence.get('sequenceId');
                });
              } else {
                if (self.get('sequences').get('length') > 0) {
                  self.get('sequences').forEach(function (sequence) {
                    var connection = _jsplumb.jsPlumb.connect({
                      source: sequence.get('source'),
                      target: sequence.get('target'),
                      detachable: false
                    });

                    connection.id = sequence.get('sequenceId');
                  });
                }
              }
            }

            if (self.get('model.processactivities')) {
              self.get('model.processactivities').forEach(function (activity) {
                if (activity.get('event')) {
                  _jsplumb.jsPlumb.getConnections({
                    source: activity.get('activityId')
                  }).forEach(function (connection) {
                    if (connection.id === activity.get('eventSequenceId')) {
                      if (activity.get('event') === 'timer') {
                        connection.endpoints[0].addOverlay(["Custom", {
                          create: function create(component) {
                            return (0, _jquery.default)("<img class='process-item node-event' src='/assets/images/bpmn/intermediate_timer_nonInterupting.png'>");
                          },
                          location: 0.7
                        }]);
                      } else if (activity.get('event') === 'message') {
                        connection.endpoints[0].addOverlay(["Custom", {
                          create: function create(component) {
                            return (0, _jquery.default)("<img class='process-item node-event' src='/assets/images/bpmn/intermediate_message_nonInterupting.png'>");
                          },
                          location: 0.7
                        }]);
                      }
                    }
                  });
                }
              });
            }

            _jsplumb.jsPlumb.bind("connection", function (info) {
              if (!self.get('generatingXML')) {
                if (self.get('isDestroyed') === false) {
                  self.set('sequenceID', 'sequence-' + self.get('sequenceIdent'));

                  if ((0, _jquery.default)(info.target).parent().hasClass('event')) {
                    self.set('targetType', 'event');
                  } else if ((0, _jquery.default)(info.target).parent().hasClass('activity')) {
                    self.set('targetType', 'activity');
                  } else if ((0, _jquery.default)(info.target).parent().hasClass('gateway')) {
                    self.set('targetType', 'gateway');
                  }

                  self.set('gatewaySequence', false);
                  self.set('activitySequence', false);

                  if ((0, _jquery.default)(info.source).parent().hasClass('gateway')) {
                    self.set('gatewaySequence', true);
                  } else if ((0, _jquery.default)(info.source).parent().hasClass('activity')) {
                    self.set('activitySequence', true);
                  }

                  self.set('gatewaySeqVal', null);
                  self.set('activitySeqVal', null);
                  self.set('gatewaySeqLab', '');
                  (0, _jquery.default)('#create-sequence-modal').modal({
                    backdrop: 'static',
                    keyboard: false
                  });
                  self.set('sequenceInfo', info);
                }
              }
            });

            _jsplumb.jsPlumb.bind("connectionDetached", function (info) {
              self.deleteSequence(info);
            });

            self.set('loading', false);
          }, 1000);
        });
      }); // }), 1000);
    },
    keyUp: function keyUp(e) {
      var container, symbol;
      var target = e.target;

      if ((0, _jquery.default)(target).hasClass('node-activity')) {
        target.style.height = "1px";
        target.style.height = 5 + target.scrollHeight + "px";
        container = (0, _jquery.default)(target).prev().prev();
        container.css({
          'height': target.scrollHeight + 18
        });

        if ((0, _jquery.default)(target).hasClass('node-sub')) {
          symbol = (0, _jquery.default)(target).next();
          symbol.css({
            'top': target.scrollHeight - 24
          });
        }
      }
    },
    actions: {
      selectNode: function selectNode(node, nodeType) {
        var type = nodeType.split('_');
        var nodeClass = '.node-' + type[0];
        (0, _jquery.default)('.node-option').css({
          'border-color': 'rgba(0, 0, 0, 0)'
        });

        if (type[0] === 'event') {
          if ((0, _jquery.default)(nodeClass).prev('.eventId-' + node.get('eventIndex')).css('border-color') === 'rgba(0, 0, 0, 0)') {
            (0, _jquery.default)(nodeClass).prev('.eventId-' + node.get('eventIndex')).css({
              'border-color': 'rgb(0, 0, 0)'
            });
            (0, _jquery.default)('#canvas-option').collapse('show');
            this.set('selectedNode', node);
            this.set('selectedType', nodeType);
            (0, _jquery.default)('.panel-collapse').collapse('hide');
            (0, _jquery.default)('#event-collapse-' + node.get('eventIndex')).collapse('toggle');
            (0, _jquery.default)('#config-tab a[href="#event-tab"]').tab('show');
          } else {
            (0, _jquery.default)(nodeClass).prev('.eventId-' + node.get('eventIndex')).css({
              'border-color': 'rgba(0, 0, 0, 0)'
            });
            (0, _jquery.default)('#canvas-option').collapse('hide');
            this.set('selectedNode', null);
            (0, _jquery.default)('.panel-collapse').collapse('hide');
            (0, _jquery.default)('#config-tab a[href="#general-tab"]').tab('show');
          }
        } else if (type[0] === 'activity') {
          if ((0, _jquery.default)(nodeClass).prev().prev().prev('.activityId-' + node.get('activityIndex')).css('border-color') === 'rgba(0, 0, 0, 0)') {
            (0, _jquery.default)(nodeClass).prev().prev().prev('.activityId-' + node.get('activityIndex')).css({
              'border-color': 'rgb(0, 0, 0)'
            });
            (0, _jquery.default)('#canvas-option').collapse('hide');
            this.set('selectedNode', node);
            (0, _jquery.default)('.panel-collapse').collapse('hide');
            (0, _jquery.default)('#activity-collapse-' + node.get('activityIndex')).collapse('show');
            (0, _jquery.default)('#config-tab a[href="#activity-tab"]').tab('show');
          } else {
            (0, _jquery.default)(nodeClass).prev().prev().prev('.activityId-' + node.get('activityIndex')).css({
              'border-color': 'rgba(0, 0, 0, 0)'
            });
            (0, _jquery.default)('#canvas-option').collapse('hide');
            this.set('selectedNode', null);
            (0, _jquery.default)('.panel-collapse').collapse('hide');
            (0, _jquery.default)('#config-tab a[href="#general-tab"]').tab('show');
          }
        } else if (type[0] === 'gateway') {
          if ((0, _jquery.default)(nodeClass).prev('.gatewayId-' + node.get('gatewayIndex')).css('border-color') === 'rgba(0, 0, 0, 0)') {
            (0, _jquery.default)(nodeClass).prev('.gatewayId-' + node.get('gatewayIndex')).css({
              'border-color': 'rgb(0, 0, 0)'
            });
            (0, _jquery.default)('#canvas-option').collapse('hide');
            this.set('selectedNode', node);
            (0, _jquery.default)('.panel-collapse').collapse('hide');
            (0, _jquery.default)('#gateway-collapse-' + node.get('gatewayIndex')).collapse('show');
            (0, _jquery.default)('#config-tab a[href="#gateway-tab"]').tab('show');
          } else {
            (0, _jquery.default)(nodeClass).prev('#gatewayId-' + node.get('gatewayIndex')).css({
              'border-color': 'rgba(0, 0, 0, 0)'
            });
            (0, _jquery.default)('#canvas-option').collapse('hide');
            this.set('selectedNode', null);
            (0, _jquery.default)('.panel-collapse').collapse('hide');
            (0, _jquery.default)('#config-tab a[href="#general-tab"]').tab('show');
          }
        }
      },
      changeNode: function changeNode(nodeType) {
        var type = nodeType.split('_');

        if (type[0] === 'event') {
          if (type[1] === 'start') {
            if (type[2] === 'none') {
              this.set('selectedNode.subType', 'none');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start.png');
            } else if (type[2] === 'message') {
              this.set('selectedNode.subType', 'message');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_message.png');
            } else if (type[2] === 'timer') {
              this.set('selectedNode.subType', 'timer');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_timer.png');
            } else if (type[2] === 'conditional') {
              this.set('selectedNode.subType', 'conditional');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_conditional.png');
            } else if (type[2] === 'signal') {
              this.set('selectedNode.subType', 'signal');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_signal.png');
            } else if (type[2] === 'multiple') {
              this.set('selectedNode.subType', 'multiple');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_multiple.png');
            } else if (type[2] === 'parallel') {
              this.set('selectedNode.subType', 'parallel');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_parallel.png');
            } else if (type[2] === 'escalation') {
              this.set('selectedNode.subType', 'escalation');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_escalation.png');
            } else if (type[2] === 'error') {
              this.set('selectedNode.subType', 'error');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_error.png');
            } else if (type[2] === 'compensation') {
              this.set('selectedNode.subType', 'compensation');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/start_compensation.png');
            }
          } else if (type[1] === 'intermediate') {
            if (type[2] === 'none') {
              this.set('selectedNode.subType', 'none');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/intermediate.png');
            } else if (type[2] === 'timer') {
              this.set('selectedNode.subType', 'timer');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/intermediate_timer.png');
            } else if (type[2] === 'message') {
              if (type[3] === 'catch') {
                this.set('selectedNode.subType', 'message_catch');
                this.set('selectedNode.imgUrl', '/assets/images/bpmn/intermediate_message_catch.png');
              } else {
                this.set('selectedNode.subType', 'message_throw');
                this.set('selectedNode.imgUrl', '/assets/images/bpmn/intermediate_message_throw.png');
              }
            }
          } else if (type[1] === 'end') {
            if (type[2] === 'none') {
              this.set('selectedNode.subType', 'none');
              this.set('selectedNode.imgUrl', '/assets/images/bpmn/end.png');
            }
          }
        }
      },
      addSequence: function addSequence() {
        var info = this.get('sequenceInfo');

        if (this.get('gatewaySequence')) {
          var id = this.get('sequenceInfo.connection.canvas.nextSibling.id');
          (0, _jquery.default)('div#' + id).text(this.get('gatewaySeqLab'));
          this.createSequence(info, this.get('sequenceID'), this.get('targetType'), this.get('gatewaySequence'), this.get('gatewaySeqVal'), this.get('gatewaySeqLab'));
        } else if (this.get('activitySequence')) {
          this.createSequence(info, this.get('sequenceID'), this.get('targetType'), this.get('activitySequence'), this.get('activitySeqVal'), null);
        } else {
          this.createSequence(info, this.get('sequenceID'), this.get('targetType'), null, null, null);
        }

        var count = this.get('model.sequenceCount');
        count++;
        this.set('model.sequenceCount', count);
        var count2 = this.get('sequenceIdent');
        count2++;
        this.set('sequenceIdent', count2);
      },
      removeSequence: function removeSequence() {
        var info = this.get('sequenceInfo');

        _jsplumb.jsPlumb.ready(function () {
          _jsplumb.jsPlumb.deleteConnection(info.connection);
        });
      }
    }
  });

  _exports.default = _default;
});