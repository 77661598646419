define("client/helpers/and-condition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.andCondition = andCondition;
  _exports.default = void 0;

  function andCondition(params
  /*, hash*/
  ) {
    if (params[0] && params[1]) {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(andCondition);

  _exports.default = _default;
});