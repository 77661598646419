define("client/pods/users-roles/route", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-users-roles/modal-actions"], function (_exports, _jquery, _applicationRouteMixin, _authenticatedRouteMixin, _modalActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _applicationRouteMixin.default, _modalActions.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      if (this.get('authObject.data.user')) {
        if (this.get('authObject.data.user.status') !== 'Active') {
          this.invalidateSession();
        }
      } else {
        this.transitionTo('start');
      }

      if (this.get('authObject.data.user.authEnable') && !this.get('authObject.session.content.authenticated.isAuth')) {
        this.transitionTo('authenticate');
      }
    },
    setupController: function setupController(controller) {
      var _this = this;

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var session = Ember.Object.create({
            user: user
          });
          controller.set('session', session); //set session
        });
        this.store.findRecord('application', 1).then(function (app) {
          _this.set('appSetting.appId', app.id);

          _this.set('appSetting.appName', app.get('appName'));

          _this.set('appSetting.resourceAccessEnabled', app.get('resourceAccessEnabled'));
        });
      } // Set variables for nav bar


      if (this.controllerFor('application').get('appID') !== 'Apps, Roles, Users') {
        this.controllerFor('application').set('appID', 'Apps, Roles, Users');
        this.controllerFor('application').set('menus', []);
        this.set('defaultMenus', [{
          'title': 'App Management',
          'unique': 'app',
          'subunique': 'subapp',
          'glyph': 'fa-database',
          'theme': '#e64759',
          'color': '#e64759',
          'borderColor': '#e64759',
          'defaultSubMenus': [{
            'title': 'Application List',
            'link': 'users-roles.role-manager.index'
          }, {
            'title': 'New Application',
            'link': 'users-roles.role-manager.create'
          }],
          'children': []
        }, {
          'title': 'Role Management',
          'unique': 'role',
          'subunique': 'subrole',
          'glyph': 'fa-list',
          'theme': '#f39c12',
          'color': '#f39c12',
          'borderColor': '#f39c12',
          'defaultSubMenus': [{
            'title': 'Role List',
            'link': 'users-roles.role-manager.app.role',
            'param': this.get('appSetting.appId')
          }, {
            'title': 'Route Management',
            'link': 'users-roles.role-manager.app.route',
            'param': this.get('appSetting.appId')
          }, {
            'title': 'Grant/Revoke Access',
            'link': 'users-roles.role-manager.app.access',
            'param': this.get('appSetting.appId')
          }, {
            'title': 'Assign Role to Groups',
            'link': 'users-roles.role-manager.app.assign',
            'param': this.get('appSetting.appId')
          }, {
            'title': 'Manage App Access',
            'link': 'users-roles.role-manager.app.app-access',
            'param': this.get('appSetting.appId')
          }],
          'children': []
        }, {
          'title': 'User Management',
          'unique': 'user',
          'subunique': 'subuser',
          'glyph': 'fa-user',
          'theme': '#4682b4',
          'color': '#4682b4',
          'borderColor': '#4682b4',
          'defaultSubMenus': [{
            'title': 'User List',
            'link': 'users-roles.users.index'
          }, {
            'title': 'Create New User',
            'link': 'users-roles.users.create'
          }, {
            'title': 'Assign Group to Users',
            'link': 'users-roles.groups'
          }, {
            'title': 'Create New Goup',
            'link': 'users-roles.groups.create'
          }],
          'children': []
        }, // {
        //     'title': 'Export / Import',
        //     'unique': 'exportimport',
        //     'subunique': 'subexportimport',
        //     'glyph': 'fa-folder-open',
        //     'theme': '#eecc99',
        //     'link': 'users-roles.exportimport'
        // },
        {
          'title': 'Config',
          'unique': 'config',
          'subunique': 'subconfig',
          'glyph': 'fa-cog',
          'theme': '#B4F3EA',
          'link': 'users-roles.config'
        }, {
          'title': 'Theme Configuration',
          'unique': 'themeconfig',
          'subunique': 'subthemeconfig',
          'glyph': 'fa-palette',
          'theme': '#B4F3EA',
          'link': 'users-roles.theme.list'
        }]);
        var menuList = "('users-roles.users.index', 'users-roles.users.create', 'users-roles.groups', 'users-roles.groups.create', 'users-roles.role-manager.index', 'users-roles.role-manager.create', 'users-roles.role-manager.app.role', 'users-roles.role-manager.app.access', 'users-roles.role-manager.app.route', 'users-roles.role-manager.app.assign', 'users-roles.role-manager.app.app-access', 'users-roles.exportimport', 'users-roles.tab-setting.index', 'users-roles.tab-setting.create', 'users-roles.config')";

        _jquery.default.ajax({
          url: '/api/v1/allowedroutes/checkMenu',
          type: 'POST',
          data: {
            userId: this.get('authObject.data.user.id'),
            menuList: menuList
          }
        }).then(function (response) {
          _this.set('shownMenu', response.menus);

          console.log('========');

          if (_this.get('shownMenu')) {
            _this.get('defaultMenus').forEach(function (header) {
              if (header.defaultSubMenus) {
                header.defaultSubMenus.forEach(function (defaultSubMenus) {
                  if (defaultSubMenus.link && _this.get('shownMenu').indexOf(defaultSubMenus.link) > -1) {
                    header.children.pushObject(defaultSubMenus);
                  } else if (defaultSubMenus.type === 'role' && _this.get('shownMenu').indexOf('users-roles.role-manager.app.role') > -1) {
                    header.children.pushObject(defaultSubMenus);
                  } else if (defaultSubMenus.type === 'access' && _this.get('shownMenu').indexOf('users-roles.role-manager.app.access') > -1) {
                    header.children.pushObject(defaultSubMenus);
                  } else if (defaultSubMenus.type === 'route' && _this.get('shownMenu').indexOf('users-roles.role-manager.app.route') > -1) {
                    header.children.pushObject(defaultSubMenus);
                  } else if (defaultSubMenus.type === 'assign' && _this.get('shownMenu').indexOf('users-roles.role-manager.app.assign') > -1) {
                    header.children.pushObject(defaultSubMenus);
                  } else if (defaultSubMenus.type === 'app-access' && _this.get('shownMenu').indexOf('users-roles.role-manager.app.app-access') > -1) {
                    header.children.pushObject(defaultSubMenus);
                  }
                });
              }

              if (header.title === 'User Management' && (_this.get('shownMenu').indexOf('users-roles.users.index') > -1 || _this.get('shownMenu').indexOf('users-roles.users.create') > -1 || _this.get('shownMenu').indexOf('users-roles.groups') > -1)) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'Role Management' && (_this.get('shownMenu').indexOf('users-roles.role-manager.app.role') > -1 || _this.get('shownMenu').indexOf('users-roles.role-manager.app.route') > -1 || _this.get('shownMenu').indexOf('users-roles.role-manager.app.access') > -1 || _this.get('shownMenu').indexOf('users-roles.role-manager.app.assign') > -1 || _this.get('shownMenu').indexOf('users-roles.role-manager.app.app-access') > -1)) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'App Management' && (_this.get('shownMenu').indexOf('users-roles.role-manager.index') > -1 || _this.get('shownMenu').indexOf('users-roles.role-manager.create') > -1)) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'Export / Import' && _this.get('shownMenu').indexOf('users-roles.exportimport') > -1) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'Config' && _this.get('shownMenu').indexOf('users-roles.config') > -1) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'Tab Setting' && (_this.get('shownMenu').indexOf('users-roles.tab-setting.index') > -1 || _this.get('shownMenu').indexOf('users-roles.tab-setting.create') > -1)) {
                _this.controllerFor('application').get('menus').pushObject(header);
              }

              if (header.title === 'Theme Configuration') {
                _this.controllerFor('application').get('menus').pushObject(header);
              }
            });
          }
        });
      }
    },
    actions: {}
  });

  _exports.default = _default;
});