define("client/pods/dashboard-builder/dabreport/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dCdgHE4x",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-top border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"mt-2 px-3\"],[8],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder\"]],{\"statements\":[[0,\"Dashboard ( \"],[1,[22,\"dashboardtitle\"],false],[0,\" )\"]],\"parameters\":[]},null],[0,\" - Dashboard Report\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"style\",\"float: right;\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"dashboard-builder.dabreport.new\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-success my-2\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Create New Report\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 px-0 mt-3\"],[8],[0,\"\\n\\t\"],[1,[28,\"component\",[\"dashboard-builder/dabreport-paging\"],[[\"totalPage\",\"listPage\",\"postLimit\",\"firstList\",\"modelConArr\",\"modelCon\",\"modelCount\",\"totalModel\",\"resourceAccessRestricted\",\"isLoadingResourceAccess\",\"nextList\",\"prevList\",\"deleteReport\"],[[24,[\"totalPage\"]],[24,[\"listPage\"]],[24,[\"postLimit\"]],[24,[\"firstList\"]],[24,[\"modelConArr\"]],[24,[\"modelCon\"]],[24,[\"modelCount\"]],[24,[\"totalModel\"]],[24,[\"resourceAccessRestricted\"]],[24,[\"isLoadingResourceAccess\"]],[28,\"route-action\",[\"nextList\"],null],[28,\"route-action\",[\"prevList\"],null],[28,\"route-action\",[\"deleteReport\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/dashboard-builder/dabreport/index/template.hbs"
    }
  });

  _exports.default = _default;
});