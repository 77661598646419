define("client/models/formprocess", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    initialization: (0, _attr.default)('string'),
    available: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    process: (0, _relationships.belongsTo)('process', {
      async: true,
      dirties: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    accessLimit: (0, _relationships.hasMany)('formaccesslimit', {
      async: true
    }),
    fieldTaskConfigs: (0, _relationships.hasMany)('fieldtaskconfig', {
      async: true
    })
  });

  _exports.default = _default;
});