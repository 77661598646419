define("client/pods/components/w3o-users-roles/user-profile-photo/component", ["exports", "axios", "jquery"], function (_exports, _axios, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authObject: Ember.inject.service('session'),
    init: function init() {
      this._super();

      if (this.get('profilePicture')) {
        this.set('filePath', this.get('profilePicture'));
      }

      this.set('isImageBroken', false);
      this.set('imgWidth', Ember.String.htmlSafe(this.get('imgWidth')));
      this.set('imgHeight', Ember.String.htmlSafe(this.get('imgHeight')));

      if (Ember.isEmpty(this.get('viewUserProfile'))) {
        this.set('displayInline', Ember.String.htmlSafe("display: inline;"));
        this.set('loadingStyle', Ember.String.htmlSafe("text-align: center; margin-top: 50%; margin-bottom: -50%"));
      } else {
        this.set('displayInline', Ember.String.htmlSafe(""));
        this.set('loadingStyle', Ember.String.htmlSafe("text-align: center; "));
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      (0, _jquery.default)('#photo-' + this.get('elementId')).on('error', function (ev) {
        if (self.get('profilePicture') && self.get('elementId')) {
          self.set('isLoading', true);
          var url = self.store.adapterFor('application').get('namespace') + "/formuploads/downloadFormAttachment?fd=" + encodeURIComponent(self.get('profilePicture'));
          (0, _axios.default)({
            method: 'POST',
            url: url,
            responseType: 'arraybuffer'
          }).catch(function (err) {
            console.log('error');
            console.log(err);
            self.set('isLoading', false);
            self.set('filePath', '/images/blank-profile.png');
            self.set('isImageBroken', true);
          }).then(function (e) {
            if (e.status === 200) {
              var blob = new Blob([e.data], {
                type: self.get('fileType')
              });
              var reader = new window.FileReader();
              reader.readAsDataURL(blob);

              reader.onloadend = function () {
                self.set('isLoading', false);
                self.set('filePath', reader.result);
              };
            } else {
              console.log(e);
              self.set('isLoading', false);
              self.set('filePath', '/images/blank-profile.png');
              self.set('isImageBroken', true);
            }
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)('#photo-' + this.get('elementId')).off();
    }
  });

  _exports.default = _default;
});