define("client/pods/components/w3o-scheduler/scheduler-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    setActiveTab: function setActiveTab(elementId) {
      var everySecond = document.getElementById(elementId);
      everySecond.classList.add("active");
      everySecond.firstElementChild.classList.add("active");
    },
    didInsertElement: function didInsertElement() {
      var DoMType = this.DoMType,
          DoWType = this.DoWType,
          hourType = this.hourType,
          minuteType = this.minuteType,
          monthType = this.monthType,
          secondType = this.secondType;
      this.setActiveTab(DoMType);
      this.setActiveTab(DoWType);
      this.setActiveTab(hourType);
      this.setActiveTab(minuteType);
      this.setActiveTab(monthType);
      this.setActiveTab(secondType);
    },
    actions: {
      addParam: function addParam() {
        this.addParam();
      },
      removeParam: function removeParam(param) {
        this.removeParam(param);
      },
      setBehavior: function setBehavior(param) {
        this.setBehavior(param);
      },
      setEnd: function setEnd(param) {
        this.setEnd(param);
      },
      setDoMType: function setDoMType(param) {
        this.setDoMType(param);
      },
      setDoWType: function setDoWType(param) {
        this.setDoWType(param);
      },
      setHourType: function setHourType(param) {
        this.setHourType(param);
      },
      setMinuteType: function setMinuteType(param) {
        this.setMinuteType(param);
      },
      setMonthType: function setMonthType(param) {
        this.setMonthType(param);
      },
      setSecondType: function setSecondType(param) {
        this.setSecondType(param);
      },
      setStart: function setStart(param) {
        this.setStart(param);
      },
      toggleDoM: function toggleDoM(param) {
        this.toggleDoM(param);
      },
      toggleDoW: function toggleDoW(param) {
        this.toggleDoW(param);
      },
      toggleHour: function toggleHour(param) {
        this.toggleHour(param);
      },
      toggleMinute: function toggleMinute(param) {
        this.toggleMinute(param);
      },
      toggleMonth: function toggleMonth(param) {
        this.toggleMonth(param);
      },
      toggleSecond: function toggleSecond(param) {
        this.toggleSecond(param);
      },
      updateDoM: function updateDoM(param) {
        this.updateDoM(param);
      },
      updateDoW: function updateDoW(param) {
        this.updateDoW(param);
      },
      updateHour: function updateHour(param) {
        this.updateHour(param);
      },
      updateMinute: function updateMinute(param) {
        this.updateMinute(param);
      },
      updateMonth: function updateMonth(param) {
        this.updateMonth(param);
      },
      updateSecond: function updateSecond(param) {
        this.updateSecond(param);
      }
    }
  });

  _exports.default = _default;
});