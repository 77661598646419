define("client/models/entryfilter", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    value: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    isHiddenValue: (0, _attr.default)('boolean'),
    conditionComparator: (0, _attr.default)('string'),
    fieldType: (0, _relationships.belongsTo)('field', {
      async: true
    }),
    hiddenType: (0, _relationships.belongsTo)('formpredefvalue', {
      async: true
    }),
    form: (0, _relationships.belongsTo)('form', {
      async: true
    }),
    entrygroup: (0, _relationships.belongsTo)('entrygroup', {
      async: true
    }),
    createdBy: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    updatedBy: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});