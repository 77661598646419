define("client/pods/components/dashboard-v2/chart-component/chart-table/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isShowingModal: false,
    drillable: false,
    hierarchy: 0,
    //selectedRow : {},
    //userinfo : {},
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'is_drilldown') === true) {
        Ember.set(this, 'drillable', true);
      }
    },
    openModal: function openModal(table_chart) {
      //table_chart.toggleProperty('isShowingModal');
      Ember.set(table_chart, 'isShowingModal', true);
    },
    closeModal: function closeModal(table_chart) {
      //table_chart.toggleProperty('isShowingModal');
      Ember.set(table_chart, 'isShowingModal', false);
    },
    drillDown: function drillDown(table_chart) {},
    drillUp: function drillUp(table_chart) {},
    detailReport: function detailReport(table_chart) {
      var searchColumn = table_chart.detailparam.primaryKey;
      var searchInput = table_chart.selectedRow[searchColumn];
      Ember.set(table_chart, 'invoiceParam.searchColumn', searchColumn);
      Ember.set(table_chart, 'invoiceParam.searchInput', searchInput);
      table_chart.getInvoice(table_chart);
      table_chart.openModal(table_chart);
      table_chart.openDetail();
    },
    cancel: function cancel(table_chart) {
      table_chart.closeModal(table_chart);
    },
    actions: {
      doSort: function doSort(param) {
        for (var key in this.ListHeader) {
          var value = this.ListHeader[key];

          if (value != param) {
            var tempId = '#sort-icon-' + value;

            if ((0, _jquery.default)(tempId)) {
              var tempList = (0, _jquery.default)(tempId).attr('class');

              if (tempList.includes('fa-sort-asc')) {
                (0, _jquery.default)(tempId).removeClass('fa-sort-asc');
                (0, _jquery.default)(tempId).addClass('fa-sort');
              }

              if (tempList.includes('fa-sort-desc')) {
                (0, _jquery.default)(tempId).removeClass('fa-sort-desc');
                (0, _jquery.default)(tempId).addClass('fa-sort');
              }
            }
          }
        }

        this.set('sortBy', param);
        var sortIconId = '#sort-icon-' + param;

        if ((0, _jquery.default)(sortIconId)) {
          var classList = (0, _jquery.default)(sortIconId).attr('class');

          if (classList.includes('fa-sort')) {
            this.set('sortOrder', 'ASC');
            (0, _jquery.default)(sortIconId).removeClass('fa-sort');
            (0, _jquery.default)(sortIconId).addClass('fa-sort-asc');
          }

          if (classList.includes('fa-sort-asc')) {
            this.set('sortOrder', 'DESC');
            (0, _jquery.default)(sortIconId).removeClass('fa-sort-asc');
            (0, _jquery.default)(sortIconId).addClass('fa-sort-desc');
          }

          if (classList.includes('fa-sort-desc')) {
            this.set('sortOrder', 'ASC');
            (0, _jquery.default)(sortIconId).removeClass('fa-sort-desc');
            (0, _jquery.default)(sortIconId).addClass('fa-sort-asc');
          }
        }

        this.doSort(this);
      },
      doSearch: function doSearch(param, param2) {
        this.doSearch(param, param2);
      },
      changeColumn: function changeColumn(param) {
        this.changeColumn(param);
      },
      changeLimit: function changeLimit(param) {
        this.changeLimit(param, this);
      },
      prevList: function prevList(table_chart) {
        this.prevList(table_chart);
      },
      nextList: function nextList(table_chart) {
        this.nextList(table_chart);
      },
      selectRow: function selectRow(selectedRow) {
        //alert('yes?' + invoice_key);
        this.selectedRow = selectedRow; //console.log(selectedRow);

        this.toggleProperty('isShowingModal');
      }
    }
  });

  _exports.default = _default;
});