define("client/helpers/chart2-col-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function chart2ColLength(params
  /*, hash*/
  ) {
    var colClass = "col-md";

    if (params[0]) {
      colClass += "-".concat(params[0]);
    }

    return new Ember.String.htmlSafe(colClass);
  });

  _exports.default = _default;
});