define("client/pods/components/dashboard-v2/data-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      /*
      * Must Decleare these when intialize this component
          this.set("dataColumns", [
              {
                  label: "Datasource Name",
                  columnName: "name"
              },
              {
                  label: "Connect As",
                  columnName: "connect_as"
              }
          ]);
           this.set("dataActions", [
              {
                  label: "Edit",
                  actionName: "editDatasource"
              },
              {
                  label: "Remove",
                  actionName: "removeDatasource"
              },
              {
                  label: "Hierary",
                  actionName: "goToHierarchy"
              }
          ]);
           this.set("dataFilters", [
              {
                  label: "Datasource",
                  columnName: "name"
              },
              {
                  label: "Connect As",
                  columnName: "connect_as"
              }
          ]);
      */


      if (this.get("dataFilters")) {
        this.set("activeFilterColumn", this.get("dataFilters")[0].columnName);
        this.set("activeFilterLabel", this.get("dataFilters")[0].label);
        this.set("filterPlaceholder", "Filter ".concat(this.get("dataFilters")[0].label));
      }
    },
    actions: {
      changeColumn: function changeColumn(param) {
        this.set("filterPlaceholder", "Filter ".concat(param.label));
        this.set("activeFilterColumn", param.columnName);
        this.set("activeFilterLabel", param.label);
        this.changeFilterColumn(param);
      },
      changeLimit: function changeLimit(limit) {
        this.set('dataLimit', limit);
        this.changeLimit();
      },
      clearFilterValue: function clearFilterValue() {
        this.set("dataFilterValue", null);
        this.set("activeFilterColumn", this.get("dataFilters")[0].columnName);
        this.set("activeFilterLabel", this.get("dataFilters")[0].label);
        this.changeFilterColumn(this.get("dataFilters")[0]);
        this.filterRecords();
      },
      doDataAction: function doDataAction(dataAction, record) {
        this.dataActionGateway({
          dataAction: dataAction,
          record: record
        });
      },
      filterRecords: function filterRecords() {
        this.filterRecords();
      },
      sortRecords: function sortRecords(param) {
        this.sortRecords(param);
      },
      prevPage: function prevPage() {
        this.prevPage();
      },
      nextPage: function nextPage() {
        this.nextPage();
      }
    }
  });

  _exports.default = _default;
});