define("client/pods/components/dashboard/dab-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['chart-inline'],
    updatedAt: '',
    loadingData: false,
    selectQuery: null,
    selectContents: null,
    paramcollectionQuery: null,
    paramcollectionContents: null,
    selectedParamcollection: null,
    paramContents: null,
    paramLoading: false,
    showButton: false,
    organizationname: 'All Organizations',
    companyname: 'All Companies',
    init: function init() {
      this._super();

      var self = this;
      this.set('chartSize', {
        height: 300,
        width: 500
      });
      this.set('legend', {
        position: 'right'
      });

      if (!Ember.isEmpty(self.get('chart').get('organizationnum.id'))) {
        self.set('organizationname', self.get('chart').get('organizationnum.organizationname'));
      }

      if (!Ember.isEmpty(self.get('chart').get('companynum.id'))) {
        self.set('companyname', self.get('chart').get('companynum.companyname'));
      }

      self.set('title', self.get('chart').get('title'));
      self.set('chartData', self.get('chart').get('chartData'));
      self.set('format', self.get('chart').get('format'));
      self.set('tooltip', self.get('chart').get('tooltip'));
    },
    didInsertElement: function didInsertElement() {
      this.set('loadingData', true);
      this.executeDabChart(this);
    },
    actions: {
      toggleButton: function toggleButton() {
        this.set('showButton', !this.get('showButton'));
      },
      updateChart: function updateChart(id) {
        this.dabChartUpdateChart(this, id);
      },
      setParamcollection: function setParamcollection(paramcollection) {
        if (paramcollection) {
          this.set('paramLoading', true);
          this.dabChartSetParamCollection(this, paramcollection);
        } else {
          this.set('selectedParamcollection', null);
        }
      },
      saveParam: function saveParam() {
        this.dabChartSaveParam(this);
      }
    }
  });

  _exports.default = _default;
});