define("client/pods/components/table-builder/attribute-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.set('createShape', false);
    },
    checkColumnRemark: function checkColumnRemark(column, index) {
      if (column.get("original_max_length") || column.get("original_column_name")) {
        column.set("remark", "Edited");
        $("#attr-row-".concat(index)).css('background-color', 'beige');
      } else {
        column.set("remark", null);
        $("#attr-row-".concat(index)).css('background-color', '');
      }
    },
    actions: {
      addNewColumn: function addNewColumn() {
        this.addNewColumn();
      },
      compareValue: function compareValue(column, index) {
        var originalColumns = this.get('originalColumns');

        if (originalColumns.objectAt(index).get('column_name') !== column.get('column_name')) {
          column.set('original_column_name', originalColumns.objectAt(index).get('column_name'));
        } else {
          column.set('original_column_name', null);
        }

        this.checkColumnRemark(column, index);
      },
      deleteNewColumn: function deleteNewColumn(index) {
        this.deleteNewColumn(index);
      },
      deleteColumn: function deleteColumn(column, index) {
        this.deleteColumn(column, index);
      },
      maxLengthChanged: function maxLengthChanged(column, index) {
        var originalColumns = this.get('originalColumns');

        if (originalColumns.objectAt(index).get('max_length') !== column.get('max_length')) {
          column.set('original_max_length', originalColumns.objectAt(index).get('max_length'));
        } else {
          column.set('original_max_length', null);
        }

        this.checkColumnRemark(column, index);
      },
      toggleShapeColumn: function toggleShapeColumn() {
        this.set('createShape', !this.get('createShape'));
        this.toggleShapeColumn();
      },
      validateColumName: function validateColumName(columnType, index, column) {
        var regex = new RegExp("[^A-Za-z_\d]");

        if (regex.test(this.get(columnType)[index].get('column_name'))) {
          this.get(columnType)[index].set('column_name', this.get(columnType)[index].get('column_name').replace(/[^A-Za-z_]/g, ''));
        }

        var originalColumns = this.get('originalColumns');

        if (!originalColumns) {
          return;
        }

        if (originalColumns.objectAt(index).get('column_name') !== column.get('column_name')) {
          column.set('original_column_name', originalColumns.objectAt(index).get('column_name'));
        } else {
          column.set('original_column_name', null);
        }

        this.checkColumnRemark(column, index);
      }
    }
  });

  _exports.default = _default;
});