define("client/pods/components/menu-builder/bootstrap-table-action-control/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    	This Component Used by components/menu-builder/menu-paging
    */
    tagName: 'tr',
    showPrompt: false,
    init: function init() {
      this._super();

      if (this.get('environment') === 'parent') {
        this.set('parent', true);
      } else if (this.get('environment') === 'child') {
        this.set('child', true);
      } else if (this.get('environment') === 'subchild') {
        this.set('subchild', true);
      }
    },
    actions: {
      toggleDelete: function toggleDelete() {
        if (this.get('showPrompt')) {
          this.set('showPrompt', false);
        } else {
          this.set('showPrompt', true);
        }
      },
      doDelete: function doDelete(id, environment) {
        this.deleteFunction(id, environment);
      }
    }
  });

  _exports.default = _default;
});