define("client/models/formpredefvaluelocalization", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    phrase: (0, _attr.default)('string'),
    lang: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    formpredefvalue: (0, _relationships.belongsTo)('formpredefvalue', {
      async: false
    })
  });

  _exports.default = _default;
});