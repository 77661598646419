define("client/pods/report-generator/index/route", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _applicationRouteMixin, _authenticatedRouteMixin, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkUserRole.default, {
    authObject: Ember.inject.service('session'),
    //initialize session
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    model: function model(params) {
      return this.modelFor('report-generator'); //get model from report-generator/route.js
    },
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set('selectedParamcollection', null);
      controller.set('paramContents', Ember.A());
      controller.set('availableFieldContents', Ember.A());
      controller.set('selection', Ember.Object.create({
        category: '',
        tablename: ''
      })); // Resource Access

      controller.set('companyList', Ember.A());
      controller.set('organizationList', Ember.A());
      var currentCompany = null;
      var currentOrganization = null;
      self.get('formContainer.resourceValidationFinished').promise.then(function () {
        if (self.get('formContainer.resourceAccess') === '/') {
          self.store.findAll('organization').then(function (organizations) {
            controller.set('organizationList', organizations);
          });
        } else if (self.get('formContainer.resourceAccess') === '/org') {
          currentOrganization = self.get('optmmSetting.companynum.organizationnum');
          self.store.query('company', {
            where: {
              organizationnum: self.get('optmmSetting.companynum.organizationnum.id')
            }
          }).then(function (companies) {
            controller.set('companyList', companies);
          });
        } else {
          currentCompany = self.get('optmmSetting.companynum');
          currentOrganization = self.get('optmmSetting.companynum.organizationnum');
        }
      });
      var newReport = self.store.createRecord('genreport', {
        reportname: "",
        reportheader: "",
        reportfooter: "",
        reportsummary: "",
        reporttype: "",
        application: "",
        reportfolder: "",
        importedby: "",
        qbrcreatedby: controller.get('user.firstName'),
        whatsql: "",
        share: false,
        filetype: "EXCEL",
        limit: "NA",
        tablecount: 0,
        isdelete: false,
        companynum: currentCompany,
        organizationnum: currentOrganization,
        createdBy: controller.get('user')
      });
      controller.set('currentReport', newReport);
      controller.set('selectedTableQuery', self.store.query('genreportconfigtable', {
        where: {
          id: -1
        }
      }));
      controller.get('selectedTableQuery').then(function (selectedTableContents) {
        controller.set('selectedTableContents', selectedTableContents.toArray());
      });
      controller.set('selectedFieldQuery', self.store.query('genreportconfigfield', {
        where: {
          id: -1
        }
      }));
      controller.get('selectedFieldQuery').then(function (selectedFieldContents) {
        controller.set('selectedFieldContents', selectedFieldContents.toArray());
      });
      controller.set('selectQuery', self.store.query('genreportconfigselect', {
        where: {
          id: -1
        }
      }));
      controller.get('selectQuery').then(function (selectContents) {
        controller.set('selectContents', selectContents.toArray());
      });
      controller.set('joinQuery', self.store.query('genreportconfigjoin', {
        where: {
          id: -1
        }
      }));
      controller.get('joinQuery').then(function (joinContents) {
        controller.set('joinContents', joinContents.toArray());
      });
      controller.set('summarizeQuery', self.store.query('genreportconfigsummarize', {
        where: {
          id: -1
        }
      }));
      controller.get('summarizeQuery').then(function (summarizeContents) {
        controller.set('summarizeContents', summarizeContents.toArray());
      });
      controller.set('groupbyQuery', self.store.query('genreportconfiggroupby', {
        where: {
          id: -1
        }
      }));
      controller.get('groupbyQuery').then(function (groupbyContents) {
        controller.set('groupbyContents', groupbyContents.toArray());
      });
      controller.set('groupingQuery', self.store.query('genreportconfiggrouping', {
        where: {
          id: -1
        }
      }));
      controller.get('groupingQuery').then(function (groupingContents) {
        controller.set('groupingContents', groupingContents.toArray());
      });
      controller.set('filterQuery', self.store.query('genreportconfigfilter', {
        where: {
          id: -1
        }
      }));
      controller.get('filterQuery').then(function (filterContents) {
        controller.set('filterContents', filterContents.toArray());
      });
      controller.set('sortQuery', self.store.query('genreportconfigsort', {
        where: {
          id: -1
        }
      }));
      controller.get('sortQuery').then(function (sortContents) {
        controller.set('sortContents', sortContents.toArray());
      });
      controller.set('expressionQuery', self.store.query('genreportconfigexpression', {
        where: {
          id: -1
        }
      }));
      controller.get('expressionQuery').then(function (expressionContents) {
        controller.set('expressionContents', expressionContents.toArray());
      });
      controller.set('availableFieldCollection', Ember.A());
      controller.set('selectedEntityCollection', Ember.A());
      controller.set('selectedFieldCollection', Ember.A());
      controller.set('selectedFieldContents', Ember.A());
      controller.set('selectedTableCollection', Ember.A());
      var methodSelects = [{
        value: "NA",
        text: "None"
      }, {
        value: "COUNT",
        text: "Count"
      }, {
        value: "SUM",
        text: "Sum"
      }, {
        value: "EXTRACTDATE",
        text: "Extract Date"
      }, {
        value: "MIN",
        text: "Min"
      }];
      var conditionSelects = [{
        value: "EQUAL",
        text: "Equal"
      }, {
        value: "EQUALIGNORECASE",
        text: "Equal Ignore Case"
      }, {
        value: "GREATEROREQUAL",
        text: "Greater or Equal"
      }, {
        value: "GREATERTHAN",
        text: "Greater Than"
      }, {
        value: "LESSOREQUAL",
        text: "Less or Equal"
      }, {
        value: "LESSTHAN",
        text: "Less Than"
      }, {
        value: "LIKE",
        text: "Like"
      }, {
        value: "LIKEIGNORECASE",
        text: "Like Ignore Case"
      }, {
        value: "NOTEQUAL",
        text: "Not Equal"
      }, {
        value: "NOTLIKE",
        text: "Not Like"
      }, {
        value: "NOTLIKEIGNORECASE",
        text: "Not Like Ignore Case"
      }, {
        value: "STARTSWITH",
        text: "Starts With"
      }, {
        value: "ENDSWITH",
        text: "Ends With"
      }, {
        value: "ISNULL",
        text: "Is Null"
      }, {
        value: "ISNOTNULL",
        text: "Is not Null"
      }, {
        value: "ISTRUE",
        text: "Is True"
      }, {
        value: "ISFALSE",
        text: "Is False"
      }];
      var joinTypes = [{
        value: "LEFT_OUTER_JOIN",
        text: "Left Outer Join"
      }, {
        value: "RIGHT_OUTER_JOIN",
        text: "Right Outer Join"
      }, {
        value: "CROSS_JOIN",
        text: "Cross Join"
      }, {
        value: "CROSS_APPLY",
        text: "Cross Apply"
      }, {
        value: "FULL_OUTER_JOIN",
        text: "Full Outer Join"
      }, {
        value: "JOIN",
        text: "Join"
      }, {
        value: "NATURAL_JOIN",
        text: "Natural Join"
      }, {
        value: "NATURAL_LEFT_OUTER_JOIN",
        text: "Natural Left Outer Join"
      }, {
        value: "NATURAL_RIGHT_OUTER_JOIN",
        text: "Natural Right Outer Join"
      }, {
        value: "OUTER_APPLY",
        text: "Outer Apply"
      }];
      var groupbyFunctionSelects = [{
        value: "NA",
        text: "N/A"
      }, {
        value: "EXTRACTDATE",
        text: "Extract Date"
      }];
      controller.set('methodSelects', methodSelects);
      controller.set('conditionSelects', conditionSelects);
      controller.set('joinTypes', joinTypes);
      controller.set('groupbyFunctionSelects', groupbyFunctionSelects);
    },
    actions: {
      actionAfterSave: function actionAfterSave(param) {
        var AdHocReportsChildren = this.controllerFor('application').get('menus').find(function (menu) {
          return menu.title == 'Ad Hoc Reports';
        })['children'];
        AdHocReportsChildren.pushObject({
          'title': param.reportname,
          'link': 'report-generator.report',
          'param': param.id
        });
        this.transitionTo('report-generator.report', param.id);
      }
    }
  });

  _exports.default = _default;
});