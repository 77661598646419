define("client/pods/components/w3office-forms/component-label/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['mb-0'],
    classNameBindings: ['customClass', 'isSkeleton:skeleton'],
    attributeBindings: ['id'],
    init: function init() {
      this._super.apply(this, arguments);

      var labelId;

      if (this.get("field.id")) {
        labelId = "label-".concat(this.get('field.id'));
      } else {
        labelId = "label-".concat(this.get('field.columnName'));
      }

      this.set("labelId", labelId);
      this.set('id', labelId);
    },
    didRender: function didRender() {
      if (this.get('currentTheme')) {
        (0, _jquery.default)("#".concat(this.get('labelId'))).css({
          'color': this.get('currentTheme').labelFontColor,
          'font-size': this.get('currentTheme').labelFontSize
        });
      }
    },
    customClass: Ember.computed('customClassName', function () {
      // if has skeleton, do not display the customClassName
      if (this.get('isSkeleton')) {
        return null;
      } else {
        return this.get('customClassName');
      }
    })
  });

  _exports.default = _default;
});