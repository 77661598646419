define("client/pods/components/w3office-forms/advanced-components/form-upload/component-field/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      if (this.get('currentTheme')) {
        (0, _jquery.default)("#formupload-".concat(this.get('field').id)).css({
          "color": this.get('currentTheme').valueFontColor,
          "font-size": this.get('currentTheme').valueFontSize
        });
      }
    }
  });

  _exports.default = _default;
});