define("client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/value-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    valueContents: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;

      if (!Ember.isEmpty(this.get('select.id'))) {
        this.store.query('genreportparamconfiguservalue', {
          genreportnum: this.get('currentReport.id'),
          genreportconfigselectnum: this.get('select.id'),
          isdelete: false,
          sort: 'id ASC'
        }).then(function (results) {
          self.set('valueContents', results.toArray());
        });
      } else {
        self.set('valueContents', Ember.A());
      }
    }
  });

  _exports.default = _default;
});