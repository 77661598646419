define("client/pods/components/dashboard-builder/dab-user-param-collection/dab-user-param/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addParam: function addParam(selectedParamcollection) {
        this.addParam(selectedParamcollection);
      },
      deleteParam: function deleteParam(param) {
        this.deleteParam(param);
      }
    }
  });

  _exports.default = _default;
});