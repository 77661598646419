define("client/pods/scheduler/index/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    authObject: Ember.inject.service('session'),
    actionList: '',
    beforeModel: function beforeModel() {
      var Variable = Ember.Object.extend({
        action: null,
        varName: null,
        status: false
      });
      var actions = [Variable.create({
        action: 'Create Schedule',
        varName: 'canCreateSchedule',
        status: false
      }), Variable.create({
        action: 'Edit Schedule',
        varName: 'canEditSchedule',
        status: false
      }), Variable.create({
        action: 'Delete Schedule',
        varName: 'canDeleteSchedule',
        status: false
      })];
      this.set('actionList', actions);

      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller) {
      // Created by : Michael
      // Created date : 12 April 2016
      // Current Programmer : Paska
      // Edit date : 1 November 2016
      // Note : Refactor to Ember 2.8
      controller.set('hasModel', false);
      controller.set('loading', true);
      controller.set('listPage', 1);
      controller.set('totalPage', 1);
      controller.set('limit', 10);
      controller.set('canCreateSchedule', false);
      controller.set('canEditSchedule', false);
      controller.set('canDeleteSchedule', false); // advance filter

      controller.set('filterList', Ember.A());
      controller.set('entryTypeContainer', Ember.A());
      controller.set('canAdd', true); // for add filter button

      controller.set('canClick', true); // prevent double clicking
      // set advance filter list

      var fieldLabel = ['Name', 'API Base Url', 'API Path'];
      var fieldColumn = ['name', 'apiBaseUrl', 'apiPath'];

      for (var i = 0; i < fieldLabel.length; i++) {
        controller.get('entryTypeContainer').addObject({
          label: fieldLabel[i],
          column: fieldColumn[i]
        });
      }

      this.getSchedules();
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateSchedule') {
          controller.set('canCreateSchedule', action.get('status'));
        }

        if (action.get('varName') === 'canEditSchedule') {
          controller.set('canEditSchedule', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteSchedule') {
          controller.set('canDeleteSchedule', action.get('status'));
        }
      });
    },
    getSchedules: function getSchedules() {
      // Created by : Michael
      // Created date : 12 April 2016
      // Current Programmer : Paska
      // Edit date : 1 November 2016
      // Note : Refactor to Ember 2.8
      var controller = this.get('controller');
      var advanceFilter = {};
      var filterList = controller.get('filterList');

      if (filterList.length) {
        filterList.forEach(function (filter, index) {
          advanceFilter[index] = {};
          advanceFilter[index].column = filterList[index].type.column;
          advanceFilter[index].value = filterList[index].value;
        });
      }

      console.log('==advanceFilter==');
      console.log(advanceFilter);

      _jquery.default.ajax({
        type: "POST",
        data: {
          page: controller.get('listPage'),
          limit: controller.get('limit'),
          advanceFilter: advanceFilter
        },
        url: this.store.adapterFor('application').get('namespace') + '/crontasks/getSchedules',
        success: function success(response) {
          controller.set('model', response.schedule);
          controller.set('totalPage', response.totalPage);
          controller.set('loading', false);

          if (response.exceedTotal) {
            controller.set('listPage', 1);
          }

          if (response.schedule.length) {
            controller.set('hasModel', true);
          } else {
            controller.set('hasModel', false);
            controller.set('loading', false);
          }
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },
    observeVariable: Ember.observer('setActions.[]', function () {
      var controller = this.get('controller');

      if (controller) {
        this.get('setActions').forEach(function (action) {
          if (action.get('varName') === 'canCreateSchedule') {
            controller.set('canCreateSchedule', action.get('status'));
          }

          if (action.get('varName') === 'canEditSchedule') {
            controller.set('canEditSchedule', action.get('status'));
          }

          if (action.get('varName') === 'canDeleteSchedule') {
            controller.set('canDeleteSchedule', action.get('status'));
          }
        });
      }
    }),
    actions: {
      changeLimit: function changeLimit() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.getSchedules();
      },
      prevList: function prevList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') - 1);
        this.getSchedules();
      },
      nextList: function nextList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') + 1);
        this.getSchedules();
      },
      gotoEdit: function gotoEdit(param) {
        // Created by : Michael
        // Created date : 13 April 2016
        // Current Programmer : Michael
        // Edit date : 13 April 2016
        // Note : 
        this.transitionTo('scheduler.edit', param);
      },
      deleteSchedule: function deleteSchedule(scheduleId, apiBaseUrl, apiPath) {
        // Created by : Michael
        // Created date : 13 April 2016
        // Current Programmer : Paska
        // Edit date : 1 November 2016
        // Note : Refactor to Ember 2.8
        var self = this;
        var controller = this.get('controller');
        var url = self.store.adapterFor('application').get('namespace') + '/crontasks/deleteCron';
        controller.set('loading', true);

        _jquery.default.ajax({
          type: "POST",
          url: url,
          data: {
            jobId: scheduleId,
            apiBaseUrl: apiBaseUrl,
            apiPath: apiPath
          },
          success: function success(res) {
            var deleted = [];
            self.store.findRecord('crontask', scheduleId).then(function (crontask) {
              crontask.deleteRecord();
              self.store.query('crontask', {
                parent: scheduleId
              }).then(function (schedules) {
                schedules.forEach(function (schedule) {
                  deleted.push(schedule.destroyRecord());
                });
                Ember.RSVP.Promise.all(deleted).then(function () {
                  deleted = [];
                  self.store.query('apiparam', {
                    crontask: scheduleId
                  }).then(function (params) {
                    params.forEach(function (schedule) {
                      deleted.push(schedule.destroyRecord());
                    });
                    Ember.RSVP.Promise.all(deleted).then(function () {
                      crontask.save().then(function () {
                        self.getSchedules();
                      });
                    });
                  });
                });
              });
            });
          },
          error: function error(xhr, status, _error2) {
            console.log('Error ' + _error2);
          }
        });
      },
      advanceFilters: function advanceFilters(filterList) {
        console.log('advanceFilters');
        var controller = this.get('controller');
        controller.set('chipContainer', Ember.A());
        controller.set('filterList', filterList);
        console.log('filterList');
        console.log(filterList);
        controller.set('listPage', 1);
        controller.set('totalPage', 1);
        controller.set('limit', 10);

        for (var i = 0; i < filterList.length; i++) {
          console.log('filter ' + filterList[i].type.label + ' : ' + filterList[i].value);
          controller.get('chipContainer').pushObject({
            label: filterList[i].type.label,
            column: filterList[i].type.column,
            value: filterList[i].value
          });
        }

        controller.set('loadingEntry', true);
        this.getSchedules();
      },
      showModal: function showModal() {
        console.log('showModal');
        var controller = this.get('controller');
        controller.set('canClick', true);

        if (controller.get('filterList').length === 0) {
          if (!Ember.isEmpty(this.controller.get('entryTypeContainer'))) {
            controller.get('filterList').addObject({
              type: {
                label: controller.get('entryTypeContainer')[0].label,
                column: controller.get('entryTypeContainer')[0].column
              },
              value: ''
            });
          }
        }

        (0, _jquery.default)('#advanceFilter').modal({
          backdrop: 'static',
          keyboard: false
        });
      }
    }
  });

  _exports.default = _default;
});