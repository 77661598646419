define("client/helpers/remove-non-alphanumeric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeNonAlphaNumericChar = removeNonAlphaNumericChar;
  _exports.default = void 0;

  function removeNonAlphaNumericChar(param) {
    if (param[0]) {
      var convertedString = param[0];
      var removedCharacters = [" ", ".", ":", "!", "?", "&", "(", ")"];
      removedCharacters.forEach(function (removedChar) {
        while (convertedString.includes(removedChar)) {
          convertedString = convertedString.replace(removedChar, "-");
        }
      });
      return convertedString;
    } else {
      return param[0];
    }
  }

  var _default = Ember.Helper.helper(removeNonAlphaNumericChar);

  _exports.default = _default;
});