define("client/pods/components/w3office-forms/standard-components/input-text/component-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PvujUuHA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"isSkeleton\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"input\",null,[[\"type\",\"id\",\"class\",\"focus-out\",\"value\",\"placeholder\",\"disabled\"],[[24,[\"type\"]],[28,\"concat\",[\"input-\",[24,[\"field\",\"id\"]]],null],\"simpleInputText form-control form-control-sm fieldContent\",[28,\"action\",[[23,0,[]],\"mutateEntry\"],null],[24,[\"containerValue\"]],[24,[\"field\",\"placeholder\"]],[24,[\"field\",\"isDisabled\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"showRetype\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"id\",\"class\",\"focus-out\",\"value\",\"placeholder\",\"disabled\"],[[24,[\"type\"]],[28,\"concat\",[\"retype-input-\",[24,[\"field\",\"id\"]]],null],\"simpleInputText form-control form-control-sm fieldContent\",[28,\"action\",[[23,0,[]],\"mutateEntry\"],null],[24,[\"field\",\"validationValue\"]],[28,\"concat\",[\"Retype \",[24,[\"field\",\"placeholder\"]]],null],[24,[\"field\",\"isDisabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/standard-components/input-text/component-field/template.hbs"
    }
  });

  _exports.default = _default;
});