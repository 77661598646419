define("client/pods/components/general-component/top-menubar/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      console.log("didReceiveAttrs() - topbar-nav");
      console.log(this.get("menus"));
      this.setProperties(this.get("currentTheme"));

      if (this.get("menus")) {
        setTimeout(function () {
          _this.initializeDropdownMenu();
        }, 700);
      }
    },
    didRender: function didRender() {// ------------------------------------------------------- //
      // Multi Level dropdowns | Source: https://bootstrapious.com/p/bootstrap-multilevel-dropdown
      // ------------------------------------------------------ //
      // alert("didRender - top-menubar");
      // console.log($("ul.dropdown-menu [data-toggle='dropdown']"));
      // this.initializeDropdownMenu();
      // if (this.get("appID")) {
      //     // add mt-md-5 to #core-top-navbar
      //     $("#core-top-navbar").addClass("mt-md-5");
      // }
      // else {
      //     // remove mt-md-5 from #core-top-navbar
      //     $("#core-top-navbar").removeClass("mt-md-5");
      // }
    },
    initializeDropdownMenu: function initializeDropdownMenu() {
      (0, _jquery.default)("ul.dropdown-menu [data-toggle='dropdown']").unbind();
      (0, _jquery.default)("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event) {
        event.preventDefault();
        event.stopPropagation();

        if (!(0, _jquery.default)(this).next().hasClass('show')) {
          (0, _jquery.default)(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }

        (0, _jquery.default)(this).siblings().toggleClass("show"); // console.log( $(this).parents('li.nav-item.dropdown.show') );
        // console.log( $(this).parents('li.nav-item.dropdown.show').children(".dropdown-menu").children(".dropdown-submenu").children(".show") );
        // $(this).parents('li.nav-item.dropdown.show').children(".dropdown-menu").children(".dropdown-submenu").children(".show").removeClass("show");

        (0, _jquery.default)(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
          (0, _jquery.default)('.dropdown-submenu .show').removeClass("show");
        });
      });
    }
  });

  _exports.default = _default;
});