define("client/pods/components/report-generator/content-tab/select-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Htv3A1I",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"placeholder\",\"class\",\"value\"],[\"text\",\"Enter Value\",\"form-control\",[24,[\"value\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/report-generator/content-tab/select-label/template.hbs"
    }
  });

  _exports.default = _default;
});