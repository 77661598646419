define("client/pods/components/dashboard-v2/chart-component/filter-component/dropdownlist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qDty7GUF",
    "block": "{\"symbols\":[\"xs\",\"content\"],\"statements\":[[4,\"x-select\",null,[[\"class\",\"value\",\"on-change\",\"disabled\"],[\"form-control form-control-sm\",[24,[\"filter\",\"inputvalue\"]],[28,\"action\",[[23,0,[]],\"mutateFilter\"],null],false]],{\"statements\":[[0,\"    \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/dashboard-v2/chart-component/filter-component/dropdownlist/template.hbs' @ L2:C7) \"],null]],null,{\"statements\":[[0,\"Nothing Selected\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"filter\",\"dropdownitem\"]]],null,{\"statements\":[[0,\"        \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/dashboard-v2/chart-component/filter-component/dropdownlist/template.hbs' @ L4:C11) \"],null]],[[\"value\"],[[23,2,[\"value\"]]]],{\"statements\":[[1,[23,2,[\"label\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dashboard-v2/chart-component/filter-component/dropdownlist/template.hbs"
    }
  });

  _exports.default = _default;
});