define("client/pods/components/w3office-forms/advanced-components/auto-fill/component-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NWrovV1r",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"isSkeleton\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"value\",\"placeholder\",\"disabled\",\"key-up\"],[[28,\"concat\",[\"autofill-\",[24,[\"field\",\"id\"]]],null],\"text\",\"simpleInputText form-control form-control-sm fieldContent col-md-12\",[24,[\"field\",\"containerLabel\"]],[24,[\"field\",\"placeholder\"]],[24,[\"field\",\"isDisabled\"]],[28,\"action\",[[23,0,[]],\"mutateEntryContainer\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/advanced-components/auto-fill/component-field/template.hbs"
    }
  });

  _exports.default = _default;
});