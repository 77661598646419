define("client/templates/components/ol-layer-tree-item-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QISpML57",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isRadio\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ol-radio\",null,[[\"id\",\"name\",\"value\",\"checked\"],[[24,[\"id2\"]],[24,[\"layer\",\"exclusive\"]],[24,[\"layer\",\"id\"]],[24,[\"isChecked\"]]]]],false],[7,\"label\",true],[11,\"for\",[29,[[22,\"id2\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"checked\"],[[24,[\"id2\"]],\"checkbox\",\"special_check_box\",[24,[\"isChecked\"]]]]],false],[7,\"label\",true],[11,\"for\",[29,[[22,\"id2\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/ol-layer-tree-item-check.hbs"
    }
  });

  _exports.default = _default;
});