define("client/pods/components/w3office-forms/advanced-components/color-picker/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    init: function init() {
      this._super();

      this.set('colorArray', Ember.A(['#FF0000', '#FFA500', '#FFFF00', '#008000', '#0000FF', '#4B0082', '#800080', '#C0C0C0', '#808080', '#000000']));
      this.set('selectedColor', 'indigo');

      if (this.get('entryContainer')) {
        this.set('containerValue', this.get('entryContainer'));
      } else {
        this.set('containerValue', {});
      }
    },
    actions: {
      setEntryContainer: function setEntryContainer(field, value) {
        this.setEntryContainer(field, value);
      }
    }
  });

  _exports.default = _default;
});