define("client/pods/components/w3o-scheduler/schedule-paging/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changeLimit: function changeLimit() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.changeLimit();
      },
      prevList: function prevList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.prevList();
      },
      nextList: function nextList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.nextList();
      },
      gotoEdit: function gotoEdit(id) {
        // Created by : Michael
        // Created date : 13 April 2016
        // Current Programmer : Michael
        // Edit date : 13 April 2016
        // Note : 
        this.gotoEdit(id);
      },
      openDeleteModal: function openDeleteModal(scheduleId, apiBaseUrl, apiPath) {
        // Created by : Michael
        // Created date : 13 April 2016
        // Current Programmer : Michael
        // Edit date : 13 April 2016
        // Note : 
        (0, _jquery.default)('#delete-schedule-modal').modal('toggle');
        this.set('scheduleId', scheduleId);
        this.set('apiBaseUrl', apiBaseUrl);
        this.set('apiPath', apiPath);
      },
      deleteSchedule: function deleteSchedule() {
        // Created by : Michael
        // Created date : 13 April 2016
        // Current Programmer : Michael
        // Edit date : 31 May 2016
        // Note : 
        this.deleteSchedule(this.get('scheduleId'), this.get('apiBaseUrl'), this.get('apiPath'));
      },
      advanceFilters: function advanceFilters(filterList) {
        this.advanceFilters(filterList);
      },
      showModal: function showModal() {
        this.showModal();
      }
    }
  });

  _exports.default = _default;
});