define("client/pods/report-generator/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MLU8sZ0G",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[\"report-generator/report-form\"],[[\"availableFieldCollection\",\"availableFieldContents\",\"companyList\",\"conditionSelects\",\"currentReport\",\"entityContents\",\"environment\",\"expressionContents\",\"filterContents\",\"groupbyContents\",\"groupbyFunctionSelects\",\"groupingContents\",\"joinContents\",\"joinTypes\",\"methodSelects\",\"organizationList\",\"resourceAccess\",\"selectContents\",\"selectedEntityCollection\",\"selectedFieldCollection\",\"selectedFieldContents\",\"selectedTableCollection\",\"selectedTableContents\",\"sortContents\",\"summarizeContents\",\"actionAfterSave\"],[[24,[\"availableFieldCollection\"]],[24,[\"availableFieldContents\"]],[24,[\"companyList\"]],[24,[\"conditionSelects\"]],[24,[\"currentReport\"]],[24,[\"entityContents\"]],\"create\",[24,[\"expressionContents\"]],[24,[\"filterContents\"]],[24,[\"groupbyContents\"]],[24,[\"groupbyFunctionSelects\"]],[24,[\"groupingContents\"]],[24,[\"joinContents\"]],[24,[\"joinTypes\"]],[24,[\"methodSelects\"]],[24,[\"organizationList\"]],[24,[\"resourceAccess\"]],[24,[\"selectContents\"]],[24,[\"selectedEntityCollection\"]],[24,[\"selectedFieldCollection\"]],[24,[\"selectedFieldContents\"]],[24,[\"selectedTableCollection\"]],[24,[\"selectedTableContents\"]],[24,[\"sortContents\"]],[24,[\"summarizeContents\"]],[28,\"route-action\",[\"actionAfterSave\"],null]]]],false],[0,\" \\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/report-generator/index/template.hbs"
    }
  });

  _exports.default = _default;
});