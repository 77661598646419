define("client/pods/table-builder/server-management/route", ["exports", "axios", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _axios, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    model: function model() {
      return this.store.query('tableserver', {
        where: {
          isdelete: false
        }
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('selectedServer', null);
      this.store.findRecord('user', this.get('authObject.data.user').id).then(function (user) {
        controller.set('currentUser', user);
      });
    },
    actions: {
      restartConfirmation: function restartConfirmation(selectedServer) {
        this.get('controller').set('selectedServer', selectedServer);
        (0, _jquery.default)("#restart-confirmation").modal("show");
      },
      restartServer: function restartServer(selectedServer) {
        (0, _axios.default)({
          method: 'POST',
          url: "".concat(selectedServer.domain).concat(selectedServer.namespace, "/Formtables/restartServer")
        });
        (0, _jquery.default)("#restart-confirmation").modal("hide");
      },
      deleteConfirmation: function deleteConfirmation(selectedServer) {
        this.get('controller').set('selectedServer', selectedServer);
        (0, _jquery.default)("#delete-confirmation").modal("show");
      },
      deleteServer: function deleteServer() {
        var controller = this.get('controller');
        var model = controller.get('model');
        controller.set('selectedServer.isdelete', true);
        model.save().then(function () {
          model.update();
        });
      },
      showServerDetail: function showServerDetail(selectedServer) {
        if (selectedServer) {
          this.get('controller').set('selectedServer', selectedServer);
        } else {
          this.get('controller').set('selectedServer', {
            serverName: null,
            domain: null,
            namespace: null
          });
        }

        (0, _jquery.default)("#server-detail").modal("show");
      },
      saveServer: function saveServer() {
        var controller = this.get('controller');
        var model = controller.get('model');
        var selectedServer = controller.get('selectedServer');

        if (!selectedServer.serverName || !selectedServer.domain || !selectedServer.namespace) {
          controller.set('triggered', 'error');
          controller.set('succeed', false);
          controller.set('message', "Please fill the fields above.");
          Ember.run.later(this, function () {
            controller.set('triggered', null);
            controller.set('message', null);
          }, 5000);
          return;
        } else {
          //Update server
          if (selectedServer.id) {
            selectedServer.set('updatedBy', controller.get('currentUser'));
            model.save();
            (0, _jquery.default)("#server-detail").modal('hide');
          } //Add new server
          else {
              selectedServer.createdBy = controller.get('currentUser');
              selectedServer.updatedBy = controller.get('currentUser');
              this.store.createRecord('tableserver', selectedServer).save().then(function () {
                model.update();
                (0, _jquery.default)("#server-detail").modal('hide');
              });
            }
        }
      }
    }
  });

  _exports.default = _default;
});