define("client/pods/components/w3office-forms/droppable-column/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['draggableDropzone'],
    classNameBindings: ['dragClass'],
    dragClass: 'deactivated',
    _droppableColId: Ember.computed('elementId', function () {
      return "droppableCol-".concat(this.get('elementId'));
    }),
    _droppableColMobileId: Ember.computed('elementId', function () {
      return "droppableColMobile-".concat(this.get('elementId'));
    }),
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      (0, _jquery.default)('#col-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('width', '15px');
      (0, _jquery.default)('#com-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('width', '100%'); //$('#col-'+this.get('currentField').get('fieldRow')+'-'+this.get('currentField').get('fieldColumn')).css('border-style', 'none');

      (0, _jquery.default)("#".concat(this.get("_droppableColId"))).css('border-style', 'none');
      this.set('dragClass', 'deactivated');
    },
    dragOver: function dragOver(event) {
      if (!this.get('isDropping')) {
        event.preventDefault();
        (0, _jquery.default)('#col-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('float', 'left');
        (0, _jquery.default)('#com-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('float', 'right');
        (0, _jquery.default)('#col-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('width', '10%');
        (0, _jquery.default)('#com-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('width', '90%'); //$('#col-'+this.get('currentField').get('fieldRow')+'-'+this.get('currentField').get('fieldColumn')).css('border-style', 'dotted');

        (0, _jquery.default)("#".concat(this.get("_droppableColId"))).css('border-style', 'dotted');
        (0, _jquery.default)("#".concat(this.get("_droppableColId"))).css('border-color', '#5e5e5c');
        this.set('dragClass', 'activated');
      }
    },
    drop: function drop(event) {
      if (!this.get('isDropping')) {
        this.putFieldOnColumn(this.get('currentField'));
        (0, _jquery.default)('#col-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('width', '0%');
        (0, _jquery.default)('#com-' + this.get('currentField').get('fieldRow') + '-' + this.get('currentField').get('fieldColumn')).css('width', '100%');
        (0, _jquery.default)("#".concat(this.get("_droppableColId"))).css('border-style', 'none');
        this.set('dragClass', 'deactivated');
      }
    } //,
    // actions: {
    // 	pushComponent: function(){
    // 		var self = this;
    // 		var movedField; // moved Field Data      
    // 		var currentField = this.get('currentField'); // dropzone Field Data
    // 		//var latestRow = this.get('latestRow'); //Get the value of latest row
    // 		var lowerRowHolder; //Hold lower limit of the ordering
    // 		var upperRowHolder; //Hold upper limit of the ordering
    // 		var isNew = false; //Determine is dropped component already persisted on Database or not
    // 		var columnHolder;
    // 		var rowHolder;
    // 		var lowerColumnHolder;
    // 		var upperColumnHolder;
    // 		var fieldLengthHolder;
    // 		var data;
    // 		if(!Ember.isEmpty(this.get('formContainer.fieldHolder')) || !Ember.isEmpty(this.get('selectedComponent')))
    // 		{
    // 			$('#orderingZone').css('height', '20px');
    // 			$('#orderingZone').css('border-style', 'none');
    // 			$('.drop-col-xs').css('height', '20px');        
    // 			$('.drop-col-xs').css('border-style', 'none');
    // 			$('.orderDrop').css('height', '20px');        
    // 			$('.orderDrop').css('border-style', 'none');
    // 			this.sendAction('resetSelection');
    // 			if(!Ember.isEmpty(this.get('formContainer.fieldHolder')))
    // 			{
    // 				data = this.get('formContainer.fieldHolder');
    // 				movedField = this.get('formContainer.fieldHolder');
    // 			}
    // 			else if(!Ember.isEmpty(this.get('selectedComponent')))
    // 			{
    // 				data = this.get('selectedComponent');
    // 				var newField;
    // 				var customLabel = '';
    // 				if(!data.get('isForm'))
    // 				{
    // 					if(data.get('path').indexOf('collection-components') !== -1)
    // 					{
    // 						if(data.get('path').indexOf('full-name') !== -1)
    // 						{
    // 							customLabel = 'Full Name';
    // 						}
    // 						else if(data.get('path').indexOf('full-address') !== -1)
    // 						{
    // 							customLabel = 'Full Address';
    // 						}
    // 					}
    // 					//Create new Component on form
    // 					newField = this.store.createRecord('field', {
    // 						formcomponent: data,
    // 						label: customLabel,
    // 						form: this.get('form'),
    // 						fieldColumn: 3,
    // 						fieldRow: currentField.get('fieldRow'),
    // 					});  
    // 					//Add component to be displayed on template
    // 					this.get('componentArray').addObject(newField._internalModel);
    // 					movedField = newField;
    // 					//Increment Latest Row
    // 					//this.set('latestRow', this.get('latestRow')+1);
    // 					//latestRow = this.get('latestRow');
    // 					isNew = true;    
    // 				}
    // 			}
    // 			if(data.get('isForm'))
    // 			{
    // 				alert('Form Component can not be deployed on column');
    // 			}
    // 			else
    // 			{
    // 				if(isNew)
    // 				{
    // 					if(currentField.get('fieldLength') <= 3)
    // 					{
    // 						if(!Ember.isEmpty(currentField) && movedField.get('fieldColumn') > currentField.get('fieldColumn'))
    // 						{
    // 							lowerColumnHolder = currentField.get('fieldColumn');
    // 							upperColumnHolder = movedField.get('fieldColumn');
    // 							movedField.set('fieldColumn', currentField.get('fieldColumn'));
    // 							fieldLengthHolder = currentField.get('fieldLength')+1;
    // 							movedField.set('fieldLength', fieldLengthHolder);
    // 							movedField.save().then(function(){
    // 								self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: currentField.get('form').get('id'), fieldRow: currentField.get('fieldRow')}}).then(function(fields){
    // 									var updatedFieldHolder = fields.filter(function(field){
    // 										return field.get('fieldColumn') >= lowerColumnHolder && field.get('fieldColumn') <= upperColumnHolder;
    // 									});
    // 									updatedFieldHolder.forEach(function(field){
    // 										field.set('fieldColumn', field.get('fieldColumn')+1);
    // 									});
    // 									fields.forEach(function(field){
    // 										field.set('fieldLength', fieldLengthHolder);
    // 										//field.save();
    // 									});
    // 									fields.save();
    // 									self.sendAction('sortGrid');
    // 								});
    // 							});            
    // 						}
    // 						else
    // 						{
    // 							movedField.save().then(function(){
    // 								self.sendAction('sortGrid');
    // 							});
    // 						}
    // 					}
    // 					else
    // 					{
    // 						alert('Maximum number of column per row achieved. Cannot have more than 4 columns per row');
    // 					}
    // 				}
    // 				else if(!Ember.isEmpty(this.get('formContainer.fieldHolder')))
    // 				{
    // 					console.log('test-asd');
    // 					if(parseInt(movedField.get('fieldRow')) === parseInt(currentField.get('fieldRow')))
    // 					{
    // 						if(!Ember.isEmpty(currentField) && movedField.get('fieldColumn') > currentField.get('fieldColumn'))
    // 						{
    // 							upperColumnHolder = movedField.get('fieldColumn');
    // 							movedField.set('fieldColumn', currentField.get('fieldColumn'));
    // 							movedField.save().then(function(){
    // 									self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: currentField.get('form').get('id'), fieldRow: currentField.get('fieldRow'), fieldColumn: {'>=': currentField.get('fieldColumn'), '<=': upperColumnHolder}}}).then(function(fields){
    // 										fields.forEach(function(field){
    // 											field.set('fieldColumn', field.get('fieldColumn')+1);
    // 										});
    // 										fields.save().then(function(){
    // 											self.sendAction('sortGrid');
    // 										});                            
    // 									});
    // 							});            
    // 						}
    // 						//Move order forward
    // 						else if(!Ember.isEmpty(currentField) && movedField.get('fieldColumn')+1 < currentField.get('fieldColumn'))
    // 						{
    // 							lowerColumnHolder = movedField.get('fieldColumn')+1;
    // 							movedField.set('fieldColumn', currentField.get('fieldColumn')-1);
    // 							movedField.save().then(function(){
    // 								self.store.query('field', {where: {id: {'!': movedField.get('id')}, form: currentField.get('form').get('id'), fieldRow: currentField.get('fieldRow'), fieldColumn: {'<=': movedField.get('fieldColumn'), '>=':lowerColumnHolder}}}).then(function(fields){
    // 									fields.forEach(function(field){
    // 										field.set('fieldColumn', field.get('fieldColumn')-1);
    // 									});
    // 									fields.save().then(function(){
    // 										self.sendAction('sortGrid');
    // 									});
    // 								});     
    // 							});                    
    // 						}
    // 					}
    // 					else
    // 					{
    // 						if(currentField.get('fieldLength') <= 3)
    // 						{   
    // 							if(parseInt(movedField.get('fieldLength')) === 1)
    // 							{
    // 								console.log('test-zxc');
    // 								//Move order backward and forward
    // 								if(!Ember.isEmpty(currentField) && movedField.get('fieldRow') != currentField.get('fieldRow'))
    // 								{
    // 									lowerRowHolder = currentField.get('fieldRow');
    // 									upperRowHolder = movedField.get('fieldRow');
    // 									columnHolder = currentField.get('fieldColumn');
    // 									if(movedField.get('fieldRow') > currentField.get('fieldRow'))
    // 									{
    // 										rowHolder = currentField.get('fieldRow');
    // 									}
    // 									else
    // 									{
    // 										rowHolder = currentField.get('fieldRow')-1;
    // 									}
    // 									movedField.set('fieldRow', currentField.get('fieldRow'));
    // 									movedField.set('fieldColumn', currentField.get('fieldColumn'));
    // 									movedField.set('fieldLength', currentField.get('fieldLength'));
    // 									this.set('latestRow', this.get('latestRow') - 1);
    // 									movedField.save().then(function(){
    // 										self.store.query('field', {where: {form: currentField.get('form').get('id')}}).then(function(fields){
    // 											var firstFilter = fields.filter(function(fieldValue){
    // 												return parseInt(fieldValue.get('fieldRow')) > upperRowHolder;
    // 											});
    // 											firstFilter.forEach(function(field){
    // 												field.set('fieldRow', field.get('fieldRow') - 1);
    // 											});
    // 											var secondFilter = fields.filter(function(fieldValue){
    // 												return parseInt(fieldValue.get('fieldRow')) === parseInt(rowHolder);
    // 											});
    // 											secondFilter.forEach(function(field){
    // 												field.set('fieldLength', field.get('fieldLength') + 1);
    // 											});
    // 											var thirdFilter = secondFilter.filter(function(fieldValue){
    // 												return parseInt(fieldValue.get('id')) !== parseInt(movedField.get('id')) && parseInt(fieldValue.get('fieldColumn')) >= parseInt(movedField.get('fieldColumn'));
    // 											});
    // 											thirdFilter.forEach(function(field){               
    // 												field.set('fieldColumn', field.get('fieldColumn') + 1);
    // 											});
    // 											fields.save().then(function(){
    // 												self.sendAction('sortGrid');
    // 											});                            
    // 										});
    // 									});            
    // 								}
    // 							}
    // 							else
    // 							{
    // 								if(!Ember.isEmpty(currentField) && movedField.get('fieldRow') != currentField.get('fieldRow'))
    // 								{
    // 									console.log('test');
    // 									lowerRowHolder = currentField.get('fieldRow');
    // 									upperRowHolder = movedField.get('fieldRow');
    // 									columnHolder = currentField.get('fieldColumn');
    // 									upperColumnHolder = movedField.get('fieldColumn');
    // 									rowHolder = currentField.get('fieldRow');
    // 									movedField.set('fieldRow', currentField.get('fieldRow'));
    // 									movedField.set('fieldColumn', currentField.get('fieldColumn'));
    // 									movedField.set('fieldLength', currentField.get('fieldLength'));
    // 									movedField.save().then(function(){
    // 										console.log('test2');
    // 										self.store.query('field', {where: {form: currentField.get('form').get('id')}}).then(function(fields){
    // 											console.log('test3');
    // 											var secondFilter = fields.filter(function(fieldValue){
    // 												return parseInt(fieldValue.get('fieldRow')) === parseInt(rowHolder);
    // 											});
    // 											secondFilter.forEach(function(field){
    // 												field.set('fieldLength', field.get('fieldLength') + 1);
    // 											});
    // 											var thirdFilter = secondFilter.filter(function(fieldValue){
    // 												return parseInt(fieldValue.get('id')) !== parseInt(movedField.get('id')) && parseInt(fieldValue.get('fieldColumn')) >= parseInt(movedField.get('fieldColumn'));
    // 											});
    // 											thirdFilter.forEach(function(field){
    // 												field.set('fieldColumn', field.get('fieldColumn') + 1);
    // 											});
    // 											var fourthFilter = fields.filter(function(fieldValue){
    // 												return parseInt(fieldValue.get('fieldRow')) === parseInt(upperRowHolder);
    // 											});
    // 											fourthFilter.forEach(function(field){
    // 												field.set('fieldLength', field.get('fieldLength') - 1);
    // 											});
    // 											var fifthFilter = fourthFilter.filter(function(fieldValue){
    // 												return parseInt(fieldValue.get('fieldColumn')) > parseInt(upperColumnHolder);
    // 											});
    // 											fifthFilter.forEach(function(field){
    // 												field.set('fieldColumn', field.get('fieldColumn') - 1);
    // 											});
    // 											fields.save().then(function(){
    // 												self.sendAction('sortGrid');
    // 											});                            
    // 										});
    // 									});            
    // 								}
    // 							}
    // 						}
    // 						else
    // 						{
    // 							alert('Maximum number of column per row achieved. Cannot have more than 4 columns per row');
    // 						}
    // 					}   
    // 				}
    // 			}
    // 		}                           
    // 	}
    // }

  });

  _exports.default = _default;
});