define("client/pods/components/w3office-forms/standard-components/dropdown-list/component-field/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isSkeleton:skeleton'],
    didRender: function didRender() {
      if (this.get('currentTheme')) {
        (0, _jquery.default)("#ddl-".concat(this.get('field').id)).css({
          "color": this.get('currentTheme').valueFontColor,
          "font-size": this.get('currentTheme').valueFontSize
        });
      }
    },
    actions: {
      mutateEntry: function mutateEntry(val) {
        this.mutateEntry(val);
      }
    }
  });

  _exports.default = _default;
});