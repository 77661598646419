define("client/pods/components/w3oprocess/task-paging/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userprocesstask: '',
    memo: '',
    modalerrorlabel: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set("showPages", 5);
      this.set("currentPageInside", Number(this.get("listPage")));
      this.generatePagination();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      Ember.run.later(function () {
        self.get('processList').forEach(function (userprocesstask) {
          if (moment().isAfter(userprocesstask.get('processtask.dueDate'))) {
            userprocesstask.setProperties({
              passDueDate: true
            });
          } else {
            userprocesstask.setProperties({
              passDueDate: false
            });
          }
        });
      }, 500);
      this.set("currentPageInside", Number(this.get("listPage")));
      this.generatePagination();
    },
    generatePagination: function generatePagination() {
      var lastPage = parseInt(this.get("totalPage"), 10);
      var currentPage = parseInt(this.get("listPage"), 10);
      var showPages = this.get("showPages");
      var pageStart;

      if (currentPage <= showPages) {
        pageStart = 0;
      } else if (currentPage % showPages === 0) {
        pageStart = (Math.floor(currentPage / showPages) - 1) * showPages;
      } else {
        pageStart = Math.floor(currentPage / showPages) * showPages;
      }

      var arrPages = [];

      for (var i = 1; i <= showPages; i++) {
        if (pageStart + i <= lastPage) {
          arrPages.push(pageStart + i);
        }
      }

      this.set("pages", arrPages);
      this.set("currentPageSet", Math.ceil(currentPage / showPages));
      this.set("lastPageSet", Math.ceil(lastPage / showPages));

      if (this.get("currentPageSet") === 1) {
        this.set("showPrevFirstPage", false);
      } else {
        this.set("showPrevFirstPage", true);
      }

      if (this.get("currentPageSet") === this.get("lastPageSet")) {
        this.set("showNextLastPage", false);
      } else {
        this.set("showNextLastPage", true);
      }
    },
    actions: {
      doSort: function doSort(param) {
        this.doSort(param);
      },
      doSearch: function doSearch() {
        this.doSearch();
      },
      resetFilter: function resetFilter() {
        this.resetFilter();
      },
      changeColumn: function changeColumn(param) {
        this.changeColumn(param);
      },
      gotoDetail: function gotoDetail(id) {
        this.gotoDetail(id);
      },
      openDeleteModal: function openDeleteModal(userprocesstask) {
        (0, _jquery.default)('#delete-task-modal').modal('toggle');
        this.set('userprocesstask', userprocesstask);
      },
      continueProcess: function continueProcess(userTask) {
        this.continueProcess(userTask);
      },
      stopProcess: function stopProcess(userTask) {
        if (this.get('memo') === '') {
          this.set('modalerrorlabel', 'Please Fill the Memo');
          (0, _jquery.default)('#memo-form-stop').addClass('has-error');
        } else {
          this.stopProcess(this.get('userprocesstask'), this.get('memo'));
          (0, _jquery.default)('#stop-process-modal').modal('toggle');
          this.set('memo', '');
          this.set('modalerrorlabel', '');
          (0, _jquery.default)('#memo-form').removeClass('has-error');
        }
      },
      deleteTask: function deleteTask() {
        this.deleteTask(this.get('userprocesstask'));
      },
      deleteAllTask: function deleteAllTask() {
        this.deleteAllTask();
      },
      changeLimit: function changeLimit(param) {
        this.changeLimit(param);
      },
      prevList: function prevList() {
        this.prevList();
      },
      nextList: function nextList() {
        this.nextList();
      },
      claimTask: function claimTask(userTask) {
        this.claimTask(userTask);
      },
      openStopModal: function openStopModal(userprocesstask) {
        (0, _jquery.default)('#stop-process-modal').modal('toggle');
        this.set('userprocesstask', userprocesstask);
      }
    }
  });

  _exports.default = _default;
});