define("client/pods/components/w3office-forms/advanced-components/custom-attribute/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend(_baseComponent.default, {
    customAttributesList: Ember.computed('field.customAttributesList', function () {
      return this.get('field').get('customAttributesList');
    }),
    init: function init() {
      this._super();

      if (!this.get('authoring')) {
        if (this.get('field').id) {
          this.fieldActionGateway(this, {
            actionName: 'populateFieldFilter'
          });
        } else {
          this.fieldActionGateway(this.get('field'), {
            actionName: 'fetchSuggestions'
          });
        }
      }

      if (this.get('entryContainer')) {
        this.set('containerValue', Object.assign({}, this.get('entryContainer'))); // Convert the value (from API) to string if the type is object

        if (this.get('environment') === "edit" && _typeof(this.get('entryContainer')) === 'object') {
          this.setEntryContainer(this.get('field'), JSON.stringify(this.get('entryContainer')));
        }
      } else {
        this.set('containerValue', {});
      }
    },
    actions: {
      setEntryContainer: function setEntryContainer(field, value) {
        this.setEntryContainer(field, value);
      }
    }
  });

  _exports.default = _default;
});