define("client/pods/components/chart-builder-v2/create-chart-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedOption: null,
    actions: {
      setType: function setType(param) {
        this.setType(param, this);
      },
      setTable: function setTable(param) {
        this.setTable(param, this);
      }
    }
  });

  _exports.default = _default;
});