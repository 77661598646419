define("client/pods/process-manager/my-task/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y2nem7Q1",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[1,[28,\"component\",[\"w3oprocess/task-paging\"],[[\"processList\",\"inbox\",\"mytask\",\"queued\",\"involved\",\"archived\",\"listPage\",\"totalPage\",\"limit\",\"sortBy\",\"sortOrder\",\"searchInput\",\"hasModel\",\"searchColumn\",\"showLoading\",\"doSort\",\"doSearch\",\"resetFilter\",\"changeColumn\",\"gotoDetail\",\"deleteTask\",\"deleteAllTask\",\"changeLimit\",\"prevList\",\"nextList\"],[[24,[\"processList\"]],false,true,false,false,false,[24,[\"listPage\"]],[24,[\"totalPage\"]],[24,[\"limit\"]],[24,[\"sortBy\"]],[24,[\"sortOrder\"]],[24,[\"searchInput\"]],[24,[\"hasModel\"]],[24,[\"searchColumn\"]],[24,[\"loading\"]],[28,\"route-action\",[\"doSort\"],null],[28,\"route-action\",[\"doSearch\"],null],[28,\"route-action\",[\"resetFilter\"],null],[28,\"route-action\",[\"changeColumn\"],null],[28,\"route-action\",[\"gotoDetail\"],null],[28,\"route-action\",[\"deleteTask\"],null],[28,\"route-action\",[\"deleteAllTask\"],null],[28,\"route-action\",[\"changeLimit\"],null],[28,\"route-action\",[\"prevList\"],null],[28,\"route-action\",[\"nextList\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/process-manager/my-task/index/template.hbs"
    }
  });

  _exports.default = _default;
});