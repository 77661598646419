define("client/pods/components/w3office-forms/advanced-components/form-upload/component", ["exports", "client/mixins/w3office-forms/component-form-upload"], function (_exports, _componentFormUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentFormUpload.default, {
    /*
    *  Inital Setup for Component Value and File Extension Validation
    *  Set Field to show component or not based on field config
    */
    init: function init() {
      this._super();
    }
  });

  _exports.default = _default;
});