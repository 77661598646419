define("client/pods/components/dashboard/dab-timechart/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['w-100'],
    isSizeConfig: false,
    slideshowWidth: 0,
    slideshowHeight: 350,
    init: function init() {
      this._super();

      this.chartSize = {
        height: 250,
        width: 400
      };
    },
    willRender: function willRender() {
      var self = this;
      var sw = (0, _jquery.default)(window).width() - 250 - 160; // howto get the leftside panel width?

      self.set('slideshowWidth', sw);
      self.set('chartSize', {
        height: self.get('slideshowHeight'),
        width: self.get('slideshowWidth')
      }); // console.log('chartSize: ' + self.get('chartSize.height') + ' ' + self.get('chartSize.width'));
      // console.log($(window).width());
    },
    actions: {
      executeDabTimeChartData: function executeDabTimeChartData(component) {
        this.executeDabTimeChart(component);
      },
      dabTimeChartDataSetRange: function dabTimeChartDataSetRange(component, id, range) {
        this.dabTimeChartSetRange(component, id, range);
      },
      dabTimeChartDataGenerateChart: function dabTimeChartDataGenerateChart(component, id) {
        this.dabTimeChartGenerateChart(component, id);
      },
      dabTimeChartDataSetParamCollection: function dabTimeChartDataSetParamCollection(component, paramcollection) {
        this.dabTimeChartSetParamCollection(component, paramcollection);
      },
      dabTimeChartDataSaveParam: function dabTimeChartDataSaveParam(component) {
        this.dabTimeChartSaveParam(component);
      }
    }
  });

  _exports.default = _default;
});