define("client/pods/components/w3office-forms/field-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    promptMessage: Ember.computed('field', function () {
      if (this.get('field').get('label')) {
        return '\'' + this.get('field').get('label') + '\' field';
      } else {
        return 'this field';
      }
    }),
    actions: {
      toggleDelete: function toggleDelete() {
        this.toggleDelete(this.get('field'));
      },
      deleteField: function deleteField() {
        this.deleteField(this.get('field'));
      }
    }
  });

  _exports.default = _default;
});