define("client/pods/components/report-generator/viewer-setting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addviewerparam: function addviewerparam() {
        this.addviewerparam();
      },
      deleteviewerparam: function deleteviewerparam(param) {
        this.deleteviewerparam(param);
      },
      saveviewerparam: function saveviewerparam() {
        this.saveviewerparam();
      },
      saveviewersetting: function saveviewersetting() {
        this.saveviewersetting();
      }
    }
  });

  _exports.default = _default;
});