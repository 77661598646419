define("client/mixins/w3office-forms/query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Mixin.create({
    generateQueryParamType2: function generateQueryParamType2(dynamicParams) {
      /**
       * Example:
       * whereObject: {
       *   isdelete: false,
       *   organizationnum: 1,
       *   companynum: 1,
       *   status: "Drafted",
       *   order: {
       *     ">" : 2
       *   }
       * } 
       * 
       * recordColumnDataType = {
       *   companynum: "number",
       *   contenttype: "string",
       *   isdelete: "boolean",
       *   organizationnum: "number",
       *   status: "string"
       * }
       */
      var whereObject = dynamicParams.whereObject,
          recordColumnDataType = dynamicParams.recordColumnDataType;
      var arrQueryParams = [];

      if (whereObject) {
        for (var key in whereObject) {
          if (whereObject.hasOwnProperty(key) && whereObject[key] !== null) {
            if (_typeof(whereObject[key]) === "object") {
              // type 'string', 'number', and 'date' come here where the operator NOT '='
              for (var key2 in whereObject[key]) {
                if (whereObject[key].hasOwnProperty(key2)) {
                  var whereClause = {
                    column: key,
                    operator: key2,
                    value: whereObject[key][key2]
                  };

                  if (recordColumnDataType && recordColumnDataType[key]) {
                    whereClause.type = recordColumnDataType[key];
                  } else {
                    whereClause.type = "string";
                    console.error("ERROR: query-param mixins. ".concat(key, " doesn't have recordColumnDataType. Please fix"));
                  }

                  arrQueryParams.push(whereClause);
                }
              }
            } else {
              var _whereClause = {
                column: key,
                operator: '='
              }; // Convert 'boolean' value to 'string'

              if (["boolean", "number"].includes(_typeof(whereObject[key]))) {
                _whereClause.value = whereObject[key].toString();
              } else {
                _whereClause.value = whereObject[key];
              } // 'type' consist of [string, number, date, boolean]


              if (recordColumnDataType && recordColumnDataType[key]) {
                _whereClause.type = recordColumnDataType[key];
              } else {
                _whereClause.type = "string";
                console.error("ERROR: query-param mixins. ".concat(key, " doesn't have recordColumnDataType. Please fix"));
              }

              arrQueryParams.push(_whereClause);
            }
          }
        }
      } else {
        console.error("ERROR: query-param mixin. whereObject is empty!");
      }

      return JSON.stringify(arrQueryParams);
    },
    generateRecordColumnDataType: function generateRecordColumnDataType(whereObject, definedDataType) {
      var returnObj = {};

      if (whereObject && definedDataType) {
        for (var key in whereObject) {
          if (whereObject.hasOwnProperty(key)) {
            if (definedDataType.hasOwnProperty(key)) {
              returnObj[key] = definedDataType[key];
            } else {
              if (typeof whereObject[key] === "boolean") {
                returnObj[key] = "boolean";
              } else {
                returnObj[key] = "string"; //maybe "number"?
              }
            }
          }
        }
      }

      return returnObj;
    }
  });

  _exports.default = _default;
});