define("client/pods/components/w3o-scheduler/filter-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addFilter: function addFilter() {
        console.log('addFilter');
        var self = this; // var filterList = this.get('filterList');

        var entryTypeContainer = this.get('entryTypeContainer'); // remove filtered column
        // var temp = [];
        // for (var i = 0; i < filterList.length; i++) {
        //  temp.push(filterList[i].type.column);
        // }
        // for (var j = 0; j < entryTypeContainer.length; j++) {
        //  for (var k = 0; k < temp.length; k++) {
        //      if (entryTypeContainer[j].value === temp[k]) {
        //          entryTypeContainer.splice(j, 1);
        //      }
        //  }
        // }
        // create new filter object

        this.get('filterList').addObject({
          type: {
            label: entryTypeContainer[0].label,
            column: entryTypeContainer[0].column
          },
          value: ''
        });
        Ember.run.later(function () {
          if (entryTypeContainer.length === 1) {
            self.set('canAdd', false);
          }
        }, 50);
      },
      deleteFilter: function deleteFilter(filter) {
        console.log('deleteFilter');
        console.log(filter); // add to entryTypeContainer 
        // this.get('entryTypeContainer').addObject({
        //  label: filter.type.label,
        //  column: filter.type.column
        // });
        // delete selected filter

        this.get('filterList').removeObject(filter);
        this.set('canAdd', true);
      },
      advanceFilters: function advanceFilters() {
        console.log('advanceFilters');
        var filterList = this.get('filterList');
        var flag = 0;
        filterList.forEach(function (filter) {
          if (filter.value === '') {
            flag++;
          }
        });

        if (flag > 0) {
          console.log('fill all value');
        } else {
          // send filter values to be processed on route
          (0, _jquery.default)('#advanceFilter').modal('hide');
          this.set('canClick', false);
          this.advanceFilters(this.get('filterList'));
        }
      }
    }
  });

  _exports.default = _default;
});