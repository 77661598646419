define("client/pods/menu-builder/index/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/menu-builder/menu-crud"], function (_exports, _jquery, _checkUserRole, _menuCrud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import serializablejson from 'client/mixins/menu-builder/serializablejson';
  var _default = Ember.Route.extend(_checkUserRole.default, _menuCrud.default, {
    beforeModel: function beforeModel() {
      this._super(); //add this at the very first of beforeModel if there are any beforeModel

    },
    renderTemplate: function renderTemplate() {
      this._super(); //add this at the very first of renderTemplate if there are any renderTemplate

    },
    authObject: Ember.inject.service('session'),
    model: function model() {
      var self = this;
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax({
          type: 'GET',
          url: '/api/v1/menus/getParentMenu',
          success: function success(response) {
            resolve(response);
          },
          error: function error(xhr, status, _error) {
            console.log('Error ' + _error);
            resolve([]);
          }
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set("menuList", []);
      this.controllerFor('menu-builder').set('indexSet', true);
      var applicationResult = [];

      _jquery.default.ajax({
        type: 'GET',
        url: 'api/v1/applications?sort=id',
        success: function success(result) {
          result.applications.forEach(function (application) {
            var obj = Ember.Object.create(application);
            applicationResult.push(obj);
          });
          controller.set("applicationList", applicationResult);
        },
        error: function error(xhr, status, _error2) {
          console.log('Error ' + _error2);
        }
      }); //import


      controller.set('errorUploadFile', false);
      controller.set('successUploadFile', false);
      controller.set('messageUploadFile', '');
      controller.set('fileName', '');
      controller.set('jsonIsLoaded', false);
      controller.set('jsonFile', null);
      controller.set('loadingImport', false);
      /*Populate appList*/

      _jquery.default.ajax({
        type: "GET",
        url: this.store.adapterFor('application').get('namespace') + '/menus/getAvailableAppId',
        success: function success(response) {
          controller.set('appList', response);
        }
      });
      /*End of Populate appList*/


      var result = [];
      model.forEach(function (menu) {
        if (menu.appID == controller.get('appFilter')) {
          result.push(Ember.Object.create(menu));
        }
      });
      controller.set("menuList", result);
    },
    downloadFile: function downloadFile(masterJSON) {
      //download the file here
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(masterJSON));
      var dlAnchorElem = document.getElementById('downloadAnchorElem');
      var file_name = "dataMenuJSON"; //remove any spaces

      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", file_name + ".json");
      dlAnchorElem.click();
    },
    generateField: function generateField(meta, model) {
      var self = this;
      var newField;
      var newFieldgroupcontent;

      if (Ember.isEmpty(meta)) {
        this.controller.set('loadingImport', false);
        this.controller.set('successUploadFile', true);
        this.controller.set('messageUploadFile', 'Import Form Complete!');
        this.controller.set('jsonIsLoaded', false);
        return true;
      } else {
        meta[0]['form'] = model;
        newField = this.store.createRecord('field', meta[0]);
        newField.save().then(function () {
          if (meta[0]['meta']) {
            meta[0]['meta'].forEach(function (value) {
              value['field'] = newField;
              newFieldgroupcontent = self.store.createRecord('fieldgroupcontent', value);
              newFieldgroupcontent.save();
            });
            meta.shift();
            return self.generateField(meta, model);
          } else {
            meta.shift();
            return self.generateField(meta, model);
          }
        });
      }
    },
    saveChildren: function saveChildren(newParentValue, children) {
      var self = this;
      children.forEach(function (child) {
        var newChild = self.store.createRecord("menu", {
          title: child['title'],
          unique: child['unique'],
          glyph: child['glyph'],
          link: child['link'],
          formid: child['formid'],
          theme: child['theme'],
          priority: child['priority'],
          menuparentnum: newParentValue
        });
        newChild.save().then(function (newChildValue) {
          if (child['children']) {
            if (child['children'].length > 0) {
              self.saveChildren(newChildValue, child['children']);
            }
          }
        });
      });
    },
    actions: {
      changeAppFilter: function changeAppFilter(selectedAppFilter) {
        var controller = this.get('controller');
        var model = controller.get('model');
        controller.set('appFilter', selectedAppFilter);
        var result = [];
        model.forEach(function (menu) {
          if (menu.appID == selectedAppFilter) {
            result.push(Ember.Object.create(menu));
          }
        });
        controller.set("menuList", result);
      },
      generateJSONFile: function generateJSONFile() {
        var masterJSON = {};
        var menuList = this.controller.get("menuList");
        masterJSON['menuList'] = [];
        menuList.forEach(function (menu) {
          var container1 = menu.serialize();

          if (menu.get("children").get("length") > 0) {
            container1["children"] = [];
            menu.get("children").forEach(function (child1) {
              var container2 = child1.serialize();

              if (child1.get("children").get("length") > 0) {
                container2['children'] = [];
                child1.get("children").forEach(function (child2) {
                  var container3 = child2.serialize();
                  container2['children'].push(container3);
                });
                container1['children'].push(container2);
              } else {
                container1['children'].push(container2);
              }
            });
            masterJSON['menuList'].push(container1);
          } else {
            masterJSON['menuList'].push(container1);
          }
        });
        console.log(masterJSON);
        this.downloadFile(masterJSON);
      },
      uploadFile: function uploadFile(file) {
        if (file['file']) {
          this.controller.set('errorUploadFile', false);
          this.controller.set('jsonFile', file);
          this.controller.set('jsonIsLoaded', true);
          this.controller.set('fileName', file.get('name'));
        } else {
          this.controller.set('fileName', file.get('name'));
          this.controller.set('errorUploadFile', true);
          this.controller.set('jsonIsLoaded', false);
          this.controller.set('messageUploadFile', 'Please select file with .json extension');
        }
      },
      uploadgenerateForm: function uploadgenerateForm(file) {
        var self = this;
        var fd;
        var result;
        this.controller.set('loadingImport', true);
        var accessToken = self.container.lookup('simple-auth-authorizer:oauth2-bearer').session.content.access_token;
        var requestHeaders = {
          Authorization: 'bearer ' + accessToken
        };
        var namespace = this.store.adapterFor('application').get('namespace');
        var url = namespace + '/jsonuploads/upload';
        var filepath = '/uploads/menujsonfile/';
        file.upload(url, {
          headers: requestHeaders,
          data: {
            path: filepath
          }
        }).then(function (response) {
          console.log(response);
          alert("upload success");
          fd = response['body']['files'][0]['fd'];
          console.log(fd);
          var url = self.store.adapterFor('application').get('namespace') + "/jsonuploads/download?fd=";

          _jquery.default.get(url + fd, function (response) {
            result = JSON.parse(response);

            if (result['menuList'].length > 0) {
              console.log('ada isi');
              console.log(result['menuList']);
              result['menuList'].forEach(function (parentMenu) {
                console.log(parentMenu);
                var newParent = self.store.createRecord("menu", {
                  title: parentMenu['title'],
                  unique: parentMenu['unique'],
                  glyph: parentMenu['glyph'],
                  link: parentMenu['link'],
                  formid: parentMenu['formid'],
                  theme: parentMenu['theme'],
                  priority: parentMenu['priority'],
                  menuparentnum: null
                });
                newParent.save().then(function (newParentValue) {
                  if (parentMenu['children'].length > 0) {
                    self.saveChildren(newParentValue, parentMenu['children']);
                  } else {}
                });
              });
            } else {
              console.log('tidak ada isi');
            }
            /*var newForm = self.store.createRecord('form', {
            	name: result['name'],
            	description: result['description'],
            	submitButtonName: result['submitButtonName'],
            	isLimit: result['isLimit'],
            	limit: result['limit']
            });
            newForm.save().then(function(newFormValue){
            	self.generateField(result['meta'], newFormValue);
            });*/

          });
        });
      }
    }
  });

  _exports.default = _default;
});