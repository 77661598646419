define("client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/param-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m3wqWKRJ",
    "block": "{\"symbols\":[\"xs\",\"select\"],\"statements\":[[4,\"x-select\",null,[[\"value\",\"class\",\"on-change\"],[[24,[\"param\",\"genreportconfigselectnum\",\"content\"]],\"form-control\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"param\",\"genreportconfigselectnum\"]]],null]],null]]],{\"statements\":[[0,\"\\t\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/param-select/template.hbs' @ L2:C4) \"],null]],[[\"value\"],[null]],{\"statements\":[[0,\"Select Option\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"selectContents\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/param-select/template.hbs' @ L4:C5) \"],null]],[[\"value\"],[[23,2,[]]]],{\"statements\":[[1,[23,2,[\"label\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/report-viewer/dab-user-param-collection/dab-user-param/param-select/template.hbs"
    }
  });

  _exports.default = _default;
});