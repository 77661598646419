define("client/pods/components/w3office-forms/standard-components/boolean-check/component-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yPfGKR+y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[8],[0,\"\\n\\t\"],[1,[28,\"input\",null,[[\"type\",\"change\",\"checked\",\"disabled\"],[\"checkbox\",[28,\"action\",[[23,0,[]],\"mutateEntry\"],null],[24,[\"containerValue\"]],[24,[\"field\",\"isDisabled\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/standard-components/boolean-check/component-field/template.hbs"
    }
  });

  _exports.default = _default;
});