define("client/pods/users-roles/role-manager/app/edit/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "client/mixins/w3o-users-roles/create-edit-route"], function (_exports, _checkUserRole, _createEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, _createEditRoute.default, {
    appId: '',
    authObject: Ember.inject.service('session'),
    edit: true,
    beforeModel: function beforeModel(transition) {
      this._super();

      this.set('appId', this.controllerFor("users-roles.role-manager.app").get("model.id"));
    },
    model: function model(param) {
      return this.store.findRecord('allowedroute', param.route);
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render({
        outlet: 'edit'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('typeSelected', true);

      if (!model.get('routeName')) {
        controller.set('type', 'group');
      } else if (model.get('routeName') === 'Action') {
        controller.set('type', 'action');
      } else {
        controller.set('type', 'route');
      }

      controller.set('parent', parseInt(model.get('parent.id'), 10));

      this._super();
    },
    actions: {
      selectType: function selectType(param) {
        this.selectType(param);
      },
      changeParent: function changeParent(param) {
        this.changeParent(param);
      },
      editModule: function editModule(model) {
        this.saveRoute(model);
      },
      goTo: function goTo(param) {
        this.goTo(param);
      }
    }
  });

  _exports.default = _default;
});