define("client/pods/components/w3o-users-roles/google-recaptcha/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['g-recaptcha'],
    // attributeBindings: ['siteKey:data-sitekey', 'data-theme', 'data-size', 'data-callback', 'data-expired-callback', 'data-tabindex'],
    //localhost
    //192.168.1.12
    lang: 'en',
    resetTrigger: false,
    _isSetup: false,
    _attempts: 0,
    // dragStart(event) {
    //     //Transfer Field Properties
    //     this.set('formContainer.fieldHolder', this.get('field'));
    // },
    _maxAttempts: Ember.computed(function () {
      return 20;
    }).readOnly(),
    _interval: Ember.computed(function () {
      return 100; // Time between polls (in ms)
    }).readOnly(),
    // verifyCallback: function(data){
    //     console.log(this);
    //     console.log(this.get('resetTrigger'));
    //     $.ajaxPrefilter(function(options, oriOpt, jqXHR) {
    //         jqXHR.setRequestHeader("X-Recaptcha-Token", data);
    //     });
    //     // let requestHeaders = {
    //     //     "X-Recaptcha-Token": response
    //     // };
    //     //var url = this.store.adapterFor('application').get('namespace')+ '/fields/verifyRecaptcha';
    //     var url = "http://localhost:4200/api/v1/fields/verifyRecaptcha";
    //     $.ajax({
    //         type: "POST",
    //         url: url,
    //         data: { 
    //             secret: '6LeYnQ4TAAAAAHXBNaUyzvardW3qmNYxHirkvlF-',
    //             response: data,
    //             //path: '/recaptcha/api/siteverify?secret=6LeYnQ4TAAAAAHXBNaUyzvardW3qmNYxHirkvlF-&response='+data                
    //             'g-recaptcha-response': data
    //         },
    //         success: function(response){
    //             console.log(response);
    //             console.log(response.success);
    //             self.set('resetTrigger', true);
    //         }
    //     });
    // },
    setupGrecaptcha: function setupGrecaptcha() {
      var _this = this;

      var siteKey = this.store.adapterFor('application').get('recaptchaSiteKey');
      var secretKey = this.store.adapterFor('application').get('recaptchaSecretKey'); //render captcha

      if (this.autoValidate != undefined && this.autoValidate != null && this.autoValidate != true) {
        grecaptcha.render('recapt', {
          'sitekey': siteKey,
          //'callback' : this.verifyCallback
          'callback': function callback(data) {
            _jquery.default.ajaxPrefilter(function (options, oriOpt, jqXHR) {
              jqXHR.setRequestHeader("X-Recaptcha-Token", data);
            });

            _this.recaptchaWait();

            _this.pipeRecaptchaResponse(data);
          },
          'expired-callback': function expiredCallback() {
            console.log('captcha expired');

            _this.expiredCallback();
          }
        });
      } else {
        grecaptcha.render('recapt', {
          'sitekey': siteKey,
          //'callback' : this.verifyCallback
          'callback': function callback(data) {
            _jquery.default.ajaxPrefilter(function (options, oriOpt, jqXHR) {
              jqXHR.setRequestHeader("X-Recaptcha-Token", data);
            }); // let requestHeaders = {
            //     "X-Recaptcha-Token": response
            // };


            var url = _this.store.adapterFor('application').get('namespace') + '/resetpasses/verifyRecaptcha';

            _this.recaptchaWait(); //verify captcha response


            _jquery.default.ajax({
              type: "POST",
              url: url,
              data: {
                'secret-key': secretKey,
                'site-key': siteKey,
                'g-recaptcha-response': data
              },
              success: function success(response) {
                //this.set('resetTrigger', true);
                _this.pipeRecaptchaResponse(response.success);
              }
            });
          }
        });
      }

      this.set('_isSetup', true);
    },
    resetGrecaptcha: Ember.observer('resetTrigger', function () {
      if (this.get('_isSetup') === true && this.get('resetTrigger') === true) {
        grecaptcha.reset('recapt');
        this.set('resetTrigger', false);
      }
    }),
    pollForObject: function pollForObject() {
      Ember.Logger.debug("Polling for grecaptcha");

      if (window.grecaptcha !== undefined) {
        this.setupGrecaptcha();
      } else if (this.get('_attempts') < this.get('_maxAttempts')) {
        this.set('_attempts', this.get('_attempts') + 1);
        Ember.run.later(this, function () {
          this.pollForObject();
        }, this.get('_interval'));
      } else {
        Ember.Logger.error("Failed to get grecapthca script");
      }
    },
    resizeReCaptcha: function resizeReCaptcha() {
      var width = (0, _jquery.default)(".g-recaptcha").parent().width();
      var scale;

      if (width < 302) {
        scale = width / 302;
        (0, _jquery.default)(".g-recaptcha").removeClass("justify-content-center");
      } else {
        scale = 1;
        (0, _jquery.default)(".g-recaptcha").addClass("justify-content-center");
      }

      (0, _jquery.default)(".g-recaptcha").css('transform', "scale(".concat(scale, ")"));
      (0, _jquery.default)(".g-recaptcha").css('-webkit-transform', "scale(".concat(scale, ")"));
      (0, _jquery.default)(".g-recaptcha").css('transform-origin', '0 0');
      (0, _jquery.default)(".g-recaptcha").css('-webkit-transform-origin', '0 0');
    },
    init: function init() {
      var _this2 = this;

      this._super();

      var siteKey = this.store.adapterFor('application').get('recaptchaSiteKey');
      var secretKey = this.store.adapterFor('application').get('recaptchaSecretKey');

      if (!Ember.isEmpty(siteKey) && !Ember.isEmpty(secretKey) && !this.get('authoring')) {
        _jquery.default.getScript("https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=".concat(this.get('lang')), function (data, textStatus, jqxhr) {
          _this2.pollForObject();
        });
      }

      (0, _jquery.default)(document).ready(function () {
        (0, _jquery.default)(window).on('resize', function () {
          _this2.resizeReCaptcha();
        });

        _this2.resizeReCaptcha();
      });
    },
    actions: {// deleteComponent: function(){
      //     //Delete Component
      //     this.sendAction('deleteComponent', this.get('field'));
      // },
      // moveComponent: function(){
      //     //Delete Component
      //     this.set('formContainer.fieldHolder', this.get('field'));
      //     $('.orderDrop').css('height', '60px');
      //     $('.orderDrop').css('border-style', 'dotted');
      // },
      // commitFieldChange: function(){
      //     this.get('field').save();
      // },
      // showProperties: function(){
      //     this.sendAction('showProperties', this.get('field'));
      // }
    }
  });

  _exports.default = _default;
});