define("client/pods/components/dashboard-v2/header-top/component", ["exports", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin", "axios"], function (_exports, _jquery, _authenticatedRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_authenticatedRouteMixin.default, {
    // menu is array which contains navigation for template
    authObject: Ember.inject.service('session'),
    init: function init() {
      this._super();

      this.menu = [{
        'title': 'Dashboard Builder',
        'glyph': 'fa-copy',
        'link': 'dashboard-builder',
        'unique': 'form',
        'subunique': 'subform',
        'theme': '#ffffff',
        'submenu': [{
          'title': 'Dashboard List',
          'link': 'dashboard-builder'
        }]
      }];
    },
    container: '',
    newNotif: false,
    imageLoaded: false,

    /*
    * Wait for session data to be processed, so need to use didReceiveAttrs
    * Get Company Logo if there is association to euser
    */
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.isEmpty(this.get('session'))) {
        var self = this;

        if (!Ember.isEmpty(this.get('session.user.euser.content')) && this.get('imageLoaded') === false) {
          var companynum;
          this.set('imageLoaded', true);

          if (this.get('session.user.euser.companynum')) {
            companynum = this.get('session.user.euser.companynum');
          } else {
            companynum = this.get('session.user.euser.optmm_company');
          }

          companynum.then(function (company) {
            if (company) {
              var ajaxCall = function ajaxCall(url) {
                (0, _axios.default)({
                  method: 'POST',
                  url: url,
                  responseType: 'arraybuffer'
                }).then(function (e) {
                  if (e.status == 200) {
                    window.URL = window.URL || window.webkitURL;
                    var blob = new Blob([e.data], {
                      type: 'image/jpeg'
                    });
                    var reader = new window.FileReader();
                    reader.readAsDataURL(blob);

                    reader.onloadend = function () {
                      self.set('appLogo', reader.result);
                    };
                  }
                });
              };

              var url;

              if (uuid.test(company.get('companylogopath'))) {
                self.store.query('formuploadedfile', {
                  where: {
                    UUID: company.get('companylogopath')
                  }
                }).then(function (resFormuploadedfile) {
                  url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + encodeURIComponent(resFormuploadedfile.get('firstObject').get('filePath'));
                  ajaxCall(url);
                });
              } else if (company.get('companylogopath')) {
                url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + encodeURIComponent(company.get('companylogopath'));
                ajaxCall(url);
              }
            }
          });
        }
      }
    },
    model: Ember.computed(function () {
      var userId = this.session.get('user.id'); // return this.store.query('page', { sort: 'order asc', limit: 999 });

      return this.store.findRecord('user', userId);
    }),
    // actions will sendAction to respective routes on template where this component is called
    actions: {
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      },
      showHideSidebar: function showHideSidebar() {
        // check sidebar shown/hidden
        var flag = (0, _jquery.default)('.show-sidebar');

        if (flag.length === 0) {
          (0, _jquery.default)('#headernavdesktop-accordion').addClass('show-sidebar');
          (0, _jquery.default)('#headernavdesktop-accordion').removeClass('hide-sidebar');
          (0, _jquery.default)('.sidebar-mask').show();
        } else {
          (0, _jquery.default)('.show-sidebar').addClass('hide-sidebar');
          (0, _jquery.default)('.show-sidebar').removeClass('show-sidebar');
          (0, _jquery.default)('.sidebar-mask').hide();
        }
      },
      hideSidebarOnClick: function hideSidebarOnClick() {
        // hide sidebar on click
        (0, _jquery.default)('.show-sidebar').addClass('hide-sidebar');
        (0, _jquery.default)('.show-sidebar').removeClass('show-sidebar');
        (0, _jquery.default)('.sidebar-mask').hide();
      }
    }
  });

  _exports.default = _default;
});