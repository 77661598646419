define("client/pods/components/dashboard/dab-timechartdata/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['w-100'],
    isSizeConfig: false,
    slideshowWidth: 0,
    slideshowHeight: 350,
    showFirstGenerateButton: null,
    organizationname: 'All Organizations',
    companyname: 'All Companies',
    init: function init() {
      this._super();

      var self = this;
      this.chartSize = {
        height: 250,
        width: 400
      };
      var sw = (0, _jquery.default)(window).width() - 250 - 160; // howto get the leftside panel width?

      this.set('slideshowWidth', sw);
      this.set('chartSize', {
        height: this.get('slideshowHeight'),
        width: this.get('slideshowWidth')
      });

      if (!Ember.isEmpty(self.get('chart').get('organizationnum.id'))) {
        self.set('organizationname', self.get('chart').get('organizationnum.organizationname'));
      }

      if (!Ember.isEmpty(self.get('chart').get('companynum.id'))) {
        self.set('companyname', self.get('chart').get('companynum.companyname'));
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      self.set('loadingData', true);
      this.executeDabTimeChartData(this);
    },
    actions: {
      toggleButton: function toggleButton() {
        this.set('showButton', !this.get('showButton'));
      },
      updateChart: function updateChart(id) {
        this.dabTimeChartDataGenerateChart(this, id);
      },
      setRange: function setRange(id, range) {
        this.dabTimeChartDataSetRange(this, id, range);
      },
      generateChart: function generateChart(id) {
        this.dabTimeChartDataGenerateChart(this, id);
      },
      setParamcollection: function setParamcollection(paramcollection) {
        if (paramcollection) {
          this.set('paramLoading', true);
          this.dabTimeChartDataSetParamCollection(this, paramcollection);
        } else {
          this.set('selectedParamcollection', null);
        }
      },
      saveParam: function saveParam() {
        this.dabTimeChartDataSaveParam(this);
      }
    }
  });

  _exports.default = _default;
});