define("client/pods/components/w3office-forms/component-picker/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.set('isTrayOpened', false);
      this.set('selectedType', 'none');
    },
    didRender: function didRender() {
      var el = document.querySelector('.custom');

      if (el) {
        Ps.initialize(el);
      }
    },
    openTray: function openTray() {
      (0, _jquery.default)('#floating-button').removeClass('add-button');
      (0, _jquery.default)('#floating-button').removeClass('btn-primary');
      (0, _jquery.default)('#floating-button').addClass('close-button');
      (0, _jquery.default)('#floating-button').addClass('btn-danger');
      (0, _jquery.default)('#component-picker').css('bottom', '20px');
      this.set('isTrayOpened', true);
    },
    closeTray: function closeTray() {
      (0, _jquery.default)('#floating-button').removeClass('close-button');
      (0, _jquery.default)('#floating-button').removeClass('btn-danger');
      (0, _jquery.default)('#floating-button').addClass('add-button');
      (0, _jquery.default)('#floating-button').addClass('btn-primary');
      (0, _jquery.default)('#component-picker').css('bottom', '-310px');
      this.set('isTrayOpened', false);
    },
    actions: {
      toggleTray: function toggleTray() {
        if (this.get('isTrayOpened')) {
          this.closeTray();
        } else {
          this.openTray();
        }
      },
      closeTray: function closeTray() {
        this.closeTray();
      },
      openTray: function openTray() {
        this.openTray();
      }
    }
  });

  _exports.default = _default;
});