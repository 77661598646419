define("client/pods/users-roles/theme/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lqMSxgoa",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[\"w3o-users-roles/theme-form\"],[[\"model\",\"showEntries\",\"togglePreview\",\"saveTheme\"],[[24,[\"model\"]],[24,[\"showEntries\"]],[28,\"route-action\",[\"togglePreview\"],null],[28,\"route-action\",[\"saveTheme\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/users-roles/theme/edit/template.hbs"
    }
  });

  _exports.default = _default;
});