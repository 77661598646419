define("client/pods/scheduler/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    setupController: function setupController(controller) {
      //Set variables for nav bar
      if (this.controllerFor('application').get('appID') !== 'Scheduler') {
        this.controllerFor('application').set('appID', 'Scheduler');
        this.controllerFor('application').set('menus', [{
          'title': 'Scheduler',
          'unique': 'scheduler',
          'subunique': 'subscheduler',
          'glyph': 'fa-calendar',
          'theme': '#e64759',
          'color': '#e64759',
          'borderColor': '#e64759',
          'children': [{
            'title': 'Schedule List',
            'link': 'scheduler.index'
          }, {
            'title': 'Create New Schedule',
            'link': 'scheduler.create'
          }]
        }]);
      }

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var session = Ember.Object.create({
            user: user
          });
          controller.set('session', session); //set session
        });
      }
    },
    actions: {}
  });

  _exports.default = _default;
});