define("client/pods/components/dashboard-v2/chart-component/filter-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filter_param: null,
    init: function init() {
      this._super();

      var filter_param = [{
        orderby: 1,
        datasourcenum: 1,
        columnname: 'createdAt',
        readablename: 'Created At',
        datatype: 'DateTime',
        inputtype: 'dropdown',
        inputvalue: '',
        defaultvalue: 'FirstObject',
        is_selectall: false,
        dropdownfilter: 'GetYear',
        dropdownitem: [{
          label: '2019',
          value: '2019'
        }, {
          label: '2018',
          value: '2018'
        }, {
          label: '2017',
          value: '2017'
        }, {
          label: '2016',
          value: '2016'
        }, {
          label: '2015',
          value: '2015'
        }]
      }, {
        orderby: 2,
        datasourcenum: 1,
        columnname: 'createdAt',
        readablename: 'Created At',
        datatype: 'DateTime',
        inputtype: 'fromto_datepicker',
        datefrom: '',
        dateto: '',
        defaultvalue: ''
      }, {
        orderby: 3,
        datasourcenum: 1,
        columnname: 'user',
        readablename: 'Audience',
        datatype: 'Int64',
        inputtype: 'dropdown',
        inputvalue: '',
        defaultvalue: '',
        is_selectall: true,
        dropdownfilter: 'GetDistinct',
        dropdownitem: [{
          label: 'John',
          value: 1
        }, {
          label: 'Jack',
          value: 2
        }]
      }];
      this.set('filter_param', filter_param);
    },
    actions: {
      clickMe: function clickMe(filter_param) {
        console.log('click me debug');
        console.log(filter_param); //filter_param.pushObject({orderby: 4, test1 : 123});
        //this.clickMe(filter_param);
      },
      mutateFilter: function mutateFilter() {
        //alert('test');
        // console.log('---value---');
        // console.log(this.get('filter_param'));
        var filterstring = []; //to generate the filter string (only compatible to CH query)

        this.get('filter_param').forEach(function (data, i) {
          switch (data.datatype) {
            case 'Int64':
              if (data.inputvalue !== '' && data.inputvalue !== null) {
                filterstring.push("( \"".concat(data.columnname, "\" = ").concat(data.inputvalue, " )"));
              }

              break;

            case 'DateTime':
              if (data.inputtype === 'dropdown') {
                if (data.inputvalue !== '' && data.inputvalue !== null) {
                  if (data.dropdownfilter === 'GetYear') {
                    filterstring.push("( toYear(\"".concat(data.columnname, "\") = ").concat(data.inputvalue, " )"));
                  }
                }
              } else if (data.inputtype === 'fromto_datepicker') {
                if (data.datefrom !== '' && data.dateto !== '' && data.datefrom !== null && data.dateto !== null) {
                  var datefrom = data.datefrom.split("/");
                  var dateto = data.dateto.split("/");
                  var datefrom_format = "".concat(datefrom[2], "-").concat(datefrom[1], "-").concat(datefrom[0]);
                  var dateto_format = "".concat(dateto[2], "-").concat(dateto[1], "-").concat(dateto[0]);
                  filterstring.push("( \"".concat(data.columnname, "\" between toDate('").concat(datefrom_format, "') and toDate('").concat(dateto_format, "') )"));
                }
              }

              break;
          }
        });
        var returnFilter = '';
        filterstring.forEach(function (data, i) {
          returnFilter += data;

          if (i !== filterstring.length - 1) {
            returnFilter += ' and ';
          }
        });
        this.returnFilterString(returnFilter); // console.log('filter string');
        // console.log(filterstring);
      }
    }
  });

  _exports.default = _default;
});