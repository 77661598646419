define("client/pods/components/w3office-forms/entry-group-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isError: false,
    errorMessage: '',
    actions: {
      saveConfiguration: function saveConfiguration() {
        this.set('isError', false);
        /*
        	Persisting record via mixins/w3office-forms/form-setting-crud.js
        	persistRecord: function(value, modalName, controllerList)
        */

        if (Ember.isEmpty(this.get('entrygroup').get('groupname'))) {
          this.set('errorMessage', 'Please fill the Group Name');
          this.set('isError', true);
        } else if (Ember.isEmpty(this.get('entrygroup').get('priority'))) {
          this.set('errorMessage', 'Please fill the Priority Number');
          this.set('isError', true);
        }

        if (!this.get('isError')) {
          this.persistRecord(this.get('entrygroup'), 'entrygroup', 'entrygroupList');
        } else {
          (0, _jquery.default)(".flash-messsage").show();
          Ember.run.later(this, function () {
            (0, _jquery.default)(".flash-messsage").fadeOut(1000);
          }, 3500);
        }
      },
      discardChanges: function discardChanges() {
        if (Ember.isEmpty(this.get('entrygroup').id)) {
          //unload if data is uncommited
          this.get('entrygroup').unloadRecord();
        } else {
          //rollback if data is persisted
          this.get('entrygroup').rollbackAttributes();
        }

        (0, _jquery.default)('#entrygroup-props').modal('hide');
      }
    }
  });

  _exports.default = _default;
});