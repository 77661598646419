define("client/pods/w3office-forms/importexport/route", ["exports", "jquery", "client/mixins/w3office-forms/serializablejson", "client/mixins/w3o-users-roles/check-user-role", "client/pods/w3office-forms/importexport/key"], function (_exports, _jquery, _serializablejson, _checkUserRole, _key) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_checkUserRole.default, _serializablejson.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super();
    },
    model: function model() {
      return true;
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        appID: 'nothing',
        choosenForms: [],
        pickFile: false,
        chooseFile: 0,
        isLoadExport: true,
        isLoadImport: true,
        listAppID: [],
        // untuk validasi pengelompokan
        listApp: [],
        // untuk nampilin dropdown
        arrayObjApp: {},
        arrayObjForm: {},
        // untuk convert property yg nyambung ke form. id -> formid
        exportFileName: null,
        processedForms: 0,
        totalForms: 0,
        processingExport: false,
        processingImport: false,
        //import
        errorUploadFile: false,
        successUploadFile: false,
        messageUploadFile: '',
        messageImportFile: '',
        fileName: '',
        jsonIsLoaded: false,
        jsonFile: null,
        //export/download
        errorDownloadFile: false,
        messageDownloadFile: '',
        generalDisable: false
      });
      var arrPromises = [this.store.findAll('process'), this.store.findAll('formcomponent')];
      Ember.RSVP.Promise.all(arrPromises).then(function (resolution) {
        var processes = resolution[0];
        var formComponents = resolution[1];
        var objFormComponent = {},
            objProcess = {};
        processes.forEach(function (process) {
          objProcess[process.id] = process;
        });
        controller.set('process', objProcess);
        formComponents.forEach(function (formComponent) {
          objFormComponent[formComponent.get('id')] = formComponent;
        });
        controller.set('formComponent', objFormComponent);
        controller.set('isLoadImport', false);
      });
      this.store.findAll('form').then(function (forms) {
        forms.forEach(function (form) {
          if (controller.get('listAppID').indexOf(form.get('appID')) === -1) {
            controller.get('listAppID').push(form.get('appID'));
            controller.get('listApp').push({
              name: form.get('appID'),
              id: form.get('appID')
            });
            controller.get('arrayObjApp')[form.get('appID')] = [];
          }

          form.set('checked', false);
          controller.get('arrayObjApp')[form.get('appID')].push(form);
          controller.get('arrayObjForm')[form.get('id')] = form.get('formid');
        });
        controller.get('listApp').forEach(function (content) {
          content.name = content.name + ' ( ' + controller.get('arrayObjApp')[content.id].length + ' form(s) )';
        });
        controller.set('isLoadExport', false);
      });
      controller.addObserver('processedForms', 'totalForms', function () {
        var resultWidth = controller.get('processedForms') / controller.get('totalForms') * 100;
        controller.set('progressbarWidth', Ember.String.htmlSafe("width: " + resultWidth + "%"));
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {// This can be used to notice when transition occured when processing export/import.
        // But still got error
        // if (processingImport || processingExport)
        // var confirmation = confirm('Export/Import still in process, Do you want to abort the process?');
        // console.log(confirmation);
        // console.log(transition);
        // transition.abort();
      }
    },
    extractEntity: function extractEntity(mainFormData, resolver, entity, entityObject, entityIDParent) {
      var _this = this;

      var self = this;
      var whereQuery = {};
      whereQuery.where = {};
      whereQuery.where[entityObject.belongsTo.key] = entityIDParent;
      var arrayIDLevelN = [];
      var arrayPromiseTemporary = [];
      entityIDParent.forEach(function (idParent) {
        var promiseTemporary = new Promise(function (resolveTemporary, rejectTemporary) {
          var whereQueryT = {};
          whereQueryT.where = {};
          whereQueryT.where[entityObject.belongsTo.key] = idParent;

          _this.store.query(entity, whereQueryT).then(function (resultEntityTemporary) {
            resolveTemporary(resultEntityTemporary);
          });
        });
        arrayPromiseTemporary.push(promiseTemporary);
      });
      Ember.RSVP.Promise.all(arrayPromiseTemporary).then(function (resolvedArrayPromiseTemporary) {
        var resultExtractEntity = [];
        resolvedArrayPromiseTemporary.forEach(function (aaa) {
          if (aaa.get('length') > 0) {
            aaa.forEach(function (a) {
              resultExtractEntity.push(a);
            });
          }
        });
        mainFormData[entity] = {};
        mainFormData[entity]['keyName'] = entity;
        mainFormData[entity]['data'] = [];

        if (resultExtractEntity.length > 0) {
          var hasHasMany = false;
          resultExtractEntity.forEach(function (recordLevelN) {
            // recordLevelN is an instance of Ember Model, fetched using store.query()
            var serializedObj = recordLevelN.serialize();
            serializedObj['id'] = recordLevelN.get('id');
            /*Here to populate Relation Extended and Check hasMany*/

            Object.keys(entityObject).forEach(function (propertyEntity) {
              if (propertyEntity === 'hasMany') {
                hasHasMany = true;
                arrayIDLevelN.push(recordLevelN.get('id'));
              } else if (entityObject[propertyEntity].modelName === 'form') {
                /*Convert 'id' of Form to 'formid'*/
                serializedObj[propertyEntity] = self.controller.get('arrayObjForm')[serializedObj[propertyEntity]];
              } else if (entityObject.belongsTo.modelName === 'form') {
                /*Convert 'id' of Form to 'formid'*/
                serializedObj[entityObject.belongsTo.key] = self.controller.get('arrayObjForm')[serializedObj[entityObject.belongsTo.key]];
              }
            });
            mainFormData[entity]['data'].push(serializedObj);
          });

          if (!hasHasMany) {
            resolver(true);
          } else {
            var arrayPromiseLevelNChildren = [];
            Object.keys(entityObject.hasMany).forEach(function (propertyKey) {
              var promiseLevelNChildren = new Promise(function (resolveLevelNChildren, rejectChildren) {
                self.extractEntity(mainFormData, resolveLevelNChildren, propertyKey, entityObject.hasMany[propertyKey], arrayIDLevelN);
              });
              arrayPromiseLevelNChildren.addObject(promiseLevelNChildren);
            });
            Ember.RSVP.Promise.all(arrayPromiseLevelNChildren).then(function () {
              resolver(true);
            });
          }
        } else {
          resolver(true);
        }
      }); // this.store.query(entity, whereQuery).then((resultExtractEntity) => {
      // 	let totalLevelN = resultExtractEntity.get('length');
      // 	mainFormData[entity] = {};
      // 	mainFormData[entity]['keyName'] = entity;
      // 	mainFormData[entity]['data'] = [];
      // 	if (totalLevelN > 0) {
      // 		let hasHasMany = false;
      // 		resultExtractEntity.forEach((recordLevelN) => {
      // 			// recordLevelN is an instance of Ember Model, fetched using store.query()
      // 			let serializedObj = recordLevelN.serialize();
      // 			serializedObj['id'] = recordLevelN.get('id');
      // 			/*Here to populate Relation Extended and Check hasMany*/
      // 			Object.keys(entityObject).forEach((propertyEntity) => {
      // 				if (propertyEntity === 'hasMany') {
      // 					hasHasMany = true;
      // 					arrayIDLevelN.push(recordLevelN.get('id'));
      // 				}
      // 				else if (entityObject[propertyEntity].modelName === 'form') {
      // 					/*Convert 'id' of Form to 'formid'*/
      // 					serializedObj[propertyEntity] = self.controller.get('arrayObjForm')[serializedObj[propertyEntity]];
      // 				}
      // 				else if (entityObject.belongsTo.modelName === 'form') {
      // 					/*Convert 'id' of Form to 'formid'*/
      // 					serializedObj[entityObject.belongsTo.key] = self.controller.get('arrayObjForm')[serializedObj[entityObject.belongsTo.key]];
      // 				}
      // 			});
      // 			mainFormData[entity]['data'].push(serializedObj);
      // 		});
      // 		if (!hasHasMany) {
      // 			resolver(true);
      // 		}
      // 		else {
      // 			let arrayPromiseLevelNChildren = [];
      // 			Object.keys(entityObject.hasMany).forEach((propertyKey) => {
      // 				let promiseLevelNChildren = new Promise((resolveLevelNChildren, rejectChildren) => {
      // 					self.extractEntity(mainFormData, resolveLevelNChildren, propertyKey, entityObject.hasMany[propertyKey], arrayIDLevelN);
      // 				});
      // 				arrayPromiseLevelNChildren.addObject(promiseLevelNChildren);
      // 			});
      // 			EmberPromise.all(arrayPromiseLevelNChildren).then(() => {
      // 				resolver(true);
      // 			});
      // 		}
      // 	}
      // 	else {
      // 		resolver(true);
      // 	}
      // });
    },
    generateEntity: function generateEntity(formData, resolver, arrayObjHash, arrayWaitingEntityRecord, entity, entityObject, entityIDParent) {
      var self = this;

      if (formData[entity].data.length > 0) {
        arrayObjHash[entity] = {};
        var arrayPromiseLevelN = [];
        var promiseLevelN = new Promise(function (resolveLevelN, rejectLevelN) {
          var arrayPromiseLevelNRecord = [];
          var arrayObjIDLevelN = {};
          formData[entity].data.forEach(function (recordLevelN) {
            var promiseLevelNRecord = new Promise(function (resolveLevelNRecord, rejectLevelNRecord) {
              var arrayWaitingProperty = [];
              Object.keys(entityObject).forEach(function (propertyEntity) {
                if (recordLevelN[propertyEntity]) {
                  if (entityObject[propertyEntity].modelName === 'form') {
                    /*Specifically populate 'form' entity */
                    if (arrayObjHash['form'][recordLevelN[propertyEntity]]) {
                      recordLevelN[propertyEntity] = arrayObjHash['form'][recordLevelN[propertyEntity]];
                    } else {
                      var waitingObj = {
                        isBelongsTo: true,
                        entity: 'form',
                        property: propertyEntity,
                        value: recordLevelN[propertyEntity]
                      };
                      arrayWaitingProperty.push(waitingObj);
                      /*set null the belongsTo relation*/

                      recordLevelN[propertyEntity] = null;
                    }
                  } else {
                    /*Check other relation extension */
                    if (arrayObjHash[entityObject[propertyEntity].modelName][recordLevelN[propertyEntity]]) {
                      if (entityObject[propertyEntity].isBelongsTo && isNaN(recordLevelN[propertyEntity]) === false) {
                        recordLevelN[propertyEntity] = arrayObjHash[entityObject[propertyEntity].modelName][recordLevelN[propertyEntity]];
                      } else {
                        recordLevelN[propertyEntity] = arrayObjHash[entityObject[propertyEntity].modelName][recordLevelN[propertyEntity]].id;
                      }
                    } else {
                      if (entity === 'fieldfilter' && entityObject[propertyEntity].modelName === 'field' && recordLevelN.filterType !== 'field-relationship') {// no need to push to arrayWaitingProperty
                      } else {
                        var _waitingObj = {
                          isBelongsTo: entityObject[propertyEntity].isBelongsTo,
                          entity: entityObject[propertyEntity].modelName,
                          property: propertyEntity,
                          value: recordLevelN[propertyEntity]
                        };
                        arrayWaitingProperty.push(_waitingObj);

                        if (entityObject[propertyEntity].isBelongsTo) {
                          /*set null the belongsTo relation*/
                          recordLevelN[propertyEntity] = null;
                        }
                      }
                    }
                  }
                }
              });
              /*Pair the Foreign Key*/

              recordLevelN[entityObject.belongsTo.key] = entityIDParent[recordLevelN[entityObject.belongsTo.key]];
              var tempID = recordLevelN.id;
              delete recordLevelN.id;

              try {
                recordLevelN.createdAt = new Date();
                recordLevelN.updatedAt = new Date();
                self.store.createRecord(entity, recordLevelN).save().then(function (newLevelNRecord) {
                  /*Push new Record to arrayObjHash*/
                  arrayObjHash[entity][tempID] = newLevelNRecord;
                  /*If some propery are waiting, push to arrayWaitingEntityRecord*/

                  if (arrayWaitingProperty.length > 0) {
                    arrayWaitingEntityRecord.push({
                      waitingProperty: arrayWaitingProperty,
                      record: newLevelNRecord
                    });
                  }

                  arrayObjIDLevelN[tempID] = newLevelNRecord;
                  resolveLevelNRecord(true);
                });
              } catch (e) {
                console.log("error on generateEntity()");
                console.log(e);
                console.log(entity);
                console.log(recordLevelN);
              }
            });
            arrayPromiseLevelNRecord.push(promiseLevelNRecord);
          });
          Ember.RSVP.Promise.all(arrayPromiseLevelNRecord).then(function () {
            if (entityObject.hasMany) {
              var arrayPromiseLevelNChildren = [];
              Object.keys(entityObject.hasMany).forEach(function (propertyKey) {
                var promiseLevelNChildren = new Promise(function (resolveLevelNChildren, rejectLevelNChildren) {
                  self.generateEntity(formData, resolveLevelNChildren, arrayObjHash, arrayWaitingEntityRecord, propertyKey, entityObject.hasMany[propertyKey], arrayObjIDLevelN);
                });
                arrayPromiseLevelNChildren.push(promiseLevelNChildren);
              });
              Ember.RSVP.Promise.all(arrayPromiseLevelNChildren).then(function () {
                resolveLevelN(true);
              });
            } else {
              resolveLevelN(true);
            }
          });
        });
        arrayPromiseLevelN.push(promiseLevelN);
        Ember.RSVP.Promise.all(arrayPromiseLevelN).then(function () {
          resolver(true);
        });
      } else {
        resolver(true);
      }
    },
    generateJSON: function generateJSON(masterJSON, iteration) {
      var dataStr = "data:text/json;charset=utf-8," + JSON.stringify(masterJSON);
      var dlAnchorElem = document.createElement('a');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "".concat(this.controller.get("exportFileName"), "-part").concat(iteration, ".json"));
      dlAnchorElem.click();
      dlAnchorElem.remove();
    },
    actions: {
      uploadFile: function uploadFile(file) {
        if (file['file'].type === 'application/json') {
          this.controller.set('errorUploadFile', false);
          this.controller.set('jsonFile', file);
          this.controller.set('jsonIsLoaded', true);
          this.controller.set('fileName', file.get('name'));
        } else {
          this.controller.set('fileName', file.get('name'));
          this.controller.set('errorUploadFile', true);
          this.controller.set('jsonIsLoaded', false);
          this.controller.set('messageUploadFile', 'Please select file with .json extension');
        }
      },
      importForms: function importForms(file) {
        var self = this;
        this.controller.set('processedForms', 0);
        this.controller.set('processingImport', true);
        this.controller.set('messageImportFile', 'Processing...');
        var namespace = this.store.adapterFor('application').get('namespace');
        var url = namespace + '/forms/uploadJSON';
        var filepath = '/uploads/jsonfile';
        file.upload(url, {
          headers: {
            Authorization: "Bearer ".concat(this.get('authObject.data.authenticated.access_token'))
          },
          data: {
            path: filepath
          }
        }).then(function (response) {
          var fd = response['body']['files'][0]['fd'];
          var url = self.store.adapterFor('application').get('namespace') + "/forms/downloadJSON?fd=";

          _jquery.default.ajax({
            type: "POST",
            url: url + fd,
            success: function success(response) {
              /*Call Delete JSON here*/
              _jquery.default.ajax({
                type: "POST",
                url: self.store.adapterFor('application').get('namespace') + "/forms/deleteJSON?fd=" + fd,
                success: function success() {
                  console.log('deleteJSON success');
                }
              });

              var result = JSON.parse(response);
              var key = result.key;
              var incrementLoop = 0;
              var arrayObjHash = {};
              var arrayWaitingEntityRecord = [];
              self.controller.set('totalForms', result.mainData.length);

              var simpleLoop = function simpleLoop() {
                if (incrementLoop === result.mainData.length) {
                  var arrayWaitingProtocol = [];
                  arrayWaitingEntityRecord.forEach(function (waitingRecord) {
                    if (waitingRecord.record._internalModel.modelName === 'formrelation') {
                      var waitingProtocol = new Promise(function (resolveWP, rejectWP) {
                        var WPEntity = waitingRecord.waitingProperty[0].entity,
                            WPValue = waitingRecord.waitingProperty[0].value,
                            WPProperty = waitingRecord.waitingProperty[0].property;

                        if (arrayObjHash[WPEntity][WPValue]) {
                          waitingRecord.record.set(WPProperty, arrayObjHash[WPEntity][WPValue]);
                          waitingRecord.record.save().then(function () {
                            resolveWP(true);
                          });
                        } else {
                          self.store.query('form', {
                            where: {
                              formid: WPValue
                            }
                          }).then(function (forms) {
                            var foundForm = forms.get('firstObject');

                            if (foundForm) {
                              waitingRecord.record.set(WPProperty, foundForm);
                              waitingRecord.record.save().then(function () {
                                resolveWP(true);
                              });
                            } else {
                              resolveWP(true);
                            }
                          });
                        }
                      });
                      arrayWaitingProtocol.push(waitingProtocol);
                    }
                  });
                  Ember.RSVP.Promise.all(arrayWaitingProtocol).then(function () {
                    self.controller.set('messageImportFile', 'Complete');
                    Ember.run.later(self, function () {
                      self.controller.set('processingImport', false);
                      self.controller.set('fileName', null);
                      self.controller.set('jsonIsLoaded', false);
                      self.transitionTo('w3office-forms');
                    }, 3000);
                  });
                } else {
                  var arrayPromiseForm = [];
                  var arrayOldAutoIncrement; // let arrayObjHash = {};
                  // let arrayWaitingEntityRecord = [];

                  var promiseForm = new Promise(function (resolveForm, rejectForm) {
                    self.store.query('form', {
                      where: {
                        formid: result.mainData[incrementLoop].form.formid
                      }
                    }).then(function (resultForm) {
                      if (resultForm.get('length') > 0) {
                        _jquery.default.ajax({
                          type: "POST",
                          url: self.store.adapterFor('application').get('namespace') + "/forms/deleteForm",
                          data: {
                            formID: resultForm.get('firstObject').get('id'),
                            isImport: true
                          },
                          success: function success(response) {
                            console.log('delete exist form complete');

                            if (response && response.oldAutoIncrement && response.oldAutoIncrement.field) {
                              arrayOldAutoIncrement = response.oldAutoIncrement.field;
                            }
                          }
                        });
                      }

                      result.mainData[incrementLoop].form.createdAt = new Date();
                      result.mainData[incrementLoop].form.updatedAt = new Date();

                      try {
                        self.store.createRecord('form', result.mainData[incrementLoop].form).save().then(function (newForm) {
                          console.log('new Form Created - ' + newForm.get('formid'));

                          if (arrayObjHash['form']) {
                            arrayObjHash['form'][newForm.get('formid')] = newForm;
                          } else {
                            arrayObjHash['form'] = {};
                            arrayObjHash['form'][newForm.get('formid')] = newForm;
                          }

                          var arrayPromiseLevel1 = [];
                          Object.keys(key.form.hasMany).forEach(function (entity, index) {
                            var promiseLevel1 = new Promise(function (resolveLevel1, rejectLevel1) {
                              if (result.mainData[incrementLoop][entity].data.length > 0) {
                                arrayObjHash[entity] = {};
                                var arrayPromiseLevel1Record = [];
                                var arrayObjIDLevel1 = {};
                                result.mainData[incrementLoop][entity].data.forEach(function (recordLevel1) {
                                  var promiseLevel1Record = new Promise(function (resolveLevel1Record, rejectLevel1Record) {
                                    var arrayWaitingProperty = [];
                                    Object.keys(key.form.hasMany[entity]).forEach(function (propertyEntity) {
                                      if (recordLevel1[propertyEntity]) {
                                        /*if property has value*/
                                        if (key.form.hasMany[entity][propertyEntity].modelName === 'form') {
                                          /*Specifically populate 'form' entity */
                                          if (arrayObjHash['form'][recordLevel1[propertyEntity]]) {
                                            recordLevel1[propertyEntity] = arrayObjHash['form'][recordLevel1[propertyEntity]];
                                          } else {
                                            var waitingObj = {
                                              isBelongsTo: true,
                                              entity: 'form',
                                              property: propertyEntity,
                                              value: recordLevel1[propertyEntity]
                                            };
                                            arrayWaitingProperty.push(waitingObj);
                                            /*set null the belongsTo relation*/

                                            recordLevel1[propertyEntity] = null;
                                          }
                                        } else if (key.form.hasMany[entity][propertyEntity].modelName === 'process') {
                                          /*Specifically populate 'process' entity */

                                          /*popule process by 'id' */
                                          recordLevel1[propertyEntity] = self.controller.get('process')[recordLevel1[propertyEntity]];
                                        } else {
                                          /*Check other relation extension */

                                          /*Emergency*/
                                          if (arrayObjHash[key.form.hasMany[entity][propertyEntity].modelName] === undefined) {
                                            arrayObjHash[key.form.hasMany[entity][propertyEntity].modelName] = {};
                                          }

                                          if (arrayObjHash[key.form.hasMany[entity][propertyEntity].modelName][recordLevel1[propertyEntity]]) {
                                            if (key.form.hasMany[entity][propertyEntity].isBelongsTo && isNaN(recordLevel1[propertyEntity]) === false) {
                                              recordLevel1[propertyEntity] = arrayObjHash[key.form.hasMany[entity][propertyEntity].modelName][recordLevel1[propertyEntity]];
                                            } else {
                                              recordLevel1[propertyEntity] = arrayObjHash[key.form.hasMany[entity][propertyEntity].modelName][recordLevel1[propertyEntity]].id;
                                            }
                                          } else {
                                            var _waitingObj2 = {
                                              isBelongsTo: key.form.hasMany[entity][propertyEntity].isBelongsTo,
                                              entity: key.form.hasMany[entity][propertyEntity].modelName,
                                              property: propertyEntity,
                                              value: recordLevel1[propertyEntity]
                                            };
                                            arrayWaitingProperty.push(_waitingObj2);

                                            if (key.form.hasMany[entity][propertyEntity].isBelongsTo) {
                                              /*set null the belongsTo relation*/
                                              recordLevel1[propertyEntity] = null;
                                            }
                                          }
                                        }
                                      }
                                    });

                                    if (entity === 'field') {
                                      /*Special Condition for populating formcomponent on w3o_field*/
                                      recordLevel1['formcomponent'] = self.controller.get('formComponent')[recordLevel1['formcomponent']];
                                    }
                                    /*Pair the Foreign Key*/


                                    recordLevel1[key.form.hasMany[entity].belongsTo.key] = newForm;
                                    var tempID = recordLevel1.id;
                                    delete recordLevel1.id;

                                    try {
                                      recordLevel1.createdAt = new Date();
                                      recordLevel1.updatedAt = new Date();
                                      self.store.createRecord(entity, recordLevel1).save().then(function (newLevel1Record) {
                                        /*Push new Record to arrayObjHash*/
                                        arrayObjHash[entity][tempID] = newLevel1Record;
                                        /*Push new record to arrayWaiting*/

                                        if (arrayWaitingProperty.length > 0) {
                                          arrayWaitingEntityRecord.push({
                                            waitingProperty: arrayWaitingProperty,
                                            record: newLevel1Record
                                          });
                                        }

                                        arrayObjIDLevel1[tempID] = newLevel1Record;
                                        /*Link Auto Increment Field*/

                                        if (entity === 'field') {
                                          if (newLevel1Record.get('formcomponent.content').get('label') === 'Auto Increment') {
                                            if (arrayOldAutoIncrement) {
                                              var arrayPromiseLevel2 = [];
                                              arrayOldAutoIncrement.forEach(function (oldAutoIncrement, index) {
                                                var promiseLevel2 = new Promise(function (resolveLevel2, rejectLevel2) {
                                                  if (newLevel1Record.get('columnName') === oldAutoIncrement.columnName && newLevel1Record.get('form.content').get('formid')) {
                                                    self.store.queryRecord('fieldautoincrement', {
                                                      field: oldAutoIncrement.id
                                                    }).then(function (updateAutoIncrement) {
                                                      updateAutoIncrement.set('field', newLevel1Record.get('id'));
                                                      updateAutoIncrement.save().then(function () {
                                                        resolveLevel2(true);
                                                      });
                                                    });
                                                  } else {
                                                    resolveLevel2(true);
                                                  }
                                                });
                                                arrayPromiseLevel2.push(promiseLevel2);
                                              });
                                              Ember.RSVP.Promise.all(arrayPromiseLevel2).then(function () {
                                                resolveLevel1Record(true);
                                              });
                                            } else {
                                              resolveLevel1Record(true);
                                            }
                                          } else {
                                            resolveLevel1Record(true);
                                          }
                                        } else {
                                          resolveLevel1Record(true);
                                        }
                                      });
                                    } catch (e) {
                                      console.log('error on importForms()');
                                      console.log(e);
                                      console.log(entity);
                                      console.log(recordLevel1);
                                    }
                                  });
                                  arrayPromiseLevel1Record.push(promiseLevel1Record);
                                });
                                Ember.RSVP.Promise.all(arrayPromiseLevel1Record).then(function () {
                                  if (key.form.hasMany[entity].hasMany) {
                                    var arrayPromiseLevel1Children = [];
                                    Object.keys(key.form.hasMany[entity].hasMany).forEach(function (propertyKey) {
                                      var promiseLevel1Children = new Promise(function (resolveLevel1Children, rejectLevel1Children) {
                                        self.generateEntity(result.mainData[incrementLoop], resolveLevel1Children, arrayObjHash, arrayWaitingEntityRecord, propertyKey, key.form.hasMany[entity].hasMany[propertyKey], arrayObjIDLevel1);
                                      });
                                      arrayPromiseLevel1Children.push(promiseLevel1Children);
                                    });
                                    Ember.RSVP.Promise.all(arrayPromiseLevel1Children).then(function () {
                                      resolveLevel1(true);
                                    });
                                  } else {
                                    resolveLevel1(true);
                                  }
                                });
                              } else {
                                resolveLevel1(true);
                              }
                            });
                            arrayPromiseLevel1.push(promiseLevel1);
                          });
                          Ember.RSVP.Promise.all(arrayPromiseLevel1).then(function () {
                            resolveForm(true);
                          });
                        });
                      } catch (e) {
                        console.log('error on createRecord form');
                        console.log(e);
                        console.log(result.mainData[incrementLoop].form);
                      }
                    });
                  });
                  arrayPromiseForm.push(promiseForm);
                  Ember.RSVP.Promise.all(arrayPromiseForm).then(function () {
                    if (arrayWaitingEntityRecord.length > 0) {
                      // 'process waitingProtocol'
                      var _arrayWaitingProtocol = [];
                      var arrayRepushRecord = [];
                      arrayWaitingEntityRecord.forEach(function (waitingRecord) {
                        var waitingProtocol = new Promise(function (resolveWP, rejectWP) {
                          var rePush = false;
                          waitingRecord.waitingProperty.forEach(function (wp) {
                            if (arrayObjHash[wp.entity][wp.value]) {
                              if (wp.isBelongsTo) {
                                waitingRecord.record.set(wp.property, arrayObjHash[wp.entity][wp.value]);
                              } else {
                                waitingRecord.record.set(wp.property, arrayObjHash[wp.entity][wp.value].id);
                              }
                            } else {
                              if (!rePush) {
                                rePush = true;
                                arrayRepushRecord.push(waitingRecord);
                              }
                            }
                          });
                          waitingRecord.record.save().then(function () {
                            resolveWP(true);
                          });
                        });

                        _arrayWaitingProtocol.push(waitingProtocol);
                      });
                      Ember.RSVP.Promise.all(_arrayWaitingProtocol).then(function () {
                        // 'waitingProtocol Complete'
                        if (arrayRepushRecord.length > 0) {
                          arrayWaitingEntityRecord = arrayRepushRecord;
                        } else {
                          arrayWaitingEntityRecord = [];
                        }

                        self.controller.set('processedForms', self.controller.get('processedForms') + 1);
                        incrementLoop++;
                        simpleLoop();
                      });
                    } else {
                      self.controller.set('processedForms', self.controller.get('processedForms') + 1);
                      incrementLoop++;
                      simpleLoop();
                    }
                  });
                }
              };

              simpleLoop();
            }
          });
        });
      },
      exportForms: function exportForms() {
        var exportforms;
        this.controller.set('messageDownloadFile', '');

        if (this.controller.get('chooseFile') === 2) {
          exportforms = this.controller.get('choosenForms');
        } else {
          exportforms = this.controller.get('arrayObjApp')[this.controller.get('appID')];
        }

        if (Ember.isEmpty(this.controller.get('exportFileName')) || this.controller.get('appID') === null || this.controller.get('appID') === 'nothing' || this.controller.get('chooseFile') === 0) {
          this.controller.set('errorDownloadFile', true);

          if (Ember.isEmpty(this.controller.get('exportFileName'))) {
            this.controller.set('messageDownloadFile', this.controller.get('messageDownloadFile') + 'File Name is Required. ');
          } else if (this.controller.get('appID') === null || this.controller.get('appID') === 'nothing') {
            this.controller.set('messageDownloadFile', this.controller.get('messageDownloadFile') + 'Application is Required. ');
          } else if (this.controller.get('chooseFile') === 0) {
            this.controller.set('messageDownloadFile', this.controller.get('messageDownloadFile') + 'Choose File is Required. ');
          }
        } else {
          if (this.controller.get('choosenForms').length === 0 && this.controller.get('chooseFile') === 2) {
            this.controller.set('errorDownloadFile', true);
            this.controller.set('messageDownloadFile', this.controller.get('messageDownloadFile') + 'Choose File is Required. ');
          } else {
            console.time('timerName');
            this.controller.setProperties({
              processingExport: true,
              errorDownloadFile: false,
              messageDownloadFile: 'Processing...',
              generalDisable: true,
              isProcessing: true,
              processedForms: 0,
              totalForms: exportforms.length
            });
            var self = this;
            var masterJSON = {};
            console.log('Generate JSON');
            masterJSON['key'] = _key.default;
            masterJSON['mainData'] = [];
            var incrementLoop = 0;
            var iteration = 1;

            var simpleLoop = function simpleLoop() {
              if (incrementLoop === exportforms.length) {
                Ember.run.later(self, function () {
                  self.controller.setProperties({
                    processingExport: false,
                    exportFileName: null,
                    appID: 'nothing',
                    messageDownloadFile: '',
                    pickFile: false,
                    chooseFile: 0,
                    choosenForms: [],
                    generalDisable: false
                  });
                }, 3000);
                var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(masterJSON));
                var dlAnchorElem = document.createElement('a');
                dlAnchorElem.setAttribute("href", dataStr);

                if (iteration === 1) {
                  dlAnchorElem.setAttribute("download", self.controller.get("exportFileName") + ".json");
                } else {
                  dlAnchorElem.setAttribute("download", "".concat(self.controller.get("exportFileName"), "-part").concat(iteration, ".json"));
                }

                dlAnchorElem.click();
                console.timeEnd('timerName');
                self.controller.set('messageDownloadFile', 'Complete');
              } else {
                var arrayPromiseForm = [];
                var mainFormData = {};
                var promiseForm = new Promise(function (resolveForm, rejectForm) {
                  /**
                   * INITIAL
                   * fetch Form data
                   */
                  self.store.findRecord('form', exportforms[incrementLoop].get('id')).then(function (formValue) {
                    mainFormData['form'] = formValue.serialize();
                    /**
                     * contain Promise(s) of 'promiseLevel1'
                     * 'promiseLevel1' is a promise that handles the entity under 'Form' Entity, like
                     * [formaccesslimit, formpredefvalue, formprocess, formuniquecomposite, field, entryfilter, entrygroup]
                     */

                    var arrayPromiseMain = []; // Loop the Entities under the Form entity

                    Object.keys(_key.default.form.hasMany).forEach(function (entity) {
                      var arrayIDLevel1 = [];
                      var promiseLevel1 = new Promise(function (resolveLevel1, reject) {
                        var whereQuery = {};
                        whereQuery.where = {};
                        whereQuery.where[_key.default.form.hasMany[entity].belongsTo.key] = formValue.get('id');
                        self.store.query(entity, whereQuery).then(function (resultLevel1) {
                          var totalLevel1 = resultLevel1.get('length');
                          mainFormData[entity] = {};
                          mainFormData[entity]['keyName'] = entity;
                          mainFormData[entity]['data'] = [];

                          if (totalLevel1 > 0) {
                            /*process data of an Entity*/
                            var hasHasMany = false;
                            resultLevel1.forEach(function (recordLevel1) {
                              // recordLevel1 is an instance of Ember Model, fetched using store.query()
                              var serializedObj = recordLevel1.serialize();
                              serializedObj['id'] = recordLevel1.get('id');
                              /*Here to populate Form and Check hasMany*/

                              Object.keys(_key.default.form.hasMany[entity]).forEach(function (propertyEntity) {
                                if (propertyEntity === 'hasMany') {
                                  hasHasMany = true;
                                  arrayIDLevel1.push(recordLevel1.get('id'));
                                } else if (_key.default.form.hasMany[entity][propertyEntity].modelName === 'form') {
                                  /*Convert 'id' of Form to 'formid'*/
                                  serializedObj[propertyEntity] = self.controller.get('arrayObjForm')[serializedObj[propertyEntity]];
                                } else if (_key.default.form.hasMany[entity].belongsTo.modelName === 'form') {
                                  /*Convert 'id' of Form to 'formid'*/
                                  serializedObj[_key.default.form.hasMany[entity].belongsTo.key] = self.controller.get('arrayObjForm')[serializedObj[_key.default.form.hasMany[entity].belongsTo.key]];
                                }
                              });
                              mainFormData[entity]['data'].push(serializedObj);
                            });
                            /*If an Entity has 'hasMany', process them. Eg. Field has hasMany Fieldfilter*/

                            if (!hasHasMany) {
                              resolveLevel1(true);
                            } else {
                              var arrayPromiseMainChildren = [];
                              Object.keys(_key.default.form.hasMany[entity].hasMany).forEach(function (propertyKey) {
                                var promiseLevel1Children = new Promise(function (resolveLevel1Children, rejectChildren) {
                                  self.extractEntity(mainFormData, resolveLevel1Children, propertyKey, _key.default.form.hasMany[entity].hasMany[propertyKey], arrayIDLevel1);
                                });
                                arrayPromiseMainChildren.addObject(promiseLevel1Children);
                              });
                              Ember.RSVP.Promise.all(arrayPromiseMainChildren).then(function () {
                                resolveLevel1(true);
                              });
                            }
                          } else {
                            resolveLevel1(true);
                          }
                        });
                      });
                      arrayPromiseMain.push(promiseLevel1);
                    });
                    Ember.RSVP.Promise.all(arrayPromiseMain).then(function () {
                      // masterJSON['mainData'].push(mainFormData);
                      resolveForm(mainFormData);
                    });
                  });
                });
                arrayPromiseForm.push(promiseForm);
                Ember.RSVP.Promise.all(arrayPromiseForm).then(function (resolved) {
                  var _resolved = _slicedToArray(resolved, 1),
                      formData = _resolved[0];

                  if (JSON.stringify(masterJSON).length + JSON.stringify(formData).length > 1970000) {
                    self.generateJSON(masterJSON, iteration);
                    masterJSON['mainData'] = [];
                    iteration++;
                  }

                  masterJSON['mainData'].push(formData);
                  self.controller.set('processedForms', self.controller.get('processedForms') + 1);
                  incrementLoop++;
                  simpleLoop();
                });
              }
            };

            simpleLoop();
          }
        }
      },
      chooseApps: function chooseApps(value) {
        var controller = this.get('controller');
        controller.setProperties({
          pickFile: false,
          chooseFile: 0,
          appID: value,
          choosenForms: []
        });
      },
      chooseForms: function chooseForms(value) {
        var controller = this.get('controller');
        controller.setProperties({
          choosenForms: [],
          pickFile: false,
          formsTopick: controller.get('arrayObjApp')[controller.get('appID')]
        });

        if (value === 2) {
          controller.set('chooseFile', 2);
          controller.set('pickFile', true);
        } else if (value === 1) {
          controller.set('chooseFile', 1);
        } else {
          controller.set('chooseFile', 0);
        }
      },
      checkboxForms: function checkboxForms(value) {
        var controller = this.get('controller');

        if (value.checked === false) {
          value.set('checked', true);
          controller.get('choosenForms').push(value);
        } else {
          value.set('checked', false);
          controller.get('choosenForms').forEach(function (id) {
            if (id.modelName === value.get('modelName')) {
              controller.get('choosenForms').removeObject(id);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});