define("client/pods/components/report-generator/report-index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deleteReport: function deleteReport(report) {
        this.deleteReport(report);
      },
      runReport: function runReport(report) {
        this.runReport(report);
      },
      duplicateReport: function duplicateReport() {
        this.duplicateReport();
      },
      setParamCollection: function setParamCollection(paramcollection) {
        this.setParamCollection(paramcollection);
      },
      addParamCollection: function addParamCollection() {
        this.addParamCollection();
      },
      deleteParamCollection: function deleteParamCollection(param) {
        this.deleteParamCollection(param);
      },
      editReport: function editReport() {
        this.editReport();
      }
    }
  });

  _exports.default = _default;
});