define("client/pods/menu-builder/editicon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mgVnHd7x",
    "block": "{\"symbols\":[],\"statements\":[[4,\"component\",[\"menu-builder/modal-dialog\"],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-6 offset-md-3 modal-box\"],[8],[0,\"\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"modal-header border-radius-4\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"h2\"],[8],[0,\"Edit Application\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"menu-builder.index\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",false],[12,\"class\",\"btn fa fa-times pull-right\"],[12,\"aria-hidden\",\"true\"],[3,\"action\",[[23,0,[]],\"closeModal\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[\"menu-builder/menulogo-uploader\"],[[\"app\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/menu-builder/editicon/template.hbs"
    }
  });

  _exports.default = _default;
});