define("client/pods/components/dashboard-builder/autocomplete-input/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    inputQuery: '',
    dropdownCSSProps: Ember.String.htmlSafe('display:none;'),
    startQuery: false,
    authObject: Ember.inject.service('session'),
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      var condition = {}; //Initial Value if deployed on Page w/ entry data available

      if (!Ember.isEmpty(this.get('field').get(self.get('fieldValue')))) {
        condition['limit'] = 1;
        condition['where'] = {};
        condition['where'][self.get('mappingvalue')] = this.get('field').get(self.get('fieldValue'));

        if (!Ember.isEmpty(this.get('category'))) {
          condition['where']['category'] = this.get('category');
        }

        if (!Ember.isEmpty(this.get('tablename'))) {
          condition['where']['tablename'] = this.get('tablename');
        }

        var nameSpace = self.store.adapterFor('application').get('namespace');
        var url = nameSpace + '/entitymappings?where=' + JSON.stringify(condition['where']) + '&limit=' + condition['limit'];

        _jquery.default.ajax({
          type: "GET",
          url: url,
          success: function success(response) {
            self.set('inputQuery', response['entitymappings'][0][self.get('mappinglabel')]);
            self.set('startQuery', true);
          }
        });
      }

      this.set("showDropdown", Ember.String.htmlSafe("display:block !important; position:absolute !important; top: initial !important; left: initial !important; float:none !important; overflow-y:auto !important; overflow-x:hidden !important; max-height:200px !important; cursor: pointer;"));
      this.set("hideDropdown", Ember.String.htmlSafe("display:none;"));
    },
    didInsertElement: function didInsertElement() {
      var self = this; //Handle behaviour on showing dropdown props

      (0, _jquery.default)(document).click(function () {
        if (_typeof(self.get('dropdownCSSProps')) === 'object' && !Ember.isEmpty(self.get('dropdownCSSProps'))) {
          if (self.get('dropdownCSSProps').toString().indexOf('display:block') !== -1) {
            self.set('dropdownCSSProps', Ember.String.htmlSafe("display:none;"));
          }
        } else if (typeof self.get('dropdownCSSProps') === 'string') {
          if (self.get('dropdownCSSProps').indexOf('display:block') !== -1) {
            self.set('dropdownCSSProps', Ember.String.htmlSafe("display:none;"));
          }
        }
      });
      (0, _jquery.default)(".search-field").click(function (e) {
        e.stopPropagation();
        return false;
      });
    },
    queryInput: function queryInput() {
      var self = this;
      var condition = {};

      if (!Ember.isEmpty(self.get('inputQuery'))) {
        var api = '';
        condition['limit'] = 10;
        condition['where'] = {};
        condition['where']['category'] = {};
        condition['where']['category']['contains'] = '';
        condition['where']['tablename'] = {};
        condition['where']['tablename']['contains'] = '';
        condition['where']['columnname'] = {};
        condition['where']['columnname']['contains'] = '';

        if (!Ember.isEmpty(this.get('category'))) {
          condition['where']['category'] = this.get('category');
        } else {
          condition['where']['category'] = {};
          condition['where']['category']['contains'] = self.get('inputQuery');
          api = '/entitymappings/getCategory';
        }

        if (!Ember.isEmpty(this.get('tablename'))) {
          condition['where']['tablename'] = this.get('tablename');
        } else {
          if (!Ember.isEmpty(this.get('category'))) {
            condition['where']['tablename'] = {};
            condition['where']['tablename']['contains'] = self.get('inputQuery');
            api = '/entitymappings/getModel';
          }
        }

        if (!Ember.isEmpty(this.get('category')) && !Ember.isEmpty(this.get('tablename'))) {
          condition['where']['columnname'] = {};
          condition['where']['columnname']['contains'] = self.get('inputQuery');
          api = '/entitymappings/getColumn';
        }

        var nameSpace = this.store.adapterFor('application').get('namespace');
        var url = nameSpace;

        _jquery.default.ajax({
          url: url + api,
          type: 'POST',
          data: {
            category: condition['where']['category'],
            categoryContains: condition['where']['category']['contains'],
            tablename: condition['where']['tablename'],
            tablenameContains: condition['where']['tablename']['contains'],
            columnnameContains: condition['where']['columnname']['contains']
          },
          success: function success(result) {
            self.set('recordList', result);
            self.set('dropdownCSSProps', self.get("showDropdown"));
            self.set('isPending', false);
          },
          error: function error(xhr, status, _error) {
            console.log(_error);
          }
        });
      }
    },
    keyUp: function keyUp(event) {
      if (event.keyCode === 27) {
        this.set("dropdownCSSProps", Ember.String.htmlSafe("display:none;"));
      }
      /*else if (this.get('escapedChars').indexOf(event.keyCode) !== -1) {
          this.set("dropdownCSSProps", Ember.String.htmlSafe("display:block;overflow:auto; max-height:90px;"));
          this.set("inputVal", $(event.target).val());
      }*/

    },
    actions: {
      addThis: function addThis(selected) {
        this.set('startQuery', false);
        this.set('inputQuery', selected.text);
        this.get('field').set(this.get('fieldValue'), selected.value);
        this.set('dropdownCSSProps', Ember.String.htmlSafe("display:none;"));
      },
      focused: function focused() {
        this.set('startQuery', true);
        this.set('isFocused', !this.get('isFocused')); // show latest search result

        if (this.get("recordList")) {
          this.set("dropdownCSSProps", this.get("showDropdown"));
        }
      },
      unfocused: function unfocused() {
        if (this.get("startQuery")) {
          // Validation to make sure user select the table or column from the popup list (autocomplete)
          var allowedFieldValue = ["tableName", "fromTable", "jointable", "fromtable", "totable"];

          if (this.get("inputQuery") && allowedFieldValue.indexOf(this.get("fieldValue")) > -1) {
            // assign categoryPrefix+inputQuery to field[fieldValue]
            var categoryPrefix;

            if (this.get("category")) {
              categoryPrefix = this.get("category");
            } else {
              categoryPrefix = this.get("field.category");
            }

            this.get("field").set(this.get("fieldValue"), "".concat(categoryPrefix, "_").concat(this.get("inputQuery")));
          } else {
            this.get("field").set(this.get("fieldValue"), "".concat(this.get("inputQuery")));
          }
        }

        this.set('startQuery', false);
      },
      observeInput: function observeInput() {
        //Observe changes on input field
        if (this.get('startQuery') && !Ember.isEmpty(this.get('inputQuery'))) {
          this.set('isPending', true); //Run Query to API if there is no input activity within 350ms of last input

          Ember.run.debounce(this, this.queryInput, 350);
        } else if (Ember.isEmpty(this.get('inputQuery'))) {
          this.set('isPending', false);
          this.set('recordList', null);
          this.get('field').set(this.get('fieldValue'), null);
        }
      }
    }
  });

  _exports.default = _default;
});