define("client/pods/dashboard-v2/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3o-users-roles/invalidate-session"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin, _invalidateSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _invalidateSession.default, {
    currentRoute: '',
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      // this.store.query('formuser', 1).then(function(user){
      // 	self.set('formContainer.sessionHolder', user);
      // });
      this.set('currentRoute', transition.targetName);
    },
    // model: function(){
    // 	return this.store.query('form');
    // },
    setupController: function setupController(controller, model) {
      //Define controller properties
      //controller.set('model', model);
      controller.set('currentRoute', this.get('currentRoute'));
      controller.set('componentsShown', false);

      if (this.get('authObject.data.user')) {
        this.store.findRecord('user', this.get('authObject.data.user.id')).then(function (user) {
          var session = Ember.Object.create({
            user: user
          });
          controller.set('session', session); //set session
        });
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        //Component Tab Handler on willTransition event
        this.controller.set('currentRoute', transition.targetName);
        this.controller.set('componentsShown', false);
      },
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      }
    }
  });

  _exports.default = _default;
});